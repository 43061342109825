import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import { getLienTemplates } from '../services/leinTemplatesService';
import {
  createSubSupplier,
  deleteSubSupplier,
  getSubSuppliers,
  resendSubSuppliersInvite,
  updateSubSupplier,
  unarchiveSubSupplier,
} from '../services/subSuppliersService';
import { SubSuppliersInitialState } from '../globalSlices/subSuppliersSlice';

export const createSubSupplierExtraReducer = (
  builder: ActionReducerMapBuilder<SubSuppliersInitialState>
) => {
  const reducerId = 'createSubSupplier';
  builder.addCase(createSubSupplier.pending, (state, action) => {
    // state.salesReceiptBase64 = null;
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createSubSupplier.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(createSubSupplier.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const deleteSubSupplierExtraReducer = (
  builder: ActionReducerMapBuilder<SubSuppliersInitialState>
) => {
  const reducerId = 'deleteSubSupplier';
  builder.addCase(deleteSubSupplier.pending, (state, action) => {
    // state.salesReceiptBase64 = null;
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    deleteSubSupplier.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(deleteSubSupplier.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const getSubSuppliersExtraReducer = (
  builder: ActionReducerMapBuilder<SubSuppliersInitialState>
) => {
  const reducerId = 'getSubSuppliers';
  builder.addCase(getSubSuppliers.pending, (state, action) => {
    // state.salesReceiptBase64 = null;
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.loadingErrorList = [];
  });
  builder.addCase(getSubSuppliers.fulfilled, (state, action: PayloadAction<any | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload.success && action.payload.data) {
      state.subSuppliers = action.payload.data;
    }
  });
  builder.addCase(getSubSuppliers.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getSubSuppliers: action.payload }]),
      ];
    }
  });
};
export const resendSubSuppliersInviteExtraReducer = (
  builder: ActionReducerMapBuilder<SubSuppliersInitialState>
) => {
  const reducerId = 'resendSubSuppliersInvite';
  builder.addCase(resendSubSuppliersInvite.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    resendSubSuppliersInvite.fulfilled,
    (state, action: PayloadAction<any | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(resendSubSuppliersInvite.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const updateSubSupplierExtraReducer = (
  builder: ActionReducerMapBuilder<SubSuppliersInitialState>
) => {
  const reducerId = 'updateSubSupplier';
  builder.addCase(updateSubSupplier.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(updateSubSupplier.fulfilled, (state, action: PayloadAction<any | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
  builder.addCase(updateSubSupplier.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const unarchiveSubSupplierExtraReducer = (
  builder: ActionReducerMapBuilder<SubSuppliersInitialState>
) => {
  const reducerId = 'unarchiveSubSupplier';
  builder.addCase(unarchiveSubSupplier.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(unarchiveSubSupplier.fulfilled, (state, action: PayloadAction<any | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
  builder.addCase(unarchiveSubSupplier.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
