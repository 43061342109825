import { Modal } from '@mui/material';
import { AppInputField } from '../AppInputField/AppInputField';
import { useEffect, useMemo, useState } from 'react';
import { AppButton } from '../AppButton/AppButton';
import {
  authorizeInforDistSXService,
  disconnectInforDistSXService,
} from '../../../redux/services/inforDistSXService';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { X } from 'phosphor-react';
import { COLORS } from '../../../utils/colors';

import InforCSDLogo2 from '../../../assets/images/inforLogo.png';
import Logo from '../../../assets/svg/logo.svg';
import Checks from '../../../assets/svg/checks.svg';
import { getUserDetails } from '../../../redux/services/authService';

enum ViewMode {
  INPUT = 'INPUT',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export interface AppAuthorizeInforDistSXModalProps {
  username: string;
  password: string;
  tenantId: string;
  grantType: string;
  clientId: string;
  clientSecret: string;
  companyNumber: number | null;
  operatorInit: string;
  operatorPassword: string;
}

const INITIAL_STATE = {
  username: '',
  password: '',
  tenantId: '',
  grantType: '',
  clientId: '',
  clientSecret: '',
  companyNumber: null,
  operatorInit: '',
  operatorPassword: '',
};

const ModalContent = ({
  handleClose,
  viewMode,
  formValues,
  setFormValues,
  errorFields,
  isLoading,
  handleSubmit,
  handleDisconnectInforDistSX,
}: {
  handleClose: () => void;
  viewMode: ViewMode;
  formValues: AppAuthorizeInforDistSXModalProps;
  setFormValues: React.Dispatch<React.SetStateAction<AppAuthorizeInforDistSXModalProps>>;
  errorFields: string[];
  isLoading: boolean;
  handleSubmit: () => void;
  handleDisconnectInforDistSX: () => void;
}) => {
  if (viewMode === ViewMode.INPUT) {
    return (
      <>
        <h1 className="font-family['Plus Jakarta Sans'] text-2xl leading-normal font-bold">
          Connect Infor CSD to Dill
        </h1>
        <p className="">You need to authorize this app to access your account</p>
        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col gap-5 h-[500px] overflow-y-auto">
            <AppInputField
              id="clientId"
              isRequired={true}
              errorText={errorFields.includes('clientId') ? 'Client ID is required' : ''}
              label="Client ID"
              placeholder="Client ID"
              inputType="text"
              value={formValues.clientId}
              onTextChange={(value) => setFormValues({ ...formValues, clientId: value })}
            />
            <AppInputField
              id="clientSecret"
              isRequired={true}
              errorText={errorFields.includes('clientSecret') ? 'Client Secret is required' : ''}
              label="Client Secret"
              placeholder="Client Secret"
              inputType="text"
              value={formValues.clientSecret}
              onTextChange={(value) => setFormValues({ ...formValues, clientSecret: value })}
            />
            <AppInputField
              id="username"
              isRequired={true}
              errorText={errorFields.includes('username') ? 'Username is required' : ''}
              label="Username"
              placeholder="Username"
              inputType="text"
              value={formValues.username}
              onTextChange={(value) => setFormValues({ ...formValues, username: value })}
            />
            <AppInputField
              id="password"
              isRequired={true}
              errorText={errorFields.includes('password') ? 'Password is required' : ''}
              label="Password"
              placeholder="Password"
              inputType="password"
              value={formValues.password}
              onTextChange={(value) => setFormValues({ ...formValues, password: value })}
            />
            <AppInputField
              id="tenantId"
              isRequired={true}
              errorText={errorFields.includes('tenantId') ? 'Tenant ID is required' : ''}
              label="Tenant ID"
              placeholder="Tenant ID"
              inputType="text"
              value={formValues.tenantId}
              onTextChange={(value) => setFormValues({ ...formValues, tenantId: value })}
            />
            <AppInputField
              id="grantType"
              isRequired={true}
              errorText={errorFields.includes('grantType') ? 'Grant Type is required' : ''}
              label="Grant Type"
              placeholder="Grant Type"
              inputType="text"
              value={formValues.grantType}
              onTextChange={(value) => setFormValues({ ...formValues, grantType: value })}
            />
            <AppInputField
              id="companyNumber"
              isRequired={true}
              errorText={errorFields.includes('companyNumber') ? 'Company Number is required' : ''}
              label="Company Number"
              placeholder="Company Number"
              inputType="number"
              value={formValues.companyNumber}
              onTextChange={(value) => setFormValues({ ...formValues, companyNumber: value })}
            />
            <AppInputField
              id="operatorInit"
              isRequired={true}
              errorText={errorFields.includes('operatorInit') ? 'Operator Init is required' : ''}
              label="Operator Init"
              placeholder="Operator Init"
              inputType="text"
              value={formValues.operatorInit}
              onTextChange={(value) => setFormValues({ ...formValues, operatorInit: value })}
            />
            <AppInputField
              id="operatorPassword"
              isRequired={true}
              errorText={
                errorFields.includes('operatorPassword') ? 'Operator Password is required' : ''
              }
              label="Operator Password"
              placeholder="Operator Password"
              inputType="text"
              value={formValues.operatorPassword}
              onTextChange={(value) => setFormValues({ ...formValues, operatorPassword: value })}
            />
          </div>
          <div className="flex flex-row gap-4 justify-center">
            <AppButton text="Cancel" type="TERTIARY" onClick={handleClose} />
            <AppButton text="Submit" type="PRIMARY" onClick={handleSubmit} isLoading={isLoading} />
          </div>
        </div>
      </>
    );
  } else if (viewMode === ViewMode.SUCCESS) {
    return (
      <>
        <div className="flex flex-row gap-2 justify-center items-center">
          <img className="h-20 my-4" src={InforCSDLogo2} />
          <img className="h-10 my-4" src={Checks} />
          <img className="h-20 my-4" src={Logo} />
        </div>
        <h1 className="font-family['Plus Jakarta Sans'] text-2xl leading-normal font-bold">
          Authorization successful!
        </h1>
        <div className="flex flex-row gap-4 justify-center mt-20">
          <AppButton text="Back to Dashboard" type="PRIMARY" onClick={handleClose} />
        </div>
      </>
    );
  } else if (viewMode === ViewMode.ERROR) {
    return (
      <>
        <div className="flex flex-row gap-2 justify-center items-center">
          <img className="h-20 my-4" src={InforCSDLogo2} />
          <img className="h-10 my-4" src={Checks} />
          <img className="h-20 my-4" src={Logo} />
        </div>
        <h1 className="font-family['Plus Jakarta Sans'] text-2xl leading-normal font-bold">
          Authorization failed!
        </h1>
        <div className="flex flex-row gap-4 justify-center mt-20">
          <AppButton text="Back to Dashboard" type="PRIMARY" onClick={handleClose} />
        </div>
      </>
    );
  } else if (viewMode === ViewMode.DISCONNECTED) {
    return (
      <>
        <div className="flex flex-row gap-2 justify-center items-center">
          <img className="h-20 my-4" src={InforCSDLogo2} />
          <img className="h-10 my-4" src={Checks} />
          <img className="h-20 my-4" src={Logo} />
        </div>
        <h1 className="font-family['Plus Jakarta Sans'] text-2xl leading-normal font-bold">
          Successfully Disconnected!
        </h1>
        <div className="flex flex-row gap-4 justify-center mt-20">
          <AppButton text="Back to Dashboard" type="PRIMARY" onClick={handleClose} />
        </div>
      </>
    );
  } else if (viewMode === ViewMode.CONNECTED) {
    return (
      <>
        <div className="flex flex-row gap-2 justify-center items-center">
          <img className="h-20 my-4" src={InforCSDLogo2} />
          <img className="h-10 my-4" src={Checks} />
          <img className="h-20 my-4" src={Logo} />
        </div>
        <h1 className="font-family['Plus Jakarta Sans'] text-2xl leading-normal font-bold">
          Connected!
        </h1>
        <div className="flex flex-row gap-4 justify-center mt-20">
          <AppButton text="Back to Dashboard" type="PRIMARY" onClick={handleClose} />
          <AppButton
            text="Disconnect"
            type="TERTIARY"
            onClick={handleDisconnectInforDistSX}
            isLoading={isLoading}
          />
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

const AppAuthorizeInforDistSXModal = ({
  open,
  handleClose = () => {},
  isConnected = false,
}: {
  open: boolean;
  handleClose: () => void;
  isConnected?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const [formValues, setFormValues] = useState<AppAuthorizeInforDistSXModalProps>(
    INITIAL_STATE as AppAuthorizeInforDistSXModalProps
  );
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewMode, setViewMode] = useState(isConnected ? ViewMode.CONNECTED : ViewMode.INPUT);
  const [authSuccess, setAuthSuccess] = useState<boolean | null>(null);

  const verifyInputFields = () => {
    const errors = [];

    if (!formValues.clientId) {
      errors.push('clientId');
    }
    if (!formValues.clientSecret) {
      errors.push('clientSecret');
    }
    if (!formValues.username) {
      errors.push('username');
    }
    if (!formValues.password) {
      errors.push('password');
    }
    if (!formValues.tenantId) {
      errors.push('tenantId');
    }
    if (!formValues.grantType) {
      errors.push('grantType');
    }
    if (!formValues.companyNumber) {
      errors.push('companyNumber');
    }
    if (!formValues.operatorInit) {
      errors.push('operatorInit');
    }
    if (!formValues.operatorPassword) {
      errors.push('operatorPassword');
    }

    return errors;
  };

  const resetFields = () => {
    setFormValues(INITIAL_STATE);
  };

  const resetErrorFields = () => {
    setErrorFields([]);
  };

  const handleSubmit = () => {
    resetErrorFields();

    const errors = verifyInputFields();
    setErrorFields(errors);

    if (errors.length === 0) {
      setIsLoading(true);
      dispatch(
        authorizeInforDistSXService({
          integrationDetails: formValues,
        })
      )
        .then(async (res) => {
          if (res.meta.requestStatus === 'fulfilled') {
            setAuthSuccess(true);
          }

          await dispatch(getUserDetails());
        })
        .finally(() => {
          resetFields();
          setIsLoading(false);
        });
    } else {
      console.log('errorFields', errorFields);
    }
  };

  const doHandleClose = () => {
    setViewMode(ViewMode.INPUT);
    setAuthSuccess(null);
    handleClose();
  };

  const handleDisconnectInforDistSX = () => {
    setIsLoading(true);
    dispatch(disconnectInforDistSXService()).then(async (res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        console.log('disconnectInforDistSXService', res);
      }
      await dispatch(getUserDetails());
      setViewMode(ViewMode.DISCONNECTED);
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (authSuccess) {
      setViewMode(ViewMode.SUCCESS);
    } else if (authSuccess === false) {
      setViewMode(ViewMode.ERROR);
    } else {
      setViewMode(ViewMode.INPUT);
    }
  }, [authSuccess]);

  useEffect(() => {
    if (isConnected === true) {
      setViewMode(ViewMode.CONNECTED);
    }
    console.log(viewMode, 'viewMode');
  }, [isConnected, viewMode]);

  return (
    <Modal
      open={open}
      onClose={doHandleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="quick-books-auth-content text-center">
        <div className="flex flex-row mb-4">
          <AppButton
            type="FLAT"
            buttonStyles={{
              padding: '0px',
              margin: '5px 2px',
              position: 'absolute',
              top: '20px',
              right: '20px',
            }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={doHandleClose}
          />
        </div>
        <ModalContent
          viewMode={viewMode}
          formValues={formValues}
          setFormValues={setFormValues}
          errorFields={errorFields}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          handleClose={doHandleClose}
          handleDisconnectInforDistSX={handleDisconnectInforDistSX}
        />
      </div>
    </Modal>
  );
};

export default AppAuthorizeInforDistSXModal;
