import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { X } from 'phosphor-react';
import { AppButton } from '../AppButton/AppButton';

function AppDateRangePickerModal({
  open,
  handleClose,
  onSelectDateRange,
  selectedDateRange,
}: {
  open: boolean;
  handleClose: () => void;
  onSelectDateRange: (range: { startDate?: Date; endDate?: Date }) => void;
  selectedDateRange: { startDate?: Date; endDate?: Date };
}) {
  const [dateRange, setDateRange] = useState<
    {
      startDate?: Date;
      endDate?: Date;
      key?: string;
    }[]
  >([
    {
      startDate: new Date(),
      endDate: moment().subtract(3, 'months').toDate(),
      key: 'selection',
    },
  ]);
  useEffect(() => {
    if (selectedDateRange) {
      setDateRange([
        {
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          key: 'selection',
        },
      ]);
    }

    return () => {};
  }, [selectedDateRange]);

  const handleSelectDateRange = () => {
    onSelectDateRange({ startDate: dateRange[0]?.startDate, endDate: dateRange[0]?.endDate });
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-80  absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white  flex-col rounded-lg px-4 ">
        <div className="w-full h-full relative pt-8">
          <X className="absolute top-2 right-1 cursor-pointer" size={20} onClick={handleClose} />
          <DateRangePicker
            onChange={(item) => setDateRange([item.selection])}
            moveRangeOnFirstSelection={true}
            months={2}
            fixedHeight={true}
            ranges={dateRange}
            direction="horizontal"
          />
          <div className="flex justify-end mx-3 mb-2">
            <AppButton
              text="Close"
              type="TERTIARY"
              onClick={handleClose}
              buttonStyles={{ marginRight: '8px' }}
            />
            <AppButton text="Done" onClick={handleSelectDateRange} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AppDateRangePickerModal;
