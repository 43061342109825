import { LienStatus } from '@dill/dill-shared';
import {
  CheckCircle,
  ClockClockwise,
  Timer,
  Warning,
  ArrowElbowUpRight,
  ClockAfternoon,
} from 'phosphor-react';
import React from 'react';
import { twMerge } from 'tailwind-merge';

const AppReleaseStatusPill = ({
  status,
  wrapperClassName,
}: {
  status?: LienStatus | null;
  wrapperClassName?: string;
}) => {
  return (
    <div className={twMerge(' w-32', wrapperClassName || '')}>
      <div
        className={twMerge(
          'text-xs capitalize font-bold py-1.5 px-3 rounded-full w-fit text-center flex items-center',
          status === 'Signed'
            ? 'SUCCESS_100-BG SUCCESS_500-CLR'
            : status === 'Requested'
            ? 'GREY_100-BG GREY_500-CLR'
            : status === 'Declined'
            ? 'ERROR_100-BG ERROR_500-CLR'
            : status === 'Rejected'
            ? 'ERROR_100-BG ERROR_500-CLR'
            : status === 'Scheduled'
            ? 'INFO_100-BG INFO_500-CLR'
            : status === 'Pending'
            ? 'WARNING_100-BG WARNING_500-CLR'
            : status === 'Sent to GC'
            ? 'PRIMARY_100-BG PRIMARY_500-CLR'
            : ''
        )}>
        {status === 'Signed' && <CheckCircle size={15} weight="bold" />}
        {status === 'Requested' && <Timer size={15} weight="bold" />}
        {status === 'Declined' && <Warning size={15} weight="bold" />}
        {status === 'Rejected' && <Warning size={15} weight="bold" />}
        {status === 'Pending' && <ClockAfternoon size={15} weight="bold" />}
        {status === 'Scheduled' && <ClockClockwise size={15} weight="bold" />}
        {status === 'Sent to GC' && <ArrowElbowUpRight size={15} weight="bold" />}
        <p className="ml-2"> {status || ''}</p>
      </div>
    </div>
  );
};

export default AppReleaseStatusPill;
