import React, { useEffect, useMemo, useState } from 'react';
import { AppInputField } from '../../../../general/AppInputField/AppInputField';
import SupplierLienWaiversTable from '../SupplierLienWaiversTable/SupplierLienWaiversTable';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { Download, Search } from 'react-feather';
import { COLORS } from '../../../../../utils/colors';
import { downloadPDFsAsZip } from '../../../../../utils/helpers';
import { useAppSelector, useAppDispatch } from '../../../../../redux/store';
import { resetSupplierLiens } from '../../../../../redux/globalSlices/liensSlice';
import { LienApiResponse } from '@dill/dill-shared';

const LienWaivers = () => {
  const dispatch = useAppDispatch();
  const { selectedSupplierLiens, supplierLienReleases } = useAppSelector((state) => state.liens);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(resetSupplierLiens());
    return () => {};
  }, []);

  const searchedReleases: LienApiResponse[] = useMemo(() => {
    let filteredBuyerLienReleases: LienApiResponse[] = [];
    if (supplierLienReleases && searchText) {
      const searched = supplierLienReleases.filter((lienRelease) => {
        if (
          (lienRelease.sender &&
            `${
              lienRelease.sender.subUser?.firstName
                ? lienRelease.sender.subUser?.firstName.trim()
                : lienRelease.sender.user?.firstName
                ? lienRelease.sender.user?.firstName.trim()
                : ''
            } ${
              lienRelease.sender.subUser?.lastName
                ? lienRelease.sender.subUser?.lastName.trim()
                : lienRelease.sender.user?.lastName
                ? lienRelease.sender.user?.lastName.trim()
                : ''
            }`
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase().trim())) ||
          (lienRelease.buyer &&
            lienRelease.buyer.name.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (lienRelease.job &&
            lienRelease.job.name.toString().toLowerCase().includes(searchText.toLowerCase()))
        ) {
          return true;
        }
        return false;
      });
      filteredBuyerLienReleases = searched;
    } else {
      filteredBuyerLienReleases = supplierLienReleases;
    }

    return filteredBuyerLienReleases;
  }, [supplierLienReleases, searchText]);

  const handleDownloadAll = () => {
    const downloadZipFiles = [] as { url: string; name: string }[];
    for (let index = 0; index < selectedSupplierLiens.length; index++) {
      const release = selectedSupplierLiens[index];
      const createdAtTimeStamp = release?.createdAt
        ? new Date(release.createdAt).getTime()
        : new Date().getTime();
      if (release.signedPdf) {
        downloadZipFiles.push({
          url: release.signedPdf.url ?? '',
          name: `${release.job?.name ?? ''}-${release.buyer?.name ?? ''}-${createdAtTimeStamp}`,
        });
      } else {
        downloadZipFiles.push({
          url: release.previewPdf?.url ?? '',
          name: `${release.job?.name ?? ''}-${release.buyer?.name ?? ''}-${createdAtTimeStamp}`,
        });
      }
    }
    downloadPDFsAsZip(downloadZipFiles, 'releases');
  };
  return (
    <div className="h-full p-8 flex ">
      <div className="flex flex-col px-5 py-4 w-full bg-white">
        <div className="py-3 flex justify-between">
          <AppInputField
            inputFieldStyles={{ width: '300px' }}
            id="search"
            value={searchText}
            placeholder={'Search by sender or job'}
            icon={<Search size={15} />}
            onTextChange={(text) => {
              setSearchText(text);
            }}
          />
          {selectedSupplierLiens.length > 0 && (
            <AppButton
              text="Bulk Download"
              type="TERTIARY"
              onClick={handleDownloadAll}
              icon={<Download size={20} color={COLORS.TEXT_SECONDARY} />}
            />
          )}
        </div>
        <SupplierLienWaiversTable releases={searchedReleases} />
      </div>

      {/* <LienWaiverDocumentView /> */}
    </div>
  );
};

export default LienWaivers;
