import React, { useRef, useState, useEffect } from 'react';
import { COLORS } from '../../../utils/colors';
import './AppCustomDropdown.scss';
import { DotsThreeVertical } from 'phosphor-react';
import { AppButton } from '../AppButton/AppButton';
import { useWindowActualWidth } from '../../../utils/domTools';

const AppCustomDropdown = ({
  inputFieldStyles,
  onSelect,
  options,
  width = '200px',
  isDisabled = false,
  isLoading = false,
  open,
  onOpen,
  onClose,
}: {
  inputFieldStyles?: any;
  onSelect: (value: string) => void;
  options: {
    value: string;
    label: string;
    type?: 'error' | 'primary' | 'warning';
    icon?: any;
  }[];
  width?: string;
  isDisabled?: boolean;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  isLoading?: boolean;
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const windowWidth = useWindowActualWidth();
  useEffect(() => {
    const handler = (e: any) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        onClose();
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  useEffect(() => {
    onClose();
  }, [windowWidth]);

  const handleSelectedOption = (e: any, val: string) => {
    e.stopPropagation();
    onSelect(val);
    onClose();
  };

  const renderDropdown = () => {
    return (
      <div className={`flex flex-col ${options.length === 1 ? 'py-0.5' : 'py-3'}`}>
        {options.map((option, index) => {
          return (
            <button
              key={`${index}_dropdownoption`}
              onClick={(e) => handleSelectedOption(e, option.value)}
              className={`w-full flex flex-row py-1.5  px-3 font-medium items-center hover:bg-blue-100 ${
                option.type === 'error'
                  ? 'ERROR_400-CLR'
                  : option.type === 'warning'
                  ? 'WARNING_400-CLR'
                  : option.type === 'primary'
                  ? 'PRIMARY_500-CLR'
                  : 'TEXT_SECONDARY-CLR'
              }`}>
              {option.icon}
              <div className="text-sm ml-2">{option.label}</div>
            </button>
          );
        })}
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col" ref={containerRef}>
      <div
        className="custom-dropdown-container h-fit "
        style={{
          ...inputFieldStyles,
        }}>
        <AppButton
          type="TERTIARY"
          buttonStyles={{
            padding: '8px',
            height: '35px',
            width: '35px',
            margin: '0px 0px',
          }}
          icon={<DotsThreeVertical color={COLORS.GREY_500} size={20} />}
          onClick={(e: any) => {
            e.stopPropagation();
            if (onOpen) {
              onOpen();
            }
          }}
          isDisabled={isDisabled}
          isLoading={isLoading}
        />
        {open && (
          <div style={{ width }} className="custom-dropdown-menu h-fit">
            {renderDropdown()}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppCustomDropdown;
