import React from 'react';
import { Check } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import { twMerge } from 'tailwind-merge';

export const AppCheckBox = ({
  isChecked = false,
  onClick = () => {},
  className = '',
  checkSize = 14,
  isDisabled = false,
}: {
  isChecked?: boolean;
  onClick?: any;
  className?: string;
  checkSize?: number;
  isDisabled?: boolean;
}) => {
  return (
    <div
      className={twMerge(
        'rounded-md h-6 w-6 p-1 flex items-center justify-center border-2  ',
        isDisabled
          ? 'GREY_500-BG'
          : isChecked
          ? ' PRIMARY_500-BG PRIMARY_500-BORDER '
          : ' WHITE-BG ',
        className
      )}
      onClick={() => {
        if (!isDisabled) {
          onClick(!isChecked);
        }
      }}>
      <Check color={COLORS.WHITE} size={checkSize} className={isChecked ? '' : 'hidden'} />
    </div>
  );
};
