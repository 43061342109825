import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import moment from 'moment';
import { ManualUploadInvoicesTable } from '../../invoices/ManualUploadInvoicesTable/ManualUploadInvoicesTable';
import {
  getManualUploadCustomers,
  getManualUploadInvoices,
  getManualUploadJobs,
} from '../../../../redux/services/manualUploadService';
import { InforDistSXInvoicesTable } from '../../invoices/InforDistSXInvoicesTable';
import { DistributionOneInvoicesTable } from '../../invoices/DistributionOneInvoicesTable/DistributionOneInvoicesTable';

const ManualUploadInvoices = () => {
  const dispatch = useAppDispatch();
  const { manualUploadInvoices } = useAppSelector((state) => state.manualUpload);
  const [searchText, setSearchText] = useState('');
  const { user } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (user && user.userSupplier?.integrationType == 'MANUAL_UPLOAD') {
      dispatch(getManualUploadInvoices({ itemsPerPage: 50 }));
      dispatch(getManualUploadCustomers());
      dispatch(getManualUploadJobs());
    }

    return () => {};
  }, []);

  const searchedTableInvoices = useMemo(() => {
    if (manualUploadInvoices && searchText) {
      const searched = manualUploadInvoices.filter((invoice) => {
        if (
          (invoice?.invoiceNumber &&
            invoice?.invoiceNumber.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (invoice?.amount &&
            invoice?.amount.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (invoice?.terms &&
            invoice?.terms.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (invoice?.dueDate &&
            moment(invoice?.dueDate)
              .format('MM/DD/YYYY')
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (invoice?.createdAt &&
            moment(invoice?.createdAt)
              .format('MM/DD/YYYY')
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (invoice?.balance &&
            invoice?.balance.toString().toLowerCase().includes(searchText.toLowerCase()))
        ) {
          return true;
        }
        return false;
      });
      return searched;
    }

    return manualUploadInvoices;
  }, [manualUploadInvoices, searchText]);

  return (
    <div className="w-full h-full">
      <div className="flex h-full flex-col mx-10 mt-3  p-3  pb-6 rounded-lg WHITE-BG  flex-nowrap relative">
        {/* <div className="py-3 flex justify-between">
          <div className="flex items-end">
            <AppInputField
              inputFieldStyles={{ width: '300px' }}
              id="search"
              placeholder={'Search '}
              icon={<Search size={15} />}
              onTextChange={(text) => {
                setSearchText(text);
              }}
            />
          </div>
        </div> */}
        <div className="pb-3" style={{ height: '88%' }}>
          {user && user.userSupplier?.integrationType == 'MANUAL_UPLOAD' && (
            <ManualUploadInvoicesTable invoices={searchedTableInvoices} />
          )}
          {user && user.userSupplier?.integrationType == 'DISTRIBUTION_ONE' && (
            <DistributionOneInvoicesTable />
          )}
          {user && user.userSupplier?.integrationType == 'INFOR_DIST_SX' && (
            <InforDistSXInvoicesTable />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManualUploadInvoices;
