import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import { getDistributionOneInvoices } from '../services/distributionOneService';
import { DistributionOneInitialState } from '../globalSlices/distributionOneSlice';

export const getDistributionOneInvoicesExtraReducer = (
  builder: ActionReducerMapBuilder<DistributionOneInitialState>
) => {
  const reducerId = 'getDistributionOneInvoices';
  builder.addCase(getDistributionOneInvoices.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getDistributionOneInvoices.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      state.distributionOneInvoices = action.payload?.data;
    }
  );
  builder.addCase(getDistributionOneInvoices.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
