import { createSlice } from '@reduxjs/toolkit';

import { SubSupplier, SubSupplierApiResponse } from '@dill/dill-shared';
import {
  createSubSupplierExtraReducer,
  deleteSubSupplierExtraReducer,
  getSubSuppliersExtraReducer,
  resendSubSuppliersInviteExtraReducer,
  updateSubSupplierExtraReducer,
  unarchiveSubSupplierExtraReducer,
} from '../reducers/subSuppliersReducers';

export interface SubSuppliersInitialState {
  loadingList: string[];
  subSuppliers: SubSupplierApiResponse[];
  loadingErrorList: object[];
}

const initialState: SubSuppliersInitialState = {
  loadingList: [],
  subSuppliers: [],
  loadingErrorList: [],
};

export const subSuppliersSlice = createSlice({
  name: 'subSuppliers',
  initialState,
  reducers: {
    resetBSubSuppliersRootState: () => initialState,
  },
  extraReducers(builder) {
    createSubSupplierExtraReducer(builder);
    getSubSuppliersExtraReducer(builder);
    deleteSubSupplierExtraReducer(builder);
    resendSubSuppliersInviteExtraReducer(builder);
    updateSubSupplierExtraReducer(builder);
    unarchiveSubSupplierExtraReducer(builder);
  },
});

export const { resetBSubSuppliersRootState } = subSuppliersSlice.actions;
