import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { Buyer, DocumentsSummary, formatMoney, roundTo } from '@dill/dill-shared';
import emptyListImg from '../../../../assets/images/emptyList.png';
import { AppButton } from '../../../general/AppButton/AppButton';
import { Mail } from 'react-feather';
import { COLORS } from '../../../../utils/colors';
import { sendEmailStatementsForBuyers } from '../../../../redux/services/suppliersService';
import { List, AutoSizer } from 'react-virtualized';
import { openDillToast } from '../../../../utils/helpers';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Info, SortAscending, SortDescending, Note } from 'phosphor-react';
import { Ellipsis } from 'react-css-spinners';
import { twMerge } from 'tailwind-merge';
import { ArrowCircleDown } from '@phosphor-icons/react/dist/ssr';
import { TotalAmount } from '../TotalAmount/TotalAmount';
import CustomerInvoicesModal from '../CustomerInvoicesModal/CustomerInvoicesModal';
import moment from 'moment';
import AppNotesModal from '../../../general/AppNotesModal/AppNotesModal';
import { updateSupplierBuyer } from '../../../../redux/services/supplierBuyersService';

export const CustomersTable = ({
  customers = [],
  infiniteRef,
  hasNextPage = false,
  loading = false,
  onSortChange = () => {},
  sortOptions,
  onLoadMore = () => {},
}: {
  customers: {
    buyer: Buyer;
    usersEmailsDetails: {
      email: string;
      isInvitedUser: boolean;
      isStatementsEmailOptOut: boolean;
      isPaymentRemindersEmailOptOut: boolean;
    }[];
    documentsSummary: DocumentsSummary;
  }[];
  infiniteRef?: any;
  hasNextPage?: boolean;
  loading?: boolean;
  sortOptions: { sortOrder: 'asc' | 'desc'; sortBy: string };
  onSortChange: (props: { sortOrder: 'asc' | 'desc'; sortBy: string }) => void;
  onLoadMore: (loadOptions?: { isFreshFetch?: boolean }) => void;
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { supplierTotalDocumentsSummary } = useAppSelector((state) => state.supplier);
  const { isSupplierSyncing } = useAppSelector((state) => state.generic);
  const [isCustomerInvoicesModalOpen, setIsCustomerInvoicesModalOpen] = useState(false);
  const [selectedBuyer, setSelectedBuyer] = useState<Buyer | null>(null);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [loadingNote, setLoadingNote] = useState(false);

  const columns = useMemo(() => {
    return [
      { id: 'name', name: 'Customer', width: 200, cellType: 'VIEW_TEXT', sortBy: 'name' },

      {
        id: 'totalPastDueOver90Invoices',
        name: 'Past Due 90+',
        width: 150,
        cellType: 'VIEW_TEXT',
        sortBy: 'documentsSummary.totalPastDueOver90Invoices',
      },
      {
        id: 'totalPastDue61To90Invoices',
        name: 'Past Due 61-90',
        width: 150,
        cellType: 'VIEW_TEXT',
        sortBy: 'documentsSummary.totalPastDue61To90Invoices',
      },
      {
        id: 'totalPastDue31To60Invoices',
        name: 'Past Due 31-60',
        width: 150,
        cellType: 'VIEW_TEXT',
        sortBy: 'documentsSummary.totalPastDue31To60Invoices',
      },
      {
        id: 'totalPastDue1To30Invoices',
        name: 'Past Due 1-30',
        width: 150,
        cellType: 'VIEW_TEXT',
        sortBy: 'documentsSummary.totalPastDue1To30Invoices',
      },
      {
        id: 'totalCurrentDueInvoices',
        name: 'Current Due',
        width: 150,
        cellType: 'VIEW_TEXT',
        sortBy: 'documentsSummary.totalCurrentDueInvoices',
      },
      {
        id: 'totalAmountDueInvoices',
        name: 'Amount Due',
        width: 150,
        cellType: 'VIEW_TEXT',
        sortBy: 'documentsSummary.totalAmountDueInvoices',
      },
      { id: 'phoneNumbers', name: 'Phone Numbers', width: 150 },
      { id: 'emails', name: 'Emails', width: 150 },
      { id: 'notes', name: 'Notes', width: 65 },
      { id: 'actions', name: '', width: 150, cellType: 'VIEW_TEXT' },
    ];
  }, []);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  const handleSendEmailStatements = async (buyerIds: string[]) => {
    const results = await dispatch(sendEmailStatementsForBuyers({ buyerIds }));
    if (results.type === 'supplier/sendEmailStatementsForBuyers/fulfilled') {
      openDillToast({ type: 'SUCCESS', message: 'Individual statement sent!' });
      await onLoadMore({ isFreshFetch: true });
    } else {
      const payload = results.payload as any;
      let errorMessage = 'Error sending individual statement!';
      if (payload) {
        errorMessage = payload;
      }
      openDillToast({ type: 'ERROR', message: errorMessage });
    }
  };

  const handleUpdateBuyer = async (val: string) => {
    setLoadingNote(true);
    const resp = await dispatch(
      updateSupplierBuyer({
        supplierBuyerId: selectedBuyer?.id ?? '',
        supplierBuyerDetails: { notes: [{ content: val, date: new Date() }] },
      })
    );
    setLoadingNote(false);
    if (resp.type === 'supplierBuyers/updateSupplierBuyer/fulfilled') {
      setOpenNotesModal(false);
      onLoadMore({ isFreshFetch: true });
      openDillToast({ message: 'Successfully updated buyer', type: 'SUCCESS' });
    } else {
      openDillToast({ message: 'Failed to update buyer', type: 'ERROR' });
    }
  };

  const renderRow = ({ index, key, style }: any) => {
    const buyerSummary = customers[index];
    return (
      <div key={key + 'oo'} style={style} className="w-full flex-row h-full">
        <div
          className="w-full flex  px-4  hover:bg-slate-50 cursor-pointer  h-full  items-center"
          onClick={(e) => {
            if (e.defaultPrevented) return;
            setSelectedBuyer(buyerSummary.buyer);
            setIsCustomerInvoicesModalOpen(true);
          }}>
          {columns.map((column) => {
            return (
              <div
                key={column.id}
                style={{ flex: column.width / totalWidth }}
                className="py-1.5 px-0.5 h-full flex  z-0 hover:z-50 relative items-center "
                onClick={(e) => {
                  // e.preventDefault();
                }}>
                {column.id === 'name' && (
                  <p className="text-sm capitalize">{buyerSummary.buyer.name.toLowerCase()}</p>
                )}
                {column.id === 'customerType' && (
                  <p className="text-sm capitalize">{buyerSummary.buyer?.type || ''}</p>
                )}

                {(column.id === 'totalAmountDueInvoices' ||
                  column.id === 'totalPastDue1To30Invoices' ||
                  column.id === 'totalPastDue31To60Invoices' ||
                  column.id === 'totalPastDue61To90Invoices' ||
                  column.id === 'totalPastDueOver90Invoices' ||
                  column.id === 'totalRemainingCredits') && (
                  <p className="text-sm text-red-500">
                    {buyerSummary.documentsSummary[column.id]
                      ? roundTo(buyerSummary.documentsSummary[column.id] || 0, 2)
                      : ''}
                  </p>
                )}
                {column.id === 'totalCurrentDueInvoices' && (
                  <p className="text-sm ">
                    {buyerSummary.documentsSummary?.totalCurrentDueInvoices
                      ? roundTo(buyerSummary.documentsSummary?.totalCurrentDueInvoices || 0, 2)
                      : ''}
                  </p>
                )}
                {column.id === 'emails' && (
                  <div
                    className="flex flex-col items-center break-words word-wrap border-none 
                    border   absolute hover:m-2 hover:bg-blue-500 
                    hover:border hover:border-blue-50
                    hover:scale-110 hover:h-min hover:w-max h-[90px]
                     hover:absolute  hover:rounded-lg 
                    hover:p-0.5  pb-2 group  top-1/2  transform -translate-y-1/2"
                    onClick={(e) => {
                      e.preventDefault();
                    }}>
                    <div className="flex items-center flex-col h-full justify-center">
                      <div className="overflow-scroll">
                        {buyerSummary?.usersEmailsDetails &&
                          buyerSummary?.usersEmailsDetails.map((emailsDetail, ii) => {
                            let tooltipMessage = '';
                            if (
                              emailsDetail.isStatementsEmailOptOut &&
                              !emailsDetail.isPaymentRemindersEmailOptOut
                            ) {
                              tooltipMessage =
                                'This user was added to the list of opt-outs and will not receive statements';
                            } else if (
                              !emailsDetail.isStatementsEmailOptOut &&
                              emailsDetail.isPaymentRemindersEmailOptOut
                            ) {
                              tooltipMessage =
                                'This user was added to the list of opt-outs and will not receive payment reminders';
                            } else if (
                              emailsDetail.isStatementsEmailOptOut &&
                              emailsDetail.isPaymentRemindersEmailOptOut
                            ) {
                              tooltipMessage =
                                'This user was added to the list of opt-outs for both statements and payment reminders. They will not receive any.';
                            }
                            return (
                              <div key={ii + 'p'} className=" mx-1  my-1 word-wrap">
                                <p
                                  className="text-2xs break-words bg-slate-100 rounded-md w-fit px-1 py-0.5 cursor-pointer flex items-center"
                                  data-tooltip-id={
                                    emailsDetail.isStatementsEmailOptOut ||
                                    emailsDetail.isPaymentRemindersEmailOptOut
                                      ? `optedOut-${ii}`
                                      : undefined
                                  }>
                                  {emailsDetail.email}
                                  {(emailsDetail.isStatementsEmailOptOut ||
                                    emailsDetail.isPaymentRemindersEmailOptOut) && (
                                    <span>
                                      <Info className="ml-0.5 text-red-600" size={16} />
                                    </span>
                                  )}
                                </p>
                                <ReactTooltip
                                  id={`optedOut-${ii}`}
                                  place="right"
                                  variant="dark"
                                  opacity={'100%'}
                                  style={{
                                    background: '#222',
                                    width: '300px',
                                    zIndex: 99999,
                                  }}>
                                  {tooltipMessage && <span className="p-0">{tooltipMessage}</span>}
                                </ReactTooltip>
                              </div>
                            );
                          })}
                      </div>
                      <div className="w-full group-hover:hidden flex justify-center p-0">
                        <ArrowCircleDown className="group-hover:hidden" size={12} />
                      </div>
                    </div>
                  </div>
                )}
                {column.id === 'phoneNumbers' &&
                  buyerSummary?.buyer?.phoneNumbers &&
                  buyerSummary?.buyer?.phoneNumbers.length > 0 && (
                    <div
                      className="flex flex-col items-center break-words word-wrap border-none 
                    border   absolute hover:m-2 hover:bg-blue-500 
                    hover:border hover:border-blue-50
                    hover:scale-110 hover:h-min hover:w-max h-[90px]
                     hover:absolute  hover:rounded-lg 
                    hover:p-0.5  pb-2 group  top-1/2  transform -translate-y-1/2"
                      onClick={(e) => {
                        e.preventDefault();
                      }}>
                      <div className="flex items-center flex-col h-full justify-center">
                        <div className="overflow-scroll">
                          {buyerSummary?.buyer?.phoneNumbers &&
                            buyerSummary?.buyer?.phoneNumbers.map((phoneNumber, ii) => {
                              return (
                                <div key={ii + 'p'} className=" mx-1  my-1 word-wrap">
                                  <p className="text-2xs break-words bg-slate-100 rounded-md w-fit px-1 py-0.5 cursor-pointer flex items-center">
                                    {phoneNumber}
                                  </p>
                                </div>
                              );
                            })}
                        </div>
                        <div className="w-full group-hover:hidden flex justify-center p-0">
                          <ArrowCircleDown className="group-hover:hidden" size={12} />
                        </div>
                      </div>
                    </div>
                  )}
                {column.id === 'notes' && (
                  <div>
                    <div
                      className="relative"
                      data-tooltip-id={`buyer-notes-content-${buyerSummary.buyer.id}`}>
                      <AppButton
                        type="TERTIARY"
                        buttonStyles={{
                          padding: '8px',
                          margin: '5px',
                          height: '30px',
                          width: '30px',
                        }}
                        icon={<Note color={COLORS.GREY_500} size={15} />}
                        onClick={(e: any) => {
                          e.preventDefault();
                          setSelectedBuyer(buyerSummary.buyer);
                          setOpenNotesModal(true);
                        }}
                      />
                      {buyerSummary.buyer?.notes && buyerSummary.buyer.notes?.length > 0 && (
                        <div className="flex absolute -top-[5px] right-[-5px] PRIMARY_500-BG WHITE-CLR rounded-full text-2xs font-bold h-5 w-5 items-center border border-white justify-center">
                          {buyerSummary.buyer?.notes?.length}
                        </div>
                      )}
                    </div>
                    {buyerSummary.buyer?.notes && buyerSummary.buyer?.notes?.length > 0 && (
                      <ReactTooltip
                        id={`buyer-notes-content-${buyerSummary.buyer.id}`}
                        place="top"
                        variant="dark"
                        opacity={'100%'}
                        style={{
                          display: 'flex',
                          background: '#334155',
                          width: '200px',
                          zIndex: '70',
                          borderRadius: '8px',
                          alignItems: 'center',
                          justifyItems: 'center',
                          fontWeight: '600',
                        }}>
                        <span>{`${buyerSummary.buyer?.notes?.[0]?.content}`}</span>
                      </ReactTooltip>
                    )}
                  </div>
                )}
                {column.id === 'actions' && (
                  <div className="flex flex-row">
                    <div className="">
                      {buyerSummary.buyer.lastEmailed && (
                        <p className="text-2xs mb-1">{`Last sent ${moment(
                          buyerSummary.buyer.lastEmailed
                        )
                          .utc()
                          .format('MM/DD/YYYY')}`}</p>
                      )}
                      <AppButton
                        text="Email Statement"
                        buttonStyles={{ fontSize: '0.5em' }}
                        buttonWidth={'100px'}
                        icon={<Mail color={COLORS.WHITE} size={15} />}
                        onClick={(e: any) => {
                          e.preventDefault();
                          handleSendEmailStatements([buyerSummary.buyer.id]);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {index === customers.length - 2 && hasNextPage && !loading && (
          <div ref={infiniteRef} className="w-full flex items-center justify-center">
            <Ellipsis color="#3762FB" size={70} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="w-full h-full flex flex-col  relative">
      {isCustomerInvoicesModalOpen && (
        <CustomerInvoicesModal
          open={isCustomerInvoicesModalOpen}
          handleClose={() => {
            setIsCustomerInvoicesModalOpen(false);
          }}
          selectedBuyer={selectedBuyer}
        />
      )}
      <AppNotesModal
        open={openNotesModal}
        handleClose={() => {
          setOpenNotesModal(false);
        }}
        onSubmit={(value: string) => {
          handleUpdateBuyer(value);
        }}
        title={
          selectedBuyer?.notes && selectedBuyer?.notes?.length > 0 ? 'Update Note' : 'Add Note'
        }
        primaryButtonText={
          selectedBuyer?.notes && selectedBuyer?.notes?.length > 0 ? 'Update' : 'Add'
        }
        value={selectedBuyer?.notes?.[0]?.content}
        loading={loadingNote}
      />
      <div className="w-full h-full flex flex-col   border rounded-lg  relative">
        <div className="w-full flex border-b justify-between px-4 sticky top-0 WHITE-BG z-10">
          {columns.map((column) => {
            return (
              <div
                key={column.id}
                style={{ flex: column.width / totalWidth }}
                className={twMerge('py-3 px-0.5 flex', column.sortBy ? 'cursor-pointer' : '')}
                onClick={() => {
                  if (column.sortBy) {
                    let sortOrder: 'asc' | 'desc' =
                      sortOptions.sortOrder === 'desc' ? 'asc' : 'desc';
                    if (sortOptions && sortOptions.sortBy !== column.sortBy) {
                      sortOrder = 'desc';
                    }
                    onSortChange({ sortBy: column.sortBy, sortOrder: sortOrder });
                  }
                }}>
                <p
                  className={twMerge(
                    'text-xs ',
                    column.sortBy && sortOptions && sortOptions.sortBy === column.sortBy
                      ? 'font-medium'
                      : 'TEXT_SECONDARY-CLR'
                  )}>
                  {column.name}
                </p>
                {column.id === 'emails' && <Info data-tooltip-id={`emailInfo`} />}
                {column.sortBy && sortOptions && sortOptions.sortBy === column.sortBy && (
                  <div>
                    {sortOptions.sortOrder === 'desc' && (
                      <SortAscending color={COLORS.PRIMARY_500} weight="fill" />
                    )}
                    {sortOptions.sortOrder === 'asc' && (
                      <SortDescending color={COLORS.PRIMARY_500} weight="fill" />
                    )}
                  </div>
                )}
                <ReactTooltip
                  id={`emailInfo`}
                  place="right"
                  variant="dark"
                  opacity={'100%'}
                  style={{
                    background: '#222',
                    width: '300px',
                    zIndex: 99999,
                  }}>
                  <span>
                    If you’d like to add or remove an email for a customer, please do so in your
                    accounting system, and any changes will be reflected in the next sync.
                  </span>
                </ReactTooltip>
              </div>
            );
          })}
        </div>

        {customers && customers.length > 0 && (
          <div className="w-full h-full overflow-scroll">
            <AutoSizer className="w-full h-full">
              {({ height, width }) => {
                return (
                  <List
                    className=""
                    width={width}
                    height={height}
                    rowRenderer={renderRow}
                    rowCount={customers.length}
                    rowHeight={90}
                    scrollToColumn={loading ? customers.length - 2 : undefined}
                  />
                );
              }}
            </AutoSizer>
          </div>
        )}

        {customers.length === 0 && !isSupplierSyncing && (
          <div className="w-full h-full flex items-center justify-center">
            <div className="flex flex-col items-center">
              <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
              <p>No customers</p>
            </div>
          </div>
        )}

        {customers.length === 0 && isSupplierSyncing && (
          <div className="w-full h-full flex items-center justify-center">
            <div className="flex flex-col items-center">
              <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
              <p>Syncing Data</p>
            </div>
          </div>
        )}
      </div>
      <div className="w-full flex items-center mt-1  px-4 justify-between GREY_100-BG rounded border  ">
        {columns.map((column) => {
          let value = '';
          if (supplierTotalDocumentsSummary) {
            value =
              supplierTotalDocumentsSummary[
                column.id as keyof typeof supplierTotalDocumentsSummary
              ]?.toString() ?? '';
          }

          return (
            <div
              key={column.id}
              style={{ flex: column.width / totalWidth }}
              className={twMerge('py-3 px-0.5 flex', column.sortBy ? 'cursor-pointer' : '')}>
              {column.id === 'name' && (
                <p className="px-2 text-sm capitalize w-full GREY_500-CLR font-semibold">
                  {'Total'}
                </p>
              )}
              {(column.id === 'totalPastDue1To30Invoices' ||
                column.id === 'totalPastDue31To60Invoices' ||
                column.id === 'totalPastDue61To90Invoices' ||
                column.id === 'totalPastDueOver90Invoices' ||
                column.id === 'totalCurrentDueInvoices' ||
                column.id === 'totalAmountDueInvoices') && (
                <p className="text-sm w-full PRIMARY_500-CLR font-semibold">{`${formatMoney(
                  value,
                  2
                )}`}</p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
