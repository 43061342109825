import { createSlice } from '@reduxjs/toolkit';
import {
  createBuyerLienTemplateExtraReducer,
  createLienTemplateByAdminExtraReducer,
  getLienTemplatesByAdminExtraReducer,
  getLienTemplatesExtraReducer,
  getSupplierLienTemplatesExtraReducer,
} from '../reducers/lienTemplatesReducers';
import { LienTemplate } from '@dill/dill-shared';

export interface LienTemplatesInitialState {
  loadingList: string[];
  lienTemplates: LienTemplate[];
  buyerLienTemplates: LienTemplate[];
  lienTemplatesByAdmin: LienTemplate[];
}

const initialState: LienTemplatesInitialState = {
  loadingList: [],
  lienTemplates: [],
  buyerLienTemplates: [],
  lienTemplatesByAdmin: [],
};

export const lienTemplatesSlice = createSlice({
  name: 'lienTemplates',
  initialState,
  reducers: {
    resetLienTemplatesRootState: () => initialState,
  },
  extraReducers(builder) {
    createLienTemplateByAdminExtraReducer(builder);
    getLienTemplatesExtraReducer(builder);
    createBuyerLienTemplateExtraReducer(builder);
    getLienTemplatesByAdminExtraReducer(builder);
    getSupplierLienTemplatesExtraReducer(builder);
  },
});

export const { resetLienTemplatesRootState } = lienTemplatesSlice.actions;
