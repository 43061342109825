import axios from 'axios';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { BASE_API_URL } from '../../utils/constants';
import { AppAuthorizeInforDistSXModalProps } from '../../components/general/AppAuthorizeInforDistSXModal';
import { myFirebaseAuth } from '../../firebase/config';

export const authorizeInforDistSXService = createAsyncThunk(
  'invoice/inforDistSX/authorizeInforDistSXService',
  async (
    { integrationDetails }: { integrationDetails: AppAuthorizeInforDistSXModalProps },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      const response = await axios.post<any>(
        `${BASE_API_URL}/inforDistSXAPI/authorize`,
        {
          integrationDetails,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const disconnectInforDistSXService = createAsyncThunk(
  'invoice/inforDistSX/disconnectInforDistSXService',
  async () => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      const response = await axios.get<any>(`${BASE_API_URL}/inforDistSXAPI/disconnect`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log(error);
      return error;
    }
  }
);

export const getInforDistSXInvoicesService = createAsyncThunk(
  'invoice/inforDistSX/getInforDistSXInvoicesService',
  async (
    {
      startAfter,
      itemsPerPage,
      invoiceType,
    }: {
      startAfter?: string | null;
      itemsPerPage?: number;
      invoiceType?: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();

      const queryParams = [];
      if (startAfter) {
        queryParams.push(`startAfter=${startAfter}`);
      }
      if (itemsPerPage) {
        queryParams.push(`itemsPerPage=${itemsPerPage}`);
      }
      if (invoiceType) {
        queryParams.push(`invoiceType=${invoiceType}`);
      }
      const response = await axios.get<any>(
        `${BASE_API_URL}/inforDistSXAPI/invoice?${queryParams.join('&')}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const deleteInforDistSXSupplierInvoice = createAsyncThunk(
  'invoice/inforDistSX/deleteSupplierInvoice',
  async ({ invoiceId, invoiceType }: { invoiceId: string; invoiceType: string }) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      const response = await axios.delete<any>(
        `${BASE_API_URL}/inforDistSXAPI/invoice/${invoiceId}?invoiceType=${invoiceType}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return error;
    }
  }
);
