import { useEffect, useState, useRef, useCallback, ReactNode } from 'react';
import { COLORS } from '../../../utils/colors';
import { twMerge } from 'tailwind-merge';
import { Ellipsis } from 'react-css-spinners';

interface Props {
  fetchData: () => void;
  renderList: (ref: React.RefCallback<HTMLElement>) => ReactNode;
  loading: boolean;
  hasMore: boolean;
}
export const AppInfiniteScroll: React.FC<Props> = ({ fetchData, renderList, loading, hasMore }) => {
  const [query, setQuery] = useState('');
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (loading) return;
      if (observer?.current) observer?.current?.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer?.current?.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <>
      {renderList(lastElementRef)}
      {loading && (
        <div className="w-full flex items-center justify-center">
          <div className={twMerge('w-fit flex flex-col items-center justify-center rounded-lg ')}>
            <Ellipsis color="#3762FB" size={40} />
          </div>
        </div>
      )}
    </>
  );
};
