import { Modal } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { AppButton } from '../AppButton/AppButton';
import './AppRangeCalender.scss';
import { COLORS } from '../../../utils/colors';
import moment from 'moment';
import AppDatePicker from '../AppDatePicker/AppDatePicker';

export const AppRangeCalender = ({
  open,
  handleClose,
  onSelectDate,
  dateRange,
}: {
  open: boolean;
  handleClose: () => void;
  onSelectDate: (range: { start: Date; finish: Date }) => void;
  dateRange: { start: string; finish: string };
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [finishDate, setFinishDate] = useState<Date | null>(null);

  const handleStartDate = (value: Date) => {
    setStartDate(value);
    if (!finishDate) {
      const year = value.getFullYear();
      const month = value.getMonth();
      const lastDayOfMonth = new Date(year, month + 1, 0);
      setFinishDate(lastDayOfMonth);
    }
  };

  const handleFinishDate = (value: Date) => {
    setFinishDate(value);
  };

  useEffect(() => {
    if (startDate && finishDate && !open) {
      const rangeDate = {
        start: startDate,
        finish: finishDate,
      };
      onSelectDate(rangeDate);
    }
    if (open && dateRange) {
      setStartDate(new Date(dateRange.start));
      setFinishDate(new Date(dateRange.finish));
    }
  }, [open]);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="range-calender-success-modal-content">
        <h1 className="range-calender-header">Select Range</h1>
        <div>
          <div className="range-date-container mb-2">
            <div className="full">
              <AppDatePicker
                label={'Start'}
                onDateSelected={(date) => {
                  if (date) {
                    handleStartDate(date);
                  }
                }}
                selectedDate={startDate || null}
                dateFormat="MMM yyyy"
                showMonthYearPicker={true}
                maximumDate={finishDate || null}
                onDateSelect={(date, event) => event.stopPropagation()}
              />
            </div>
            <div className="full ml-4">
              <AppDatePicker
                label="Finish"
                onDateSelected={(date) => {
                  if (date) {
                    const year = date.getFullYear();
                    const month = date.getMonth();
                    const lastDayOfMonth = new Date(year, month + 1, 0);
                    handleFinishDate(lastDayOfMonth);
                  }
                }}
                selectedDate={finishDate || null}
                dateFormat="MMM yyyy"
                showMonthYearPicker={true}
                minumumDate={startDate || null}
                onDateSelect={(date, event) => event.stopPropagation()}
              />
            </div>
          </div>
        </div>

        <AppButton
          text="Close"
          type="TERTIARY"
          buttonStyles={{
            marginLeft: '10px',
            marginRight: '10px',
          }}
          onClick={handleClose}
        />
      </div>
    </Modal>
  );
};
