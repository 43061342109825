import React, { useEffect, useMemo, useState } from 'react';
import { RefreshCcw, Search } from 'react-feather';
import { getSupplierSummary, getUserCustomers } from '../../../../redux/services/suppliersService';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { AppInputField } from '../../../general/AppInputField/AppInputField';
import { CustomersTable } from '../CustomersTable/CustomersTable';
import { useLocation } from 'react-router-dom';
import AppLastSyncDataView from '../../../general/AppLastSyncDataView/AppLastSyncDataView';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { Buyer, DocumentsSummary } from '@dill/dill-shared';
import { TotalAmount } from '../TotalAmount/TotalAmount';

export const Customers = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchText, setSearchText] = useState('');
  const { isSupplierSyncing } = useAppSelector((state) => state.generic);
  const { user, firebaseUserId } = useAppSelector((state) => state.auth);
  const mainRoles = user?.roles.filter((role) => role !== 'DILL_ADMIN');
  const [currentCustomers, setCurrentCustomers] = useState<
    {
      buyer: Buyer;
      usersEmailsDetails: {
        email: string;
        isInvitedUser: boolean;
        isStatementsEmailOptOut: boolean;
        isPaymentRemindersEmailOptOut: boolean;
      }[];
      documentsSummary: DocumentsSummary;
    }[]
  >([]);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [previousLastDocumentId, setPreviousLastDocumentId] = useState('');
  const [customerCount, setCustomerCount] = useState(0);
  const [sortOptions, setSortOptions] = useState<{ sortOrder: 'asc' | 'desc'; sortBy: string }>({
    sortOrder: 'asc',
    sortBy: 'name',
  });

  const searchedCustomers = useMemo(() => {
    if (currentCustomers && searchText) {
      const searched = currentCustomers.filter((customerDetails) => {
        if (
          customerDetails.buyer.name &&
          customerDetails.buyer?.name.toString().toLowerCase().includes(searchText.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      return searched;
    }

    return currentCustomers;
  }, [currentCustomers, searchText]);

  useEffect(() => {
    setPreviousLastDocumentId('');
    return () => {};
  }, []);

  const handleLoadMore = async (loadOptions?: { isFreshFetch?: boolean }) => {
    setLoading(true);
    const pageSize = 100;
    let currentPreviousLastDocumentId = previousLastDocumentId;
    let customersList = currentCustomers;
    if (loadOptions && loadOptions?.isFreshFetch) {
      currentPreviousLastDocumentId = '';
      customersList = [];
    }
    const results = await dispatch(
      getUserCustomers({
        previousLastDocumentId: currentPreviousLastDocumentId,
        pageSize,
        sortBy: sortOptions.sortBy,
        sortOrder: sortOptions.sortOrder,
      })
    );
    setLoading(false);
    if (results.type === 'supplier/getUserCustomers/fulfilled') {
      if (results.payload) {
        const payload = results.payload as any;
        const cust: {
          buyer: Buyer;
          usersEmailsDetails: {
            email: string;
            isInvitedUser: boolean;
            isStatementsEmailOptOut: boolean;
            isPaymentRemindersEmailOptOut: boolean;
          }[];
          documentsSummary: DocumentsSummary;
        }[] = payload.data as any;
        const lastDockId = payload.lastDocumentId;
        if (!customerCount && payload.customerCount) {
          setCustomerCount(payload.customerCount || 0);
        }
        console.log({ lastDockId, previousLastDocumentId });
        if (!lastDockId) {
          setHasNextPage(false);
        } else if (lastDockId !== currentPreviousLastDocumentId) {
          setCurrentCustomers([...customersList, ...cust]);
          setPreviousLastDocumentId(lastDockId);
          console.log('--------');
          if (cust.length === pageSize) {
            setHasNextPage(true);
          } else {
            setHasNextPage(false);
          }
        } else {
          setHasNextPage(false);
        }
      }
    }
  };

  useEffect(() => {
    if (mainRoles?.includes('SUPPLIER') && firebaseUserId) {
      setCurrentCustomers([]);
      setPreviousLastDocumentId('');
      handleLoadMore({ isFreshFetch: true });
    }

    return () => {};
  }, [user?.id, firebaseUserId, location.pathname, sortOptions]);

  useEffect(() => {
    if (mainRoles?.includes('SUPPLIER') && firebaseUserId) {
      dispatch(getSupplierSummary());
    }

    return () => {};
  }, [user?.id, firebaseUserId, location.pathname]);

  const [infiniteRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <div className="w-full h-full">
      <div className="flex flex-col mx-10 mt-4  p-3  pb-6 rounded-lg WHITE-BG  h-5/6 relative">
        <div className="absolute right-2 top-2 flex items-center">
          <AppLastSyncDataView dataKey="customers" from="supplier" />
        </div>

        <div className="py-3 flex justify-end">
          {/* <AppInputField
            inputFieldStyles={{ width: '300px' }}
            id="search"
            placeholder={'Search '}
            icon={<Search size={15} />}
            onTextChange={(text) => {
              setSearchText(text);
            }}
          /> */}

          <div className="flex flex-row mt-4 ">
            {currentCustomers.length > 0 && (
              <p className="text-xs ml-2 whitespace-nowrap">
                <span className="font-semibold ">{customerCount}</span>
                <span className="ml-1">Customers</span>
              </p>
            )}
            {isSupplierSyncing && <p className="text-xs ml-2">Syncing Data</p>}
          </div>
        </div>
        <div className="pb-3" style={{ height: '95%' }}>
          <CustomersTable
            customers={searchedCustomers}
            infiniteRef={infiniteRef}
            hasNextPage={hasNextPage}
            loading={loading}
            sortOptions={sortOptions}
            onSortChange={({ sortBy, sortOrder }) => {
              setSortOptions({ sortBy, sortOrder });
            }}
            onLoadMore={handleLoadMore}
          />
        </div>
      </div>
    </div>
  );
};
