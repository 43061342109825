import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { AuthInitialState } from '../globalSlices/authSlice';
import {
  getUserDetails,
  logoutUser,
  resetPassword,
  firebaseSendLoginEmail,
  firebaseConfirmLoginUser,
  checkUserExists,
  getPlaidLinkToken,
  firebaseLoginEmailAndPassword,
  updateLoggedInUserPassword,
  firebaseConfirmPasswordReset,
  getUserByBuyers,
  referBuyerUsers,
  referSubSupplierUsers,
  updateSubUser,
  checkUserIsSetupWithCompany,
  getStripePublishableKey,
  getRutterPublicKey,
  processRutterPublicToken,
  firebaseSendPasswordResetEmail,
  updateUserDetails,
  createUserSignature,
  deleteUserSignature,
  getUserSignatures,
  newUserNotifyDill,
} from '../services/authService';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';

export const firebaseSendLoginEmailExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'firebaseSendLoginEmail';
  builder.addCase(firebaseSendLoginEmail.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(firebaseSendLoginEmail.fulfilled, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
  builder.addCase(firebaseSendLoginEmail.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.error = action.payload;
  });
};
export const firebaseSendPasswordResetEmailExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'firebaseSendPasswordResetEmail';
  builder.addCase(firebaseSendPasswordResetEmail.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(firebaseSendPasswordResetEmail.fulfilled, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
  builder.addCase(firebaseSendPasswordResetEmail.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.error = action.payload;
  });
};
export const firebaseLoginEmailAndPasswordExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'firebaseLoginEmailAndPassword';
  builder.addCase(firebaseLoginEmailAndPassword.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(firebaseLoginEmailAndPassword.fulfilled, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
  builder.addCase(firebaseLoginEmailAndPassword.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.error = action.payload;
  });
};
export const updateLoggedInUserPasswordExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'updateLoggedInUserPassword';
  builder.addCase(updateLoggedInUserPassword.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(updateLoggedInUserPassword.fulfilled, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
  builder.addCase(updateLoggedInUserPassword.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.error = action.payload;
  });
};
export const firebaseConfirmLoginUserExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'firebaseConfirmLoginUser';
  builder.addCase(firebaseConfirmLoginUser.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.isValidatingUser = true;
  });
  builder.addCase(firebaseConfirmLoginUser.fulfilled, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.isValidatingUser = false;
  });
  builder.addCase(firebaseConfirmLoginUser.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.isValidatingUser = false;
    state.error = action.payload;
  });
};

export const getUserDetailsExtraReducer = (builder: ActionReducerMapBuilder<AuthInitialState>) => {
  const reducerId = 'getUserDetails';
  builder.addCase(getUserDetails.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.user = null;
  });
  builder.addCase(getUserDetails.fulfilled, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload?.success) {
      state.user = action.payload.data;
    } else {
      state.user = null;
      localStorage.removeItem('firebaseToken');
    }
  });
  builder.addCase(getUserDetails.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.user = null;
    localStorage.removeItem('firebaseToken');
  });
};
export const logoutUserExtraReducer = (builder: ActionReducerMapBuilder<AuthInitialState>) => {
  const reducerId = 'logoutUser';
  builder.addCase(logoutUser.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(logoutUser.fulfilled, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.user = null;
    localStorage.removeItem('firebaseToken');
  });
  builder.addCase(logoutUser.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.error = action.payload;
    localStorage.removeItem('firebaseToken');
  });
};
export const resetPasswordExtraReducer = (builder: ActionReducerMapBuilder<AuthInitialState>) => {
  const reducerId = 'resetPassword';
  builder.addCase(resetPassword.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(resetPassword.fulfilled, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.user = null;
  });
  builder.addCase(resetPassword.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    //   state.error = action.payload;
  });
};
export const firebaseConfirmPasswordResetExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'firebaseConfirmPasswordReset';
  builder.addCase(firebaseConfirmPasswordReset.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(firebaseConfirmPasswordReset.fulfilled, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.user = null;
  });
  builder.addCase(firebaseConfirmPasswordReset.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    //   state.error = action.payload;
  });
};

export const checkUserExistsExtraReducer = (builder: ActionReducerMapBuilder<AuthInitialState>) => {
  const reducerId = 'checkUserExists';
  builder.addCase(checkUserExists.pending, (state, action) => {
    state.userCheckState = '';
    state.userCheckRoles = [];
    state.isPasswordSet = false;
    state.isEmailVerified = false;
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(checkUserExists.fulfilled, (state, action: PayloadAction<AppServerResponse>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload && action.payload?.success) {
      if (action.payload.data.isPasswordSet) {
        state.isPasswordSet = true;
      } else {
        state.isPasswordSet = false;
      }
      if (action.payload.data.isEmailVerified) {
        state.isEmailVerified = true;
      } else {
        state.isEmailVerified = false;
      }
      if (
        action.payload?.data?.roles &&
        action.payload?.data?.roles.length === 1 &&
        action.payload?.data?.roles[0] === 'DRIVER'
      ) {
        state.userCheckState = 'DRIVERS_NOT_ALLOWED';
      } else {
        state.userCheckState = 'USER_EXISTS';
      }
      if (action.payload?.data?.roles && action.payload?.data?.roles.length > 0) {
        state.userCheckRoles = action.payload?.data?.roles;
      }
    } else {
      console.log('ff');
      state.userCheckState = 'NEW_USER';
    }
  });
  builder.addCase(checkUserExists.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.error = action.payload;
  });
};
export const getPlaidLinkTokenExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'getPlaidLinkToken';
  builder.addCase(getPlaidLinkToken.pending, (state, action) => {
    state.userCheckState = '';
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getPlaidLinkToken.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload?.success) {
        state.plaidTokenDetails = action.payload.data;
      }
    }
  );
  builder.addCase(getPlaidLinkToken.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.error = action.payload;
  });
};

export const getUserBuyersExtraReducer = (builder: ActionReducerMapBuilder<AuthInitialState>) => {
  const reducerId = 'getUserBuyers';
  builder.addCase(getUserByBuyers.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(getUserByBuyers.fulfilled, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload?.success && action.payload.data) {
      state.userBuyers = action.payload.data;
    }
  });
  builder.addCase(getUserByBuyers.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.userBuyers = [];
  });
};
export const referBuyerUsersExtraReducer = (builder: ActionReducerMapBuilder<AuthInitialState>) => {
  const reducerId = 'referBuyerUsers';
  builder.addCase(referBuyerUsers.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    referBuyerUsers.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(referBuyerUsers.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.error = action.payload;
  });
};

export const referSubSupplierUsersExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'referSubSupplierUsers';
  builder.addCase(referSubSupplierUsers.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    referSubSupplierUsers.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(referSubSupplierUsers.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.error = action.payload;
  });
};

export const updateSubUserExtraReducer = (builder: ActionReducerMapBuilder<AuthInitialState>) => {
  const reducerId = 'updateSubUser';
  builder.addCase(updateSubUser.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    updateSubUser.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(updateSubUser.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const checkUserIsSetupWithCompanyExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'checkUserIsSetupWithCompany';
  builder.addCase(checkUserIsSetupWithCompany.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    checkUserIsSetupWithCompany.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(checkUserIsSetupWithCompany.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const getStripePublishableKeyExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'getStripePublishableKey';
  builder.addCase(getStripePublishableKey.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getStripePublishableKey.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload?.data.publishableKey) {
        state.stripePublishableKey = action.payload?.data.publishableKey;
      }
    }
  );
  builder.addCase(getStripePublishableKey.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getRutterPublicKeyExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'getRutterPublicKey';
  builder.addCase(getRutterPublicKey.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getRutterPublicKey.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload?.data.publicKey) {
        state.rutterPublicKey = action.payload?.data.publicKey;
      }
    }
  );
  builder.addCase(getRutterPublicKey.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const processRutterPublicTokenExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'processRutterPublicToken';
  builder.addCase(processRutterPublicToken.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    processRutterPublicToken.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(processRutterPublicToken.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const updateUserDetailsExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'updateUserDetails';
  builder.addCase(updateUserDetails.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    updateUserDetails.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(updateUserDetails.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getUserSignaturesExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'getUserSignatures';
  builder.addCase(getUserSignatures.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(getUserSignatures.fulfilled, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload?.success && action.payload.data) {
      state.userSignatures = action.payload.data;
    }
  });
  builder.addCase(getUserSignatures.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.userSignatures = [];
  });
};

export const createUserSignatureExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'createUserSignature';
  builder.addCase(createUserSignature.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createUserSignature.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(createUserSignature.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const deleteUserSignatureExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'deleteUserSignature';
  builder.addCase(deleteUserSignature.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    deleteUserSignature.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(deleteUserSignature.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const newUserNotifyDillExtraReducer = (
  builder: ActionReducerMapBuilder<AuthInitialState>
) => {
  const reducerId = 'newUserNotifyDill';
  builder.addCase(newUserNotifyDill.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    newUserNotifyDill.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(newUserNotifyDill.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
