import { Modal } from '@mui/material';
import React, { useState } from 'react';
import { AppButton } from '../AppButton/AppButton';
import { X } from 'react-feather';
import { FileText } from 'phosphor-react';
import { COLORS } from '../../../utils/colors';
import './AppPdfListModal.scss';
import moment from 'moment';

interface SignedPdf {
  name: string;
  url: string;
  date: Date;
}

export const AppPdfListModal = ({
  open,
  handleClose,
  pdfList,
  invoice,
  handleDownloadPdf,
  pdfType = 'INVOICE',
  buttonText = 'Download',
}: {
  open: boolean;
  handleClose: () => void;
  pdfList: SignedPdf[];
  invoice: string;
  pdfType?: 'INVOICE' | 'SALES_RECEIPT';
  handleDownloadPdf: (url: string) => void;
  buttonText?: string;
}) => {
  const [selectedPdf, setSelectedPdf] = useState(null);

  const onChangeValue = (event: any) => {
    setSelectedPdf(event.target.value);
  };
  const handleCloseModal = () => {
    setSelectedPdf(null);
    handleClose();
  };
  const handleDownload = () => {
    if (selectedPdf) {
      handleDownloadPdf(selectedPdf);
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="pdf-dowload-modal-content">
        <div className="title-container">
          <div className="title-text">
            Download {pdfType === 'SALES_RECEIPT' ? 'Sales Receipt' : 'Invoice'}
          </div>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={handleCloseModal}
          />
        </div>
        <div className="content">
          <div className="content-title">
            Select {pdfType === 'SALES_RECEIPT' ? 'a sales receipt' : 'an invoice'}
          </div>
          <div onChange={onChangeValue}>
            {pdfList.map((item, i) => (
              <label className="tile-label" key={`${invoice}_${i}`}>
                <input
                  type="radio"
                  name={item.name}
                  value={item.url}
                  checked={item.url === selectedPdf}
                />
                <div className="tile-container">
                  <div className="tile-content">
                    <div className="tile-radio-button">
                      {item.url !== selectedPdf ? (
                        <div className="tile-radio-icon" />
                      ) : (
                        <div className="selected-tile-radio-icon">
                          <div className="selected-tile-radio-icon-check" />
                        </div>
                      )}
                    </div>
                    <div className="tile-icon">
                      <FileText color={COLORS.PRIMARY_500} size={30} />
                    </div>
                    <div>
                      <div className="tile-title">
                        {`${pdfType === 'SALES_RECEIPT' ? 'Sales Receipt' : 'Invoice'} #${invoice}`}
                      </div>
                      <div className="last-update">
                        <span>Signed </span>
                        {moment(item.date).format('MM/DD LT')}
                      </div>
                    </div>
                  </div>
                  {i === 0 && <div className="newest-pdf">New</div>}
                </div>
              </label>
            ))}
          </div>
        </div>
        <div className="action-buttons">
          <AppButton
            text={buttonText}
            buttonStyles={{ marginLeft: '10px' }}
            onClick={handleDownload}
            isDisabled={selectedPdf === null}
          />
          <AppButton
            text={'Close'}
            type="TERTIARY"
            onClick={handleCloseModal}
            buttonStyles={{ marginRight: '10px' }}
          />
        </div>
      </div>
    </Modal>
  );
};
