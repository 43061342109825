import { Modal } from '@mui/material';
import { useMemo, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { AppButton } from '../AppButton/AppButton';
import emptyListImg from '../../../assets/images/emptyList.png';
import { X, ChevronDown, Download } from 'react-feather';
import {
  Timer,
  CheckCircle,
  Warning,
  ArrowRight,
  ArrowDownLeft,
  ArrowURightDown,
  CaretUp,
  CaretDown,
} from 'phosphor-react';
import './AppPaidInvoicesModal.scss';
import { COLORS } from '../../../utils/colors';
import { InvoiceType } from '../../modules/payments/BuyerPaymentTable/BuyerPaymentTable';
import {
  DillTransaction,
  InvoicePaymentDetails,
  formatMoney,
  getNextBusinessDay,
  Supplier,
} from '@dill/dill-shared';
import AppTransactionStatusPill from '../AppTransactionStatusPill/AppTransactionStatusPill';
import { useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useParamMainBuyerId } from '../../../utils/hooks/useParamMainBuyerId';
import {
  downloadInvoicePdfReceipt,
  downloadMultiInvoicePdfZip,
  getInvoicePdf,
} from '../../../redux/services/buyersService';
import { getFirstLetters, openDillToast } from '../../../utils/helpers';

export const AppPaidInvoicesModal = ({
  open,
  handleClose,
  selectedInvoices,
  paymentTo = '',
  paymentFrom = '',
  status = '',
  accountType = 'supplier',
  transactionError,
  paymentType,
  transactionAmount,
  partialPaymentReason,
  selectedTransaction = null,
  selectedDate,
  selectedPaymentName,
}: {
  open: boolean;
  handleClose?: () => void;
  status?: string;
  selectedInvoices: InvoicePaymentDetails[];
  paymentTo?: string | undefined;
  paymentFrom?: string | undefined;
  accountType?: 'supplier' | 'buyer';
  transactionError?: string | undefined | null;
  paymentType: string;
  transactionAmount: number;
  partialPaymentReason?: string | undefined | null;
  selectedTransaction?: DillTransaction | null;
  selectedDate: string;
  selectedPaymentName: string;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedMainBuyer, selectedBuyerSuppliers } = useAppSelector((state) => state.buyers);
  const { user } = useAppSelector((state) => state.auth);
  const [showMenu, setShowMenu] = useState(false);
  const [openDiscountDropDown, setOpenDiscountDropDown] = useState(false);
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (e: any) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);
  const columns = useMemo(() => {
    let currentColumns = [];
    if (selectedTransaction?.type === 'WILL_CALL_PAYMENT') {
      currentColumns = [
        { id: 'invoiceNumber', name: 'Invoice #', width: 130, cellType: 'VIEW_TEXT' },
        { id: 'amountPaid', name: 'Payment', width: 120, cellType: 'VIEW_TEXT' },
      ];
    } else {
      currentColumns = [
        { id: 'invoiceNumber', name: 'Invoice #', width: 130, cellType: 'VIEW_TEXT' },
        { id: 'job', name: 'Job', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'dueDate', name: 'Due Date', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'openAmount', name: 'Open Amount', width: 150, cellType: 'VIEW_TEXT' },
        { id: 'amountPaid', name: 'Payment', width: 150, cellType: 'VIEW_TEXT' },
        { id: 'actions', name: '', width: 50, cellType: 'VIEW_TEXT' },
      ];
    }
    return currentColumns;
  }, [selectedInvoices, selectedTransaction]);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );
  const error = useMemo(() => {
    if (transactionError) {
      const errorResult = transactionError.split('__');
      return errorResult;
    }
    return [];
  }, [transactionError]);

  const selectedSupplier = useMemo(() => {
    const newSupplier: Supplier | null = null;
    if (selectedMainBuyer?.syncSupplierId && accountType === 'buyer') {
      const supplierId = selectedMainBuyer?.syncSupplierId;

      const supplier: Supplier | undefined = selectedBuyerSuppliers.find(
        (item: Supplier) => item.id === supplierId
      );
      return supplier;
    }
    if (user?.userSupplier && accountType === 'supplier') {
      return user?.userSupplier;
    }
    return newSupplier;
  }, [selectedBuyerSuppliers, open, selectedMainBuyer, user]);

  const fees = useMemo(() => {
    const total = selectedInvoices.reduce((accumulator: number, currentValue: any) => {
      return accumulator + currentValue?.amountPaid || 0;
    }, 0);
    return Number(transactionAmount) - Number(total);
  }, [selectedInvoices, transactionAmount]);
  const creditAmount = useMemo(() => {
    if (
      selectedTransaction &&
      selectedTransaction.linkedCreditsTransactions &&
      selectedTransaction?.linkedCreditsTransactions?.length > 0
    ) {
      const total = selectedTransaction?.linkedCreditsTransactions.reduce(
        (accumulator: number, currentValue: any) => {
          return accumulator + currentValue?.totalAmountPaid || 0;
        },
        0
      );
      return total;
    }
    return 0;
  }, [selectedTransaction]);

  const invoices = useMemo(() => {
    if (selectedTransaction && selectedTransaction.invoicePayments) {
      if (
        selectedTransaction.linkedCreditsTransactions &&
        selectedTransaction?.linkedCreditsTransactions?.length > 0
      ) {
        let result = [] as any;
        for (let i = 0; i < selectedTransaction?.linkedCreditsTransactions.length; i++) {
          const linkedInvoices = selectedTransaction?.linkedCreditsTransactions[i].invoicePayments;
          const combinedArray = linkedInvoices.map((item, index) => {
            const matchingItem = selectedInvoices.find(
              (element) => element.quickbooksInvoiceId === item.quickbooksInvoiceId
            );
            const amount = item.amountPaid + (matchingItem?.amountPaid || 0);
            return { ...item, amountPaid: amount };
          });
          result = result.concat(combinedArray);
        }

        return result;
      }

      return selectedTransaction.invoicePayments;
    }
    return [];
  }, [selectedTransaction, open]);

  const filteredInvoice = useMemo(() => {
    return invoices?.filter((item: any) => !item?.recordType || item.recordType === 'INVOICE');
  }, [invoices]);

  const fullCreditPay = useMemo(() => {
    if (selectedTransaction?.type === 'CREDIT_PAYMENT') {
      const total = selectedInvoices.reduce((accumulator: number, currentValue: any) => {
        return accumulator + currentValue?.amountPaid || 0;
      }, 0);
      return total;
    }
    return 0;
  }, [selectedInvoices, selectedTransaction, open]);

  const discount = useMemo(() => {
    if (selectedTransaction?.type === 'PAYMENT') {
      const total = selectedInvoices.reduce((accumulator: number, currentValue: any) => {
        return accumulator + currentValue?.discountAmount || 0;
      }, 0);
      return total;
    }
    return 0;
  }, [selectedInvoices, selectedTransaction, open]);

  const supplierFees = useMemo(() => {
    if (
      selectedTransaction?.linkedFacilitationFeeTransactions &&
      selectedTransaction?.linkedFacilitationFeeTransactions.length > 0
    ) {
      const filterFeeTransaction = selectedTransaction?.linkedFacilitationFeeTransactions.filter(
        (item) => item.type === 'DILL_FACILITATION_FEE_PAYMENT_SUPPLIER'
      );
      const total = filterFeeTransaction.reduce((accumulator: number, currentValue: any) => {
        return accumulator + currentValue.totalAmountPaid;
      }, 0);
      return total;
    }
    return 0;
  }, [selectedTransaction, open]);
  const buyerFees = useMemo(() => {
    if (
      selectedTransaction?.linkedFacilitationFeeTransactions &&
      selectedTransaction?.linkedFacilitationFeeTransactions.length > 0
    ) {
      const filterFeeTransaction = selectedTransaction?.linkedFacilitationFeeTransactions.filter(
        (item) => item.type === 'DILL_FACILITATION_FEE_PAYMENT_BUYER'
      );
      const total = filterFeeTransaction.reduce((accumulator: number, currentValue: any) => {
        return accumulator + currentValue.totalAmountPaid;
      }, 0);
      return total;
    }
    return 0;
  }, [selectedTransaction, open]);
  const handleShowDropdown = () => {
    setShowMenu(!showMenu);
  };
  const handleViewRelease = (val: string) => {
    const releasePath =
      accountType === 'supplier'
        ? `/lienReleases?releaseId=${val}`
        : `/buyerLienReleases/${selectedMainBuyerId}/overview?releaseId=${val}`;
    const viewReleaseTab = window.open(releasePath, '_blank');
    if (viewReleaseTab) {
      viewReleaseTab.focus();
    }
    setShowMenu(!showMenu);
  };

  const handleDownloadPdf = async (invoiceId: string) => {
    if (selectedMainBuyer?.syncSupplierId || user?.userSupplier?.id) {
      const res = await dispatch(
        getInvoicePdf({
          supplierId:
            accountType === 'supplier'
              ? user?.userSupplier?.id || ''
              : selectedMainBuyer?.syncSupplierId,
          invoiceId,
        })
      );
      if (res && res.type === 'buyers/getInvoicePdf/fulfilled') {
        openDillToast({ message: 'invoice pdf successfully downloaded', type: 'SUCCESS' });
      } else {
        openDillToast({ message: 'Failed to download invoice pdf', type: 'ERROR' });
      }
    } else {
      openDillToast({ message: 'Failed to get supplier details', type: 'ERROR' });
    }
  };

  const handleDownloadAllInvoices = async () => {
    try {
      if (
        filteredInvoice.length > 0 &&
        (selectedMainBuyer?.syncSupplierId || user?.userSupplier?.id)
      ) {
        if (filteredInvoice.length === 1) {
          const res = await dispatch(
            getInvoicePdf({
              supplierId:
                accountType === 'supplier'
                  ? user?.userSupplier?.id || ''
                  : selectedMainBuyer?.syncSupplierId,
              invoiceId:
                filteredInvoice[0]?.quickbooksInvoiceDetails?.id ??
                filteredInvoice[0].quickbooksInvoiceId,
            })
          );
          if (res && res.type === 'buyers/getInvoicePdf/fulfilled') {
            openDillToast({ message: 'invoice pdf successfully downloaded', type: 'SUCCESS' });
          } else {
            openDillToast({ message: 'Failed to download invoice pdf', type: 'ERROR' });
          }
        } else {
          const res = await dispatch(
            downloadMultiInvoicePdfZip({
              supplierId:
                accountType === 'supplier'
                  ? user?.userSupplier?.id || ''
                  : selectedMainBuyer?.syncSupplierId,
              invoiceIds: filteredInvoice.map((invoice: any) => {
                return invoice?.quickbooksInvoiceDetails?.id ?? invoice.quickbooksInvoiceId;
              }),
            })
          );
          if (res && res.type === 'buyers/downloadMultiInvoicePdfZip/fulfilled') {
            openDillToast({
              message: 'All invoice pdf zip successfully downloaded',
              type: 'SUCCESS',
            });
          } else {
            openDillToast({ message: 'Failed to download all invoice pdf zip', type: 'ERROR' });
          }
        }
      }
    } catch (err) {
      openDillToast({ message: 'Failed to download all invoice pdf zip', type: 'ERROR' });
      console.log(err);
    }
  };

  const handleDownloadReceipt = async () => {
    try {
      const res = await dispatch(
        downloadInvoicePdfReceipt({
          supplierId:
            accountType === 'supplier'
              ? user?.userSupplier?.id || ''
              : selectedMainBuyer?.syncSupplierId,
          transactionId: selectedTransaction?.id || '',
        })
      );
      if (res && res.type === 'buyers/downloadInvoicePdfReceipt/fulfilled') {
        openDillToast({
          message: 'Successfully downloaded receipt',
          type: 'SUCCESS',
        });
      } else {
        openDillToast({ message: 'Failed to download receipt', type: 'ERROR' });
      }
    } catch (err: any) {
      openDillToast({ message: err.message || 'Failed to download receipt', type: 'ERROR' });
      console.log(err);
    }
  };

  const discountData = useMemo(() => {
    const discountTable = [];
    for (let i = 0; i < invoices.length; i++) {
      const invoice = invoices[i];
      if (invoice.discountAmount && invoice.discountAmount > 0) {
        const amountPaid = invoice?.amountPaid ?? 0;
        const discountAmout = invoice?.discountAmount ?? 0;
        const totalAmountPaid = amountPaid + discountAmout;
        discountTable.push({
          dueDate: invoice?.dueDate ?? '',
          invoiceNumber: invoice?.invoiceNumber ?? '',
          discountAmount: invoice?.discountAmount ?? '',
          amount: totalAmountPaid,
        });
      }
    }
    return discountTable;
  }, [invoices]);

  const renderDiscountDropDown = () => {
    return (
      <div className="PRIMARY_300-BORDER border mb-2 rounded overflow-hidden">
        <div
          className="flex flex-row justify-between  cursor-pointer p-1 hover:bg-indigo-100"
          onClick={() => setOpenDiscountDropDown(!openDiscountDropDown)}>
          <div className="flex items-center justify-center">
            {openDiscountDropDown ? (
              <CaretUp color={COLORS.GREY_500} size={19} />
            ) : (
              <CaretDown color={COLORS.GREY_500} size={19} />
            )}
            <div className="amount-label-text ml-2">Discount</div>
          </div>
          <div className="amount-text">{`-${formatMoney(discount, 2)}`}</div>
        </div>
        {openDiscountDropDown && (
          <div className="WHITE-BG p-2">
            <div className="w-full px-[4px] py-1 flex justify-between PRIMARY_100-BG">
              <div className="text-xs font-bold">Invoice No</div>
              <div className="flex">
                <div className="text-xs w-[100px] font-bold">Due Date</div>
                <div className="text-xs w-[120px] font-bold">Amount</div>
                <div className="text-xs w-[100px] flex justify-end font-bold">Discount</div>
              </div>
            </div>
            {discountData.map((invoice) => {
              return (
                <div
                  className="w-full px-[2px] py-1 flex justify-between"
                  key={`dropdown_discount_${invoice.invoiceNumber}`}>
                  <div className="text-xs">{invoice.invoiceNumber}</div>
                  <div className="flex">
                    <div className={`text-xs w-[100px]`}>
                      {moment(invoice.dueDate).format('MM/DD/YYYY')}
                    </div>
                    <div className="text-xs w-[120px]">{`${formatMoney(
                      invoice?.amount ?? '',
                      2
                    )}`}</div>
                    <div className="text-xs w-[100px] flex justify-end">{`${
                      discount > 0 ? '-' : ''
                    }${formatMoney(invoice?.discountAmount ?? '', 2)}`}</div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="invoice-select-pay-modal-content pt-3">
        <div>
          <div className="payment-modal-title-container">
            {paymentType === 'PAYMENT' ||
            paymentType === 'WILL_CALL_PAYMENT' ||
            paymentType === 'CREDIT_PAYMENT' ? (
              <h3 className="paid-invoice-modal-title">
                {`${invoices.length > 1 ? 'Bulk Paid Invoice' : 'Paid Invoice'}`}
              </h3>
            ) : (
              <h3 className="paid-invoice-modal-title">
                {`${paymentType === 'WITHDRAW' ? 'Withdrawal Details' : 'Fee'}`}
              </h3>
            )}
            <AppButton
              type="FLAT"
              buttonStyles={{ padding: '0px', margin: '5px 2px' }}
              icon={<X color={COLORS.GREY_300} size={30} />}
              onClick={handleClose}
            />
          </div>
          <div className="paid-invoice-modal-list-content">
            {paymentType === 'WITHDRAW' && (
              <div>
                <div className="flex flex-row align-center justify-between">
                  <div className="flex flex-col mx-6 mt-4">
                    <p className="text-base font-bold">
                      {selectedTransaction?.paymentMethod?.type === 'BANK_ACCOUNT'
                        ? 'Bank Account'
                        : 'Credit Card'}
                    </p>
                    <p className="bank-detail-number">{`${paymentTo.toLowerCase()} | ${
                      selectedTransaction?.paymentMethod?.paymentMethodName
                    }`}</p>
                  </div>
                  <div className="self-center mx-6 mt-4">
                    <AppTransactionStatusPill status={selectedTransaction?.transactionStatus} />
                  </div>
                </div>
                <div className="flex flex-col w-full  mb-3 px-4">
                  <div className="flex flex-col justify-between  mt-4 rounded-xl PRIMARY_50-BG px-5 py-4">
                    <div className="">
                      <div className="flex flex-row justify-between w-full pb-2 ">
                        <div className="withdrawal-date">Withdrawal Date</div>
                        <div className="text-sm font-bold">
                          {moment(selectedTransaction?.createdAt).format('MMM Do, YYYY')}
                        </div>
                      </div>
                      <div className="flex flex-row justify-between w-full pb-2">
                        <div className="withdrawal-date">Estimated Arrival</div>
                        <div className="text-sm font-bold">
                          {moment(
                            getNextBusinessDay(moment(selectedTransaction?.createdAt), 2)
                          ).format('MMM Do, YYYY')}
                        </div>
                      </div>
                    </div>
                    <div className="bg-white rounded-lg px-4 py-3">
                      <p className="text-xs text-gray-500 mb-3 font-semibold">INCLUDED PAYMENTS</p>
                      <div className="">
                        {selectedTransaction &&
                          selectedTransaction?.linkedWithdrawTransactions &&
                          selectedTransaction?.linkedWithdrawTransactions.length > 0 &&
                          selectedTransaction?.linkedWithdrawTransactions?.map((transaction, i) => {
                            let senderName = selectedTransaction?.recipient?.name ?? '';
                            if (
                              transaction.type === 'DILL_FACILITATION_FEE_PAYMENT_BUYER' ||
                              transaction.type === 'DILL_FACILITATION_FEE_PAYMENT_SUPPLIER'
                            ) {
                              senderName = 'Dill';
                            } else if (transaction.type === 'PAYMENT') {
                              senderName = transaction?.buyer
                                ? transaction?.buyer?.name.toLocaleLowerCase()
                                : '';
                            }
                            const transactionType = transaction.type;
                            let totalBuyerFees = 0;
                            let totalSupplierFees = 0;
                            if (transaction?.linkedFacilitationFeeTransactions) {
                              const filterBuyerFeeTransaction =
                                transaction?.linkedFacilitationFeeTransactions.filter(
                                  (item) => item.type === 'DILL_FACILITATION_FEE_PAYMENT_BUYER'
                                );
                              totalBuyerFees = filterBuyerFeeTransaction.reduce(
                                (accumulator: number, currentValue: any) => {
                                  return accumulator + currentValue.totalAmountPaid;
                                },
                                0
                              );
                              const filterSupplierFeeTransaction =
                                transaction?.linkedFacilitationFeeTransactions.filter(
                                  (item) => item.type === 'DILL_FACILITATION_FEE_PAYMENT_SUPPLIER'
                                );
                              totalSupplierFees = filterSupplierFeeTransaction.reduce(
                                (accumulator: number, currentValue: any) => {
                                  return accumulator + currentValue.totalAmountPaid;
                                },
                                0
                              );
                            }

                            return (
                              <div key={`${i}-a`} className="flex justify-between mt-2">
                                <div className=" w-full " style={{ flex: 2 / 3 }}>
                                  <div className="w-full flex items-center">
                                    <div className="letter-container">
                                      {getFirstLetters(senderName || '')}
                                    </div>
                                    <div>
                                      <div className="name-text">{senderName || ''}</div>
                                      <div className="flex items-center">
                                        {transactionType === 'PAYMENT' ||
                                        transactionType === 'WILL_CALL_PAYMENT' ||
                                        transactionType === 'CREDIT_PAYMENT' ? (
                                          <ArrowDownLeft color={COLORS.SUCCESS_500} size={10} />
                                        ) : (
                                          <ArrowDownLeft
                                            color={COLORS.ERROR_500}
                                            size={10}
                                            className="rotate-180"
                                          />
                                        )}
                                        <div className="action-type-text">
                                          {`${
                                            transactionType === 'PAYMENT' ||
                                            transactionType === 'CREDIT_PAYMENT' ||
                                            transactionType === 'WILL_CALL_PAYMENT'
                                              ? 'RECEIVED'
                                              : transaction.type.replaceAll('_', ' ')
                                          }`}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="w-full" style={{ flex: 0.5 / 3 }}>
                                  <p className="font-bold text-xs ">
                                    +
                                    {formatMoney(
                                      transaction.totalAmountPaid -
                                        totalBuyerFees -
                                        totalSupplierFees,
                                      2
                                    )}
                                  </p>
                                </div>
                                <div className="w-full" style={{ flex: 0.5 / 3 }}>
                                  <p className="text-xs">
                                    {transaction.createdAt
                                      ? moment(transaction.createdAt).format('MM/DD/YYYY')
                                      : ''}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(paymentType === 'PAYMENT' ||
              paymentType === 'WILL_CALL_PAYMENT' ||
              paymentType === 'CREDIT_PAYMENT') && (
              <div>
                <div className="flex flex-col align-center justify-between">
                  <div className="flex  mx-4 mt-3 justify-between">
                    <div className="flex w-full flex-col my-1 mx-1">
                      <p className="text-xs font-semibold mb-1 TEXT_SECONDARY-CLR">Date</p>
                      <p className="text-xs font-bold capitalize">{selectedDate}</p>
                    </div>
                    <div className="flex w-full flex-col my-1 mx-1">
                      <p className="text-xs font-semibold mb-1 TEXT_SECONDARY-CLR">To</p>
                      <p className="text-xs font-bold capitalize">{paymentTo.toLowerCase()}</p>
                    </div>
                    <div className="flex w-full flex-col my-1 mx-1">
                      <p className="text-xs font-semibold mb-1 TEXT_SECONDARY-CLR">From</p>
                      <p className="text-xs font-bold capitalize">{paymentFrom.toLowerCase()}</p>
                    </div>
                  </div>
                  <div className="flex  mx-4 mt-3 justify-between">
                    <div className="flex w-full flex-col my-1 mx-1">
                      <p className="text-xs font-semibold mb-1 TEXT_SECONDARY-CLR">Method</p>
                      {selectedTransaction && (
                        <p className="text-xs font-bold capitalize">
                          {selectedTransaction.paymentMethod?.type === 'BANK_ACCOUNT'
                            ? 'Bank Transfer'
                            : selectedTransaction.paymentMethod?.type === 'CREDIT_CARD' ||
                              selectedTransaction.type === 'WILL_CALL_PAYMENT'
                            ? 'Credit/Debit Card'
                            : 'Credits'}
                        </p>
                      )}
                      <p className="text-xs font-bold capitalize">{selectedPaymentName}</p>
                    </div>
                    <div className="flex w-full flex-col my-1 mx-1">
                      <p className="text-xs font-semibold mb-1 TEXT_SECONDARY-CLR">
                        Transaction Status
                      </p>
                      <AppTransactionStatusPill status={selectedTransaction?.transactionStatus} />
                    </div>
                    <div className="flex w-full flex-col my-1 mx-1">
                      {accountType === 'supplier' && (
                        <div className="flex flex-col w-full">
                          <p className="text-xs font-semibold mb-1 TEXT_SECONDARY-CLR">
                            Reconciliation Status
                          </p>
                          <AppTransactionStatusPill
                            status={selectedTransaction?.reconciliationStatus}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  {selectedTransaction?.linkedReleases &&
                    selectedTransaction?.linkedReleases?.length > 1 && (
                      <div className="flex  mx-4 mt-3 justify-between ">
                        <div className="flex flex-col w-full  my-1 mx-1">
                          {selectedTransaction?.linkedReleases &&
                            selectedTransaction?.linkedReleases?.length === 1 && (
                              <div
                                data-tooltip-id={
                                  selectedTransaction?.linkedReleases?.[0]?.status === 'Pending' &&
                                  accountType === 'supplier'
                                    ? 'release-pending-tooltip'
                                    : undefined
                                }>
                                <AppButton
                                  text="View Lien Release"
                                  buttonStyles={{ width: '100%' }}
                                  isDisabled={
                                    selectedTransaction?.linkedReleases?.[0]?.status ===
                                      'Pending' && accountType === 'supplier'
                                  }
                                  onClick={() => {
                                    handleViewRelease(
                                      selectedTransaction?.linkedReleases?.[0]?.id ?? ''
                                    );
                                  }}
                                />
                              </div>
                            )}
                          {selectedTransaction?.linkedReleases &&
                            selectedTransaction?.linkedReleases?.length > 1 && (
                              <div
                                className="w-full grid flex-col justify-items-end"
                                ref={containerRef}>
                                <div className="custom-dropdown-container h-fit ">
                                  <AppButton
                                    type="PRIMARY"
                                    buttonStyles={{
                                      padding: '8px',
                                      margin: '0px 0px',
                                      width: '100%',
                                    }}
                                    text="View Lien Releases"
                                    onClick={handleShowDropdown}
                                    iconPosition="RIGHT"
                                    icon={<ChevronDown color={COLORS.WHITE} />}
                                  />
                                  {showMenu && (
                                    <div className="custom-dropdown-menu h-fit">
                                      <div className={`flex flex-col py-3`}>
                                        {selectedTransaction?.linkedReleases.map((release) => (
                                          <button
                                            data-tooltip-id={
                                              release.status === 'Pending' &&
                                              accountType === 'supplier'
                                                ? 'release-pending-tooltip'
                                                : undefined
                                            }
                                            key={`${release.id}`}
                                            disabled={
                                              release.status === 'Pending' &&
                                              accountType === 'supplier'
                                            }
                                            onClick={() => handleViewRelease(release.id)}
                                            className="w-full flex flex-row py-1.5  px-2 font-medium items-center hover:bg-blue-100">
                                            <div
                                              className="text-sm TEXT_SECONDARY-CLR"
                                              style={{ maxWidth: 200 }}>
                                              {release.job?.name}
                                            </div>
                                          </button>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                        <ReactTooltip
                          id={'release-pending-tooltip'}
                          place="top"
                          variant="dark"
                          opacity={'100%'}
                          style={{
                            display: 'flex',
                            background: '#334155',
                            width: '190px',
                            zIndex: '70',
                            borderRadius: '8px',
                            alignItems: 'center',
                            justifyItems: 'center',
                            fontWeight: '600',
                          }}>
                          <span>
                            {selectedTransaction?.linkedReleases &&
                            selectedTransaction?.linkedReleases?.length > 1
                              ? `These lien release requests are still pending. They will be sent to you for your sign-off when payment is successfully processed`
                              : `This lien release request is still pending. It will be sent to you for your sign-off when payment is successfully processed`}
                          </span>
                        </ReactTooltip>
                      </div>
                    )}
                </div>
                {selectedTransaction?.transactionStatus === 'failed' && error.length > 0 && (
                  <div className="failed-desc-container">
                    <div className="failed-desc-text">
                      <div>{error[0]}</div>
                      <div className="failed-desc-text mt-2">
                        Please reach out to <span>972-339-0799</span> for customer support
                      </div>
                    </div>
                  </div>
                )}
                {selectedTransaction?.type !== 'WILL_CALL_PAYMENT' && (
                  <div className="mx-4 mt-4 mb-2">
                    <AppButton
                      type="FLAT"
                      buttonStyles={{ padding: '5px', fontSize: '16px', borderRadius: '5px' }}
                      text="Download receipt"
                      onClick={() => {
                        handleDownloadReceipt();
                      }}
                      icon={<Download size={18} />}
                    />
                    {filteredInvoice?.length > 0 ? (
                      <AppButton
                        type="FLAT"
                        buttonStyles={{ padding: '5px', fontSize: '16px', borderRadius: '5px' }}
                        text="Download all invoices"
                        onClick={() => {
                          handleDownloadAllInvoices();
                        }}
                        icon={<Download size={18} />}
                      />
                    ) : (
                      <div className="mx-4 mt-4 mb-2"></div>
                    )}
                  </div>
                )}

                <div className="flex flex-col px-4  grow overflow-y-scroll">
                  <div className="flex w-full flex-col overflow-y-scroll border max-h-96 rounded-xl">
                    <div className="flex border-b px-5 items-center w-full sticky top-0 PRIMARY_50-BG py-3">
                      {columns.map((column) => {
                        return (
                          <div
                            key={column.id}
                            style={{ flex: column.width / totalWidth }}
                            className="flex items-center w-full h-full  pr-4">
                            {column.cellType !== 'SELECT' && (
                              <p className="TEXT_SECONDARY-CLR text-xs">{column.name}</p>
                            )}
                          </div>
                        );
                      })}
                    </div>

                    {selectedInvoices.length < 1 && (
                      <div className="w-full h-full flex items-center justify-center">
                        <div className="flex flex-col items-center">
                          <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
                          <p>No invoices</p>
                        </div>
                      </div>
                    )}
                    {invoices.length > 0 &&
                      [...invoices].map((invoice, invoiceIndex) => {
                        return (
                          <div key={invoiceIndex + 'oo'} className={'flex  px-5 my-2 items-center'}>
                            {columns.map((column) => {
                              type ObjectKey = keyof typeof invoice;
                              const columnKey = column.id as ObjectKey;
                              let value = invoice[columnKey]?.toString();
                              if (column.id === 'dueDate') {
                                value = value ? moment(value).utc().format('MM/DD/YYYY') : '';
                              }
                              if (column.id === 'amountPaid') {
                                const amountPaid = invoice?.amountPaid ?? 0;
                                const discountAmout = invoice?.discountAmount ?? 0;
                                const totalAmountPaid = amountPaid + discountAmout;
                                value = formatMoney(totalAmountPaid ?? 0, 2);
                              }
                              if (column.id === 'openAmount') {
                                value = value ? formatMoney(value ?? 0, 2) : '';
                              }
                              return (
                                <div
                                  key={column.id}
                                  style={{ flex: column.width / totalWidth }}
                                  className="flex items-center w-full h-full   pr-4 ">
                                  {column.cellType === 'VIEW_TEXT' && column.id !== 'job' && (
                                    <p className={'py-3 text-sm ' + 'TEXT_PRIMARY-CLR'}>{value}</p>
                                  )}
                                  {column.cellType === 'VIEW_TEXT' &&
                                    column.id === 'actions' &&
                                    (!invoice?.recordType || invoice?.recordType === 'INVOICE') && (
                                      <div className="">
                                        <Download
                                          size={16}
                                          color={COLORS.PRIMARY_500}
                                          className="ml-2 cursor-pointer"
                                          onClick={() => {
                                            handleDownloadPdf(
                                              invoice?.quickbooksInvoiceDetails?.id ??
                                                invoice?.quickbooksInvoiceId
                                            );
                                          }}
                                        />
                                      </div>
                                    )}
                                  {column.id === 'job' &&
                                    invoice?.quickbooksInvoiceDetails?.quickBooksJob && (
                                      <div className={'text-sm w-full px-0.5'}>
                                        <p className="font-bold text-xs ">
                                          {`${
                                            invoice?.quickbooksInvoiceDetails?.quickBooksJob
                                              ?.jobName ?? ''
                                          }`}
                                        </p>
                                        <p className="text-2xs  ">
                                          {`${
                                            invoice?.quickbooksInvoiceDetails?.quickBooksJob
                                              ?.address ||
                                            invoice?.quickbooksInvoiceDetails?.shipAddress
                                              ?.address1 ||
                                            ''
                                          }`}
                                        </p>
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
            {paymentType === 'FEES' && (
              <div className="w-full justify-between flex">
                <div />
                <div className="self-center mx-6 mt-4">
                  <AppTransactionStatusPill status={selectedTransaction?.transactionStatus} />
                </div>
              </div>
            )}
            {status === 'failed' &&
              error.length > 0 &&
              paymentType !== 'PAYMENT' &&
              paymentType !== 'WILL_CALL_PAYMENT' && (
                <div className="failed-desc-container">
                  <div className="failed-desc-text">
                    <div>{error[0]}</div>
                    <div className="failed-desc-text mt-2">
                      Please reach out to <span>972-339-0799</span> for customer support
                    </div>
                  </div>
                </div>
              )}
            {partialPaymentReason && (
              <div className="flex flex-col w-full  mb-3  ">
                <div className=" px-4 py-2 PRIMARY_50-BG mx-5 my-4 rounded-xl">
                  <div className="flex flex-row mt-4  ">
                    <div className="text-sm font-bold mr-2 text-nowrap w-1/3">
                      Partial Payment Reason:
                    </div>
                    <div className="text-sm  w-2/3">{partialPaymentReason}</div>
                  </div>

                  {selectedTransaction?.partialPaymentNotes &&
                    selectedTransaction?.partialPaymentNotes.length > 0 && (
                      <div className="flex flex-row mt-4">
                        <div className="text-sm font-bold mr-2 text-nowrap w-1/3">
                          Partial Payment Notes:
                        </div>
                        <div className="text-sm w-2/3">
                          {selectedTransaction?.partialPaymentNotes}
                        </div>
                      </div>
                    )}
                  {selectedTransaction?.partialPaymentAttachments &&
                    selectedTransaction?.partialPaymentAttachments.length > 0 && (
                      <div className="flex flex-row mt-4 rounded-xl ">
                        <div className="text-sm font-bold mr-2  text-nowrap w-1/3">
                          Partial Payment Attachments:
                        </div>
                        <div className="flex flex-col  w-2/3">
                          {selectedTransaction?.partialPaymentAttachments?.map(
                            (partialPaymentAttachment, i) => {
                              return (
                                <div key={`${i}-oo`} className="text-sm text-blue-500">
                                  <a href={partialPaymentAttachment.url} target="_blank">
                                    {partialPaymentAttachment.name}
                                  </a>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            )}
            <div className="flex flex-col w-full  mb-3">
              {selectedTransaction?.type === 'CREDIT_PAYMENT' && fullCreditPay !== 0 ? (
                <div className="w-full px-4">
                  <div className="flex flex-col justify-between  mt-4 rounded-xl PRIMARY_50-BG px-5 py-4">
                    <div className={'border-b'}>
                      <div className="flex flex-row justify-between  mb-2 ">
                        <div className="amount-label-text">Amount</div>
                        <div className="amount-text">{`${formatMoney(transactionAmount, 2)}`}</div>
                      </div>
                      <div className="flex flex-row justify-between  mb-2 ">
                        <div className="amount-label-text">Credits</div>
                        <div className="amount-text">{`-${formatMoney(fullCreditPay, 2)}`}</div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-between w-full pt-2">
                      <div className="amount-text">Total Payment</div>
                      <div className="totalText">{`${formatMoney(
                        transactionAmount - fullCreditPay,
                        2
                      )}`}</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full px-4">
                  {paymentType !== 'WITHDRAW' && (
                    <div className="flex flex-col justify-between  mt-4 rounded-xl PRIMARY_50-BG px-5 py-4">
                      <div className="flex flex-row justify-between  mb-2 ">
                        <div className="amount-label-text">Amount</div>
                        <div className="amount-text">
                          {`${formatMoney(
                            transactionAmount - buyerFees + creditAmount + discount,
                            2
                          )}`}
                        </div>
                      </div>
                      {discount > 0 && renderDiscountDropDown()}
                      {creditAmount > 0 && (
                        <div>
                          <div className="flex flex-row justify-between  mb-2 ">
                            <div className="amount-label-text">Credits</div>
                            <div className="amount-text">{`-${formatMoney(creditAmount, 2)}`}</div>
                          </div>
                        </div>
                      )}
                      {buyerFees !== 0 && (
                        <div className={`flex flex-row justify-between w-full pb-2`}>
                          <div className="detailText">{`${
                            accountType === 'supplier' ? 'Buyer Fee' : 'Your Fee'
                          }`}</div>
                          <div className="text-sm font-bold">{`+${formatMoney(buyerFees, 2)}`}</div>
                        </div>
                      )}
                      {supplierFees + buyerFees !== 0 && accountType === 'supplier' && (
                        <div className="flex flex-row justify-between w-full pb-2 ">
                          <div className="detailText">{`${
                            accountType === 'supplier' ? 'Your Fee' : 'Supplier Fee'
                          }`}</div>
                          <div className="text-sm font-bold">{`-${formatMoney(
                            supplierFees + buyerFees,
                            2
                          )}`}</div>
                        </div>
                      )}
                      <div className="flex flex-row justify-between w-full pt-2 border-t">
                        <div className="text-sm font-bold">Total Payment</div>
                        <div className="totalText">{`${
                          accountType === 'supplier'
                            ? `${formatMoney(transactionAmount - buyerFees - supplierFees, 2)}`
                            : `${formatMoney(transactionAmount, 2)}`
                        }`}</div>
                      </div>
                    </div>
                  )}
                  {paymentType === 'WITHDRAW' && (
                    <div className="flex flex-col justify-between  mt-4 rounded-xl PRIMARY_50-BG px-5 py-4">
                      <div className="flex flex-row justify-between w-full pb-2 ">
                        <div className="detailText">Amount</div>
                        <div className="text-sm font-bold">{`${formatMoney(
                          transactionAmount + (selectedTransaction?.facilitationFee ?? 0),
                          2
                        )}`}</div>
                      </div>
                      {selectedTransaction?.facilitationFee !== 0 && (
                        <div className="flex flex-row justify-between w-full pb-2 border-b">
                          <div className="detailText">Fee</div>
                          <div className="text-sm font-bold">{`-${formatMoney(
                            selectedTransaction?.facilitationFee ?? 0,
                            2
                          )}`}</div>
                        </div>
                      )}
                      <div className="flex flex-row justify-between w-full pt-2">
                        <div className="text-sm font-bold">Total Payment</div>
                        <div className="totalText">{`${formatMoney(transactionAmount, 2)}`}</div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
              <div className="flex flex-row self-end px-6">
                <AppButton
                  text="Close"
                  buttonStyles={{
                    marginTop: '4px',
                    marginBottom: '4px',
                  }}
                  onClick={handleClose}
                  type="TERTIARY"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
