import { useEffect, useMemo, useState } from 'react';
import './DashboardPage.scss';
// import logoImg from '../../assets/images/logo.png';
import logoImg from '../../assets/svg/logo.svg';
import coinsLightImg from '../../assets/svg/coins_light.svg';
import coinsImg from '../../assets/svg/coins.svg';
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  logoutUser,
  getUserDetails,
  getRutterPublicKey,
  processRutterPublicToken,
} from '../../redux/services/authService';
import { useRutterLink } from 'react-rutter-link';
import {
  getQuickBooksAuthUri,
  disconnectQuickbooks,
  mainBuyerDownloadSupplierInvoiceStatements,
} from '../../redux/services/invoicesService';
import { AppButton } from '../../components/general/AppButton/AppButton';
import {
  HelpCircle,
  ChevronLeft,
  ChevronDown,
  AlertTriangle,
  AlertCircle,
  RefreshCcw,
} from 'react-feather';
import { COLORS } from '../../utils/colors';
import {
  ClipboardText,
  Plugs,
  UserCircle,
  CaretUp,
  CaretDown,
  Checks,
  FileText,
  CreditCard,
  FileX,
  Database,
  UserSquare,
  Briefcase,
  Lock,
  MegaphoneSimple,
  PlusCircle,
  FilePlus,
} from 'phosphor-react';
import { ConnectQuickBooksModal } from '../../components/modules/invoices/ConnectQuickBooksModal/ConnectQuickBooksModal';
import { clearQuickBooksAuthUri } from '../../redux/globalSlices/invoiceSlice';
import quickBooksConnectSuccessIcon from '../../assets/svg/quickbooks_connect_success.svg';
import quickBooksConnectFailedIcon from '../../assets/svg/quickbooks_connect_failed.svg';
import quickBooksConnectWarnIcon from '../../assets/svg/quickbooks_connect_warn.svg';
import d1Logo from '../../assets/images/d1-logo.png';
import sageLogo from '../../assets/images/sage-logo.png';
import { AuthorizeQuickbooksConnect } from '../../components/modules/invoices/AuthorizeQuickbooksConnect/AuthorizeQuickbooksConnect';
import { useComponentVisible } from '../../utils/hooks/useComponentVisible';
import { getUserCustomers } from '../../redux/services/suppliersService';
import { useWindowActualHeight, useWindowActualWidth } from '../../utils/domTools';
import {
  FetchAndSaveDataQueueJobName,
  MainBuyer,
  SaveDataQueueJobName,
  capitalizeWordsInSentence,
} from '@dill/dill-shared';
import { AppVerifyBusinessModal } from '../../components/general/AppVerifyBusinesModal/AppVerifyBusinessModal';
import {
  getDwollaCustomer,
  getSupplierRegistrationStatus,
} from '../../redux/services/paymentsService';
import { refreshAccounting } from '../../redux/services/quickBooksService';
import { twMerge } from 'tailwind-merge';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { toast } from 'react-toastify';

import { openDillToast } from '../../utils/helpers';
import { REACT_APP_RUTTER_PUBLIC_KEY } from '../../utils/constants';

import { updateSelectedMainBuyer } from '../../redux/globalSlices/buyersSlice';
import { useParamMainBuyerId } from '../../utils/hooks/useParamMainBuyerId';
import { AppCustomerSettingsModal } from '../../components/general/AppCustomerSettingsModal/AppCustomerSettingsModal';

import {
  updateSupplierQueueJobsRunning,
  updateSupplierManualUploadProgress,
} from '../../redux/globalSlices/genericSlice';
import { AppManualImportCSVModal } from '../../components/general/AppManualImportCSVModal/AppManualImportCSVModal';
import { onValue, ref as realTimeDBRef } from 'firebase/database';
import type { Unsubscribe } from 'firebase/database';
import { myRealtimeDB } from '../../firebase/config';
import AppManualImportProgressModal from '../../components/general/AppManualImportProgressModal/AppManualImportProgressModal';
import AppAuthorizeInforDistSXModal from '../../components/general/AppAuthorizeInforDistSXModal';
import AppDistributionOneConnectionModal from '../../components/general/AppDistributionOneConnectionModal/AppDistributionOneConnectionModal';
import { AppInputField } from '../../components/general/AppInputField/AppInputField';
import AppSage100ConnectionModal from '../../components/general/AppSage100ConnectionModal/AppSage100ConnectionModal';

interface RouteDetails {
  id: string;
  parentId: string;
  name: string;
  path: string;
  isNested: boolean;
  isDisabled: boolean;
  type: string;
  icon?: {
    inActive: any;
    active: any;
  };
  subRoutes?: RouteDetails[];
}
export const AppProfileView = ({
  onMenuClick,
  modeType,
  platformName,
}: {
  onMenuClick: (item: any) => void;
  modeType: string;
  platformName: string;
}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const { supplierRegistrationStatus, loadingList: paymentLoadingList } = useAppSelector(
    (state) => state.payments
  );
  const { user } = useAppSelector((state) => state.auth);
  const isVerified = useMemo(() => {
    return supplierRegistrationStatus?.isFullyRegistered;
  }, [user, supplierRegistrationStatus?.isFullyRegistered]);

  return (
    <div className=" flex items-center relative">
      <div
        className="flex items-center border px-2 py-2 cursor-pointer"
        onClick={() => {
          setIsComponentVisible(!isComponentVisible);
        }}>
        <div className="w-8 h-8 PRIMARY_500-BG mr-2  flex items-center justify-center rounded-full">
          {user && (
            <p className="WHITE-CLR text-sm">{`${
              user.firstName ? user.firstName?.toLocaleUpperCase().slice(0, 1) : ''
            } ${user.lastName ? user.lastName?.toLocaleUpperCase().slice(0, 1) : ''}`}</p>
          )}
        </div>
        {user && (
          <p className="font-semibold mr-2">{`${user.firstName || ''} ${user.lastName || ''}`}</p>
        )}
        <ChevronDown />
      </div>
      {isComponentVisible && (
        <div
          ref={ref}
          className=" WHITE-BG right-0 absolute w-fit top-12 shadow-lg min-h-fit py-2 px-2 flex flex-col z-50 ">
          <div className="flex items-center">
            <div className="flex w-8 h-8 PRIMARY_500-BG rounded-full   items-center justify-center">
              {user && (
                <p className="WHITE-CLR m-6 p-0 whitespace-nowrap text-sm">{`${
                  user.firstName ? user.firstName?.toLocaleUpperCase().slice(0, 1) : ''
                } ${user.lastName ? user.lastName?.toLocaleUpperCase().slice(0, 1) : ''}`}</p>
              )}
            </div>

            <div className="flex flex-col ml-1">
              {user && (
                <p className="text-sm">{`${user.firstName || ''} ${user.lastName || ''}`}</p>
              )}
              <p className="text-sm GREY_500-CLR">{user?.email}</p>
            </div>
          </div>
          {user?.roles.includes('SUPPLIER') && modeType === 'Supplier' && (
            <div className="h-0.5 GREY_100-BG w-full mt-3"></div>
          )}
          <div className="w-full">
            {user?.roles.includes('SUPPLIER') &&
              !user.isConnectedToQuickBooks &&
              modeType === 'Supplier' &&
              user?.userSupplier?.integrationType != 'INFOR_DIST_SX' && (
                <AppButton
                  text={
                    user?.userSupplier &&
                    ((user?.userSupplier?.integrationType?.startsWith('CODAT_') &&
                      user?.userSupplier?.integrationDetails?.codat?.companyId &&
                      user?.userSupplier?.integrationDetails?.codat?.connectorId) ||
                      (user?.userSupplier?.integrationType?.startsWith('RUTTER_') &&
                        user?.userSupplier?.integrationDetails?.rutter?.connectionId))
                      ? user?.userSupplier?.integrationDetails?.codat &&
                        user?.userSupplier?.integrationDetails?.codat.currency &&
                        user?.userSupplier?.integrationDetails?.codat.currency !== 'USD'
                        ? `'${user?.userSupplier?.integrationDetails?.codat.currency}' currency not supported `
                        : `Finalizing  ${platformName} Connection`
                      : `Connect ${platformName}`
                  }
                  type={
                    user?.userSupplier &&
                    user?.userSupplier?.integrationType?.startsWith('CODAT_') &&
                    user?.userSupplier?.integrationDetails?.codat?.companyId &&
                    user?.userSupplier?.integrationDetails?.codat?.connectorId &&
                    user?.userSupplier?.integrationDetails?.codat.currency &&
                    user?.userSupplier?.integrationDetails?.codat.currency !== 'USD'
                      ? 'ERROR'
                      : 'PRIMARY'
                  }
                  buttonStyles={{ marginTop: '10px', width: '100%' }}
                  icon={<Plugs color={COLORS.WHITE} />}
                  onClick={() => {
                    if (!user.isConnectedToQuickBooks) {
                      onMenuClick('quickBooks');
                    }
                  }}
                />
              )}
            {user?.roles.includes('SUPPLIER') &&
              user.isConnectedToQuickBooks &&
              (user?.userSupplier?.integrationType?.startsWith('RUTTER_') ||
                user?.userSupplier?.integrationType?.startsWith('CODAT_') ||
                user?.userSupplier?.integrationType?.startsWith('QBO') ||
                user?.userSupplier?.integrationType === 'DISTRIBUTION_ONE' ||
                user?.userSupplier?.integrationType === 'SAGE_100') &&
              modeType === 'Supplier' &&
              user?.userSupplier?.integrationType !== 'MANUAL_UPLOAD' && (
                <AppButton
                  text={`${platformName} Connected`}
                  type="SECONDARY"
                  onClick={() => {
                    if (!user.isConnectedToQuickBooks) {
                      onMenuClick('quickBooks');
                    } else {
                      onMenuClick('disconnect');
                    }
                  }}
                  buttonStyles={{ marginTop: '10px', width: '100%' }}
                />
              )}
            {user?.roles.includes('SUPPLIER') &&
              modeType === 'Supplier' &&
              user?.userSupplier?.integrationType == 'INFOR_DIST_SX' && (
                <AppButton
                  text={
                    user?.userSupplier &&
                    user?.userSupplier?.integrationType == 'INFOR_DIST_SX' &&
                    !user?.userSupplier?.integrationDetails?.inforDistSX?.isSetupDone
                      ? 'Connect to InforDistSX'
                      : 'Connected to InforDistSX'
                  }
                  type={
                    user?.userSupplier &&
                    user?.userSupplier?.integrationType == 'INFOR_DIST_SX' &&
                    !user?.userSupplier?.integrationDetails?.inforDistSX?.isSetupDone
                      ? 'PRIMARY'
                      : 'SECONDARY'
                  }
                  buttonStyles={{ marginTop: '10px', width: '100%' }}
                  icon={
                    user?.userSupplier &&
                    user?.userSupplier?.integrationType == 'INFOR_DIST_SX' &&
                    !user?.userSupplier?.integrationDetails?.inforDistSX?.isSetupDone ? (
                      <Plugs color={COLORS.WHITE} />
                    ) : (
                      <></>
                    )
                  }
                  onClick={() => onMenuClick('inforDistSX')}
                />
              )}
            {user?.roles.includes('SUPPLIER') && user.isAdmin && modeType === 'Supplier' && (
              <AppButton
                text={
                  paymentLoadingList.includes('getSupplierRegistrationStatus')
                    ? 'Checking Status'
                    : user?.userSupplier?.dwollaCustomerStatus === undefined ||
                      user?.userSupplier?.dwollaCustomerStatus === 'create'
                    ? 'Verify Your Business'
                    : isVerified
                    ? 'Business Verified'
                    : 'Finish Verification'
                }
                type="TERTIARY"
                buttonStyles={{
                  marginTop: '10px',
                  width: '100%',
                  alignSelf: 'center',
                }}
                icon={
                  isVerified ? (
                    <Checks color={COLORS.SUCCESS_600} />
                  ) : (
                    <AlertCircle color={COLORS.ERROR_600} size={18} />
                  )
                }
                onClick={() => {
                  // if (user?.userSupplier?.dwollaCustomerStatus !== 'verified') {
                  onMenuClick('connectDwolla');
                  // }
                }}
              />
            )}
          </div>
          {user?.roles.includes('MAIN_BUYER') && modeType === 'Buyer' && (
            <AppButton
              text={'Company Settings'}
              type="TERTIARY"
              buttonStyles={{
                marginTop: '10px',
                width: '100%',
                alignSelf: 'center',
              }}
              onClick={() => {
                onMenuClick('companySettings');
              }}
            />
          )}
          <div className="h-0.5 GREY_100-BG w-full mt-3"></div>
          <div
            className="my-2 cursor-pointer"
            onClick={() => {
              onMenuClick('logout');
            }}>
            <p className="GREY_700-CLR text-sm">Logout</p>
          </div>
        </div>
      )}
    </div>
  );
};
// interface RouteType {
//   id: string;
//   name: string;
//   path: string;
//   icon: any;
// }

const Dropdown = ({
  subRoutes,
  pathSplit,
  onOpenDropdown,
}: {
  subRoutes: RouteDetails[];
  pathSplit: any;
  onOpenDropdown: (value: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedMainBuyer } = useAppSelector((state) => state.buyers);
  const [isOpen, setIsOpen] = useState(false);
  const [filteredMainBuyers, setFilteredMainBuyers] = useState<MainBuyer[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedMainBuyerId] = useParamMainBuyerId();

  const handleSelectItem = (mainBuyer: MainBuyer) => {
    if (mainBuyer.id) {
      dispatch(updateSelectedMainBuyer(mainBuyer));
      setSearchText('');

      let newLocation = '';
      if (
        location.pathname.split('/').length == 5 &&
        location.pathname.includes('/companies') &&
        location.pathname.includes('/invoices')
      ) {
        newLocation = `/companies/${mainBuyer.id}/invoices`;
      } else if (
        location.pathname.split('/').length >= 5 &&
        location.pathname.includes('/buyerLienReleases')
      ) {
        const locationArray = location.pathname.split('/');
        const splitLocationPath = locationArray.slice(0, 4);
        newLocation = splitLocationPath
          .map((item, i) => {
            if (i === 2) {
              return mainBuyer.id;
            }
            return item;
          })
          .join('/');
      } else {
        newLocation = location.pathname
          .split('/')
          .map((item, i) => {
            if (i === 2) {
              return mainBuyer.id;
            }
            return item;
          })
          .join('/');
      }

      navigate(newLocation);
      setIsOpen(!isOpen);
      onOpenDropdown(!isOpen);
    }
  };

  useEffect(() => {
    if (!params?.mainBuyerId && user?.userMainBuyers && user?.userMainBuyers.length > 0) {
      dispatch(updateSelectedMainBuyer(user?.userMainBuyers[0]));
      if (location.pathname.startsWith('/adminTemplates')) {
        return;
      } else if (location.pathname.startsWith('/companies')) {
        navigate(`/companies/${user?.userMainBuyers[0].id}/invoices`);
      } else if (location.pathname.startsWith('/buyerLienReleases')) {
        navigate(`/buyerLienReleases/${user?.userMainBuyers[0].id}/overview`);
      } else {
        navigate(`/companies/${user?.userMainBuyers[0].id}/invoices`);
      }
    }
    if (params && params.mainBuyerId && user?.userMainBuyers) {
      const mainBuyer = user?.userMainBuyers.find((mb) => mb.id === selectedMainBuyerId);
      if (mainBuyer) {
        dispatch(updateSelectedMainBuyer(mainBuyer));
      } else {
        dispatch(updateSelectedMainBuyer(user?.userMainBuyers[0]));
      }
    }
  }, [location.pathname, selectedMainBuyerId]);

  const toggleDropdown = () => {
    if (user?.userMainBuyers && user?.userMainBuyers.length > 0) {
      setIsOpen(!isOpen);
      onOpenDropdown(!isOpen);
    } else {
      setIsOpen(false);
      onOpenDropdown(false);
    }
  };
  const handleSelectRoute = (id: string) => {
    if (selectedMainBuyerId) {
      navigate(`/companies/${selectedMainBuyerId}/${id}`);
      setIsOpen(false);
      onOpenDropdown(false);
    }
  };
  useEffect(() => {
    if (user?.userMainBuyers) {
      if (searchText) {
        const filtMB = user?.userMainBuyers.filter((mainBuyer) =>
          mainBuyer.name.toLowerCase().includes(searchText.toLowerCase())
        );
        console.log({ filtMB, searchText });
        setFilteredMainBuyers(filtMB);
      } else {
        setFilteredMainBuyers(user?.userMainBuyers);
      }
    }

    return () => {};
  }, [searchText, user?.userMainBuyers]);

  return (
    <div className="relative ">
      {isOpen && (
        <div className="bg-slate-50 my-1 sticky top-0 ">
          <AppInputField
            value={searchText}
            placeholder="Search"
            onTextChange={(val) => {
              setSearchText(val);
            }}
          />
        </div>
      )}

      {!isOpen && user?.userMainBuyers && user?.userMainBuyers.length > 0 && (
        <button className="drop-down sticky top-0 " onClick={toggleDropdown}>
          {selectedMainBuyer && (
            <div className="drop-down-title text-sm ">
              {selectedMainBuyer.name.toLocaleLowerCase()}
            </div>
          )}

          {isOpen ? (
            <CaretUp size={24} color={COLORS.GREY_600} />
          ) : (
            <CaretDown size={24} color={COLORS.GREY_600} />
          )}
          {!selectedMainBuyer && <div className="text-sm text-slate-400">Select company</div>}
        </button>
      )}
      {isOpen && selectedMainBuyer && (
        <div>
          {filteredMainBuyers &&
            filteredMainBuyers.map((mainBuyer) => (
              <button
                className={
                  selectedMainBuyerId === mainBuyer.id
                    ? 'selected-drop-down-option'
                    : 'drop-down-option'
                }
                key={`buyer_route_${mainBuyer.id}`}
                onClick={() => handleSelectItem(mainBuyer)}>
                <div
                  className={
                    selectedMainBuyerId === mainBuyer.id
                      ? 'selected-drop-down-option-text'
                      : 'drop-down-option-text'
                  }>
                  {mainBuyer.name.toLocaleLowerCase()}
                </div>
              </button>
            ))}
        </div>
      )}
      {!isOpen && (
        <div>
          {subRoutes.map((subRoute) => {
            return (
              <div
                onClick={() => {
                  if (!subRoute.isDisabled) {
                    handleSelectRoute(subRoute.id);
                  }
                }}
                key={subRoute.id}
                className={twMerge(
                  'flex h-10 my-2 items-center px-4 w-full rounded-lg',
                  `${pathSplit[3]}` === subRoute.id && !subRoute.isDisabled
                    ? ' GREY_100-BG '
                    : 'hover:bg-slate-50 ',
                  subRoute.isDisabled ? 'bg-slate-400' : ''
                )}>
                {`${pathSplit[3]}` === subRoute.id && subRoute?.icon && subRoute?.icon.active}
                {`${pathSplit[3]}` !== subRoute.id && subRoute?.icon && subRoute?.icon.inActive}
                <p
                  className={
                    'font-semibold text-sm ml-3.5 ' +
                    (`${pathSplit[3]}` === subRoute.id ? 'TEXT_PRIMARY' : 'GREY_500-CLR')
                  }>
                  {subRoute.name.substring(0, 15)}
                  {subRoute.name.length > 17 && '...'}
                </p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const width = useWindowActualWidth();
  const height = useWindowActualHeight();
  const { user, firebaseUserId, rutterPublicKey } = useAppSelector((state) => state.auth);
  const { selectedMainBuyer } = useAppSelector((state) => state.buyers);
  const { supplierQueueJobsRunning } = useAppSelector((state) => state.generic);
  const { quickBooksAuthUri, isGetQuickBooksAuthUrlFailed, selectedSupplierForBuyer } =
    useAppSelector((state) => state.invoice);
  const { selectedLiensRelease } = useAppSelector((state) => state.liens);
  const { selectedJob } = useAppSelector((state) => state.jobs);
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const params = useParams();
  const { supplierManualUploadProgress } = useAppSelector((state) => state.generic);

  const [openConnectQuickBooksModal, setOpenConnectQuickBooksModal] = useState(false);
  const [isAppDistributionOneConnectionModalOpen, setIsAppDistributionOneConnectionModalOpen] =
    useState(false);
  const [isAppSage100ConnectionModalOpen, setIsAppSage100ConnectionModalOpen] = useState(false);
  const [openConnectSuccessQuickBooksModal, setOpenConnectSuccessQuickBooksModal] = useState(false);
  const [openConnectFailedQuickBooksModal, setOpenConnectFailedQuickBooksModal] = useState(false);
  const [openDisconnectQuickBooksModal, setOpenDisconnectQuickBooksModal] = useState(false);
  const [openDisconnectSuccessModal, setOpenDisconnectSuccessModal] = useState(false);
  const [openDisconnectFailedModal, setOpenDisconnectFailedModal] = useState(false);
  const [openConfirmDisconnectQuickBooksModal, setOpenConfirmDisconnectQuickBooksModal] =
    useState(false);
  const [selectAccountTypeDropdown, setSelectAccountTypeDropdown] = useState(false);
  const [selectAccountType, setSelectAccountType] = useState('');
  const [businessVerifyCustomerModal, setBusinessVerifyCustomerModal] = useState(false);
  const [companySettingsModal, setCompanySettingsModal] = useState(false);
  const [inforDistSXModal, setInforDistSXModal] = useState(false);
  const [hideTabWhenDropDownOpen, setHideTabWhenDropDownOpen] = useState(false);
  const [openImportCSVModal, setOpenImportCSVModal] = useState(false);
  const [isAppManualImportProgressModalOpen, setIsAppManualImportProgressModalOpen] =
    useState(false);
  useEffect(() => {
    dispatch(getUserDetails());
    return () => {};
  }, []);

  const [platformName, setPlatformName] = useState('');
  const [platformLogo, setPlatformLogo] = useState<any>(null);
  useEffect(() => {
    if (user?.userSupplier?.integrationType === 'INFOR_DIST_SX') {
      setPlatformName('InforDistSX');
    }
    if (
      user?.userSupplier?.integrationType === 'RUTTER_QBD' ||
      user?.userSupplier?.integrationType === 'RUTTER_QBO' ||
      user?.userSupplier?.integrationType === 'QBO'
    ) {
      setPlatformName('Quickbooks');
    }

    if (user?.userSupplier?.integrationType === 'DISTRIBUTION_ONE') {
      setPlatformName('Distribution one');
      setPlatformLogo(d1Logo);
    }
    if (user?.userSupplier?.integrationType === 'SAGE_100') {
      setPlatformName('Sage 100');
      setPlatformLogo(sageLogo);
    }

    return () => {};
  }, [user?.userSupplier?.integrationType]);
  useEffect(() => {
    if (user?.isConnectedToQuickBooks) {
      setOpenConnectQuickBooksModal(false);
    }
    return () => {};
  }, [user?.isConnectedToQuickBooks]);
  useEffect(() => {
    if (supplierManualUploadProgress) {
      setIsAppManualImportProgressModalOpen(true);
    }

    return () => {};
  }, [supplierManualUploadProgress]);
  const getSupplierManualUploadStatus = (supplierId: string) => {
    const suppliersManualUploadProgressRef = realTimeDBRef(
      myRealtimeDB,
      `suppliersManualUploadProgress/${supplierId}`
    );
    const subScription = onValue(suppliersManualUploadProgressRef, (snapshot) => {
      const data = snapshot.val();
      if (data?.status) {
        dispatch(
          updateSupplierManualUploadProgress({ status: data?.status, message: data?.message })
        );
      } else {
        dispatch(updateSupplierManualUploadProgress(null));
      }
    });
    return subScription;
  };

  useEffect(() => {
    let supplierManualUploadStatusUnSubscribe: Unsubscribe | null = null;
    if (user?.userSupplier?.id) {
      supplierManualUploadStatusUnSubscribe = getSupplierManualUploadStatus(user?.userSupplier?.id);
    }
    return () => {
      if (supplierManualUploadStatusUnSubscribe) {
        supplierManualUploadStatusUnSubscribe();
      }
    };
  }, [user?.userSupplier?.id]);

  const getSupplierQueueJobsRunning = (jobSupplierId: string) => {
    const lastSyncTimeRef = realTimeDBRef(myRealtimeDB, `suppliersRunningJobs/${jobSupplierId}`);
    const subScription = onValue(lastSyncTimeRef, (snapshot) => {
      const data = snapshot.val();
      if (data && Object.keys(data).length > 0) {
        dispatch(updateSupplierQueueJobsRunning(Object.keys(data)));
      } else {
        dispatch(updateSupplierQueueJobsRunning([]));
      }
    });
    return subScription;
  };
  useEffect(() => {
    if (selectedMainBuyer) {
      let getSupplierQueueJobsRunningUnSubscribe: Unsubscribe | null = null;
      if (selectedMainBuyer.syncSupplierId) {
        getSupplierQueueJobsRunningUnSubscribe = getSupplierQueueJobsRunning(
          selectedMainBuyer.syncSupplierId
        );
      }
      return () => {
        if (getSupplierQueueJobsRunningUnSubscribe) {
          getSupplierQueueJobsRunningUnSubscribe();
        }
      };
    }

    return () => {};
  }, [selectedMainBuyer]);

  useEffect(() => {
    if (user?.roles) {
      const savedSelectAccountType = localStorage.getItem('selectAccountType');
      const searchParams = new URLSearchParams(location.search);
      const emailFromLink = searchParams.get('email');

      if (user?.roles.includes('SUPPLIER') || user?.roles.includes('SUB_SUPPLIER')) {
        if (
          savedSelectAccountType === 'Buyer' &&
          (user?.roles.includes('BUYER') || user?.roles.includes('MAIN_BUYER'))
        ) {
          setSelectAccountType(savedSelectAccountType);
        } else {
          setSelectAccountType('Supplier');
        }
      } else if (user?.roles.includes('BUYER') || user?.roles.includes('MAIN_BUYER')) {
        if (
          savedSelectAccountType === 'Supplier' &&
          (user?.roles.includes('SUPPLIER') || user?.roles.includes('SUB_SUPPLIER'))
        ) {
          setSelectAccountType(savedSelectAccountType);
        } else {
          setSelectAccountType('Buyer');
        }
      }
    }
  }, [user?.roles]);

  useEffect(() => {
    if (user?.isAdmin && selectAccountType === 'Supplier') {
      dispatch(getSupplierRegistrationStatus());
      dispatch(getDwollaCustomer({ email: user?.userSupplier?.email }));
      if (user.userSupplier?.integrationType?.startsWith('RUTTER_')) {
        dispatch(getRutterPublicKey());
      }
    }
    return () => {};
  }, []);
  useEffect(() => {
    if (user?.isAdmin && selectAccountType === 'Supplier') {
      dispatch(getSupplierRegistrationStatus());
      dispatch(getDwollaCustomer({ email: user?.userSupplier?.email }));
    }
    return () => {};
  }, [user?.isAdmin, selectAccountType]);

  useEffect(() => {
    if (selectAccountType) {
      const mainRoles = !user ? [] : user?.roles.filter((role) => role !== 'DILL_ADMIN');
      if (
        selectAccountType === 'Buyer' &&
        ['/buyerLienReleases', '/companies'].every((path) => !location.pathname.startsWith(path))
      ) {
        if (mainRoles.includes('BUYER')) {
          navigate('/companies');
        } else if (mainRoles.includes('MAIN_BUYER') && selectedMainBuyerId) {
          navigate(`/buyerLienReleases/${selectedMainBuyerId}/overview`);
        }
      }

      if (
        selectAccountType === 'Supplier' &&
        ['/customers', '/invoices', '/salesReceipts', '/payments', '/lienReleases'].every(
          (path) => !location.pathname.startsWith(path)
        )
      ) {
        if (mainRoles.includes('SUPPLIER')) {
          // navigate('/invoices');
        } else if (mainRoles.includes('SUB_SUPPLIER')) {
          navigate('/lienReleases');
        }
      }
    }
  }, [selectAccountType, selectedMainBuyerId]);

  const routePaths = useMemo(
    () => [{ name: 'Invoices', path: '/invoices', image: coinsLightImg, imageActive: coinsImg }],
    []
  );

  const nestedRoutePaths = useMemo(() => {
    const allRoutes: RouteDetails[] = [];
    if (
      user &&
      (user.roles.includes('SUPPLIER') || user.roles.includes('SUB_SUPPLIER')) &&
      selectAccountType === 'Supplier'
    ) {
      const supplierPaths: RouteDetails[] = [
        {
          id: 'customers',
          parentId: 'supplierPaths',
          name: 'Customers',
          path: `/customers`,
          isNested: false,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <UserCircle color={COLORS.GREY_400} size={20} />,
            active: <UserCircle color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        },
        // These tabs are no longer being used
        // {
        //   id: 'invoices',
        //   parentId: 'supplierPaths',
        //   name: 'Invoices',
        //   path: `/invoices`,
        //   isNested: false,
        //   isDisabled: false,
        //   type: '',
        //   icon: {
        //     inActive: <ClipboardText color={COLORS.GREY_400} size={20} />,
        //     active: <ClipboardText color={COLORS.PRIMARY_500} size={20} weight="fill" />,
        //   },
        // },
        // {
        //   id: 'salesReceipts',
        //   parentId: 'supplierPaths',
        //   name: 'Sales Receipts',
        //   path: `/salesReceipts`,
        //   isNested: false,
        //   isDisabled: false,
        //   type: '',
        //   icon: {
        //     inActive: <FileText color={COLORS.GREY_400} size={20} weight="light" />,
        //     active: <FileText color={COLORS.PRIMARY_500} size={20} weight="fill" />,
        //   },
        // },
        {
          id: `payments`,
          parentId: 'supplierPaths',
          name: 'Payments',
          path: `/payments`,
          isNested: false,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <CreditCard color={COLORS.GREY_400} size={20} />,
            active: <CreditCard color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        },
      ];
      allRoutes.push({
        id: 'supplierPaths',
        parentId: '',
        name: '',
        path: ``,
        isNested: false,
        isDisabled: selectAccountType === 'Supplier' && !user?.roles.includes('SUPPLIER'),
        type: 'GROUPED_UNNESTED',
        subRoutes: supplierPaths,
      });
      // allRoutes.push(...supplierPath);
      // allRoutes = [...allRoutes, ...supplierPath];
    }
    if (
      user &&
      (user.roles.includes('SUPPLIER') || user.roles.includes('SUB_SUPPLIER')) &&
      user?.userSupplier &&
      user?.userSupplier.integrationType &&
      (user?.userSupplier.integrationType === 'MANUAL_UPLOAD' ||
        user?.userSupplier.integrationType === 'INFOR_DIST_SX' ||
        user?.userSupplier.integrationType === 'DISTRIBUTION_ONE') &&
      selectAccountType === 'Supplier'
    ) {
      const supplierPaths: RouteDetails[] = [
        {
          id: 'invoices',
          parentId: 'supplierPaths',
          name: 'Invoices',
          path: `/invoices`,
          isNested: false,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <ClipboardText color={COLORS.GREY_400} size={20} />,
            active: <ClipboardText color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        },
      ];
      allRoutes.push({
        id: 'supplierPaths',
        parentId: '',
        name: '',
        path: ``,
        isNested: false,
        isDisabled: selectAccountType === 'Supplier' && !user?.roles.includes('SUPPLIER'),
        type: 'GROUPED_UNNESTED',
        subRoutes: supplierPaths,
      });
      // allRoutes.push(...supplierPath);
      // allRoutes = [...allRoutes, ...supplierPath];
    }
    if (
      user &&
      (user.roles.includes('SUPPLIER') || user.roles.includes('SUB_SUPPLIER')) &&
      selectAccountType === 'Supplier'
    ) {
      const supplierJobsPaths: RouteDetails[] = [
        {
          id: `supplierJobs`,
          parentId: 'supplierPaths',
          name: 'Jobs',
          path: `/supplierJobs`,
          isNested: false,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <Briefcase color={COLORS.GREY_400} size={20} />,
            active: <Briefcase color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        },
      ];
      allRoutes.push({
        id: 'supplierJobsPaths',
        parentId: '',
        name: '',
        path: ``,
        isNested: false,
        isDisabled: selectAccountType === 'Supplier' && !user?.roles.includes('SUPPLIER'),
        type: 'GROUPED_UNNESTED',
        subRoutes: supplierJobsPaths,
      });
    }
    if (
      user &&
      (user.roles.includes('SUB_SUPPLIER') || user.roles.includes('SUPPLIER')) &&
      selectAccountType === 'Supplier'
    ) {
      const preliminaryNoticesPaths: RouteDetails[] = [
        {
          id: `preliminaryNotices`,
          parentId: 'preliminaryNoticesPaths',
          name: 'Preliminary Notices',
          path: `/preliminaryNotices/overview`,
          isNested: false,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <MegaphoneSimple color={COLORS.GREY_400} size={20} />,
            active: <MegaphoneSimple color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        },
      ];
      console.log(selectAccountType, 'selectAccountType', user.roles);
      allRoutes.push({
        id: 'preliminaryNoticesPaths',
        parentId: '',
        name: '',
        path: ``,
        isNested: false,
        isDisabled:
          selectAccountType === 'Supplier' &&
          user?.roles.includes('SUB_SUPPLIER') &&
          !user.roles.includes('SUPPLIER'),
        type: 'GROUPED_UNNESTED',
        subRoutes: preliminaryNoticesPaths,
      });
    }
    if (
      user &&
      (user.roles.includes('SUB_SUPPLIER') || user.roles.includes('SUPPLIER')) &&
      selectAccountType === 'Supplier'
    ) {
      const releasesPaths: RouteDetails[] = [
        {
          id: `lienReleases`,
          parentId: 'releasesPaths',
          name: 'Lien Releases',
          path: `/lienReleases`,
          isNested: false,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <FileX color={COLORS.GREY_400} size={20} />,
            active: <FileX color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        },
      ];
      allRoutes.push({
        id: 'releasesPaths',
        parentId: '',
        name: '',
        path: ``,
        isNested: false,
        isDisabled: false,
        type: 'GROUPED_UNNESTED',
        subRoutes: releasesPaths,
      });
    }
    if (
      user &&
      user.roles.includes('DILL_ADMIN') &&
      (user.roles.includes('SUPPLIER') || user.roles.includes('SUB_SUPPLIER')) &&
      selectAccountType === 'Supplier'
    ) {
      const noticesPaths: RouteDetails[] = [
        {
          id: `noticeTemplates`,
          parentId: 'noticesPaths',
          name: 'Notice Templates',
          path: `/noticeTemplates/templates`,
          isNested: true,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <ClipboardText color={COLORS.GREY_400} size={20} />,
            active: <ClipboardText color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        },
        {
          id: `noticeTemplateFields`,
          parentId: 'noticesPaths',
          name: 'Notice Fields Templates',
          path: `/noticeTemplateFields`,
          isNested: true,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <ClipboardText color={COLORS.GREY_400} size={20} />,
            active: <ClipboardText color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        },
      ];
      allRoutes.push({
        id: 'noticesPaths',
        parentId: '',
        name: '',
        path: ``,
        isNested: false,
        isDisabled: false,
        type: 'GROUPED_UNNESTED',
        subRoutes: noticesPaths,
      });
    }
    if (
      user &&
      (user.roles.includes('BUYER') || user.roles.includes('MAIN_BUYER')) &&
      selectAccountType === 'Buyer'
    ) {
      let companiesSubRoutes: RouteDetails[] = [];

      companiesSubRoutes = [
        {
          id: `invoices`,
          parentId: 'companies',
          name: 'Invoices',
          path: `/companies/${selectedMainBuyerId}/invoices`,
          isNested: true,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <ClipboardText color={COLORS.GREY_400} size={20} />,
            active: <ClipboardText color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        },
        {
          id: `payments`,
          parentId: 'companies',
          name: 'Payments',
          path: `/companies/${selectedMainBuyerId}/payments`,
          isNested: true,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <CreditCard color={COLORS.GREY_400} size={20} />,
            active: <CreditCard color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        },
      ];

      allRoutes.push({
        id: 'companies',
        parentId: '',
        name: '',
        path: `/companies`,
        isDisabled: false,
        isNested: true,
        type: 'DROP_DOWN_SELECT',
        subRoutes: companiesSubRoutes,
      });
    }
    if (
      user &&
      (user.roles.includes('MAIN_BUYER') || user.roles.includes('BUYER')) &&
      selectAccountType === 'Buyer' &&
      selectedMainBuyerId
    ) {
      const lienReleasesSubRoutes: RouteDetails[] = [
        {
          id: `overview`,
          parentId: 'buyerLienReleases',
          name: 'Overview',
          path: `/buyerLienReleases/${selectedMainBuyerId}/overview`,
          isNested: true,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <Database color={COLORS.GREY_400} size={20} />,
            active: <Database color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        },
        {
          id: `lienReleasesSupplier`,
          parentId: 'buyerLienReleases',
          name: 'Suppliers',
          path: `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesSupplier`,
          isNested: true,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <UserSquare color={COLORS.GREY_400} size={20} />,
            active: <UserSquare color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        },
        {
          id: `lienReleasesJobs`,
          parentId: 'buyerLienReleases',
          name: 'Jobs',
          path: `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs`,
          isNested: true,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <Briefcase color={COLORS.GREY_400} size={20} />,
            active: <Briefcase color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        },
        {
          id: `templates`,
          parentId: 'buyerLienReleases',
          name: 'Templates',
          path: `/buyerLienReleases/${selectedMainBuyerId}/templates`,
          isNested: true,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <ClipboardText color={COLORS.GREY_400} size={20} />,
            active: <ClipboardText color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        },
      ];
      if (user.roles.includes('DILL_ADMIN')) {
        lienReleasesSubRoutes.push({
          id: `templateFields`,
          parentId: 'buyerLienReleases',
          name: 'Template Fields',
          path: `/buyerLienReleases/${selectedMainBuyerId}/templateFields`,
          isNested: true,
          isDisabled: false,
          type: '',
          icon: {
            inActive: <ClipboardText color={COLORS.GREY_400} size={20} />,
            active: <ClipboardText color={COLORS.PRIMARY_500} size={20} weight="fill" />,
          },
        });
      }
      allRoutes.push({
        id: `buyerLienReleases`,
        name: 'Lien Releases',
        path: `/buyerLienReleases/${selectedMainBuyerId}/overview`,
        isNested: false,
        isDisabled: false,
        parentId: '',
        type: 'EXPANDABLE',
        subRoutes: lienReleasesSubRoutes,
        // subRoutes: [],
        icon: {
          inActive: <FileX color={COLORS.GREY_400} size={20} />,
          active: <FileX color={COLORS.PRIMARY_500} size={20} weight="fill" />,
        },
      });
    }
    return allRoutes;
  }, [location.pathname, user?.id, selectAccountType, selectedMainBuyerId, user?.userMainBuyers]);

  useEffect(() => {
    if (location.pathname) {
      const routeCheck = routePaths.find((p) => p.path === location.pathname);

      if (location.search === '?success=true') {
        setOpenConnectSuccessQuickBooksModal(true);
        navigate('/customers');
      }
      if (location.search === '?isFromStripeForm=true') {
        dispatch(getSupplierRegistrationStatus());
        setBusinessVerifyCustomerModal(true);
      }
      if (location.search === '?success=false') {
        setOpenConnectFailedQuickBooksModal(true);
        navigate('/customers');
      }
      if (location.search === '?disconnected=true') {
        setOpenConfirmDisconnectQuickBooksModal(false);
        setOpenDisconnectSuccessModal(true);
        navigate('/customers');
      }
      if (location.search === '?disconnected=false') {
        setOpenConfirmDisconnectQuickBooksModal(false);
        setOpenDisconnectFailedModal(true);
        navigate('/customers');
      }
    }
    return () => {};
  }, [location.pathname, routePaths]);

  const handleOpenDropdown = (value: boolean) => {
    setHideTabWhenDropDownOpen(value);
  };

  const handleProfileViewMenuClick = (item: string) => {
    if (item === 'quickBooks') {
      if (
        user?.userSupplier &&
        ((user?.userSupplier?.integrationType?.startsWith('CODAT_') &&
          user?.userSupplier?.integrationDetails?.codat?.companyId &&
          user?.userSupplier?.integrationDetails?.codat?.connectorId) ||
          (user?.userSupplier?.integrationType?.startsWith('RUTTER_') &&
            user?.userSupplier?.integrationDetails?.rutter?.connectionId))
      ) {
        setOpenDisconnectQuickBooksModal(true);
        // setOpenConnectQuickBooksModal(true);
      } else {
        setOpenConnectQuickBooksModal(true);
      }
    }
    if (item === 'logout') {
      localStorage.removeItem('pastPathList');
      localStorage.removeItem('referPath');
      localStorage.removeItem('emailFromLink');
      dispatch(logoutUser());
    }
    if (item === 'disconnect') {
      setOpenDisconnectQuickBooksModal(true);
    }
    if (item === 'connectDwolla') {
      setBusinessVerifyCustomerModal(true);
    }
    if (item === 'companySettings') {
      setCompanySettingsModal(true);
    }
    if (item == 'inforDistSX') {
      setInforDistSXModal(true);
    }
  };

  const handleRetryConnectQuickbooks = async () => {
    await setOpenConnectFailedQuickBooksModal(false);
    handleProfileViewMenuClick('quickBooks');
  };

  const handleDisconnectQuickBooks = () => {
    dispatch(disconnectQuickbooks({ supplierDomainId: user?.userSupplier?.domainId || '' }));
  };

  useEffect(() => {
    if (quickBooksAuthUri) {
      window.location.href = quickBooksAuthUri;
      clearQuickBooksAuthUri();
    }

    return () => {};
  }, [quickBooksAuthUri]);

  const toggleSelectAccountTypeDropdown = () => {
    if (
      (user?.roles.includes('BUYER') || user?.roles.includes('MAIN_BUYER')) &&
      (user?.roles.includes('SUPPLIER') || user?.roles.includes('SUB_SUPPLIER'))
    ) {
      setSelectAccountTypeDropdown(!selectAccountTypeDropdown);
    }
  };
  const handleSelectAccountType = (accountType: string) => {
    if (
      accountType === 'Buyer' &&
      (user?.roles.includes('BUYER') || user?.roles.includes('MAIN_BUYER'))
    ) {
      if (user?.roles.includes('BUYER')) {
        navigate('/companies');
      } else if (selectedMainBuyerId) {
        navigate(`/buyerLienReleases/${selectedMainBuyerId}/overview`);
      } else if (!selectedMainBuyerId && user.userMainBuyers && user.userMainBuyers.length > 0) {
        navigate(`/buyerLienReleases/${user.userMainBuyers[0].id}/overview`);
      }
      localStorage.setItem('selectAccountType', accountType);
      setSelectAccountType(accountType);
    } else if (
      accountType === 'Supplier' &&
      (user?.roles.includes('SUB_SUPPLIER') || user?.roles.includes('SUPPLIER'))
    ) {
      if (user?.roles.includes('SUPPLIER')) {
        navigate('/customers');
      } else {
        navigate('/lienReleases');
      }
      localStorage.setItem('selectAccountType', accountType);
      setSelectAccountType(accountType);
    }

    setSelectAccountTypeDropdown(false);
  };
  const currentLocation = useMemo(() => {
    let result = '';
    const splitLocation = location.pathname.split('/');
    if (splitLocation.includes('invoices')) {
      result = 'invoices';
    } else {
      result = 'payments';
    }
    if (splitLocation.includes('buyerLienReleases')) {
      result = 'buyerLienReleases';
    }
    return result;
  }, [location.pathname]);

  const handleRefreshAccount = async () => {
    await dispatch(refreshAccounting());
    toast.info('Refreshing data from your accounting system...', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };

  const onSuccess = async (publicToken: string) => {
    const results = await dispatch(processRutterPublicToken({ publicToken }));
    if (results && results.type === 'auth/processRutterPublicToken/fulfilled') {
      openDillToast({ type: 'SUCCESS', message: 'Connection success' });
      setOpenConnectSuccessQuickBooksModal(true);
      dispatch(getUserDetails());
    } else {
      openDillToast({ type: 'ERROR', message: 'Error processing connection' });
    }
  };

  const { open } = useRutterLink({
    // publicKey: rutterPublicKey || '',
    publicKey: REACT_APP_RUTTER_PUBLIC_KEY || '',
    onSuccess: onSuccess,
  });

  const handleDownloadInvoiceStatements = async () => {
    const res = await dispatch(
      mainBuyerDownloadSupplierInvoiceStatements({
        mainBuyerId: selectedMainBuyerId || '',
        supplierDomainId: params.supplierDomainId || '',
      })
    );
    if (res && res.type === 'invoice/mainBuyerDownloadSupplierInvoiceStatements/fulfilled') {
      openDillToast({ type: 'SUCCESS', message: 'Invoice statement download success' });
    } else {
      openDillToast({ type: 'ERROR', message: 'Invoice statement download failed' });
    }
  };

  return (
    <div className="dashboard-container">
      <AppManualImportProgressModal
        open={isAppManualImportProgressModalOpen}
        handleClose={() => {
          setIsAppManualImportProgressModalOpen(false);
        }}
      />
      <AppCustomerSettingsModal
        open={companySettingsModal}
        handleClose={() => {
          setCompanySettingsModal(false);
        }}
      />
      <AppManualImportCSVModal
        open={openImportCSVModal}
        handleClose={() => {
          setOpenImportCSVModal(false);
        }}
      />
      <AppDistributionOneConnectionModal
        open={isAppDistributionOneConnectionModalOpen}
        handleClose={() => {
          setIsAppDistributionOneConnectionModalOpen(false);
        }}
      />
      <AppSage100ConnectionModal
        open={isAppSage100ConnectionModalOpen}
        handleClose={() => {
          setIsAppSage100ConnectionModalOpen(false);
        }}
      />
      <AuthorizeQuickbooksConnect
        open={openConnectQuickBooksModal}
        handleClose={() => {
          setOpenConnectQuickBooksModal(false);
        }}
        platformName={platformName}
        modalImage={user?.userSupplier?.integrationType !== 'QBO' ? platformLogo : null}
        handleAuthorizeButtonClick={() => {
          if (user?.userSupplier?.integrationType?.startsWith('RUTTER_')) {
            open();
            setOpenConnectQuickBooksModal(false);
          } else if (user?.userSupplier?.integrationType?.startsWith('DISTRIBUTION_ONE')) {
            setIsAppDistributionOneConnectionModalOpen(true);
            setOpenConnectQuickBooksModal(false);
          } else if (user?.userSupplier?.integrationType?.startsWith('SAGE_100')) {
            setIsAppSage100ConnectionModalOpen(true);
            setOpenConnectQuickBooksModal(false);
          } else {
            dispatch(getQuickBooksAuthUri());
          }
        }}
      />
      <ConnectQuickBooksModal
        titleText="Authorization succeeded"
        subTitleText={`Your ${platformName} account is now connected to Dill`}
        buttonText="Back to Dashboard"
        isSuccess={false}
        modalImage={
          user?.userSupplier?.integrationType !== 'QBO'
            ? platformLogo
            : quickBooksConnectSuccessIcon
        }
        open={openConnectSuccessQuickBooksModal}
        handleClose={() => {
          setOpenConnectSuccessQuickBooksModal(false);
        }}
        handleButtonClick={() => {
          setOpenConnectSuccessQuickBooksModal(false);
        }}
      />

      <ConnectQuickBooksModal
        titleText="Authorization failed"
        subTitleText=""
        buttonText="Retry"
        isSuccess={false}
        modalImage={quickBooksConnectFailedIcon}
        open={openConnectFailedQuickBooksModal}
        secondaryButton={true}
        secondaryButtonText="Back to Dashboard"
        handleSecondaryButtonClick={() => {
          setOpenConnectFailedQuickBooksModal(false);
        }}
        handleClose={() => {
          setOpenConnectFailedQuickBooksModal(false);
        }}
        handleButtonClick={handleRetryConnectQuickbooks}
      />
      <ConnectQuickBooksModal
        titleText="Error getting authorization URL"
        subTitleText=""
        buttonText="Retry"
        isSuccess={false}
        secondaryButton={true}
        secondaryButtonText="Back to Dashboard"
        handleSecondaryButtonClick={() => {
          dispatch(clearQuickBooksAuthUri());
        }}
        modalImage={quickBooksConnectWarnIcon}
        open={isGetQuickBooksAuthUrlFailed}
        handleClose={() => {
          dispatch(clearQuickBooksAuthUri());
        }}
        handleButtonClick={handleRetryConnectQuickbooks}
      />
      <ConnectQuickBooksModal
        titleText={
          !user?.isConnectedToQuickBooks &&
          user?.userSupplier &&
          ((user?.userSupplier?.integrationType?.startsWith('CODAT_') &&
            user?.userSupplier?.integrationDetails?.codat?.companyId &&
            user?.userSupplier?.integrationDetails?.codat?.connectorId) ||
            (user?.userSupplier?.integrationType?.startsWith('RUTTER_') &&
              user?.userSupplier?.integrationDetails?.rutter?.connectionId))
            ? user?.userSupplier?.integrationDetails?.codat &&
              user?.userSupplier?.integrationDetails?.codat.currency &&
              user?.userSupplier?.integrationDetails?.codat.currency !== 'USD'
              ? `'${user?.userSupplier?.integrationDetails?.codat.currency}' currency not supported `
              : 'Finalizing Quickbooks Connection'
            : user?.userSupplier?.integrationType !== 'QBO'
            ? `${platformName} Connected`
            : 'Quickbooks Connected'
        }
        subTitleText={
          !user?.isConnectedToQuickBooks &&
          user?.userSupplier &&
          ((user?.userSupplier?.integrationType?.startsWith('CODAT_') &&
            user?.userSupplier?.integrationDetails?.codat?.companyId &&
            user?.userSupplier?.integrationDetails?.codat?.connectorId) ||
            (user?.userSupplier?.integrationType?.startsWith('RUTTER_') &&
              user?.userSupplier?.integrationDetails?.rutter?.connectionId))
            ? user?.userSupplier?.integrationDetails?.codat &&
              user?.userSupplier?.integrationDetails?.codat.currency &&
              user?.userSupplier?.integrationDetails?.codat.currency !== 'USD'
              ? 'Please update your home currency to “USD.” After doing so, reconnect below and immediately click “Exit” to trigger the sync.'
              : `Your  ${platformName} account is securely connected to Dill but one final step is fetching the expense and vendor accounts which should take a few minutes`
            : `Your  ${platformName} account is securely connected to Dill`
        }
        buttonText={
          user?.userSupplier &&
          user?.userSupplier?.integrationType?.startsWith('CODAT_') &&
          user?.userSupplier?.integrationDetails?.codat?.companyId &&
          user?.userSupplier?.integrationDetails?.codat?.connectorId &&
          user?.userSupplier?.integrationDetails?.codat.currency &&
          user?.userSupplier?.integrationDetails?.codat.currency !== 'USD'
            ? 'Reconnect'
            : 'Back to Dashboard'
        }
        modalImage={
          user?.userSupplier?.integrationType !== 'QBO'
            ? platformLogo
            : quickBooksConnectSuccessIcon
        }
        open={openDisconnectQuickBooksModal}
        secondaryButton={user?.isAdmin}
        secondaryButtonText="Disconnect"
        handleSecondaryButtonClick={() => {
          setOpenDisconnectQuickBooksModal(false);
          setOpenConfirmDisconnectQuickBooksModal(true);
        }}
        handleClose={() => {
          setOpenDisconnectQuickBooksModal(false);
        }}
        handleButtonClick={() => {
          if (
            user?.userSupplier &&
            user?.userSupplier?.integrationType?.startsWith('CODAT_') &&
            user?.userSupplier?.integrationDetails?.codat?.companyId &&
            user?.userSupplier?.integrationDetails?.codat?.connectorId &&
            user?.userSupplier?.integrationDetails?.codat.currency &&
            user?.userSupplier?.integrationDetails?.codat.currency !== 'USD'
          ) {
            dispatch(getQuickBooksAuthUri());
          } else {
            setOpenDisconnectQuickBooksModal(false);
          }
        }}
      />
      <ConnectQuickBooksModal
        titleText={`Are you sure you want to\ndisconnect your\n${platformName}?`}
        subTitleText={`Your ${platformName} account will be disconnected from Dill`}
        buttonText="No , I’m not"
        secondaryButtonText="Yes, I’m sure"
        additionalInfo={
          user?.userSupplier?.integrationType === 'RUTTER_QBD'
            ? [
                'You need to remove the web connector from your Quickbooks Desktop',
                'Go to: File -> App Management -> Update Web Services',
                'Remove Dill from your list of applications',
                'You will no longer see your invoices in the "Invoices” tab',
                'Your customers will no longer see their invoices from you',
              ]
            : [
                'You will no longer see your invoices in the "Invoices” tab',
                'Your customers will no longer see their invoices from you',
              ]
        }
        modalImage={
          user?.userSupplier?.integrationType !== 'QBO'
            ? platformLogo
            : quickBooksConnectSuccessIcon
        }
        open={openConfirmDisconnectQuickBooksModal}
        secondaryButton={true}
        handleSecondaryButtonClick={async () => {
          handleDisconnectQuickBooks();
        }}
        handleClose={() => {
          setOpenConfirmDisconnectQuickBooksModal(false);
        }}
        handleButtonClick={() => {
          setOpenConfirmDisconnectQuickBooksModal(false);
        }}
      />
      <ConnectQuickBooksModal
        titleText="Successfully disconnected!"
        subTitleText={`Your  ${platformName} account is now disconnected  from Dill.   ${
          user?.userSupplier && user?.userSupplier?.integrationType?.startsWith('CODAT_')
            ? 'You may need to click on “remove connection” in your QBD connector if you haven’t already'
            : ''
        }`}
        buttonText="Back to Dashboard"
        modalImage={
          user?.userSupplier?.integrationType !== 'QBO' ? platformLogo : quickBooksConnectFailedIcon
        }
        open={openDisconnectSuccessModal}
        handleClose={() => {
          setOpenDisconnectSuccessModal(false);
        }}
        handleButtonClick={() => {
          setOpenDisconnectSuccessModal(false);
        }}
      />
      <ConnectQuickBooksModal
        titleText="Failed to disconnect"
        subTitleText=""
        buttonText="Retry"
        isSuccess={false}
        secondaryButton={true}
        secondaryButtonText={'Back to Dashboard'}
        modalImage={
          user?.userSupplier?.integrationType !== 'QBO' ? platformLogo : quickBooksConnectWarnIcon
        }
        open={openDisconnectFailedModal}
        handleButtonClick={async () => {
          await setOpenDisconnectFailedModal(false);
          setOpenDisconnectQuickBooksModal(true);
        }}
        handleClose={() => {
          setOpenDisconnectFailedModal(false);
        }}
        handleSecondaryButtonClick={() => {
          setOpenDisconnectFailedModal(false);
        }}
      />
      {/* <AppDwollaCustomerDropIn
        open={openDwollaVerifyCustomerModal}
        handleClose={async () => {
          await dispatch(getUserDetails());
          await setOpenDwollaVerifyCustomerModal(false);
          // setBusinessVerifyCustomerModal(true);
        }}
      /> */}
      <AppVerifyBusinessModal
        open={businessVerifyCustomerModal}
        handleClose={() => {
          setBusinessVerifyCustomerModal(false);
        }}
        onVerifyACH={async () => {
          // await setBusinessVerifyCustomerModal(false);
          // await setOpenDwollaVerifyCustomerModal(true);
        }}
        onVerifyBankAccount={async () => {
          // await setBusinessVerifyCustomerModal(false);
          // await setIsAppAddPaymentMethodModalOpen(true);
        }}
      />
      <AppAuthorizeInforDistSXModal
        open={inforDistSXModal}
        handleClose={() => {
          setInforDistSXModal(false);
        }}
        isConnected={user?.isConnectedToQuickBooks}
      />
      <section className="side-nav flex flex-col z-10">
        <div className="flex relative">
          <div className="company-logo">
            <img src={logoImg} />
            <p className="company-name">Dill</p>
          </div>
          {user?.roles && user?.roles.length > 0 && (
            <div className="absolute left-24 top-2">
              <button className="select-account-button" onClick={toggleSelectAccountTypeDropdown}>
                <div className="select-account-text">{selectAccountType}</div>
                {(user?.roles.includes('BUYER') || user?.roles.includes('MAIN_BUYER')) &&
                  (user?.roles.includes('SUPPLIER') || user?.roles.includes('SUB_SUPPLIER')) && (
                    <>
                      {selectAccountTypeDropdown ? (
                        <CaretUp size={16} color={COLORS.PRIMARY_500} />
                      ) : (
                        <CaretDown size={16} color={COLORS.PRIMARY_500} />
                      )}
                    </>
                  )}
              </button>
              {selectAccountTypeDropdown && (
                <button
                  className="account-button"
                  onClick={() =>
                    handleSelectAccountType(
                      `${selectAccountType === 'Buyer' ? 'Supplier' : 'Buyer'}`
                    )
                  }>
                  <p className="account-text">{`${
                    selectAccountType === 'Buyer' ? 'Supplier' : 'Buyer'
                  }`}</p>
                </button>
              )}
            </div>
          )}
        </div>
        <div className="flex flex-col h-full ">
          <div className="flex flex-col h-full mt-12 relative overflow-y-scroll">
            {nestedRoutePaths.map((route, routeIndex) => {
              const pathname = location.pathname;
              const pathSplit = pathname.split('/');
              if (route.type === '') {
                return (
                  <div key={routeIndex + 'ee'} className="">
                    <Link
                      to={route.path}
                      className={twMerge(
                        'flex h-10 mb-2 items-center px-4 rounded-lg opacity-20 ',
                        pathSplit.length > 1 && route.id === pathSplit[1]
                          ? 'GREY_100-BG'
                          : 'hover:bg-slate-50  '
                      )}>
                      {pathSplit.length > 1 &&
                        route.id === pathSplit[1] &&
                        route?.icon &&
                        route?.icon.active}
                      {pathSplit.length > 1 &&
                        route.id !== pathSplit[1] &&
                        route?.icon &&
                        route?.icon.inActive}
                      <p
                        className={
                          ' font-semibold text-sm ml-3.5 ' +
                          (pathSplit.length > 1 && route.id === pathSplit[1]
                            ? 'TEXT_PRIMARY'
                            : 'GREY_500-CLR')
                        }>
                        {route.name.substring(0, 15)}
                        {route.name.length > 17 && '...'}
                      </p>
                    </Link>
                  </div>
                );
              }
              if (route.type === 'GROUPED_UNNESTED') {
                return (
                  <div key={routeIndex + 'ee'} className="">
                    <div className={twMerge('relative', route.isDisabled ? 'opacity-80' : '')}>
                      {route.isDisabled && (
                        <div
                          className="absolute top-0 h-full w-full GREY_100-BG rounded-lg -z-10"
                          data-tooltip-id={'locked_user_feature'}></div>
                      )}
                      {route.isDisabled && (
                        <div
                          className="absolute top-0 right-0 h-full w-full GREY_100-BG   opacity-10 rounded-lg z-30"
                          data-tooltip-id={'locked_user_feature'}></div>
                      )}
                      {route.isDisabled && (
                        <Lock
                          data-tooltip-id={'locked_user_feature'}
                          color={COLORS.ERROR_500}
                          className="absolute top-1 right-1 ERROR_200-BG p-2 rounded-full w-8 h-8 z-40"
                        />
                      )}

                      {route.subRoutes &&
                        route.subRoutes.map((subRoute, j) => {
                          const locationPathId = selectedMainBuyerId ? pathSplit[2] : pathSplit[1];
                          return (
                            <Link
                              key={j + 'oo'}
                              to={subRoute.path}
                              className={twMerge(
                                'flex h-10 mb-2 items-center px-4 rounded-lg ',
                                pathSplit.length > 1 && subRoute.id === locationPathId
                                  ? 'GREY_100-BG'
                                  : 'hover:bg-slate-50 '
                              )}>
                              {pathSplit.length > 1 &&
                                subRoute.id === locationPathId &&
                                subRoute?.icon &&
                                subRoute?.icon.active}
                              {pathSplit.length > 1 &&
                                subRoute.id !== locationPathId &&
                                subRoute?.icon &&
                                subRoute?.icon.inActive}
                              <p
                                className={
                                  ' font-semibold text-sm ml-3.5 ' +
                                  (pathSplit.length > 1 && subRoute.id === locationPathId
                                    ? 'TEXT_PRIMARY'
                                    : 'GREY_500-CLR')
                                }>
                                {subRoute.name.substring(0, 15)}
                                {subRoute.name.length > 17 && '...'}
                              </p>
                            </Link>
                          );
                        })}
                    </div>
                  </div>
                );
              }
              if (route.type === 'EXPANDABLE' && !hideTabWhenDropDownOpen) {
                return (
                  <div key={routeIndex + 'ee'} className="">
                    <Link
                      to={route.path}
                      className={twMerge(
                        'flex h-10 mb-2 items-center px-4  rounded-lg justify-between ',
                        pathSplit.length > 1 && route.id === pathSplit[1]
                          ? ' GREY_100-BG'
                          : 'rounded-lg hover:bg-slate-50  '
                      )}>
                      <div className="flex items-center">
                        {pathSplit.length > 1 &&
                          route.id === pathSplit[1] &&
                          route?.icon &&
                          // <img src={route?.icon.imageActive} />
                          // <UserCircle />
                          route?.icon.active}
                        {pathSplit.length > 1 &&
                          route.id !== pathSplit[1] &&
                          route?.icon &&
                          // <img src={route?.icon.image} />
                          // <UserCircle />
                          route?.icon.inActive}
                        <p
                          className={
                            ' font-semibold text-sm ml-3.5 ' +
                            (pathSplit.length > 1 && route.id === pathSplit[1]
                              ? 'TEXT_PRIMARY'
                              : 'GREY_500-CLR')
                          }>
                          {route.name.substring(0, 15)}
                          {route.name.length > 17 && '...'}
                        </p>
                      </div>

                      {route.path !== location.pathname && <CaretDown />}
                      {route.path === location.pathname && <CaretUp />}
                    </Link>
                    <div className="">
                      {pathSplit[1] &&
                        route.subRoutes
                          ?.filter(
                            (subRoute) => subRoute.parentId && pathSplit[1] === subRoute?.parentId
                          )
                          ?.map((subRoute, j) => {
                            const subRoutePathSplit = subRoute.path.split('/');
                            return (
                              <Link
                                key={j + 'oi'}
                                to={subRoute.path}
                                className={twMerge(
                                  'flex h-7 ml-5 mb-2 items-center px-4  rounded-lg',
                                  location.pathname.startsWith(subRoute.path)
                                    ? 'GREY_100-BG'
                                    : 'hover:bg-slate-50  '
                                )}>
                                {pathSplit.length > 1 &&
                                  subRoute.id === pathSplit[3] &&
                                  subRoute?.icon &&
                                  // <img src={route?.icon.imageActive} />
                                  // <UserCircle />
                                  subRoute?.icon.active}
                                {pathSplit.length > 1 &&
                                  subRoute.id !== pathSplit[3] &&
                                  subRoute?.icon &&
                                  // <img src={route?.icon.image} />
                                  // <UserCircle />
                                  subRoute?.icon.inActive}
                                <p
                                  className={
                                    ' font-semibold text-sm ml-3.5 whitespace-nowrap ' +
                                    (location.pathname.startsWith(subRoute.path)
                                      ? 'TEXT_PRIMARY'
                                      : 'GREY_500-CLR')
                                  }>
                                  {subRoute.name.substring(0, 9)}
                                  {subRoute.name.length > 9 && '...'}
                                </p>
                              </Link>
                            );
                          })}
                    </div>
                  </div>
                );
              }
              if (route.type === 'DROP_DOWN_SELECT') {
                return (
                  <div key={route.id} className="relative">
                    <p className="py-2">{route.name}</p>
                    <Dropdown
                      onOpenDropdown={handleOpenDropdown}
                      subRoutes={route?.subRoutes || []}
                      pathSplit={pathSplit}
                    />
                  </div>
                );
              }
            })}
          </div>

          <div className="flex flex-wrap whitespace-pre-wrap w-full self-end justify-self-end pb-8">
            <div className="mb-5">
              {user?.roles.includes('SUPPLIER') &&
                user.isConnectedToQuickBooks &&
                selectAccountType === 'Supplier' &&
                user?.userSupplier?.integrationType?.startsWith('CODAT_') && (
                  <AppButton
                    text={'Refresh Accounting'}
                    type="TERTIARY"
                    onClick={handleRefreshAccount}
                    icon={<RefreshCcw color={COLORS.PRIMARY_500} size={15} />}
                    buttonStyles={{ width: '100%' }}
                  />
                )}
              <p className="text-xs mt-2 font-normal">
                Reach out for customer support or feature requests. We will respond in seconds.
              </p>
              <p className="font-semibold text-xs mt-2">
                Email{' '}
                <span className="PRIMARY_500-CLR whitespace-nowrap">
                  :{' '}
                  <a href="mailto:team@usedill.com" target="_blank">
                    team@usedill.com
                  </a>
                </span>
              </p>
              <p className="font-semibold text-xs mt-2">
                Phone: <span className="PRIMARY_500-CLR whitespace-nowrap">972-339-0799</span>
              </p>
            </div>
            {user && user.roles && user.roles.includes('SUB_SUPPLIER') && (
              <button className="help-button" onClick={() => navigate(`/help`)}>
                <HelpCircle color={COLORS.WARNING_600} />
                <div className="help-text">Help</div>
              </button>
            )}
          </div>
        </div>
      </section>
      <section className="main-section">
        <div className="flex justify-between WHITE-BG px-4 py-3 border-b items-center">
          <div className="left-section ">
            {location.pathname.startsWith('/customers') && (
              <div>
                <h3 className="font-bold TEXT_PRIMARY-CLR  ">Customers</h3>
              </div>
            )}
            {location.pathname.startsWith('/invoices') && (
              <div className="flex justify-between items-center pr-4">
                <h3 className="font-bold TEXT_PRIMARY-CLR  ">Invoices</h3>
              </div>
            )}
            {location.pathname.startsWith('/payments') && (
              <div>
                <h3 className="font-bold TEXT_PRIMARY-CLR  ">Payments</h3>
              </div>
            )}
            {location.pathname.startsWith('/lienReleases') && (
              <div>
                {location.pathname.split('/').length > 2 && (
                  <button
                    className={
                      'flex ' + (location.pathname.split('/').length > 2 ? 'cursor-pointer' : '')
                    }
                    onClick={() => {
                      if (location.pathname.split('/').length > 2) {
                        navigate('/lienReleases');
                      }
                    }}
                    disabled={!(location.pathname.split('/').length > 2)}>
                    {location.pathname.split('/').length > 2 && <ChevronLeft />}
                    <h3 className="font-bold TEXT_PRIMARY-CLR  ml-3">Lien Release</h3>
                    {selectedLiensRelease && (
                      <h3 className="font-bold TEXT_PRIMARY-CLR  ml-1 capitalize">
                        {`  -  ${selectedLiensRelease.buyer?.name ?? ''}`}
                      </h3>
                    )}
                    {/* {selectedLiensRelease && (
                      <h3 className="font-bold TEXT_PRIMARY-CLR  ml-1 capitalize">
                        {`  -  ${
                          moment(selectedLiensRelease.throughDate).format('MM/DD/YYYY') ?? ''
                        }`}
                      </h3>
                    )} */}
                    {selectedLiensRelease && (
                      <h3 className="font-bold TEXT_PRIMARY-CLR  ml-1 capitalize">
                        {`  -  ${selectedLiensRelease.job?.name ?? ''}`}
                      </h3>
                    )}
                  </button>
                )}
                {location.pathname.split('/').length < 3 && (
                  <h3 className="font-bold TEXT_PRIMARY-CLR  ">Lien Releases</h3>
                )}
              </div>
            )}
            {location.pathname.startsWith('/preliminaryNotices') && (
              <div>
                {location.pathname.split('/').length > 3 && (
                  <button
                    className={
                      'flex ' + (location.pathname.split('/').length > 2 ? 'cursor-pointer' : '')
                    }
                    onClick={() => {
                      if (location.pathname.split('/').length > 2) {
                        navigate('/preliminaryNotices/overview');
                      }
                    }}
                    disabled={!(location.pathname.split('/').length > 2)}>
                    {location.pathname.split('/').length > 2 && <ChevronLeft />}
                    <h3 className="font-bold TEXT_PRIMARY-CLR  ml-3">
                      Request Preliminary Notices
                    </h3>
                  </button>
                )}
                {location.pathname.split('/').length < 4 && (
                  <h3 className="font-bold TEXT_PRIMARY-CLR  ">Preliminary Notices</h3>
                )}
              </div>
            )}
            {location.pathname.startsWith('/noticeTemplates') && (
              <div>
                {location.pathname.split('/').length > 3 && (
                  <button
                    className={
                      'flex ' + (location.pathname.split('/').length > 2 ? 'cursor-pointer' : '')
                    }
                    onClick={() => {
                      if (location.pathname.split('/').length > 2) {
                        navigate('/noticeTemplates/templates');
                      }
                    }}
                    disabled={!(location.pathname.split('/').length > 2)}>
                    {location.pathname.split('/').length > 2 && <ChevronLeft />}
                    <h3 className="font-bold TEXT_PRIMARY-CLR  ml-3">
                      Add Preliminary Notices Template
                    </h3>
                  </button>
                )}
                {location.pathname.split('/').length < 4 && (
                  <h3 className="font-bold TEXT_PRIMARY-CLR  ">Preliminary Notices Templates</h3>
                )}
              </div>
            )}
            {location.pathname.startsWith('/noticeTemplateFields') && (
              <div>
                <h3 className="font-bold TEXT_PRIMARY-CLR  ">Add Notice Template Fields</h3>
              </div>
            )}
            {location.pathname.startsWith('/supplierJobs') && (
              <div>
                <h3 className="font-bold TEXT_PRIMARY-CLR  ">Jobs</h3>
              </div>
            )}
            {location.pathname.startsWith(`/buyerLienReleases/${selectedMainBuyerId}/overview`) && (
              <div>
                {location.pathname.length ===
                  `/buyerLienReleases/${selectedMainBuyerId}/overview`.length && (
                  <h3 className="font-bold TEXT_PRIMARY-CLR  ">Lien Releases</h3>
                )}
                {location.pathname.length >
                  `/buyerLienReleases/${selectedMainBuyerId}/overview`.length && (
                  <button
                    className={'flex cursor-pointer'}
                    onClick={() => {
                      navigate(`/buyerLienReleases/${selectedMainBuyerId}/overview`);
                    }}>
                    <ChevronLeft />
                    <h3 className="font-bold TEXT_PRIMARY-CLR  ml-3">
                      {location.pathname.split('/').includes('addLienRelease')
                        ? 'Request Release'
                        : location.pathname.split('/').includes('manualAddLienRelease')
                        ? 'Manual Add Signed Release'
                        : 'Edit Release'}
                    </h3>
                  </button>
                )}
              </div>
            )}
            {location.pathname.startsWith(
              `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesSupplier`
            ) && (
              <div>
                <h3 className="font-bold TEXT_PRIMARY-CLR  ">Suppliers</h3>
              </div>
            )}
            {location.pathname.startsWith(
              `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs`
            ) && (
              <div className="flex">
                <button
                  className={
                    'flex ' +
                    (location.pathname.length >
                    `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs`.length
                      ? 'cursor-pointer'
                      : '')
                  }
                  onClick={() => {
                    if (location.pathname.split('/').length === 5) {
                      navigate(`/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs`);
                    } else if (
                      location.pathname.split('/').length === 6 ||
                      location.pathname.split('/').length === 7
                    ) {
                      navigate(
                        `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs/${selectedJob?.id}`
                      );
                    }
                  }}
                  disabled={!(location.pathname.split('/').length > 4)}>
                  {location.pathname.split('/').length > 4 && <ChevronLeft />}
                  <h3 className="font-bold TEXT_PRIMARY-CLR  ">
                    {location.pathname.length ===
                    `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs`.length
                      ? 'Jobs'
                      : location.pathname.includes('addRelease')
                      ? 'Request Release'
                      : location.pathname.includes('editRelease')
                      ? 'Edit Job Release'
                      : `# ${selectedJob?.number ?? ''} ${selectedJob?.name ?? ''}`}
                  </h3>
                </button>
              </div>
            )}
            {location.pathname.startsWith(
              `/buyerLienReleases/${selectedMainBuyerId}/templates`
            ) && (
              <div className="flex">
                {/* <h3 className="font-bold TEXT_PRIMARY-CLR  ">Templates</h3> */}

                {selectedMainBuyer !== null && (
                  <button
                    className={
                      'flex ' +
                      (location.pathname.length >
                      `/buyerLienReleases/${selectedMainBuyerId}/templates`.length
                        ? 'cursor-pointer'
                        : '')
                    }
                    onClick={() => {
                      if (
                        location.pathname.length >
                        `/buyerLienReleases/${selectedMainBuyerId}/templates`.length
                      ) {
                        navigate(`/buyerLienReleases/${selectedMainBuyerId}/templates`);
                      }
                    }}
                    disabled={
                      location.pathname.length ===
                      `/buyerLienReleases/${selectedMainBuyerId}/templates`.length
                    }>
                    {location.pathname.length >
                      `/buyerLienReleases/${selectedMainBuyerId}/templates`.length && (
                      <ChevronLeft />
                    )}
                    <h3 className="font-bold TEXT_PRIMARY-CLR  ml-1 capitalize">Templates</h3>
                  </button>
                )}
              </div>
            )}
            {location.pathname.startsWith(`/buyerLienReleases/templateFields`) && (
              <div>
                <h3 className="font-bold TEXT_PRIMARY-CLR  ">Add Template With Fields</h3>
              </div>
            )}
            {location.pathname.startsWith('/help') && (
              <div>
                <h3 className="font-bold TEXT_PRIMARY-CLR  ">Help</h3>
              </div>
            )}
            {location.pathname.startsWith('/salesReceipts') && (
              <div>
                <h3 className="font-bold TEXT_PRIMARY-CLR  ">Sales Receipts</h3>
              </div>
            )}
            {location.pathname.startsWith('/companies') && (
              <div className="flex">
                {selectedMainBuyer !== null && (
                  <button
                    className={
                      'flex ' + (location.pathname.split('/').length > 4 ? 'cursor-pointer' : '')
                    }
                    onClick={() => {
                      if (location.pathname.split('/').length > 4) {
                        navigate(`/companies/${selectedMainBuyerId}/invoices`);
                      }
                    }}
                    disabled={!(location.pathname.split('/').length > 4)}>
                    {params && params.supplierDomainId && <ChevronLeft />}
                    {!params.supplierDomainId && (
                      <h3 className="font-bold TEXT_PRIMARY-CLR  ml-1 capitalize">
                        {`${selectedMainBuyer?.name.toLocaleLowerCase()} ${currentLocation}`}
                      </h3>
                    )}
                    {selectedSupplierForBuyer &&
                      location.pathname.startsWith(
                        `/companies/${selectedMainBuyerId}/invoices`
                      ) && (
                        <h3 className="font-bold TEXT_PRIMARY-CLR  ml-1 whitespace-nowrap text-xl">
                          {`${capitalizeWordsInSentence(
                            selectedSupplierForBuyer.name?.toLocaleLowerCase()
                          )} Open Items for ${capitalizeWordsInSentence(
                            selectedMainBuyer?.name.toLocaleLowerCase()
                          )}`}
                        </h3>
                      )}
                  </button>
                )}
                {selectedMainBuyer === null && <h3 className="page-title">{}</h3>}
              </div>
            )}
          </div>

          <div className="right-section flex items-center">
            {location.pathname.startsWith('/companies') &&
              selectedMainBuyerId &&
              location.pathname.startsWith(`/companies/${selectedMainBuyerId}/invoices`) &&
              location.pathname.split('/').length > 4 && (
                <div className="mr-2">
                  <AppButton
                    text="Download statement"
                    icon={<FilePlus color={COLORS.WHITE} size={19} />}
                    onClick={handleDownloadInvoiceStatements}
                  />
                </div>
              )}
            {user?.userSupplier &&
              user?.userSupplier.integrationType === 'MANUAL_UPLOAD' &&
              selectAccountType === 'Supplier' && (
                <div className="mr-2">
                  <AppButton
                    text="Upload Data"
                    iconPosition="RIGHT"
                    icon={<PlusCircle size={20} />}
                    onClick={() => setOpenImportCSVModal(true)}
                  />
                </div>
              )}
            <AppProfileView
              onMenuClick={handleProfileViewMenuClick}
              modeType={selectAccountType}
              platformName={platformName}
            />
          </div>
        </div>
        <div className="details">
          <Outlet />
        </div>
        <ReactTooltip
          id={'locked_user_feature'}
          place="right"
          variant="dark"
          opacity={'100%'}
          style={{
            background: '#222',
            width: '300px',
            zIndex: 99999,
          }}>
          <span>
            These features are locked. If you are interested in learning more, please reach out to
            customer support below
          </span>
        </ReactTooltip>
      </section>
      {width / height < 1 && width > 0 && (
        <div className="warning-pop-up z-50">
          <AlertTriangle color={COLORS.WARNING_600} size={50} />
          <div className="warning-text">
            To get the best experience of the Dill portal, please login on the web or expand your
            window width.
          </div>
        </div>
      )}
    </div>
  );
};
