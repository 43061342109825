import { inforDistSXInvoicesResponse } from '@dill/dill-shared';
import { createSlice } from '@reduxjs/toolkit';

import { getInforDistSXInvoicesExtraReducer } from '../reducers/inforDistSXReducers';

export interface InforDistSXInitialState {
  invoices: inforDistSXInvoicesResponse[];
  numberOfInvoices: number | null;
  lastRefKey: string | null;
}

const initialState: InforDistSXInitialState = {
  invoices: [],
  numberOfInvoices: null,
  lastRefKey: null,
};

export const inforDistSXSlice = createSlice({
  name: 'inforDistSX',
  initialState,
  reducers: {
    resetInforDistSXRootState: () => initialState,
  },
  extraReducers(builder) {
    getInforDistSXInvoicesExtraReducer(builder);
  },
});
