import { Calendar } from 'phosphor-react';
import React from 'react';
import moment from 'moment';

import DatePicker from 'react-datepicker';
import { twMerge } from 'tailwind-merge';

const AppDatePicker = ({
  selectedDate,
  onDateSelected,
  wrapperClasses = '',
  pickerClasses = '',
  errorText = '',
  label = '',
  labelTextClassName = '',
  isRequired = false,
  minumumDate = null,
  dateFormat,
  showMonthYearPicker = false,
  maximumDate = null,
  onBlur = (e) => {},
  onFocus = (e) => {},
  onDateSelect = (date, e) => {},
  isDisabled = false,
}: {
  selectedDate: Date | null | undefined;
  onDateSelected: (val: Date | null | undefined) => void;
  wrapperClasses?: string;
  pickerClasses?: string;
  errorText?: string;
  label?: string;
  labelTextClassName?: string;
  isRequired?: boolean;
  minumumDate?: Date | null;
  maximumDate?: Date | null;
  dateFormat?: string;
  showMonthYearPicker?: boolean;
  onBlur?: (props: any) => void;
  onFocus?: (props: any) => void;
  onDateSelect?: (date: Date | null | undefined, props: any) => void;
  isDisabled?: boolean;
}) => {
  return (
    <div className="w-full flex flex-col">
      {(label || isRequired) && (
        <p className={twMerge('text-xs mb-0.5  whitespace-nowrap font-medium', labelTextClassName)}>
          {isRequired && <span className="text-red-500">*</span>}
          {label}
        </p>
      )}

      <div className={twMerge('w-full flex flex-col justify-center', wrapperClasses)}>
        {isDisabled ? (
          <div
            className="flex items-center border GREY_200-BG px-1.5 py-2 rounded-lg "
            style={{
              borderColor: errorText ? '#F04438' : '#E4EAF1',
            }}>
            <Calendar className="text-slate-500 mr-2" size={20} />
            <div className={twMerge('w-full text-sm', pickerClasses)}>
              {selectedDate ? moment(selectedDate).format(dateFormat ?? 'MM/DD/YYYY') : ''}
            </div>
          </div>
        ) : (
          <div
            className="flex items-center border px-1.5 py-2 rounded-lg "
            style={{
              borderColor: errorText ? '#F04438' : '#E4EAF1',
            }}>
            <Calendar className="text-slate-500 mr-2" size={20} />
            <DatePicker
              className={twMerge('w-full text-sm', pickerClasses)}
              selected={selectedDate}
              onChange={(val) => {
                onDateSelected(val);
              }}
              minDate={minumumDate}
              dateFormat={dateFormat}
              showMonthYearPicker={showMonthYearPicker}
              maxDate={maximumDate}
              onBlur={onBlur}
              onFocus={onFocus}
              onSelect={onDateSelect}
            />
          </div>
        )}

        {errorText && <p className="text-2xs mt-0.5 text-red-500">{errorText}</p>}
      </div>
    </div>
  );
};

export default AppDatePicker;
