import { SupplierVerifiedJobFormData } from './constants';

const VerifySupplierJob = (props: SupplierVerifiedJobFormData) => {
  const {
    jobAddress,
    jobCity,
    jobState,
    jobZipCode,
    owner,
    ownerAddress,
    ownerCity,
    ownerState,
    ownerZipCode,
    verifiedProperty,
  } = props;

  return (
    <>
      <div className="flex flex-col w-full mb-2 gap-2">
        <p className="font-medium">For the job address entered:</p>
        <p className="text-base font-medium text-blue-500">{`${jobAddress}, ${jobCity}, ${jobState} ${jobZipCode}`}</p>
      </div>
      <div className="flex flex-col w-full mb-2 gap-2">
        <p className="font-medium">Dill automatically identified:</p>
        <div className="flex flex-col px-3 py-4 bg-gray-50 border border-solid gap-3">
          {verifiedProperty ? (
            <>
              <div className="flex flex-row gap-2">
                <span className="text-sm text-gray-500 w-1/4">Property Address</span>
                <span className="text-sm font-medium w-3/4">{`${verifiedProperty?.address}, ${verifiedProperty?.city}, ${verifiedProperty?.state} ${verifiedProperty?.zipCode}`}</span>
              </div>
              <div className="flex flex-row gap-2">
                <span className="text-sm text-gray-500 w-1/4">Property APN</span>
                <span className="text-sm font-medium w-3/4">{verifiedProperty?.apn || 'N/A'}</span>
              </div>
              <div className="flex flex-row gap-2">
                <span className="text-sm text-gray-500 w-1/4">Owner Name</span>
                <span className="text-sm font-medium w-3/4">{owner}</span>
              </div>
              <div className="flex flex-row gap-2">
                <span className="text-sm text-gray-500 w-1/4">Owner Address</span>
                <span className="text-sm font-medium w-3/4">{`${ownerAddress}, ${ownerCity}, ${ownerState} ${ownerZipCode}`}</span>
              </div>
            </>
          ) : (
            <p className="text-sm font-medium text-red-500">No matching property found</p>
          )}
        </div>
      </div>
    </>
  );
};

export default VerifySupplierJob;
