import { Contact, ContactForm, Product, ProductForm } from './interfaces/generalInterfaces';
import axios from 'axios';
import FileDownload from 'js-file-download';
import { myFirebaseStorage } from '../firebase/config';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import JSZip from 'jszip';
import {
  FromFieldTypeNoticeTemplate,
  FromFieldTypeReleaseTemplate,
  LienTemplate,
  NoticeTemplate,
  PDFFieldReleaseTemplate,
  formatMoney,
  promiseNull,
  validateEmail,
  validatePhoneNumber,
} from '@dill/dill-shared';
import { PDFDocument, setFontAndSize, PDFName, PDFRef } from 'pdf-lib';
import moment from 'moment';
import { REACT_APP_FRONTEND_URL } from './constants';
import { toast } from 'react-toastify';

export const createFormFromContact = (contact: Contact): ContactForm => {
  const newContactForm: ContactForm = {
    companyId: { id: 'companyId', name: '', value: contact.companyId, errorText: '' },
    firstName: { id: 'firstName', name: '', value: contact.firstName, errorText: '' },
    lastName: { id: 'lastName', name: '', value: contact.lastName, errorText: '' },
    restaurant: { id: 'restaurant', name: '', value: contact.restaurant, errorText: '' },
    phoneNumber: { id: 'phoneNumber', name: '', value: contact.phoneNumber, errorText: '' },
    emailAddress: { id: 'email', name: '', value: contact.emailAddress, errorText: '' },
    priceListId: {
      id: 'priceListId',
      name: '',
      value: contact.priceListId,
      // value: { value: contact.priceListId, label: contact.priceListId },
      errorText: '',
      options: [],
    },
    isSharePersonalCell: {
      id: 'isSharePersonalCell',
      name: '',
      value: contact.isSharePersonalCell,
      errorText: '',
    },
  };
  return newContactForm;
};
export const createFormFromProduct = (product: Product): ProductForm => {
  const newProductForm: ProductForm = {
    productName: { id: 'productName', name: '', value: product.productName, errorText: '' },
    category: { id: 'category', name: '', value: product.category, errorText: '' },
    price: { id: 'price', name: '', value: product.price, errorText: '' },
    unit: { id: 'unit', name: '', value: product.unit, errorText: '' },
  };
  return newProductForm;
};

export const validateContact = (contact: Contact) => {
  let isError = false;
  const contactForm = createFormFromContact(contact);
  if (!contact.firstName) {
    isError = true;
    contactForm.firstName.errorText = 'This field is required';
  } else {
    contactForm.firstName.errorText = '';
  }
  if (contact.phoneNumber && !validatePhoneNumber(contact.phoneNumber)) {
    isError = true;
    contactForm.phoneNumber.errorText = 'Please input a valid phone number';
  } else {
    contactForm.phoneNumber.errorText = '';
  }
  if (contact.emailAddress && !validateEmail(contact.emailAddress)) {
    isError = true;
    contactForm.emailAddress.errorText = 'Please input a valid email';
  } else {
    contactForm.emailAddress.errorText = '';
  }
  return { contactForm, isError };
};

export const validateProduct = (product: Product) => {
  let isError = false;
  const productForm = createFormFromProduct(product);
  if (!product.productName) {
    isError = true;
    productForm.productName.errorText = 'This field is required';
  } else {
    productForm.productName.errorText = '';
  }
  if (!product.price) {
    isError = true;
    productForm.price.errorText = 'This field is required';
  } else {
    productForm.price.errorText = '';
  }

  return { productForm, isError };
};

export const convertHexToRGBA = (hexCode: string, opacity: number) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity})`;
};

export const DLog = (message: any, obj = null) => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') return;
  console.log(message, obj !== null ? obj : '');
};

export const getProductFromForm = (productForm: ProductForm): Product => {
  const product: Product = {
    productName: productForm.productName.value,
    category: productForm.category.value,
    price: productForm.price.value,
    unit: productForm.unit.value,
    isSaved: false,
  };
  return product;
};

export const getContactFromForm = (contactForm: ContactForm): Contact => {
  const contact: Contact = {
    companyId: contactForm.companyId.value,
    firstName: contactForm.firstName.value,
    lastName: contactForm.lastName.value,
    emailAddress: contactForm.emailAddress.value,
    phoneNumber: contactForm.phoneNumber.value,
    restaurant: contactForm.restaurant.value,
    isSharePersonalCell: contactForm.isSharePersonalCell.value,
    priceListId: contactForm.priceListId.value,
    // previewPriceListLink: contactForm.previewPriceListLink.value,
    isSaved: false,
  };
  return contact;
};

export const downloadFile = (url: string, fileName: string) => {
  axios({
    url,
    method: 'GET',
    responseType: 'blob', // Important
  }).then((response) => {
    FileDownload(response.data, fileName);
  });
};

export const printFile = (url: string, fileName: string) => {
  axios({
    url,
    method: 'GET',
    responseType: 'blob', // Important
  }).then((response) => {
    const file = new Blob([response.data], { type: 'application/pdf' });

    // Create an invisible iframe
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = window.URL.createObjectURL(file);

    document.body.appendChild(iframe);

    iframe.onload = () => {
      iframe.contentWindow?.print();
      // Optional: Remove the iframe after printing
      setTimeout(() => {
        document.body.removeChild(iframe);
      }, 1000);
    };
  });
};

export const firebaseFileUploadHelper = async ({
  blobUrl,
  storagePath,
  fileNameWithExtension,
}: {
  blobUrl: string;
  storagePath: string;
  fileNameWithExtension: string;
}): Promise<string | null> => {
  const templateRef = ref(myFirebaseStorage, `${storagePath}/${fileNameWithExtension}`);
  const pdfBlob = await axios({
    method: 'get',
    url: blobUrl,
    responseType: 'blob',
  });
  if (pdfBlob.data) {
    const uploadResults = await uploadBytes(templateRef, pdfBlob.data);
    // console.log(uploadResults);

    const downloadURL = await getDownloadURL(uploadResults.ref);
    return downloadURL;
  }
  return null;
};

export async function downloadPDFsAsZip(
  pdfFiles: { url: string; name: string }[],
  zipFileName: string
): Promise<void> {
  const zip = new JSZip();

  const downloadPromises = pdfFiles.map(async (file, index) => {
    try {
      const response = await fetch(file.url);
      const pdfData = await response.arrayBuffer();
      const fileName = `${file.name}.pdf`;
      zip.file(fileName, pdfData);
    } catch (error) {
      console.error(`Failed to download ${file.url}: ${error}`);
    }
  });

  await Promise.all(downloadPromises);

  const zipContent = await zip.generateAsync({ type: 'blob' });

  const anchorElement = document.createElement('a');
  anchorElement.href = URL.createObjectURL(zipContent);
  anchorElement.download = zipFileName;
  anchorElement.click();
  URL.revokeObjectURL(anchorElement.href);

  console.log(`Zip file "${zipFileName}" created and downloaded successfully.`);
}

export const isEmailValid = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const extractEmailAddresses = (inputString: string, unique?: boolean): string[] => {
  // Define a regular expression pattern to match email addresses
  const emailRegex = /[\w.+-]+@[\w.+-]+\.[A-Za-z]{1,}/g;

  // Use the regular expression's exec method to find all matches in the input string
  const emailAddresses = [];
  let match;
  while ((match = emailRegex.exec(inputString)) !== null) {
    emailAddresses.push(match[0].trim());
  }
  if (unique) {
    const uniqueEmails: string[] = emailAddresses.filter((email, index, self) => {
      return index === self.findIndex((e) => e === email);
    });
    return uniqueEmails;
  }
  return emailAddresses;
};

export const sentenceToCamelCase = (text?: string) => {
  if (!text) return '';
  return text
    .toLowerCase()
    .replace(/-/g, '')
    .split(' ')
    .reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)));
};

export const getValueUsingStringKeyPath = ({
  keyPath,
  data,
}: {
  keyPath: string;
  data: any;
}): string => {
  const keys = keyPath.split('.');

  let nestedData = data;
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (nestedData && nestedData.hasOwnProperty(key)) {
      nestedData = nestedData[key];
    } else {
      nestedData = '';
      break;
    }
  }
  return nestedData;
};

export const fetchPdf = async (url: string) => {
  const res = await axios.get(url, {
    responseType: 'arraybuffer',
  });
  if (res.data) {
    return res.data;
  }
  return null;
};

export const flattenPDF = async (pdfUrl: string) => {
  try {
    const PdfBytes = await fetchPdf(pdfUrl);
    if (PdfBytes) {
      const pdfDoc = await PDFDocument.load(PdfBytes);
      const form = pdfDoc.getForm();

      // Flatten pdf fields
      form.flatten();
      const modifiedPdfBytes = await pdfDoc.save();
      // Download the modified PDF
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      return url;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const disablePDFFields = async (pdfUrl: string) => {
  try {
    const PdfBytes = await fetchPdf(pdfUrl);
    if (PdfBytes) {
      const pdfDoc = await PDFDocument.load(PdfBytes);
      const form = pdfDoc.getForm();
      // const pages = pdfDoc.getPages();
      // pages.forEach((page) => {
      //   const annots = page.node.Annots();
      //   if (annots) {
      //     const size = annots?.size();
      //     if (size) {
      //       for (let i = 0; i < size; i++) {
      //         annots.remove(i);
      //       }
      //     }
      //   }
      // });
      // Disable fields to read only
      form.getFields().forEach((formField) => formField.enableReadOnly());
      const modifiedPdfBytes = await pdfDoc.save();
      // Download the modified PDF
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      return url;
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const removePDFFields = async (pdfUrl: string) => {
  try {
    const PdfBytes = await fetchPdf(pdfUrl);
    if (PdfBytes) {
      const pdfDoc = await PDFDocument.load(PdfBytes);
      const form = pdfDoc.getForm();
      // Remove form fields from the form
      const formFields = form.getFields();
      formFields.forEach((formField) => {
        const widget0 = formField.acroField.getWidgets()[0];
        const AP = widget0.ensureAP();
        AP.set(PDFName.of('N'), PDFRef.of(0, 0));
        form.removeField(formField);
      });
      const modifiedPdfBytes = await pdfDoc.save();
      // Download the modified PDF
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      return url;
    }

    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const removeSpecificPDFFields = async (pdfUrl: string, fieldId: string) => {
  try {
    const PdfBytes = await fetchPdf(pdfUrl);
    if (PdfBytes) {
      const pdfDoc = await PDFDocument.load(PdfBytes);
      const form = pdfDoc.getForm();
      // Remove form fields from the form
      const tmp = form.getFields().find((f) => f.getName() === fieldId);
      if (tmp) {
        const widget0 = tmp.acroField.getWidgets()[0];
        const AP = widget0.ensureAP();
        AP.set(PDFName.of('N'), PDFRef.of(0, 0));
        form.removeField(tmp);
      }
      const modifiedPdfBytes = await pdfDoc.save();
      // Download the modified PDF
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      return url;
    }

    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const structureTemplateNonNewFieldFromTypePDFData = ({
  template,
  details,
}: {
  template: LienTemplate | NoticeTemplate;
  details: {
    fromFieldType: FromFieldTypeReleaseTemplate | FromFieldTypeNoticeTemplate;
    dataToUse: any;
  }[];
}): PDFFieldReleaseTemplate[] => {
  try {
    if (template) {
      const fieldsToPopulate: PDFFieldReleaseTemplate[] = [];
      details.forEach(({ fromFieldType, dataToUse }) => {
        template.formFields.forEach((formField) => {
          if (formField.canPopulatePDF && formField.fromFieldType === fromFieldType && dataToUse) {
            const fieldData = (formField?.fromFieldDataKeys || [])
              .map((fromFieldDataKey, fromFieldDataKeyIndex) => {
                let value = getValueUsingStringKeyPath({
                  keyPath: fromFieldDataKey,
                  data: dataToUse,
                });
                if (!value && fromFieldDataKey.includes('subUser')) {
                  value = getValueUsingStringKeyPath({
                    keyPath: fromFieldDataKey.replaceAll('subUser', 'user'),
                    data: dataToUse,
                  });
                }
                if (
                  formField.fromFieldDataKeysDelimiters &&
                  formField.fromFieldDataKeysDelimiters[fromFieldDataKeyIndex]
                ) {
                  return value
                    ? `${value}${formField.fromFieldDataKeysDelimiters[fromFieldDataKeyIndex]}`
                    : '';
                }
                return value ? value : '';
              })
              .join(' ');

            formField.pdfFields.forEach((pdfField) => {
              if (formField.fromFieldType === 'SIGNATURE_FIELD') {
                fieldsToPopulate.push({
                  ...pdfField,
                  value: dataToUse,
                });
              } else {
                fieldsToPopulate.push({
                  ...pdfField,
                  value: fieldData || '',
                });
              }
            });
          }
        });
      });

      return fieldsToPopulate;
    }
    return [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const structureTemplateNewFieldFromTypePDFData = ({
  template,
  details,
}: {
  template?: LienTemplate | NoticeTemplate | null;
  details: { fromFieldId: string; value: any }[];
}): PDFFieldReleaseTemplate[] => {
  try {
    if (template) {
      const fieldsToPopulate: PDFFieldReleaseTemplate[] = [];
      details.forEach(({ fromFieldId, value }) => {
        template.formFields.forEach((formField) => {
          if (formField.canPopulatePDF && formField.id === fromFieldId) {
            formField.pdfFields.forEach((pdfField) => {
              if (formField.fieldType === 'DATE') {
                let dateFormat = 'MM/DD/YYYY';
                if (formField.dateSplitFormats && formField.dateSplitFormats.length > 0) {
                  const idSplit = pdfField.id.split('Format');
                  if (idSplit.length > 1) {
                    dateFormat = formField.dateSplitFormats[parseInt(idSplit[1]) - 1];
                  }
                }
                fieldsToPopulate.push({
                  ...pdfField,
                  value: value ? moment(value).format(dateFormat) : '',
                });
              } else if (formField.fieldType === 'CURRENCY') {
                fieldsToPopulate.push({
                  ...pdfField,
                  value: !value && value !== 0 ? '' : formatMoney(value, 2, '.', ',', ''),
                });
              } else if (formField.fieldType === 'NUMBER') {
                fieldsToPopulate.push({
                  ...pdfField,
                  value: !value && value !== 0 ? '' : value,
                });
              } else {
                fieldsToPopulate.push({
                  ...pdfField,
                  value: value ? `${value}` : '',
                });
              }
            });
          }
        });
      });

      return fieldsToPopulate;
    }
    return [];
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const populatePdfWithFieldsData = async ({
  fieldDetails,
  url,
  releaseId = '',
}: {
  fieldDetails: PDFFieldReleaseTemplate[];
  url: string;
  releaseId?: string;
}) => {
  let existingPdfBytes = null;
  const res = await axios
    .get(url, {
      responseType: 'arraybuffer',
    })
    .catch(promiseNull);
  if (!res) {
    return null;
  }
  if (res && res.data) {
    existingPdfBytes = res.data;
  }

  if (existingPdfBytes) {
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const page = pdfDoc.getPages()[0];
    const form = pdfDoc.getForm();
    const { width: horizontalLength, height: verticalLength } = page.getSize();
    for (const fieldDetail of fieldDetails) {
      try {
        if (fieldDetail.id.startsWith('signature')) {
          if (fieldDetail.value) {
            let signatureImage = null;
            if (fieldDetail.value.startsWith('data:image/jpeg')) {
              signatureImage = await pdfDoc.embedJpg(fieldDetail.value);
            } else {
              signatureImage = await pdfDoc.embedPng(fieldDetail.value);
            }
            const { width, height } = signatureImage.size();
            const field = form.createTextField(`dillField_text_${fieldDetail.id}`);
            field.enableMultiline();
            field.disableScrolling();
            field.setText(`E-signed using Dill (${releaseId})`);
            field.addToPage(page, {
              x: fieldDetail.x ? fieldDetail.x + 105 : undefined,
              y: verticalLength - (fieldDetail.y || 0),
              width: 140,
              height: fieldDetail.height || undefined,
              borderWidth: 0,
            });
            // const scaled = signatureImage.scaleToFit(width * 0.2, height * 0.08);
            const scaled = signatureImage.scaleToFit(100, 25);
            page.drawImage(signatureImage, {
              x: fieldDetail?.x ?? 377.3359375,
              y: verticalLength - (fieldDetail?.y ?? 553.203125) - 5,
              width: scaled.width,
              height: scaled.height,
            });
          }
        } else {
          const field = form.createTextField(`dillField_${fieldDetail.id}`);
          field.enableMultiline();
          field.disableScrolling();
          field.setText(fieldDetail?.value || '');
          field.addToPage(page, {
            x: fieldDetail.x || undefined,
            y: verticalLength - (fieldDetail.y || 0),
            width: fieldDetail.width || undefined,
            height: fieldDetail.height || undefined,
            borderWidth: 0,
          });
          const da = field.acroField.getDefaultAppearance() ?? '';
          if (da) {
            const dalength = da.split(' ').length;
            const dafontsize = Number(da.split(' ')[dalength - 2]);
            const sizeOffest = 10 / dafontsize;
            const adjustfontSize = 1 / sizeOffest;

            field.setFontSize(dafontsize - adjustfontSize);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    const modifiedPdfBytes = await pdfDoc.save();

    // Download the modified PDF
    const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
    const populatedPdfUrl = URL.createObjectURL(blob);
    // setPdfUrlHistory([pdfUrlHistory[0], url]);
    return populatedPdfUrl;
  }
};

export const populatePagesPdfWithFieldsData = async ({
  fieldDetails,
  url,
  releaseId = '',
}: {
  fieldDetails: PDFFieldReleaseTemplate[];
  url: string;
  releaseId?: string;
}) => {
  let existingPdfBytes = null;
  const res = await axios
    .get(url, {
      responseType: 'arraybuffer',
    })
    .catch(promiseNull);
  if (!res) {
    return null;
  }
  if (res && res.data) {
    existingPdfBytes = res.data;
  }

  if (existingPdfBytes) {
    const groupedData: PDFFieldReleaseTemplate[][] = [];

    fieldDetails.forEach((item) => {
      const page = item.page ?? 0;
      if (!groupedData[page]) {
        groupedData[page] = [];
      }
      groupedData[page].push(item);
    });
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    for (let index = 0; index < groupedData.length; index++) {
      const pageFields = groupedData[index];
      const pageNumber = pageFields?.[0]?.page ?? 0;
      const page = pdfDoc.getPages()[pageNumber];
      const form = pdfDoc.getForm();
      const { width: horizontalLength, height: verticalLength } = page?.getSize();
      if (pageFields && pageFields.length > 0) {
        for (const fieldDetail of pageFields) {
          try {
            if (fieldDetail.id.startsWith('signature')) {
              if (fieldDetail.value) {
                let signatureImage = null;
                if (fieldDetail.value.startsWith('data:image/jpeg')) {
                  signatureImage = await pdfDoc.embedJpg(fieldDetail.value);
                } else {
                  signatureImage = await pdfDoc.embedPng(fieldDetail.value);
                }
                const { width, height } = signatureImage.size();
                const field = form.createTextField(`dillField_text_${fieldDetail.id}`);
                field.enableMultiline();
                field.disableScrolling();
                field.setText(`E-signed using Dill ${releaseId ? `(${releaseId})` : ''}`);
                field.addToPage(page, {
                  x: fieldDetail.x ? fieldDetail.x + 105 : undefined,
                  y: verticalLength - (fieldDetail.y || 0),
                  width: 140,
                  height: fieldDetail.height || undefined,
                  borderWidth: 0,
                });
                // const scaled = signatureImage.scaleToFit(width * 0.2, height * 0.08);
                const scaled = signatureImage.scaleToFit(100, 25);
                page.drawImage(signatureImage, {
                  x: fieldDetail?.x ?? 377.3359375,
                  y: verticalLength - (fieldDetail?.y ?? 553.203125) - 5,
                  width: scaled.width,
                  height: scaled.height,
                });
              }
            } else if (fieldDetail.id.startsWith('check')) {
              if (fieldDetail.value === 'X') {
                const field = form.createCheckBox(`dillField_check_${fieldDetail.id}`);
                field.addToPage(page, {
                  x: fieldDetail.x || undefined,
                  y: verticalLength - (fieldDetail.y || 0),
                  width: fieldDetail.width || undefined,
                  height: fieldDetail.height || undefined,
                  borderWidth: 0,
                });
                field.check();
              }
            } else {
              const field = form.createTextField(`dillField_${fieldDetail.id}`);
              field.enableMultiline();
              field.disableScrolling();
              field.setText(fieldDetail?.value || '');
              field.addToPage(page, {
                x: fieldDetail.x || undefined,
                y: verticalLength - (fieldDetail.y || 0),
                width: fieldDetail.width || undefined,
                height: fieldDetail.height || undefined,
                borderWidth: 0,
              });
              const da = field.acroField.getDefaultAppearance() ?? '';
              if (da) {
                const dalength = da.split(' ').length;
                const dafontsize = Number(da.split(' ')[dalength - 2]);
                const sizeOffest = 10 / dafontsize;
                const adjustfontSize = 1 / sizeOffest;

                field.setFontSize(dafontsize - adjustfontSize);
              }
            }
          } catch (error) {
            console.log(error);
          }
        }
      }
    }

    const modifiedPdfBytes = await pdfDoc.save();

    // Download the modified PDF
    const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
    const populatedPdfUrl = URL.createObjectURL(blob);
    // setPdfUrlHistory([pdfUrlHistory[0], url]);
    return populatedPdfUrl;
  }
};

export const getSavedPastPath = () => {
  let pastPath = '';
  const pastPathList = localStorage.getItem('pastPathList');
  const timeLimitSeconds = 60 * 3;
  if (pastPathList) {
    const savedPastPathList = JSON.parse(pastPathList);
    const sortttt = savedPastPathList.sort((A: any, B: any) => {
      return new Date(B.date).getTime() - new Date(A.date).getTime();
    });
    if (sortttt.length > 0) {
      const currentTime = moment(new Date());
      const lastSavedTime = moment(new Date(sortttt[0].date));
      const diff = currentTime.diff(lastSavedTime, 'seconds');

      pastPath = sortttt[0].route;

      if (diff > timeLimitSeconds) {
        // pastPath = '';
        // localStorage.removeItem('pastPathList');
      }
    }
  }
  return pastPath;
};

export const handleSavePastPath = (currentPath: string) => {
  if (!currentPath) {
    return;
  }
  const pastPathList = localStorage.getItem('pastPathList');
  let finalList: { route: string; date: string | Date }[] = [];
  if (pastPathList) {
    const savedPastPathList = JSON.parse(pastPathList);

    const check = savedPastPathList.find((item: any) => item.route === currentPath);

    if (!check) {
      finalList = [{ route: currentPath, date: new Date() }, ...savedPastPathList];
    } else {
      finalList = [
        ...savedPastPathList.map((item: any) => {
          if (item.route === currentPath) {
            return { route: item.route, date: new Date() };
          }
          return item;
        }),
      ];
    }
  }
  if (!pastPathList) {
    finalList = [{ route: currentPath, date: new Date() }];
  }
  const sortttt = finalList.sort((A: any, B: any) => {
    const dateA = moment(new Date(A.date));
    const dateB = moment(new Date(B.date));
    return dateB.diff(dateA, 'milliseconds');
  });
  localStorage.setItem('pastPathList', JSON.stringify([...sortttt.slice(0, 8)]));
};

export const removeQueryParam = ({
  path,
  paramNameToRemove,
}: {
  path: string;
  paramNameToRemove: string;
}) => {
  let urlObj;
  try {
    urlObj = new URL(path, REACT_APP_FRONTEND_URL);
  } catch (error) {
    urlObj = new URL(path, window.location.href);
  }

  const paramValue = urlObj.searchParams.get(paramNameToRemove);

  if (paramValue !== null) {
    urlObj.searchParams.delete(paramNameToRemove);
  }

  const pathAndQuery = urlObj.pathname + urlObj.search;

  return pathAndQuery;
};

export const openDillToast = ({
  message,
  type,
}: {
  message: string;
  type: 'SUCCESS' | 'ERROR';
}) => {
  if (type === 'SUCCESS') {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      style: {
        backgroundColor: '#ECFDF3',
        color: '#054F31',
      },
    });
  } else if (type === 'ERROR') {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
      style: {
        backgroundColor: '#FEF0EF',
        color: '#F04438',
      },
    });
  }
};

export const truncateText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength - 3) + '...';
  }
  return text;
};

export function trimObject(obj: object): object {
  if (typeof obj !== 'object' || obj === null) {
    // If the value is not an object, or is null, return it as is
    return obj;
  }

  // Handle arrays
  if (Array.isArray(obj)) {
    return obj.map(trimObject);
  }

  // Handle objects
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [
      key.trim(),
      typeof value === 'string' ? value.trim() : trimObject(value),
    ])
  );
}

export function getFirstLetters(str: string | undefined) {
  if (str) {
    return str
      .split(' ')
      .slice(0, 2)
      .map((word) => word[0])
      .join('')
      .toLocaleUpperCase();
  }
  return '';
}
export const convertKeysToCamelCase = (data: any[]): any[] => {
  return data.map((item) => {
    const newItem: any = {};
    for (const key in item) {
      if (item.hasOwnProperty(key) && !key.startsWith('__EMPTY')) {
        const camelCaseKey = key
          .trim()
          .replace(/\s+/g, '')
          .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) =>
            index === 0 ? letter.toLowerCase() : letter.toUpperCase()
          );
        newItem[camelCaseKey] = item[key];
      }
    }
    return newItem;
  });
};
