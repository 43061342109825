import { Modal } from '@mui/material';
import React from 'react';
import successChecksCircle from '../../../assets/svg/success_checks_circle.svg';
import { AppButton } from '../AppButton/AppButton';
import './AppVideoModal.scss';
import { COLORS } from '../../../utils/colors';
import { X } from 'react-feather';
import { AppYoutubeEmbed } from '../AppYoutubeEmbed/AppYoutubeEmbed';

export const AppVideoModal = ({
  open,
  handleClose,
  video,
}: {
  open: boolean;
  handleClose?: () => void;
  video: string;
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="video-modal">
        <button className="close-button" onClick={handleClose}>
          <X color={COLORS.GREY_300} size={30} />
        </button>
        <div className="video">
          <AppYoutubeEmbed embedId={video} />
        </div>
      </div>
    </Modal>
  );
};
