import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { myFirebaseAuth } from '../../firebase/config';
import { checkUserExists, logoutUser } from '../../redux/services/authService';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { appDelay } from '@dill/dill-shared';
import { getBasicSupplier } from '../../redux/services/buyersService';

export const ReferSetupPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { userCheckState, firebaseUserId, isPasswordSet } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (location.pathname.startsWith('/refer')) {
      const originalReferPath = location.pathname + location.search;
      localStorage.setItem('referPath', originalReferPath);
      const params = new URLSearchParams(location.search);
      const currentEmailFromLinkParam = params.get('email');
      if (currentEmailFromLinkParam && !userCheckState) {
        (async () => {
          await appDelay(2000);
          dispatch(checkUserExists({ email: currentEmailFromLinkParam.toLocaleLowerCase() }));
        })();
      }
    } else {
      navigate('/login');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.startsWith('/refer')) {
      const originalReferPath = location.pathname + location.search;
      localStorage.setItem('referPath', originalReferPath);
      const params = new URLSearchParams(location.search);
      const currentEmailFromLinkParam = params.get('email');
      const isAcceptSetup = params.get('isAcceptSetup');
      const supplierDomainId = params.get('supplierDomainId');
      if (currentEmailFromLinkParam) {
        sessionStorage.setItem('emailFromLink', currentEmailFromLinkParam.toLocaleLowerCase());
      }
      let loginPath = '/login';
      if (supplierDomainId) {
        dispatch(getBasicSupplier({ supplierDomainId: supplierDomainId }));
        loginPath = `/login/${supplierDomainId}`;
      }
      if (
        myFirebaseAuth.currentUser &&
        currentEmailFromLinkParam &&
        myFirebaseAuth.currentUser.email !== currentEmailFromLinkParam.toLocaleLowerCase() &&
        userCheckState === 'USER_EXISTS'
      ) {
        dispatch(logoutUser());
        if (isAcceptSetup && isAcceptSetup === 'true') {
          navigate('/login');
          return;
        }
      }

      if (isAcceptSetup) {
        if (isAcceptSetup === 'true' && userCheckState === 'USER_EXISTS') {
          if (firebaseUserId && isPasswordSet) {
            navigate('/refer/add');
          } else if (firebaseUserId && !isPasswordSet) {
            navigate(loginPath);
          } else {
            dispatch(logoutUser());
            navigate(loginPath);
          }
        } else if (isAcceptSetup === 'false' && userCheckState === 'USER_EXISTS') {
          navigate('/refer/add');
        } else if (userCheckState === 'NEW_USER') {
          dispatch(logoutUser());
          navigate(loginPath);
        }
      } else {
        localStorage.removeItem('referPath');
        navigate(loginPath);
      }
    } else {
      navigate('/login');
    }
  }, [userCheckState]);

  return <div></div>;
};
