import React, { useEffect, useMemo, useState } from 'react';
import AddTemplateFields from '../AddTemplateFields/AddTemplateFields';
import emptyListImg from '../../../../../assets/images/emptyList.png';
import { AppInputField } from '../../../../general/AppInputField/AppInputField';
import { Plus, Search } from 'react-feather';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { COLORS } from '../../../../../utils/colors';
import BuyerLienTemplatesTable from '../BuyerLienTemplatesTable/BuyerLienTemplatesTable';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { LienTemplate } from '@dill/dill-shared';
import { useNavigate } from 'react-router-dom';
import { getLienTemplates } from '../../../../../redux/services/leinTemplatesService';
import { useParamMainBuyerId } from '../../../../../utils/hooks/useParamMainBuyerId';

const BuyerLienTemplates = () => {
  const navigate = useNavigate();
  const { lienTemplates } = useAppSelector((state) => state.lienTemplates);
  const [searchText, setSearchText] = useState('');
  const dispatch = useAppDispatch();
  const [selectedMainBuyerId] = useParamMainBuyerId();

  useEffect(() => {
    if (selectedMainBuyerId) {
      dispatch(getLienTemplates({ mainBuyerId: selectedMainBuyerId }));
    }

    return () => {};
  }, [selectedMainBuyerId]);

  const searchedLienTemplates: LienTemplate[] = useMemo(() => {
    let filteredLienTemplates: LienTemplate[] = [];
    if (lienTemplates && searchText) {
      const searched = lienTemplates.filter((lienTemplate) => {
        if (
          (lienTemplate.name &&
            lienTemplate.name.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (lienTemplate.type &&
            lienTemplate.type.toString().toLowerCase().includes(searchText.toLowerCase()))
        ) {
          return true;
        }
        return false;
      });
      console.log(searched);
      filteredLienTemplates = searched;
    } else {
      filteredLienTemplates = lienTemplates;
    }

    return filteredLienTemplates;
  }, [lienTemplates, searchText]);
  return (
    <div className="h-full p-8 flex ">
      <div className="flex flex-col px-5 py-4 w-full bg-white">
        <div className="flex justify-between">
          <AppInputField
            inputFieldStyles={{ width: '300px' }}
            id="search"
            value={searchText}
            placeholder={'Search '}
            icon={<Search size={15} />}
            onTextChange={(text) => {
              setSearchText(text);
            }}
          />
          <div className="relative">
            <input
              id="upload"
              className="absolute w-full h-full top-0 bottom-0 opacity-0 hover:bg-blue-700"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                if (!event?.target.files) {
                  return;
                }
                const file = event?.target?.files[0];

                if (file) {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const base64String = reader.result;
                    navigate(`/buyerLienReleases/${selectedMainBuyerId}/templates/addTemplate`, {
                      state: { pdf: base64String, name: file.name.split('.')[0] },
                    });
                  };
                  reader.readAsDataURL(file);
                }
              }}
              // onClick={(event) => {}}
            />
            <AppButton
              text="Add New Template"
              icon={<Plus color={COLORS.WHITE} />}
              onClick={() => {
                // setIsAddSupplierModalOpen(true);
                navigate(`/buyerLienReleases/${selectedMainBuyerId}/templates/addTemplate`, {
                  state: {},
                });
              }}
            />
          </div>

          {/* <AppButton
            text="Add New Template"
            icon={<Plus color={COLORS.WHITE} />}
            onClick={() => {
              // setIsAddSupplierModalOpen(true);
              navigate('/buyerLienReleases/templates/addTemplate');
            }}
          /> */}
        </div>

        <BuyerLienTemplatesTable lienTemplates={searchedLienTemplates} />
      </div>
    </div>
  );
};

export default BuyerLienTemplates;
