import React, { useState } from 'react';
import { COLORS } from '../../../utils/colors';
import './AppButton.scss';
import { twMerge } from 'tailwind-merge';

export const AppButton = ({
  text,
  icon,
  iconPosition = 'LEFT',
  isDisabled = false,
  buttonStyles = {},
  onClick = () => {},
  isLoading = false,
  type = 'PRIMARY',
  buttonWidth = 'fit-content',
  buttonTextClassName = '',
}: {
  text?: string,
  buttonWidth?: string,
  isDisabled?: boolean,
  iconPosition?: 'LEFT' | 'RIGHT',
  buttonStyles?: any,
  buttonTextClassName?: any,
  icon?: Icon,
  onClick?: any,
  isLoading?: boolean,
  type?: 'PRIMARY' | 'SECONDARY' | 'FLAT' | 'TERTIARY' | 'ERROR',
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleHoverEnd = () => {
    setIsHovered(false);
  };
  const buttonTypeColor = {
    color:
      type === 'PRIMARY'
        ? COLORS.PRIMARY_500
        : type === 'SECONDARY'
        ? COLORS.WHITE
        : type === 'TERTIARY'
        ? COLORS.PRIMARY_50
        : type === 'ERROR'
        ? COLORS.ERROR_500
        : 'transparent',
    hover:
      type === 'PRIMARY'
        ? COLORS.PRIMARY_700
        : type === 'SECONDARY'
        ? COLORS.PRIMARY_100
        : type === 'TERTIARY'
        ? COLORS.GREY_100
        : type === 'ERROR'
        ? COLORS.ERROR_700
        : COLORS.PRIMARY_100,
    disabled:
      type === 'PRIMARY'
        ? COLORS.PRIMARY_100
        : type === 'SECONDARY'
        ? COLORS.PRIMARY_50
        : type === 'TERTIARY'
        ? COLORS.GREY_100
        : type === 'ERROR'
        ? COLORS.ERROR_50
        : 'transparent',
  };
  const textTypeColor = {
    color:
      type === 'PRIMARY'
        ? COLORS.WHITE
        : type === 'SECONDARY'
        ? COLORS.PRIMARY_500
        : type === 'TERTIARY'
        ? COLORS.GREY_500
        : type === 'ERROR'
        ? COLORS.WHITE
        : COLORS.PRIMARY_500,
    disabled:
      type === 'PRIMARY'
        ? COLORS.PRIMARY_300
        : type === 'SECONDARY'
        ? COLORS.PRIMARY_200
        : type === 'TERTIARY'
        ? COLORS.GREY_300
        : type === 'ERROR'
        ? COLORS.ERROR_300
        : COLORS.PRIMARY_300,
  };
  const borderTypeColor = {
    color:
      type === 'PRIMARY'
        ? COLORS.PRIMARY_500
        : type === 'SECONDARY'
        ? COLORS.PRIMARY_500
        : type === 'TERTIARY'
        ? COLORS.GREY_200
        : 'transparent',
    disabled:
      type === 'PRIMARY'
        ? COLORS.PRIMARY_100
        : type === 'SECONDARY'
        ? COLORS.PRIMARY_200
        : type === 'TERTIARY'
        ? COLORS.GREY_300
        : 'transparent',
  };
  return (
    <div
      onMouseEnter={handleHover}
      onMouseLeave={handleHoverEnd}
      className={[isDisabled ? 'button-wrapper cursor-default' : 'button-wrapper  cursor-pointer']}
      style={{
        background: isDisabled
          ? buttonTypeColor.disabled
          : `${isHovered ? buttonTypeColor.hover : buttonTypeColor.color}`,
        borderWidth: type === 'SECONDARY' ? '2px' : '1px',
        borderColor: isDisabled
          ? borderTypeColor.disabled
          : `${isHovered && type === 'PRIMARY' ? buttonTypeColor.hover : borderTypeColor.color}`,
        width: buttonWidth,
        ...buttonStyles,
      }}
      onClick={(e) => {
        if (!isDisabled) {
          onClick(e);
        }
      }}>
      <button disabled={isDisabled} className={[isDisabled ? 'cursor-default' : 'cursor-pointer']}>
        {isLoading ? (
          <div className="button-content">
            <div className="spinner" />
          </div>
        ) : (
          <div
            className="button-content"
            style={{
              color: isDisabled ? textTypeColor.disabled : textTypeColor.color,
            }}>
            {icon && iconPosition === 'LEFT' && icon}
            {text && <p className={twMerge('button-text', buttonTextClassName)}>{text}</p>}
            {icon && iconPosition === 'RIGHT' && icon}
          </div>
        )}
      </button>
    </div>
  );
};
