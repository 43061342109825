import { Modal } from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import successChecksCircle from '../../../assets/svg/success_checks_circle.svg';
import { AppButton } from '../AppButton/AppButton';
import { CreditCard, X } from 'react-feather';
import { Bank, Check, Warning } from 'phosphor-react';
import { COLORS } from '../../../utils/colors';
import './AppVerifyBusinessModal.scss';
import {
  getStripeSupplierAccount,
  createStripeExternalBankToSupplierAccount,
  getSupplierRegistrationStatus,
} from '../../../redux/services/paymentsService';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useStripe } from '@stripe/react-stripe-js';
import AppAddPaymentMethodModal from '../AppAddPaymentMethodModal/AppAddPaymentMethodModal';
import { getUserDetails } from '../../../redux/services/authService';
import { AppDwollaCustomerDropIn } from '../AppDwollaCustomerDropIn/AppDwollaCustomerDropIn';
import { DillPaymentMethod } from '@dill/dill-shared';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import AppVerifyDwollaMicroTransactionsModal from '../AppVerifyDwollaMicroTransactionsModal/AppVerifyDwollaMicroTransactionsModal';

export const AppVerifyBusinessModal = ({
  open,
  handleClose,
  onVerifyACH,
  onVerifyBankAccount,
}: {
  open: boolean;
  handleClose: () => void;
  onVerifyACH: () => void;
  onVerifyBankAccount: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { loadingList: paymentLoadingList } = useAppSelector((state) => state.payments);
  const [step, setStep] = useState(1);
  const [creditCardMode, setCreditCardMode] = useState('');
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.auth);
  const { stripeCustomer, supplierRegistrationStatus } = useAppSelector((state) => state.payments);
  const stripe: any = useStripe();
  const [openDwollaVerifyCustomerModal, setOpenDwollaVerifyCustomerModal] = useState(false);
  const [isAppAddPaymentMethodModalOpen, setIsAppAddPaymentMethodModalOpen] = useState(false);
  // useEffect(() => {
  //   if (stripeCustomer && step === 2 && !stripeCustomer.isLinkRegistrationDone && open) {
  //     window.open(stripeCustomer.registrationUrl, '_blank');
  //     handleClose();
  //   }
  //   return () => {};
  // }, [stripeCustomer, step, open]);

  const [
    isAppVerifyDwollaMicroTransactionsModalOpen,
    setIsAppVerifyDwollaMicroTransactionsModalOpen,
  ] = useState(false);
  const [
    selectedPaymentMethodToVerifyMicroTransactions,
    setSelectedPaymentMethodToVerifyMicroTransactions,
  ] = useState<DillPaymentMethod>();

  useEffect(() => {
    if (open) {
      dispatch(getSupplierRegistrationStatus());
    }

    return () => {};
  }, [open]);
  useEffect(() => {
    if (supplierRegistrationStatus) {
      if (supplierRegistrationStatus?.isFullyRegistered) {
        setStep(4);
      } else if (!supplierRegistrationStatus?.isDwollaVerified) {
        setStep(1);
      } else if (!supplierRegistrationStatus.isStripeLinkRegistrationDone) {
        setStep(2);
      } else if (!supplierRegistrationStatus.isSupplierWithdrawPaymentMethodSet) {
        setStep(3);
      }
    }

    return () => {};
  }, [supplierRegistrationStatus]);

  const handleCloseModal = () => {
    handleClose();
    // setCondition('');
    // setTitle('');
  };
  const handleNext = async () => {
    setLoading(true);
    if (step === 1 && !supplierRegistrationStatus?.isDwollaVerified) {
      // onVerifyACH();
      setOpenDwollaVerifyCustomerModal(true);
    } else {
      // setStep(step + 1);
    }
    if (step === 2 && !supplierRegistrationStatus?.isStripeLinkRegistrationDone) {
      window.open(supplierRegistrationStatus?.stripeRegistrationUrl, '_blank');
    } else {
      // setStep(step + 1);
    }
    if (step === 3 && !supplierRegistrationStatus?.isSupplierWithdrawPaymentMethodSet) {
      setIsAppAddPaymentMethodModalOpen(true);
    } else {
      // setStep(step + 1);
    }

    // await dispatch(getSupplierRegistrationStatus());
    setLoading(false);
  };
  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
    if (step === 4) {
      handleClose();
      setStep(4);
    }
  };
  const buttonDisabled = useMemo(() => {
    const isDwollaVerified = user?.userSupplier?.dwollaCustomerStatus === 'verified';
    const isStripeVerified = user?.userSupplier?.stripeCustomerStatus === 'verified';
    if (step === 1 && isDwollaVerified) {
      return false;
    }
    if (step === 2 && isStripeVerified) {
      return false;
    }
    if (step === 3 && isDwollaVerified && isStripeVerified) {
      return false;
    }
    return true;
  }, [user, step]);
  const addCreditCard = async () => {
    const res: any = await stripe.createToken('bank_account', {
      country: 'US',
      currency: 'usd',
      routing_number: '110000000',
      account_number: '000123456789',
      account_holder_name: 'Jenny Rosen',
      account_holder_type: 'individual',
    });
    if (res.token.id) {
      await dispatch(createStripeExternalBankToSupplierAccount({ bankOrCardToken: res.token.id }));
      setStep(4);
    }
    await dispatch(getStripeSupplierAccount());
  };

  const steps = [
    {
      step: 1,
      name: 'Bank ACH Verification',
    },
    {
      step: 2,
      name: 'Credit/Debit Card Verification',
    },
    {
      step: 3,
      name: 'Link Bank Account',
    },
    {
      step: 4,
      name: 'Finish',
    },
  ];

  useEffect(() => {
    if (paymentLoadingList.filter((item) => item === 'getSupplierRegistrationStatus').length > 0) {
      setLoading(true);
    } else if (
      loading === true &&
      paymentLoadingList.filter((item) => item === 'getSupplierRegistrationStatus').length == 0
    ) {
      setLoading(false);
    }
    return () => {};
  }, [paymentLoadingList]);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="verify-business-modal-content">
        {openDwollaVerifyCustomerModal && (
          <AppDwollaCustomerDropIn
            open={openDwollaVerifyCustomerModal}
            handleClose={async () => {
              await dispatch(getUserDetails());
              setOpenDwollaVerifyCustomerModal(false);
              dispatch(getSupplierRegistrationStatus());
              // setBusinessVerifyCustomerModal(true);
            }}
          />
        )}
        <AppAddPaymentMethodModal
          open={isAppAddPaymentMethodModalOpen}
          handleClose={async () => {
            await setIsAppAddPaymentMethodModalOpen(false);
            await dispatch(getUserDetails());
            await dispatch(getSupplierRegistrationStatus());
          }}
          userType="supplier"
          paymentOptionsToShow="BANK"
        />
        <AppVerifyDwollaMicroTransactionsModal
          selectedPaymentMethod={selectedPaymentMethodToVerifyMicroTransactions}
          open={isAppVerifyDwollaMicroTransactionsModalOpen}
          handleClose={async () => {
            setIsAppVerifyDwollaMicroTransactionsModalOpen(false);
            dispatch(getSupplierRegistrationStatus());
            setSelectedPaymentMethodToVerifyMicroTransactions(undefined);
          }}
        />
        <div className={'drop-in-modal-title-container'}>
          <h3 className="verify-busine-modal-title">Create Verified Business</h3>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={handleCloseModal}
          />
        </div>
        <div className="progress-container">
          {steps.map((item, i) => (
            <div
              className={item.step === 4 ? 'finsh-item' : 'progress-item'}
              key={`progress_ui_${i}`}>
              <div className="row-container">
                <Check
                  color={COLORS.WHITE}
                  size={20}
                  style={{
                    backgroundColor:
                      step === item.step && step === 4
                        ? '#32D583'
                        : step - 1 < item.step
                        ? '#CBD5E1'
                        : '#32D583',
                    marginRight: 12,
                    height: item.step === 4 ? 18 : 17,
                    width: item.step === 4 ? 18 : 20,
                    padding: 2,
                    borderRadius: 15,
                  }}
                />
                {item.step !== 4 && <div className="line" />}
              </div>
              <div className="step-text">{`Step ${item.step}`}</div>
              <div
                className={
                  step === item.step && step !== 4
                    ? 'current-progress-item'
                    : step < item.step
                    ? 'next-progress-item'
                    : 'verified-progress-item'
                }>
                {item.name}
              </div>
            </div>
          ))}
        </div>
        {step === 1 && (
          <div className="verify-business-content">
            <div>
              <div className="business-message-container">
                Verify your business to start accepting bank ACH payments
              </div>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="verify-business-content">
            <div className="business-message-container">
              You have finished verification for accepting bank ACH payments. Start accepting credit
              card payments by clicking continue below. There may be some information you will need
              to complete again.
            </div>
          </div>
        )}
        {step === 3 && (
          <div className="verify-business-content">
            {(!supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod ||
              !supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod.status) && (
              <div className="business-message-container">
                You have finished verification for accepting credit/debit card payments. As a final
                step, link your bank account for withdrawals.
              </div>
            )}{' '}
            {supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod && (
              <div className="business-message-container">
                <div
                  className="payment-item-container"
                  key={`buyer_payment_${supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod.id}`}
                  data-tooltip-id={`payment-method-tooltip_${supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod.id}`}>
                  <div className="w-full flex justify-between">
                    <div className="card-container">
                      {supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod.type ===
                      'BANK_ACCOUNT' ? (
                        <Bank size={30} style={{ display: 'flex', alignSelf: 'center' }} />
                      ) : (
                        <CreditCard size={30} style={{ display: 'flex', alignSelf: 'center' }} />
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="card-title">
                      {
                        supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod
                          .paymentMethodName
                      }
                    </div>
                  </div>

                  <div className="">
                    {(supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod.status ===
                      'MICRO_DEPOSITS_SENT' ||
                      supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod.status ===
                        'MICRO_DEPOSITS_VERIFICATION_PENDING' ||
                      supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod.status ===
                        'UNVERIFIED') && (
                      <div className="flex-end">
                        <div className="text-xs flex">
                          <Warning size={20} color={COLORS.ERROR_500} />
                          Unverified
                        </div>
                        <ReactTooltip
                          id={`payment-method-tooltip_${supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod.id}`}
                          place="top"
                          variant="dark"
                          opacity={'100%'}
                          style={{
                            display: 'flex',
                            background: '#334155',
                            width: '300px',
                            zIndex: '70',
                            borderRadius: '8px',
                            alignItems: 'center',
                            justifyItems: 'center',
                            fontWeight: '600',
                          }}>
                          {supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod.status ===
                          'MICRO_DEPOSITS_VERIFICATION_PENDING' ? (
                            <span>
                              Two micro-deposits have arrived in your account. Please check for
                              these deposits in your account and verify them in order to use this
                              bank method.
                            </span>
                          ) : (
                            <span>
                              Two micro-deposits were sent to this bank account. They should arrive
                              1-2 business days from the day it was added. Once you see these
                              deposits in your account, please verify them in order to use this bank
                              method.
                            </span>
                          )}
                        </ReactTooltip>

                        <div className="">
                          <AppButton
                            text="Verify Deposits"
                            isDisabled={
                              supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod
                                .status !== 'MICRO_DEPOSITS_VERIFICATION_PENDING'
                            }
                            onClick={() => {
                              if (supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod) {
                                setIsAppVerifyDwollaMicroTransactionsModalOpen(true);
                                setSelectedPaymentMethodToVerifyMicroTransactions(
                                  supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {step === 4 && (
          <div className="verify-business-content">
            <div className="business-message-container">
              <div className="verify-text-header">Business Verified!</div>
              <div className="finished-verify-text">
                You have finished verification and can now accept both bank ACH & credit/debit card
                payments.
              </div>
            </div>
          </div>
        )}
        <div className="action-buttons">
          {step === 4 && (
            <AppButton
              text={step === 4 ? 'Back to Home' : 'Back'}
              type="TERTIARY"
              buttonStyles={{
                width: '100%',
              }}
              onClick={handleBack}
            />
          )}
          {/* {step !== 4 && <div className="spacer" />} */}
          {step !== 4 && (
            <AppButton
              text={step !== 3 ? 'Continue' : 'Link Bank Account'}
              buttonStyles={{ width: '100%' }}
              onClick={handleNext}
              isLoading={loading}
              isDisabled={
                loading ||
                (step === 3 &&
                  supplierRegistrationStatus?.supplierWithdrawBankPaymentMethod?.status)
                  ? true
                  : false
              }
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
