import { Modal } from '@mui/material';
import { CaretLeft } from 'phosphor-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../../redux/store';
import { AppButton } from '../AppButton/AppButton';
import { AppInputField } from '../AppInputField/AppInputField';
import { openDillToast } from '../../../utils/helpers';
import { verifyDwollaMicroTransactions } from '../../../redux/services/paymentsService';
import { DillPaymentMethod } from '@dill/dill-shared';
import { AppSuccessModal } from '../AppSuccessModal/AppSuccessModal';
import errorCircle from '../../../assets/svg/error_circle.svg';

const AppVerifyDwollaMicroTransactionsModal = ({
  open,
  selectedPaymentMethod,
  handleClose = () => {},
}: {
  open: boolean;
  selectedPaymentMethod: DillPaymentMethod | undefined;
  handleClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [
    isMicroTransactionVerificationSuccessModalOpen,
    setIsMicroTransactionVerificationSuccessModalOpen,
  ] = useState(false);
  const [microTransactionVerificationErrorMessage, setMicroTransactionVerificationErrorMessage] =
    useState('');
  const [transactionAmount1, setTransactionAmount1] = useState<number | undefined>();
  const [transactionAmount2, setTransactionAmount2] = useState<number | undefined>();
  const [errors, setErrors] = useState({
    transactionAmount1: '',
    transactionAmount2: '',
  });
  const [
    isMicroTransactionVerificationFailedModalOpen,
    setIsMicroTransactionVerificationFailedModalOpen,
  ] = useState(false);
  const paymentMethodId = useMemo(() => {
    if (selectedPaymentMethod) {
      return selectedPaymentMethod.id;
    }
    return null;
  }, [selectedPaymentMethod]);

  const handleCloseModal = () => {
    handleClose();
  };
  useEffect(() => {
    if (open) {
      setErrors({
        transactionAmount1: '',
        transactionAmount2: '',
      });
      setTransactionAmount1(undefined);
      setTransactionAmount2(undefined);
    }

    return () => {};
  }, [open]);

  const validateInputs = () => {
    const newErrors = {
      transactionAmount1: '',
      transactionAmount2: '',
    };

    let isValid = true;

    if (transactionAmount1 === undefined) {
      newErrors.transactionAmount1 = 'This field is required.';
      isValid = false;
    }
    if (transactionAmount2 === undefined) {
      newErrors.transactionAmount2 = 'This field is required.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  const handleVerifyDwollaMicroTransactions = async () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    if (!paymentMethodId) {
      openDillToast({ message: 'Payment method is missing', type: 'ERROR' });
      return;
    }
    if (transactionAmount1 && transactionAmount2 && paymentMethodId) {
      const results = await dispatch(
        verifyDwollaMicroTransactions({
          amount1: transactionAmount1,
          amount2: transactionAmount2,
          paymentMethodId: paymentMethodId || '',
        })
      );
      if (results && results.type === 'payments/verifyDwollaMicroTransactions/fulfilled') {
        setIsMicroTransactionVerificationSuccessModalOpen(true);
        openDillToast({ message: 'Payment method verified', type: 'SUCCESS' });
      } else {
        const payload: any = results.payload as any;
        if (results.type === 'payments/verifyDwollaMicroTransactions/rejected' && payload) {
          setMicroTransactionVerificationErrorMessage(payload);
          setIsMicroTransactionVerificationFailedModalOpen(true);
        }
        // openDillToast({ message: 'Failed', type: 'ERROR' });
      }
    } else {
      openDillToast({ message: 'Some fields are missing', type: 'ERROR' });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-80 w-[40vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
        <AppSuccessModal
          open={isMicroTransactionVerificationSuccessModalOpen}
          title={`Bank method successfully verified!`}
          subTitle="You can now use this bank method going forward"
          buttonText="Close"
          handleClose={() => {
            setIsMicroTransactionVerificationSuccessModalOpen(false);
            handleClose();
          }}
        />
        <AppSuccessModal
          open={isMicroTransactionVerificationFailedModalOpen}
          modalImage={errorCircle}
          title={'Error'}
          subTitle={microTransactionVerificationErrorMessage}
          buttonText="Close"
          handleClose={() => {
            setIsMicroTransactionVerificationFailedModalOpen(false);
          }}
        />
        <div className="flex items-center mt-2">
          <AppButton
            type="FLAT"
            buttonStyles={{
              width: '40px',
              height: '40px',
              marginLeft: '12px',
              marginRight: '12px',
            }}
            icon={<CaretLeft size={24} color={'#000000'} />}
            onClick={handleClose}
          />

          <h3 className="font-bold text-xl">Verify Deposits</h3>
        </div>
        <hr className="h-px my-1 bg-gray-200 border-0 dark:bg-gray-200"></hr>
        <div className="flex flex-col px-4 mt-4 grow  pb-3">
          <div className="mb-2 bg-gray-100 p-1 rounded-md">
            <p className="text-xs  text-center">
              We sent two micro-deposits to your account
              <span className="font-bold"> {selectedPaymentMethod?.paymentMethodName || ''}</span>.
              These should arrive in your account 1-2 business days from the day it was added.
              Please verify your account by entering the values below
            </p>
          </div>
          <div className="w-full my-1.5">
            <AppInputField
              id="transactionAmount1"
              label="Transaction Amount 1"
              inputType="number"
              placeholder=""
              value={transactionAmount1}
              errorText={errors.transactionAmount1 ? errors.transactionAmount1 : ''}
              onTextChange={(text) => {
                setTransactionAmount1(text ? Number(text) : undefined);
              }}
            />
          </div>
          <div className="w-full my-1.5">
            <AppInputField
              id="transactionAmount2"
              label="Transaction Amount 2"
              inputType="number"
              placeholder=""
              value={transactionAmount2}
              errorText={errors.transactionAmount2 ? errors.transactionAmount2 : ''}
              onTextChange={(text) => {
                setTransactionAmount2(text ? Number(text) : undefined);
              }}
            />
          </div>
        </div>
        <div className="flex flex-col w-full  mb-3">
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
          <p className="text-xs ml-3">You only get 3 chances to verify the deposits.</p>
          <div className="flex flex-row self-end px-6">
            <AppButton
              text="Close"
              type="TERTIARY"
              buttonStyles={{
                marginTop: '4px',
                marginBottom: '4px',
                marginRight: '12px',
              }}
              onClick={handleCloseModal}
            />
            <AppButton
              text={'Verify'}
              onClick={handleVerifyDwollaMicroTransactions}
              buttonStyles={{
                marginTop: '4px',
                marginBottom: '4px',
                width: '100px',
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AppVerifyDwollaMicroTransactionsModal;
