import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export function useDebounce<T>(delay?: number): [T | null, Dispatch<SetStateAction<T | null>>] {
  const [value, setValue] = useState<T | null>(null);
  const [debouncedValue, setDebouncedValue] = useState<T | null>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return [debouncedValue, setValue];
}

export function useTrivialValueDebounce(delay?: number): [object, () => void] {
  const [value, setValue] = useState<object>({});
  const [debouncedValue, setDebouncedValue] = useState<object>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  const triggerDebounce = () => {
    setValue({});
  };

  return [debouncedValue, triggerDebounce];
}
