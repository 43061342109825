import { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import type { ActionMeta, SingleValue, StylesConfig } from 'react-select';
import './AppDropDown.scss';
import { FormOptionItem } from '../../../utils/interfaces/generalInterfaces';
import { twMerge } from 'tailwind-merge';
import { CustomSelectComponents } from './constants';

export const AppDropDown = ({
  items = [],
  value,
  inputFieldStyles,
  isCreatable = false,
  isSearchable = true,
  isDisabled = false,
  isIndicatorSeparator = false,
  placeholder = 'Select',
  label = '',
  labelTextClassName = '',
  isRequired = false,
  errorText = '',
  onSelectChange = () => {},
  onCreateNewItem = () => {},
  height = 300,
  isRequiredIconDisabled = false,
  customStyles = {},
  customSelectComponents = {},
}: {
  items: FormOptionItem[];
  label?: string;
  labelTextClassName?: string;
  isRequired?: boolean;
  value: string | null;
  isCreatable?: boolean;
  isSearchable?: boolean;
  isDisabled?: boolean;
  isIndicatorSeparator?: boolean;
  placeholder?: string;
  inputFieldStyles?: any;
  errorText?: string;
  onSelectChange?: (
    newValue: SingleValue<FormOptionItem>,
    actionMeta: ActionMeta<FormOptionItem>
  ) => void;
  onCreateNewItem?: (newValue: string) => void;
  height?: number;
  isRequiredIconDisabled?: boolean;
  customStyles?: StylesConfig<FormOptionItem, false>;
  customSelectComponents?: CustomSelectComponents;
}) => {
  const [selectedValue, setSelectedValue] = useState<FormOptionItem | undefined | null>();
  useEffect(() => {
    if (value !== null && value !== undefined && value !== '') {
      const check = items.find((item) => item.value === value);

      if (isCreatable) {
        if (!check) {
          setSelectedValue({ label: value, value });
        } else {
          setSelectedValue(check);
        }
      } else if (!isCreatable) {
        setSelectedValue(check);
      }
    } else {
      setSelectedValue(null);
    }
    return () => {};
  }, [value]);
  const selectComponents = useMemo(() => {
    let customComponents = {};
    if (!isIndicatorSeparator) {
      customComponents = {
        ...customComponents,
        IndicatorSeparator: () => null,
      };
    }
    return { ...customComponents, ...customSelectComponents };
  }, [isIndicatorSeparator]);

  return (
    <div className="w-full flex flex-col">
      {(label || isRequired) && !isRequiredIconDisabled && (
        <p
          className={twMerge(
            'text-xs mb-0.5 text-left whitespace-nowrap font-medium',
            labelTextClassName
          )}>
          {isRequired && <span className="text-red-500">*</span>}
          {label}
        </p>
      )}
      <div
        className={twMerge(
          'select-wrapper flex flex-col h-fit ',
          errorText ? 'select-wrapper__error' : ''
        )}
        style={{
          ...inputFieldStyles,
          borderColor: errorText ? '#F04438' : '#E4EAF1',
          ...(isDisabled ? { backgroundColor: '#E4EAF1' } : {}),
        }}>
        {!isCreatable && (
          <Select
            value={selectedValue}
            options={items}
            placeholder={placeholder}
            className="select-input text-left"
            onChange={onSelectChange}
            isSearchable={isSearchable}
            isDisabled={isDisabled}
            styles={{
              container: (styles) => ({
                ...styles,
              }),
              menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
              menu: (provided: any) => ({
                ...provided,
                zIndex: 9999,
              }),
              menuList: (provided: any) => ({
                ...provided,
                maxHeight: `${height}px`,
                overflowY: 'auto',
              }),
              control: (provided: any) => ({
                ...provided,
                borderRadius: '8px',
                minHeight: '42px',
                borderColor: errorText ? '#F04438' : '#E4EAF1',
                marginBottom: '4px',
              }),
              placeholder: (provided: any) => ({
                ...provided,
                color: '#94A3B8',
                fontSize: 12,
              }),
              // dropdownIndicator: (provided: any) => ({
              //   ...provided,
              //   color: '#fff',
              //   width: '24px',
              //   height: '24px',
              //   paddingLeft: '0px',
              //   alignItems: 'center',
              // }),
              // singleValue: (provided: any) => ({
              //   ...provided,
              //   color: '#fff  ',
              // }),
              // valueContainer: (provided: any) => ({
              //   ...provided,
              //   color: '#fff',
              //   paddingLeft: '0px',
              //   paddingRight: '0px',
              //   fontFamily: 'Inter',
              // }),
              // option: (provided, state) => ({
              //   ...provided,
              //   backgroundColor: 'white', // Static background color
              //   color: 'black', // Static text color
              //   opacity: state.isFocused ? 0.5 : 1, // Lower opacity by 25% when active
              //   fontFamily: 'Inter',
              // }),
              // indicatorsContainer: (provided: any) => ({
              //   ...provided,
              //   paddingLeft: '0px',
              // }),
              ...customStyles,
            }}
            components={{ ...selectComponents }}
          />
        )}
        {isCreatable && (
          <CreatableSelect
            value={selectedValue}
            isSearchable={isSearchable}
            options={items}
            onCreateOption={(cc) => {
              onCreateNewItem(cc);
            }}
            placeholder={placeholder}
            className="select-input text-left"
            classNamePrefix="select"
            onChange={onSelectChange}
            components={{ ...selectComponents }}
            isDisabled={isDisabled}
          />
        )}
        {errorText && <p className="text-2xs mt-0.5 text-red-500">{errorText}</p>}
      </div>
    </div>
  );
};
