import { Modal } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Viewer } from '@react-pdf-viewer/core';
import {
  RenderCurrentPageLabelProps,
  pageNavigationPlugin,
} from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { X } from 'react-feather';
import { twMerge } from 'tailwind-merge';
import { AppButton } from '../AppButton/AppButton';
import { COLORS } from '../../../utils/colors';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { AppDropDown } from '../AppDropDown/AppDropDown';
import {
  FromFieldTypeReleaseTemplate,
  LienTemplate,
  PDFFieldReleaseTemplate,
  ReleaseFieldData,
  ReleaseTemplateField,
  SupplierJobApiResponse,
} from '@dill/dill-shared/dist/src';
import {
  downloadFile,
  flattenPDF,
  populatePdfWithFieldsData,
  removePDFFields,
  structureTemplateNewFieldFromTypePDFData,
  structureTemplateNonNewFieldFromTypePDFData,
} from '../../../utils/helpers';
import { AppInputField } from '../AppInputField/AppInputField';
import AppDatePicker from '../AppDatePicker/AppDatePicker';
import { useTrivialValueDebounce } from '../../../utils/hooks/useDebounce';
import {
  createUserSignature,
  deleteUserSignature,
  getUserDetails,
  getUserSignatures,
  updateUserDetails,
} from '../../../redux/services/authService';
import { getGroupedSuppliersInvoices } from '../../../redux/services/invoicesService';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';
import { AppInvoiceTable } from '../AppInvoiceTable/AppInvoiceTable';

const AppCreateReleaseModal = ({
  open,
  handleClose,
  selectedJob,
  onSignSignature,
  signature,
}: {
  open: boolean;
  handleClose: () => void;
  selectedJob: SupplierJobApiResponse;
  onSignSignature: () => void;
  signature: string;
}) => {
  const dispatch = useAppDispatch();
  const { lienTemplates } = useAppSelector((state) => state.lienTemplates);
  const { user, userSignatures } = useAppSelector((state) => state.auth);
  const { groupedSuppliersInvoices } = useAppSelector((state) => state.invoice);

  const [selectedTemplate, setSelectedTemplate] = useState<LienTemplate | null>(null);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [showErrorAbove, setShowErrorAbove] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState('');
  const [flattenedPdfUrl, setFlattenedPdfUrl] = useState('');
  const [selectedTemplateFields, setSelectedTemplateFields] = useState<
    ReleaseTemplateField[] | null
  >(null);
  const [formFieldsData, setFormFieldsData] = useState<{
    [x: string]: ReleaseFieldData;
  }>({});
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [showInvoices, setShowInvoices] = useState(false);

  const [debouncedPopulatePdfChange, triggerDebouncedPopulatePdf] = useTrivialValueDebounce(200);
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageLabel } = pageNavigationPluginInstance;

  useEffect(() => {
    if (lienTemplates.length > 0) {
      const template = lienTemplates[0];
      setSelectedTemplate(template);
      if (template.originalPdf?.url) {
        setCurrentPdfUrl(template.originalPdf?.url);
      }
    }
  }, [lienTemplates, open]);

  useEffect(() => {
    if (selectedJob && open) {
      dispatch(getGroupedSuppliersInvoices({ buyerId: selectedJob.buyerId, weeksLimit: 12 }));
    }
  }, [open]);

  useEffect(() => {
    if (selectedTemplate) {
      setSelectedTemplateFields(
        [...selectedTemplate.formFields]
          .sort((A, B) => {
            if (
              (A.position === null || A.position === undefined) &&
              B.position !== null &&
              B.position !== undefined
            ) {
              return 1;
            }
            if (
              (B.position === null || B.position === undefined) &&
              A.position !== null &&
              A.position !== undefined
            ) {
              return -1;
            }
            if (
              A.position !== null &&
              A.position !== undefined &&
              B.position !== null &&
              B.position !== undefined &&
              A.position > B?.position
            ) {
              return 1;
            }
            return -1;
          })
          .filter((formFields) => formFields.fromFieldType === 'NEW_FIELD')
      );
    }

    return () => {};
  }, [selectedTemplate]);

  const subUsersInvoices = useMemo(() => {
    if (groupedSuppliersInvoices && selectedJob) {
      const unPaidInvoices = [];
      const paidInvoices = [];
      for (let index = 0; index < groupedSuppliersInvoices.length; index++) {
        const groupedSuppliersInvoice = groupedSuppliersInvoices[index];
        const buyerId = groupedSuppliersInvoice?.buyer?.id ?? '';
        if (buyerId === selectedJob.buyerId) {
          const invoices = groupedSuppliersInvoice.invoices;
          if (invoices) {
            const filteredPaid = [...invoices.paidInvoices.invoices];
            paidInvoices.push(...filteredPaid);
            const todayInvoices = invoices.todayInvoices.invoices;
            const currentInvoices = invoices.currentInvoices.invoices;
            const pastDueInvoices = invoices.pastDueInvoices.invoices;
            const pendingPayInvoices = invoices.pendingPayInvoices.invoices;
            const filterUnpaidInvoice = [
              ...todayInvoices,
              ...currentInvoices,
              ...pastDueInvoices,
              ...pendingPayInvoices,
            ];
            unPaidInvoices.push(...filterUnpaidInvoice);
          }
        }
      }
      const result = {
        paidInvoices: paidInvoices,
        unPaidInvoices: unPaidInvoices,
      };
      return result;
    }

    return {
      paidInvoices: [],
      unPaidInvoices: [],
    };
  }, [groupedSuppliersInvoices, selectedJob]);

  const job = useMemo(() => {
    let processedJob = {};
    if (selectedJob) {
      processedJob = {
        ...selectedJob,
        number: selectedJob.jobNumber,
        address: selectedJob.jobAddress,
        city: selectedJob.jobCity,
        state: selectedJob.jobState,
        zipCode: selectedJob.jobZipCode,
      };
    }
    return processedJob;
  }, [selectedJob]);

  const vendorContactTitleId = useMemo(() => {
    if (selectedTemplate) {
      const hasTitle = [...selectedTemplate.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' && formFields.id.startsWith('vendorContactTitle')
      );
      if (hasTitle.length > 0) {
        return hasTitle?.[0]?.id;
      }
      return '';
    }

    return '';
  }, [selectedTemplate]);

  const hasUserTitle = useMemo(() => {
    if (selectedTemplate) {
      const hasAddress = [...selectedTemplate.formFields].filter(
        (formFields) =>
          formFields.fromFieldType === 'NEW_FIELD' && formFields.id.startsWith('vendorContactTitle')
      );
      if (hasAddress.length > 0) {
        setTitle(user?.title ?? '');
        setFormFieldsData({
          ...formFieldsData,
          [vendorContactTitleId]: {
            ...formFieldsData[vendorContactTitleId],
            value: user?.title ?? '',
          },
        });
      }
      return hasAddress.length > 0;
    }

    return false;
  }, [selectedTemplate]);

  const checkSignatureError = useMemo(() => {
    let fields: string[] = [];
    if (selectedTemplate) {
      selectedTemplate.formFields.forEach((formField) => {
        if (
          formField.fromFieldType === 'SIGNATURE_FIELD' &&
          formField.whoPopulatesField === 'SUB_SUPPLIER'
        ) {
          if (signature === '') {
            fields = [...fields, formField.id];
          }
        }
      });
    }
    return fields;
  }, [formFieldsData, selectedTemplate, signature]);

  const checkNonSignatureErrors = useMemo(() => {
    let fields: string[] = [];

    if (hasUserTitle && title === '') {
      fields = [...fields, 'userTitle'];
    }

    Object.keys(formFieldsData).forEach((fieldKey) => {
      const fieldData = formFieldsData[fieldKey];
      if (
        fieldData.isRequired &&
        !fieldData.value &&
        !fieldData.id.startsWith('signedDate') &&
        !fieldData.id.startsWith('vendorContactTitle')
      ) {
        fields = [...fields, fieldKey];
      }
    });
    return fields;
  }, [formFieldsData, selectedTemplate, signature]);

  const populatePdf = async (fieldDetails: PDFFieldReleaseTemplate[]) => {
    if (!selectedTemplate?.originalPdf?.url) {
      return;
    }

    const pdfUrl = await removePDFFields(selectedTemplate?.originalPdf?.url ?? '');

    if (!pdfUrl) {
      return;
    }
    const populatedPdfUrl = await populatePdfWithFieldsData({
      fieldDetails,
      url: pdfUrl,
      releaseId: `${selectedJob.id}__${new Date().getTime()}`,
    });
    if (populatedPdfUrl) {
      setCurrentPdfUrl(populatedPdfUrl);
    }
  };

  const populateTemplateFieldData = () => {
    let data: { [x: string]: ReleaseFieldData } = {};
    if (selectedTemplate) {
      selectedTemplate.formFields.forEach((formField) => {
        if (formField.fromFieldType === 'NEW_FIELD') {
          let defaultValue: any = '';
          if (
            formField.fieldType === 'CURRENCY' &&
            formField.defaultValue !== null &&
            formField.defaultValue !== undefined
          ) {
            defaultValue = Number(formField.defaultValue).toFixed(2);
          } else if (formField.defaultValue !== null && formField.defaultValue !== undefined) {
            defaultValue = `${formField.defaultValue}`;
          } else {
            defaultValue = formField.defaultValue;
          }
          data = {
            ...data,
            [formField.id]: {
              isRequired: formField.isFieldRequired,
              value:
                formFieldsData[formField.id] && formFieldsData[formField.id].value
                  ? formFieldsData[formField.id].value
                  : formField.defaultValue !== null && formField.defaultValue !== undefined
                  ? defaultValue
                  : '',
              id: formField.id,
              name: formField.fieldName,
              type: formField.fieldType,
            },
          };
        }
      });
      const currentDate = moment();
      const endOfLastMonth = currentDate.subtract(1, 'months').endOf('month').startOf('day');
      const endOfMonthDate = endOfLastMonth.toDate();
      let throughDateData: { [x: string]: ReleaseFieldData } = {};
      for (const key of Object.keys(data)) {
        if (key.startsWith('throughDate')) {
          throughDateData = {
            ...throughDateData,
            [key]: {
              ...data[key],
              value: new Date(endOfMonthDate),
            },
          };
        }
      }
      data = { ...data, ...throughDateData };
      return { ...data };
    }
  };

  const populateAllPdfData = () => {
    const specialFieldsPDFData: { fromFieldType: FromFieldTypeReleaseTemplate; dataToUse: any }[] =
      [];

    const dynamicForFieldsPdfData: {
      fromFieldId: string;
      value: any;
    }[] = [];

    if (job) {
      specialFieldsPDFData.push({ fromFieldType: 'JOB_FIELD', dataToUse: job });
    }

    if (user && user.userSupplier) {
      const supplierDetails = {
        subSupplierName: user.userSupplier?.name ?? '',
        subSupplierId: user.userSupplier.id,
        subUser: {
          firstName: user?.firstName ?? '',
          lastName: user?.lastName ?? '',
          email: user?.email ?? '',
          title: user?.title ?? '',
          phoneNumber: user?.phoneNumber ?? '',
        },
      };
      specialFieldsPDFData.push({
        fromFieldType: 'SUPPLIER_FIELD',
        dataToUse: supplierDetails,
      });
    }

    if (selectedJob.buyer) {
      specialFieldsPDFData.push({
        fromFieldType: 'USER_BUYER_FIELD',
        dataToUse: selectedJob.buyer,
      });
    }

    if (selectedTemplate) {
      const allNewFormFields = { ...formFieldsData };
      Object.keys(allNewFormFields).forEach((formFieldKey) => {
        if (allNewFormFields[formFieldKey].id !== 'signedDate') {
          dynamicForFieldsPdfData.push({
            fromFieldId: formFieldKey,
            value: allNewFormFields[formFieldKey].value,
          });
        }
      });
    }

    if (signature) {
      specialFieldsPDFData.push({ fromFieldType: 'SIGNATURE_FIELD', dataToUse: signature });
      if (selectedTemplateFields) {
        const foundField = [...selectedTemplateFields].find((field) => field.id === 'signedDate');
        if (foundField) {
          dynamicForFieldsPdfData.push({
            fromFieldId: 'signedDate',
            value: new Date(),
          });
        }
      }
    }

    let pdfData: PDFFieldReleaseTemplate[] = [];
    if (specialFieldsPDFData.length > 0 && selectedTemplate) {
      const data1 = structureTemplateNonNewFieldFromTypePDFData({
        template: selectedTemplate,
        details: specialFieldsPDFData,
      });
      pdfData = [...pdfData, ...data1];
    }
    if (dynamicForFieldsPdfData.length > 0) {
      const data2 = structureTemplateNewFieldFromTypePDFData({
        template: selectedTemplate,
        details: dynamicForFieldsPdfData,
      });
      pdfData = [...pdfData, ...data2];
    }
    if (pdfData.length > 0) {
      populatePdf(pdfData);
    }
  };

  useEffect(() => {
    populateAllPdfData();
  }, [debouncedPopulatePdfChange]);

  useEffect(() => {
    triggerDebouncedPopulatePdf();
    return () => {};
  }, [selectedJob, formFieldsData, selectedTemplate, signature, open, title]);

  const hasSignature = useMemo(() => {
    if (selectedTemplate) {
      const filteredSignatureField = [...selectedTemplate.formFields].filter((formFields) =>
        formFields.id.startsWith('signature')
      );
      return filteredSignatureField.length > 0;
    }

    return false;
  }, [selectedTemplate]);

  useEffect(() => {
    const populatedTemplateFields = populateTemplateFieldData();
    setFormFieldsData({
      ...populatedTemplateFields,
    });

    return () => {};
  }, [selectedTemplate]);

  const handleCreateSignature = async () => {
    if (signature.startsWith('data:image')) {
      const foundSignature = userSignatures.find((item) => item.signature === signature);
      if (!foundSignature) {
        if (userSignatures.length > 4) {
          await dispatch(
            deleteUserSignature({ userSignatureId: userSignatures[userSignatures.length - 1].id })
          );
        }
        const createUserSgnatureResult = await dispatch(
          createUserSignature({
            signature,
            date: new Date(),
          })
        );
        if (createUserSgnatureResult.type === 'auth/createUserSignatures/fulfilled') {
          await dispatch(getUserSignatures());
        }
      }
    }
  };

  const checkErrors = () => {
    let fields: string[] = [];

    if (!selectedTemplate) {
      fields = [...fields, 'template'];
    }

    if (hasUserTitle && title === '') {
      fields = [...fields, 'userTitle'];
    }

    Object.keys(formFieldsData).forEach((fieldKey) => {
      const fieldData = formFieldsData[fieldKey];
      if (
        fieldData.isRequired &&
        !fieldData.value &&
        !fieldData.id.startsWith('signedDate') &&
        !fieldData.id.startsWith('vendorContactTitle')
      ) {
        fields = [...fields, fieldKey];
      }
    });
    setErrorFields(fields);
    return fields;
  };

  const handleCloseModal = () => {
    setErrorFields([]);
    setShowErrorAbove(false);
    setTitle('');
    handleClose();
    setSelectedTemplate(null);
    setCurrentPdfUrl('');
    setFlattenedPdfUrl('');
    setSelectedTemplateFields(null);
    setFormFieldsData({});
    setShowInvoices(false);
  };

  const handleDownloadRelease = async () => {
    await handleCreateSignature();
    if (user && hasUserTitle && title !== user?.title) {
      const updateUserDetailsResponse = await dispatch(
        updateUserDetails({
          userId: user.id,
          userDetails: { title },
        })
      );
      if (updateUserDetailsResponse.type === 'auth/updateUserDetails/fulfilled') {
        await dispatch(getUserDetails());
      }
    }
    setLoading(true);
    const createdAtTimeStamp = new Date().getTime();
    const flattened = await flattenPDF(currentPdfUrl || '');
    if (flattened) {
      downloadFile(
        flattened,
        `${selectedJob?.name ? `${selectedJob.name}-` : ''}${
          selectedJob?.buyer?.name ?? ''
        }-${createdAtTimeStamp}.pdf`
      );
    }
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-80 w-[70vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
        <h2 className="text-lg font-semibold mx-4 my-3">Request Release</h2>
        <X
          className="absolute right-4 top-4 cursor-pointer"
          color={COLORS.GREY_500}
          onClick={handleCloseModal}
        />
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
        <div className="flex justify-between">
          <div className="flex flex-col">
            <div className="w-[35vw] h-[70vh] mt-2 overflow-scroll">
              <div className="h-full px-4 py-2">
                <div className="my-1">
                  <AppDropDown
                    label="Waiver Template"
                    isRequired={true}
                    errorText={errorFields.includes('template') ? 'Please select template' : ''}
                    value={selectedTemplate ? selectedTemplate.id : ''}
                    items={[
                      ...lienTemplates.map((template) => {
                        return {
                          label: template.name,
                          value: template.id,
                        };
                      }),
                    ]}
                    onSelectChange={(item) => {
                      if (item?.value) {
                        const uu = lienTemplates.find((val) => val.id === item.value);
                        if (uu) {
                          setSelectedTemplate(uu);
                          setShowErrorAbove(false);
                          setErrorFields([]);
                        }
                      }
                    }}
                  />
                </div>
                <div>
                  {(subUsersInvoices.paidInvoices.length > 0 ||
                    subUsersInvoices.unPaidInvoices.length > 0) && (
                    <button
                      className="flex items-center my-2"
                      onClick={() => {
                        setShowInvoices(!showInvoices);
                      }}>
                      <AppCheckBox isChecked={showInvoices} />
                      <div className="text-sm ml-2 font-medium">Show Invoices</div>
                    </button>
                  )}
                  {(subUsersInvoices.paidInvoices.length > 0 ||
                    subUsersInvoices.unPaidInvoices.length > 0) &&
                    showInvoices && (
                      <div className="my-1">
                        <AppInvoiceTable invoices={subUsersInvoices} onSelectInvoice={() => {}} />
                      </div>
                    )}
                </div>
                {selectedTemplate && (
                  <div className="flex flex-wrap ">
                    {selectedTemplateFields &&
                      selectedTemplateFields.length > 0 &&
                      [...selectedTemplateFields]
                        .filter(
                          (field) =>
                            field.id !== 'signedDate' && !field.id.startsWith('vendorContactTitle')
                        )
                        .map((formField, i) => {
                          const {
                            id,
                            fieldType,
                            fieldName,
                            isFieldRequired,
                            suffix,
                            prefix,
                            maxValue,
                            minValue,
                          } = formField;
                          const fieldValue = formFieldsData[id]?.value || '';
                          return (
                            <div
                              key={i + 'oo'}
                              className={twMerge(
                                'w-1/2 my-2',
                                i % 2 === 0 ? 'pr-1' : 'pl-1',
                                i ===
                                  selectedTemplate.formFields.filter(
                                    (formFields) =>
                                      formFields.fromFieldType === 'NEW_FIELD' &&
                                      !formFields.id.startsWith('signedDate') &&
                                      !formFields.id.startsWith('vendorContactTitle')
                                  ).length -
                                    1 && i % 2 === 0
                                  ? 'w-full p-0'
                                  : '',
                                fieldType === 'TEXT_AREA' ? 'w-full p-0' : ''
                              )}>
                              {(fieldType === 'TEXT' ||
                                fieldType === 'NUMBER' ||
                                fieldType === 'CURRENCY' ||
                                fieldType === 'TEXT_AREA') && (
                                <AppInputField
                                  label={fieldName}
                                  inputType={
                                    fieldType === 'TEXT_AREA'
                                      ? 'textarea'
                                      : fieldType === 'NUMBER' || fieldType === 'CURRENCY'
                                      ? 'number'
                                      : 'text'
                                  }
                                  value={
                                    fieldValue !== null && fieldValue !== undefined
                                      ? fieldValue
                                      : ''
                                  }
                                  isRequired={isFieldRequired}
                                  errorText={errorFields.includes(id) ? 'Required' : ''}
                                  suffix={suffix || ''}
                                  prefix={prefix || ''}
                                  onBlur={(e) => {
                                    let val = e.target.value;
                                    if (minValue && parseInt(val) <= minValue) {
                                      setFormFieldsData({
                                        ...formFieldsData,
                                        [id]: { ...formFieldsData[id], value: minValue },
                                      });
                                      return;
                                    }
                                    if (maxValue && parseInt(val) >= maxValue) {
                                      setFormFieldsData({
                                        ...formFieldsData,
                                        [id]: { ...formFieldsData[id], value: maxValue },
                                      });
                                      return;
                                    }
                                    if (fieldType === 'CURRENCY') {
                                      if (!val) {
                                        val = null;
                                      } else {
                                        val = Number(Number(val)).toFixed(2);
                                      }
                                      setFormFieldsData({
                                        ...formFieldsData,
                                        [id]: { ...formFieldsData[id], value: val },
                                      });
                                    }
                                  }}
                                  onTextChange={(text) => {
                                    let val = text;

                                    if (fieldType === 'NUMBER') {
                                      if (text !== undefined && text !== null) {
                                        val = text;
                                      } else {
                                        val = null;
                                      }
                                    } else if (fieldType === 'CURRENCY') {
                                      if (text !== undefined && text !== null) {
                                        val = text;
                                      } else {
                                        val = null;
                                      }
                                    }
                                    setShowErrorAbove(false);
                                    setFormFieldsData({
                                      ...formFieldsData,
                                      [id]: { ...formFieldsData[id], value: val },
                                    });
                                  }}
                                />
                              )}
                              {fieldType === 'DATE' && (
                                <AppDatePicker
                                  label={fieldName}
                                  selectedDate={fieldValue}
                                  isRequired={isFieldRequired}
                                  errorText={errorFields.includes(id) ? 'Required' : ''}
                                  onDateSelected={(date) => {
                                    setShowErrorAbove(false);
                                    setFormFieldsData({
                                      ...formFieldsData,
                                      [id]: { ...formFieldsData[id], value: date },
                                    });
                                  }}
                                />
                              )}
                            </div>
                          );
                        })}
                    {hasUserTitle && selectedTemplate && (
                      <div className="w-full my-2 pr-2">
                        <AppInputField
                          label="Job Title"
                          inputType="text"
                          value={title}
                          isRequired={true}
                          errorText={errorFields.includes('userTitle') ? 'Required' : ''}
                          onTextChange={(text) => {
                            setFormFieldsData({
                              ...formFieldsData,
                              [vendorContactTitleId]: {
                                ...formFieldsData[vendorContactTitleId],
                                value: text,
                              },
                            });
                            setTitle(text);
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div>
                  {hasSignature && (
                    <div className="flex">
                      <AppButton
                        text="Sign Release"
                        type="PRIMARY"
                        buttonStyles={{ width: '100px', height: '35px', marginTop: '8px' }}
                        onClick={() => {
                          onSignSignature();
                        }}
                        isDisabled={checkNonSignatureErrors.length !== 0 || signature !== ''}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
              {showErrorAbove ? (
                <div className="my-2 text-xs ERROR_500-CLR text-center">{`${
                  checkNonSignatureErrors.length > 0
                    ? `Please see errors above ${
                        checkSignatureError.length > 0 ? 'and add a signature.' : '.'
                      }`
                    : checkSignatureError.length > 0
                    ? 'Please add a signature to the document.'
                    : ''
                }`}</div>
              ) : (
                <div className="h-[24px]"> </div>
              )}

              <div className="flex justify-end mx-3 mb-2">
                <AppButton
                  text="Cancel"
                  type="TERTIARY"
                  onClick={handleCloseModal}
                  buttonStyles={{ height: '40px', marginRight: '8px' }}
                />
                <AppButton
                  text="Download"
                  onClick={() => {
                    const fieldsWithErrors = checkErrors();
                    setShowErrorAbove(
                      fieldsWithErrors.length !== 0 || checkSignatureError.length !== 0
                    );
                    if (fieldsWithErrors.length === 0 && checkSignatureError.length === 0) {
                      handleDownloadRelease();
                    }
                  }}
                  buttonStyles={{ height: '40px', width: '100px' }}
                  isLoading={loading}
                  isDisabled={loading}
                />
              </div>
            </div>
          </div>
          <div className="w-[35vw] h-[80vh] my-4 mr-4 GREY_200-BG overflow-hidden p-4">
            <div className="h-[74vh] relative  overflow-scroll flex flex-col items-center pb-3">
              <div
                style={{
                  width: '32vw',
                  background: '#F4F7FA',
                }}
                className="sticky top-0  z-10 flex justify-between  mb-0.5"></div>

              <div
                style={{
                  width: '32vw',
                  height: '792px',
                }}
                className="relative ">
                {currentPdfUrl && (
                  <Viewer
                    fileUrl={currentPdfUrl}
                    plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-row justify-center">
              <div>
                <CurrentPageLabel>
                  {(props: RenderCurrentPageLabelProps) => (
                    <span>{`page ${props.currentPage + 1} / ${props.numberOfPages}`}</span>
                  )}
                </CurrentPageLabel>
              </div>

              <ZoomOutButton />
              <ZoomPopover />
              <ZoomInButton />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AppCreateReleaseModal;
