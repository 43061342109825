//external  imports
import { useEffect, useState } from 'react';
import { Plus, Trash } from 'react-feather';

//utils
import { COLORS } from '../../../../utils/colors';

//components
import { AppButton } from '../../../general/AppButton/AppButton';
import { AppInputField } from '../../../general/AppInputField/AppInputField';

//constants
import { InvoiceListButtonStyle, InvoiceReference } from '../../../../constants/invoice';

interface InvoiceListProps {
  className?: string;
  invoiceButtonStyle?: InvoiceListButtonStyle;
  invoices: InvoiceReference[];
  setInvoices: (invoices: InvoiceReference[]) => void;
  invoiceAmountRequired?: boolean;
  isShowErrors?: boolean;
}

const InvoiceListButton = (props: { className?: string; onClick: () => void }) => {
  const { className, onClick } = props;

  return (
    <div className={`flex w-full justify-end ${className}`}>
      <AppButton
        text="Add Invoice"
        buttonStyles={{ height: '10px', padding: '10px' }}
        iconPosition="RIGHT"
        icon={<Plus color={COLORS.WHITE} size={12} />}
        onClick={onClick}
      />
    </div>
  );
};

const EditInvoiceList: React.FC<InvoiceListProps> = (props: InvoiceListProps) => {
  const {
    className,
    invoiceButtonStyle = InvoiceListButtonStyle.TOP,
    invoices,
    setInvoices,
    invoiceAmountRequired = true,
    isShowErrors = false,
  } = props;

  const handleAddInvoice = () => {
    setInvoices([
      ...invoices,
      {
        invoiceNumber: '',
        amount: null,
      },
    ]);
  };

  const handleEditInvoice = ({
    value,
    key,
    position,
  }: {
    value: any;
    key: string;
    position: number;
  }) => {
    setInvoices([
      ...invoices.map((invoice, index) => {
        if (index === position) {
          return { ...invoice, [key]: value };
        }
        return invoice;
      }),
    ]);
  };

  const handleDeleteInvoice = (position: number) => {
    setInvoices([...invoices.filter((_, index) => index !== position)]);
  };

  return (
    <div className={`w-full ${className}`}>
      {invoiceButtonStyle == InvoiceListButtonStyle.TOP && (
        <InvoiceListButton className={'mt-6'} onClick={handleAddInvoice} />
      )}

      <div
        className={`overflow-auto p-3 bg-slate-50 ${
          invoiceButtonStyle == InvoiceListButtonStyle.TOP && 'max-h-72 h-72'
        }`}>
        {invoices.map((invoice, invoiceIndex) => {
          return (
            <div key={invoiceIndex + 'oo'} className="flex items-end  justify-between mb-4">
              <div className="mx-1 w-full">
                <AppInputField
                  label="Invoice Number"
                  value={invoice.invoiceNumber}
                  isRequired={true}
                  errorText={
                    isShowErrors &&
                    ((invoiceAmountRequired && !invoice.invoiceNumber) ||
                      (!invoice.invoiceNumber && invoice.amount))
                      ? 'Required'
                      : ''
                  }
                  onTextChange={(text) => {
                    handleEditInvoice({
                      value: text,
                      key: 'invoiceNumber',
                      position: invoiceIndex,
                    });
                  }}
                />
              </div>
              <div className="mx-1 w-full">
                <AppInputField
                  prefix="$"
                  label="Amount"
                  isRequired={invoiceAmountRequired}
                  inputType="number"
                  errorText={
                    isShowErrors && invoiceAmountRequired && invoice.amount === 0
                      ? 'Amount has to be greater than 0'
                      : isShowErrors &&
                        invoiceAmountRequired &&
                        (invoice.amount === null || invoice.amount === undefined)
                      ? 'Required'
                      : ''
                  }
                  value={invoice.amount}
                  onBlur={(e) => {
                    let val = e.target.value;
                    if (!val) {
                      val = null;
                    } else {
                      val = Number(Number(val)).toFixed(2);
                    }
                    handleEditInvoice({
                      value: val,
                      key: 'amount',
                      position: invoiceIndex,
                    });
                  }}
                  onTextChange={(text) => {
                    handleEditInvoice({
                      value: text ? Number(text) : null,
                      key: 'amount',
                      position: invoiceIndex,
                    });
                  }}
                />
              </div>
              <div className=" mx-1">
                <Trash
                  className={'bg-white rounded-lg p-2 cursor-pointer mb-1'}
                  size={35}
                  color={COLORS.ERROR_400}
                  onClick={() => {
                    handleDeleteInvoice(invoiceIndex);
                  }}
                />
              </div>
            </div>
          );
        })}
        {invoiceButtonStyle == InvoiceListButtonStyle.BOTTOM && (
          <InvoiceListButton className={'mt-6'} onClick={handleAddInvoice} />
        )}
      </div>
    </div>
  );
};

export default EditInvoiceList;
