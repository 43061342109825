import { Modal } from '@mui/material';
import React from 'react';
import { AppButton } from '../AppButton/AppButton';
import './AppDeleteModal.scss';
import { COLORS, ColorType } from '../../../utils/colors';
import { Trash } from 'react-feather';

export const AppDeleteModal = ({
  title = '',
  subTitle = '',
  highlightedText = '',
  buttonText = 'Back',
  open,
  buttonColor = 'PRIMARY_500',
  buttonTextColor = 'WHITE',
  handleClose,
  isSecondButton = false,
  onSecondButtonClick,
  secondButtonText = '',
  secondButtonTextColor = 'GREY_500',
  secondButtonColor = 'GREY_100',
}: {
  title?: string;
  subTitle?: string;
  highlightedText?: string;
  secondButtonText?: string;
  isSecondButton?: boolean;
  buttonText?: string;
  modalImage?: any;
  open: boolean;
  buttonColor?: ColorType;
  buttonTextColor?: ColorType;
  secondButtonTextColor?: ColorType;
  secondButtonColor?: ColorType;
  handleClose?: () => void;
  onSecondButtonClick?: () => void;
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="delete-modal-content">
        <div className="rounded-full ERROR_100-BG p-6">
          <Trash color={COLORS.ERROR_500} size={52} />
        </div>
        <p className="mt-4 font-bold text-center text-2xl mb-2  px-6">{title}</p>
        <p className="text-sm mb-2 TEXT_SECONDARY-CLR text-center">{subTitle}</p>
        {highlightedText && (
          <p className="PRIMARY_500-CLR text-sm font-medium">{highlightedText}</p>
        )}
        <div className="flex mt-6">
          <AppButton
            text={secondButtonText}
            type="TERTIARY"
            buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
            onClick={onSecondButtonClick}
          />
          <AppButton
            text={buttonText}
            buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
            onClick={handleClose}
          />
        </div>
      </div>
    </Modal>
  );
};
