import { Modal } from '@mui/material';
import React from 'react';
import { AppButton } from '../AppButton/AppButton';
import deleteWarnCircle from '../../../assets/svg/delete_warn_circle.svg';

export const AppConfirmationModal = ({
  title = '',
  subTitle = '',
  highlightedText = '',
  positiveButtonText = "Yes, I'm sure",
  negativeButtonText = "No, I'm not",
  onPositiveButtonClick = () => {},
  onNegativeButtonClick = () => {},
  modalImage = deleteWarnCircle,
  open,
  handleClose,
}: {
  title?: string;
  subTitle?: string;
  highlightedText?: string;
  positiveButtonText?: string;
  negativeButtonText?: string;
  modalImage?: any;
  open: boolean;
  onPositiveButtonClick: () => void;
  onNegativeButtonClick: () => void;
  handleClose: () => void;
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="confirm-modal-content">
        <img src={modalImage} alt="" />
        <p className="mt-4 font-bold text-center text-2xl mb-1">{title}</p>
        <p className="text-sm mb-2">{subTitle}</p>
        {highlightedText && (
          <p className="PRIMARY_500-CLR text-sm font-medium">{highlightedText}</p>
        )}
        <div className="action-buttons">
          <AppButton
            text={negativeButtonText}
            type="SECONDARY"
            buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
            onClick={onNegativeButtonClick}
          />
          <AppButton
            text={positiveButtonText}
            type="PRIMARY"
            buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
            onClick={onPositiveButtonClick}
          />
        </div>
      </div>
    </Modal>
  );
};
