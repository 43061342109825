import React from 'react';
import emptyListImg from '../../../assets/images/emptyList.png';
import './AppErrorPage.scss';

export const AppErrorPage = ({
  errorMessage,
  title,
  contactMessage,
}: {
  errorMessage: string;
  title: string;
  contactMessage: string;
}) => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="flex flex-col items-center">
        <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
        <p className="error-page-error-title">{title}</p>
        <p className="error-page-error-title">{contactMessage}</p>
        <p className="error-page-error-text">{errorMessage}</p>
      </div>
    </div>
  );
};
