import { Modal } from '@mui/material';
import './ConnectQuickBooksModal.scss';
import { AppButton } from '../../../general/AppButton/AppButton';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { Check } from 'react-feather';
import { X } from 'phosphor-react';
import { COLORS } from '../../../../utils/colors';

export const ConnectQuickBooksModal = ({
  open,
  handleClose = () => {},
  titleText = '',
  subTitleText = '',
  buttonText = '',
  secondaryButtonText = '',
  handleButtonClick = () => {},
  handleSecondaryButtonClick = () => {},
  modalImage,
  isSuccess = true,
  isButton = true,
  isAuthorize = false,
  secondaryButton = false,
  additionalInfo = [],
}: {
  open: boolean;
  isSuccess?: boolean;
  isButton?: boolean;
  isAuthorize?: boolean;
  handleClose: () => void;
  handleButtonClick: () => void;
  handleSecondaryButtonClick?: () => void;
  titleText: string;
  subTitleText: string;
  buttonText: string;
  secondaryButtonText?: string;
  modalImage: any;
  secondaryButton?: boolean;
  additionalInfo?: string[];
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="quick-books-connect-content text-center">
        <AppButton
          type="FLAT"
          buttonStyles={{
            padding: '0px',
            margin: '5px 2px',
            position: 'absolute',
            top: '20px',
            right: '20px',
          }}
          icon={<X color={COLORS.GREY_300} size={30} />}
          onClick={handleClose}
        />
        <img className="h-13 my-4" src={modalImage} />
        <p className="title-text">{titleText}</p>
        {isSuccess && <p className="subtitle-text">{subTitleText}</p>}
        {!isSuccess && subTitleText && <p className="subtitle-text">{subTitleText}</p>}
        {!isSuccess && !subTitleText && (
          <p className="subtitle-text">
            Reach out to{' '}
            <span className="PRIMARY_500-CLR font-bold whitespace-nowrap">972-339-0799</span> for
            customer support
          </p>
        )}
        {isAuthorize && (
          <div>
            <p>
              This will allow <span>Dill</span> to :
            </p>
            <div className="">
              <Check />
              <p>See invoice data in Quickbooks</p>
            </div>
            <p>
              This does not mean that <span>Dill</span> can:
            </p>
            <div className="">
              <Check />
              <p>Edit, create, or delete invoice data in Quickbooks</p>
            </div>
          </div>
        )}
        {additionalInfo.length > 0 && (
          <ul className="additional-info">
            {additionalInfo.map((item, index) => (
              <li className="additional-info-item" key={`additional_info_${index}`}>
                {item}
              </li>
            ))}
          </ul>
        )}
        <div className="flex mt-12">
          {secondaryButton && (
            <AppButton
              text={secondaryButtonText}
              type="TERTIARY"
              onClick={handleSecondaryButtonClick}
              buttonStyles={{ marginRight: '8px' }}
            />
          )}
          {isButton && (
            <AppButton
              text={buttonText}
              type={secondaryButton ? 'PRIMARY' : 'TERTIARY'}
              onClick={handleButtonClick}
              buttonStyles={{
                display: 'flex',
                alignSelf: 'center',
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
