import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AppButton } from '../AppButton/AppButton';
import { AppInputField } from '../AppInputField/AppInputField';
import { X } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import AppFilePicker from '../AppFilePicker/AppFilePicker';
import { AppDropDown } from '../AppDropDown/AppDropDown';

const AppNotesModal = ({
  open,
  handleClose,
  onSubmit,
  type = 'supplier',
  title,
  primaryButtonText,
  value,
  loading = false,
  placeHolder,
  hasAttachment,
  onSelectFile,
  hasDropDown,
  dropDownOptions,
  onSelectDropDown,
}: {
  open: boolean,
  handleClose: () => void,
  onSubmit: (value: string) => void,
  type?: 'supplier' | 'buyer',
  title?: string,
  primaryButtonText?: string,
  value?: string,
  loading?: boolean,
  placeHolder?: string,
  hasAttachment?: boolean,
  onSelectFile?: (files: File[], fileUrl?: { name: string, url: string }[]) => void,
  hasDropDown?: boolean,
  dropDownOptions?: {
    label: string,
    options: {
      label: string,
      value: string,
    }[],
  },
  onSelectDropDown?: (value: string) => void,
}) => {
  const [textFieldValue, setTextFieldValue] = useState('');
  const [dropDown, setDropDown] = useState('');
  const [dropDownError, setDropDownError] = useState('');

  const handleSubmit = () => {
    if (hasDropDown && !dropDown) {
      setDropDownError('Please select an option');
      return;
    }
    onSubmit(textFieldValue);
  };
  const handleCloseModal = () => {
    setTextFieldValue('');
    handleClose();
  };
  useEffect(() => {
    if (value && open) {
      setTextFieldValue(value);
    } else {
      setTextFieldValue('');
    }
    if (open) {
      setDropDownError('');
      setDropDown('');
    }
  }, [value, open]);

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-80 w-4/12 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
        <div>
          <h2 className="text-lg font-semibold mx-4 my-3">
            {title ? title : type === 'buyer' ? 'Reject Reason' : 'Add decline reason'}
          </h2>
          <X
            className="absolute right-4 top-4 cursor-pointer"
            color={COLORS.GREY_500}
            onClick={handleClose}
          />
        </div>
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
        <div className=" m-4 p-3 bg-slate-100">
          {hasDropDown && (
            <AppDropDown
              isRequired
              label={dropDownOptions.label}
              errorText={dropDownError}
              value={dropDown}
              items={dropDownOptions.options}
              onSelectChange={(item) => {
                if (item?.value) {
                  setDropDown(item?.value || '');
                  setDropDownError('');
                  if (onSelectDropDown) {
                    onSelectDropDown(item?.value || '');
                  }
                }
              }}
            />
          )}
          <div className="flex flex-col">
            <AppInputField
              label={
                placeHolder
                  ? placeHolder
                  : type === 'buyer'
                  ? 'Why are you rejecting this signed lien release?'
                  : ''
              }
              inputType="textarea"
              onTextChange={(text) => {
                setTextFieldValue(text);
                setDropDownError('');
              }}
              value={textFieldValue}
            />
          </div>
          {hasAttachment && (
            <div className="WHITE-BG mt-2 p-2">
              <AppFilePicker
                onSelectFile={onSelectFile}
                onDropProps={{
                  accept: {
                    'application/pdf': ['.pdf'],
                  },
                }}
              />
            </div>
          )}
        </div>
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
        <div className="flex justify-end mx-3 mb-2">
          <AppButton
            text="Close"
            type="TERTIARY"
            onClick={handleCloseModal}
            buttonStyles={{ marginRight: '8px' }}
          />
          <AppButton
            text={primaryButtonText ? primaryButtonText : type === 'buyer' ? 'Reject' : 'Decline'}
            onClick={handleSubmit}
            isDisabled={textFieldValue === '' || loading}
            isLoading={loading}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AppNotesModal;
