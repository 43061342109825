import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import {
  assignDriverToSalesReceipt,
  downloadMultiSalesReceiptPdfZip,
  getSalesReceiptPdf,
} from '../services/salesReceiptsService';
import { SalesReceiptsInitialState } from '../globalSlices/salesReceiptsSlice';

export const getSalesReceiptPdfExtraReducer = (
  builder: ActionReducerMapBuilder<SalesReceiptsInitialState>
) => {
  const reducerId = 'getSalesReceiptPdf';
  builder.addCase(getSalesReceiptPdf.pending, (state, action) => {
    // state.salesReceiptBase64 = null;
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getSalesReceiptPdf.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.previewPdf = action.payload?.data?.pdfUrl;
      } else {
        state.previewPdf = null;
      }
    }
  );
  builder.addCase(getSalesReceiptPdf.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.salesReceiptBase64 = null;
  });
};

export const downloadMultiSalesReceiptPdfZipExtraReducer = (
  builder: ActionReducerMapBuilder<SalesReceiptsInitialState>
) => {
  const reducerId = 'downloadMultiSalesReceiptPdfZip';
  builder.addCase(downloadMultiSalesReceiptPdfZip.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    downloadMultiSalesReceiptPdfZip.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
      } else {
      }
    }
  );
  builder.addCase(downloadMultiSalesReceiptPdfZip.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const assignDriverToSalesReceiptExtraReducer = (
  builder: ActionReducerMapBuilder<SalesReceiptsInitialState>
) => {
  const reducerId = 'assignDriverToSalesReceipt';
  builder.addCase(assignDriverToSalesReceipt.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    assignDriverToSalesReceipt.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      console.log(action.payload);
    }
  );
  builder.addCase(assignDriverToSalesReceipt.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    console.log(action.payload?.data);
  });
};
