import { createSlice } from '@reduxjs/toolkit';
import { getDistributionOneInvoicesExtraReducer } from '../reducers/distributionOneReducers';

export interface DistributionOneInitialState {
  loadingList: string[];
  distributionOneInvoices: any[];
}

const initialState: DistributionOneInitialState = {
  loadingList: [],
  distributionOneInvoices: [],
};

export const distributionOneSlice = createSlice({
  name: 'distributionOne',
  initialState,
  reducers: {
    resetManualUploadsRootState: () => initialState,
  },
  extraReducers(builder) {
    getDistributionOneInvoicesExtraReducer(builder);
  },
});

export const { resetManualUploadsRootState } = distributionOneSlice.actions;
