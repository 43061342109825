import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import {
  assignDriverToSalesReceiptExtraReducer,
  downloadMultiSalesReceiptPdfZipExtraReducer,
  getSalesReceiptPdfExtraReducer,
} from '../reducers/salesReceiptsReducers';

export interface SalesReceiptsInitialState {
  loadingList: string[];
  salesReceiptIdsForDownload: string[];
  salesReceiptBase64: string | null;
  previewPdf: string | null;
}

const initialState: SalesReceiptsInitialState = {
  loadingList: [],
  salesReceiptIdsForDownload: [],
  salesReceiptBase64: null,
  previewPdf: null,
};

export const salesReceiptsSlice = createSlice({
  name: 'salesReceipts',
  initialState,
  reducers: {
    resetSalesReceiptsRootState: () => initialState,
    selectSalesReceiptIdsForDownload: (state, action: PayloadAction<string[]>) => {
      state.salesReceiptIdsForDownload = [
        ...new Set([...state.salesReceiptIdsForDownload, ...action.payload]),
      ];
    },
    unSelectSalesReceiptIdsForDownload: (state, action: PayloadAction<string[]>) => {
      state.salesReceiptIdsForDownload = [
        ...state.salesReceiptIdsForDownload.filter(
          (salesReceiptId) => !action.payload.includes(salesReceiptId)
        ),
      ];
    },
    resetSalesReceiptIdsForDownload: (state) => {
      state.salesReceiptIdsForDownload = [];
    },
  },
  extraReducers(builder) {
    getSalesReceiptPdfExtraReducer(builder);
    downloadMultiSalesReceiptPdfZipExtraReducer(builder);
    assignDriverToSalesReceiptExtraReducer(builder);
  },
});

export const {
  resetSalesReceiptsRootState,
  selectSalesReceiptIdsForDownload,
  unSelectSalesReceiptIdsForDownload,
  resetSalesReceiptIdsForDownload,
} = salesReceiptsSlice.actions;
