import { createSlice } from '@reduxjs/toolkit';
import {
  createJobsExtraReducer,
  getJobsExtraReducer,
  updateJobExtraReducer,
  getJobExtraReducer,
  updateBillingRepJobsExtraReducer,
} from '../reducers/jobsReducers';
import { Job, JobDetailsApiResponse } from '@dill/dill-shared';

export interface JobsInitialState {
  loadingList: string[];
  jobs: Job[];
  loadingErrorList: object[];
  selectedJob: JobDetailsApiResponse | null;
}

const initialState: JobsInitialState = {
  loadingList: [],
  jobs: [],
  loadingErrorList: [],
  selectedJob: null,
};

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    resetJobsRootState: () => initialState,
  },
  extraReducers(builder) {
    createJobsExtraReducer(builder);
    getJobsExtraReducer(builder);
    updateJobExtraReducer(builder);
    getJobExtraReducer(builder);
    updateBillingRepJobsExtraReducer(builder);
  },
});

export const { resetJobsRootState } = jobsSlice.actions;
