import { createSlice } from '@reduxjs/toolkit';
import {
  manualUploadDataExtraReducer,
  getManualUploadInvoicesExtraReducer,
  getManualUploadCustomersExtraReducer,
  getManualUploadJobsExtraReducer,
  recursiveGetManualUploadInvoicesExtraReducer,
  getManualUploadInvoicesIdsExtraReducer,
} from '../reducers/manualUploadReducers';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  ManualUploadCustomer,
  ManualUploadJob,
  ManualUploadInvoiceResponse,
} from '@dill/dill-shared';

export interface ManualUploadInitialState {
  loadingList: string[];
  manualUploadInvoices: ManualUploadInvoiceResponse[];
  manualUploadJobs: ManualUploadJob[];
  manualUploadCustomers: ManualUploadCustomer[];
  numberOfManualInvoices: number | null;
  existingManualUploadInvoices: ManualUploadInvoiceResponse[];
  lastRefKey: string | null;
}

const initialState: ManualUploadInitialState = {
  loadingList: [],
  manualUploadInvoices: [],
  manualUploadJobs: [],
  manualUploadCustomers: [],
  numberOfManualInvoices: null,
  existingManualUploadInvoices: [],
  lastRefKey: null,
};

export const manualUploadSlice = createSlice({
  name: 'manualUpload',
  initialState,
  reducers: {
    resetManualUploadsRootState: () => initialState,
  },
  extraReducers(builder) {
    getManualUploadInvoicesExtraReducer(builder);
    manualUploadDataExtraReducer(builder);
    getManualUploadCustomersExtraReducer(builder);
    getManualUploadJobsExtraReducer(builder);
    recursiveGetManualUploadInvoicesExtraReducer(builder);
    getManualUploadInvoicesIdsExtraReducer(builder);
  },
});

export const { resetManualUploadsRootState } = manualUploadSlice.actions;
