import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import {
  createBuyerLienTemplate,
  createLienTemplateByAdmin,
  getLienTemplates,
  getLienTemplatesByAdmin,
  getSupplierLienTemplates,
} from '../services/leinTemplatesService';
import { LienTemplatesInitialState } from '../globalSlices/lienTemplatesSlice';

export const createLienTemplateByAdminExtraReducer = (
  builder: ActionReducerMapBuilder<LienTemplatesInitialState>
) => {
  const reducerId = 'createLienTemplateByAdmin';
  builder.addCase(createLienTemplateByAdmin.pending, (state, action) => {
    // state.salesReceiptBase64 = null;
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createLienTemplateByAdmin.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(createLienTemplateByAdmin.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const createBuyerLienTemplateExtraReducer = (
  builder: ActionReducerMapBuilder<LienTemplatesInitialState>
) => {
  const reducerId = 'createBuyerLienTemplate';
  builder.addCase(createBuyerLienTemplate.pending, (state, action) => {
    // state.salesReceiptBase64 = null;
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createBuyerLienTemplate.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(createBuyerLienTemplate.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const getLienTemplatesExtraReducer = (
  builder: ActionReducerMapBuilder<LienTemplatesInitialState>
) => {
  const reducerId = 'getLienTemplates';
  builder.addCase(getLienTemplates.pending, (state, action) => {
    // state.salesReceiptBase64 = null;
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(getLienTemplates.fulfilled, (state, action: PayloadAction<any | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload.success && action.payload.data) {
      state.lienTemplates = action.payload.data;
    }
  });
  builder.addCase(getLienTemplates.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getLienTemplatesByAdminExtraReducer = (
  builder: ActionReducerMapBuilder<LienTemplatesInitialState>
) => {
  const reducerId = 'getLienTemplatesByAdmin';
  builder.addCase(getLienTemplatesByAdmin.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(getLienTemplatesByAdmin.fulfilled, (state, action: PayloadAction<any | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload.success && action.payload.data) {
      state.lienTemplatesByAdmin = action.payload.data;
    }
  });
  builder.addCase(getLienTemplatesByAdmin.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getSupplierLienTemplatesExtraReducer = (
  builder: ActionReducerMapBuilder<LienTemplatesInitialState>
) => {
  const reducerId = 'getSupplierLienTemplates';
  builder.addCase(getSupplierLienTemplates.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getSupplierLienTemplates.fulfilled,
    (state, action: PayloadAction<any | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload.success && action.payload.data) {
        state.lienTemplates = action.payload.data;
      }
    }
  );
  builder.addCase(getSupplierLienTemplates.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
