import React, { useEffect, useState } from 'react';
import { SupplierPaymentTable } from '../SupplierPaymentTable/SupplierPaymentTable';
import { Bank, ClipboardText, Plus, CreditCard, Warning } from 'phosphor-react';
import { AppButton } from '../../../general/AppButton/AppButton';
import { COLORS } from '../../../../utils/colors';
import { useAppSelector, useAppDispatch } from '../../../../redux/store';
import {
  getSupplierPaymentMethods,
  getSupplierRegistrationStatus,
  getSupplierTransactions,
} from '../../../../redux/services/paymentsService';
import { DillPaymentMethod, formatMoney } from '@dill/dill-shared';
import './SupplierPayments.scss';
import AppAddPaymentMethodModal from '../../../general/AppAddPaymentMethodModal/AppAddPaymentMethodModal';
import SupplierWithdrawModal from '../../suppliers/SupplierWithdrawModal/SupplierWithdrawModal';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import AppVerifyDwollaMicroTransactionsModal from '../../../general/AppVerifyDwollaMicroTransactionsModal/AppVerifyDwollaMicroTransactionsModal';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export const SupplierPayments = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isOpenActionOption, setOpenActionOptions] = useState('');
  const [isAppAddPaymentMethodModalOpen, setIsAppAddPaymentMethodModalOpen] = useState(false);
  const [isSupplierWithdrawModalOpen, setIsSupplierWithdrawModalOpen] = useState(false);
  const { user } = useAppSelector((state) => state.auth);
  const [
    isAppVerifyDwollaMicroTransactionsModalOpen,
    setIsAppVerifyDwollaMicroTransactionsModalOpen,
  ] = useState(false);
  const [
    selectedPaymentMethodToVerifyMicroTransactions,
    setSelectedPaymentMethodToVerifyMicroTransactions,
  ] = useState<DillPaymentMethod>();

  const toggleDropdown = (value: string) => {
    setOpenActionOptions(value);
  };
  const handleDelete = () => {
    setOpenActionOptions('');
  };
  useEffect(() => {
    dispatch(getSupplierPaymentMethods());
  }, []);
  const { totalSupplierBalance, supplierPaymentMethods, amountToWithraw } = useAppSelector(
    (state) => state.payments
  );

  useEffect(() => {
    if (location.pathname.startsWith('/payments')) {
      const searchParams = new URLSearchParams(location.search);
      const paymentMethodId = searchParams.get('paymentMethodId');
      if (paymentMethodId && supplierPaymentMethods.length > 0) {
        const paymentMethod = supplierPaymentMethods.find((item) => item.id === paymentMethodId);
        if (paymentMethod) {
          setSelectedPaymentMethodToVerifyMicroTransactions(paymentMethod);
          setIsAppVerifyDwollaMicroTransactionsModalOpen(true);
        }
      }
    }
  }, [location.pathname, location.search, supplierPaymentMethods]);

  return (
    <div className="h-full px-4 py-8 flex">
      <AppAddPaymentMethodModal
        open={isAppAddPaymentMethodModalOpen}
        paymentOptionsToShow="BANK"
        userType="supplier"
        handleClose={() => {
          setIsAppAddPaymentMethodModalOpen(false);
          dispatch(getSupplierPaymentMethods());
        }}
      />
      <SupplierWithdrawModal
        open={isSupplierWithdrawModalOpen}
        handleClose={() => {
          setIsSupplierWithdrawModalOpen(false);
        }}
      />
      <AppVerifyDwollaMicroTransactionsModal
        selectedPaymentMethod={selectedPaymentMethodToVerifyMicroTransactions}
        open={isAppVerifyDwollaMicroTransactionsModalOpen}
        handleClose={() => {
          dispatch(getSupplierPaymentMethods());
          dispatch(getSupplierRegistrationStatus());
          setSelectedPaymentMethodToVerifyMicroTransactions(undefined);
          setIsAppVerifyDwollaMicroTransactionsModalOpen(false);
          navigate('/payments');
        }}
      />
      <div className="flex flex-col px-5 w-1/5">
        <div className="title">Balance</div>
        <div className="summary-card">
          <div className="summary-icon-container">
            <ClipboardText color={COLORS.PRIMARY_500} />
          </div>
          <div className="w-full flex flex-row justify-between items-center">
            <div>
              <div className="summary-amount">{formatMoney(totalSupplierBalance, 2)}</div>
              <p className="text-xs PRIMARY_500-CLR mt-1 ">
                Amount to withdraw {formatMoney(amountToWithraw, 2)}
              </p>
            </div>
            {/* {user?.isAdmin && user.userSupplier?.dwollaCustomerStatus === 'verified' && (
              <AppButton
                buttonStyles={{ padding: '5px 8px', borderRadius: '6px' }}
                buttonType={'OUTLINED'}
                text={'Withdraw'}
                onClick={() => {
                  setIsSupplierWithdrawModalOpen(true);
                }}
              />
            )} */}
          </div>
        </div>

        <div className="flex flex-col h-full">
          {user?.isAdmin && user.userSupplier?.dwollaCustomerStatus === 'verified' && (
            <div className="flex justify-between">
              <h2>Withdrawal Methods</h2>
              <AppButton
                buttonStyles={{ padding: '3px 6px', borderRadius: '6px' }}
                text={'Add'}
                icon={
                  <Plus
                    color={supplierPaymentMethods.length > 0 ? COLORS.PRIMARY_300 : COLORS.WHITE}
                  />
                }
                onClick={() => {
                  setIsAppAddPaymentMethodModalOpen(true);
                }}
                isDisabled={supplierPaymentMethods.length > 0}
                // isDisabled={true}
              />
            </div>
          )}

          <div className="w-full withdrawal-container mt-4 overflow-scroll flex flex-col">
            {user?.isAdmin &&
              supplierPaymentMethods.map((paymentMethod: any, i) => (
                <div
                  className="flex flex-col bg-white min-h-fit p-5 rounded-lg my-2"
                  key={`buyer_payment_${paymentMethod.id}`}
                  data-tooltip-id={`payment-method-tooltip_${i}`}>
                  <div className="w-full flex justify-between">
                    <div className="flex GREY_100-BG rounded-sm px-1 py-2 h-10">
                      {paymentMethod.type === 'BANK_ACCOUNT' ? (
                        <Bank size={30} style={{ display: 'flex', alignSelf: 'center' }} />
                      ) : (
                        <CreditCard size={30} style={{ display: 'flex', alignSelf: 'center' }} />
                      )}
                    </div>
                    {/* <div className="relative">
                    <button onClick={() => toggleDropdown(paymentMethod.id)}>
                      <MoreVertical color={COLORS.GREY_300} size={24} />
                    </button>
                    {isOpenActionOption === paymentMethod.id && (
                      <div className="absolute">
                        <AppButton
                          buttonColor="GREY_200"
                          buttonType="OUTLINED"
                          buttonTextColor="GREY_500"
                          text="Delete"
                          buttonStyles={{
                            padding: '8px',
                            borderWidth: 1,
                            borderColor: COLORS.GREY_200,
                          }}
                          onClick={() => {
                            handleDelete();
                          }}
                        />
                      </div>
                    )}
                  </div> */}
                  </div>
                  {paymentMethod.type === 'BANK_ACCOUNT' ? (
                    <div>
                      <div className="card-title">{paymentMethod.paymentMethodName}</div>
                      <div className="exp-card-date">{paymentMethod.bankAccountNumber}</div>
                    </div>
                  ) : (
                    <div>
                      <div className="card-title">{paymentMethod.paymentMethodName}</div>
                      <div className="exp-card-date">{`Expires ${paymentMethod.bankAccountNumber}`}</div>
                    </div>
                  )}
                  <div className="">
                    {(paymentMethod.status === 'MICRO_DEPOSITS_SENT' ||
                      paymentMethod.status === 'MICRO_DEPOSITS_VERIFICATION_PENDING' ||
                      paymentMethod.status === 'UNVERIFIED') && (
                      <div className="flex-end">
                        <div className="text-xs flex">
                          <Warning size={20} color={COLORS.ERROR_500} />
                          Unverified
                        </div>
                        <ReactTooltip
                          id={`payment-method-tooltip_${i}`}
                          place="top"
                          variant="dark"
                          opacity={'100%'}
                          style={{
                            display: 'flex',
                            background: '#334155',
                            width: '300px',
                            zIndex: '70',
                            borderRadius: '8px',
                            alignItems: 'center',
                            justifyItems: 'center',
                            fontWeight: '600',
                          }}>
                          {paymentMethod.status === 'MICRO_DEPOSITS_VERIFICATION_PENDING' ? (
                            <span>
                              Two micro-deposits have arrived in your account. Please check for
                              these deposits in your account and verify them in order to use this
                              bank method.
                            </span>
                          ) : (
                            <span>
                              Two micro-deposits were sent to this bank account. They should arrive
                              1-2 business days from the day it was added. Once you see these
                              deposits in your account, please verify them in order to use this bank
                              method.
                            </span>
                          )}
                        </ReactTooltip>

                        <div className="">
                          <AppButton
                            text="Verify Deposits"
                            isDisabled={
                              paymentMethod.status !== 'MICRO_DEPOSITS_VERIFICATION_PENDING'
                            }
                            onClick={() => {
                              navigate(`${location.pathname}?paymentMethodId=${paymentMethod.id}`);
                              setSelectedPaymentMethodToVerifyMicroTransactions(paymentMethod);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <AppButton
                  buttonColor="PRIMARY_500"
                  buttonType="OUTLINED"
                  buttonTextColor="PRIMARY_500"
                  text="Details"
                  buttonStyles={{
                    padding: '8px',
                    borderWidth: 2,
                    borderColor: COLORS.PRIMARY_500,
                    width: '100%',
                    marginTop: '10px',
                  }}
                  onClick={() => {}}
                /> */}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="w-4/5">
        <SupplierPaymentTable />
      </div>
    </div>
  );
};
