import React, { useEffect, useMemo, useReducer, useState } from 'react';

import moment from 'moment';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { twMerge } from 'tailwind-merge';
import { Loader, X } from 'react-feather';
import { useDropzone } from 'react-dropzone';
import {
  Briefcase,
  CheckCircle,
  FileCsv,
  Notebook,
  Notepad,
  Warning,
  XCircle,
  CaretLeft,
  CaretRight,
  BookOpen,
} from 'phosphor-react';

import { Modal } from '@mui/material';
import {
  formatMoney,
  ManualUploadCustomer,
  ManualUploadInvoice,
  ManualUploadInvoiceResponse,
  ManualUploadJob,
  ManualUploadLineItems,
  ManualUploadPayment,
  ManualUploadOpenItem,
  ManualUploadGroupedPayment,
  processAllUploadedData,
  groupPaymentsInvoices,
} from '@dill/dill-shared/dist/src';

import { COLORS } from '../../../utils/colors';
import { AppButton } from '../AppButton/AppButton';
import { useAppDispatch } from '../../../redux/store';
import { AppVirtualizedList } from '../AppVirtualizedList/AppVirtualizedList';
import {
  getManualUploadInvoices,
  manualUploadData,
} from '../../../redux/services/manualUploadService';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { openMessageModal } from '../../../redux/globalSlices/genericSlice';
import ReactPaginate from 'react-paginate';
import { convertKeysToCamelCase } from '../../../utils/helpers';
interface PaymentRecord {
  paymentId: string;
  invoiceId: string;
  amountPaid: string;
  openAmount: string;
  customerId: string;
  jobId: string;
  recType: string;
  appliesTo: string;
  onlinePmt: string;
  invoiceIdSeqNo: string;
  docDate: Date | null;
  ref: string;
}

const baseStyle = {
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 4,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};
export const AppManualImportCSVModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [mode, setMode] = useState<'UPLOADING' | 'PROCESSING' | 'WAITING FILE' | 'VIEW_DATA'>(
    'WAITING FILE'
  );
  const [invoices, setInvoices] = useState<ManualUploadInvoice[]>([]);
  const [lineItemsList, setLineItemsList] = useState<ManualUploadLineItems[]>([]);
  const [jobList, setJobList] = useState<ManualUploadJob[]>([]);
  const [debitMemoList, setDebitMemoList] = useState<ManualUploadOpenItem[]>([]);
  const [financeChargeList, setFinanceChargeList] = useState<ManualUploadOpenItem[]>([]);
  const [payments, setPayments] = useState<ManualUploadPayment[]>([]);
  const [creditMemos, setCreditMemos] = useState<ManualUploadPayment[]>([]);
  const [openItemsList, setOpenItemsList] = useState<ManualUploadPayment[]>([]);
  const [customerList, setCustomerList] = useState<ManualUploadCustomer[]>([]);
  const [error, setError] = useState<string>('');
  const [triggerNavigationToInvoices, setTriggerNavigationToInvoices] = useState<any>(null);
  const [fileErrors, setFileErrors] = useState<{ errorMessage: string; fileName: string }[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<
    { fileName: string; fileType: string; file: File }[]
  >([]);
  const [missingFiles, setMissingFiles] = useState<string[]>([]);
  const [curentFileUploaded, setCurentFileUploaded] = useState<{ name: string; size: number }>();
  const [fileNumber, setFileNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedTab, setSelectedTab] = useState<
    'invoice' | 'job' | 'lineItems' | 'customer' | 'payments' | 'openItems'
  >('invoice');
  const [loading, setLoading] = useState(false);
  const [refreshScroll, setRefreshScroll] = useState(false);
  // Pagination
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 50; // Number of items per page
  const endOffset = itemOffset + itemsPerPage;
  const [currentPage, setCurrentPage] = useState(1);

  const [generalCheck, generalCheckDispatch] = useReducer(
    (
      state: {
        isSomeInvoicesAlreadySaved: boolean;
        isSomeInvoicesHaveDillPaymentSaved: boolean;
        isSomePaymentsAlreadySaved: boolean;
      },
      action:
        | { type: 'isSomeInvoicesAlreadySaved'; payload: boolean }
        | { type: 'isSomeInvoicesHaveDillPaymentSaved'; payload: boolean }
        | { type: 'isSomePaymentsAlreadySaved'; payload: boolean }
        | { type: 'reset' }
    ): any => {
      switch (action.type) {
        case 'isSomeInvoicesAlreadySaved':
          return { ...state, isSomeInvoicesAlreadySaved: action.payload };
        case 'isSomeInvoicesHaveDillPaymentSaved':
          return { ...state, isSomeInvoicesHaveDillPaymentSaved: action.payload };
        case 'isSomePaymentsAlreadySaved':
          return { ...state, isSomePaymentsAlreadySaved: action.payload };
        case 'reset':
          return {
            isSomeInvoicesAlreadySaved: false,
            isSomeInvoicesHaveDillPaymentSaved: false,
            isSomePaymentsAlreadySaved: false,
          };
        default:
      }
    },
    {
      isSomeInvoicesAlreadySaved: false,
      isSomeInvoicesHaveDillPaymentSaved: false,
      isSomePaymentsAlreadySaved: false,
    }
  );

  useEffect(() => {
    if (open) {
      setUploadedFiles([]);
      setOpenItemsList([]);
      setFinanceChargeList([]);
      setDebitMemoList([]);
      setCustomerList([]);
      setJobList([]);
      generalCheckDispatch({ type: 'reset' });
      setCurentFileUploaded({} as { name: string; size: number });
      setUploadProgress(0);
      setFileNumber(0);
      setCount(0);
    }

    return () => {};
  }, [open]);

  const columns = useMemo(() => {
    const currentColumns = {
      invoiceColumns: [
        { id: 'invoiceNumber', name: 'Invoice Number', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'invoiceDate', name: 'Invoice Date', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'poNumber', name: 'P O Number', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'amount', name: 'Amount', width: 120, cellType: 'VIEW_TEXT' },
        // { id: 'balance', name: 'Balance', width: 120, cellType: 'VIEW_TEXT' },
      ],
      lineItemsColumns: [
        { id: 'invoiceId', name: 'Invoice Id', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'name', name: 'Name', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'description', name: 'Description', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'unitAmount', name: 'Unit Amount', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'subTotal', name: 'Sub Total', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'totalAmount', name: 'Total Amount', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'taxAmount', name: 'Tax Amount', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'discountAmount', name: 'Discount Amount', width: 120, cellType: 'VIEW_TEXT' },
      ],
      paymentsColumns: [
        { id: 'paymentId', name: 'Payment Id', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'invoiceId', name: 'Invoice Id', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'jobId', name: 'Job Id', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'customerId', name: 'Customer Id', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'amountPaid', name: 'Amount Paid', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'appliesTo', name: 'Applies To', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'docDate', name: 'Doc Date', width: 120, cellType: 'VIEW_TEXT' },
      ],
      jobColumns: [
        { id: 'customerId', name: 'Customer Id', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'name', name: 'Name', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'address', name: 'Address', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'city', name: 'City', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'state', name: 'State', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'zip', name: 'Zip Code', width: 120, cellType: 'VIEW_TEXT' },
      ],
      customerColumns: [
        { id: 'name', name: 'Name', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'emails', name: 'Emails', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'address', name: 'Address', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'city', name: 'City', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'state', name: 'State', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'zip', name: 'Zip Code', width: 120, cellType: 'VIEW_TEXT' },
      ],
      openItemsColumns: [
        { id: 'paymentId', name: 'Payment Id', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'invoiceId', name: 'Invoice Id', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'amountPaid', name: 'Amount Paid', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'jobId', name: 'Job Id', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'customerId', name: 'Customer Id', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'docDate', name: 'Doc Date', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'appliesTo', name: 'Applies To', width: 120, cellType: 'VIEW_TEXT' },
        { id: 'recType', name: 'Record Type', width: 120, cellType: 'VIEW_TEXT' },
      ],
    };
    return currentColumns;
  }, [open]);

  const containsRequiredProperties = (data: string[], type: string) => {
    let fieldsToFind: string[] = [];
    const lowerCaseArray = data.map((str) => str?.toLowerCase());
    if (type === 'invoice') {
      fieldsToFind = ['customerId', 'invoiceNumber', 'invoiceDate', 'dueDate', 'amount', 'balance'];
    }
    if (type === 'customer') {
      fieldsToFind = ['name', 'emails'];
    }
    if (type === 'lineItems') {
      fieldsToFind = ['invoiceId', 'name', 'unitAmount', 'quantity', 'subTotal', 'totalAmount'];
    }
    if (type === 'job') {
      fieldsToFind = ['customerId', 'name'];
    }

    if (type === 'payments') {
      fieldsToFind = ['paymentId', 'invoiceId', 'amountPaid', 'openAmount', 'customerId', 'jobId'];
    }

    const missingFields = fieldsToFind.filter(
      (field) => !lowerCaseArray.includes(field?.toLowerCase())
    );

    if (missingFields.length === 0) {
      return true;
    }
    return false;
  };

  const checkErrors = () => {
    let requiredFileTypes: string[] = [];

    if (
      uploadedFiles.some((uploadedFile) => {
        return uploadedFile.fileType === 'INVOICE' || uploadedFile.fileType === 'LINE_ITEMS';
      })
    ) {
      requiredFileTypes = ['INVOICE', 'LINE_ITEMS'];
    }
    const errors = requiredFileTypes
      .filter((type) => !uploadedFiles.some((file) => file.fileType === type))
      .map((type) => type.replace('_', ' ')?.toLowerCase());
    setMissingFiles(errors);
    return errors;
  };

  useEffect(() => {
    setCount(count + 1);
  }, [curentFileUploaded]);

  const onDrop = async (acceptedFiles: File[], fileRejections: any[]) => {
    try {
      setFileErrors([]);
      setMissingFiles([]);
      setFileNumber(acceptedFiles.length);
      if (acceptedFiles) {
        setError('');
        setMode('UPLOADING');
        const processedFiles = [...uploadedFiles];
        const filesToAdd: { fileName: string; fileType: string; file: File }[] = [];

        const readFileAsBinaryString = (file: File): Promise<string> => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onprogress = (event: ProgressEvent<FileReader>) => {
              if (event.lengthComputable) {
                const percentCompleted = Math.round((event.loaded * 100) / event.total);
                setUploadProgress(percentCompleted);
              }
            };
            reader.onload = (event: ProgressEvent<FileReader>) => {
              if (event.target && event.target.result) {
                resolve(event.target.result.toString());
              } else {
                reject(new Error('File reading failed'));
              }
            };
            reader.onerror = () => {
              reject(new Error('File reading failed'));
            };
            reader.readAsBinaryString(file);
          });
        };

        for (const file of acceptedFiles) {
          try {
            setMode('UPLOADING');
            const result = await readFileAsBinaryString(file);
            setCurentFileUploaded({ name: file.name, size: file.size });
            if (file.name.endsWith('.csv')) {
              Papa.parse(result, {
                complete: async (parsedData) => {
                  const header = parsedData.meta.fields;
                  const checkIifInvoiceFile = containsRequiredProperties(
                    header as string[],
                    'invoice'
                  );
                  const checkIifCustomerFile = containsRequiredProperties(
                    header as string[],
                    'customer'
                  );
                  const checkIifLineItemsFile = containsRequiredProperties(
                    header as string[],
                    'lineItems'
                  );
                  const checkIifJobFile = containsRequiredProperties(header as string[], 'job');
                  const checkIifPaymentsFile = containsRequiredProperties(
                    header as string[],
                    'payments'
                  );

                  if (checkIifInvoiceFile)
                    filesToAdd.push({ fileName: file.name, fileType: 'INVOICE', file });
                  if (checkIifCustomerFile)
                    filesToAdd.push({ fileName: file.name, fileType: 'CUSTOMER', file });
                  if (checkIifLineItemsFile)
                    filesToAdd.push({ fileName: file.name, fileType: 'LINE_ITEMS', file });
                  if (checkIifJobFile)
                    filesToAdd.push({ fileName: file.name, fileType: 'JOB', file });
                  if (checkIifPaymentsFile)
                    filesToAdd.push({ fileName: file.name, fileType: 'PAYMENTS', file });

                  filesToAdd.forEach((newFile) => {
                    const index = processedFiles.findIndex(
                      (existingFile) => newFile.fileType === existingFile.fileType
                    );
                    if (index !== -1) {
                      processedFiles[index] = newFile;
                    } else {
                      processedFiles.push(newFile);
                    }
                  });
                  setUploadedFiles(processedFiles);
                },
                header: true,
              });
            } else if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
              const workbook = XLSX.read(result, { type: 'binary', cellStyles: true });
              const sheetName: string = workbook.SheetNames[0]; // Assuming the data is in the first sheet
              const worksheet: XLSX.WorkSheet = workbook.Sheets[sheetName];
              const options = { header: 1 };
              const sheetData2 = XLSX.utils.sheet_to_json(worksheet, options);
              const header = sheetData2.shift();
              const checkIifInvoiceFile = containsRequiredProperties(header as string[], 'invoice');
              const checkIifCustomerFile = containsRequiredProperties(
                header as string[],
                'customer'
              );
              const checkIifLineItemsFile = containsRequiredProperties(
                header as string[],
                'lineItems'
              );
              const checkIifJobFile = containsRequiredProperties(header as string[], 'job');
              const checkIifPaymentsFile = containsRequiredProperties(
                header as string[],
                'payments'
              );

              if (checkIifInvoiceFile)
                filesToAdd.push({ fileName: file.name, fileType: 'INVOICE', file });
              if (checkIifCustomerFile)
                filesToAdd.push({ fileName: file.name, fileType: 'CUSTOMER', file });
              if (checkIifLineItemsFile)
                filesToAdd.push({ fileName: file.name, fileType: 'LINE_ITEMS', file });
              if (checkIifJobFile) filesToAdd.push({ fileName: file.name, fileType: 'JOB', file });
              if (checkIifPaymentsFile)
                filesToAdd.push({ fileName: file.name, fileType: 'PAYMENTS', file });

              filesToAdd.forEach((newFile) => {
                const index = processedFiles.findIndex(
                  (existingFile) =>
                    newFile.fileName === existingFile.fileName &&
                    newFile.fileType === existingFile.fileType
                );
                if (index !== -1) {
                  processedFiles[index] = newFile;
                } else {
                  processedFiles.push(newFile);
                }
              });
              setUploadedFiles(processedFiles);
            }
          } catch (err) {
            console.error(`Error processing file ${file.name}:`, err);
          }
        }
        setMode('WAITING FILE');
      }

      if (fileRejections && fileRejections.length > 0) {
        const fileReject = fileRejections.map((file) => ({
          errorMessage:
            file.errors[0]?.code === 'file-invalid-type'
              ? 'File should be either .xls, .xlsx, or .csv'
              : file.errors[0]?.message || 'File is rejected',
          fileName: file.file ? file.file.name : '',
        }));
        setFileErrors(fileReject);
      }

      setMode('WAITING FILE');
    } catch (err) {
      console.log(err);
      setMode('WAITING FILE');
    }
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    open: openFilePicker,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/csv': ['.csv'],
    },
    noClick: true,
    noKeyboard: true,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleCloseModal = () => {
    setMode('WAITING FILE');
    setError('');
    setFileErrors([]);
    setUploadedFiles([]);
    setMissingFiles([]);
    setCustomerList([]);
    setJobList([]);
    setLineItemsList([]);
    setInvoices([]);
    setPayments([]);
    setOpenItemsList([]);
    setRefreshScroll(false);
    setLoading(false);
    handleClose();
  };

  const isDataCorrupt = (data: any): boolean => {
    const keys = Object.keys(data);
    for (const key of keys) {
      if (
        [
          'subTotal',
          'totalAmount',
          'unitAmount',
          'taxAmount',
          'discountAmount',
          'amount',
          'balance',
          'amountPaid',
          'openAmount',
        ].includes(key)
      ) {
        const value = data[key];
        if (value !== undefined && isNaN(Number(value))) {
          return true; // Data is corrupt if value is not a valid number
        }
      }
    }
    return false; // Data is not corrupt
  };

  const handleProcessData = async () => {
    if (uploadedFiles.length === 0) {
      setFileErrors([{ errorMessage: 'No files selected', fileName: '' }]);
      return;
    }
    setLoading(true);
    setMode('PROCESSING');
    const processedUploadedData = await processAllUploadedData(uploadedFiles);
    if (processedUploadedData.data) {
      setInvoices(processedUploadedData.data.invoices);
      setLineItemsList(processedUploadedData.data.lineItems);
      setCustomerList(processedUploadedData.data.customers);
      setJobList(processedUploadedData.data.jobs);
      setPayments(processedUploadedData.data.payments);
      setCreditMemos(processedUploadedData.data.creditMemos);
      setDebitMemoList(processedUploadedData.data.debitMemos);
      setFinanceChargeList(processedUploadedData.data.financeCharges);
      setOpenItemsList(processedUploadedData.data.openItems);
      setMode('VIEW_DATA');
    } else {
      dispatch(
        openMessageModal({
          modalType: 'ERROR',
          title: 'Error while processing the data',
          subTitle: '',
          buttonText: 'Close',
        })
      );
      setMode('WAITING FILE');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (triggerNavigationToInvoices) {
      navigate('/invoices');
      setTriggerNavigationToInvoices(null);
    }

    return () => {};
  }, [triggerNavigationToInvoices]);

  const handleImportData = async () => {
    try {
      setLoading(true);
      const camelCaseKeysPayment: PaymentRecord[] = convertKeysToCamelCase(
        payments.filter((data) => !isDataCorrupt(data))
      );
      const groupedPayment = groupPaymentsInvoices(camelCaseKeysPayment);
      const camelCaseKeysCreditMemos: PaymentRecord[] = convertKeysToCamelCase(
        creditMemos.filter((data) => !isDataCorrupt(data))
      );
      const groupedCreditMemos = groupPaymentsInvoices(camelCaseKeysCreditMemos);
      const manualUploadedData = await dispatch(
        manualUploadData({
          invoices: invoices.filter((data) => !isDataCorrupt(data)),
          lineItems: lineItemsList.filter((data) => !isDataCorrupt(data)),
          customers: customerList,
          jobs: jobList,
          payments: groupedPayment,
          creditMemos: groupedCreditMemos,
          debitMemos: debitMemoList.filter((data) => !isDataCorrupt(data)),
          financeCharges: financeChargeList.filter((data) => !isDataCorrupt(data)),
        })
      );
      if (manualUploadedData.type === 'manualUpload/manualUploadData/fulfilled') {
        await dispatch(getManualUploadInvoices({ itemsPerPage: 50 }));
        handleCloseModal();
        setLoading(false);
        if (!location.pathname.startsWith('/invoices')) {
          setTriggerNavigationToInvoices({});
        }
      } else if (manualUploadedData.type === 'manualUpload/manualUploadData/rejected') {
        setLoading(false);
        const pp = manualUploadedData.payload as any;
        dispatch(
          openMessageModal({
            modalType: 'ERROR',
            title: 'Error while uploading data',
            subTitle: pp || '',
            buttonText: 'Close',
          })
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      dispatch(
        openMessageModal({
          modalType: 'ERROR',
          title: 'Error while uploading data',
          subTitle: err as string,
          buttonText: 'Close',
        })
      );
    }
  };

  const handleConfirmImport = async () => {
    if (Object.values(generalCheck).some((item) => item === true)) {
      dispatch(
        openMessageModal({
          modalType: 'WARN',
          title: 'Some data is already in our system. ',
          subTitle: `${
            generalCheck.isSomePaymentsAlreadySaved
              ? 'Payments that were already saved will not be applied, '
              : ''
          } ${
            generalCheck.isSomeInvoicesHaveDillPaymentSaved
              ? 'Invoices that were already paid using Dill will not be updated, '
              : ''
          } Confirm that you would like to proceed`,
          buttonText: 'Close',
          isSecondButton: true,
          secondButtonText: 'Proceed',
          onSecondButtonClick: async () => {
            handleImportData();
          },
        })
      );
    } else {
      handleImportData();
    }
  };

  const getColumns = (tabId: string) => {
    if (tabId === 'invoice') {
      return columns.invoiceColumns;
    }
    if (tabId === 'lineItems') {
      return columns.lineItemsColumns;
    }
    if (tabId === 'job') {
      return columns.jobColumns;
    }
    if (tabId === 'customer') {
      return columns.customerColumns;
    }
    if (tabId === 'payments') {
      return columns.paymentsColumns;
    }
    if (tabId === 'openItems') {
      return columns.openItemsColumns;
    }
    return [];
  };

  const getColumnTotalWidth = (tabId: string) => {
    return getColumns(tabId).reduce((curr, prev) => {
      return curr + prev.width;
    }, 0);
  };

  const selectedTabTableColumns = useMemo(() => {
    if (selectedTab) {
      return getColumns(selectedTab);
    }

    return [];
  }, [selectedTab]);

  const getTabData = (tabId: string) => {
    if (tabId === 'invoice') {
      return invoices;
    }
    if (tabId === 'lineItems') {
      return lineItemsList;
    }
    if (tabId === 'job') {
      return jobList;
    }
    if (tabId === 'customer') {
      return customerList;
    }
    if (tabId === 'payments') {
      return payments;
    }
    if (tabId === 'openItems') {
      return openItemsList;
    }
    return [];
  };

  const getCurrentTabData = (tabId: string) => {
    if (tabId === 'invoice') {
      return invoices.slice(itemOffset, endOffset);
    }
    if (tabId === 'lineItems') {
      return lineItemsList.slice(itemOffset, endOffset);
    }
    if (tabId === 'job') {
      return jobList.slice(itemOffset, endOffset);
    }
    if (tabId === 'customer') {
      return customerList.slice(itemOffset, endOffset);
    }
    if (tabId === 'payments') {
      return payments.slice(itemOffset, endOffset);
    }
    if (tabId === 'openItems') {
      return openItemsList.slice(itemOffset, endOffset);
    }
    return [];
  };

  const tabItems = useMemo(() => {
    const tabs: { id: string; name: string; icon: any }[] = [];
    if (getTabData('invoice').length > 0) {
      tabs.push({
        id: 'invoice',
        name: 'Invoice',
        icon: <Notebook size={24} />,
      });
    }
    if (getTabData('lineItems').length > 0) {
      tabs.push({
        id: 'lineItems',
        name: 'Line Items',
        icon: <Notepad size={24} />,
      });
    }
    if (getTabData('customer').length > 0) {
      tabs.push({
        id: 'customer',
        name: 'Customer',
        icon: <Notepad size={24} />,
      });
    }
    if (getTabData('job').length > 0) {
      tabs.push({
        id: 'job',
        name: 'Jobs',
        icon: <Briefcase size={24} />,
      });
    }
    if (getTabData('payments').length > 0) {
      tabs.push({
        id: 'payments',
        name: 'Payments',
        icon: <Notebook size={24} />,
      });
    }
    if (getTabData('openItems').length > 0) {
      tabs.push({
        id: 'openItems',
        name: 'Other Open Items',
        icon: <BookOpen size={24} />,
      });
    }
    if (tabs.length > 0) {
      setSelectedTab(tabs[0].id as any);
    }
    return tabs;
  }, [open, payments, customerList, jobList, lineItemsList, invoices, openItemsList]);

  const selectedTabTableData = useMemo(() => {
    if (selectedTab) {
      return getTabData(selectedTab);
    }

    return [];
  }, [selectedTab, payments, customerList, jobList, lineItemsList, invoices, openItemsList]);

  // Pagination function
  const pageCount = Math.ceil(selectedTabTableData.length / itemsPerPage);

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % selectedTabTableData.length;
    setItemOffset(newOffset);
  };

  const cummulativeOffset = useMemo(() => {
    let result = 0;
    const currentTotalItems = endOffset / itemsPerPage;
    if (pageCount === currentTotalItems) {
      result = selectedTabTableData.length;
    } else if (selectedTabTableData.length === 0) {
      result = 0;
    } else {
      const itemsTotal = currentTotalItems * itemsPerPage;
      result = itemsTotal;
    }
    return result;
  }, [endOffset, selectedTabTableData]);
  const currentItems = selectedTabTableData.slice(itemOffset, endOffset);

  const curentUploadFileProgres = useMemo(() => {
    if (curentFileUploaded?.size) {
      if (curentFileUploaded?.size > 1000000) {
        const uploadedSize = (curentFileUploaded?.size * uploadProgress) / 100000000;
        const currentFileSize = curentFileUploaded?.size / 1000000;
        return `${uploadedSize.toFixed(2)}MB from ${currentFileSize.toFixed(2)} MB`;
      } else {
        const uploadedSize = (curentFileUploaded?.size * uploadProgress) / 100000;
        const currentFileSize = curentFileUploaded?.size / 1000;
        return `${uploadedSize.toFixed(2)}KB from ${currentFileSize.toFixed(2)}KB`;
      }
    }
    return '';
  }, [uploadProgress, curentFileUploaded]);

  const renderRow = (item: { id: string; content: React.ReactNode }) => {
    const index = Number(item.id);
    const data = getCurrentTabData(selectedTab)[index];
    return (
      <div
        key={index + 'oo' + `${item.id}`}
        className={`w-full flex h-full px-4 justify-between items-center ${
          index % 2 === 0 ? 'GREY_50-BG' : ''
        }`}>
        {getColumns(selectedTab).map((column, columnIndex) => {
          type ObjectKey = keyof typeof data;
          const columnKey = column.id as ObjectKey;
          let value: any = data[columnKey];
          if (
            column.id === 'subTotal' ||
            column.id === 'totalAmount' ||
            column.id === 'unitAmount' ||
            column.id === 'taxAmount' ||
            column.id === 'discountAmount' ||
            column.id === 'amount'
            // column.id === 'balance'
          ) {
            const amount = data[columnKey];
            if (amount) {
              if (isNaN(Number(amount))) {
                value = `${Number(amount)}`;
              } else {
                if (typeof amount === 'string' || typeof amount === 'number') {
                  value = formatMoney(amount ?? '', 2);
                }
              }
            } else {
              value = '';
            }
          }
          if (column.id === 'invoiceDate') {
            value = moment(data[columnKey]).format('MM/DD/YYYY');
          }
          if (column.id === 'docDate') {
            value = moment(data[columnKey]).format('MM/DD/YYYY');
          }
          const corruptData = isDataCorrupt(data);

          return (
            <div
              key={column.id}
              style={{ flex: column.width / getColumnTotalWidth(selectedTab) }}
              className={twMerge(`py-1 px-1 h-full overflow-y-hidden flex items-center`)}>
              <div className="whitespace-break-spaces w-fit">
                {column.cellType === 'VIEW_TEXT' && columnIndex === 0 && (
                  <div className="flex">
                    <ReactTooltip
                      id={`manualUpload-${index}`}
                      place="top"
                      variant="dark"
                      opacity={'100%'}
                      style={{
                        display: 'flex',
                        background: '#334155',
                        width: '300px',
                        zIndex: '70',
                        borderRadius: '8px',
                        alignItems: 'center',
                        justifyItems: 'center',
                        fontWeight: '600',
                      }}></ReactTooltip>
                    <ReactTooltip
                      id={`balanceiszero-${index}`}
                      place="top"
                      variant="dark"
                      opacity={'100%'}
                      style={{
                        display: 'flex',
                        background: '#334155',
                        width: '300px',
                        zIndex: '70',
                        borderRadius: '8px',
                        alignItems: 'center',
                        justifyItems: 'center',
                        fontWeight: '600',
                      }}>
                      <span>{`The payment posted is greater than balance so we will update the balance to 0`}</span>
                    </ReactTooltip>
                    <ReactTooltip
                      id={`data-corupt-${index}`}
                      place="top"
                      variant="dark"
                      opacity={'100%'}
                      style={{
                        display: 'flex',
                        background: '#334155',
                        width: '300px',
                        zIndex: '70',
                        borderRadius: '8px',
                        alignItems: 'center',
                        justifyItems: 'center',
                        fontWeight: '600',
                      }}>
                      <span>{`This row contains data that is not the correct forrmat therefore it will not be uploaded`}</span>
                    </ReactTooltip>
                    {corruptData && (
                      <Warning
                        size={20}
                        weight="bold"
                        className="ERROR_100-BG ERROR_500-CLR mr-1"
                        data-tooltip-id={`data-corupt-${index}`}
                      />
                    )}
                    <div className="text-xs word-wrap">{value}</div>
                  </div>
                )}
                {column.cellType === 'VIEW_TEXT' && columnIndex !== 0 && (
                  <div className="text-xs word-wrap">{value}</div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div>
        <div
          className={`flex min-h-80 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col ${
            mode === 'VIEW_DATA' ? ' w-[900px]' : ' w-[540px]'
          }`}>
          <h2 className="text-lg font-semibold mx-4 my-3">Import CSV</h2>
          <X
            className="absolute right-4 top-4 cursor-pointer"
            color={COLORS.GREY_500}
            onClick={handleCloseModal}
          />
          <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>

          {mode === 'WAITING FILE' && (
            <div className="mx-4 mt-3 mb-2">
              <div {...getRootProps({ style })} className="flex flex-col">
                <input {...getInputProps()} />
                <p>Choose files or drag 'n' drop some files here</p>
                <div className="text-sm mt-2 TEXT_SECONDARY-CLR">Accepted file .csv .xslx .xls</div>

                <AppButton
                  text="Browse Files"
                  type="TERTIARY"
                  onClick={openFilePicker}
                  buttonStyles={{ marginTop: '8px' }}
                />
              </div>
              {uploadedFiles.length > 0 && (
                <div className="border GREY_200-BORDER rounded p-2 mt-2">
                  {uploadedFiles.map((uploadedFile, i) => (
                    <div key={`uploaded_files_${i}`} className="flex items-center my-1">
                      <CheckCircle color={COLORS.SUCCESS_500} size={20} weight="fill" />
                      <div className="capitalize w-[140px] text-sm ml-3">
                        {`${uploadedFile?.fileType?.replace('_', ' ')?.toLowerCase()} file:`}
                      </div>
                      <div className="text-sm">{uploadedFile.fileName}</div>
                    </div>
                  ))}
                </div>
              )}

              {missingFiles.length > 0 && (
                <div>
                  <div className="border ERROR_500-BORDER rounded p-2 mt-2">
                    {missingFiles.map((missingFile, i) => (
                      <div key={`missing_files_${i}`} className="flex items-center my-1">
                        <XCircle color={COLORS.ERROR_500} size={20} weight="fill" />
                        <div className="capitalize text-sm ml-2">
                          {`${missingFile?.toLowerCase()} file missing`}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="text-sm ERROR_500-CLR mt-2">{`Missing file${
                    missingFiles.length > 1 ? 's' : ''
                  } above`}</div>
                </div>
              )}
              {fileErrors.map((fileError, i) => (
                <div key={`file_error_${i}`} className="flex mt-2">
                  <div className="text-sm">{`${
                    fileError.fileName ? `${fileError.fileName}:` : ''
                  } `}</div>
                  <div className="text-sm ERROR_500-CLR">{fileError.errorMessage}</div>
                </div>
              ))}
              {error && <div className="my-2 text-xs ERROR_500-CLR text-center">{error}</div>}
            </div>
          )}
          {mode === 'UPLOADING' && (
            <div className="flex m-4 p-4 outline-dashed rounded outline-2 outline-offset-0 outline-gray-400 items-center flex-col">
              <div
                className="flex PRIMARY_200-BG rounded-full items-center justify-center mb-3"
                style={{
                  width: 40,
                  height: 40,
                  animation: 'spin 2s linear infinite',
                }}>
                <Loader size={24} color={COLORS.PRIMARY_500} />
              </div>
              <div>Please wait your file is uploading. . .</div>
              <div className="w-full border GREY_200-BORDER p-2 mt-2">
                <div className="flex">
                  <FileCsv size={44} />
                  <div className="ml-2">
                    <div className="text-sm">{curentFileUploaded?.name}</div>
                    <div className="text-sm">{curentUploadFileProgres}</div>
                  </div>
                </div>
                <div className="mt-2 rounded-full GREY_300-BG w-full h-[8px]">
                  <div
                    className={`mt-2 rounded-full PRIMARY_500-BG w-${count}/${fileNumber} h-[8px]`}
                  />
                </div>
              </div>
            </div>
          )}
          {mode === 'PROCESSING' && (
            <div className="flex m-4 p-4 outline-dashed rounded outline-2 outline-offset-0 outline-gray-400 items-center flex-col">
              <div
                className="flex PRIMARY_200-BG rounded-full items-center justify-center mb-3"
                style={{
                  width: 100,
                  height: 100,
                  animation: 'spin 2s linear infinite',
                }}>
                <Loader size={64} color={COLORS.PRIMARY_500} />
              </div>
              <div className="mt-4">Processing data. . .</div>
            </div>
          )}
          {mode === 'VIEW_DATA' && (
            <div className="PRIMARY_50-BG my-2 mx-4 ">
              <div className="GREY_300-BORDER border-2 w-fit rounded-xl overflow-hidden">
                <div className="flex">
                  {tabItems.map((tabItem, index) => {
                    const idKey = tabItem.id as
                      | 'invoice'
                      | 'job'
                      | 'lineItems'
                      | 'customer'
                      | 'payments'
                      | 'openItems';
                    return (
                      <div
                        key={index + 'oo'}
                        onClick={() => {
                          setSelectedTab(idKey);
                          setItemOffset(0);
                          setCurrentPage(0);
                          setRefreshScroll((prev) => !prev);
                        }}
                        className={twMerge(
                          `flex cursor-pointer px-4 py-2 items-center`,
                          selectedTab === idKey ? 'PRIMARY_500-CLR WHITE-BG' : 'GREY_500-CLR ',
                          index < tabItems.length - 1 ? 'border-r-2' : ''
                        )}>
                        {tabItem.icon}
                        <div className="ml-2 font-semibold">{tabItem.name}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="mt-4 GREY_300-BORDER border rounded-xl overflow-scroll">
                {selectedTabTableColumns && (
                  <div className="flex border-b px-5 items-center w-full sticky top-0 PRIMARY_50-BG py-3 z-20">
                    {selectedTabTableColumns.map((column) => {
                      return (
                        <div
                          key={column.id}
                          style={{ flex: column.width / getColumnTotalWidth(selectedTab) }}
                          className="flex w-full">
                          <p className="TEXT_SECONDARY-CLR text-xs w-full text-left">
                            {column.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}

                {selectedTab && (
                  <div className=" WHITE-BG">
                    <AppVirtualizedList
                      itemsLength={currentItems.length}
                      itemHeight={45}
                      containerHeight={30}
                      renderRow={renderRow}
                      className="pb-[40px]"
                      refreshScroll={refreshScroll}
                    />
                  </div>
                )}
              </div>
              {/* Pagination component */}
              <div className="flex flex-row justify-between items-center w-full mt-3 mb-2 ml-1">
                <div className="text-xs GREY_500-CLR">{`Showing ${
                  selectedTabTableData.length !== 0 ? `${itemOffset + 1} to` : ''
                } ${cummulativeOffset} from ${selectedTabTableData.length} ${selectedTab}${
                  selectedTabTableData.length > 1 &&
                  selectedTab !== 'lineItems' &&
                  selectedTab !== 'payments' &&
                  selectedTab !== 'openItems'
                    ? 's'
                    : ''
                }`}</div>
                <ReactPaginate
                  breakLabel="..."
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  forcePage={Math.min(Math.max(0, currentPage - 1), pageCount - 1)}
                  previousLabel={<CaretLeft size={20} />}
                  nextLabel={<CaretRight size={20} />}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            </div>
          )}
          <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
          <div className="flex justify-end mx-3 mb-2">
            <AppButton
              text={'Cancel'}
              type="TERTIARY"
              onClick={() => {
                handleCloseModal();
              }}
              buttonStyles={{ marginRight: '8px', height: '40px' }}
            />
            {mode !== 'UPLOADING' && (
              <AppButton
                text={mode === 'WAITING FILE' ? 'Process Files' : 'Import'}
                isLoading={loading}
                isDisabled={loading}
                onClick={() => {
                  const missingFileError = checkErrors();
                  if (missingFileError.length === 0 && mode === 'WAITING FILE') {
                    handleProcessData();
                  } else if (mode === 'VIEW_DATA') {
                    handleConfirmImport();
                  }
                }}
                buttonStyles={{ width: '100px', height: '40px' }}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
