import { createSlice } from '@reduxjs/toolkit';
import {
  createSupplierJobsExtraReducer,
  getSupplierJobsExtraReducer,
  updateSupplierJobExtraReducer,
  getSupplierJobExtraReducer,
} from '../reducers/supplierJobsReducers';
import { SupplierJobApiResponse, JobDetailsApiResponse } from '@dill/dill-shared';

export interface SupplierJobsInitialState {
  loadingList: string[];
  loadingErrorList: object[];
  selectedSupplierJob: SupplierJobApiResponse | null;
}

const initialState: SupplierJobsInitialState = {
  loadingList: [],
  loadingErrorList: [],
  selectedSupplierJob: null,
};

export const supplierJobsSlice = createSlice({
  name: 'supplierJobs',
  initialState,
  reducers: {
    resetSupplierJobsRootState: () => initialState,
  },
  extraReducers(builder) {
    createSupplierJobsExtraReducer(builder);
    getSupplierJobsExtraReducer(builder);
    updateSupplierJobExtraReducer(builder);
    getSupplierJobExtraReducer(builder);
  },
});

export const { resetSupplierJobsRootState } = supplierJobsSlice.actions;
