import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import {
  createSupplierNoticeTemplate,
  createNoticeTemplateByAdmin,
  getNoticeTemplates,
  getNoticeTemplatesByAdmin,
} from '../services/noticeTemplatesService';
import { NoticeTemplatesInitialState } from '../globalSlices/noticeTemplatesSlice';

export const createNoticeTemplateByAdminExtraReducer = (
  builder: ActionReducerMapBuilder<NoticeTemplatesInitialState>
) => {
  const reducerId = 'createNoticeTemplateByAdmin';
  builder.addCase(createNoticeTemplateByAdmin.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createNoticeTemplateByAdmin.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(createNoticeTemplateByAdmin.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const createSupplierNoticeTemplateExtraReducer = (
  builder: ActionReducerMapBuilder<NoticeTemplatesInitialState>
) => {
  const reducerId = 'createSupplierNoticeTemplate';
  builder.addCase(createSupplierNoticeTemplate.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createSupplierNoticeTemplate.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(createSupplierNoticeTemplate.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const getNoticeTemplatesExtraReducer = (
  builder: ActionReducerMapBuilder<NoticeTemplatesInitialState>
) => {
  const reducerId = 'getNoticeTemplates';
  builder.addCase(getNoticeTemplates.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(getNoticeTemplates.fulfilled, (state, action: PayloadAction<any | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload.success && action.payload.data) {
      state.noticeTemplates = action.payload.data;
    }
  });
  builder.addCase(getNoticeTemplates.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getNoticeTemplatesByAdminExtraReducer = (
  builder: ActionReducerMapBuilder<NoticeTemplatesInitialState>
) => {
  const reducerId = 'getNoticeTemplatesByAdmin';
  builder.addCase(getNoticeTemplatesByAdmin.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getNoticeTemplatesByAdmin.fulfilled,
    (state, action: PayloadAction<any | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload.success && action.payload.data) {
        state.noticeTemplatesByAdmin = action.payload.data;
      }
    }
  );
  builder.addCase(getNoticeTemplatesByAdmin.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
