import { useEffect, useMemo, useState } from 'react';
import { Modal } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import {
  getGroupedSuppliersInvoices,
  updateBuyerGroupedInvoice,
} from '../../../../redux/services/invoicesService';
import {
  AppFile,
  Buyer,
  DillInvoice,
  QuickbooksInvoice,
  QuickbooksInvoiceSchema,
  SupplierForBuyer,
  formatMoney,
  roundTo,
} from '@dill/dill-shared';
import { twMerge } from 'tailwind-merge';
import emptyListImg from '../../../../assets/images/emptyList.png';
import { List, AutoSizer } from 'react-virtualized';
import moment from 'moment';
import { AppButton } from '../../../general/AppButton/AppButton';
import { X, Note } from 'phosphor-react';
import { Download, Eye, Mail } from 'react-feather';
import { COLORS } from '../../../../utils/colors';
import { downloadFile, openDillToast, truncateText } from '../../../../utils/helpers';
import { sendInvoicePaymentReminderEmail } from '../../../../redux/services/paymentsService';
import { AppPdfPreviewModal } from '../../../general/AppPdfPreviewModal/AppPdfPreviewModal';
import AppViewInvoiceWithoutPdfModal from '../../../general/AppViewInvoiceWithoutPdfModal/AppViewInvoiceWithoutPdfModal';
import { getInvoicePdf } from '../../../../redux/services/buyersService';
import { logEvent } from 'firebase/analytics';
import { myAnalytics } from '../../../../firebase/config';
import AppNotesModal from '../../../general/AppNotesModal/AppNotesModal';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const CustomerInvoicesModal = ({
  open,
  selectedBuyer,
  handleClose = () => {},
}: {
  open: boolean;
  selectedBuyer?: Buyer | null;
  handleClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const [currentBuyerInvoices, setCurrentBuyerInvoices] = useState<QuickbooksInvoice[]>([]);

  const [previewPdfList, setPreviewPdfList] = useState([] as any);
  const [openPdfPreviewModal, setOpenPdfPreviewModal] = useState(false);
  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<QuickbooksInvoice | null>(null);
  const [isViewInvoiceWithoutPdfModalOpen, setIsViewInvoiceWithoutPdfModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const columns = useMemo(() => {
    return [
      { id: 'docNumber', name: 'Document #', width: 80, cellType: 'VIEW_TEXT' },
      { id: 'type', name: 'Type', width: 60, cellType: 'VIEW_TEXT' },
      { id: 'poNumber', name: 'PO #', width: 80, cellType: 'VIEW_TEXT' },
      { id: 'job', name: 'Job', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'dueDate', name: 'Due Date', width: 90, cellType: 'VIEW_TEXT' },
      { id: 'createdAt', name: 'Date', width: 90, cellType: 'VIEW_TEXT' },
      { id: 'terms', name: 'Terms', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'amount', name: 'Amount', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'balance', name: 'Open', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'notes', name: 'Notes', width: 50, cellType: 'VIEW_TEXT' },
      { id: 'actions', name: '', width: 220, cellType: 'VIEW_TEXT' },
    ];
  }, [open]);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  const fetchData = async (buyerId: string) => {
    const results = await dispatch(
      getGroupedSuppliersInvoices({ buyerId: buyerId, weeksLimit: 12 })
    );
    if (results && results.type === 'suppliers/getGroupedSuppliersInvoices/fulfilled') {
      const payload = results.payload as any;
      if (payload.data.buyerSuppliers) {
        const data = payload.data.buyerSuppliers as SupplierForBuyer[];
        let invoices: QuickbooksInvoice[] = [];
        data.forEach((item) => {
          invoices = [...invoices, ...(item.invoices?.pastDueInvoices.invoices || [])];
          invoices = [...invoices, ...(item.invoices?.currentInvoices.invoices || [])];
          // invoices = [...invoices, ...(item.invoices?.paidInvoices.invoices || [])];
        });
        setCurrentBuyerInvoices(invoices);
      } else {
        setCurrentBuyerInvoices([]);
      }
    } else {
      setCurrentBuyerInvoices([]);
    }
  };
  useEffect(() => {
    if (open && selectedBuyer) {
      setCurrentBuyerInvoices([]);
      fetchData(selectedBuyer?.id);
    }
    setLoading(false);

    return () => {};
  }, [open, selectedBuyer]);

  const handleSendInvoicePaymentReminder = async (invoiceId: string) => {
    if (selectedBuyer) {
      const results = await dispatch(
        sendInvoicePaymentReminderEmail({ buyerId: selectedBuyer?.id, invoiceId })
      );
      console.log(results);
      if (results.type === 'payments/sendInvoicePaymentReminderEmail/fulfilled') {
        openDillToast({ type: 'SUCCESS', message: 'Payment reminder sent!' });
        fetchData(selectedBuyer?.id);
      } else {
        console.log(results);
        const payload = results.payload as any;
        let errorMessage = 'Error sending payment reminder!';
        if (payload) {
          errorMessage = payload;
        }
        openDillToast({ type: 'ERROR', message: errorMessage });
      }
    }
  };
  const handleDownloadSelectedPdf = (url: string) => {
    downloadFile(url, `invoice-${selectedInvoice?.docNumber}-${+new Date()}.pdf`);
  };

  const handleDownloadPdf = async (
    invoiceId: string,
    invoiceNumber: string,
    signedPdfs?: AppFile[] | undefined | null
  ) => {
    if (signedPdfs && signedPdfs.length > 0) {
      const sortedPdfs = [...signedPdfs].sort((a, b) => {
        console.log(a, b);
        if (!b.date || !a.date) {
          return 0;
        }
        return new Date(b.date.toString()).getTime() > new Date(a.date.toString()).getTime()
          ? 1
          : -1;
      });
      if (sortedPdfs.length > 1) {
        // setDownloadPdfList(sortedPdfs);
        // setOpenPdfListModal(true);
      } else {
        downloadFile(sortedPdfs[0].url || '', `invoice-${invoiceNumber}-${+new Date()}.pdf`);
        logEvent(myAnalytics, 'buyer_download_invoice', {
          userId: user ? user.id : '',
          time: new Date(),
          route: location.pathname,
        });
      }
    } else {
      const res = await dispatch(
        getInvoicePdf({
          supplierDomainId: user?.userSupplier?.domainId,
          invoiceId,
        })
      );
      if (res && res.type === 'buyers/getInvoicePdf/fulfilled') {
        openDillToast({ message: 'invoice pdf successfully downloaded', type: 'SUCCESS' });
      } else {
        openDillToast({ message: 'Failed to download invoice pdf', type: 'ERROR' });
      }
      logEvent(myAnalytics, 'buyer_download_invoice', {
        userId: user ? user.id : '',
        time: new Date(),
        route: location.pathname,
      });
    }
  };

  const handlePreviewPdf = async (
    invoiceId: string,
    invoiceNumber: string,
    signedPdfs?: AppFile[] | undefined | null
  ) => {
    if (signedPdfs && signedPdfs.length > 0) {
      const sortedPdfs = [...signedPdfs].sort((a, b) => {
        console.log(a, b);
        if (!b.date || !a.date) {
          return 0;
        }
        return new Date(b.date.toString()).getTime() > new Date(a.date.toString()).getTime()
          ? 1
          : -1;
      });
      setPreviewPdfList(sortedPdfs);
      setOpenPdfPreviewModal(true);
      logEvent(myAnalytics, 'buyer_preview_invoice', {
        userId: user ? user.id : '',
        time: new Date(),
        route: location.pathname,
      });
    } else {
      console.log('00000');
      const invoice = await dispatch(
        getInvoicePdf({
          supplierDomainId: user?.userSupplier?.domainId,
          invoiceId,
          preview: true,
        })
      );
      if (invoice.payload) {
        const payload = invoice.payload as any;
        if (payload?.data?.pdfUrl) {
          setPreviewPdfList([{ url: payload.data.pdfUrl, name: 'pdf', date: new Date() }]);
          setOpenPdfPreviewModal(true);
        }
      }
    }
  };

  const handlePreviewInvoiceWithoutPdf = (invoice: QuickbooksInvoice) => {
    setSelectedInvoice(invoice);
    setIsViewInvoiceWithoutPdfModalOpen(true);
  };

  const handleUpdateGroupedInvoice = async (val: string) => {
    setLoading(true);
    const resp = await dispatch(
      updateBuyerGroupedInvoice({
        invoiceId: selectedInvoice?.id ?? '',
        data: { notes: [{ content: val, date: new Date() }] },
      })
    );
    setLoading(false);
    if (resp.type === 'suppliers/updateBuyerGroupedInvoice/fulfilled') {
      setOpenNotesModal(false);
      openDillToast({ message: 'Successfully updated invoice', type: 'SUCCESS' });
      fetchData(selectedBuyer?.id ?? '');
    } else {
      openDillToast({ message: 'Failed to update invoice', type: 'ERROR' });
    }
  };

  const renderRow = ({ index, key, style }: any) => {
    const documentDetails: any = currentBuyerInvoices[index];
    const invoiceCheck = QuickbooksInvoiceSchema.safeParse(documentDetails);
    let invoice: QuickbooksInvoice | null = null;
    let id: string;
    let jobName: string;
    let jobAddress: string;
    let dillInvoice: DillInvoice | null = null;
    if (invoiceCheck.success) {
      invoice = invoiceCheck.data;
      id = invoice.id;
      if (invoice.jobId) {
        jobName = invoice.quickBooksJob?.jobName || '';
        jobAddress = invoice.quickBooksJob?.address || invoice.shipAddress?.address1 || '';
      }
      if (invoice.dillInvoice) {
        dillInvoice = invoice.dillInvoice;
      }
    }

    return (
      <div key={key + 'oo'} style={style} className={'flex  px-2 my-2'}>
        {columns.map((column) => {
          let value: string | undefined;

          if (invoice) {
            type ObjectKey = keyof typeof invoice;
            const columnKey = column.id as ObjectKey;
            value = invoice[columnKey]?.toString();
          }

          if (column.id === 'createdAt' && invoice) {
            value = invoice.createdAt ? moment(invoice.createdAt).utc().format('MM/DD/YYYY') : '';
          }

          if (column.id === 'dueDate') {
            value = value ? moment(value).utc().format('MM/DD/YYYY') : '';
          }
          if (column.id === 'balance' || column.id === 'amount') {
            value = `$${roundTo(parseFloat(value || '0'), 2)}`;
          }
          if (column.id === 'payment' && invoice) {
            value = formatMoney(invoice?.dillInvoicePaymentSummary?.amountPaid || '0', 2);
          }
          if (column.id === 'type') {
            value =
              invoice && documentDetails?.recordType === 'FINANCE_CHARGE'
                ? 'Finance Charge'
                : invoice && documentDetails?.recordType === 'DEBIT_MEMO'
                ? 'Debit Memo'
                : invoice && !documentDetails?.recordType
                ? 'Invoice'
                : '';
          }

          return (
            <div
              key={column.id}
              style={{ flex: column.width / totalWidth }}
              className="flex items-center w-full h-full  mx-0.5 ">
              {column.cellType === 'VIEW_TEXT' &&
                column.id !== 'select' &&
                column.id !== 'download' &&
                column.id !== 'signedBy' &&
                column.id !== 'job' &&
                column.id !== 'notes' &&
                column.id !== 'isPastDueDate' && (
                  <p
                    className={
                      'text-sm ' +
                      (column.id === 'dueDate' && invoice && invoice.status === 'PAST_DUE'
                        ? 'ERROR_500-CLR'
                        : 'TEXT_PRIMARY-CLR')
                    }>
                    {value}
                  </p>
                )}
              {column.cellType === 'VIEW_TEXT' &&
                column.id === 'job' &&
                documentDetails.quickBooksJob &&
                (jobName || jobAddress) && (
                  <div className={'text-sm w-full px-0.5'}>
                    <p className="font-bold text-xs text-center word-wrap">
                      {truncateText(`${jobName}`, 20)}
                    </p>
                    <p className="text-2xs  text-center word-wrap">
                      {truncateText(`${jobAddress}`, 20)}
                    </p>
                  </div>
                )}
              {column.cellType === 'VIEW_TEXT' &&
                column.id !== 'select' &&
                column.id !== 'job' &&
                column.id !== 'download' &&
                column.id !== 'isPastDueDate' &&
                column.id !== 'notes' &&
                column.id === 'signedBy' && (
                  <div className="items-center justify-center ">
                    {dillInvoice && dillInvoice.isReceived && (
                      <div className="items-center justify-center">
                        {dillInvoice.receivedBy && (
                          <p className={'text-xs font-bold text-center  ' + 'TEXT_PRIMARY-CLR'}>
                            {dillInvoice.receivedBy}
                          </p>
                        )}
                        {dillInvoice.receivedByTime && (
                          <p className={'text-xs text-center ' + 'TEXT_PRIMARY-CLR'}>
                            {moment(dillInvoice.receivedByTime).format('MM/DD  LT')}
                          </p>
                        )}
                      </div>
                    )}
                    {(!dillInvoice || (dillInvoice && !dillInvoice.isReceived)) && (
                      <p className={'text-sm  text-center ' + 'TEXT_PRIMARY-CLR'}>-</p>
                    )}
                  </div>
                )}
              {column.cellType === 'VIEW_TEXT' &&
                column.id !== 'select' &&
                column.id !== 'download' &&
                column.id !== 'notes' &&
                column.id === 'isPastDueDate' && (
                  <div
                    style={{ width: '100px' }}
                    className={
                      value === 'true'
                        ? 'rounded-lg text-sm SUCCESS_100-BG p-2 SUCCESS_600-CLR text-center'
                        : 'rounded-lg text-sm WARNING_100-BG p-2 WARNING_600-CLR text-center'
                    }>
                    {value === 'true' ? 'Past Due' : 'Current Due'}
                  </div>
                )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'notes' && invoice && (
                <div className="flex w-full items-center justify-center">
                  <div className="relative" data-tooltip-id={`invoice-notes-content-${invoice.id}`}>
                    <AppButton
                      type="TERTIARY"
                      buttonStyles={{
                        padding: '8px',
                        margin: '5px 0px ',
                        height: '30px',
                        width: '30px',
                      }}
                      icon={<Note color={COLORS.GREY_500} size={15} />}
                      onClick={() => {
                        setSelectedInvoice(invoice);
                        setOpenNotesModal(true);
                      }}
                    />
                    {invoice?.notes && invoice?.notes?.length > 0 && (
                      <div className="flex absolute -top-[5px] right-[-10px] PRIMARY_500-BG WHITE-CLR rounded-full text-2xs font-bold h-5 w-5 items-center border border-white justify-center">
                        {invoice?.notes?.length}
                      </div>
                    )}
                  </div>
                  {invoice?.notes && invoice?.notes?.length > 0 && (
                    <ReactTooltip
                      id={`invoice-notes-content-${invoice.id}`}
                      place="top"
                      variant="dark"
                      opacity={'100%'}
                      style={{
                        display: 'flex',
                        background: '#334155',
                        width: '200px',
                        zIndex: '70',
                        borderRadius: '8px',
                        alignItems: 'center',
                        justifyItems: 'center',
                        fontWeight: '600',
                      }}>
                      <span>{`${invoice?.notes?.[0]?.content}`}</span>
                    </ReactTooltip>
                  )}
                </div>
              )}
              {column.cellType === 'VIEW_TEXT' && column.id === 'actions' && invoice && (
                <div className="flex w-full items-center ">
                  <>
                    <AppButton
                      type="TERTIARY"
                      buttonStyles={{
                        padding: '8px',
                        margin: '5px 4px',
                        height: '30px',
                        width: '30px',
                      }}
                      icon={<Eye color={COLORS.GREY_500} size={15} />}
                      onClick={() => {
                        if (invoice) {
                          if (
                            user?.userSupplier?.integrationType === 'QBO' ||
                            user?.userSupplier?.integrationType === 'RUTTER_NETSUITE' ||
                            user?.userSupplier?.integrationType === 'MANUAL_UPLOAD' ||
                            user?.userSupplier?.integrationType === 'INFOR_DIST_SX' ||
                            user?.userSupplier?.integrationType === 'DISTRIBUTION_ONE' ||
                            user?.userSupplier?.integrationType === 'SAGE_100'
                          ) {
                            handlePreviewPdf(
                              invoice.id,
                              invoice.docNumber,
                              invoice.dillInvoice?.signedPdfs
                            );
                          } else {
                            handlePreviewInvoiceWithoutPdf(invoice);
                          }
                        }
                      }}
                    />

                    {invoice &&
                    (user?.userSupplier?.integrationType === 'QBO' ||
                      user?.userSupplier?.integrationType === 'RUTTER_NETSUITE' ||
                      user?.userSupplier?.integrationType === 'RUTTER_QBD' ||
                      (user?.userSupplier?.integrationType === 'INFOR_DIST_SX' &&
                        !documentDetails?.recordType) ||
                      (user?.userSupplier?.integrationType === 'MANUAL_UPLOAD' &&
                        !documentDetails?.recordType)) ? (
                      // || selectedSupplier?.integrationType === 'RUTTER_QBD'
                      <AppButton
                        type="TERTIARY"
                        buttonStyles={{
                          padding: '8px',
                          margin: '5px 4px',
                          height: '30px',
                          width: '30px',
                        }}
                        isDisabled={!invoice ? true : false}
                        icon={<Download color={COLORS.GREY_500} size={15} />}
                        onClick={() => {
                          if (invoice) {
                            handleDownloadPdf(
                              invoice.id,
                              invoice.docNumber,
                              invoice.dillInvoice?.signedPdfs
                            );
                          }
                        }}
                      />
                    ) : (
                      <div className="h-2 w-11"></div>
                    )}
                  </>
                  {invoice && (
                    <div className="">
                      {invoice?.lastEmailed && (
                        <p className="text-2xs mb-1 ml-3">{`Last sent ${moment(invoice.lastEmailed)
                          .utc()
                          .format('MM/DD/YYYY')}`}</p>
                      )}
                      <AppButton
                        text="Email Payment Reminder"
                        buttonTextClassName="text-2xs"
                        onClick={() => {
                          if (invoice) {
                            handleSendInvoicePaymentReminder(invoice.id);
                          }
                        }}
                        icon={<Mail color={COLORS.WHITE} size={15} />}
                        buttonStyles={{
                          marginTop: '5px',
                          height: '36px',
                          margin: '5px 4px',
                          fontSize: '0.7em',
                        }}
                        buttonWidth="150px"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex h-[540px] w-10/12 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg flex-col px-3 py-3">
        <AppPdfPreviewModal
          open={openPdfPreviewModal}
          handleClose={() => {
            setOpenPdfPreviewModal(false);
          }}
          invoice={selectedInvoice?.docNumber || ''}
          pdfList={previewPdfList}
          handleDownloadPdf={handleDownloadSelectedPdf}
          isSigned={false}
        />
        <AppNotesModal
          open={openNotesModal}
          handleClose={() => {
            setOpenNotesModal(false);
          }}
          onSubmit={(value: string) => {
            handleUpdateGroupedInvoice(value);
          }}
          title={
            selectedInvoice?.notes && selectedInvoice?.notes?.length > 0
              ? 'Update Note'
              : 'Add Note'
          }
          primaryButtonText={
            selectedInvoice?.notes && selectedInvoice?.notes?.length > 0 ? 'Update' : 'Add'
          }
          value={selectedInvoice?.notes?.[0]?.content}
          loading={loading}
        />
        {isViewInvoiceWithoutPdfModalOpen && (
          <AppViewInvoiceWithoutPdfModal
            open={isViewInvoiceWithoutPdfModalOpen}
            handleClose={() => {
              setIsViewInvoiceWithoutPdfModalOpen(false);
            }}
            selectedInvoice={selectedInvoice}
            selectedSupplier={user?.userSupplier}
          />
        )}
        <div className="">
          <div className="text-lg font-bold mb-2">{`${
            selectedBuyer ? selectedBuyer.name : ''
          } Open Invoices`}</div>
          <X
            className="absolute right-4 top-4 cursor-pointer"
            color={COLORS.GREY_500}
            onClick={handleClose}
          />
        </div>

        <div className="flex flex-col h-full  w-full border rounded-lg">
          <div className="flex w-full h-full flex-col overflow-y-hidden ">
            <div className="flex border-b px-2 items-center w-full sticky top-0 WHITE-BG py-3 z-10">
              {columns.map((column) => {
                return (
                  <div
                    key={column.id}
                    style={{ flex: column.width / totalWidth }}
                    className={twMerge(
                      `flex items-center h-full w-full  mx-0.5`,
                      column.id === 'job' ? 'justify-center' : ''
                    )}>
                    <p
                      className={twMerge(
                        'TEXT_SECONDARY-CLR text-xs',
                        column.id === 'download' ? 'w-[180px]' : ''
                      )}>
                      {column.name}
                    </p>
                  </div>
                );
              })}
            </div>

            {currentBuyerInvoices.length < 1 && (
              <div className="w-full h-full flex items-center justify-center">
                <div className="flex flex-col items-center">
                  <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
                  <p>No invoices</p>
                </div>
              </div>
            )}

            <div className="" style={{ minHeight: 'calc(100% - 45px)' }}>
              {currentBuyerInvoices.length > 0 && (
                <div className="w-full h-full overflow-scroll">
                  <AutoSizer className="w-full ">
                    {({ height, width }) => {
                      return (
                        <List
                          className=""
                          width={width}
                          height={height}
                          rowRenderer={renderRow}
                          rowCount={currentBuyerInvoices.length}
                          rowHeight={70}
                        />
                      );
                    }}
                  </AutoSizer>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CustomerInvoicesModal;
