import React, { useEffect, useMemo, useState } from 'react';
import { AppButton } from '../../../../general/AppButton/AppButton';
import {
  Plus,
  Warning,
  WarningOctagon,
  Backspace,
  DownloadSimple,
  Notepad,
  PlusCircle,
  CheckCircle,
  Timer,
  ClockClockwise,
  Calendar,
  CaretLeft,
  CaretRight,
  ArrowsDownUp,
  SortDescending,
  SortAscending,
  DotsThreeVertical,
  ArrowElbowUpRight,
  ArrowElbowUpLeft,
  Prohibit,
  Trash,
  UploadSimple,
  Rows,
} from 'phosphor-react';
import { ChevronUp, ChevronDown, Eye, Edit3 } from 'react-feather';
import { COLORS } from '../../../../../utils/colors';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { getJob, getJobs, updateJob } from '../../../../../redux/services/jobsService';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import momenttz from 'moment-timezone';
import {
  Job,
  LienApiResponse,
  JobDetailsApiResponse,
  UserLinkApiResponse,
  GroupedCSVRelease,
} from '@dill/dill-shared';
import {
  getJobReleases,
  deleteRelease,
  createGroupedRelease,
  updateReleaseByMainBuyer,
  getMainBuyerReleaseHistory,
} from '../../../../../redux/services/liensService';
import { getSubSuppliers } from '../../../../../redux/services/subSuppliersService';
import { getLienTemplates } from '../../../../../redux/services/leinTemplatesService';
import { twMerge } from 'tailwind-merge';
import { AppNewJobModal } from '../../../../general/AppNewJobModal/AppNewJobModal';
import AppReleaseStatusPill from '../../../../general/AppReleaseStatusPill/AppReleaseStatusPill';
import AppCustomDropdown from '../../../../general/AppCustomDropdown/AppCustomDropdown';
import { AppSuccessModal } from '../../../../general/AppSuccessModal/AppSuccessModal';
import { toast } from 'react-toastify';
import { AppPdfPreviewModal } from '../../../../general/AppPdfPreviewModal/AppPdfPreviewModal';
import { downloadFile, disablePDFFields } from '../../../../../utils/helpers';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import AppUploadFileModal from '../../../../general/AppUploadFileModal/AppUploadFileModal';
import errorCircle from '../../../../../assets/svg/error_circle.svg';
import { AppDeleteModal } from '../../../../general/AppDeleteModal/AppDeleteModal';
import AppNotesModal from '../../../../general/AppNotesModal/AppNotesModal';
import AppAddSupplierModal from '../../../../general/AppAddSupplierModal/AppAddSupplierModal';
import { useWindowActualWidth } from '../../../../../utils/domTools';
import { AppBulkReleaseModal } from '../../../../general/AppBulkReleaseModal/AppBulkReleaseModal';
import { openMessageModal } from '../../../../../redux/globalSlices/genericSlice';
import { InvoiceReference } from '../../../../../constants/invoice';
import { AppReleaseHistoryModal } from '../../../../general/AppReleaseHistoryModal/AppReleaseHistoryModal';

import { useParamMainBuyerId } from '../../../../../utils/hooks/useParamMainBuyerId';
import { getUserByBuyers } from '../../../../../redux/services/authService';
interface ProcessedBulkReleases {
  jobId: string;
  recipientId: string;
  throughDate: string;
  amount: string;
  templateId: string;
  dueDate: string;
}

const BuyerReleaseJobDetails = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [openDropDown, setOpenDropDown] = useState('');
  const [additionalInfoNeeded, setAdditionalInfoNeeded] = useState(false);
  const [openRemoveSupplier, setOpenRemoveSupplier] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState<any | null>(null);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [editJob, setEditJob] = useState<boolean>(false);
  const [showPreviewPdf, setShowPreviewPdf] = useState(false);
  const [previePddfUrl, setPreviePddfUrl] = useState('');
  const [selectedRelease, setSelectedRelease] = useState<LienApiResponse | null>(null);
  const [releasePreviewDetails, setReleasePreviewDetails] = useState(
    {} as {
      recipient: string;
      type: string;
      paymentAmount: number | null;
      throughDate: string | Date | null;
      job: string;
      status:
        | 'Declined'
        | 'Rejected'
        | 'Requested'
        | 'Signed'
        | 'Scheduled'
        | 'Pending'
        | 'Sent to GC';
      declineReason?: string | null;
      releaseAttachments?: { name: string; url: string }[] | null;
      notes?: string | null;
      dueDate: string | Date;
      signedTime?: Date | null;
      signedBy?: string | null;
      sentToGCTime?: Date | null;
      sentToGCEmails?: string[] | null;
      invoiceList?: InvoiceReference[] | null;
    }
  );
  const [selectedFile, setSelectedFile] = useState({} as File);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openSentToGC, setOpenSentToGC] = useState(false);
  const [openDeclineReleaseModal, setOpenRejectReleaseModal] = useState(false);
  const [openBackToSigned, setOpenBackToSigned] = useState(false);
  const [openSuccessBackToSigned, setOpenSuccessBackToSigned] = useState(false);
  const [isSignSuccess, setIsSignSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [openRejectSuccessModal, setOpenRejectSuccessModal] = useState(false);
  const [declineReason, setDeclineReason] = useState('');
  const [openRejectErrorModal, setOpenRejectErrorModal] = useState(false);
  const [errorDelete, setErrorDelete] = useState(false);
  const [openSuccessSentToGC, setOpenSuccessSentToGC] = useState(false);
  const [openFailedToSentToGC, setOpenFailedToSentToGC] = useState(false);
  const [openAddSupplierModal, setOpenAddSupplierModal] = useState(false);
  const [openSuccessAddSupplierModal, setOpenSuccessAddSupplierModal] = useState(false);
  const [openErrorAddSupplierModal, setOpenErrorAddSupplierModal] = useState(false);
  const [openCustomDropdown, setOpenCustomDropDown] = useState('');
  const [suppliersAddedLength, setSuppliersAddedLength] = useState(0);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [updatingReleaseRequirements, setUpdatingReleaseRequirements] = useState('');
  const [openShowHistory, setOpenShowHistory] = useState(false);
  const { selectedJob } = useAppSelector((state) => state.jobs);
  const { jobReleases, selectedReleaseHistory } = useAppSelector((state) => state.liens);
  const { subSuppliers } = useAppSelector((state) => state.subSuppliers);
  const { lienTemplates } = useAppSelector((state) => state.lienTemplates);
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const { user } = useAppSelector((state) => state.auth);
  const width = useWindowActualWidth();
  const today = moment();
  const lastMonthEnd = today.subtract(1, 'months').endOf('month').startOf('day');
  const [openBulkReleasesModal, setOpenBulkReleasesModal] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [selectedBulkReleases, setSelectedBulkReleases] = useState<
    {
      jobId: string;
      recipientId: string;
      throughDate: string;
      amount: string;
      templateId: string;
      dueDate: string;
    }[]
  >([]);

  const fetchJobData = () => {
    if (selectedMainBuyerId) {
      const pathlist = location.pathname.split('/');
      const jobId = pathlist[pathlist.length - 1];
      const currentTimeInDesiredTimeZone = momenttz.tz.guess();
      dispatch(
        getJob({ mainBuyerId: selectedMainBuyerId, jobId, timezone: currentTimeInDesiredTimeZone })
      );
      dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
      dispatch(getJobReleases({ jobId, mainBuyerId: selectedMainBuyerId }));
      dispatch(getSubSuppliers({ mainBuyerId: selectedMainBuyerId }));
      dispatch(getLienTemplates({ mainBuyerId: selectedMainBuyerId }));
      dispatch(getUserByBuyers({ buyerId: selectedMainBuyerId }));
    }
  };

  useEffect(() => {
    fetchJobData();
  }, [selectedMainBuyerId, refresh]);

  useEffect(() => {
    if (
      selectedJob &&
      selectedJob?.endDate &&
      selectedJob?.startDate &&
      selectedJob?.releaseRequirements &&
      selectedJob?.subSupplierIds
    ) {
      setAdditionalInfoNeeded(false);
    } else {
      setAdditionalInfoNeeded(true);
    }
  }, [selectedJob]);

  const defaultTemplateId = () => {
    if (lienTemplates.length > 0) {
      const processedLienTemplates = lienTemplates.filter(
        (template) => template.status === 'processed' && template.type.split('_')[0] === 'PROGRESS'
      );
      if (processedLienTemplates.length > 0) {
        const filteredTemplates = processedLienTemplates.filter((template) =>
          selectedJob?.releaseRequirements?.PROGRESS?.includes(template.type.split('_')[1])
        );
        if (selectedJob && filteredTemplates.length > 0) {
          const jobTemplates = filteredTemplates.filter(
            (template) => template.id === selectedJob?.templates?.[template.type]
          );
          if (jobTemplates.length > 0) {
            return jobTemplates[0].id;
          }
        } else {
          return processedLienTemplates[0].id;
        }
      }
    }
    return '';
  };

  const handleOpenDropDown = (val: string) => {
    if (val === openDropDown) {
      setOpenDropDown('');
    } else {
      setOpenDropDown(val);
    }
  };
  const handleEditJob = () => {
    setEditJob(false);
    fetchJobData();
  };
  const handleBackToSigned = async () => {
    const sendToGCResult = await dispatch(
      updateReleaseByMainBuyer({
        mainBuyerId: selectedMainBuyerId || '',
        releaseId: selectedRelease?.id ?? '',
        releaseDetails: {
          status: 'Signed',
        },
      })
    );
    setOpenBackToSigned(false);
    if (sendToGCResult.type === 'liens/updateReleaseByMainBuyer/fulfilled') {
      setOpenSuccessBackToSigned(true);
    } else {
      setOpenSuccessBackToSigned(true);
    }
  };
  const handleRemoveSupplier = async () => {
    setOpenRemoveSupplier(false);
    if (selectedSupplier?.id && selectedDate && selectedJob?.startDate && selectedMainBuyerId) {
      const data = {
        supplierDateRanges: {
          [selectedSupplier.id]: {
            startDate: selectedJob?.supplierDateRanges?.[selectedSupplier.id]?.startDate
              ? new Date(selectedJob?.supplierDateRanges?.[selectedSupplier.id].startDate)
              : new Date(selectedJob.startDate),
            endDate: new Date(selectedDate),
          },
        },
      };
      const updateResult = await dispatch(
        updateJob({
          mainBuyerId: selectedMainBuyerId,
          jobId: selectedJob.id,
          jobDetails: { ...data },
        })
      );
      if (updateResult.type === 'jobs/updateJob/fulfilled') {
        toast.success(`Successfully removed ${selectedSupplier?.name}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          style: {
            backgroundColor: '#ECFDF3',
            color: '#054F31',
          },
        });
        await fetchJobData();
      } else {
        toast.error(`Error removing ${selectedSupplier?.name}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          style: {
            backgroundColor: '#FEF0EF',
            color: '#F04438',
          },
        });
      }
    }
  };
  const handlePreviewPdf = async (release: LienApiResponse) => {
    setSelectedRelease(release);
    const paymentAmountFieldData = release.fieldsData?.find((item) => item.id === 'paymentAmount');
    const throughDateFieldData = release.fieldsData?.find((item) => item.id === 'throughDate');
    setReleasePreviewDetails({
      recipient: `${
        release.recipient?.subUser?.firstName ?? release.recipient?.user?.firstName ?? ''
      } ${release.recipient?.subUser?.lastName ?? release.recipient?.user?.lastName ?? ''}`,
      type: release?.type?.toLowerCase() ?? '',
      paymentAmount: paymentAmountFieldData ? Number(paymentAmountFieldData.value) : null,
      throughDate: throughDateFieldData ? throughDateFieldData.value : '',
      job: selectedJob?.name ?? '',
      status: release.status,
      declineReason: release?.declineReason,
      releaseAttachments: release?.releaseAttachments,
      notes: release?.notes,
      dueDate: release?.dueDate ?? '',
      signedTime: release.signedTime,
      signedBy:
        release.signedBy?.subUser?.firstName || release.signedBy?.user?.firstName
          ? `${release.signedBy?.subUser?.firstName ?? release.signedBy?.user?.firstName ?? ''} ${
              release.signedBy?.subUser?.lastName ?? release.signedBy?.user?.lastName ?? ''
            }`
          : null,
      sentToGCTime: release?.sentToGCTime ?? null,
      sentToGCEmails: release?.sentToGCEmails ?? null,
      invoiceList: release.invoiceList,
    });
    setShowPreviewPdf(true);
  };
  const handleDownloadSelectedPdf = async (release: LienApiResponse) => {
    if (release) {
      let flattenedPDF;
      const createdAtTimeStamp = release?.createdAt
        ? new Date(release.createdAt).getTime()
        : new Date().getTime();
      if (release?.status === 'Signed' || release?.status === 'Sent to GC') {
        flattenedPDF = release?.signedPdf?.url;
      } else {
        flattenedPDF = await disablePDFFields(release?.previewPdf?.url ?? '');
      }
      downloadFile(
        flattenedPDF || '',
        `${release.job?.name ?? ''}-${release.subSupplier?.name ?? ''}-${createdAtTimeStamp}.pdf`
      );
    }
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setOpenUploadFile(true);
    }
  };

  const openFilePicker = (releaseId: string) => {
    const fileInput = document.getElementById(`fileInput_${releaseId}`) as HTMLInputElement;
    fileInput.click();
  };

  const handleDropdownOption = (value: string, release: LienApiResponse) => {
    switch (value) {
      case 'upload':
        openFilePicker(release.id);
        break;
      case 'edit':
        navigate(`editRelease/${release.id}`);
        break;
      case 'delete':
        setConfirmDelete(true);
        break;
      case 'sent to gc':
        setOpenSentToGC(true);
        break;
      case 'reject':
        setOpenRejectReleaseModal(true);
        break;
      case 'back to signed':
        setOpenBackToSigned(true);
        break;
      // case 'download':
      //   if (release) {
      //     handleDownloadSelectedPdf(release);
      //   }
      //   break;
      default:
        break;
    }
  };
  const handleUploadSignedRelease = async (file: File) => {
    const blobUrl = URL.createObjectURL(file);
    if (selectedRelease && selectedMainBuyerId) {
      const currentTimeInDesiredTimeZone = momenttz.tz.guess();
      const result = await dispatch(
        updateReleaseByMainBuyer({
          mainBuyerId: selectedMainBuyerId || '',
          releaseId: selectedRelease.id ?? '',
          releaseDetails: {
            status: 'Signed',
            signedTime: new Date(),
            signedById: null,
          },
          timezone: currentTimeInDesiredTimeZone,
          storagePathName: `buyerReleases/${selectedRelease.buyer?.id}/${selectedRelease.subSupplier?.id}/${selectedRelease.job?.id}`,
          releasePdfUrl: blobUrl,
          signedPdfName: file.name,
        })
      );
      if (result.type === 'liens/updateReleaseByMainBuyer/fulfilled') {
        setIsSignSuccess(true);
      }
    }
  };
  const handleRejectRelease = async (value: string) => {
    const supplierTemplateField = selectedRelease?.template?.formFields.filter(
      (field) => field.whoPopulatesField === 'SUB_SUPPLIER'
    );
    const adjustedFieldData = [];
    if (selectedRelease?.fieldsData) {
      for (let index = 0; index < selectedRelease?.fieldsData.length; index++) {
        const fieldData = selectedRelease?.fieldsData[index];
        const foundSupplier = supplierTemplateField?.find((temp) => temp.id === fieldData.id);
        if (!foundSupplier) {
          adjustedFieldData.push(fieldData);
        }
      }
    }
    const result = await dispatch(
      updateReleaseByMainBuyer({
        mainBuyerId: selectedMainBuyerId || '',
        releaseId: selectedRelease?.id ?? '',
        releaseDetails: {
          status: 'Rejected',
          declineReason: value,
          fieldsData: adjustedFieldData,
        },
      })
    );
    setOpenRejectReleaseModal(false);
    setDeclineReason(value);
    if (result.type === 'liens/updateReleaseByMainBuyer/fulfilled') {
      setOpenRejectSuccessModal(true);
    } else {
      setOpenRejectErrorModal(true);
    }
  };
  const handleDeleteRelease = async () => {
    const deleteReleaseResult = await dispatch(
      deleteRelease({
        releaseId: selectedRelease?.id ?? '',
        mainBuyerId: selectedMainBuyerId || '',
      })
    );
    setConfirmDelete(false);
    if (deleteReleaseResult.type === 'liens/deleteRelease/fulfilled') {
      setSuccessDelete(true);
    } else {
      setErrorDelete(true);
    }
  };
  const handleSendToGG = async () => {
    const sendToGCResult = await dispatch(
      updateReleaseByMainBuyer({
        mainBuyerId: selectedMainBuyerId || '',
        releaseId: selectedRelease?.id ?? '',
        releaseDetails: {
          status: 'Sent to GC',
          sentToGCTime: null,
          sentToGCEmails: null,
        },
      })
    );
    setOpenSentToGC(false);
    if (sendToGCResult.type === 'liens/updateReleaseByMainBuyer/fulfilled') {
      setOpenSuccessSentToGC(true);
      fetchJobData();
    } else {
      setOpenFailedToSentToGC(true);
    }
  };
  const handleAddNewSupplier = async (items: string[]) => {
    if (!selectedMainBuyerId) {
      return;
    }
    let supplierDateRanges = {};
    for (let index = 0; index < items.length; index++) {
      const startMonth = moment(selectedStartDate).startOf('month').format('MM/DD/YYYY LT');
      const item = items[index];
      supplierDateRanges = {
        ...supplierDateRanges,
        [item]: {
          startDate: new Date(startMonth ?? ''),
          endDate: new Date(selectedJob?.endDate ?? ''),
        },
      };
    }
    const supplierIds = selectedJob?.subSupplierIds?.concat(items);
    const editresult = await dispatch(
      updateJob({
        mainBuyerId: selectedMainBuyerId,
        jobId: selectedJob?.id ?? '',
        jobDetails: { subSupplierIds: supplierIds, supplierDateRanges: supplierDateRanges },
      })
    );
    if (editresult.type === 'jobs/updateJob/fulfilled') {
      setOpenSuccessAddSupplierModal(true);
    } else {
      setOpenErrorAddSupplierModal(true);
    }
  };
  const removeDuplicates = (arr: ProcessedBulkReleases[]): ProcessedBulkReleases[] => {
    const uniqueObjects = arr.filter((obj, index, array) => {
      // Convert the current object to a JSON string and check for uniqueness
      const jsonString = JSON.stringify(obj);
      return array.findIndex((o) => JSON.stringify(o) === jsonString) === index;
    });

    return uniqueObjects;
  };
  const handleBulkUpload = (date: Date, suppliers: any) => {
    const bulkRelease: {
      jobId: string;
      recipientId: string;
      throughDate: string;
      amount: string;
      templateId: string;
      dueDate: string;
    }[] = [];
    setSelectedMonth(moment(date).format('MMMM YYYY'));
    for (let index = 0; index < suppliers.length; index++) {
      const supplier = suppliers[index];
      if (!supplier.archivedState?.includes('BUYER_ARCHIVED')) {
        for (const conditionType in supplier.releaseStatus) {
          if (supplier.releaseStatus?.[conditionType]?.status === 'Not Requested') {
            bulkRelease.push({
              jobId: selectedJob?.id ?? '',
              recipientId: supplier.id,
              throughDate: moment(date).format('MM/DD/YYYY'),
              amount: '',
              templateId: supplier.releaseStatus?.[conditionType]?.templateId ?? '',
              dueDate: '',
            });
          }
        }
      }
    }
    const dataNoDuplicates = removeDuplicates(bulkRelease);
    setSelectedBulkReleases(dataNoDuplicates);
    setOpenBulkReleasesModal(true);
  };
  const handlePreviewBulkRelease = (
    data: {
      recipient: string;
      type: string;
      paymentAmount: number | null;
      throughDate: string | Date | null;
      job: string;
      status:
        | 'Declined'
        | 'Rejected'
        | 'Requested'
        | 'Signed'
        | 'Scheduled'
        | 'Pending'
        | 'Sent to GC';
      dueDate: string | Date;
    },
    url: string
  ) => {
    setSelectedUrl(url);
    setReleasePreviewDetails(data);
    setShowPreviewPdf(true);
  };
  const handleCreateUploadedRelease = async (data: GroupedCSVRelease[]) => {
    const uploadGroupedRelese = await dispatch(
      createGroupedRelease({ releaseDetails: data, mainBuyerId: selectedMainBuyerId || '' })
    );
    if (uploadGroupedRelese.type === 'liens/createGroupedRelease/fulfilled') {
      dispatch(
        openMessageModal({
          title: 'Successfully bulk requested releases!',
          subTitle: `Your selected releases have been sent to your suppliers`,
          buttonText: 'View Job Details',
          modalType: 'SUCCESS',
          onClose: () => {
            setOpenBulkReleasesModal(false);
            setRefresh(!refresh);
          },
        })
      );
    } else {
      dispatch(
        openMessageModal({
          title: 'Bulk requesting releases failed!',
          subTitle: `Contact customer support.`,
          buttonText: 'Close',
          modalType: 'ERROR',
          isSecondButton: false,
          onClose: () => {},
        })
      );
    }
  };
  const handleSendBulkRelease = (data: GroupedCSVRelease[]) => {
    dispatch(
      openMessageModal({
        title: `Are you sure you would like to Bulk Request release${data.length > 1 ? 's' : ''}?`,
        subTitle: `This action will send releases for the month of ${selectedMonth} to the selected suppliers`,
        buttonText: 'No, cancel',
        modalType: 'WARN',
        isSecondButton: true,
        closeOnClickOutsideModal: true,
        secondButtonText: 'Yes, request',
        onSecondButtonClick: () => {
          handleCreateUploadedRelease(data);
        },
        onClose: () => {},
      })
    );
  };

  const updateReleaseException = async (
    val: string,
    monthYear: string,
    supplierId: string,
    supplierName: string,
    releaseRequirement: string[]
  ) => {
    const updateJobRequirement = await dispatch(
      updateJob({
        mainBuyerId: selectedMainBuyerId || '',
        jobId: selectedJob?.id ?? '',
        jobDetails: {
          exceptions: {
            [monthYear]: { [supplierId]: releaseRequirement },
          },
        },
      })
    );
    if (updateJobRequirement.type === 'jobs/updateJob/fulfilled') {
      const monthYearSplit = monthYear.replace('_', ' ');
      dispatch(
        openMessageModal({
          title: `Successfully updated release requirements for ${supplierName}!`,
          subTitle: `This action ${
            val.startsWith('add') ? 'added' : 'removed'
          } a release requirement for this supplier for ${monthYearSplit}.`,
          buttonText: 'Close',
          modalType: 'SUCCESS',
          isSecondButton: false,
          onClose: () => {
            fetchJobData();
          },
        })
      );
    } else {
      dispatch(
        openMessageModal({
          title: `Failed to update release requirements for this job!`,
          subTitle: `Contact customer support.`,
          buttonText: 'Close',
          modalType: 'ERROR',
          isSecondButton: false,
          onClose: () => {},
        })
      );
    }
    setUpdatingReleaseRequirements('');
  };

  const handleReleaseActions = async (
    val: string,
    month: string,
    supplierId: string,
    supplierName: string,
    releaseRequirement: string[]
  ) => {
    try {
      let newReleaseRequirement = releaseRequirement;
      if (val === 'add unconditional') {
        newReleaseRequirement.push('UNCONDITIONAL');
      } else if (val === 'add conditional') {
        newReleaseRequirement.push('CONDITIONAL');
      } else if (val === 'delete unconditional') {
        newReleaseRequirement = releaseRequirement.filter((item) => item !== 'UNCONDITIONAL');
      } else if (val === 'delete conditional') {
        newReleaseRequirement = releaseRequirement.filter((item) => item !== 'CONDITIONAL');
      }
      const actualDate = month.split(' - ')[1].trim();
      const monthYear = moment(actualDate).format('MMMM_YYYY');
      dispatch(
        openMessageModal({
          title: `Are you sure you want to ${val} release requirement for ${supplierName}?`,
          subTitle: `This action will ${val} release requirement for this supplier for ${moment(
            actualDate
          ).format('MMMM YYYY')}.`,
          buttonText: 'No, close',
          modalType: val.startsWith('add') ? 'WARN' : 'ERROR',
          isSecondButton: true,
          secondButtonText: 'Yes, I am sure',
          onSecondButtonClick: () => {
            setUpdatingReleaseRequirements(`supplier_${month}_${supplierId}`);
            updateReleaseException(val, monthYear, supplierId, supplierName, newReleaseRequirement);
          },
          onClose: () => {},
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowHistory = async () => {
    const releaseHisResult = await dispatch(
      getMainBuyerReleaseHistory({
        releaseId: selectedRelease?.id ?? '',
        mainBuyerId: selectedMainBuyerId || '',
      })
    );
    if (releaseHisResult.type === 'releases/getMainBuyerReleaseHistory/fulfilled') {
      setOpenShowHistory(true);
    }
  };

  return (
    <div className="w-full h-5/6 p-8 flex flex-col">
      <AppReleaseHistoryModal
        open={openShowHistory}
        handleClose={() => setOpenShowHistory(false)}
        history={selectedReleaseHistory}
      />
      <AppBulkReleaseModal
        open={openBulkReleasesModal}
        releaseOptions={selectedBulkReleases}
        handleClose={() => setOpenBulkReleasesModal(false)}
        onCreateBulkReleases={handleSendBulkRelease}
        onPreviewRelease={handlePreviewBulkRelease}
      />
      <AppAddSupplierModal
        open={openAddSupplierModal}
        handleClose={() => {
          setOpenAddSupplierModal(false);
        }}
        suppliersGroup={[
          ...subSuppliers
            .filter(
              (sub) =>
                !selectedJob?.subSupplierIds?.includes(sub.id) &&
                !sub.archivedState?.includes('BUYER_ARCHIVED')
            )
            .map((subSupplier, i) => {
              return {
                label: `${subSupplier.name ?? ''}`,
                value: subSupplier.id ?? '',
              };
            }),
        ]}
        onAddSupplier={(item) => {
          setSuppliersAddedLength(item.length);
          handleAddNewSupplier(item);
        }}
      />
      {selectedJob && (
        <AppNewJobModal
          open={editJob}
          editMode={true}
          selectedJob={selectedJob}
          handleClose={handleEditJob}
          subSuppliers={subSuppliers}
        />
      )}
      <AppPdfPreviewModal
        open={showPreviewPdf}
        handleClose={() => {
          setShowPreviewPdf(false);
        }}
        invoice={''}
        pdfType="RELEASE"
        pdfList={[
          {
            name: 'lien-release',
            url: openBulkReleasesModal
              ? selectedUrl
              : selectedRelease?.status === 'Signed' || selectedRelease?.status === 'Sent to GC'
              ? selectedRelease?.signedPdf?.url || ''
              : selectedRelease?.previewPdf?.url || '',
            date: new Date(),
          },
        ]}
        handleDownloadPdf={() => {
          if (selectedRelease && !openBulkReleasesModal) {
            handleDownloadSelectedPdf(selectedRelease);
          }
        }}
        isSigned={false}
        releaseDetails={releasePreviewDetails}
        mode="Buyer"
        showPendingMessage={!openBulkReleasesModal}
        showHistoryButton={!openBulkReleasesModal}
        onShowHistory={handleShowHistory}
      />
      <AppSuccessModal
        open={openRemoveSupplier}
        type="warning"
        title={`Are you sure you want to remove ${selectedSupplier?.name ?? ''} ${
          selectedDate ? ` for ${moment(selectedDate).add(1, 'month').format('MMMM')} onwards?` : ''
        }`}
        subTitle={`${selectedSupplier?.name ?? ''} will be removed from your supplier list ${
          selectedDate
            ? ` for ${moment(selectedDate).add(1, 'month').format('MMMM')}  and all months onwards`
            : ''
        }`}
        buttonText="No, cancel"
        secondButtonText="Yes, I’m sure"
        isSecondButton={true}
        onSecondButtonClick={() => {
          handleRemoveSupplier();
        }}
        handleClose={() => {
          setOpenRemoveSupplier(false);
        }}
      />
      <AppSuccessModal
        open={openSuccessBackToSigned}
        title="Successfully updated release status back to “Signed”!"
        subTitle="The status of this release is now “Signed”"
        buttonText="Back to All Releases"
        handleClose={() => {
          setOpenSuccessBackToSigned(false);
          fetchJobData();
        }}
      />
      <AppSuccessModal
        open={openBackToSigned}
        type="warning"
        title="Are you sure you want to update release status back to “Signed”?"
        subTitle="The status of this release will be updated to ““Signed”” in your Dill dashboard"
        buttonText="No, cancel"
        secondButtonText="Yes, I’m sure"
        isSecondButton={true}
        onSecondButtonClick={() => {
          handleBackToSigned();
        }}
        handleClose={() => {
          setOpenBackToSigned(false);
        }}
      />
      <AppUploadFileModal
        open={openUploadFile}
        handleClose={() => {
          setOpenUploadFile(false);
        }}
        title="Upload Signed Release"
        secondaryText="The status of this release will update  to “Signed,” and you’ll be able to access and download this signed release in your Dill dashboard."
        onSelectFile={handleUploadSignedRelease}
        selectedFile={selectedFile}
      />
      <AppSuccessModal
        open={isSignSuccess}
        handleClose={() => {
          setIsSignSuccess(false);
          fetchJobData();
        }}
        title="Successfully uploaded signed release!"
        subTitle="The status of this release is now “Signed”"
        buttonText="View Job Details"
      />
      <AppSuccessModal
        open={successDelete}
        handleClose={() => {
          setSuccessDelete(false);
          fetchJobData();
        }}
        title="Successfully deleted lien release!"
        subTitle="This release is now gone from your dashboard"
        buttonText="View Job Details"
      />

      <AppSuccessModal
        open={openRejectSuccessModal}
        handleClose={() => {
          setOpenRejectSuccessModal(false);
          setDeclineReason('');
          fetchJobData();
        }}
        title="Successfully rejected lien release!"
        subTitle="This release is now rejected"
        buttonText="View Job Details"
      />
      <AppSuccessModal
        open={openRejectErrorModal}
        modalImage={errorCircle}
        title="Error rejecting release!"
        subTitle="Your lien release request failed to reject. Contact customer support"
        buttonText="Close"
        secondButtonText="Retry"
        isSecondButton={true}
        onSecondButtonClick={() => {
          setOpenRejectErrorModal(false);
          handleRejectRelease(declineReason);
        }}
        handleClose={() => {
          setOpenRejectErrorModal(false);
        }}
      />
      <AppSuccessModal
        open={errorDelete}
        modalImage={errorCircle}
        title="Error deleting release!"
        subTitle="Your lien release request failed to delete. Contact customer support"
        buttonText="Close"
        secondButtonText="Retry"
        isSecondButton={true}
        onSecondButtonClick={() => {
          setErrorDelete(false);
          handleDeleteRelease();
        }}
        handleClose={() => {
          setErrorDelete(false);
        }}
      />
      <AppSuccessModal
        open={openSentToGC}
        type="warning"
        title="Are you sure you want to update release status to “Sent to GC”?"
        subTitle="The status of this release will be updated to “Sent to GC” in your Dill dashboard"
        buttonText="No, cancel"
        secondButtonText="Yes, I’m sure"
        isSecondButton={true}
        onSecondButtonClick={() => {
          handleSendToGG();
        }}
        handleClose={() => {
          setOpenSentToGC(false);
        }}
      />
      <AppSuccessModal
        open={openFailedToSentToGC}
        modalImage={errorCircle}
        title="Error updating release status to “Sent to GC”"
        subTitle="Please contact customer support"
        buttonText="Close"
        handleClose={() => {
          setOpenFailedToSentToGC(false);
        }}
      />
      <AppSuccessModal
        open={openSuccessSentToGC}
        title="Successfully updated release status!"
        subTitle="The status of this release is now “Sent to GC”"
        buttonText="Back to All Releases"
        handleClose={() => {
          setOpenSuccessSentToGC(false);
          fetchJobData();
        }}
      />
      <AppDeleteModal
        open={confirmDelete}
        handleClose={() => setConfirmDelete(false)}
        title="Are you sure you want to delete this lien release?"
        subTitle="This will cancel the request to your vendor and remove any link to payments on Dill. This action can’t be undone"
        buttonText="No, cancel"
        secondButtonText="Yes, I’m sure"
        onSecondButtonClick={handleDeleteRelease}
      />
      <AppNotesModal
        open={openDeclineReleaseModal}
        type="buyer"
        onSubmit={handleRejectRelease}
        handleClose={() => setOpenRejectReleaseModal(false)}
      />
      <AppSuccessModal
        open={openSuccessAddSupplierModal}
        handleClose={() => {
          setOpenSuccessAddSupplierModal(false);
          fetchJobData();
          setSuppliersAddedLength(0);
        }}
        title={`Successfully added supplier${suppliersAddedLength > 1 ? 's' : ''} to the job!`}
        subTitle={`The supplier${
          suppliersAddedLength > 1 ? 's have ' : ' has '
        } been added to the selected month and the rest of the months on the job`}
        buttonText="View Job Details"
      />
      <AppSuccessModal
        open={openErrorAddSupplierModal}
        modalImage={errorCircle}
        title={`Error adding supplier${suppliersAddedLength > 1 ? 's' : ''}!`}
        subTitle={`Failed to add supplier${suppliersAddedLength > 1 ? 's' : ''} to the job`}
        buttonText="Close"
        secondButtonText="Retry"
        isSecondButton={true}
        onSecondButtonClick={() => {
          setOpenAddSupplierModal(true);
          setOpenErrorAddSupplierModal(false);
        }}
        handleClose={() => {
          setOpenErrorAddSupplierModal(false);
          setSuppliersAddedLength(0);
        }}
      />
      <div className="bg-white p-6 rounded-xl">
        {/* Header */}
        <div className="flex justify-between items-center">
          <div>
            <div className="TEXT_SECONDARY-CLR text-xs font-medium">JOB</div>
            <div className="TEXT_PRIMARY-CLR font-bold text-2xl">{`# ${selectedJob?.number ?? ''} ${
              selectedJob?.name ?? ''
            }`}</div>
            <div className="TEXT_SECONDARY-CLR text-sm font-normal truncate">
              {`${selectedJob?.address ?? ''} ${selectedJob?.city ?? ''} ${
                selectedJob?.state ?? ''
              }`}
            </div>
          </div>
          <div>
            <div className="TEXT_SECONDARY-CLR text-xs font-medium">GC</div>
            {/* <div className="TEXT_PRIMARY-CLR font-bold text-2xl">{jobDetails.name}</div> */}
            <div className="TEXT_PRIMARY-CLR font-bold text-2xl">
              {selectedJob?.accountantEmails?.join(', ') ?? ''}
            </div>
          </div>
          <div className="flex">
            <div
              data-tooltip-id={
                selectedJob?.archivedState?.includes('BUYER_ARCHIVED')
                  ? 'job-details-archive-tooltip'
                  : undefined
              }>
              <AppButton
                text="Edit Job"
                type="SECONDARY"
                onClick={() => {
                  setEditJob(true);
                }}
                buttonStyles={{ marginRight: '10px' }}
                isDisabled={selectedJob?.archivedState?.includes('BUYER_ARCHIVED')}
              />
            </div>
            <div
              data-tooltip-id={
                selectedJob?.archivedState?.includes('BUYER_ARCHIVED')
                  ? 'job-details-archive-tooltip'
                  : undefined
              }>
              <AppButton
                text="Add Release Request"
                icon={<Plus color={COLORS.WHITE} />}
                onClick={() => {
                  navigate(
                    `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs/${
                      selectedJob?.id
                    }/addRelease?templateId=${defaultTemplateId()}&currentThroughDate=${moment(
                      lastMonthEnd
                    ).format('MM/DD/YYYY LT')}`
                  );
                }}
                isDisabled={selectedJob?.archivedState?.includes('BUYER_ARCHIVED')}
              />
            </div>
            <ReactTooltip
              id={'job-details-archive-tooltip'}
              place="top"
              variant="dark"
              opacity={'100%'}
              style={{
                display: 'flex',
                background: '#334155',
                width: '300px',
                zIndex: '70',
                borderRadius: '8px',
                alignItems: 'center',
                justifyItems: 'center',
                fontWeight: '600',
              }}>
              <span>
                This job has been archived. You cannot edit or request a release for this job.
              </span>
            </ReactTooltip>
          </div>
        </div>
        {selectedJob?.notes && selectedJob?.notes !== '' && (
          <div
            data-tooltip-id={'job-notes-tooltip'}
            style={{ maxWidth: '40vw' }}
            className="flex INFO_200-BG py-3 px-2 items-center rounded-lg overflow-hidden w-fit">
            <div className="flex font-bold items-center">
              <Notepad color="#000000" size={20} />
              <div className="mx-1 text-sm">Notes:</div>
            </div>
            <div className="truncate text-sm TEXT_SECONDARY-CLR font-medium ml-2">
              {selectedJob?.notes}
            </div>
          </div>
        )}
        <ReactTooltip
          id={'job-notes-tooltip'}
          place="right"
          variant="dark"
          opacity={'100%'}
          style={{
            display: 'flex',
            background: '#334155',
            width: '300px',
            zIndex: '70',
            borderRadius: '8px',
            alignItems: 'center',
            justifyItems: 'center',
            fontWeight: '600',
          }}>
          <span>{selectedJob?.notes}</span>
        </ReactTooltip>
        {/* Grid table */}
        {!additionalInfoNeeded ? (
          <div
            style={{ maxHeight: '65vh' }}
            className="w-full flex-col flex overflow-y-scroll mt-4 GREY_50-BG GREY_200-BORDER border rounded-xl ">
            {selectedJob?.jobRangeDetails?.map((job) => {
              const sentToGCConditionalCount = job.subSuppliers.filter(
                (item) =>
                  item.releaseStatus.conditional.status === 'Sent to GC' ||
                  item.releaseStatus.conditional.status === 'Signed'
              ).length;
              const sentToGCUnconditionalCount = job.subSuppliers.filter(
                (item) =>
                  item.releaseStatus.unconditional.status === 'Sent to GC' ||
                  item.releaseStatus.unconditional.status === 'Signed'
              ).length;
              const notApplicableconditionalCount = job.subSuppliers.filter(
                (item) => item.releaseStatus.conditional.status === 'Not Applicable'
              ).length;
              const notApplicableUnconditionalCount = job.subSuppliers.filter(
                (item) => item.releaseStatus.unconditional.status === 'Not Applicable'
              ).length;

              const totalCount = job.subSuppliers.length;
              return (
                <div key={job.date}>
                  <div
                    onClick={() => handleOpenDropDown(job.id)}
                    className="flex justify-between items-center GREY_200-BORDER border-b">
                    <div className="flex w-1/3 GREY_200-BORDER border-r justify-between items-center ">
                      <div className="flex px-6 py-4 w-full TEXT_PRIMARY-CLR text-sm font-medium items-center">
                        <div className="mr-2">{job.date}</div>
                        {openDropDown === job.id ? (
                          <ChevronUp color={COLORS.GREY_500} />
                        ) : (
                          <ChevronDown color={COLORS.GREY_500} />
                        )}
                      </div>
                      <div
                        data-tooltip-id={
                          selectedJob?.archivedState?.includes('BUYER_ARCHIVED')
                            ? `job-details-archive-tooltip_${selectedJob.id}`
                            : undefined
                        }>
                        <div
                          className="pr-2"
                          data-tooltip-id={
                            !selectedJob?.archivedState?.includes('BUYER_ARCHIVED')
                              ? `job-details-tooltip_add_supplier`
                              : undefined
                          }>
                          {width < 1300 ? (
                            <AppCustomDropdown
                              width="150px"
                              options={[
                                {
                                  value: 'add supplier',
                                  label: 'Add Supplier',
                                  icon: <PlusCircle size={16} />,
                                },
                                {
                                  value: 'bulk request',
                                  label: 'Bulk Request',
                                  icon: <Rows size={16} />,
                                  type: 'primary',
                                },
                              ]}
                              onSelect={(val) => {
                                if (val === 'add supplier') {
                                  setOpenAddSupplierModal(true);
                                  setSelectedStartDate(job.startDate ?? null);
                                } else if (val === 'bulk request') {
                                  handleBulkUpload(job.endDate, job.subSuppliers);
                                }
                              }}
                              open={openCustomDropdown === `supplier_date_${job.date}`}
                              onOpen={() => setOpenCustomDropDown(`supplier_date_${job.date}`)}
                              onClose={() => setOpenCustomDropDown('')}
                              isDisabled={selectedJob?.archivedState?.includes('BUYER_ARCHIVED')}
                            />
                          ) : (
                            <div className="flex">
                              <AppButton
                                type="SECONDARY"
                                text="Bulk Request"
                                buttonStyles={{
                                  padding: '8px',
                                  height: '35px',
                                  width: '100%',
                                  marginRight: '8px',
                                }}
                                onClick={() => {
                                  handleBulkUpload(job.endDate, job.subSuppliers);
                                }}
                              />
                              <div className="mr-3">
                                <div data-tooltip-id={`job-add-supplier-${job.date}`}>
                                  <AppButton
                                    type="TERTIARY"
                                    buttonStyles={{
                                      padding: '8px',
                                      height: '35px',
                                      width: '35px',
                                    }}
                                    icon={<PlusCircle color={COLORS.GREY_500} size={20} />}
                                    onClick={() => {
                                      setOpenAddSupplierModal(true);
                                      setSelectedStartDate(job.startDate ?? null);
                                    }}
                                  />
                                </div>
                                <ReactTooltip
                                  id={`job-add-supplier-${job.date}`}
                                  place="top"
                                  variant="dark"
                                  opacity={'100%'}
                                  style={{
                                    display: 'flex',
                                    background: '#334155',
                                    width: '120px',
                                    zIndex: '70',
                                    borderRadius: '8px',
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                    fontWeight: '600',
                                  }}>
                                  <span>Add Supplier</span>
                                </ReactTooltip>
                              </div>
                            </div>
                          )}
                        </div>

                        <ReactTooltip
                          id={`job-details-archive-tooltip_${selectedJob.id}`}
                          place="top"
                          variant="dark"
                          opacity={'100%'}
                          style={{
                            display: 'flex',
                            background: '#334155',
                            width: '220px',
                            zIndex: '70',
                            borderRadius: '8px',
                            alignItems: 'center',
                            justifyItems: 'center',
                            fontWeight: '600',
                            fontSize: '12px',
                          }}>
                          <span>
                            This job has been archived. You cannot add or remove a supplier.
                          </span>
                        </ReactTooltip>
                      </div>
                    </div>
                    <div className="px-6 py-4 GREY_200-BORDER border-r w-1/3 TEXT_SECONDARY-CLR text-sm font-medium ">
                      <div className="flex justify-between">
                        <div>Unconditionals</div>
                        <div>
                          {notApplicableUnconditionalCount === totalCount
                            ? '0/0'
                            : `${sentToGCUnconditionalCount}/${
                                totalCount - notApplicableUnconditionalCount
                              }`}
                        </div>
                      </div>
                      <div className="w-full GREY_200-BG h-2 mt-3 rounded">
                        {totalCount - notApplicableUnconditionalCount !== 0 && (
                          <div>
                            {sentToGCUnconditionalCount === 0 && totalCount === 0 ? (
                              <div
                                className="PRIMARY_500-BG h-2 rounded"
                                style={{
                                  width: `100%`,
                                }}
                              />
                            ) : (
                              <div
                                className="PRIMARY_500-BG h-2 rounded"
                                style={{
                                  width: `${
                                    (sentToGCUnconditionalCount /
                                      (totalCount - notApplicableUnconditionalCount)) *
                                    100
                                  }%`,
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="px-6 py-4 w-1/3 TEXT_SECONDARY-CLR text-sm font-medium">
                      <div className="flex justify-between">
                        <div>Conditionals</div>
                        <div>
                          {notApplicableconditionalCount === totalCount
                            ? '0/0'
                            : `${sentToGCConditionalCount}/${
                                totalCount - notApplicableconditionalCount
                              }`}
                        </div>
                      </div>
                      <div className="w-full GREY_200-BG h-2 mt-3 rounded">
                        {totalCount - notApplicableconditionalCount !== 0 && (
                          <div>
                            {sentToGCConditionalCount === 0 && totalCount === 0 ? (
                              <div
                                className="PRIMARY_500-BG h-2 rounded"
                                style={{
                                  width: `100%`,
                                }}
                              />
                            ) : (
                              <div
                                className="PRIMARY_500-BG h-2 rounded"
                                style={{
                                  width: `${
                                    (sentToGCConditionalCount /
                                      (totalCount - notApplicableconditionalCount)) *
                                    100
                                  }%`,
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {openDropDown === job.id && (
                    <div className="flex PRIMARY_50-BG GREY_500-CLR GREY_200-BORDER border-b text-xs">
                      <div className="w-1/3 GREY_200-BORDER border-r py-2 px-6">Company</div>
                      <div className="flex w-1/3">
                        <div className="w-full GREY_200-BORDER border-r py-2 px-6">Status</div>
                        <div className="w-full GREY_200-BORDER border-r py-2 px-6">Action</div>
                      </div>
                      <div className="flex w-1/3">
                        <div className="w-full GREY_200-BORDER border-r py-2 px-6">Status</div>
                        <div className="w-full GREY_200-BORDER border-r py-2 px-6">Action</div>
                      </div>
                    </div>
                  )}
                  {openDropDown === job.id && (
                    <div>
                      {job.subSuppliers.length > 0 &&
                        job.subSuppliers.map((subSupplier, i) => {
                          const releaseStatus: string[] = [];
                          if (
                            !subSupplier?.archivedState?.includes('BUYER_ARCHIVED') &&
                            !selectedJob?.archivedState?.includes('BUYER_ARCHIVED') &&
                            !selectedJob.releaseRequirements?.PROGRESS?.includes('DUAL') &&
                            !selectedJob.releaseRequirements?.FINAL?.includes('DUAL')
                          ) {
                            if (
                              subSupplier?.releaseStatus?.unconditional?.status === 'Not Requested'
                            ) {
                              releaseStatus.push('UNCONDITIONAL');
                            }
                            if (
                              subSupplier?.releaseStatus?.conditional?.status === 'Not Requested'
                            ) {
                              releaseStatus.push('CONDITIONAL');
                            }
                          }
                          const supplierDropdownOptions: {
                            value: string;
                            label: string;
                            type?: 'error';
                            icon?: any;
                          }[] = [];
                          if (
                            !subSupplier?.archivedState?.includes('BUYER_ARCHIVED') &&
                            !selectedJob?.archivedState?.includes('BUYER_ARCHIVED') &&
                            !selectedJob.releaseRequirements?.PROGRESS?.includes('DUAL') &&
                            !selectedJob.releaseRequirements?.FINAL?.includes('DUAL')
                          ) {
                            if (
                              !releaseStatus.includes('UNCONDITIONAL') &&
                              (subSupplier?.releaseStatus?.unconditional?.status ===
                                'Not Requested' ||
                                subSupplier?.releaseStatus?.unconditional?.status ===
                                  'Not Applicable')
                            ) {
                              supplierDropdownOptions.push({
                                value: 'add unconditional',
                                label: 'Add  Unconditional',
                                icon: <PlusCircle size={16} />,
                              });
                            }
                            if (releaseStatus.includes('UNCONDITIONAL')) {
                              supplierDropdownOptions.push({
                                value: 'delete unconditional',
                                label: 'Delete  Unconditional',
                                icon: <Trash size={16} />,
                              });
                            }
                            if (
                              !releaseStatus.includes('CONDITIONAL') &&
                              (subSupplier?.releaseStatus?.conditional?.status ===
                                'Not Requested' ||
                                subSupplier?.releaseStatus?.conditional?.status ===
                                  'Not Applicable')
                            ) {
                              supplierDropdownOptions.push({
                                value: 'add conditional',
                                label: 'Add  Conditional',
                                icon: <PlusCircle size={16} />,
                              });
                            }
                            if (releaseStatus.includes('CONDITIONAL')) {
                              supplierDropdownOptions.push({
                                value: 'delete conditional',
                                label: 'Delete  Conditional',
                                icon: <Trash size={16} />,
                              });
                            }
                          }
                          supplierDropdownOptions.push({
                            value: 'delete supplier',
                            label: 'Remove Supplier',
                            icon: <Backspace size={16} />,
                            type: 'error',
                          });
                          let unconditionalOptions: {
                            value: string;
                            label: string;
                            type?: 'error' | 'primary' | 'warning';
                            icon?: any;
                          }[] = [];
                          if (
                            !subSupplier?.archivedState?.includes('BUYER_ARCHIVED') &&
                            !selectedJob?.archivedState?.includes('BUYER_ARCHIVED')
                          ) {
                            unconditionalOptions =
                              subSupplier?.releaseStatus?.unconditional?.release?.status ===
                                'Signed' &&
                              subSupplier?.releaseStatus?.unconditional?.release?.template
                                ? [
                                    {
                                      value: 'sent to gc',
                                      label: 'Mark As ‘Sent to GC’',
                                      icon: <ArrowElbowUpRight size={16} />,
                                    },
                                    {
                                      value: 'reject',
                                      label: 'Reject',
                                      icon: <Prohibit size={16} />,
                                      type: 'error',
                                    },
                                  ]
                                : subSupplier?.releaseStatus?.unconditional?.release?.status ===
                                    'Signed' &&
                                  !subSupplier?.releaseStatus?.unconditional?.release?.template
                                ? [
                                    {
                                      value: 'sent to gc',
                                      label: 'Mark As ‘Sent to GC’',
                                      icon: <ArrowElbowUpRight size={16} />,
                                    },
                                  ]
                                : subSupplier?.releaseStatus?.unconditional?.release?.status ===
                                  'Sent to GC'
                                ? [
                                    {
                                      value: 'back to signed',
                                      icon: <ArrowElbowUpLeft size={16} />,
                                      label: 'Revert back to ‘Signed’',
                                    },
                                  ]
                                : [
                                    {
                                      value: 'upload',
                                      label: 'Upload Signed Release',
                                      icon: <UploadSimple size={16} />,
                                    },
                                    {
                                      value: 'edit',
                                      label: 'Edit',
                                      icon: <Edit3 size={16} />,
                                    },
                                    {
                                      value: 'delete',
                                      label: 'Delete',
                                      icon: <Trash size={16} />,
                                      type: 'error',
                                    },
                                  ];
                          }
                          if (width <= 1400) {
                            unconditionalOptions.push(
                              { value: 'preview', label: 'Preview', icon: <Eye size={16} /> },
                              {
                                value: 'download',
                                label: 'Download',
                                icon: <DownloadSimple size={16} />,
                              }
                            );
                          }
                          let conditionalOptions: {
                            value: string;
                            label: string;
                            type?: 'error' | 'primary' | 'warning';
                            icon?: any;
                          }[] = [];
                          if (
                            !subSupplier?.archivedState?.includes('BUYER_ARCHIVED') &&
                            !selectedJob?.archivedState?.includes('BUYER_ARCHIVED')
                          ) {
                            conditionalOptions =
                              subSupplier?.releaseStatus?.conditional?.release?.status ===
                                'Signed' &&
                              subSupplier?.releaseStatus?.conditional?.release?.template
                                ? [
                                    {
                                      value: 'sent to gc',
                                      label: 'Mark As ‘Sent to GC’',
                                      icon: <ArrowElbowUpRight size={16} />,
                                    },
                                    {
                                      value: 'reject',
                                      label: 'Reject',
                                      icon: <Prohibit size={16} />,
                                      type: 'error',
                                    },
                                  ]
                                : subSupplier?.releaseStatus?.conditional?.release?.status ===
                                    'Signed' &&
                                  !subSupplier?.releaseStatus?.conditional?.release?.template
                                ? [
                                    {
                                      value: 'sent to gc',
                                      label: 'Mark As ‘Sent to GC’',
                                      icon: <ArrowElbowUpRight size={16} />,
                                    },
                                  ]
                                : subSupplier?.releaseStatus?.conditional?.release?.status ===
                                  'Sent to GC'
                                ? [
                                    {
                                      value: 'back to signed',
                                      icon: <ArrowElbowUpLeft size={16} />,
                                      label: 'Revert back to ‘Signed’',
                                    },
                                  ]
                                : [
                                    {
                                      value: 'upload',
                                      label: 'Upload Signed Release',
                                      icon: <UploadSimple size={16} />,
                                    },
                                    {
                                      value: 'edit',
                                      label: 'Edit',
                                      icon: <Edit3 size={16} />,
                                    },
                                    {
                                      value: 'delete',
                                      label: 'Delete',
                                      icon: <Trash size={16} />,
                                      type: 'error',
                                    },
                                  ];
                          }
                          if (width <= 1400) {
                            conditionalOptions.push(
                              { value: 'preview', label: 'Preview', icon: <Eye size={16} /> },
                              {
                                value: 'download',
                                label: 'Download',
                                icon: <DownloadSimple size={16} />,
                              }
                            );
                          }
                          return (
                            <div
                              key={`${job.id}_${subSupplier?.id}`}
                              className="flex justify-between items-center bg-white GREY_200-BORDER border-b">
                              <div className="flex px-6 py-6 justify-between GREY_200-BORDER border-r h-[104px] items-center w-1/3 TEXT_PRIMARY-CLR text-xs font-medium">
                                <div>{subSupplier?.name}</div>
                                <div
                                  data-tooltip-id={
                                    selectedJob?.archivedState?.includes('BUYER_ARCHIVED')
                                      ? `job-details-archive-tooltip_${selectedJob.id}`
                                      : undefined
                                  }>
                                  <AppCustomDropdown
                                    options={supplierDropdownOptions}
                                    onSelect={(val) => {
                                      if (val === 'delete supplier') {
                                        const stringStartDate = moment(job.startDate).format(
                                          'MM/DD/YYYY'
                                        );
                                        setSelectedDate(
                                          moment(stringStartDate)
                                            .subtract(1, 'day')
                                            .format('MM/DD/YYYY LT')
                                        );
                                        setSelectedSupplier(subSupplier);
                                        setOpenRemoveSupplier(true);
                                      } else {
                                        handleReleaseActions(
                                          val,
                                          job.date,
                                          subSupplier?.id ?? '',
                                          subSupplier?.name ?? '',
                                          releaseStatus
                                        );
                                      }
                                    }}
                                    open={openCustomDropdown === `delete_${subSupplier?.id}`}
                                    onOpen={() =>
                                      setOpenCustomDropDown(`delete_${subSupplier?.id ?? ''}`)
                                    }
                                    onClose={() => setOpenCustomDropDown('')}
                                    isDisabled={selectedJob?.archivedState?.includes(
                                      'BUYER_ARCHIVED'
                                    )}
                                    isLoading={
                                      `supplier_${job.date}_${subSupplier?.id ?? ''}` ===
                                      updatingReleaseRequirements
                                    }
                                  />
                                </div>
                              </div>
                              <div className="flex w-1/3 items-center GREY_200-BORDER border-r h-full">
                                <div className="flex px-6 py-7 GREY_200-BORDER border-r w-full h-[104px] items-center font-semibold overflow-scroll">
                                  {subSupplier?.releaseStatus?.unconditional?.status !==
                                    'Not Applicable' &&
                                    subSupplier?.releaseStatus?.unconditional?.status !==
                                      'Not Requested' && (
                                      <AppReleaseStatusPill
                                        wrapperClassName={'w-full'}
                                        status={subSupplier?.releaseStatus?.unconditional?.status}
                                      />
                                    )}
                                  {subSupplier?.releaseStatus?.unconditional?.status ===
                                    'Not Requested' && (
                                    <div className="flex items-center w-fit px-3 py-2 rounded-full WARNING_100-BG WARNING_500-CLR">
                                      <Warning weight="bold" />
                                      <div className="font-semibold text-xs ml-2">
                                        {subSupplier?.releaseStatus?.unconditional?.status}
                                      </div>
                                    </div>
                                  )}
                                  {subSupplier?.releaseStatus?.unconditional?.status ===
                                    'Not Applicable' && (
                                    <div className="flex items-center w-fit px-3 py-2 rounded-full GREY_500-CLR GREY_100-BG">
                                      <WarningOctagon weight="bold" />
                                      <div className="font-semibold text-xs ml-2">
                                        {subSupplier?.releaseStatus?.unconditional?.status}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={`flex px-6 py-3 w-full TEXT_SECONDARY-CLR text-xs items-center font-medium ${
                                    subSupplier?.releaseStatus?.unconditional?.status ===
                                    'Not Requested'
                                      ? 'overflow-scroll'
                                      : ''
                                  }`}>
                                  {subSupplier?.releaseStatus?.unconditional?.status !==
                                    'Not Requested' &&
                                    subSupplier?.releaseStatus?.unconditional?.status !==
                                      'Not Applicable' && (
                                      <div className="flex  w-full">
                                        {width > 1400 && (
                                          <AppButton
                                            type="TERTIARY"
                                            buttonStyles={{
                                              padding: '8px',
                                              height: '35px',
                                              width: '35px',
                                              marginRight: '8px',
                                            }}
                                            icon={<Eye color={COLORS.GREY_500} size={20} />}
                                            onClick={() => {
                                              handlePreviewPdf(
                                                subSupplier?.releaseStatus?.unconditional?.release
                                              );
                                            }}
                                          />
                                        )}
                                        {width > 1400 && (
                                          <AppButton
                                            type="TERTIARY"
                                            buttonStyles={{
                                              padding: '8px',
                                              height: '35px',
                                              width: '35px',
                                              marginRight: '8px',
                                            }}
                                            icon={
                                              <DownloadSimple color={COLORS.GREY_500} size={20} />
                                            }
                                            onClick={() => {
                                              handleDownloadSelectedPdf(
                                                subSupplier?.releaseStatus?.unconditional?.release
                                              );
                                            }}
                                          />
                                        )}
                                        <input
                                          id={`fileInput_${subSupplier?.releaseStatus?.unconditional?.release.id}`}
                                          type="file"
                                          style={{ display: 'none' }}
                                          accept="application/pdf"
                                          onChange={handleFileChange}
                                        />
                                        <div
                                          data-tooltip-id={
                                            (width > 1400 &&
                                              subSupplier?.archivedState &&
                                              subSupplier.archivedState.includes(
                                                'BUYER_ARCHIVED'
                                              )) ||
                                            (width > 1400 &&
                                              selectedJob?.archivedState &&
                                              selectedJob.archivedState.includes('BUYER_ARCHIVED'))
                                              ? `job-details-archive-supplier-${subSupplier.id}-${i}`
                                              : undefined
                                          }>
                                          <AppCustomDropdown
                                            inputFieldStyles={{ width: '35px', marginRight: '8px' }}
                                            onSelect={(val) => {
                                              setSelectedRelease(
                                                subSupplier?.releaseStatus?.unconditional?.release
                                              );
                                              if (val === 'preview') {
                                                handlePreviewPdf(
                                                  subSupplier?.releaseStatus?.unconditional?.release
                                                );
                                              } else if (val === 'download') {
                                                handleDownloadSelectedPdf(
                                                  subSupplier?.releaseStatus?.unconditional?.release
                                                );
                                              } else {
                                                handleDropdownOption(
                                                  val,
                                                  subSupplier?.releaseStatus?.unconditional?.release
                                                );
                                              }
                                            }}
                                            options={unconditionalOptions}
                                            isDisabled={
                                              (width > 1400 &&
                                                subSupplier?.archivedState &&
                                                subSupplier.archivedState.includes(
                                                  'BUYER_ARCHIVED'
                                                )) ||
                                              (width > 1400 &&
                                                selectedJob?.archivedState &&
                                                selectedJob.archivedState.includes(
                                                  'BUYER_ARCHIVED'
                                                ))
                                            }
                                            open={
                                              openCustomDropdown ===
                                              `${subSupplier?.releaseStatus?.unconditional?.release?.id}_unconditional`
                                            }
                                            onOpen={() =>
                                              setOpenCustomDropDown(
                                                `${subSupplier?.releaseStatus?.unconditional?.release?.id}_unconditional` ??
                                                  ''
                                              )
                                            }
                                            onClose={() => setOpenCustomDropDown('')}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  {subSupplier?.releaseStatus?.unconditional?.status ===
                                    'Not Requested' && (
                                    <div
                                      data-tooltip-id={
                                        (subSupplier?.archivedState &&
                                          subSupplier.archivedState.includes('BUYER_ARCHIVED')) ||
                                        (selectedJob?.archivedState &&
                                          selectedJob.archivedState.includes('BUYER_ARCHIVED'))
                                          ? `job-details-archive-supplier-${subSupplier.id}-${i}`
                                          : undefined
                                      }>
                                      <AppButton
                                        text="Request Now"
                                        onClick={() => {
                                          navigate(
                                            `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs/${
                                              selectedJob?.id
                                            }/addRelease?subSupplierId=${
                                              subSupplier.id
                                            }&templateId=${
                                              subSupplier?.releaseStatus?.unconditional?.templateId
                                            }&templateType=${
                                              subSupplier?.releaseStatus?.unconditional
                                                ?.templateType
                                            }&currentThroughDate=${moment(job.endDate)
                                              .utc()
                                              .format('MM/DD/YYYY LT')}`,
                                            {
                                              state: {
                                                subSupplierId: subSupplier.id,
                                                templateId:
                                                  subSupplier?.releaseStatus?.unconditional
                                                    ?.templateId,
                                                templateType:
                                                  subSupplier?.releaseStatus?.unconditional
                                                    ?.templateType,
                                                currentThroughDate: moment(job.endDate)
                                                  .utc()
                                                  .format('MM/DD/YYYY LT'),
                                              },
                                            }
                                          );
                                        }}
                                        isDisabled={
                                          (subSupplier?.archivedState &&
                                            subSupplier.archivedState.includes('BUYER_ARCHIVED')) ||
                                          (selectedJob?.archivedState &&
                                            selectedJob.archivedState.includes('BUYER_ARCHIVED'))
                                        }
                                      />
                                    </div>
                                  )}
                                  {width > 1400 && (
                                    <ReactTooltip
                                      id={`job-details-archive-supplier-${subSupplier.id}-${i}`}
                                      place="top"
                                      variant="dark"
                                      opacity={'100%'}
                                      style={{
                                        display: 'flex',
                                        background: '#334155',
                                        width: '200px',
                                        zIndex: '70',
                                        borderRadius: '8px',
                                        alignItems: 'center',
                                        justifyItems: 'center',
                                        fontWeight: '600',
                                      }}>
                                      <span>
                                        This supplier or job has been archived so you can no longer
                                        request or edit this release.
                                      </span>
                                    </ReactTooltip>
                                  )}
                                </div>
                              </div>
                              <div className="flex w-1/3 items-center">
                                <div className="flex px-6 py-7 GREY_200-BORDER border-r w-full h-[104px] items-center font-semibold overflow-scroll">
                                  {subSupplier?.releaseStatus?.conditional?.status !==
                                    'Not Applicable' &&
                                    subSupplier?.releaseStatus?.conditional?.status !==
                                      'Not Requested' && (
                                      <AppReleaseStatusPill
                                        status={subSupplier?.releaseStatus?.conditional?.status}
                                      />
                                    )}
                                  {subSupplier?.releaseStatus?.conditional?.status ===
                                    'Not Requested' && (
                                    <div className="flex items-center w-fit px-3 py-2 rounded-full WARNING_100-BG WARNING_500-CLR">
                                      <Warning weight="bold" />
                                      <div className="font-semibold text-xs ml-2">
                                        {subSupplier?.releaseStatus?.conditional?.status}
                                      </div>
                                    </div>
                                  )}
                                  {subSupplier?.releaseStatus?.conditional?.status ===
                                    'Not Applicable' && (
                                    <div className="flex items-center w-fit px-3 py-2 rounded-full GREY_500-CLR GREY_100-BG">
                                      <WarningOctagon weight="bold" />
                                      <div className="font-semibold text-xs ml-2">
                                        {subSupplier?.releaseStatus?.conditional?.status}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className={`flex px-6 py-3 w-full TEXT_SECONDARY-CLR items-center text-xs font-medium ${
                                    subSupplier?.releaseStatus?.conditional?.status ===
                                    'Not Requested'
                                      ? 'overflow-scroll'
                                      : ''
                                  }`}>
                                  {subSupplier?.releaseStatus?.conditional?.status !==
                                    'Not Requested' &&
                                    subSupplier?.releaseStatus?.conditional?.status !==
                                      'Not Applicable' && (
                                      <div className="flex">
                                        {width > 1400 && (
                                          <AppButton
                                            type="TERTIARY"
                                            buttonStyles={{
                                              padding: '8px',
                                              height: '35px',
                                              width: '35px',
                                              marginRight: '8px',
                                            }}
                                            icon={<Eye color={COLORS.GREY_500} size={20} />}
                                            onClick={() => {
                                              handlePreviewPdf(
                                                subSupplier?.releaseStatus?.conditional?.release
                                              );
                                            }}
                                          />
                                        )}
                                        {width > 1400 && (
                                          <AppButton
                                            type="TERTIARY"
                                            buttonStyles={{
                                              padding: '8px',
                                              height: '35px',
                                              width: '35px',
                                              marginRight: '8px',
                                            }}
                                            icon={
                                              <DownloadSimple color={COLORS.GREY_500} size={20} />
                                            }
                                            onClick={() => {
                                              handleDownloadSelectedPdf(
                                                subSupplier?.releaseStatus?.conditional?.release
                                              );
                                            }}
                                          />
                                        )}
                                        <input
                                          id={`fileInput_${subSupplier?.releaseStatus?.conditional?.release.id}`}
                                          type="file"
                                          style={{ display: 'none' }}
                                          accept="application/pdf"
                                          onChange={handleFileChange}
                                        />
                                        <div
                                          data-tooltip-id={
                                            (width > 1400 &&
                                              subSupplier?.archivedState &&
                                              subSupplier.archivedState.includes(
                                                'BUYER_ARCHIVED'
                                              )) ||
                                            (width > 1400 &&
                                              selectedJob?.archivedState &&
                                              selectedJob.archivedState.includes('BUYER_ARCHIVED'))
                                              ? `job-details-archive-supplier-${subSupplier.id}-${i}`
                                              : undefined
                                          }>
                                          <AppCustomDropdown
                                            inputFieldStyles={{ width: '35px', marginRight: '8px' }}
                                            onSelect={(val) => {
                                              setSelectedRelease(
                                                subSupplier?.releaseStatus?.conditional?.release
                                              );
                                              if (val === 'preview') {
                                                handlePreviewPdf(
                                                  subSupplier?.releaseStatus?.conditional?.release
                                                );
                                              } else if (val === 'download') {
                                                handleDownloadSelectedPdf(
                                                  subSupplier?.releaseStatus?.conditional?.release
                                                );
                                              } else {
                                                handleDropdownOption(
                                                  val,
                                                  subSupplier?.releaseStatus?.conditional?.release
                                                );
                                              }
                                            }}
                                            options={conditionalOptions}
                                            open={
                                              openCustomDropdown ===
                                              `${subSupplier?.releaseStatus?.conditional?.release?.id}_conditional`
                                            }
                                            onOpen={() =>
                                              setOpenCustomDropDown(
                                                `${subSupplier?.releaseStatus?.conditional?.release?.id}_conditional` ??
                                                  ''
                                              )
                                            }
                                            isDisabled={
                                              (width > 1400 &&
                                                subSupplier?.archivedState &&
                                                subSupplier.archivedState.includes(
                                                  'BUYER_ARCHIVED'
                                                )) ||
                                              (width > 1400 &&
                                                selectedJob?.archivedState &&
                                                selectedJob.archivedState.includes(
                                                  'BUYER_ARCHIVED'
                                                ))
                                            }
                                            onClose={() => setOpenCustomDropDown('')}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  {subSupplier?.releaseStatus?.conditional?.status ===
                                    'Not Requested' && (
                                    <AppButton
                                      text="Request Now"
                                      onClick={() => {
                                        navigate(
                                          `/buyerLienReleases/${selectedMainBuyerId}/lienReleasesJobs/${
                                            selectedJob?.id
                                          }/addRelease?subSupplierId=${
                                            subSupplier.id
                                          }&templateType=${
                                            subSupplier?.releaseStatus?.conditional?.templateType
                                          }&templateId=${
                                            subSupplier?.releaseStatus?.conditional?.templateId
                                          }&currentThroughDate=${moment(job.endDate)
                                            .utc()
                                            .format('MM/DD/YYYY LT')}`,
                                          {
                                            state: {
                                              subSupplierId: subSupplier.id,
                                              templateId:
                                                subSupplier?.releaseStatus?.conditional?.templateId,
                                              templateType:
                                                subSupplier?.releaseStatus?.conditional
                                                  ?.templateType,
                                              currentThroughDate: moment(job.endDate)
                                                .utc()
                                                .format('MM/DD/YYYY LT'),
                                            },
                                          }
                                        );
                                      }}
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="w-full h-96 flex items-center justify-between">
            <div className="w-full text-2xl text-center">
              This job has no set release requirements. Please edit the job to update release
              requirements, start and end dates, and suppliers
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuyerReleaseJobDetails;
