import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';
import { getUserSalesReceipts } from './suppliersService';

export const getSalesReceiptPdf = createAsyncThunk(
  'salesReceipt/getSalesReceiptPdf',
  async (
    {
      supplierDomainId,
      salesReceiptId,
      preview = false,
    }: { supplierDomainId: string; salesReceiptId: string; preview?: boolean },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<any>(
        `${BASE_API_URL}/salesReceiptsAPI/salesReceiptPdf/?salesReceiptId=${salesReceiptId}&supplierDomainId=${supplierDomainId}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      const file = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(file);
      if (!preview) {
        link.download = `salesReceipt-${salesReceiptId}-${+new Date()}.pdf`;
        link.click();
      }
      // //Build a URL from the file
      // const fileURL = URL.createObjectURL(file);
      // //Open the URL on new Window
      // const pdfWindow = window.open('', '_blank');
      // if (pdfWindow) {
      //   pdfWindow.location.href = fileURL;
      // }

      return { success: true, data: { pdfUrl: link.href } };
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const downloadMultiSalesReceiptPdfZip = createAsyncThunk(
  'salesReceipt/downloadMultiSalesReceiptPdfZip',
  async (
    { supplierDomainId, salesReceiptIds }: { supplierDomainId: string; salesReceiptIds: string[] },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<any>(
        `${BASE_API_URL}/salesReceiptsAPI/multiSalesReceiptPdf`,
        {
          salesReceiptIds: salesReceiptIds,
          supplierDomainId: supplierDomainId,
        },
        {
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      const file = new Blob([response.data], { type: 'application/zip' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(file);
      link.download = `zip-${salesReceiptIds.length}-${supplierDomainId}`;
      // link.download = `invoice-${invoiceId}-${+new Date()}.zip`;
      link.click();
      // //Build a URL from the file
      // const fileURL = URL.createObjectURL(file);
      // //Open the URL on new Window
      // const pdfWindow = window.open('', '_blank');
      // if (pdfWindow) {
      //   pdfWindow.location.href = fileURL;
      // }

      return { success: true, data: {} };
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const assignDriverToSalesReceipt = createAsyncThunk(
  'salesReceipt/assignDriverToSalesReceipt',
  async (
    {
      salesReceiptId,
      driverId,
      weeksLimit,
    }: { salesReceiptId: string; driverId: string; weeksLimit: number },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/salesReceiptsAPI/supplierSalesReceipts/${salesReceiptId}/driver`,
        { driverId },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      thunkApi.dispatch(getUserSalesReceipts({ weeksLimit }));
      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
