import { createSlice } from '@reduxjs/toolkit';
import {
  createSupplierNoticeTemplateExtraReducer,
  createNoticeTemplateByAdminExtraReducer,
  getNoticeTemplatesExtraReducer,
  getNoticeTemplatesByAdminExtraReducer,
} from '../reducers/noticeTemplatesReducers';
import { NoticeTemplate } from '@dill/dill-shared';

export interface NoticeTemplatesInitialState {
  loadingList: string[];
  noticeTemplates: NoticeTemplate[];
  supplierNoticeTemplates: NoticeTemplate[];
  noticeTemplatesByAdmin: NoticeTemplate[];
}

const initialState: NoticeTemplatesInitialState = {
  loadingList: [],
  noticeTemplates: [],
  supplierNoticeTemplates: [],
  noticeTemplatesByAdmin: [],
};

export const noticeTemplatesSlice = createSlice({
  name: 'noticeTemplates',
  initialState,
  reducers: {
    resetNoticeTemplatesRootState: () => initialState,
  },
  extraReducers(builder) {
    createNoticeTemplateByAdminExtraReducer(builder);
    getNoticeTemplatesExtraReducer(builder);
    createSupplierNoticeTemplateExtraReducer(builder);
    getNoticeTemplatesByAdminExtraReducer(builder);
  },
});

export const { resetNoticeTemplatesRootState } = noticeTemplatesSlice.actions;
