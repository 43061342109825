import { RadioButton } from 'phosphor-react';
import React from 'react';
import { COLORS } from '../../../utils/colors';

export const AppRadioButton = ({
  isChecked = false,
  onClick = () => {},
}: {
  isChecked?: boolean;
  onClick?: any;
}) => {
  return (
    <div
      //   className={
      //     'rounded-md h-6 w-6 p-1 flex items-center justify-center' +
      //     (isChecked ? ' PRIMARY_500-BG' : ' WHITE-BG border-2')
      //   }
      onClick={() => {
        onClick(!isChecked);
      }}>
      <RadioButton
        weight={isChecked ? 'fill' : 'thin'}
        size={20}
        color={isChecked ? COLORS.PRIMARY_500 : COLORS.GREY_500}
      />
    </div>
  );
};
