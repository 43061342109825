import { formatMoney } from '@dill/dill-shared/dist/src';

import { InvoiceReference } from '../../../constants/invoice';

interface AppInvoiceListProps {
  invoiceList: InvoiceReference[];
}

const AppInvoiceList = ({ invoiceList }: AppInvoiceListProps) => {
  return (
    <div className="flex flex-wrap gap-1">
      {invoiceList.map((invoice, i) => (
        <span
          className="GREY_100-BG GREY_500-CLR p-2 rounded-2xl text-xs text-slate-500 font-bold"
          key={`${invoice.invoiceNumber}_${i}`}>
          {`Invoice #${invoice.invoiceNumber}`}
          {invoice.amount ? ` | ${formatMoney(invoice.amount, 2)}` : ''}
        </span>
      ))}
    </div>
  );
};

export default AppInvoiceList;
