import { createSlice } from '@reduxjs/toolkit';
import {
  getSupplierBuyersExtraReducer,
  updateSupplierBuyerExtraReducer,
} from '../reducers/supplierBuyersReducers';
import { Buyer } from '@dill/dill-shared';

export interface SupplierBuyersInitialState {
  loadingList: string[];
  supplierBuyers: Buyer[];
  loadingErrorList: object[];
}

const initialState: SupplierBuyersInitialState = {
  loadingList: [],
  supplierBuyers: [],
  loadingErrorList: [],
};

export const supplierBuyersSlice = createSlice({
  name: 'supplierBuyers',
  initialState,
  reducers: {
    resetSupplierBuyersRootState: () => initialState,
  },
  extraReducers(builder) {
    getSupplierBuyersExtraReducer(builder);
    updateSupplierBuyerExtraReducer(builder);
  },
});

export const { resetSupplierBuyersRootState } = supplierBuyersSlice.actions;
