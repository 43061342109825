import type { ActionReducerMapBuilder } from '@reduxjs/toolkit';

import { InforDistSXInitialState } from '../globalSlices/inforDistSX';
import {
  deleteInforDistSXSupplierInvoice,
  getInforDistSXInvoicesService,
} from '../services/inforDistSXService';

export const getInforDistSXInvoicesExtraReducer = (
  builder: ActionReducerMapBuilder<InforDistSXInitialState>
) => {
  builder.addCase(getInforDistSXInvoicesService.fulfilled, (state, action) => {
    state.invoices = Array.from(new Set([...state.invoices, ...(action.payload?.data ?? [])]));

    if (action.payload?.invoiceType === 'IN') {
      state.numberOfInvoices = action.payload?.count ?? null;
      if (action.payload && action.payload.success && action.payload?.lastRefKey) {
        state.lastRefKey = action.payload?.lastRefKey?._fieldsProto?.id?.stringValue ?? null;
      }
    }
  });

  builder.addCase(getInforDistSXInvoicesService.rejected, (state, action) => {
    state.invoices = [];
    state.numberOfInvoices = null;
    state.lastRefKey = null;
  });

  builder.addCase(deleteInforDistSXSupplierInvoice.fulfilled, (state, action) => {
    state.invoices = state.invoices.filter((invoice) => invoice.id !== action.meta.arg.invoiceId);
  });
};
