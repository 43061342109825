import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import sageLogo from '../../../assets/images/sage-logo.png';
import { AppInputField } from '../AppInputField/AppInputField';
import { AppButton } from '../AppButton/AppButton';
import { useAppDispatch } from '../../../redux/store';
import { addSage100SupplierConnection } from '../../../redux/services/suppliersService';
import { useNavigate } from 'react-router-dom';
import { openMessageModal } from '../../../redux/globalSlices/genericSlice';

const AppSage100ConnectionModal = ({
  open,
  handleClose = () => {},
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [clientId, setClientId] = useState('');
  const [clientSecrete, setClientSecrete] = useState('');

  const [errors, setErrors] = useState({
    clientId: '',
    clientSecrete: '',
  });
  useEffect(() => {
    if (open) {
      setErrors({
        clientId: '',
        clientSecrete: '',
      });
      setClientId('');
      setClientSecrete('');
    }

    return () => {};
  }, [open]);

  const validateInputs = () => {
    const newErrors = {
      clientId: '',
      clientSecrete: '',
    };

    let isValid = true;

    if (!clientId) {
      newErrors.clientId = 'This field is required.';
      isValid = false;
    }
    if (!clientSecrete) {
      newErrors.clientSecrete = 'This field is required.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleAuthorizeButtonClick = async () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    const res = await dispatch(addSage100SupplierConnection({ clientId, clientSecrete }));
    console.log({ res });
    if (res && res.type === 'supplier/addSage100SupplierConnection/fulfilled') {
      navigate('/customers?success=true');
      navigate(0);
    } else {
      let errorMessage = '';
      if (res.type === 'supplier/addSage100SupplierConnection/rejected') {
        errorMessage = res.payload as any;
      }
      dispatch(
        openMessageModal({
          buttonText: 'Close',
          modalType: 'ERROR',
          title: 'Failed to connect to Sage 100 platform',
          subTitle: errorMessage || '',
          onClose: () => {},
        })
      );
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-80 w-[30vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col py-4 px-3">
        <div className="flex items-center flex-col">
          <img className="w-16 object-contain rounded-full" src={sageLogo} />
          <p className="text-sm font-bold">Sage 100</p>
          <p className="text-xs">Connection</p>
        </div>
        <div className="flex flex-col">
          <div className="my-1.5">
            <AppInputField
              label="Client Id"
              value={clientId}
              isRequired={true}
              errorText={errors.clientId ? errors.clientId : ''}
              onTextChange={(value) => {
                setClientId(value);
              }}
            />
          </div>
          <div className="my-1.5">
            <AppInputField
              label="Client Secrete"
              value={clientSecrete}
              isRequired={true}
              errorText={errors.clientSecrete ? errors.clientSecrete : ''}
              onTextChange={(value) => {
                setClientSecrete(value);
              }}
            />
          </div>

          <div className="flex justify-between w-full mt-4">
            <AppButton
              buttonStyles={{ width: '100px' }}
              text="Cancel"
              type="TERTIARY"
              onClick={handleClose}
            />
            <AppButton
              buttonStyles={{ width: '100px' }}
              text="Authorize"
              onClick={handleAuthorizeButtonClick}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AppSage100ConnectionModal;
