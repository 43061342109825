import { Modal } from '@mui/material';
import React from 'react';
import successChecksCircle from '../../../assets/svg/success_checks_circle.svg';
import { AppButton } from '../AppButton/AppButton';
import './AppSuccessModal.scss';
import { COLORS, ColorType } from '../../../utils/colors';
import { Warning } from 'phosphor-react';

export const AppSuccessModal = ({
  title = '',
  subTitle = '',
  highlightedText = '',
  buttonText = 'Back',
  modalImage = successChecksCircle,
  open,
  handleClose,
  isSecondButton = false,
  onSecondButtonClick,
  secondButtonText = '',
  type = '',
  handleCloseClickOutsideModal,
}: {
  title?: string;
  subTitle?: string;
  highlightedText?: string;
  isSecondButton?: boolean;
  buttonText?: string;
  secondButtonText?: string;
  modalImage?: any;
  open: boolean;
  handleClose?: () => void;
  onSecondButtonClick?: () => void;
  type?: string;
  handleCloseClickOutsideModal?: () => void;
}) => {
  return (
    <Modal
      open={open}
      onClose={handleCloseClickOutsideModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="success-modal-content min-h-80 w-[412px]">
        {type !== 'warning' && <img src={modalImage} alt="" />}
        {type === 'warning' && (
          <div className="flex h-20 w-20 WARNING_100-BG WARNING_500-CLR justify-center items-center rounded-full">
            <Warning size={45} weight="bold" />
          </div>
        )}
        <p className="mt-4 font-bold text-center text-2xl mb-1">{title}</p>
        <p className="text-sm mb-2 TEXT_SECONDARY-CLR text-center">{subTitle}</p>
        {highlightedText && (
          <p className="PRIMARY_500-CLR text-sm font-medium">{highlightedText}</p>
        )}
        <div className="action-buttons">
          <AppButton
            text={buttonText}
            type="TERTIARY"
            buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
            onClick={handleClose}
          />
          {isSecondButton && (
            <AppButton
              text={secondButtonText}
              buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
              onClick={onSecondButtonClick}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};
