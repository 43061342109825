import React from 'react';
import './AppInputField.scss';
import { twMerge } from 'tailwind-merge';

export const AppInputField = ({
  id,
  inputType = 'text',
  placeholder = '',
  suffix = '',
  prefix = '',
  errorText = '',
  label = '',
  labelTextClassName = '',
  isRequired = false,
  icon,
  inputFieldStyles = {},
  isDisabled = false,
  onTextChange = (value) => {},
  onBlur = (e) => {},
  onFocus = (e) => {},
  value,
  minValue = 0,
}: {
  icon?: Icon,
  id?: string,
  value?: any,
  label?: string,
  labelTextClassName?: string,
  isRequired?: boolean,
  placeholder?: string,
  suffix?: string,
  prefix?: string,
  isDisabled?: boolean,
  inputFieldStyles?: any,
  inputType?: string,
  errorText?: string,
  minValue?: number,
  onTextChange?: (props: any) => void,
  onBlur?: (props: any) => void,
  onFocus?: (props: any) => void,
}) => {
  return (
    <div className="w-full flex flex-col">
      {(label || isRequired) && (
        <label
          htmlFor={id}
          className={twMerge(
            'text-xs mb-0.5 text-left  whitespace-nowrap font-medium',
            labelTextClassName
          )}>
          {isRequired && <span className="text-red-500">*</span>}
          {label}
        </label>
      )}

      <div
        className={twMerge('input-wrapper', 'h-fit')}
        style={{
          borderColor: errorText ? '#F04438' : '#E4EAF1',
          ...inputFieldStyles,
          ...(isDisabled ? { backgroundColor: '#E4EAF1' } : {}),
        }}>
        <div className={'input-section '}>
          {icon && <div className="icon">{icon}</div>}
          {prefix && <p className="whitespace-nowrap text-slate-500 mr-2 ">{prefix}</p>}
          {inputType === 'textarea' && (
            <textarea
              className="w-full text-sm resize-none"
              rows={5}
              id={id}
              disabled={isDisabled}
              value={value}
              onChange={(e) => {
                onTextChange(e.target.value);
              }}
              onBlur={onBlur}
              onFocus={onFocus}
            />
          )}
          {inputType !== 'textarea' && (
            <input
              type={inputType}
              id={id}
              disabled={isDisabled}
              wrap="soft"
              rows="2"
              placeholder={placeholder}
              className={'field'}
              value={value}
              onChange={(e) => {
                const val = e.target.value;
                onTextChange(val);
              }}
              onBlur={onBlur}
              onFocus={onFocus}
              {...(inputType === 'number' ? { min: minValue } : {})}
            />
          )}
          {suffix && <p className="whitespace-nowrap text-slate-500">{suffix}</p>}
        </div>
        {errorText && <p className="error-text">{errorText}</p>}
      </div>
    </div>
  );
};
