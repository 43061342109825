import { Modal } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { X } from 'react-feather';
import {
  NoticeTemplateField,
  FromFieldTypeNoticeTemplate,
  FieldTypeNoticeTemplate,
  NoticeTemplateFieldCreate,
  PDFFieldNoticeTemplate,
  WhoPopulatesFieldNoticeTemplate,
} from '@dill/dill-shared';
import { COLORS } from '../../../../utils/colors';
import { AppInputField } from '../../../general/AppInputField/AppInputField';
import { AppDropDown } from '../../../general/AppDropDown/AppDropDown';
import { AppRadioButton } from '../../../general/AppRadioButton/AppRadioButton';
import AppDatePicker from '../../../general/AppDatePicker/AppDatePicker';
import { AppButton } from '../../../general/AppButton/AppButton';
import { twMerge } from 'tailwind-merge';
import moment from 'moment';
import { Plus } from 'phosphor-react';
import { sentenceToCamelCase } from '../../../../utils/helpers';
import { AppCheckBox } from '../../../general/AppCheckBox/AppCheckBox';

const AddNoticeEditFieldModal = ({
  open,
  handleClose,
  selectedFieldPosition,
  fieldsList,
  onAddField,
  onEditField,
}: {
  open: boolean;
  selectedFieldPosition?: number | null;
  fieldsList: NoticeTemplateField[];
  handleClose: () => void;
  onAddField: (field: NoticeTemplateFieldCreate) => void;
  onEditField: (field: NoticeTemplateFieldCreate, position: number) => void;
}) => {
  const [fieldName, setFieldName] = useState('');
  const [delimiter, setDelimiter] = useState('');
  const [fieldPosition, setFieldPosition] = useState<number | null>(1);
  const [minValue, setMinValue] = useState<number | null>(null);
  const [maxValue, setMaxValue] = useState<number | null>(null);
  const [fromFieldType, setFromFieldType] = useState<FromFieldTypeNoticeTemplate>('NEW_FIELD');
  const [fieldType, setFieldType] = useState<FieldTypeNoticeTemplate>('TEXT');
  const [canPopulatePDF, setCanPopulatePDF] = useState(true);
  const [whoPopulatesField, setWhoPopulatesField] =
    useState<WhoPopulatesFieldNoticeTemplate | null>('SUPPLIER');
  const [isFieldRequired, setIsFieldRequired] = useState<boolean>(true);
  const [suffix, setSuffix] = useState('');
  const [prefix, setPrefix] = useState('');
  const [defaultValue, setDefaultValue] = useState<string | number>('');
  const [fromFieldDataKeys, setFromFieldDataKeys] = useState<string[]>([]);
  const [fromFieldDataKeysDelimiters, setFromFieldDataDelimiters] = useState<string[]>([]);
  const [disabledFields, setDisabledFields] = useState<string[]>([]);
  const [fromFieldDataKey, setFromFieldDataKey] = useState('');
  const [currentFieldDataKeysOptions, setCurrentFieldDataKeysOptions] = useState<string[]>([]);
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [dateSplitFormats, setDateSplitFormats] = useState<string[]>([]);
  const [fieldNumberOfAppearanceInPDF, setFieldNumberOfAppearanceInPDF] = useState<number | null>(
    1
  );

  const supplierJobPossibleKeys = useMemo(
    () => [
      'name',
      'jobNumber',
      'jobAddress',
      'jobCity',
      'jobState',
      'jobZipCode',
      'owner',
      'ownerAddress',
      'ownerCity',
      'ownerState',
      'ownerZipCode',
      'GC',
      'GCAddress',
      'GCCity',
      'GCState',
      'GCZipCode',
      'lender',
      'lenderAddress',
      'lenderCity',
      'lenderState',
      'lenderZipCode',
      'buyer.name',
      'buyer.address',
      'buyer.city',
      'buyer.state',
      'buyer.zipCode',
    ],
    []
  );
  const userPossibleKeys = useMemo(
    () => ['firstName', 'lastName', 'phoneNumber', 'title', 'email'],
    []
  );
  const userSupplierPossibleKeys = useMemo(
    () => [
      'userSupplier.name',
      'userSupplier.address',
      'userSupplier.city',
      'userSupplier.state',
      'userSupplier.zipCode',
    ],
    []
  );
  const possibleDateFormats = useMemo(
    () => [
      'MM/DD/YYYY',
      'MMMM, YYYY',
      'MMMM YYYY',
      'MMMM, YY',
      'MMMM YY',
      'MM/YYYY',
      'MMM YYYY',
      'MMMM D',
      'MMM D',
      'YYYY',
      'YY',
      'MMMM',
      'MMM',
      'MM',
      'Do',
      'DD',
      'D',
    ],
    []
  );

  useEffect(() => {
    if (fromFieldType === 'SUPPLIER_JOB_FIELD') {
      setCurrentFieldDataKeysOptions(supplierJobPossibleKeys);
    } else if (fromFieldType === 'USER_FIELD') {
      setCurrentFieldDataKeysOptions(userPossibleKeys);
    } else if (fromFieldType === 'USER_SUPPLIER_FIELD') {
      setCurrentFieldDataKeysOptions(userSupplierPossibleKeys);
    }

    return () => {};
  }, [fromFieldType]);

  const resetForm = () => {
    setFieldName('');
    setFromFieldType('NEW_FIELD');
    setFieldType('TEXT');
    setCanPopulatePDF(true);
    setIsFieldRequired(true);
    setCanPopulatePDF(true);
    setSuffix('');
    setPrefix('');
    setDefaultValue('');
    setFromFieldDataKeys([]);
    setFromFieldDataDelimiters([]);
    setFromFieldDataKey('');
    setCurrentFieldDataKeysOptions([]);
    setErrorFields([]);
    setFieldNumberOfAppearanceInPDF(1);
    if (fieldsList.length > 0 && !selectedFieldPosition) {
      setFieldPosition(fieldsList.length + 1);
    }
    setWhoPopulatesField('SUPPLIER');
    setDisabledFields([]);
    setDateSplitFormats([]);
    setDelimiter('');
  };

  useEffect(() => {
    if (open) {
      if (selectedFieldPosition === null) {
        resetForm();
      }
      if (fieldsList && selectedFieldPosition !== null) {
        const selectedFieldDetails = fieldsList.find((filed, i) => i === selectedFieldPosition);
        if (selectedFieldDetails) {
          setFieldName(selectedFieldDetails.fieldName);
          setFromFieldType(selectedFieldDetails.fromFieldType);

          setCanPopulatePDF(selectedFieldDetails.canPopulatePDF);
          if (selectedFieldDetails.fieldType) {
            setFieldType(selectedFieldDetails.fieldType);
          }
          if (selectedFieldDetails.suffix) {
            setSuffix(selectedFieldDetails.suffix);
          }
          if (selectedFieldDetails.prefix) {
            setPrefix(selectedFieldDetails.prefix);
          }
          if (selectedFieldDetails.defaultValue) {
            setDefaultValue(selectedFieldDetails.defaultValue);
          }
          if (selectedFieldDetails.fromFieldDataKeys) {
            setFromFieldDataKeys(selectedFieldDetails.fromFieldDataKeys);
            if (!selectedFieldDetails.fromFieldDataKeysDelimiters) {
              setFromFieldDataDelimiters(selectedFieldDetails.fromFieldDataKeys.map(() => ''));
            }
          }
          if (selectedFieldDetails.fromFieldDataKeysDelimiters) {
            setFromFieldDataDelimiters(selectedFieldDetails.fromFieldDataKeysDelimiters);
          }
          if (selectedFieldDetails.whoPopulatesField) {
            setWhoPopulatesField(selectedFieldDetails.whoPopulatesField);
          }
          if (selectedFieldDetails.dateSplitFormats) {
            setDateSplitFormats(selectedFieldDetails.dateSplitFormats);
          } else {
            setDateSplitFormats([]);
          }
          setFromFieldDataKey('');

          setCurrentFieldDataKeysOptions([]);
          setErrorFields([]);
          if (selectedFieldDetails.pdfFields) {
            setFieldNumberOfAppearanceInPDF(
              selectedFieldDetails.pdfFields.reduce((prev: string[], curr) => {
                if (curr && (!curr.id.includes('Format') || curr.id.endsWith('Format1'))) {
                  return [...prev, curr.id];
                }
                return [...prev];
              }, []).length
            );
          }
          if (selectedFieldDetails.position) {
            setFieldPosition(selectedFieldDetails.position);
          }
          if (selectedFieldDetails.minValue) {
            setMinValue(selectedFieldDetails.minValue);
          }
          if (selectedFieldDetails.maxValue) {
            setMaxValue(selectedFieldDetails.maxValue);
          }

          if (selectedFieldDetails.fieldAdjustmentForm === 'ONLY_PDF_FIELDS') {
            setDisabledFields([
              'fieldName',
              'whoPopulatesField',
              'fromFieldType',
              'fromFieldDataKeys',
              'fieldType',
              'isFieldRequired',
              'canPopulatePDF',
              'defaultValue',
              'fieldPosition',
              '',
              '',
              '',
            ]);
          } else {
            setDisabledFields([]);
          }
        }
      }
    }

    return () => {};
  }, [open]);

  const handleAddNewField = () => {
    let details: any = {};
    const errors = [];
    if (fromFieldType) {
      details = { ...details, fromFieldType };
    } else {
      errors.push('fromFieldType');
    }
    if (fieldName) {
      if (selectedFieldPosition !== null && selectedFieldPosition !== undefined) {
        details = { ...details, fieldName };
      } else {
        let updateFieldName = fieldName;
        if (fromFieldType === 'SIGNATURE_FIELD') {
          updateFieldName = `Signature ${updateFieldName}`;
        } else if (fieldType === 'CHECK_BOX') {
          updateFieldName = `Check ${updateFieldName}`;
        }
        const check = fieldsList.filter(
          (fieldItem) =>
            fieldItem.id.toLowerCase() === sentenceToCamelCase(updateFieldName).toLowerCase()
        );
        if (check.length > 0) {
          errors.push('fieldNameExits');
        } else {
          details = { ...details, fieldName };
        }
      }
    } else if (!fieldName) {
      errors.push('fieldName');
    }

    if (fieldType) {
      details = { ...details, fieldType };
    } else if (!fieldType && fromFieldType === 'NEW_FIELD') {
      errors.push('fieldType');
    }
    if (whoPopulatesField) {
      details = { ...details, whoPopulatesField };
    } else if (!whoPopulatesField) {
      errors.push('whoPopulatesField');
    }

    if (fromFieldDataKeys.length > 0) {
      details = { ...details, fromFieldDataKeys };
      details = { ...details, fromFieldDataKeysDelimiters };
    } else if (
      fromFieldDataKeys.length === 0 &&
      fromFieldType !== 'NEW_FIELD' &&
      fromFieldType !== 'SIGNATURE_FIELD' &&
      fieldName !== 'signature'
    ) {
      errors.push('fromFieldDataKeys');
    }
    if (fieldNumberOfAppearanceInPDF === null && canPopulatePDF) {
      errors.push('fieldNumberOfAppearanceInPDF');
    } else if (
      fieldNumberOfAppearanceInPDF !== null &&
      fieldNumberOfAppearanceInPDF > 0 &&
      canPopulatePDF &&
      fieldName
    ) {
      if (fieldType === 'DATE') {
        let splitFormats = dateSplitFormats;
        if (dateSplitFormats.length === 0) {
          splitFormats = [possibleDateFormats[0]];
        }
        details = { ...details, dateSplitFormats: splitFormats };

        const pdfFieldsData: PDFFieldNoticeTemplate[] = [];
        [...new Array(fieldNumberOfAppearanceInPDF).fill('')].forEach((_item, i) => {
          dateSplitFormats.forEach((_format, formatIndex) => {
            pdfFieldsData.push({
              id: sentenceToCamelCase(fieldName + (i + 1)) + 'Format' + (formatIndex + 1),
              isMarkedInPdf: false,
            });
          });
        });
        details = {
          ...details,
          pdfFields: pdfFieldsData,
        };
      } else if (fieldType === 'CHECK_BOX') {
        const signatureField = `Check ${fieldName}`;
        details = {
          ...details,
          pdfFields: [...new Array(fieldNumberOfAppearanceInPDF).fill('')].map((item, i) => {
            return { id: sentenceToCamelCase(signatureField + (i + 1)), isMarkedInPdf: false };
          }),
        };
      } else if (fromFieldType === 'SIGNATURE_FIELD') {
        const signatureField = `Signature ${fieldName}`;
        details = {
          ...details,
          pdfFields: [...new Array(fieldNumberOfAppearanceInPDF).fill('')].map((item, i) => {
            return { id: sentenceToCamelCase(signatureField + (i + 1)), isMarkedInPdf: false };
          }),
        };
      } else {
        details = {
          ...details,
          pdfFields: [...new Array(fieldNumberOfAppearanceInPDF).fill('')].map((item, i) => {
            return { id: sentenceToCamelCase(fieldName + (i + 1)), isMarkedInPdf: false };
          }),
        };
      }
    } else {
      details = {
        ...details,
        pdfFields: [],
      };
    }
    if (canPopulatePDF !== undefined) {
      details = { ...details, canPopulatePDF };
    }
    if (suffix) {
      details = { ...details, suffix };
    }
    if (prefix) {
      details = { ...details, prefix };
    }
    if (defaultValue) {
      details = { ...details, defaultValue };
    }
    if (minValue !== null) {
      details = { ...details, minValue };
    }
    if (maxValue !== null) {
      details = { ...details, maxValue };
    }
    if (fieldPosition !== undefined && fieldPosition !== null) {
      details = { ...details, position: fieldPosition };
    }

    if (errors.length > 0) {
      setErrorFields(errors);
      return;
    }
    setErrorFields([]);

    let updateFieldName = fieldName;
    if (fromFieldType === 'SIGNATURE_FIELD') {
      updateFieldName = `Signature ${updateFieldName}`;
    } else if (fieldType === 'CHECK_BOX') {
      updateFieldName = `Check ${updateFieldName}`;
    }
    if (selectedFieldPosition !== null && selectedFieldPosition !== undefined) {
      const edited: NoticeTemplateField = {
        id: sentenceToCamelCase(updateFieldName),
        fieldName,
        fromFieldType,
        delimiter: delimiter,
        canPopulatePDF,
        isFieldRequired,
        pdfFields: details.pdfFields,
        fieldAdjustmentForm: fieldsList[selectedFieldPosition].fieldAdjustmentForm,
        ...details,
      };
      onEditField(edited, selectedFieldPosition);
    } else {
      const newField: NoticeTemplateField = {
        id: sentenceToCamelCase(updateFieldName),
        fieldName,
        fromFieldType,
        canPopulatePDF,
        delimiter: delimiter,
        isFieldRequired,
        pdfFields: details.pdfFields,
        fieldAdjustmentForm: 'EDITABLE',
        ...details,
      };
      onAddField(newField);
    }
  };
  const fieldFrom = useMemo(() => {
    if (fieldsList) {
      const check = fieldsList.filter((fieldItem) => fieldItem.fromFieldType === 'SIGNATURE_FIELD');
      if (check.length > 0 && selectedFieldPosition === null) {
        return ['NEW_FIELD', 'SUPPLIER_JOB_FIELD', 'USER_FIELD', 'USER_SUPPLIER_FIELD'] as const;
      }
    }
    return [
      'NEW_FIELD',
      'SUPPLIER_JOB_FIELD',
      'USER_FIELD',
      'USER_SUPPLIER_FIELD',
      'SIGNATURE_FIELD',
    ] as const;
  }, [fieldsList, open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div
        style={{ maxHeight: '90vh' }}
        className="flex min-h-80  w-4/12 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col pb-2 px-4">
        <div className="sticky top-0 z-10 bg-white py-2">
          <X
            className="absolute right-2 top-2 cursor-pointer"
            color={COLORS.GREY_500}
            onClick={handleClose}
          />
          <h3 className="text-lg font-bold ">
            {selectedFieldPosition !== null ? 'Edit Field' : 'Add Field'}
          </h3>
        </div>

        <div
          className={twMerge(
            'flex flex-col ',
            fromFieldType === 'NEW_FIELD' ? 'overflow-y-scroll' : ''
          )}>
          <div className="flex flex-col my-3">
            <div className="my-2">
              <AppInputField
                label="Field Name"
                isRequired={true}
                isDisabled={disabledFields.includes('fieldName')}
                errorText={
                  errorFields.includes('fieldName')
                    ? 'Please add field name'
                    : errorFields.includes('fieldNameExits')
                    ? 'Field name already exists'
                    : ''
                }
                value={fieldName}
                onTextChange={(text) => {
                  setFieldName(text);
                }}
              />
            </div>
            <div className="my-2">
              <AppDropDown
                label="Who populates this field"
                isRequired={true}
                isDisabled={disabledFields.includes('whoPopulatesField')}
                errorText={errorFields.includes('whoPopulatesField') ? 'Please select' : ''}
                value={whoPopulatesField || ''}
                items={[
                  ...['SUPPLIER'].map((value) => {
                    return {
                      label: value.replaceAll('_', ' '),
                      value,
                    };
                  }),
                ]}
                onSelectChange={(item) => {
                  if (item?.value) {
                    setWhoPopulatesField(item?.value);
                  }
                }}
              />
            </div>
            {canPopulatePDF && (
              <div className="my-2 flex flex-col">
                <AppInputField
                  label="How many times does this field appear in pdf"
                  inputType="number"
                  isRequired={true}
                  isDisabled={disabledFields.includes('fieldNumberOfAppearanceInPDF')}
                  errorText={errorFields.includes('fieldNumberOfAppearanceInPDF') ? 'Required' : ''}
                  value={fieldNumberOfAppearanceInPDF || ''}
                  onTextChange={(text) => {
                    if (text) {
                      setFieldNumberOfAppearanceInPDF(parseInt(text));
                    } else {
                      setFieldNumberOfAppearanceInPDF(null);
                    }
                  }}
                />
              </div>
            )}

            <div className="my-2">
              <AppDropDown
                label="Field From"
                isRequired={true}
                isDisabled={disabledFields.includes('fromFieldType')}
                errorText={errorFields.includes('fromFieldType') ? 'Please select' : ''}
                value={fromFieldType}
                items={[
                  ...fieldFrom.map((value) => {
                    return {
                      label: value.replaceAll('_', ' '),
                      value,
                    };
                  }),
                ]}
                onSelectChange={(item) => {
                  if (item?.value) {
                    setFromFieldType(item?.value);
                    setFromFieldDataKeys([]);
                    setFromFieldDataKey('');
                    if (item?.value !== 'NEW_FIELD') {
                      setDefaultValue('');
                      setPrefix('');
                      setSuffix('');
                      setIsFieldRequired(true);
                      setCanPopulatePDF(true);
                    } else if (item?.value === 'NEW_FIELD') {
                      // setFromFieldDataKeys([]);
                    }
                  }
                }}
              />
            </div>
            {fromFieldType !== 'NEW_FIELD' && fromFieldType !== 'SIGNATURE_FIELD' && (
              <>
                <AppDropDown
                  label="From Field Data Key"
                  isRequired={true}
                  isDisabled={disabledFields.includes('fromFieldDataKeys')}
                  errorText={errorFields.includes('fromFieldDataKeys') ? 'Please select' : ''}
                  value={fromFieldDataKey}
                  items={[
                    ...currentFieldDataKeysOptions.map((value) => {
                      return {
                        label: value.replaceAll('_', ' '),
                        value,
                      };
                    }),
                  ]}
                  onSelectChange={(item) => {
                    if (item?.value) {
                      // setFromFieldDataKey(item?.value);
                      setFromFieldDataKeys([...new Set([...fromFieldDataKeys, item?.value])]);
                      setFromFieldDataDelimiters([...fromFieldDataKeysDelimiters, '']);
                      setFromFieldDataKey('');
                    }
                  }}
                />

                <p className="text-2xs mt-1">You can add delimiters</p>
                <div className="flex  flex-wrap ">
                  {fromFieldDataKeys.map((item, i) => {
                    return (
                      <div key={i + 'pp'} className="flex items-end">
                        <div
                          className="w-fit px-2 py-1 border border-slate-600 rounded-lg flex items-center justify-center m-1"
                          onClick={() => {
                            setFromFieldDataKeys(fromFieldDataKeys.filter((val, j) => j !== i));
                            setFromFieldDataDelimiters(
                              fromFieldDataKeysDelimiters.filter((val, j) => j !== i)
                            );
                          }}>
                          <p className="text-xs m-0 mr-1 ">{item}</p>
                          <X size={14} className="cursor-pointer" />
                        </div>
                        <div className="w-6">
                          <AppInputField
                            label=""
                            inputFieldStyles={{ height: '25px', padding: '5px 4px' }}
                            isDisabled={disabledFields.includes('delimiter')}
                            value={fromFieldDataKeysDelimiters[i]}
                            onTextChange={(text) => {
                              // setDelimiter(text);
                              setFromFieldDataDelimiters([
                                ...fromFieldDataKeysDelimiters.map((val, j) => {
                                  if (i === j) {
                                    return text;
                                  }
                                  return val;
                                }),
                              ]);
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {fromFieldType === 'NEW_FIELD' && (
              <>
                <div className="my-2">
                  <AppDropDown
                    label="Field Type"
                    isRequired={true}
                    isDisabled={disabledFields.includes('fieldType')}
                    errorText={errorFields.includes('fieldType') ? 'Please select field type' : ''}
                    value={fieldType || ''}
                    items={[
                      ...['TEXT', 'NUMBER', 'CURRENCY', 'DATE', 'TEXT_AREA', 'CHECK_BOX'].map(
                        (value) => {
                          return {
                            label: value.replaceAll('_', ' '),
                            value,
                          };
                        }
                      ),
                    ]}
                    onSelectChange={(item) => {
                      if (item?.value) {
                        setFieldType(item?.value);
                        if (item?.value !== 'TEXT' && item?.value !== 'NUMBER') {
                          setSuffix('');
                          setPrefix('');
                        }
                        if (item?.value === 'DATE') {
                          setDateSplitFormats([possibleDateFormats[0]]);
                        }
                      }
                    }}
                  />
                </div>
                {(fieldType === 'TEXT' || fieldType === 'NUMBER' || fieldType === 'CURRENCY') && (
                  <>
                    <div className="my-2">
                      <AppInputField
                        label="Prefix"
                        isDisabled={disabledFields.includes('prefix')}
                        value={prefix}
                        onTextChange={(text) => {
                          setPrefix(text);
                        }}
                      />
                    </div>
                    <div className="my-2">
                      <AppInputField
                        label="Suffix"
                        isDisabled={disabledFields.includes('suffix')}
                        value={suffix}
                        onTextChange={(text) => {
                          setSuffix(text);
                        }}
                      />
                    </div>
                  </>
                )}
                {fieldType !== 'DATE' && fieldType !== 'CHECK_BOX' && (
                  <div className="my-2">
                    <AppInputField
                      label="Default Value"
                      inputType={
                        fieldType === 'CURRENCY' || fieldType === 'NUMBER' ? 'number' : 'text'
                      }
                      value={defaultValue}
                      isDisabled={disabledFields.includes('defaultValue')}
                      onTextChange={(text) => {
                        setDefaultValue(text);
                      }}
                    />
                  </div>
                )}
                {fieldType === 'DATE' && (
                  <div className="my-2 flex flex-col">
                    <div className="flex items-center">
                      <p className="text-sm mr-3 ">Pdf Field Date format</p>
                      <AppButton
                        text="Add Date Split"
                        buttonStyles={{ padding: '8px', height: '25px' }}
                        icon={<Plus color={COLORS.WHITE} />}
                        iconPosition="RIGHT"
                        onClick={() => {
                          setDateSplitFormats([...dateSplitFormats, possibleDateFormats[0]]);
                        }}
                      />
                    </div>

                    <div className="flex flex-wrap mt-2">
                      {dateSplitFormats.map((dateFormat, index) => {
                        return (
                          <div key={index + 'ii'} className="mx-2 my-1 flex items-center">
                            <AppDropDown
                              label=""
                              value={dateFormat}
                              items={[
                                ...possibleDateFormats.map((value) => {
                                  let desc = '';
                                  const meta: any = {
                                    YY: 'Year',
                                    Do: 'Date',
                                    DD: 'Date',
                                    MM: 'Month',
                                  };
                                  if (meta[value]) {
                                    desc = ` --- ${meta[value]}`;
                                  }
                                  return {
                                    label: (moment(new Date()).format(value) || '') + desc,
                                    value,
                                  };
                                }),
                              ]}
                              onSelectChange={(item) => {
                                if (item?.value) {
                                  // setFromFieldDataKey(item?.value);
                                  setDateSplitFormats([
                                    ...dateSplitFormats.map((format, formatIndex) => {
                                      if (formatIndex === index) {
                                        return item.value;
                                      }
                                      return format;
                                    }),
                                  ]);
                                }
                              }}
                            />
                            {dateSplitFormats.length > 1 && (
                              <X
                                size={14}
                                className="cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDateSplitFormats(
                                    dateSplitFormats.filter(
                                      (format, formatIndex) => formatIndex !== index
                                    )
                                  );
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {(fieldType === 'CURRENCY' || fieldType === 'NUMBER') && (
                  <div className="my-2 flex">
                    <div className=" mr-1 w-full">
                      <AppInputField
                        label="Min Value"
                        value={minValue}
                        inputType="number"
                        isDisabled={disabledFields.includes('minValue')}
                        onBlur={(e) => {
                          const val = e.target.value;
                          setMinValue(parseInt(val));
                        }}
                        onTextChange={(text) => {
                          setMinValue(text);
                        }}
                      />
                    </div>

                    <div className="ml-1 w-full">
                      <AppInputField
                        label="Max Value"
                        value={maxValue}
                        inputType="number"
                        isDisabled={disabledFields.includes('maxValue')}
                        onBlur={(e) => {
                          const val = e.target.value;
                          setMaxValue(parseInt(val));
                        }}
                        onTextChange={(text) => {
                          setMaxValue(text);
                        }}
                      />
                    </div>
                  </div>
                )}

                {!disabledFields.includes('isFieldRequired') && (
                  <div className="my-2 flex items-center">
                    <p
                      className="text-sm mr-1 cursor-pointer"
                      onClick={() => {
                        setIsFieldRequired(!isFieldRequired);
                      }}>
                      Is Field Required
                    </p>
                    <AppRadioButton
                      isChecked={isFieldRequired}
                      onClick={(val: boolean) => {
                        setIsFieldRequired(val);
                      }}
                    />
                  </div>
                )}
                {!disabledFields.includes('canPopulatePDF') && (
                  <div className="my-2 flex items-center">
                    <p
                      className="text-sm mr-1 cursor-pointer"
                      onClick={() => {
                        setCanPopulatePDF(!canPopulatePDF);
                      }}>
                      Does field populate pdf
                    </p>
                    <AppRadioButton
                      isChecked={canPopulatePDF}
                      onClick={(val: boolean) => {
                        setCanPopulatePDF(val);
                      }}
                    />
                  </div>
                )}
                <div className="my-2">
                  <AppInputField
                    label="Field Position"
                    inputType="number"
                    isDisabled={disabledFields.includes('fieldPosition')}
                    value={fieldPosition}
                    onTextChange={(text) => {
                      if (text) {
                        setFieldPosition(parseInt(text));
                      } else {
                        setFieldPosition(null);
                      }
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col  mt-2 -z-10  bg-white">
          {fromFieldType === 'NEW_FIELD' && (
            <div className="mb-2">
              <p className="text-sm font-bold">Preview</p>

              <div className="border border-blue-500  px-2 py-4">
                {(fieldType === 'TEXT' ||
                  fieldType === 'NUMBER' ||
                  fieldType === 'CURRENCY' ||
                  fieldType === 'TEXT_AREA') && (
                  <AppInputField
                    label={fieldName}
                    inputType={
                      fieldType === 'TEXT_AREA'
                        ? 'textarea'
                        : fieldType === 'NUMBER'
                        ? 'number'
                        : 'text'
                    }
                    value={defaultValue}
                    isRequired={isFieldRequired}
                    suffix={suffix}
                    prefix={prefix}
                    onTextChange={(text) => {
                      setDefaultValue(text);
                    }}
                  />
                )}
                {fieldType === 'DATE' && (
                  <AppDatePicker
                    label={fieldName}
                    selectedDate={new Date()}
                    isRequired={isFieldRequired}
                    onDateSelected={(date) => {}}
                  />
                )}
                {fieldType === 'CHECK_BOX' && (
                  <div className="my-1 flex items-center">
                    <AppCheckBox />
                    <div className="ml-2 text-sm">{fieldName}</div>
                  </div>
                )}
              </div>
            </div>
          )}
          <AppButton
            text={
              selectedFieldPosition !== null && selectedFieldPosition !== undefined
                ? 'Edit field'
                : 'Add field'
            }
            onClick={() => {
              handleAddNewField();
            }}
          />
          {errorFields.length > 0 && (
            <p className="text-xs text-red-600 text-center">There are some errors</p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddNoticeEditFieldModal;
