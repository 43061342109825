import React, { useMemo, useState, useEffect } from 'react';
import { Plus, Download, Search } from 'react-feather';
import { COLORS } from '../../../../utils/colors';
import { AppButton } from '../../../general/AppButton/AppButton';
import { AppInputField } from '../../../general/AppInputField/AppInputField';
import PreliminaryNoticesTable from '../PreliminaryNoticesTable/PreliminaryNoticesTable';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../../redux/store';
import {
  LienApiResponse,
  LienStatus,
  UserLinkApiResponse,
  GroupedCSVRelease,
  PreliminaryNoticeApiResponse,
} from '@dill/dill-shared';
import { downloadPDFsAsZip } from '../../../../utils/helpers';
import { AppDropDown2 } from '../../../general/AppDropDown2/AppDropDown2';
import { twMerge } from 'tailwind-merge';
import { CheckCircle, Timer, Warning, ClockClockwise, CaretLeft, CaretRight } from 'phosphor-react';
import { AppErrorPage } from '../../../general/AppErrorPage/AppErrorPage';
import AppGroupedDropdown from '../../../general/AppGroupedDropdown/AppGroupedDropdown';
import moment from 'moment';
import AppReleaseStatusPill from '../../../general/AppReleaseStatusPill/AppReleaseStatusPill';
import AppReleaseOptionsDropdown from '../../../general/AppReleaseOptionsDropdown/AppReleaseOptionsDropdown';
import { AppForwardReleaseModal } from '../../../general/AppForwardReleaseModal/AppForwardReleaseModal';
import { getJobs } from '../../../../redux/services/jobsService';
import { forwardRelease, getLienBuyerReleases } from '../../../../redux/services/liensService';
import { AppSuccessModal } from '../../../general/AppSuccessModal/AppSuccessModal';
import successChecksCircle from '../../../../assets/svg/success_checks_circle.svg';
import errorCircle from '../../../../assets/svg/error_circle.svg';
import { AppCheckBox } from '../../../general/AppCheckBox/AppCheckBox';
import { getSubSuppliers } from '../../../../redux/services/subSuppliersService';
import { getLienTemplates } from '../../../../redux/services/leinTemplatesService';
import { AppPdfPreviewModal } from '../../../general/AppPdfPreviewModal/AppPdfPreviewModal';
import { openMessageModal } from '../../../../redux/globalSlices/genericSlice';
import { AppUploadCSVInvoicesModal } from '../../../general/AppUploadCSVInvoicesModal/AppUploadCSVInvoicesModal';

const PreliminaryNotices = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');
  const [statusFilterOption, setStatusFilterOption] = useState<string[] | null>(null);
  const { preliminaryNotices, loadingErrorList, selectedPreliminaryNotices } = useAppSelector(
    (state) => state.preliminaryNotices
  );
  const { jobs } = useAppSelector((state) => state.jobs);
  const { subSuppliers } = useAppSelector((state) => state.subSuppliers);
  const [filteredBuyerReleases, setFilteredBuyerReleases] = useState<LienApiResponse[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const [openForwardingModal, setOpenForwardingModal] = useState<boolean>(false);
  const [openConfirmUpload, setOpenConfirmUpload] = useState<boolean>(false);
  const [uploadedReleases, setUploadedReleases] = useState<GroupedCSVRelease[]>([]);
  const [forwardEmail, setForwardEmail] = useState<string[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [openUploadCSVModal, setOpenUploadCSVModal] = useState<boolean>(false);
  const [showPreviewPdf, setShowPreviewPdf] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState('');
  const [preliminaryNoticePreviewDetails, setReleasePreviewDetails] = useState(
    {} as {
      recipient: string;
      type: string;
      paymentAmount: number | null;
      throughDate: string | Date | null;
      job: string;
      status:
        | 'Declined'
        | 'Rejected'
        | 'Requested'
        | 'Signed'
        | 'Scheduled'
        | 'Pending'
        | 'Sent to GC';
      dueDate: string | Date;
      sentToGCTime?: Date | null;
      sentToGCEmails?: string[] | null;
    }
  );
  const [uploadingReleaseSuccess, setUploadingReleaseSuccess] = useState(false);
  const [uploadingReleaseFailed, setUploadingReleaseFailed] = useState(false);

  const error = useMemo(() => {
    if (loadingErrorList.length > 0) {
      const errorMessage: any = loadingErrorList.filter((obj) =>
        obj.hasOwnProperty('getSupplierPreliminaryNotices')
      );
      if (errorMessage.length > 0) {
        return errorMessage[0]?.getSupplierPreliminaryNotices;
      }
    }
    return null;
  }, [loadingErrorList]);

  const searchedPreliminaryNotices: PreliminaryNoticeApiResponse[] = useMemo(() => {
    let filteredPreliminaryNotices: PreliminaryNoticeApiResponse[] = [];
    if (preliminaryNotices && searchText) {
      const searched = preliminaryNotices.filter((preliminaryNotice) => {
        if (
          (preliminaryNotice.job &&
            preliminaryNotice.job.name
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (preliminaryNotice.job?.jobNumber &&
            preliminaryNotice.job.jobNumber
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (preliminaryNotice.job?.buyer?.name &&
            preliminaryNotice.job?.buyer?.name
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (preliminaryNotice.job?.owner &&
            preliminaryNotice.job?.owner
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (preliminaryNotice.job?.GC &&
            preliminaryNotice.job?.GC.toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (preliminaryNotice.job?.lender &&
            preliminaryNotice.job?.lender
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase()))
        ) {
          return true;
        }
        return false;
      });
      filteredPreliminaryNotices = searched;
    } else {
      filteredPreliminaryNotices = preliminaryNotices;
    }

    return filteredPreliminaryNotices;
  }, [preliminaryNotices, searchText]);

  const handleDownloadAll = () => {
    const downloadZipFiles = [] as { url: string; name: string }[];
    selectedPreliminaryNotices.forEach((preliminaryNotice) => {
      const createdAtTimeStamp = preliminaryNotice?.createdAt
        ? new Date(preliminaryNotice.createdAt).getTime()
        : new Date().getTime();
      if (preliminaryNotice.pdf) {
        downloadZipFiles.push({
          url: preliminaryNotice?.pdf?.url ?? '',
          name: `${preliminaryNotice.job?.name ?? ''}-${
            preliminaryNotice.job?.owner ?? ''
          }-${createdAtTimeStamp}`,
        });
      }
    });
    downloadPDFsAsZip(downloadZipFiles, 'preliminaryNotices');
  };

  const filteredPreliminaryNotice: PreliminaryNoticeApiResponse[] = useMemo(() => {
    if (selectedFilters.length === 0) {
      return searchedPreliminaryNotices;
    }

    return searchedPreliminaryNotices.filter((preliminaryNotice) => {
      for (const filter of selectedFilters) {
        if (filter.name === 'amount') {
          const paymentAmount = preliminaryNotice.fieldsData?.find(
            (item: any) => item.id === 'paymentAmount'
          );
          if (
            (filter.value?.min &&
              filter.value?.max &&
              (Number(paymentAmount?.value) < Number(filter.value.min) ||
                Number(paymentAmount?.value) > Number(filter.value.max))) ||
            !paymentAmount
          ) {
            return false;
          }
        } else if (filter.name === 'job') {
          if (!filter?.value?.includes(preliminaryNotice?.job?.id)) {
            return false;
          }
        }
      }
      return true;
    });
  }, [searchedPreliminaryNotices, selectedFilters]);

  const handleFilterSelect = (selectFilter: any[]) => {
    setSelectedFilters(selectFilter);
  };

  return (
    <div className="h-full p-8 flex ">
      <div className="flex flex-col px-5 py-4 w-full bg-white">
        <div className="flex justify-between flex-wrap z-20">
          <div className="flex mb-2">
            <AppInputField
              inputFieldStyles={{ width: '200px' }}
              id="search"
              value={searchText}
              placeholder={'Search by recipient or job'}
              icon={<Search size={15} />}
              onTextChange={(text) => {
                setSearchText(text);
              }}
            />
          </div>
          <div className="flex mb-2">
            <AppButton
              text="Request Notice"
              icon={<Plus color={COLORS.WHITE} />}
              buttonStyles={{ padding: '8px', height: '40px' }}
              onClick={() => {
                navigate('/preliminaryNotices/overview/addPreliminaryNotice');
              }}
            />
            {selectedPreliminaryNotices.length > 0 && (
              <AppButton
                text="Bulk Download"
                buttonStyles={{ marginLeft: '5px', padding: '8px', height: '40px' }}
                type="TERTIARY"
                onClick={handleDownloadAll}
                icon={<Download size={20} color={COLORS.TEXT_SECONDARY} />}
              />
            )}
          </div>
        </div>
        {error ? (
          <AppErrorPage
            title="Error loading  preliminaryNotices."
            contactMessage="Please contact customer support."
            errorMessage={error}
          />
        ) : (
          <div>
            <PreliminaryNoticesTable
              preliminaryNotices={filteredPreliminaryNotice}
              refresh={refresh}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PreliminaryNotices;
