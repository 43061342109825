import { QuickbooksInvoice, Supplier, formatMoney } from '@dill/dill-shared';
import { Modal } from '@mui/material';
import React, { useMemo } from 'react';
import './AppViewInvoiceWithoutPdfModal.scss';
import moment from 'moment';
import { X } from 'phosphor-react';

const AppViewInvoiceWithoutPdfModal = ({
  open,
  handleClose,
  selectedInvoice = null,
  selectedSupplier = null,
}: {
  open: boolean;
  handleClose: () => void;
  selectedInvoice?: QuickbooksInvoice | null;
  selectedSupplier?: Supplier | null;
}) => {
  console.log(selectedInvoice);
  const columns = useMemo(() => {
    const currentColumns = [
      { id: 'service', name: 'Service', width: 80 },
      { id: 'description', name: 'Description', width: 50 },
      { id: 'quantity', name: 'QTY', width: 50 },
      { id: 'rate', name: 'Rate', width: 50 },
      { id: 'discountAmount', name: 'Discount', width: 50 },
      { id: 'subTotal', name: 'Sub Total', width: 50 },
      { id: 'taxAmount', name: 'Tax', width: 50 },
      { id: 'amount', name: 'Amount', width: 50 },
    ];
    return currentColumns;
  }, []);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );
  const totalPayment = useMemo(
    () => (selectedInvoice?.amount || 0) - (selectedInvoice?.balance || 0),
    [selectedInvoice]
  );
  console.log(formatMoney(totalPayment, 2), '>>>');
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="view-invoice-without-pdf p-3 ">
        <X size={20} className="absolute right-2 top-2 z-10 cursor-pointer" onClick={handleClose} />
        <div className="pb-4">
          <div className="min-h-fit w-fit my-1 flex flex-col mb-4">
            <p className="capitalize text-sm font-bold">{selectedSupplier?.name}</p>
            <p className="text-sm">{selectedSupplier?.email}</p>
            <h2 className="text-3xl text-blue-400">Invoice</h2>
          </div>
          <div className="min-h-fit w-full my-1 flex justify-between">
            <div className=" min-h-fit w-fit mx-1">
              <div className="">
                <p className="capitalize text-xs font-bold">Bill To</p>
                {/* <p className="capitalize text-xs ">{selectedInvoice?.customerName}</p> */}
                {selectedInvoice?.billAddress?.address1 && (
                  <p className="capitalize text-xs mt-0.5">
                    {selectedInvoice?.billAddress?.address1}
                  </p>
                )}
                {selectedInvoice?.billAddress?.address2 && (
                  <p className="capitalize text-xs mt-0.5">
                    {selectedInvoice?.billAddress?.address2}
                  </p>
                )}
                {(selectedInvoice?.billAddress?.city ||
                  selectedInvoice?.billAddress?.state ||
                  selectedInvoice?.billAddress?.zipCode) && (
                  <p className="capitalize text-xs mt-0.5">
                    {selectedInvoice?.billAddress?.city}, {selectedInvoice?.billAddress?.state}{' '}
                    {selectedInvoice?.billAddress?.zipCode}
                  </p>
                )}
              </div>
              {selectedInvoice?.quickBooksJob && (
                <div className="mt-1 ">
                  <p className="capitalize text-xs font-bold">Job</p>
                  <p className="capitalize text-xs ">{selectedInvoice?.quickBooksJob.jobName}</p>
                </div>
              )}
            </div>

            <div className=" min-h-fit w-fit mx-1">
              <div className="flex ">
                <div className="flex flex-col items-end mr-1">
                  <p className="capitalize text-sm font-bold">Invoice #</p>
                  <p className="capitalize text-sm font-bold">PO #</p>
                  <p className="capitalize text-sm font-bold">Date</p>
                  <p className="capitalize text-sm font-bold">Due Date</p>
                  <p className="capitalize text-sm font-bold">Terms</p>
                </div>
                <div className="flex flex-col">
                  <p className=" text-sm ">{selectedInvoice?.docNumber || '-'}</p>
                  <p className=" text-sm ">{selectedInvoice?.poNumber || '-'}</p>
                  <p className=" text-sm ">
                    {moment(selectedInvoice?.createdAt).utc().format('YYYY-MM-DD')}
                  </p>
                  <p className=" text-sm ">
                    {moment(selectedInvoice?.dueDate).utc().format('YYYY-MM-DD') || '-'}
                  </p>
                  <p className=" text-sm ">{selectedInvoice?.terms || '-'}</p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ height: '2px' }} className="h-1 w-full bg-blue-400 mb-4"></div>
          <div className=" h-72 w-full my-1 px-1">
            <div className="flex w-full h-full flex-col overflow-y-scroll ">
              <div className="flex border-b items-center w-full sticky top-0 bg-blue-50 py-2 px-2 z-10">
                {columns.map((column) => {
                  return (
                    <div
                      key={column.id}
                      style={{ flex: column.width / totalWidth }}
                      className="flex w-full h-full mx-1 relative">
                      <p className="text-blue-400 text-xs uppercase">{column.name}</p>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col px-2 ">
                {selectedInvoice &&
                  selectedInvoice?.items.length > 0 &&
                  selectedInvoice?.items.map((item, itemIndex) => {
                    return (
                      <div key={itemIndex + 'oo'} className="flex border-b border-b-slate-200">
                        {columns.map((column) => {
                          return (
                            <div
                              key={column.id + 'ii'}
                              style={{ flex: column.width / totalWidth }}
                              className="flex w-full h-full text-sm mx-1 relative word-wrap py-1">
                              {column.id === 'service' && <p className="">{item?.name}</p>}
                              {column.id === 'quantity' && (
                                <p className="">
                                  {!item?.quantity && item?.quantity !== 0 ? '' : item?.quantity}
                                </p>
                              )}{' '}
                              {column.id === 'description' && (
                                <p className="text-xs">{item?.description}</p>
                              )}
                              {column.id === 'rate' && (
                                <p className="">
                                  {!item?.unitAmount && item?.unitAmount !== 0
                                    ? ''
                                    : formatMoney(item?.unitAmount || 0, 2)}
                                </p>
                              )}
                              {column.id === 'taxAmount' && (
                                <p className="">
                                  {!item?.taxAmount && item?.taxAmount !== 0
                                    ? ''
                                    : formatMoney(item?.taxAmount || 0, 2)}
                                </p>
                              )}
                              {column.id === 'discountAmount' && (
                                <p className="">
                                  {!item?.discountAmount && item?.discountAmount !== 0
                                    ? ''
                                    : formatMoney(item?.discountAmount || 0, 2)}
                                </p>
                              )}
                              {column.id === 'subTotal' && (
                                <p className="">
                                  {!item?.subTotal && item?.subTotal !== 0
                                    ? ''
                                    : formatMoney(item?.subTotal || 0, 2)}
                                </p>
                              )}
                              {column.id === 'amount' && (
                                <p className="">
                                  {!item?.totalAmount && item?.totalAmount !== 0
                                    ? ''
                                    : formatMoney(item?.totalAmount || 0, 2)}
                                </p>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <hr className="border-b border-dotted" />
          <div className="h-20 w-full my-1 flex">
            <div className=" h-20 w-3/4 mx-1">
              <p className="text-sm">{selectedInvoice?.note}</p>
            </div>
            <div className=" h-20  mx-1">
              <div className="flex justify-between">
                <div className="flex flex-col items-end mr-1.5">
                  <div>
                    <p className="capitalize text-sm font-bold">Sub Total</p>
                    <p className="capitalize text-sm font-bold">{`Tax`}</p>
                    <p className="capitalize text-sm font-bold">Total</p>
                    <p className="capitalize text-sm font-bold">Payment</p>
                    <p className="capitalize text-sm font-bold whitespace-nowrap">Balance Due</p>
                  </div>
                </div>
                <div className="flex flex-col ">
                  <div>
                    <p className=" text-sm ">
                      {!selectedInvoice?.subAmount && selectedInvoice?.subAmount !== 0
                        ? '-'
                        : formatMoney(selectedInvoice?.subAmount || 0, 2)}
                    </p>
                    <p className=" text-sm ">
                      {!selectedInvoice?.totalTaxAmount && selectedInvoice?.totalTaxAmount !== 0
                        ? '-'
                        : formatMoney(selectedInvoice?.totalTaxAmount || 0, 2)}
                    </p>
                    <p className=" text-sm ">
                      {!selectedInvoice?.amount && selectedInvoice?.amount !== 0
                        ? '-'
                        : formatMoney(selectedInvoice?.amount || 0, 2)}
                    </p>
                    <p className=" text-sm ">{formatMoney(totalPayment, 2)}</p>
                    <p className="text-lg font-bold">
                      {!selectedInvoice?.balance && selectedInvoice?.balance !== 0
                        ? '-'
                        : formatMoney(selectedInvoice?.balance || 0, 2)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AppViewInvoiceWithoutPdfModal;
