import { Modal } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { UploadSimple } from 'phosphor-react';
import { useDropzone } from 'react-dropzone';
import { AppButton } from '../AppButton/AppButton';

const AppUploadFileModal = ({
  open,
  title,
  handleClose,
  secondaryText,
  onSelectFile,
  selectedFile,
}: {
  title: string;
  secondaryText?: string;
  open: boolean;
  handleClose: () => void;
  onSelectFile: (file: File) => void;
  selectedFile?: File;
}) => {
  const [file, setFile] = useState<File[]>([]);

  const handleSelectFile = (uploadedFiles: FileList) => {
    const fileList = Array.from(uploadedFiles) as File[];
    setFile(fileList);
  };

  useEffect(() => {
    if (open && selectedFile) {
      setFile([selectedFile]);
    }
  }, [open]);

  const onDrop = (acceptedFiles: any) => {
    handleSelectFile(acceptedFiles);
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });
  const handleUploadFile = async () => {
    if (file.length > 0) {
      await onSelectFile(file[0]);
    }
    handleClose();
  };
  const handleCloseModal = () => {
    handleClose();
    setFile([]);
  };
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-80 w-4/12 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
        <h2 className="text-lg font-semibold mx-4 my-3">{title}</h2>
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
        <div className="mx-4 mt-3 mb-2">
          <input className="hidden" {...getInputProps()} />
          <div className="PRIMARY_50-BG mt-4 rounded-lg p-4 items-center justify-center flex flex-col">
            <div
              {...getRootProps()}
              className="justify-center flex flex-col items-center cursor-pointer">
              <div className="PRIMARY_100-BG p-5 rounded-full">
                <UploadSimple size={54} color={'#3762FB'} />
              </div>
            </div>
            {file.length > 0 && (
              <div className="text-sm font-normal mt-6">
                {file.map((item, index) => (
                  <div key={index}>{item.name}</div>
                ))}
              </div>
            )}
            <div {...getRootProps()}>
              <AppButton
                text={'Change'}
                type="SECONDARY"
                buttonStyles={{
                  marginTop: '10px',
                  height: '30px',
                  width: '74px',
                  borderRadius: '4px',
                }}
                onClick={() => {}}
              />
            </div>
          </div>
          {secondaryText && <p className="mt-2 text-xs TEXT_SECONDARY-CLR  ">{secondaryText}</p>}
        </div>

        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
        <div className="flex justify-end mx-3 mb-2">
          <AppButton
            text="Cancel"
            type="TERTIARY"
            onClick={handleCloseModal}
            buttonStyles={{ marginRight: '8px' }}
          />
          <AppButton text="Upload" onClick={handleUploadFile} isDisabled={file.length === 0} />
        </div>
      </div>
    </Modal>
  );
};

export default AppUploadFileModal;
