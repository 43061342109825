import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import {
  createLienRelease,
  getLienBuyerReleases,
  getSupplierLienReleases,
  updateSupplierLienSignature,
  getReleaseBySupplier,
  updateReleaseSendEmail,
  deleteRelease,
  getJobReleases,
  forwardRelease,
  manualCreateLienRelease,
  createGroupedRelease,
  updateReleaseByMainBuyer,
  updateReleaseBySupplier,
  getReleaseByMainBuyer,
  getMainBuyerReleaseHistory,
  getSupplierReleaseHistory,
} from '../services/liensService';
import { LiensInitialState } from '../globalSlices/liensSlice';

export const createLienReleaseExtraReducer = (
  builder: ActionReducerMapBuilder<LiensInitialState>
) => {
  const reducerId = 'createLienRelease';
  builder.addCase(createLienRelease.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createLienRelease.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(createLienRelease.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const manualCreateLienReleaseExtraReducer = (
  builder: ActionReducerMapBuilder<LiensInitialState>
) => {
  const reducerId = 'manualCreateLienRelease';
  builder.addCase(manualCreateLienRelease.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    manualCreateLienRelease.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(manualCreateLienRelease.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const getLienBuyerReleasesExtraReducer = (
  builder: ActionReducerMapBuilder<LiensInitialState>
) => {
  const reducerId = 'getLienBuyerReleases';
  builder.addCase(getLienBuyerReleases.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.loadingErrorList = [];
  });
  builder.addCase(getLienBuyerReleases.fulfilled, (state, action: PayloadAction<any | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload?.success && action.payload.data) {
      state.buyerLienReleases = action.payload.data;
    }
  });
  builder.addCase(getLienBuyerReleases.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getLienBuyerReleases: action.payload }]),
      ];
    }
  });
};
export const getSupplierLienReleasesExtraReducer = (
  builder: ActionReducerMapBuilder<LiensInitialState>
) => {
  const reducerId = 'getSupplierLienReleases';
  builder.addCase(getSupplierLienReleases.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.loadingErrorList = [];
  });
  builder.addCase(getSupplierLienReleases.fulfilled, (state, action: PayloadAction<any | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload.success && action.payload.data) {
      state.supplierLienReleases = action.payload.data;
    }
  });
  builder.addCase(getSupplierLienReleases.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getSupplierLienReleases: action.payload }]),
      ];
    }
  });
};
export const updateSupplierLienSignatureExtraReducer = (
  builder: ActionReducerMapBuilder<LiensInitialState>
) => {
  const reducerId = 'updateSupplierLienSignature';
  builder.addCase(updateSupplierLienSignature.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    updateSupplierLienSignature.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(updateSupplierLienSignature.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const getReleaseBySupplierExtraReducer = (
  builder: ActionReducerMapBuilder<LiensInitialState>
) => {
  const reducerId = 'getReleaseBySupplier';
  builder.addCase(getReleaseBySupplier.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.loadingErrorList = [];
    state.selectedLiensRelease = null;
  });
  builder.addCase(
    getReleaseBySupplier.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload.data) {
        state.selectedLiensRelease = action.payload.data;
      }
    }
  );
  builder.addCase(getReleaseBySupplier.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getRelease: action.payload }]),
      ];
    }
  });
};
export const getReleaseByMainBuyerExtraReducer = (
  builder: ActionReducerMapBuilder<LiensInitialState>
) => {
  const reducerId = 'getReleaseByMainBuyer';
  builder.addCase(getReleaseByMainBuyer.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.loadingErrorList = [];
    state.selectedLiensRelease = null;
  });
  builder.addCase(
    getReleaseByMainBuyer.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload.data) {
        state.selectedLiensRelease = action.payload.data;
      }
    }
  );
  builder.addCase(getReleaseByMainBuyer.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getRelease: action.payload }]),
      ];
    }
  });
};
export const updateReleaseBySupplierExtraReducer = (
  builder: ActionReducerMapBuilder<LiensInitialState>
) => {
  const reducerId = 'updateReleaseBySupplier';
  builder.addCase(updateReleaseBySupplier.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    updateReleaseBySupplier.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(updateReleaseBySupplier.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const updateReleaseByMainBuyerExtraReducer = (
  builder: ActionReducerMapBuilder<LiensInitialState>
) => {
  const reducerId = 'updateReleaseByMainBuyer';
  builder.addCase(updateReleaseByMainBuyer.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    updateReleaseByMainBuyer.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(updateReleaseByMainBuyer.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const updateReleaseSendEmailExtraReducer = (
  builder: ActionReducerMapBuilder<LiensInitialState>
) => {
  const reducerId = 'updateReleaseSendEmail';
  builder.addCase(updateReleaseSendEmail.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    updateReleaseSendEmail.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(updateReleaseSendEmail.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const deleteReleaseExtraReducer = (builder: ActionReducerMapBuilder<LiensInitialState>) => {
  const reducerId = 'deleteRelease';
  builder.addCase(deleteRelease.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    deleteRelease.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(deleteRelease.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const getJobReleasesExtraReducer = (builder: ActionReducerMapBuilder<LiensInitialState>) => {
  const reducerId = 'getJobReleases';
  builder.addCase(getJobReleases.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(getJobReleases.fulfilled, (state, action: PayloadAction<any | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload.success && action.payload.data) {
      state.jobReleases = action.payload.data;
    }
  });
  builder.addCase(getJobReleases.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const forwardReleaseExtraReducer = (builder: ActionReducerMapBuilder<LiensInitialState>) => {
  const reducerId = 'forwardRelease';
  builder.addCase(forwardRelease.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(forwardRelease.fulfilled, (state, action: PayloadAction<any | null>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
  builder.addCase(forwardRelease.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const createGroupedReleaseExtraReducer = (
  builder: ActionReducerMapBuilder<LiensInitialState>
) => {
  const reducerId = 'createGroupedRelease';
  builder.addCase(createGroupedRelease.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createGroupedRelease.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(createGroupedRelease.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getSupplierReleaseHistoryExtraReducer = (
  builder: ActionReducerMapBuilder<LiensInitialState>
) => {
  const reducerId = 'getSupplierReleaseHistory';
  builder.addCase(getSupplierReleaseHistory.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.selectedReleaseHistory = [];
  });
  builder.addCase(
    getSupplierReleaseHistory.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload.data) {
        state.selectedReleaseHistory = action.payload.data;
      }
    }
  );
  builder.addCase(getSupplierReleaseHistory.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const getMainBuyerReleaseHistoryExtraReducer = (
  builder: ActionReducerMapBuilder<LiensInitialState>
) => {
  const reducerId = 'getMainBuyerReleaseHistory';
  builder.addCase(getMainBuyerReleaseHistory.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.selectedReleaseHistory = [];
  });
  builder.addCase(
    getMainBuyerReleaseHistory.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload.data) {
        state.selectedReleaseHistory = action.payload.data;
      }
    }
  );
  builder.addCase(getMainBuyerReleaseHistory.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
