import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { COLORS } from '../../../../utils/colors';
import { AppInputField } from '../../../general/AppInputField/AppInputField';
import { AppButton } from '../../../general/AppButton/AppButton';
import { Download, Search } from 'react-feather';
import moment from 'moment';
import { getUserDrivers, getUserSalesReceipts } from '../../../../redux/services/suppliersService';
import { AppDropDown } from '../../../general/AppDropDown/AppDropDown';
import { updateUserSupplierSalesReceiptsDataWeeksLimit } from '../../../../redux/globalSlices/suppliersSlice';
import { weeksOptions } from '../../../../utils/constants';
import { SalesReceiptStatus, QuickbooksSalesReceipt, roundTo } from '@dill/dill-shared';
import { UserSalesReceiptsTable } from '../../salesReceipts/UserSalesReceiptsTable/UserSalesReceiptsTable';
import {
  downloadMultiSalesReceiptPdfZip,
  getSalesReceiptPdf,
} from '../../../../redux/services/salesReceiptsService';
import { resetSalesReceiptIdsForDownload } from '../../../../redux/globalSlices/salesReceiptsSlice';

export interface SalesReceiptsTabs {
  id: SalesReceiptStatus;
  name: string;
  totalAmount: number;
  pastDueCount?: number;
  salesReceipts: QuickbooksSalesReceipt[];
}
const SupplierSalesReceipts = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { salesReceiptIdsForDownload } = useAppSelector((state) => state.salesReceipts);
  const { userSupplierSalesReceipts, userSupplierSalesReceiptsDataWeeksLimit } = useAppSelector(
    (state) => state.supplier
  );
  const { user } = useAppSelector((state) => state.auth);
  const [selectedTabId, setSelectedTabId] = useState<SalesReceiptStatus>('THIS_WEEK');
  const [searchText, setSearchText] = useState('');
  //   const [selectedSupplier, setSelectedSupplier] = useState<Supplier>();

  useEffect(() => {
    dispatch(getUserSalesReceipts({ weeksLimit: userSupplierSalesReceiptsDataWeeksLimit }));
    dispatch(getUserDrivers());

    return () => {};
  }, []);
  useEffect(() => {
    dispatch(getUserSalesReceipts({ weeksLimit: userSupplierSalesReceiptsDataWeeksLimit }));
    dispatch(getUserDrivers());
    return () => {};
  }, [userSupplierSalesReceiptsDataWeeksLimit]);

  const salesReceiptTabs = useMemo(() => {
    const pp: SalesReceiptsTabs[] = [
      {
        id: 'THIS_WEEK',
        name: 'This Week',
        totalAmount: userSupplierSalesReceipts?.todaySalesReceipts?.totalAmount || 0,
        salesReceipts: userSupplierSalesReceipts?.todaySalesReceipts.salesReceipts || [],
      },
      {
        id: 'PAST_SIGNED',
        name: 'Past Signed',
        totalAmount: userSupplierSalesReceipts?.pastSignedSalesReceipts?.totalAmount || 0,
        salesReceipts: userSupplierSalesReceipts?.pastSignedSalesReceipts.salesReceipts || [],
      },
    ];
    return pp;
  }, [userSupplierSalesReceipts, location.pathname]);

  const tableSalesReceipts = useMemo(() => {
    if (userSupplierSalesReceipts && selectedTabId) {
      const tab = salesReceiptTabs.find((tabDetails) => tabDetails.id === selectedTabId);

      //   logEvent(myAnalytics, 'buyer_view_tab', {
      //     userId: user ? user.id : '',
      //     supplierId: selectedSupplier.id,
      //     status: selectedTabId.toLocaleLowerCase().replaceAll('_', ' '),
      //     time: new Date(),
      //     route: location.pathname,
      //   });
      if (tab) {
        return tab.salesReceipts;
      } else {
        return [];
      }
    }

    return [];
  }, [salesReceiptTabs, selectedTabId]);

  const searchedTableSalesReceipts = useMemo(() => {
    if (tableSalesReceipts && searchText) {
      const searched = tableSalesReceipts.filter((salesReceipt) => {
        if (
          (salesReceipt?.customerName &&
            salesReceipt?.customerName
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (salesReceipt?.amount &&
            salesReceipt?.amount.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (salesReceipt?.terms &&
            salesReceipt?.terms.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (salesReceipt?.createdAt &&
            moment(salesReceipt?.createdAt)
              .format('MM/DD/YYYY')
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (salesReceipt?.docNumber &&
            salesReceipt?.docNumber.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (salesReceipt?.balance &&
            salesReceipt?.balance.toString().toLowerCase().includes(searchText.toLowerCase()))
        ) {
          return true;
        }
        return false;
      });
      return searched;
    }

    return tableSalesReceipts;
  }, [tableSalesReceipts, searchText]);

  const handleDownloadAll = () => {
    if (user && user.userSupplier) {
      if (salesReceiptIdsForDownload.length > 0 && user.userSupplier?.domainId) {
        if (salesReceiptIdsForDownload.length === 1) {
          dispatch(
            getSalesReceiptPdf({
              supplierDomainId: user.userSupplier?.domainId,
              salesReceiptId: salesReceiptIdsForDownload[0],
            })
          );
        } else {
          dispatch(
            downloadMultiSalesReceiptPdfZip({
              supplierDomainId: user.userSupplier?.domainId || '',
              salesReceiptIds: salesReceiptIdsForDownload,
            })
          );
        }
      }
    }
  };

  return (
    <div className="w-full h-full">
      <div className="flex h-full flex-col mx-10 mt-3  p-3  pb-6 rounded-lg WHITE-BG  flex-nowrap">
        <div className="flex flex-nowrap">
          {salesReceiptTabs.map((tab, index) => {
            return (
              <div
                key={index + 'oo'}
                className={
                  'flex flex-col border-b-1 border-b-slate-500 w-full  cursor-pointer ' +
                  (selectedTabId === tab.id ? '' : 'opacity-60')
                }
                onClick={() => {
                  setSelectedTabId(tab.id);
                  dispatch(resetSalesReceiptIdsForDownload());
                }}>
                <p className="font-bold text-lg mb-2">{tab.name}</p>
                <div className="flex mb-2 justify-between">
                  <div className="flex items-center ">
                    <p className="text-sm ml-1 font-semibold">
                      {`$${roundTo(tab.totalAmount, 2)}`}
                    </p>
                    <p className="text-sm TEXT_SECONDARY-CLR mx-1">|</p>
                    <p className="text-sm TEXT_SECONDARY-CLR ">{`${tab.salesReceipts.length} ${
                      tab.salesReceipts.length === 1 ? 'item' : 'items'
                    }`}</p>
                  </div>
                </div>

                {selectedTabId === tab.id && (
                  <div style={{ height: '2px' }} className="h-1 w-full PRIMARY_500-BG"></div>
                )}
                {selectedTabId !== tab.id && (
                  <div style={{ height: '2px' }} className="h-1 w-full GREY_300-BG"></div>
                )}
              </div>
            );
          })}
        </div>
        <div className="py-3 flex justify-between">
          <div className="flex items-end">
            <AppInputField
              inputFieldStyles={{ width: '300px' }}
              id="search"
              placeholder={'Search '}
              icon={<Search size={15} />}
              onTextChange={(text) => {
                setSearchText(text);
              }}
            />
            {selectedTabId === 'PAST_SIGNED' && (
              <div className="flex flex-col ml-3 z-20">
                <AppDropDown
                  inputFieldStyles={{ width: '200px' }}
                  isSearchable={false}
                  isCreatable={false}
                  items={[...weeksOptions]}
                  value={userSupplierSalesReceiptsDataWeeksLimit.toString()}
                  onSelectChange={(data) => {
                    if (data?.value) {
                      dispatch(
                        updateUserSupplierSalesReceiptsDataWeeksLimit(parseInt(data?.value))
                      );
                    }
                  }}
                />
              </div>
            )}
          </div>

          {salesReceiptIdsForDownload.length > 0 && (
            <AppButton
              text="Bulk Download"
              type="TERTIARY"
              onClick={handleDownloadAll}
              icon={<Download size={20} color={COLORS.TEXT_SECONDARY} />}
            />
          )}
        </div>
        <div className="pb-3" style={{ height: '75%' }}>
          {tableSalesReceipts && selectedTabId && (
            <UserSalesReceiptsTable
              salesReceipts={searchedTableSalesReceipts}
              selectedTabId={selectedTabId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SupplierSalesReceipts;
