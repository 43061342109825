import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import { initializeApp } from 'firebase/app';
import {
  browserSessionPersistence,
  connectAuthEmulator,
  getAuth,
  inMemoryPersistence,
  setPersistence,
} from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';
// import 'firebase/analytics';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';

// @Paul, I've copied this over from a previous React / Firebase project. It is in JS.
// Feel free to adjust & convert this to TSX properly. For Dill, we don't need to be creative
// with the login/registration pages.
// The devConfig & prodConfig values below are REAL. I've gone into the Firebase projects and
// copied & pasted the values here.

const devConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_REALTIME_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
function getFirebaseConfig() {
  const env = process.env.NODE_ENV;
  const deployEnv = process.env.REACT_APP_DEPLOY_ENV;
  console.log('NODE_ENV: ', env);
  console.log('DEPLOY_ENV: ', deployEnv);
  if (env === 'production' && deployEnv === 'prod') {
    console.log('Using Firebase prod config...');
    return devConfig;
    // return prodConfig;
  } // env === 'development' or 'test' or env === 'production' && deployEnv === 'dev'
  console.log('Using Firebase dev config...');
  return devConfig;
}

// let myFirebase: FirebaseApp;
// if (!firebase.apps.length) {
//   const firebaseConfig = getFirebaseConfig();
//   myFirebase = initializeApp(firebaseConfig);
// } else {
//   myFirebase = firebase.app();
// }
const firebaseConfig = getFirebaseConfig();
const myFirebase = initializeApp(firebaseConfig);
const myFirestore = getFirestore(myFirebase);
const myFirebaseAuth = getAuth(myFirebase);
const myFirebaseStorage = getStorage(myFirebase);
const myRealtimeDB = getDatabase(myFirebase);

// setPersistence(myFirebaseAuth, inMemoryPersistence).then().catch();
// const isUseEmulator = false;
if (process.env.REACT_APP_IS_USE_EMULATOR === 'true') {
  console.log('.........using emulator ......');
  connectAuthEmulator(myFirebaseAuth, 'http://localhost:9099');
  connectFirestoreEmulator(myFirestore, 'localhost', 8081);
  connectStorageEmulator(myFirebaseStorage, 'localhost', 9199);
  connectDatabaseEmulator(myRealtimeDB, 'localhost', 8089);

  //
  // connectStorageEmulator(storage, 'localhost', 9199);
}

const myAnalytics = getAnalytics(myFirebase);

export {
  myFirebase,
  myAnalytics,
  firebase,
  myFirebaseAuth,
  myFirestore,
  myFirebaseStorage,
  myRealtimeDB,
};
