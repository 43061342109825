import React, { useEffect, useMemo, useState } from 'react';
import emptyListImg from '../../../../assets/images/emptyList.png';
import { AppInputField } from '../../../general/AppInputField/AppInputField';
import { Plus, Search } from 'react-feather';
import { AppButton } from '../../../general/AppButton/AppButton';
import { COLORS } from '../../../../utils/colors';
import NoticeTemplatesTable from '../NoticeTemplatesTable/NoticeTemplatesTable';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { NoticeTemplate } from '@dill/dill-shared';
import { useNavigate } from 'react-router-dom';
import { getNoticeTemplates } from '../../../../redux/services/noticeTemplatesService';

const NoticeTemplates = () => {
  const navigate = useNavigate();
  const { noticeTemplates } = useAppSelector((state) => state.noticeTemplates);
  const [searchText, setSearchText] = useState('');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNoticeTemplates());
    return () => {};
  }, []);

  const searchedNoticeTemplates: NoticeTemplate[] = useMemo(() => {
    let filteredNoticeTemplates: NoticeTemplate[] = [];
    if (noticeTemplates && searchText) {
      const searched = noticeTemplates.filter((noticeTemplate) => {
        if (
          noticeTemplate.name &&
          noticeTemplate.name.toString().toLowerCase().includes(searchText.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      filteredNoticeTemplates = searched;
    } else {
      filteredNoticeTemplates = noticeTemplates;
    }

    return filteredNoticeTemplates;
  }, [noticeTemplates, searchText]);

  return (
    <div className="h-full p-8 flex ">
      <div className="flex flex-col px-5 py-4 w-full bg-white">
        <div className="flex justify-between">
          <AppInputField
            inputFieldStyles={{ width: '300px' }}
            id="search"
            value={searchText}
            placeholder={'Search '}
            icon={<Search size={15} />}
            onTextChange={(text) => {
              setSearchText(text);
            }}
          />
          <div className="relative">
            <input
              id="upload"
              className="absolute w-full h-full top-0 bottom-0 opacity-0 hover:bg-blue-700"
              type="file"
              accept="application/pdf"
              onChange={(event) => {
                if (!event?.target.files) {
                  return;
                }
                const file = event?.target?.files[0];

                if (file) {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    const base64String = reader.result;
                    navigate('/noticeTemplates/templates/addTemplate', {
                      state: { pdf: base64String, name: file.name.split('.')[0] },
                    });
                  };
                  reader.readAsDataURL(file);
                }
              }}
            />
            <AppButton
              text="Add New Template"
              icon={<Plus color={COLORS.WHITE} />}
              onClick={() => {
                // navigate('/noticeTemplates/templates/addTemplate', { state: {} });
              }}
            />
          </div>
        </div>

        <NoticeTemplatesTable noticeTemplates={searchedNoticeTemplates} />
      </div>
    </div>
  );
};

export default NoticeTemplates;
