import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RootSetupPage = ({ redirectToPath = '/login' }: { redirectToPath?: string }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname.startsWith('/')) {
      const params = new URLSearchParams(location.search);
      const emailFromLink = params.get('email');
      if (emailFromLink) {
        sessionStorage.setItem('emailFromLink', emailFromLink);
      }
      navigate(redirectToPath);
    }

    return () => {};
  }, [location.pathname]);

  return <div></div>;
};

export default RootSetupPage;
