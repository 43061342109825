import { Modal } from '@mui/material';
import React, { useMemo } from 'react';
import { AppButton } from '../AppButton/AppButton';
import { X } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import emptyListImg from '../../../assets/images/emptyList.png';
import { ReleaseHistoryApiResponse } from '@dill/dill-shared/dist/src';
import { twMerge } from 'tailwind-merge';
import moment from 'moment';
import { useAppSelector } from '../../../redux/store';
import AppReleaseStatusPill from '../AppReleaseStatusPill/AppReleaseStatusPill';

export const AppReleaseHistoryModal = ({
  open,
  handleClose,
  history,
}: {
  open: boolean;
  handleClose: () => void;
  history: ReleaseHistoryApiResponse[];
}) => {
  const { user } = useAppSelector((state) => state.auth);
  const columns = useMemo(() => {
    const currentColumns = [
      { id: 'time', name: 'Time', width: 120, cellType: 'VIEW_TEXT' },
      { id: 'user', name: 'User', width: 120, cellType: 'VIEW_TEXT' },
      { id: 'action', name: 'Action', width: 120, cellType: 'VIEW_TEXT' },
      { id: 'details', name: 'Details', width: 120, cellType: 'VIEW_TEXT' },
      { id: 'status', name: 'Status', width: 120, cellType: 'VIEW_TEXT' },
    ];
    return currentColumns;
  }, [history]);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-80 w-[60vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
        <h2 className="text-lg font-semibold mx-4 my-3">History</h2>
        <X
          className="absolute right-4 top-4 cursor-pointer"
          color={COLORS.GREY_500}
          onClick={handleClose}
        />
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
        <div className="my-4 mx-6 border GREY_300-BORDER rounded-lg max-h-[80vh] overflow-scroll">
          <div className="w-full flex border-b justify-between px-4 sticky top-0 PRIMARY_50-BG items-center">
            {columns.map((column, i) => {
              return (
                <div
                  key={column.id}
                  style={{ flex: column.width / totalWidth }}
                  className={twMerge('py-2 mx-1')}>
                  {column.id !== 'select' && column.id !== 'actions' && (
                    <p className="text-xs TEXT_SECONDARY-CLR">{column.name}</p>
                  )}
                </div>
              );
            })}
          </div>
          {history.length === 0 && (
            <div className="w-full h-full flex items-center justify-center">
              <div className="flex flex-col items-center p-6">
                <img className="w-2/5 object-contain" src={emptyListImg} alt="" />
                <p>No release history</p>
              </div>
            </div>
          )}
          {history.length > 0 &&
            history.map((releasehistory, index) => {
              const userDetails = releasehistory?.user?.user
                ? releasehistory?.user?.user
                : releasehistory?.user?.subUser;
              const status = releasehistory.status;
              const action = releasehistory?.action ?? '';
              return (
                <div
                  key={index + 'oohist'}
                  className="w-full flex items-center h-full  px-4 justify-between">
                  {columns.map((column) => {
                    return (
                      <div
                        key={column.id}
                        style={{ flex: column.width / totalWidth }}
                        className="py-3 mx-1">
                        {column.id === 'time' && (
                          <div className="flex  w-full">
                            {releasehistory.date && (
                              <div className="font-semibold">
                                <p className="text-sm TEXT_SECONDARY-CLR">
                                  {moment(new Date(releasehistory.date)).format('MMM DD, YYYY')}
                                </p>
                                <p className="text-sm TEXT_SECONDARY-CLR">
                                  {`at ${moment(new Date(releasehistory.date)).format('LT')}`}
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                        {column.id === 'status' && (
                          <p className="text-sm capitalize w-full text-center">
                            <AppReleaseStatusPill status={status} />
                          </p>
                        )}
                        {column.id === 'user' && (
                          <p className="text-sm capitalize font-semibold w-full TEXT_SECONDARY-CLR">
                            {`${userDetails?.firstName || ''} ${userDetails?.lastName || ''}`}
                            {user?.id === userDetails?.id && (
                              <span className="PRIMARY_500-CLR lowercase">{` (you)`}</span>
                            )}
                          </p>
                        )}
                        {column.id === 'action' && (
                          <p className="text-sm font-semibold w-full TEXT_SECONDARY-CLR">
                            {action}
                          </p>
                        )}
                        {column.id === 'details' && (
                          <p className="text-sm font-semibold w-full TEXT_SECONDARY-CLR">{`${
                            releasehistory?.details ? releasehistory?.details : '-'
                          }`}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
    </Modal>
  );
};
