import React from 'react';
import AddNoticeTemplateFields from '../AddNoticeTemplateFields/AddNoticeTemplateFields';

const NoticeTemplatesFields = () => {
  return (
    <div className="w-full h-full">
      <AddNoticeTemplateFields />
    </div>
  );
};

export default NoticeTemplatesFields;
