import { Modal } from '@mui/material';
import React, { useState, useMemo, useEffect } from 'react';
import { X } from 'react-feather';
import { AppButton } from '../AppButton/AppButton';
import './AppForwardReleaseModal.scss';
import { COLORS } from '../../../utils/colors';
import emptyListImg from '../../../assets/images/emptyList.png';
import { AppInputField } from '../AppInputField/AppInputField';
import { AppDropDown } from '../AppDropDown/AppDropDown';
import { Job, LienApiResponse } from '@dill/dill-shared';
import { Warning } from 'phosphor-react';
import { useAppSelector, useAppDispatch } from '../../../redux/store';
import { twMerge } from 'tailwind-merge';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';
import moment from 'moment';
import { getJobReleases } from '../../../redux/services/liensService';
import {
  selectJobReleases,
  unSelectJobReleases,
  resetJobReleases,
} from '../../../redux/globalSlices/liensSlice';
import { extractEmailAddresses, openDillToast } from '../../../utils/helpers';
import { addSendgridApiKeyToMainBuyer } from '../../../redux/services/buyersService';
import { getUserDetails } from '../../../redux/services/authService';
import { useParamMainBuyerId } from '../../../utils/hooks/useParamMainBuyerId';

export const AppForwardReleaseModal = ({
  open,
  handleClose,
  jobs,
  onForwardEmail,
}: {
  open: boolean;
  handleClose?: () => void;
  jobs: Job[];
  onForwardEmail: ({
    notes,
    subject,
    emails,
    cc,
    selectedReleases,
  }: {
    notes: string;
    subject: string;
    emails: string[];
    cc: string;
    selectedReleases: LienApiResponse[];
  }) => void;
}) => {
  const INITIAL_STATE = {
    notes: '',
    subject: '',
    cc: '',
  };
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedMainBuyer } = useAppSelector((state) => state.buyers);
  const { jobReleases, selectedJobReleases } = useAppSelector((state) => state.liens);
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [forwardInput, setForwardInput] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState({ ...INITIAL_STATE, job: '', releases: '' });
  const [sendgridApiKey, setSendgridApiKey] = useState('');

  const columns = useMemo(() => {
    return [
      { id: 'select', name: '', width: 50, cellType: 'VIEW_TEXT' },
      { id: 'supplier', name: 'Supplier', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'throughDate', name: 'Through Date', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'template', name: 'Template', width: 100, cellType: 'VIEW_TEXT' },
    ];
  }, []);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  const filteredJobReleases = useMemo(() => {
    const filteredRelease = jobReleases.filter((release) => release.status === 'Signed');
    return filteredRelease;
  }, [jobReleases]);

  useEffect(() => {
    if (open && selectedMainBuyerId && user && !user.isSendgridVerified) {
      const mainBuyer = user?.userMainBuyers?.find((mb) => mb.id === selectedMainBuyerId);
      if (mainBuyer && mainBuyer?.sendgridApiKey) {
        setSendgridApiKey(mainBuyer?.sendgridApiKey);
      }
    }

    return () => {};
  }, [open, selectedMainBuyerId]);

  const validateInputs = () => {
    const newErrors = { notes: '', subject: '', job: '', releases: '', cc: '' };
    let isValid = true;
    if (forwardInput.subject.trim() === '') {
      newErrors.subject = 'Subject is required.';
      isValid = false;
    }
    if (forwardInput.notes.trim() === '') {
      newErrors.notes = 'Notes is required.';
      isValid = false;
    }
    if (forwardInput.cc.trim() !== '') {
      const emailRegex = /[\w.+-]+@[\w-]+\.[a-z]{1,}/gi;
      const isMatch = emailRegex.test(forwardInput.cc);
      if (!isMatch) {
        const inputEmail = forwardInput.cc.split(/[;, ]/);
        newErrors.cc =
          inputEmail.length === 1
            ? 'Invalid email'
            : 'Email list should either be separated by ";" "," or space';
        isValid = false;
      } else {
        const validEmails = extractEmailAddresses(forwardInput.cc);
        const inputEmail = forwardInput.cc.split(/[;, ]/);
        if (inputEmail > validEmails) {
          newErrors.cc = 'Email list contains an invalid email';
          isValid = false;
        }
      }
    }
    if (selectedJob === null) {
      newErrors.job = 'Please select a job.';
      newErrors.releases = 'Job not selected.';
      isValid = false;
    } else if (selectedJob !== null && selectedJob.accountantEmails.length === 0) {
      newErrors.job = 'Job does not have a forwarding email. Edit the job to add an email.';
      isValid = false;
    }
    if (jobReleases.length > 0 && selectedJobReleases.length === 0) {
      newErrors.releases = 'Please select at least one release.';
      isValid = false;
    } else if ((selectedJob !== null && jobReleases.length) === 0) {
      newErrors.releases = 'Job does not have any releases.';
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  const handleOnchangeInput = ({ name, value }: { name: string; value: string }) => {
    setForwardInput((values) => ({ ...values, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
  };
  const handleSubmitSendgridApiKey = async () => {
    if (!sendgridApiKey) {
      openDillToast({ message: 'No Api Key has been set', type: 'ERROR' });
    }
    if (sendgridApiKey) {
      const res = await dispatch(
        addSendgridApiKeyToMainBuyer({ mainBuyerId: selectedMainBuyerId || '', sendgridApiKey })
      );
      console.log(res);
      if (res && res.type === 'buyers/addSendgridApiKeyToMainBuyer/fulfilled') {
        openDillToast({ type: 'SUCCESS', message: 'API key successfully submitted' });
        dispatch(getUserDetails());
      }
      if (res && res.type === 'buyers/addSendgridApiKeyToMainBuyer/rejected') {
        openDillToast({
          type: 'ERROR',
          message: 'Failed to submit API Key please contact support: team@usedill.com ',
        });
      }
    }
  };
  useEffect(() => {
    if (selectedJob && selectedMainBuyerId) {
      dispatch(getJobReleases({ jobId: selectedJob.id, mainBuyerId: selectedMainBuyerId }));
    }
  }, [selectedJob, selectedMainBuyerId]);

  useEffect(() => {
    if (open) {
      dispatch(resetJobReleases());
    } else {
      setSelectedJob(null);
      setErrors({ ...INITIAL_STATE, job: '', releases: '' });
      setForwardInput(INITIAL_STATE);
    }
  }, [open]);

  const handleForwardEmail = () => {
    if (!validateInputs() || selectedJobReleases.length > 90) {
      return;
    }
    onForwardEmail({
      ...forwardInput,
      emails: selectedJob?.accountantEmails ? selectedJob?.accountantEmails : [],
      selectedReleases: selectedJobReleases,
    });
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="forward-release-modal-content">
        <div className="flex justify-between items-center mt-2 mx-6">
          <div className="flex">
            <h3 className="font-medium text-lg">Forward Releases to General Contractor</h3>
          </div>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={handleClose}
          />
        </div>
        <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
        <div className="mx-6 overflow-scroll" style={{ maxHeight: '68vh' }}>
          {!user?.isSendgridVerified && (
            <div className="WARNING_300-BG text-sm WARNING_900-CLR flex items-center justify-center px-2 py-3 mb-2 rounded-lg">
              <Warning size={16} />
              <div className="ml-2">
                Your email is not yet verified for GC forwarding. Please contact customer support
              </div>
            </div>
          )}
          {user?.userMainBuyers &&
            selectedMainBuyer?.sendgridApiKey !== undefined &&
            !user.isSendgridVerified && (
              <div className="flex mt-3 mb-5 flex-col">
                <p className="text-xs mb-1">If you have a Sendgrid API key, please submit below.</p>
                <div className="flex items-end">
                  <AppInputField
                    id="sendgridApiKey"
                    label="Sendgrid Api Key"
                    isRequired={true}
                    value={sendgridApiKey}
                    errorText={errors.subject ? errors.subject : ''}
                    onTextChange={(text) => {
                      setSendgridApiKey(text);
                    }}
                  />

                  <div className="">
                    <AppButton
                      text={
                        user?.userMainBuyers?.find((mb) => mb.id === selectedMainBuyerId)
                          ?.sendgridApiKey
                          ? 'Edit'
                          : 'Submit'
                      }
                      buttonStyles={{ marginLeft: '10px' }}
                      onClick={handleSubmitSendgridApiKey}
                    />
                  </div>
                </div>
              </div>
            )}
          <div className="flex flex-col">
            <div className="w-full">
              <AppDropDown
                label="Job"
                isRequired={true}
                errorText={errors.job ? errors.job : ''}
                value={selectedJob?.id || ''}
                items={[
                  ...jobs
                    .filter((jb) => !jb?.archivedState?.includes('BUYER_ARCHIVED'))
                    .map((job) => {
                      return {
                        label: `${job.number ? `${job.number} | ` : ''}${job.name ?? ''} | ${
                          job.accountantEmails.join(', ') ?? ''
                        }`,
                        value: job.id,
                      };
                    }),
                ]}
                onSelectChange={(item) => {
                  setErrors((prevErrors) => ({ ...prevErrors, job: '', releases: '' }));
                  if (item?.value) {
                    const jobFound = jobs.find((val) => val.id === item.value);
                    if (jobFound) {
                      setSelectedJob(jobFound);
                    }
                  }
                }}
              />
            </div>
            <div className="w-full mt-3">
              <AppInputField
                id="subject"
                label="Email Subject"
                isRequired={true}
                value={forwardInput.subject}
                errorText={errors.subject ? errors.subject : ''}
                onTextChange={(text) => {
                  handleOnchangeInput({ name: 'subject', value: text });
                }}
              />
            </div>
            <div className="w-full mt-3">
              <AppInputField
                id="cc"
                label="Email CC"
                placeholder="ar@contractor.com;arsupervisor@contractor.com"
                // isRequired={true}
                value={forwardInput.cc}
                errorText={errors.cc ? errors.cc : ''}
                onTextChange={(text) => {
                  handleOnchangeInput({ name: 'cc', value: text });
                }}
              />
            </div>
            <div className="w-full mt-3">
              <AppInputField
                label="Email Body"
                isRequired={true}
                inputType="textarea"
                errorText={errors.notes ? errors.notes : ''}
                value={forwardInput.notes}
                onTextChange={(text) => {
                  handleOnchangeInput({ name: 'notes', value: text });
                }}
              />
            </div>
            <div className="w-full mt-3">
              <div className="text-xs mb-0.5  whitespace-nowrap font-medium flex">
                <div className="ERROR_500-CLR">*</div>
                <div>Attach Releases</div>
              </div>
              <div
                className={`border rounded-lg overflow-hidden ${
                  errors.releases === '' && selectedJobReleases.length < 91
                    ? 'GREY_200-BORDER'
                    : 'ERROR_500-BORDER'
                }`}>
                <div className="w-full flex border-b justify-between px-4 sticky top-0 PRIMARY_50-BG items-center">
                  {columns.map((column, i) => {
                    return (
                      <div
                        key={column.id}
                        style={{ flex: column.width / totalWidth }}
                        className={twMerge('py-2 mx-1', i === 1 ? '' : 'text-center')}>
                        {column.id === 'select' && (
                          <AppCheckBox
                            isChecked={
                              selectedJobReleases.length !== 0 &&
                              selectedJobReleases.length === filteredJobReleases.length
                            }
                            onClick={(isChecked: boolean) => {
                              setErrors((prevErrors) => ({ ...prevErrors, releases: '' }));
                              if (isChecked) {
                                dispatch(selectJobReleases(filteredJobReleases));
                              } else {
                                dispatch(unSelectJobReleases(filteredJobReleases));
                              }
                            }}
                          />
                        )}
                        {column.id !== 'select' && column.id !== 'actions' && (
                          <p className="text-xs TEXT_SECONDARY-CLR">{column.name}</p>
                        )}
                      </div>
                    );
                  })}
                </div>
                {filteredJobReleases.length === 0 && (
                  <div className="w-full h-full flex items-center justify-center">
                    <div className="flex flex-col items-center">
                      <img className="w-2/5 object-contain" src={emptyListImg} alt="" />
                      <p>No releases</p>
                    </div>
                  </div>
                )}
                {filteredJobReleases.length > 0 &&
                  filteredJobReleases.map((release, index) => {
                    const throughdate = release?.fieldsData?.find(
                      (elem) => elem.id === 'throughDate'
                    );
                    return (
                      <div
                        key={index + 'oo'}
                        className="w-full flex items-center h-full  px-4 justify-between">
                        {columns.map((column) => {
                          return (
                            <div
                              key={column.id}
                              style={{ flex: column.width / totalWidth }}
                              className="py-3 mx-1">
                              {column.id === 'select' && (
                                <AppCheckBox
                                  isChecked={selectedJobReleases.some(
                                    (obj) => obj.id === release.id
                                  )}
                                  onClick={(isChecked: boolean) => {
                                    setErrors((prevErrors) => ({ ...prevErrors, releases: '' }));
                                    if (isChecked) {
                                      dispatch(selectJobReleases([release]));
                                    } else {
                                      dispatch(unSelectJobReleases([release]));
                                    }
                                  }}
                                />
                              )}
                              {column.id === 'supplier' && (
                                <div className="flex  w-full">
                                  <div className="">
                                    <p className="text-sm capitalize ">
                                      {release.subSupplier?.name || ''}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {column.id === 'throughDate' && (
                                <p className="text-sm capitalize w-full text-center">
                                  {throughdate && throughdate.value
                                    ? moment(new Date(throughdate.value)).format('MM/DD/YYYY')
                                    : ''}
                                </p>
                              )}
                              {column.id === 'template' && (
                                <p className="px-2 text-xs capitalize w-full text-center">
                                  {release.template?.name || ''}
                                </p>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
              {errors.releases !== '' && <div className="error-text">{errors.releases}</div>}
            </div>
          </div>
        </div>
        <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
        {selectedJobReleases.length > 90 && (
          <div className="my-1 mx-2 ERROR_500-CLR text-sm text-center">
            Selected releases to forward should not exceed 90
          </div>
        )}
        <div className="flex flex-row-reverse p-3">
          <AppButton
            text="Forward"
            buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
            onClick={handleForwardEmail}
            isDisabled={!user?.isSendgridVerified}
          />
          <AppButton
            text="Close"
            type="TERTIARY"
            buttonStyles={{ marginLeft: '10px', marginRight: '10px' }}
            onClick={handleClose}
          />
        </div>
      </div>
    </Modal>
  );
};
