import React from 'react';
import { ColorType, COLORS } from '../../../utils/colors';
import { convertHexToRGBA } from '../../../utils/helpers';
import './AppYoutubeEmbed.scss';

export const AppYoutubeEmbed = ({ embedId }: { embedId: string }) => {
  return (
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  );
};
