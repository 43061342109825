import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';
import { SubSupplierUpdate, SubUserApiPostBody } from '@dill/dill-shared';

export const createSubSupplier = createAsyncThunk(
  'subSuppliers/createSubSupplier',
  async (
    {
      mainBuyerId,
      subSupplierDetails: { contacts, companyId, companyName, contactIdsToDelete, notes },
    }: {
      mainBuyerId: string;
      subSupplierDetails: {
        companyId?: string;
        companyName: string;
        notes: string;
        contacts: SubUserApiPostBody[];
        contactIdsToDelete: string[];
      };
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/subSuppliers/createSubSupplier`,
        { contacts, companyId, companyName, contactIdsToDelete, notes },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getSubSuppliers = createAsyncThunk(
  'subSuppliers/getSubSuppliers',
  async ({ mainBuyerId }: { mainBuyerId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/subSuppliers/getSubSuppliers`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const deleteSubSupplier = createAsyncThunk(
  'subSuppliers/deleteSubSupplier',
  async (
    {
      subSupplierId,
      mainBuyerId,
    }: {
      subSupplierId: string;
      mainBuyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.delete<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/subSuppliers/deleteSubSupplier/${subSupplierId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const resendSubSuppliersInvite = createAsyncThunk(
  'subSuppliers/resendSubSuppliersInvite',
  async (
    {
      contact,
      mainBuyerId,
    }: {
      contact: SubUserApiPostBody;
      mainBuyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/subSuppliers/resendSubSuppliersInvite`,
        { contact },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateSubSupplier = createAsyncThunk(
  'subSuppliers/updateSubSupplier',
  async (
    {
      subSupplierDetails,
      subSupplierId,
      mainBuyerId,
    }: {
      subSupplierDetails: SubSupplierUpdate;
      subSupplierId: string;
      mainBuyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<any>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/subSuppliers/updateSubSupplier/${subSupplierId}`,
        { ...subSupplierDetails },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const unarchiveSubSupplier = createAsyncThunk(
  'subSuppliers/unarchiveSubSupplier',
  async (
    {
      subSupplierDetails,
      subSupplierId,
      mainBuyerId,
    }: {
      subSupplierDetails: SubSupplierUpdate;
      subSupplierId: string;
      mainBuyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<any>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/subSuppliers/unarchiveSubSupplier/${subSupplierId}`,
        { ...subSupplierDetails },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
