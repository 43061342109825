import { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { X } from 'react-feather';
import { SupplierJobApiResponse } from '@dill/dill-shared/dist/src';

import './index.scss';

import { AppButton } from '../AppButton/AppButton';

import { COLORS } from '../../../utils/colors';

import { getSupplierJobs, updateSupplierJob } from '../../../redux/services/supplierJobsService';

import { useAppDispatch } from '../../../redux/store';

import { openDillToast } from '../../../utils/helpers';

import AppDatePicker from '../AppDatePicker/AppDatePicker';

interface OwnProps {
  open: boolean;
  dateType: 'firstDeliveryDate' | 'lastDeliveryDate' | 'noticeOfCompletionDate'; // New prop to specify the date type
  handleClose: () => void;
  selectedSupplierJob: SupplierJobApiResponse | null;
  softDeliveryDate: Date | null;
}

const AppEditDeliveryDateModal: React.FC<OwnProps> = (props: OwnProps) => {
  const dispatch = useAppDispatch();

  const { open, handleClose, selectedSupplierJob, softDeliveryDate, dateType } = props;

  const { firstDeliveryDate, lastDeliveryDate, noticeOfCompletionDate } = selectedSupplierJob ?? {
    firstDeliveryDate: null,
    lastDeliveryDate: null,
    noticeOfCompletionDate: null,
  };

  const [loading, setLoading] = useState(false);
  const [onDeliveryDate, setOnDeliveryDate] = useState<Date | null>(new Date());

  const handleCloseModal = () => {
    handleClose();
  };

  const handleSubmitJob = async () => {
    if (!selectedSupplierJob || !onDeliveryDate) return;

    setLoading(true);

    const updates = {
      [dateType]: onDeliveryDate, // Update the specific date based on dateType
    };

    // remove invoices and preLienNotice from the selectedSupplierJob
    const jobDetails = {
      ...selectedSupplierJob,
      invoices: undefined,
      preLienNotice: undefined,
      ...updates,
    };

    const updatedSupplierJob = await dispatch(
      updateSupplierJob({
        supplierJobId: selectedSupplierJob?.id ?? '',

        supplierJobDetails: { ...jobDetails },
      })
    );

    if (updatedSupplierJob.type === 'supplierJobs/updateSupplierJob/fulfilled') {
      openDillToast({ message: 'Job successfully updated!', type: 'SUCCESS' });
    } else {
      openDillToast({ message: 'Failed to update job', type: 'ERROR' });
    }
    setLoading(false);
    handleCloseModal();
  };

  useEffect(() => {
    let newDate = null;

    if (dateType === 'firstDeliveryDate') {
      newDate =
        firstDeliveryDate && !isNaN(Date.parse(firstDeliveryDate.toString()))
          ? firstDeliveryDate
          : softDeliveryDate;
    } else if (dateType === 'lastDeliveryDate') {
      newDate =
        lastDeliveryDate && !isNaN(Date.parse(lastDeliveryDate.toString()))
          ? lastDeliveryDate
          : softDeliveryDate;
    } else if (dateType === 'noticeOfCompletionDate') {
      newDate =
        noticeOfCompletionDate && !isNaN(Date.parse(noticeOfCompletionDate.toString()))
          ? noticeOfCompletionDate
          : softDeliveryDate;
    }

    // Ensure softDeliveryDate is a valid date if it's going to be used as a fallback
    if (
      newDate === softDeliveryDate &&
      softDeliveryDate &&
      isNaN(Date.parse(softDeliveryDate.toString()))
    ) {
      newDate = null; // or set a default valid date
    }

    if (newDate) {
      setOnDeliveryDate(new Date(newDate));
    }
  }, [
    selectedSupplierJob,
    firstDeliveryDate,
    lastDeliveryDate,
    noticeOfCompletionDate,
    softDeliveryDate,
    dateType,
  ]);

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="new-job-modal-content pt-3">
        <div className="new-job-modal-title-container">
          <h3 className="font-medium text-lg">
            {/* Capitalize first letter of dataType */}
            Edit
            {dateType.charAt(0).toUpperCase() +
              dateType
                .slice(1)
                .replace(/([A-Z])/g, ' $1')
                .trim()}{' '}
          </h3>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={handleCloseModal}
          />
        </div>
        <div className="content-job">
          <div className="flex flex-col px-6 pb-3 gap-2">
            <label className="text-sm font-medium">
              {dateType.charAt(0).toUpperCase() +
                dateType
                  .slice(1)
                  .replace(/([A-Z])/g, ' $1')
                  .trim()}{' '}
            </label>
            <AppDatePicker
              selectedDate={onDeliveryDate}
              onDateSelected={(date) => {
                if (date) {
                  setOnDeliveryDate(date);
                }
              }}
            />
          </div>
        </div>
        <div className="flex flex-col w-full mb-3">
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
          <div className="flex flex-row self-end px-6">
            <AppButton
              text="Close"
              type="TERTIARY"
              buttonStyles={{
                marginTop: '4px',
                marginBottom: '4px',
                marginRight: '12px',
                height: '40px',
              }}
              onClick={handleCloseModal}
            />
            <AppButton
              text={'Save'}
              onClick={handleSubmitJob}
              buttonStyles={{
                marginTop: '4px',
                marginBottom: '4px',
                width: '100px',
                height: '40px',
              }}
              isLoading={loading}
              isDisabled={loading}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AppEditDeliveryDateModal;
