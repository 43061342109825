import { DillTransactionStatus } from '@dill/dill-shared';
import React from 'react';
import { CheckCircle, Timer, Warning } from 'phosphor-react';
import { twMerge } from 'tailwind-merge';

const AppTransactionStatusPill = ({
  status,
  wrapperClassName,
}: {
  status?: DillTransactionStatus;
  wrapperClassName?: string;
}) => {
  return (
    <div className={twMerge(' w-fit', wrapperClassName || '')}>
      <div
        className={twMerge(
          'flex items-center px-3 py-1 w-full  rounded-2xl',
          status === 'processed'
            ? 'SUCCESS_100-BG   SUCCESS_500-CLR '
            : status === 'failed' || status === 'cancelled'
            ? 'ERROR_100-BG ERROR_500-CLR'
            : status === 'waiting'
            ? 'INFO_100-BG INFO_500-CLR'
            : 'PRIMARY_100-BG PRIMARY_500-CLR '
        )}>
        {status === 'processed' ? (
          <CheckCircle size={15} weight="bold" />
        ) : status === 'failed' || status === 'cancelled' ? (
          <Warning size={15} weight="bold" />
        ) : status === 'waiting' ? (
          <Warning size={15} weight="bold" />
        ) : (
          <Timer size={15} weight="bold" />
        )}
        <p className="text-xs font-bold ml-1 capitalize mr-1 mb-0.5">{status}</p>
      </div>
    </div>
  );
};

export default AppTransactionStatusPill;
