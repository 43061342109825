import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import { BuyersInitialState } from '../globalSlices/buyersSlice';
import {
  downloadMultiInvoicePdfZip,
  getBasicSupplier,
  getGroupedBuyersSuppliers,
  getInvoicePdf,
  getMainBuyer,
  addSendgridApiKeyToMainBuyer,
  downloadInvoicePdfReceipt,
} from '../services/buyersService';

export const getGroupedBuyersSuppliersExtraReducer = (
  builder: ActionReducerMapBuilder<BuyersInitialState>
) => {
  const reducerId = 'getGroupedBuyersSuppliers';
  builder.addCase(getGroupedBuyersSuppliers.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.selectedBuyerSuppliers = [];
  });
  builder.addCase(
    getGroupedBuyersSuppliers.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      if (action.payload) {
        state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      }
      if (action.payload && action.payload.success && action.payload?.data.buyerSuppliers) {
        state.selectedBuyerSuppliers = action.payload?.data.buyerSuppliers;
      }
    }
  );
  builder.addCase(getGroupedBuyersSuppliers.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getBasicSupplierExtraReducer = (
  builder: ActionReducerMapBuilder<BuyersInitialState>
) => {
  const reducerId = 'getBasicSupplier';
  builder.addCase(getBasicSupplier.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.basicSupplier = null;
  });
  builder.addCase(
    getBasicSupplier.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.basicSupplier = action.payload?.data;
      } else {
        state.basicSupplier = null;
      }
    }
  );
  builder.addCase(getBasicSupplier.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.basicSupplier = null;
  });
};

export const getInvoicePdfExtraReducer = (builder: ActionReducerMapBuilder<BuyersInitialState>) => {
  const reducerId = 'getInvoicePdf';
  builder.addCase(getInvoicePdf.pending, (state, action) => {
    state.invoiceBase64 = null;
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getInvoicePdf.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.previewPdf = action.payload?.data?.pdfUrl;
      } else {
        state.previewPdf = null;
      }
    }
  );
  builder.addCase(getInvoicePdf.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    // state.invoiceBase64 = null;
  });
};

export const downloadMultiInvoicePdfZipExtraReducer = (
  builder: ActionReducerMapBuilder<BuyersInitialState>
) => {
  const reducerId = 'downloadMultiInvoicePdfZip';
  builder.addCase(downloadMultiInvoicePdfZip.pending, (state, action) => {
    state.invoiceBase64 = null;
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    downloadMultiInvoicePdfZip.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
      } else {
      }
    }
  );
  builder.addCase(downloadMultiInvoicePdfZip.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
// export const updateBuyerExtraReducer = (builder: ActionReducerMapBuilder<BuyersInitialState>) => {
//   const reducerId = 'updateBuyer';
//   builder.addCase(updateBuyer.pending, (state, action) => {
//     state.loadingList = [...new Set([...state.loadingList, reducerId])];
//   });
//   builder.addCase(
//     updateBuyer.fulfilled,
//     (state, action: PayloadAction<AppServerResponse | null>) => {
//       state.loadingList = state.loadingList.filter((item) => item !== reducerId);
//       // if (
//       //   action.payload &&
//       //   action.payload.success &&
//       //   action.payload?.data &&
//       //   state.selectedBuyersGroup?.buyers
//       // ) {
//       //   const updatedBuyer = { ...state.selectedBuyersGroup };
//       //   const index = state.selectedBuyersGroup?.buyers.findIndex(
//       //     (buyer) => buyer.id === action.payload?.data.id
//       //   );
//       //   updatedBuyer.buyers[index] = action.payload?.data;
//       // }
//     }
//   );
//   builder.addCase(updateBuyer.rejected, (state, action: PayloadAction<any>) => {
//     state.loadingList = state.loadingList.filter((item) => item !== reducerId);
//   });
// };

export const getMainBuyerExtraReducer = (builder: ActionReducerMapBuilder<BuyersInitialState>) => {
  const reducerId = 'getMainBuyer';
  builder.addCase(getMainBuyer.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.mainBuyer = null;
  });
  builder.addCase(
    getMainBuyer.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.mainBuyer = action.payload?.data;
      }
    }
  );
  builder.addCase(getMainBuyer.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const addSendgridApiKeyToMainBuyerExtraReducer = (
  builder: ActionReducerMapBuilder<BuyersInitialState>
) => {
  const reducerId = 'addSendgridApiKeyToMainBuyer';
  builder.addCase(addSendgridApiKeyToMainBuyer.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    addSendgridApiKeyToMainBuyer.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(addSendgridApiKeyToMainBuyer.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const downloadInvoicePdfReceiptExtraReducer = (
  builder: ActionReducerMapBuilder<BuyersInitialState>
) => {
  const reducerId = 'downloadInvoicePdfReceipt';
  builder.addCase(downloadInvoicePdfReceipt.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    downloadInvoicePdfReceipt.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(downloadInvoicePdfReceipt.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
