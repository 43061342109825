import React, { useEffect, useRef, useState, ChangeEvent, useMemo, MouseEvent } from 'react';
import { Square } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import './AppGroupedDropdown.scss';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';
import { AppInputField } from '../AppInputField/AppInputField';
import AppDatePicker from '../AppDatePicker/AppDatePicker';
import moment from 'moment';
import { RadioButton } from 'phosphor-react';
import { AppDropDown2 } from '../AppDropDown2/AppDropDown2';

interface Option {
  label: string;
  value: string;
  placeholder?: string;
}

interface Group {
  name: string;
  type: string;
  value: string;
  item: Option[];
}

interface DropdownProps {
  options: Group[];
  onSelect: (groupName: any) => void;
  inputFieldStyles?: any;
}

const Icon = ({ disabled }: { disabled: boolean }) => {
  return (
    <svg height="20" width="20" viewBox="0 0 20 20">
      <path
        fill={disabled ? '#ccc' : '#64748B'}
        d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
    </svg>
  );
};

const AppGroupedDropdown = ({ options, onSelect, inputFieldStyles }: DropdownProps) => {
  const [selectedOptions, setSelectedOptions] = useState<Record<string, string>>({});
  const [showMenu, setShowMenu] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const [disable, setSDisabled] = useState(false);
  const [numberRangeValue, setNumberRangeValue] = useState<Record<string, { [x: string]: string }>>(
    {}
  );
  const [multiSelectValue, setMultiSelectValue] = useState<Record<string, string[]>>({});
  const [dateRangeValue, setDateRangeValue] = useState<Record<string, { [x: string]: Date }>>({});
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [numberFilters, setNumberFilters] = useState<number>(0);

  useEffect(() => {
    const handler = (e: any) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);

  const rangeValueFinal = useMemo(() => {
    const rangeList = [];
    if (numberRangeValue) {
      for (const key in numberRangeValue) {
        if (numberRangeValue.hasOwnProperty(key)) {
          const numberRange = numberRangeValue[key] || {};
          const fiteredRange = options.filter((item) => item.value === key);

          if (fiteredRange.length > 0) {
            const firstItemValue = fiteredRange[0]?.item[0]?.value ?? '';
            const secondItemValue = fiteredRange[0]?.item[1]?.value ?? '';
            if (
              numberRange[firstItemValue] &&
              numberRange[secondItemValue] &&
              Number(numberRange[firstItemValue]) <= Number(numberRange[secondItemValue])
            ) {
              rangeList.push({
                name: key,
                value: numberRange,
                label: `$${numberRange[firstItemValue]} - $${numberRange[secondItemValue]}`,
              });
            }
          }
        }
      }
    }
    return rangeList;
  }, [numberRangeValue]);

  const rangeDateFinal = useMemo(() => {
    const dateList: any[] = [];
    if (dateRangeValue) {
      for (const key in dateRangeValue) {
        if (dateRangeValue.hasOwnProperty(key)) {
          const dateRange = dateRangeValue[key] || {};
          const fiteredRange = options.filter((item) => item.value === key);

          if (fiteredRange.length > 0) {
            const firstItemValue = fiteredRange[0]?.item[0]?.value ?? '';
            const secondItemValue = fiteredRange[0]?.item[1]?.value ?? '';
            if (
              dateRange[firstItemValue] &&
              dateRange[secondItemValue] &&
              dateRange[firstItemValue] < dateRange[secondItemValue]
            ) {
              dateList.push({
                name: key,
                value: dateRange,
                label: `${moment(dateRange[firstItemValue]).format('MMM YYYY')} - ${moment(
                  dateRange[secondItemValue]
                ).format('MMM YYYY')}`,
              });
            }
          }
        }
      }
    }
    return dateList;
  }, [dateRangeValue]);

  const selectedOptionsValue = useMemo(() => {
    const optionsList = [];
    if (selectedOptions) {
      for (const key in selectedOptions) {
        if (selectedOptions.hasOwnProperty(key)) {
          const value = selectedOptions[key];
          optionsList.push({ name: key, value, label: `${key}_${value}` });
        }
      }
    }
    return optionsList;
  }, [selectedOptions]);

  const multiSelectFinal = useMemo(() => {
    const optionsList = [];
    if (multiSelectValue) {
      for (const key in multiSelectValue) {
        if (multiSelectValue.hasOwnProperty(key)) {
          const value = multiSelectValue[key];
          if (value.length > 0) {
            optionsList.push({ name: key, value, label: `${key}` });
          }
        }
      }
    }
    return optionsList;
  }, [multiSelectValue]);

  const handleOptionSelect = (groupName: string, optionValue: string) => {
    setSelectedOptions((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [groupName]: optionValue,
    }));
  };

  const handleRangeValueChange = (group: Group, groupKey: string, optionValue: string) => {
    setNumberRangeValue((prevValueRange) => ({
      ...prevValueRange,
      [group.value]: { ...prevValueRange[group.value], [groupKey]: optionValue },
    }));
    const numberRange = numberRangeValue[group.value] || {};
    const maxValue = numberRange[group.item[1].value] || null;
    if (maxValue) {
      if (Number(maxValue) < Number(optionValue)) {
        setNumberRangeValue((prevValueRange) => ({
          ...prevValueRange,
          [group.value]: { ...prevValueRange[group.value], [group.item[1].value]: optionValue },
        }));
      }
    } else {
      setNumberRangeValue((prevValueRange) => ({
        ...prevValueRange,
        [group.value]: { ...prevValueRange[group.value], [group.item[1].value]: optionValue },
      }));
    }
  };
  const handleDateValueChange = (
    group: Group,
    groupKey: string,
    optionValue: Date,
    nextKey: string
  ) => {
    setDateRangeValue((prevDateRange) => ({
      ...prevDateRange,
      [group.value]: { ...prevDateRange[group.value], [groupKey]: optionValue },
    }));
    if (groupKey !== nextKey) {
      const dateRange = dateRangeValue[group.value] || {};
      const finsihValue = dateRange[group.item[1].value] || null;
      if (!finsihValue) {
        const year = optionValue.getFullYear();
        const month = optionValue.getMonth();
        const lastDayOfMonth = new Date(year, month + 1, 0);
        setDateRangeValue((prevValueRange) => ({
          ...prevValueRange,
          [group.value]: { ...prevValueRange[group.value], [nextKey]: lastDayOfMonth },
        }));
      }
    }
  };

  const handleShowDropdown = (e: any) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleBlurRangeValue1Change = (group: Group, groupKey: string, optionValue: string) => {
    const numberRange = numberRangeValue[group.value] || {};
    const maxValue = numberRange[group.item[1].value] || null;
    if (maxValue) {
      if (Number(maxValue) < Number(optionValue)) {
        setNumberRangeValue((prevValueRange) => ({
          ...prevValueRange,
          [group.value]: { ...prevValueRange[group.value], [groupKey]: optionValue },
        }));
      }
    } else {
      setNumberRangeValue((prevValueRange) => ({
        ...prevValueRange,
        [group.value]: { ...prevValueRange[group.value], [groupKey]: optionValue },
      }));
    }
    if (errorFields.includes(`${group.value}-${group.item[1].value}`)) {
      const filterError = errorFields.filter(
        (item) => item !== `${group.value}-${group.item[1].value}`
      );
      setErrorFields(filterError);
    }
  };
  const handleBlurRangeValue2Change = (
    group: Group,
    value1: string,
    value2: string,
    errorValue: string
  ) => {
    const numberRange = numberRangeValue[group.value] || {};
    const maxValue = numberRange[group.item[0].value] || null;
    if (!maxValue) {
      setNumberRangeValue((prevValueRange) => ({
        ...prevValueRange,
        [group.value]: { ...prevValueRange[group.value], [group.item[0].value]: '0' },
      }));
    }
    if (Number(value1) > Number(value2) && !errorFields.includes(errorValue)) {
      setErrorFields((prevErrors) => [...prevErrors, errorValue]);
    } else if (Number(value1) < Number(value2) && errorFields.includes(errorValue)) {
      const filterError = errorFields.filter((item) => item !== errorValue);
      setErrorFields(filterError);
    }
  };

  useEffect(() => {
    let filterList = [];
    filterList = rangeDateFinal.concat(rangeValueFinal);
    filterList = filterList.concat(selectedOptionsValue);
    filterList = filterList.concat(multiSelectFinal);
    onSelect(filterList);
    setNumberFilters(filterList.length);
  }, [showMenu, rangeDateFinal, rangeValueFinal, selectedOptionsValue, multiSelectFinal]);

  const handleClear = (groupType: string, groupValue: string, groupName: string) => {
    if (groupType === 'date-range') {
      const filteredDateRange = Object.keys(dateRangeValue)
        .filter((key) => key !== groupValue)
        .reduce((obj, key) => {
          obj[key] = dateRangeValue[key];
          return obj;
        }, {} as { [key: string]: any });

      setDateRangeValue(filteredDateRange);
    }
    if (groupType === 'value-range') {
      const filteredValueRange = Object.keys(numberRangeValue)
        .filter((key) => key !== groupValue)
        .reduce((obj, key) => {
          obj[key] = numberRangeValue[key];
          return obj;
        }, {} as { [key: string]: any });

      setNumberRangeValue(filteredValueRange);
    }
    if (groupType === 'drop-down' || groupType === 'input') {
      const filteredValueOptions = Object.keys(selectedOptions)
        .filter((key) => key !== groupName)
        .reduce((obj, key) => {
          obj[key] = selectedOptions[key];
          return obj;
        }, {} as { [key: string]: any });
      setSelectedOptions(filteredValueOptions);
    }
    if (groupType === 'multi-select') {
      const filteredValueOptions = Object.keys(multiSelectValue)
        .filter((key) => key !== groupValue)
        .reduce((obj, key) => {
          obj[key] = multiSelectValue[key];
          return obj;
        }, {} as { [key: string]: any });
      setMultiSelectValue(filteredValueOptions);
    }
    if (groupType === 'multi-select-drop-down') {
      const filteredValueOptions = Object.keys(multiSelectValue)
        .filter((key) => key !== groupValue)
        .reduce((obj, key) => {
          obj[key] = multiSelectValue[key];
          return obj;
        }, {} as { [key: string]: any });
      setMultiSelectValue(filteredValueOptions);
    }
  };

  const handleMultiSelect = (groupType: string, groupValue: string) => {
    setMultiSelectValue((prevValueRange) => {
      const currentValues = prevValueRange[groupType] || [];

      const updatedValues = currentValues.includes(groupValue)
        ? currentValues.filter((value) => value !== groupValue)
        : [...currentValues, groupValue];

      return {
        ...prevValueRange,
        [groupType]: updatedValues,
      };
    });
  };

  const handleMultiSelectDropDown = (groupType: string, groupValue: string[]) => {
    setMultiSelectValue((prevValueRange) => {
      return {
        ...prevValueRange,
        [groupType]: groupValue,
      };
    });
  };

  const renderDropdown = (group: Group) => {
    return (
      <div ref={dropDownRef}>
        <AppDropDown2
          value={selectedOptions[group.name]}
          items={[
            ...group.item.map((item) => ({
              label: item.label,
              value: item.value,
              labelInElement: (
                <div className="flex m-2 items-center">
                  <div className="text-black text-sm">{item.label}</div>
                </div>
              ),
            })),
          ]}
          onSelectChange={(item) => {
            if (item?.value) {
              handleOptionSelect(group.name, item.value);
            }
          }}
        />
      </div>
    );
  };

  const renderMultiDropdown = (group: Group) => {
    return (
      <div ref={dropDownRef}>
        <AppDropDown2
          isSearchable={false}
          isCreatable={false}
          closeMenu={false}
          isMulti
          showSelectedItems
          value={null}
          multiValue={multiSelectValue?.[group.value]}
          items={[
            ...group.item.map((item) => ({
              label: item.label,
              value: item.value,
              labelInElement: (
                <div className="flex m-2 items-center">
                  <AppCheckBox
                    className="mr-2"
                    isChecked={multiSelectValue?.[group.value]?.includes(item.value)}
                  />
                  <div className="text-black text-sm">{item.label}</div>
                </div>
              ),
            })),
          ]}
          onMultiSelectChange={(data) => {
            if (data) {
              const valueList: string[] = data.map((item) => item.value);
              handleMultiSelectDropDown(group.value, valueList);
            }
          }}
        />
      </div>
    );
  };

  const renderRadioOption = (group: Group) => {
    return (
      <div>
        {group.item.map((item) => (
          <button
            key={item.value}
            className="flex flex-row align-center w-full py-1 items-center"
            onClick={(event) => {
              event.stopPropagation();
              handleOptionSelect(group.name, item.value);
            }}>
            <RadioButton
              size={20}
              color={
                selectedOptions[group.name] === item.value ? COLORS.PRIMARY_500 : COLORS.GREY_300
              }
              weight={selectedOptions[group.name] === item.value ? 'fill' : undefined}
            />
            <div className="grouped-dropdown-option-text ml-4">{item.label}</div>
          </button>
        ))}
      </div>
    );
  };

  const renderInput = (group: Group) => {
    return (
      <>
        {group.item.map((item) => (
          <div key={item.value}>
            <AppInputField
              id={item.value}
              value={selectedOptions[group.name] || ''}
              onTextChange={(text) => {
                handleOptionSelect(group.name, text);
              }}
              placeholder={item?.placeholder ?? ''}
            />
          </div>
        ))}
      </>
    );
  };
  const renderMultiSelect = (group: Group) => {
    return (
      <>
        {group.item.map((item) => (
          <div
            key={item.value}
            className="flex mb-2 items-center"
            onClick={() => handleMultiSelect(group.value, item.value)}>
            <AppCheckBox isChecked={multiSelectValue?.[group.value]?.includes(item.value)} />
            <div className="ml-2 text-sm">{item.label}</div>
          </div>
        ))}
      </>
    );
  };
  const renderAmountRange = (group: Group) => {
    const numberRange = numberRangeValue[group.value] || {};

    return (
      <div className="grouped-amount-range mb-2">
        <div className="full">
          <AppInputField
            id={group.item[0].value}
            value={numberRange[group.item[0].value] || ''}
            label={group.item[0].label}
            inputType="number"
            onTextChange={(text) => {
              handleRangeValueChange(group, group.item[0].value, text);
            }}
            placeholder={group.item[0]?.placeholder ?? ''}
            onBlur={(e) => {
              handleBlurRangeValue1Change(group, group.item[1].value, e.target.value);
            }}
          />
        </div>
        <div className="full ml-4">
          <AppInputField
            id={group.item[1].value}
            value={numberRange[group.item[1].value] || ''}
            label={group.item[1].label}
            inputType="number"
            onTextChange={(text) => {
              handleRangeValueChange(group, group.item[1].value, text);
            }}
            placeholder={group.item[1]?.placeholder ?? ''}
            onBlur={(e) => {
              handleBlurRangeValue2Change(
                group,
                numberRange[group.item[0].value],
                e.target.value,
                `${group.value}-${group.item[1].value}`
              );
            }}
            errorText={
              errorFields.includes(`${group.value}-${group.item[1].value}`)
                ? `Should be greater than ${group.item[0].label}`
                : ''
            }
            minValue={Number(numberRange[group.item[0].value]) || 0}
          />
        </div>
      </div>
    );
  };

  const renderDateRange = (group: Group) => {
    const dateRange = dateRangeValue[group.value] || {};
    return (
      <div className="grouped-amount-range mb-2">
        <div className="full">
          <AppDatePicker
            label={group.item[0].label}
            onDateSelected={(date) => {
              if (date) {
                handleDateValueChange(group, group.item[0].value, date, group.item[1].value);
              }
            }}
            selectedDate={dateRange[group.item[0].value] || null}
            dateFormat="MMM yyyy"
            showMonthYearPicker={true}
            maximumDate={dateRange[group.item[1].value] || null}
            onDateSelect={(date, event) => event.stopPropagation()}
          />
        </div>
        <div className="full ml-4">
          <AppDatePicker
            label={group.item[1].label}
            onDateSelected={(date) => {
              if (date) {
                const year = date.getFullYear();
                const month = date.getMonth();
                const lastDayOfMonth = new Date(year, month + 1, 0);
                handleDateValueChange(
                  group,
                  group.item[1].value,
                  lastDayOfMonth,
                  group.item[1].value
                );
              }
            }}
            selectedDate={dateRange[group.item[1].value] || null}
            dateFormat="MMM yyyy"
            showMonthYearPicker={true}
            minumumDate={dateRange[group.item[0].value] || null}
            onDateSelect={(date, event) => event.stopPropagation()}
          />
        </div>
      </div>
    );
  };

  const renderGroup = (group: Group) => {
    if (group.type === 'drop-down') {
      return renderDropdown(group);
    } else if (group.type === 'input') {
      return renderInput(group);
    } else if (group.type === 'value-range') {
      return renderAmountRange(group);
    } else if (group.type === 'date-range') {
      return renderDateRange(group);
    } else if (group.type === 'multi-select') {
      return renderMultiSelect(group);
    } else if (group.type === 'radio-options') {
      return renderRadioOption(group);
    } else if (group.type === 'multi-select-drop-down') {
      return renderMultiDropdown(group);
    }
    return null;
  };

  return (
    <div className="w-full flex flex-col" ref={containerRef}>
      <div
        className="grouped-dropdown-container h-fit py-0.5"
        style={{
          ...inputFieldStyles,
        }}>
        <button
          // disabled={disabled}
          onClick={handleShowDropdown}
          className="grouped-dropdown-input">
          <div
            className={
              numberFilters > 0
                ? 'grouped-dropdown-filter-title-selected'
                : 'grouped-dropdown-filter-title'
            }>
            {numberFilters > 0 ? `Filter(${numberFilters})` : 'Filter'}
          </div>
          <div className="dropdown-tools">
            <div className="dropdown-tool">
              <Icon disabled={false} />
            </div>
          </div>
        </button>
        {showMenu && (
          <div className="grouped-dropdown-menu h-fit">
            {options.map((group, i) => (
              <div
                key={group.name}
                className={`${options.length - 1 !== i ? 'border-b mt-3 pb-3' : 'mt-3 pb-3'}`}>
                <div className="grouped-dropdown-option-header-container">
                  <h3 className="grouped-dropdown-option-header">{group.name}</h3>
                  <button
                    className="grouped-dropdown-filter-title-selected"
                    onClick={() => handleClear(group.type, group.value, group.name)}>
                    Clear
                  </button>
                </div>
                {renderGroup(group)}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AppGroupedDropdown;
