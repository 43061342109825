import React, { useState, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface VirtualizedListProps {
  itemsLength: number;
  itemHeight: number;
  containerHeight: number;
  className?: string;
  renderRow: (item: { id: string; content: React.ReactNode }) => React.ReactNode;
  refreshScroll?: boolean;
}

export const AppVirtualizedList: React.FC<VirtualizedListProps> = ({
  itemsLength,
  itemHeight,
  containerHeight,
  renderRow,
  className,
  refreshScroll,
}) => {
  const [scrollTop, setScrollTop] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refreshScroll !== undefined && scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
      setScrollTop(0);
    }
  }, [refreshScroll]);

  const items = Array.from({ length: itemsLength }, (_, index) => ({
    id: index.toString(),
    content: `Item ${index + 1}`,
  }));

  const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
  const valueInPx = (containerHeight / 100) * viewportHeight;

  const startIndex = Math.floor(scrollTop / itemHeight);
  const endIndex = Math.min(startIndex + Math.ceil(valueInPx / itemHeight), items.length);
  const visibleItems = items.slice(startIndex, endIndex);
  const invisibleItemsHeight = (startIndex + visibleItems.length - endIndex) * itemHeight;

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    setScrollTop(event.currentTarget.scrollTop);
  };

  return (
    <div
      ref={scrollContainerRef}
      style={{ height: `${containerHeight}vh`, overflowY: 'scroll' }}
      onScroll={handleScroll}
      className={twMerge('pb-[240px]', className ? className : '')}>
      <div style={{ height: `${items.length * itemHeight}px` }}>
        <div
          style={{
            position: 'relative',
            height: `${visibleItems.length * itemHeight}px`,
            top: `${startIndex * itemHeight}px`,
          }}>
          {visibleItems.map((item, index) => (
            <div key={index} style={{ height: `${itemHeight}px` }}>
              {renderRow(item)}
            </div>
          ))}
        </div>
        <div style={{ height: `${invisibleItemsHeight}px` }} />
      </div>
    </div>
  );
};
