import { Modal } from '@mui/material';
import React, { useMemo, useEffect, useState } from 'react';
import { X } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import { AppButton } from '../AppButton/AppButton';
import { AppDropDown } from '../AppDropDown/AppDropDown';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { useParamMainBuyerId } from '../../../utils/hooks/useParamMainBuyerId';
import { AppUserApiResponse } from '@dill/dill-shared/dist/src';
import { getUserByBuyers } from '../../../redux/services/authService';
import { getJobs, updateBillingRepJobs } from '../../../redux/services/jobsService';
import { openDillToast } from '../../../utils/helpers';

export const AppCustomerSettingsModal = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const { userBuyers } = useAppSelector((state) => state.auth);
  const [buyerRep, setBuyerRep] = useState<AppUserApiResponse | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedMainBuyerId && open) {
      dispatch(getUserByBuyers({ buyerId: selectedMainBuyerId }));
    }
  }, [open]);

  const fetchUserBuyer = useMemo(() => {
    if (userBuyers.length > 0) {
      return userBuyers;
    }
    return [];
  }, [userBuyers]);

  const handleCloseModal = () => {
    setBuyerRep(null);
    setLoading(false);
    handleClose();
  };

  const handleUpdateJobBillingRep = async () => {
    setLoading(true);
    if (buyerRep?.userIds && selectedMainBuyerId) {
      const billingRepJobs = await dispatch(
        updateBillingRepJobs({ ...buyerRep.userIds, mainBuyerId: selectedMainBuyerId })
      );
      if (billingRepJobs.type === 'jobs/updateBillingRepJobs/fulfilled') {
        openDillToast({ type: 'SUCCESS', message: 'Successfully updated colleague' });
        await dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
        handleCloseModal();
      } else {
        openDillToast({ type: 'ERROR', message: 'Failed to update colleague' });
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-40 w-[540px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
        <h2 className="text-lg font-semibold mx-4 my-3">Company Settings</h2>
        <X
          className="absolute right-4 top-4 cursor-pointer"
          color={COLORS.GREY_500}
          onClick={handleCloseModal}
        />
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
        <div className="p-4 flex h-20">
          <div className="w-full mr-2 text-base">
            Colleague out of office? Change the billing rep on all jobs
          </div>
          <div className="w-full">
            <AppDropDown
              value={buyerRep ? buyerRep.userIds?.userId ?? null : null}
              items={[
                ...fetchUserBuyer.map((userBuyer) => {
                  return {
                    label: `${userBuyer?.firstName ?? ''} ${userBuyer?.lastName ?? ''}`,
                    value: userBuyer.userIds?.userId ?? '',
                  };
                }),
              ]}
              onSelectChange={(item) => {
                if (item?.value) {
                  const uu = fetchUserBuyer.find((val) => val.id === item.value);
                  if (uu) {
                    setBuyerRep(uu);
                  }
                }
              }}
            />
          </div>
        </div>
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
        <div className="flex justify-end mx-3 mb-2">
          <AppButton
            text="Cancel"
            type="TERTIARY"
            onClick={handleCloseModal}
            buttonStyles={{ marginRight: '8px' }}
          />
          <AppButton
            text={'Update'}
            onClick={handleUpdateJobBillingRep}
            buttonStyles={{
              width: '100px',
              height: '40px',
            }}
            isLoading={loading}
            isDisabled={buyerRep === null || loading}
          />
        </div>
      </div>
    </Modal>
  );
};
