import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  firebaseSendLoginEmail,
  firebaseConfirmLoginUser,
  checkUserExists,
  getUserDetails,
  firebaseLoginEmailAndPassword,
  logoutUser,
  updateLoggedInUserPassword,
  resetPassword,
  firebaseConfirmPasswordReset,
  checkUserIsSetupWithCompany,
  firebaseSendPasswordResetEmail,
  newUserNotifyDill,
} from '../../redux/services/authService';
import { AppInputField } from '../../components/general/AppInputField/AppInputField';
import dillLogoWithText from '../../assets/svg/dill_logo_with_text.svg';
import { AppButton } from '../../components/general/AppButton/AppButton';
import { myFirebaseAuth } from '../../firebase/config';
import { getBasicSupplier } from '../../redux/services/buyersService';
import { resetUserCheck, updateSendLoginEmailState } from '../../redux/globalSlices/authSlice';
import { z } from 'zod';
import { AppCheckBox } from '../../components/general/AppCheckBox/AppCheckBox';
import { Checks } from 'phosphor-react';
import { getSavedPastPath, openDillToast, removeQueryParam } from '../../utils/helpers';

const loginModes = [
  'ENTER_EMAIL',
  'EMAIL_LINK_SENT',
  'ENTER_PASSWORD',
  'NEW_USER',
  'CREATE_PASSWORD',
  'DRIVERS_NOT_ALLOWED',
  'PASSWORD_RESET',
  'EMAIL_NEEDS_REVERIFICATION',
] as const;
const LoginModeSchema = z.enum(loginModes);
type LoginMode = z.infer<typeof LoginModeSchema>;

function LoginPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    isLoginEmailSent,
    userCheckState,
    isValidatingUser,
    firebaseUserId,
    isPasswordSet,
    isEmailVerified,
    globalPastPath,
    loadingList: authLoadingList,
  } = useAppSelector((state) => state.auth);
  const { basicSupplier } = useAppSelector((state) => state.buyers);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [oobCode, setOobCode] = useState('');
  const [nextPageNavigationPath, setNextPageNavigationPath] = useState('');
  const [goToNextPath, setGoToNextPath] = useState({});
  const [isPasswordResetLinkSent, setIsPasswordResetLinkSent] = useState(false);
  const [isAcceptTerms, setIsAcceptTerms] = useState(false);
  const [currentLoginMode, setCurrentLoginMode] = useState<LoginMode>('ENTER_EMAIL');
  const [isEmailForLinkSignInSaved, setIsEmailForLinkSignInSaved] = useState(true);

  const isAnyDataLoading = () => {
    const check = authLoadingList.filter((item) => item === 'checkUserExists');
    if (check.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (myFirebaseAuth.currentUser && isPasswordSet && nextPageNavigationPath) {
      (async () => {
        await dispatch(getUserDetails());
        setGoToNextPath({});
      })();
    }

    return () => {};
  }, [nextPageNavigationPath]);

  useEffect(() => {
    if (myFirebaseAuth.currentUser && isPasswordSet && nextPageNavigationPath) {
      navigate(nextPageNavigationPath);
    }

    return () => {};
  }, [goToNextPath]);

  const handleNavigationAfterUserLogin = () => {
    if (myFirebaseAuth.currentUser && isPasswordSet) {
      const pastPath = getSavedPastPath();
      const newSignIn = localStorage.getItem('newSignIn');
      const referPath = localStorage.getItem('referPath');
      if (newSignIn) {
        if (referPath) {
          const params = new URLSearchParams(referPath.split('?')[1]);
          const email1 = params.get('email');
          if (email1 && email1.toLowerCase() === myFirebaseAuth.currentUser.email) {
            setNextPageNavigationPath(referPath);
          } else {
            setNextPageNavigationPath('/customers');
          }
          localStorage.removeItem('referPath');
        } else if (pastPath?.startsWith('/lienReleases') && pastPath.includes('email=')) {
          const params = new URLSearchParams(pastPath.split('?')[1]);
          const email1 = params.get('email');
          const buyerId = params.get('buyerId');
          const supplierDomainId = params.get('supplierDomainId');
          if (
            email1 &&
            email1.toLocaleLowerCase() === myFirebaseAuth.currentUser.email &&
            buyerId
          ) {
            (async () => {
              const res = await dispatch(
                checkUserIsSetupWithCompany({ email: email1, companyId: buyerId })
              );
              if (
                res.type === 'auth/checkUserIsSetupWithCompany/fulfilled' &&
                !res.payload.isSetUp
              ) {
                setNextPageNavigationPath(
                  `/refer?email=${email1}&buyerId=${buyerId}&type=SUB_SUPPLIER&isAcceptSetup=true`
                );
              } else {
                setNextPageNavigationPath(
                  removeQueryParam({ path: pastPath, paramNameToRemove: 'email' })
                );
              }
            })();
          } else if (
            email1 &&
            email1.toLocaleLowerCase() === myFirebaseAuth.currentUser.email &&
            supplierDomainId
          ) {
            (async () => {
              const res = await dispatch(
                checkUserIsSetupWithCompany({ email: email1, supplierDomainId })
              );
              if (
                res.type === 'auth/checkUserIsSetupWithCompany/fulfilled' &&
                !res.payload.isSetUp
              ) {
                setNextPageNavigationPath(
                  `/refer?email=${email1}&supplierDomainId=${supplierDomainId}&type=BUYER&isAcceptSetup=true`
                );
              } else {
                setNextPageNavigationPath(
                  removeQueryParam({ path: pastPath, paramNameToRemove: 'email' })
                );
              }
            })();
          }
        } else if (
          pastPath?.startsWith('/companies') &&
          pastPath?.includes('/invoices') &&
          pastPath.includes('email=')
        ) {
          const params = new URLSearchParams(pastPath.split('?')[1]);
          const email1 = params.get('email');
          const supplierDomainId = pastPath.split('?')[0].split('/').slice(-1)[0];
          if (
            email1 &&
            email1.toLocaleLowerCase() === myFirebaseAuth.currentUser.email &&
            supplierDomainId
          ) {
            (async () => {
              const res = await dispatch(
                checkUserIsSetupWithCompany({ email: email1, supplierDomainId })
              );
              if (
                res.type === 'auth/checkUserIsSetupWithCompany/fulfilled' &&
                !res.payload.isSetUp
              ) {
                setNextPageNavigationPath(
                  `/refer?email=${email1}&supplierDomainId=${supplierDomainId}&type=BUYER&isAcceptSetup=true`
                );
              } else {
                setNextPageNavigationPath(
                  removeQueryParam({ path: pastPath, paramNameToRemove: 'email' })
                );
              }
            })();
          } else if (email1 && email1.toLocaleLowerCase() === myFirebaseAuth.currentUser.email) {
            setNextPageNavigationPath(
              removeQueryParam({ path: pastPath, paramNameToRemove: 'email' })
            );
          }
        } else if (pastPath) {
          setNextPageNavigationPath(pastPath);
        } else {
          setNextPageNavigationPath('/customers');
        }
      } else if (referPath) {
        const params = new URLSearchParams(referPath.split('?')[1]);
        const email1 = params.get('email');
        if (email1 && email1.toLocaleLowerCase() === myFirebaseAuth.currentUser.email) {
          setNextPageNavigationPath(referPath);
        } else if (pastPath) {
          setNextPageNavigationPath(pastPath);
        } else {
          setNextPageNavigationPath('/customers');
        }
        localStorage.removeItem('referPath');
      } else if (globalPastPath) {
        setNextPageNavigationPath(globalPastPath);
      } else if (pastPath) {
        if (pastPath?.startsWith('/lienReleases') && pastPath.includes('email=')) {
          const params = new URLSearchParams(pastPath.split('?')[1]);
          const email1 = params.get('email');
          const buyerId = params.get('buyerId');
          const supplierDomainId = params.get('supplierDomainId');
          if (
            email1 &&
            email1.toLocaleLowerCase() === myFirebaseAuth.currentUser.email &&
            buyerId
          ) {
            (async () => {
              const res = await dispatch(
                checkUserIsSetupWithCompany({ email: email1, companyId: buyerId })
              );
              if (
                res.type === 'auth/checkUserIsSetupWithCompany/fulfilled' &&
                !res.payload.isSetUp
              ) {
                setNextPageNavigationPath(
                  `/refer?email=${email1}&buyerId=${buyerId}&type=SUB_SUPPLIER&isAcceptSetup=true`
                );
              } else {
                setNextPageNavigationPath(
                  removeQueryParam({ path: pastPath, paramNameToRemove: 'email' })
                );
              }
            })();
          } else if (
            email1 &&
            email1.toLocaleLowerCase() === myFirebaseAuth.currentUser.email &&
            supplierDomainId
          ) {
            (async () => {
              const res = await dispatch(
                checkUserIsSetupWithCompany({ email: email1, supplierDomainId })
              );
              if (
                res.type === 'auth/checkUserIsSetupWithCompany/fulfilled' &&
                !res.payload.isSetUp
              ) {
                setNextPageNavigationPath(
                  `/refer?email=${email1}&supplierDomainId=${supplierDomainId}&type=BUYER&isAcceptSetup=true`
                );
              } else {
                setNextPageNavigationPath(
                  removeQueryParam({ path: pastPath, paramNameToRemove: 'email' })
                );
              }
            })();
          }
        } else if (
          pastPath?.startsWith('/companies') &&
          pastPath?.includes('/invoices') &&
          pastPath.includes('email=')
        ) {
          const params = new URLSearchParams(pastPath.split('?')[1]);
          const email1 = params.get('email');
          const supplierDomainId = pastPath.split('?')[0].split('/').slice(-1)[0];
          if (
            email1 &&
            email1.toLocaleLowerCase() === myFirebaseAuth.currentUser.email &&
            supplierDomainId
          ) {
            (async () => {
              const res = await dispatch(
                checkUserIsSetupWithCompany({ email: email1, supplierDomainId })
              );
              if (
                res.type === 'auth/checkUserIsSetupWithCompany/fulfilled' &&
                !res.payload.isSetUp
              ) {
                setNextPageNavigationPath(
                  `/refer?email=${email1}&supplierDomainId=${supplierDomainId}&type=BUYER&isAcceptSetup=true`
                );
              } else {
                setNextPageNavigationPath(
                  removeQueryParam({ path: pastPath, paramNameToRemove: 'email' })
                );
              }
            })();
          } else if (email1 === myFirebaseAuth.currentUser.email) {
            setNextPageNavigationPath(
              removeQueryParam({ path: pastPath, paramNameToRemove: 'email' })
            );
          }
        } else {
          setNextPageNavigationPath(pastPath);
        }
      } else if (pastPath) {
        setNextPageNavigationPath(pastPath);
      } else {
        setNextPageNavigationPath('/customers');
      }
    }
  };

  useEffect(() => {
    if (!isAnyDataLoading()) {
      if (userCheckState === 'DRIVERS_NOT_ALLOWED') {
        setCurrentLoginMode('DRIVERS_NOT_ALLOWED');
      } else if (userCheckState === 'USER_EXISTS' && !myFirebaseAuth.currentUser) {
        if (isLoginEmailSent) {
          setCurrentLoginMode('EMAIL_LINK_SENT');
        } else if (isEmailVerified && isPasswordSet && email) {
          setCurrentLoginMode('ENTER_PASSWORD');
        } else if (isEmailVerified && !isPasswordSet && email) {
          setCurrentLoginMode('EMAIL_NEEDS_REVERIFICATION');
        } else if (isEmailVerified && isPasswordSet && !email) {
          setCurrentLoginMode('ENTER_EMAIL');
        } else if (!isEmailVerified && email && currentLoginMode !== 'EMAIL_LINK_SENT') {
          dispatch(firebaseSendLoginEmail({ email }));
          setIsEmailForLinkSignInSaved(true);
          setCurrentLoginMode('EMAIL_LINK_SENT');
        } else if (!email) {
          setCurrentLoginMode('ENTER_EMAIL');
        } else {
          // setCurrentLoginMode('CREATE_PASSWORD');
        }
      } else if (userCheckState === 'NEW_USER' && !myFirebaseAuth.currentUser) {
        setCurrentLoginMode('NEW_USER');
      } else if (myFirebaseAuth.currentUser && !isPasswordSet) {
        if (userCheckState === 'USER_EXISTS') {
          if (isLoginEmailSent) {
            setCurrentLoginMode('EMAIL_LINK_SENT');
          } else if (!isPasswordSet && isEmailVerified && myFirebaseAuth.currentUser.email) {
            setCurrentLoginMode('CREATE_PASSWORD');
            setEmail(myFirebaseAuth.currentUser.email);
          } else if (!isEmailVerified && email && currentLoginMode !== 'EMAIL_LINK_SENT') {
            dispatch(logoutUser());
          }
        } else {
          if (myFirebaseAuth.currentUser.email) {
            setEmail(myFirebaseAuth.currentUser.email);
            dispatch(checkUserExists({ email: myFirebaseAuth.currentUser.email }));
          }
        }
      } else if (myFirebaseAuth.currentUser && isPasswordSet) {
        if (!isEmailVerified && email && currentLoginMode !== 'EMAIL_LINK_SENT') {
          dispatch(logoutUser());
        } else {
          handleNavigationAfterUserLogin();
        }
      }
    }

    return () => {};
  }, [
    isPasswordSet,
    userCheckState,
    isLoginEmailSent,
    myFirebaseAuth.currentUser,
    isEmailVerified,
    location.pathname,
  ]);

  useEffect(() => {
    if (!myFirebaseAuth.currentUser) {
      const params = new URLSearchParams(location.search);
      const urlModeValue = params.get('mode');
      const apiKey = params.get('apiKey');
      const oobCodeValue = params.get('oobCode');
      // complete user login after clicking link in email
      if (oobCodeValue) {
        setOobCode(oobCodeValue);
      }
      if (urlModeValue === '' && apiKey) {
        dispatch(firebaseConfirmLoginUser({ url: window.location.href }));
      }
      if (urlModeValue === 'signIn' && apiKey) {
        localStorage.setItem('newSignIn', 'newSignIn');
        const emailForSignIn = localStorage.getItem('emailForSignIn');
        if (emailForSignIn) {
          dispatch(firebaseConfirmLoginUser({ url: window.location.href }));
          setIsEmailForLinkSignInSaved(true);
          setEmail(emailForSignIn);
        } else {
          setIsEmailForLinkSignInSaved(false);
          openDillToast({
            type: 'ERROR',
            message:
              'Failed to verify your email. You might have opened the verification link for a different browser. You can resend a verification email for this browser by entering your email below or open the verification link with the previous browser',
          });
        }
      }

      if (urlModeValue === 'resetPassword' && apiKey && oobCodeValue) {
        // dispatch(firebaseConfirmLoginUser({ url: window.location.href }));
        setPassword('');
        setOobCode(oobCodeValue);
        const emailForPasswordReset = localStorage.getItem('emailForPasswordReset');
        if (emailForPasswordReset) {
          setEmail(emailForPasswordReset);
        }
        setCurrentLoginMode('PASSWORD_RESET');
      }

      if (location.pathname.startsWith('/portal/') || location.pathname.startsWith('/login/')) {
        // gets supplier details when use portal path
        const pathSplit = location.pathname.split('/');
        if (pathSplit.length > 2) {
          dispatch(getBasicSupplier({ supplierDomainId: pathSplit[2] }));
        }
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    const emailFromLink = sessionStorage.getItem('emailFromLink');
    if (emailFromLink && !myFirebaseAuth.currentUser) {
      setEmail(emailFromLink);
    }
    if (
      emailFromLink &&
      (!myFirebaseAuth.currentUser ||
        (myFirebaseAuth.currentUser &&
          myFirebaseAuth.currentUser.email !== emailFromLink.toLocaleLowerCase()))
    ) {
      (async () => {
        await dispatch(logoutUser());
        setEmail(emailFromLink);
        sessionStorage.removeItem('emailFromLink');
        dispatch(checkUserExists({ email: emailFromLink, thenLogIn: false }));
      })();
    }
    if (
      emailFromLink &&
      myFirebaseAuth.currentUser &&
      myFirebaseAuth.currentUser.email !== emailFromLink.toLocaleLowerCase()
    ) {
      setEmail(emailFromLink);
      localStorage.removeItem('newSignIn');
      dispatch(logoutUser());
      setNextPageNavigationPath('/login');
    }
  }, [myFirebaseAuth.currentUser, userCheckState]);

  useEffect(() => {
    // Get basic supplier details for the past supplier whose invoices where seen by the user
    // setEmail('');
    setPassword('');
    setErrorMessage('');
    setIsAcceptTerms(false);
    const pastPath = getSavedPastPath();
    if (pastPath && pastPath.startsWith('/companies/')) {
      const pathSplit = pastPath.split('/');
      if (pathSplit.length > 3) {
        dispatch(getBasicSupplier({ supplierDomainId: pathSplit[4] }));
      }
    }
  }, []);

  useEffect(() => {
    if (currentLoginMode === 'NEW_USER') {
      dispatch(newUserNotifyDill({ email }));
    }
  }, [currentLoginMode]);

  useEffect(() => {
    // Navigate to the login page of the supplier whose basic details have been fetched
    if (basicSupplier) {
      setNextPageNavigationPath(`/login/${basicSupplier.domainId}`);
    }
  }, [basicSupplier]);

  useEffect(() => {
    if (location.pathname.startsWith('/login')) {
      const params = new URLSearchParams(location.search);
      const emailFromLink = params.get('email');
      if (emailFromLink) {
        sessionStorage.setItem('emailFromLink', emailFromLink);
        navigate('/login');
        // navigate(0);
      }
    }

    return () => {};
  }, [location.pathname, myFirebaseAuth.currentUser]);

  const handleLoginNextClick = async () => {
    // checks if user exists, then login if the user is in our db
    if (email) {
      setErrorMessage('');
      const results = await dispatch(checkUserExists({ email, thenLogIn: false }));
      if (results.payload && results.payload.success) {
        setPassword('');
        setIsEmailForLinkSignInSaved(true);
      } else {
        setErrorMessage('');
        setCurrentLoginMode('NEW_USER');
      }
    } else {
      setErrorMessage('Enter Email address');
    }
  };
  const handleLoginPasswordClick = async () => {
    if (email && password) {
      const results = await dispatch(firebaseLoginEmailAndPassword({ email, password }));
      if (results.type === 'auth/firebaseLoginEmailAndPassword/rejected') {
        setErrorMessage('Invalid Password');
      }
    } else {
      if (!email) {
        setErrorMessage('Email not set, try using different email');
      }
      if (!password) {
        setErrorMessage('Enter Password');
      }
    }
  };
  const handleResetPasswordClick = async () => {
    if (password.length < 6) {
      setErrorMessage('Password should have at least 6 characters');
    } else if (password && oobCode) {
      const results = await dispatch(
        firebaseConfirmPasswordReset({ newPassword: password, oobCode })
      );
      if (results.type === 'auth/firebaseConfirmPasswordReset/rejected') {
        setErrorMessage('Invalid Password');
      } else {
        setErrorMessage('');
        setPassword('');
        setNextPageNavigationPath('/login');
        if (email) {
          setCurrentLoginMode('ENTER_PASSWORD');
        } else {
          setCurrentLoginMode('ENTER_EMAIL');
        }
      }
    } else {
      if (!password) {
        setErrorMessage('Enter Password');
      }
      if (!oobCode) {
        setErrorMessage('Error resetting password. Try again');
        setCurrentLoginMode('ENTER_PASSWORD');
      }
    }
  };
  const handlePasswordSetClick = async () => {
    if (password.length < 6) {
      setErrorMessage('Password should have at least 6 characters');
    } else if (password) {
      const results = await dispatch(updateLoggedInUserPassword({ email, password }));
      if (results.type === 'auth/updateLoggedInUserPassword/rejected') {
        if (results.payload.message) {
          setErrorMessage(results.payload.message);
        } else {
          setErrorMessage('Invalid Password');
        }
      } else if (results.type === 'auth/updateLoggedInUserPassword/fulfilled') {
        setPassword('');
      }
    } else {
      if (!password) {
        setErrorMessage('Enter Password');
      }
    }
  };
  const handleResendEmail = async () => {
    if (email) {
      // When the check was already done, we just login
      if (userCheckState === 'USER_EXISTS') {
        await dispatch(firebaseSendLoginEmail({ email }));
        setIsEmailForLinkSignInSaved(true);
      } else if (userCheckState === 'DRIVERS_NOT_ALLOWED') {
      } else {
        await dispatch(checkUserExists({ email, thenLogIn: true }));
      }
    } else {
      await dispatch(updateSendLoginEmailState(false));
    }
  };

  const handleUseDifferentEmail = async () => {
    await dispatch(logoutUser());
    localStorage.removeItem('pastPathList');
    sessionStorage.removeItem('emailFromLink');
    localStorage.removeItem('newSignIn');
    localStorage.removeItem('emailForSignIn');
    setEmail('');
    dispatch(updateSendLoginEmailState(false));
    dispatch(resetUserCheck());
    // navigate('/login');
    setNextPageNavigationPath('/login');
    setCurrentLoginMode('ENTER_EMAIL');
    setErrorMessage('');
  };

  const handleSendResetPasswordEmail = async () => {
    if (email) {
      const results = await dispatch(firebaseSendPasswordResetEmail({ email }));
      if (results.type === 'auth/resetPassword/rejected') {
      } else {
        setIsPasswordResetLinkSent(true);
      }
    } else {
      setErrorMessage('Email not set, try using different email');
    }
  };

  return (
    <div className="flex w-full h-full justify-center items-center">
      <div className="flex flex-col md:w-1/3  w-3/4 items-center justify-center mx-4">
        {basicSupplier && basicSupplier.logo && basicSupplier.logo?.url && (
          <div className="flex flex-col items-center mb-14">
            <img src={basicSupplier.logo.url} />
            <div className="flex items-center mt-2">
              <p>Powered By</p>
              <img className="h-8 ml-2" src={dillLogoWithText} />
            </div>
          </div>
        )}
        {(!basicSupplier || (basicSupplier && !basicSupplier.logo?.url)) && (
          <div className="flex flex-col items-center mb-14 w-full">
            <img className="w-8/12" src={dillLogoWithText} />
          </div>
        )}
        <div className="flex flex-col GREY_100-BG px-14 py-5 rounded-xl w-full">
          {currentLoginMode === 'ENTER_EMAIL' && (
            <div className="flex flex-col">
              {!isEmailForLinkSignInSaved && (
                <>
                  <p className="text-center text-xs">
                    You might have opened the email verification link for a different browser. A new
                    link for this browser will be sent when you enter your email below
                  </p>
                </>
              )}
              <p className="text-center font-bold text-sm mt-2">Enter your email address</p>

              <div className="mb-6 mt-3 flex w-full">
                <div className="w-full mr-2">
                  <AppInputField
                    value={email}
                    inputType="email"
                    placeholder="Your email address"
                    onTextChange={(text) => {
                      setEmail(text);
                    }}
                  />
                </div>

                <AppButton text={'Next'} onClick={handleLoginNextClick} />
              </div>
            </div>
          )}
          {currentLoginMode === 'EMAIL_LINK_SENT' && (
            <div className="flex flex-col">
              <p className="text-center font-bold text-sm">
                Check your email for a verification link to sign in to your portal. *Make sure to
                open the link from this browser, and to immediately set your password after
                verifying your email*
              </p>
              <p className="text-center text-sm mt-6">
                Didn’t receive the link?{' '}
                <span
                  className="PRIMARY_500-CLR font-bold cursor-pointer"
                  onClick={handleResendEmail}>
                  Resend here
                </span>
              </p>
              <p className="text-center text-sm mt-6">
                If you're still not receiving the link, check with your IT department to whitelist
                the domain <span className="PRIMARY_500-CLR ">usedill.com</span>
              </p>
              <p className="text-center mt-4">
                <span
                  className="PRIMARY_500-CLR font-bold cursor-pointer text-sm"
                  onClick={handleUseDifferentEmail}>
                  Use a different email
                </span>
              </p>
              <p className="text-2xs text-center">
                Current email: <span className="PRIMARY_500-CLR ">{email}</span>
              </p>
            </div>
          )}
          {currentLoginMode === 'NEW_USER' && (
            <div className="flex flex-col">
              <p className="text-center font-bold text-sm">
                We see that you’re a new user. Reach out to{' '}
                <span className="PRIMARY_500-CLR font-bold cursor-pointer whitespace-nowrap">
                  972-339-0799
                </span>{' '}
                to request access to the portal
              </p>
              <p className="text-center mt-4">
                <span
                  className="PRIMARY_500-CLR font-bold cursor-pointer text-sm"
                  onClick={handleUseDifferentEmail}>
                  Use a different email
                </span>
              </p>
              <p className="text-2xs text-center">
                Current email: <span className="PRIMARY_700-CLR ">{email}</span>
              </p>
            </div>
          )}
          {currentLoginMode === 'DRIVERS_NOT_ALLOWED' && (
            <div className="flex flex-col">
              <div className="w-full mr-2 text-center">
                You currently don't have access. Reach out to your company admin to request access
                to the Dill web portal
              </div>
              <p className="text-center mt-4">
                <span
                  className="PRIMARY_500-CLR font-bold cursor-pointer text-sm"
                  onClick={handleUseDifferentEmail}>
                  Use a different email
                </span>
              </p>
              <p className="text-2xs text-center">
                Current email: <span className="PRIMARY_700-CLR ">{email}</span>
              </p>
            </div>
          )}
          {currentLoginMode === 'ENTER_PASSWORD' && (
            <div className="flex flex-col">
              <p className="text-center font-bold text-sm">Enter your password</p>
              <div className="my-6 flex w-full">
                <div className="w-full mr-2">
                  <AppInputField
                    value={password}
                    inputType="password"
                    placeholder="Your password"
                    onTextChange={(text) => {
                      setPassword(text);
                    }}
                  />
                </div>

                <AppButton text="Sign In" onClick={handleLoginPasswordClick} />
              </div>

              <p className="text-center mt-4">
                <span
                  className="PRIMARY_500-CLR font-bold cursor-pointer text-sm"
                  onClick={handleUseDifferentEmail}>
                  Use a different email
                </span>
              </p>
              <p className="text-2xs text-center">
                Current email: <span className="PRIMARY_700-CLR ">{email}</span>
              </p>
            </div>
          )}
          {currentLoginMode === 'PASSWORD_RESET' && (
            <div className="flex flex-col">
              <p className="text-center font-bold text-sm">Reset your password</p>
              <div className="my-6 flex w-full">
                <div className="w-full mr-2">
                  <AppInputField
                    value={password}
                    inputType="password"
                    placeholder="Your password"
                    onTextChange={(text) => {
                      setPassword(text);
                    }}
                  />
                </div>

                <AppButton text="Update" onClick={handleResetPasswordClick} />
              </div>
              <p className="text-center mt-4">
                <span
                  className="PRIMARY_500-CLR font-bold cursor-pointer text-sm"
                  onClick={handleUseDifferentEmail}>
                  Use a different email
                </span>
              </p>
              <p className="text-2xs text-center">
                Current email: <span className="PRIMARY_700-CLR ">{email}</span>
              </p>
            </div>
          )}
          {currentLoginMode === 'CREATE_PASSWORD' && (
            <div className="flex flex-col">
              <p className="text-center font-bold text-sm">Please set a password</p>
              <div className="my-6 flex w-full">
                <div className="w-full mr-2">
                  <AppInputField
                    value={password}
                    inputType="password"
                    placeholder="Your password"
                    onTextChange={(text) => {
                      setPassword(text);
                    }}
                  />
                </div>
              </div>
              <div className="flex mb-3">
                <AppCheckBox
                  isChecked={isAcceptTerms}
                  onClick={() => {
                    setIsAcceptTerms(!isAcceptTerms);
                  }}
                  className="px-1 py-2  mx-0 "
                  checkSize={12}
                />
                <p className="text-xs text-left ml-2  space-y-2 w-full font-medium">
                  By checking this box you agree to{' '}
                  <a
                    href="https://www.usedill.com/terms"
                    target="_blank"
                    className="PRIMARY_500-CLR underline">
                    Our Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.usedill.com/privacy"
                    target="_blank"
                    className="PRIMARY_500-CLR underline">
                    Privacy Policy
                  </a>
                  , as well as our partner{' '}
                  <a
                    href="https://www.dwolla.com/legal/tos/"
                    target="_blank"
                    className="PRIMARY_500-CLR underline">
                    Dwolla’s Terms of Service
                  </a>{' '}
                  and{' '}
                  <a
                    href="https://www.dwolla.com/legal/privacy/"
                    target="_blank"
                    className="PRIMARY_500-CLR underline">
                    Privacy Policy
                  </a>
                </p>
              </div>
              <div className="flex justify-center">
                <AppButton
                  text="Agree & Continue"
                  onClick={handlePasswordSetClick}
                  isDisabled={!isAcceptTerms}
                />
              </div>

              <p className="text-center mt-4">
                <span
                  className="PRIMARY_500-CLR font-bold cursor-pointer text-sm"
                  onClick={handleUseDifferentEmail}>
                  Use a different email
                </span>
              </p>
              <p className="text-2xs text-center">
                Current email: <span className="PRIMARY_700-CLR ">{email}</span>
              </p>
            </div>
          )}
          {currentLoginMode === 'EMAIL_NEEDS_REVERIFICATION' && (
            <div className="flex flex-col">
              <p className="text-center font-bold text-sm">
                Your email needs to be reverified. This could be that you did not set your password
                immediately after you last verified your email
              </p>

              <div className="flex justify-center mt-1">
                <AppButton text="Resend verification link" onClick={handleResendEmail} />
              </div>

              <p className="text-center mt-4">
                <span
                  className="PRIMARY_500-CLR font-bold cursor-pointer text-sm"
                  onClick={handleUseDifferentEmail}>
                  Use a different email
                </span>
              </p>
              <p className="text-2xs text-center">
                Current email: <span className="PRIMARY_700-CLR ">{email}</span>
              </p>
            </div>
          )}
        </div>
        {currentLoginMode === 'ENTER_PASSWORD' && (
          <p className="text-center mt-4">
            <span
              className="PRIMARY_500-CLR font-bold cursor-pointer text-xs"
              onClick={handleSendResetPasswordEmail}>
              Forgot password? Send password reset link
            </span>
          </p>
        )}
        {errorMessage && <p className="ERROR_500-CLR text-sm mt-3">{errorMessage}</p>}
        {isPasswordResetLinkSent && (
          <div className="flex items-center py-2 px-3 SUCCESS_100-BG  SUCCESS_900-CLR rounded-md mt-2">
            <Checks size={20} />
            <p className="text-sm  ml-2">Password reset link sent to your email!</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoginPage;
