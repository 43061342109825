import React, { useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../../utils/colors';
import { twMerge } from 'tailwind-merge';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';
import { QuickbooksInvoice, formatMoney } from '@dill/dill-shared/dist/src';
import emptyListImg from '../../../assets/images/emptyList.png';
import moment from 'moment';
import { InvoiceReference } from '../../../constants/invoice';

export const AppInvoiceTable = ({
  invoices,
  onSelectInvoice,
  invoicesSelected,
}: {
  invoices: {
    paidInvoices: QuickbooksInvoice[];
    unPaidInvoices: QuickbooksInvoice[];
  };
  onSelectInvoice: (invoices: InvoiceReference[]) => void;
  invoicesSelected?: QuickbooksInvoice[];
}) => {
  const [selectedInvoicesOption, setSelectedInvoicesOption] = useState<'PAID' | 'UNPAID'>('PAID');
  const [selectedInvoices, setSelectedInvoices] = useState<QuickbooksInvoice[]>([]);

  const columns = useMemo(() => {
    return [
      { id: 'select', name: '', width: 50, cellType: 'VIEW_TEXT' },
      { id: 'invoiceDate', name: 'Invoice Date', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'invoiceNumber', name: 'Invoice #', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'amount', name: 'Amount', width: 100, cellType: 'VIEW_TEXT' },
    ];
  }, []);
  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );
  const filteredInvoices = useMemo(() => {
    if (selectedInvoicesOption === 'UNPAID') {
      return invoices.unPaidInvoices;
    }
    return invoices.paidInvoices;
  }, [selectedInvoicesOption]);

  const totalAmount = useMemo(() => {
    let accumulatedAmount = 0;
    if (selectedInvoices.length > 0) {
      selectedInvoices.forEach((invoice) => {
        if (invoice.amount) {
          accumulatedAmount += invoice.amount;
        }
      });
    }
    return accumulatedAmount;
  }, [selectedInvoices]);

  useEffect(() => {
    if (invoicesSelected) {
      setSelectedInvoices(invoicesSelected);
    }
  }, [invoicesSelected]);

  useEffect(() => {
    const invoicesUpdate = selectedInvoices.map((selInv) => {
      return {
        invoiceNumber: selInv.docNumber,
        amount: Number(selInv.amount),
      };
    });
    onSelectInvoice(invoicesUpdate);
  }, [selectedInvoices]);

  return (
    <div className="border rounded">
      <div className="flex">
        <div
          onClick={() => setSelectedInvoicesOption('PAID')}
          className={twMerge(
            'w-1/2 p-2 ',
            selectedInvoicesOption === 'PAID'
              ? 'border-b-2 PRIMARY_500-BORDER PRIMARY_500-CLR'
              : 'hover:bg-blue-100 cursor-pointer'
          )}>
          Paid
        </div>
        <div
          onClick={() => setSelectedInvoicesOption('UNPAID')}
          className={twMerge(
            'w-1/2 p-2 ',
            selectedInvoicesOption === 'UNPAID'
              ? 'border-b-2 PRIMARY_500-BORDER PRIMARY_500-CLR'
              : 'hover:bg-blue-100 cursor-pointer'
          )}>
          Unpaid
        </div>
      </div>
      <div className="w-full flex border-b justify-between px-4 sticky top-0 PRIMARY_50-BG items-center z-1 ">
        {columns.map((column, i) => {
          const invsSelected =
            selectedInvoicesOption === 'UNPAID' ? invoices.unPaidInvoices : invoices.paidInvoices;
          return (
            <div
              key={column.id}
              style={{ flex: column.width / totalWidth }}
              className={twMerge('flex py-3 mx-1')}>
              {column.id === 'select' && (
                <AppCheckBox
                  isChecked={
                    filteredInvoices.length > 0 && selectedInvoices.length === invsSelected.length
                  }
                  onClick={(isChecked: boolean) => {
                    if (isChecked) {
                      setSelectedInvoices(filteredInvoices);
                    } else {
                      setSelectedInvoices([]);
                    }
                  }}
                />
              )}

              {column.id !== 'select' && column.id !== 'actions' ? (
                <div className="flex align-center">
                  <p className="text-xs TEXT_SECONDARY-CLR mr-2">{column.name}</p>
                </div>
              ) : (
                <p className="text-xs TEXT_SECONDARY-CLR">{column.name}</p>
              )}
            </div>
          );
        })}
      </div>
      {filteredInvoices.length === 0 && (
        <div className="w-full h-full flex items-center justify-center">
          <div className="flex flex-col items-center mb-2">
            <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
            <p>{`No ${selectedInvoicesOption === 'PAID' ? 'Paid' : 'Unpaid'} invoices`}</p>
          </div>
        </div>
      )}
      <div className="max-h-[500px] overflow-scroll">
        {filteredInvoices.length > 0 &&
          filteredInvoices.map((filteredInvoice, index) => {
            return (
              <div key={index + 'oo'} className="w-full flex items-center  px-4 justify-between">
                {columns.map((column, i) => {
                  return (
                    <div
                      key={column.id}
                      style={{ flex: column.width / totalWidth }}
                      className={`flex py-3 mx-1 ${
                        columns.length - 1 !== i ? 'overflow-hidden' : ''
                      }`}>
                      {column.id === 'select' && (
                        <AppCheckBox
                          isChecked={selectedInvoices.some((obj) => obj.id === filteredInvoice.id)}
                          onClick={(isChecked: boolean) => {
                            if (isChecked) {
                              const combInvoices = [...selectedInvoices];
                              combInvoices.push(filteredInvoice);
                              setSelectedInvoices(combInvoices);
                            } else {
                              const combInvoices = [...selectedInvoices].filter(
                                (inv) => inv.id !== filteredInvoice.id
                              );
                              setSelectedInvoices(combInvoices);
                            }
                          }}
                        />
                      )}
                      {column.id === 'invoiceDate' && (
                        <p className="text-sm capitalize w-full">
                          {filteredInvoice.createdAt
                            ? moment(filteredInvoice.createdAt).format('MM/DD/YYYY')
                            : ''}
                        </p>
                      )}
                      {column.id === 'invoiceNumber' && (
                        <p className="px-2 text-sm capitalize w-full">
                          {filteredInvoice.docNumber ? `${filteredInvoice.docNumber}` : ''}
                        </p>
                      )}
                      {column.id === 'amount' && (
                        <p className="text-sm w-full ">{`${
                          filteredInvoice.amount ? formatMoney(filteredInvoice.amount ?? '', 2) : ''
                        }`}</p>
                      )}
                    </div>
                  );
                })}
              </div>
            );
          })}
      </div>
      {filteredInvoices.length > 0 && (
        <div className="flex p-2 border-t flex-row-reverse">
          <div>{`Total:  ${formatMoney(totalAmount ?? '', 2)}`}</div>
        </div>
      )}
    </div>
  );
};
