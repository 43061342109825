import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import {
  pageNavigationPlugin,
  RenderCurrentPageLabelProps,
} from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import axios from 'axios';
import { PDFDocument, PDFName, PDFString, rgb, PDFRef } from 'pdf-lib';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Save, X } from 'react-feather';
import { twMerge } from 'tailwind-merge';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { AppInputField } from '../../../../general/AppInputField/AppInputField';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import {
  createLienTemplateByAdmin,
  getLienTemplatesByAdmin,
  updateLienTemplateByAdmin,
} from '../../../../../redux/services/leinTemplatesService';
import { AppDropDown } from '../../../../general/AppDropDown/AppDropDown';
import { toast } from 'react-toastify';
import { AppCheckBox } from '../../../../general/AppCheckBox/AppCheckBox';
import { LienTemplate, ReleaseTemplateField } from '@dill/dill-shared';
import AddEditFieldModal from './AddEditFieldModal';
import {
  ArrowCounterClockwise,
  Check,
  Database,
  FloppyDisk,
  CaretCircleLeft,
  CaretCircleRight,
} from 'phosphor-react';
import { removePDFFields } from '../../../../../utils/helpers';

interface Details {
  x: number;
  y: number;
  page: number;
  width: number;
  height: number;
}

interface DataItem {
  fieldId: string;
  fromFieldType: string;
  details: Details;
}

const AddTemplateFields = () => {
  const dispatch = useAppDispatch();
  const { lienTemplatesByAdmin } = useAppSelector((state) => state.lienTemplates);
  const canvasRef = useRef(null);
  const [currentPdfFieldCoordinates, setCurrentPdfFieldCoordinates] = useState<{
    x: number;
    y: number;
    page: number;
  } | null>(null);
  const [currentPdfFieldDimensions, setCurrentPdfFieldDimensions] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pdfUrlHistory, setPdfUrlHistory] = useState<string[]>([]);
  const [fieldWidth, setFieldWidth] = useState(200);
  const [fieldHeight, setFieldHeight] = useState(14);
  const [selectedTemplate, setSelectedTemplate] = useState<LienTemplate | null>(null);
  const [selectedPDFFieldPosition, setSelectedPDFFieldPosition] = useState<{
    parent: number;
    child: number;
  } | null>(null);
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageLabel, jumpToNextPage, jumpToPreviousPage } = pageNavigationPluginInstance;

  const [isUsePendingTemplates, setIsUsePendingTemplates] = useState(false);
  const [isAddEditFieldModalOpen, setIsAddEditFieldModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [fieldsList, setFieldsList] = useState<ReleaseTemplateField[]>([]);
  const [selectedFieldPosition, setSelectedFieldPosition] = useState<number | null>(null);
  const [currentClickedPosition, setCurrentClickedPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [errorMessage, setErrorMessage] = useState('');

  const templates: LienTemplate[] = useMemo(() => {
    const rr: LienTemplate[] = [
      {
        id: '1',
        originalPdf: {
          url: 'https://firebasestorage.googleapis.com/v0/b/dill-technologies-dev.appspot.com/o/testLienPdfs%2FUnconditionalWaiverAndReleaseOnFinalPayment.pdf?alt=media&token=5656340d-25b8-40d7-bea5-f1d34fadedde',
        },
        status: 'processed',
        formFields: [],
        name: 'Unconditional on Final - California',
        type: 'FINAL_UNCONDITIONAL',
      },
      {
        id: '2',
        originalPdf: {
          url: 'https://firebasestorage.googleapis.com/v0/b/dill-technologies-dev.appspot.com/o/testLienPdfs%2FConditionalWaiverAndReleaseOnProgressPayment.pdf?alt=media&token=3979e8a4-875c-49ba-8a67-aad4e52d3e05',
        },
        status: 'processed',
        formFields: [],
        name: 'Conditional on Progress - California',
        type: 'PROGRESS_CONDITIONAL',
      },

      {
        id: '3',
        originalPdf: {
          url: 'https://firebasestorage.googleapis.com/v0/b/dill-technologies-dev.appspot.com/o/testLienPdfs%2FConditionalWaiverAndReleaseOnFinalPayment.pdf?alt=media&token=769b3a0d-a638-429f-a087-e5628c8d4bcc',
        },
        status: 'processed',
        formFields: [],
        name: 'Conditional on Final - California',
        type: 'FINAL_CONDITIONAL',
      },
      {
        id: '4',
        originalPdf: {
          url: 'https://firebasestorage.googleapis.com/v0/b/dill-technologies-dev.appspot.com/o/testLienPdfs%2FUnconditionalWaiverAndReleaseOnProgressPayment.pdf?alt=media&token=4275dba4-76a2-4c7b-87da-3b2c25210b7b',
        },
        status: 'processed',
        formFields: [],
        name: 'Unconditional on Progress - California',
        type: 'PROGRESS_UNCONDITIONAL',
      },
    ];
    return rr;
  }, []);

  const [currentPdfUrl, setCurrentPdfUrl] = useState('');

  const resetForm = () => {
    setPdfUrlHistory([]);
    setFieldWidth(200);
    setFieldHeight(14);
    setSelectedTemplate(null);
    setSelectedPDFFieldPosition(null);
    setIsUsePendingTemplates(false);
    setIsAddEditFieldModalOpen(false);
    setSelectedFieldPosition(null);
    setErrorMessage('');
    setCurrentPdfUrl('');
  };

  const fetchEditedPdf = async (isOrginalPdf: boolean) => {
    if (isOrginalPdf) {
      if (pdfUrlHistory.length > 0) {
        //  const res = await axios.get(pdfUrlHistory[pdfUrlHistory.length - 1], {
        const res = await axios.get(pdfUrlHistory[0], {
          responseType: 'arraybuffer',
        });
        if (res.data) {
          return res.data;
        }
      }
    } else {
      if (pdfUrlHistory.length > 1) {
        const res = await axios.get(pdfUrlHistory[1], {
          responseType: 'arraybuffer',
        });
        if (res.data) {
          return res.data;
        }
      } else if (pdfUrlHistory.length > 0) {
        const res = await axios.get(pdfUrlHistory[0], {
          responseType: 'arraybuffer',
        });
        if (res.data) {
          return res.data;
        }
      }
    }

    return null;
  };

  const addFieldToPdf = async (
    fieldDetails: {
      fieldId: string;
      fromFieldType: string;
      details: {
        x: number;
        y: number;
        page: number;
        width: number;
        height: number;
      };
    }[],
    refresh: boolean
  ) => {
    const existingPdfBytes = await fetchEditedPdf(refresh);
    if (existingPdfBytes) {
      const groupedData: DataItem[][] = [];

      fieldDetails.forEach((item) => {
        const page = item.details.page;
        if (!groupedData[page]) {
          groupedData[page] = [];
        }
        groupedData[page].push(item);
      });
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      for (let index = 0; index < groupedData.length; index++) {
        const pageFields = groupedData[index];
        const pageNumber = pageFields?.[0]?.details?.page ?? 0;
        const page = pdfDoc.getPages()[pageNumber];
        const { width: horizontalLength, height: verticalLength } = page.getSize();
        const form = pdfDoc.getForm();
        if (existingPdfBytes) {
          const formFields = form.getFields();
          formFields.forEach((formField) => {
            const widget0 = formField.acroField.getWidgets()[0];
            const AP = widget0.ensureAP();
            AP.set(PDFName.of('N'), PDFRef.of(0, 0));
            form.removeField(formField);
          });
        }
        if (pageFields && pageFields.length > 0) {
          pageFields.forEach(({ details, fieldId, fromFieldType }) => {
            if (fromFieldType === 'SIGNATURE_FIELD' && !refresh) {
              page.drawRectangle({
                x: details.x,
                y: verticalLength - details.y,
                width: details.width,
                height: details.height,
                borderWidth: 0.00000004,
                color: rgb(1, 1, 1),
                opacity: 1,
                borderOpacity: 0.75,
              });
            }
            if (fromFieldType !== 'SIGNATURE_FIELD') {
              const field = form.createTextField(`dillField_${fieldId}`);
              field.enableMultiline();
              field.disableScrolling();
              field.addToPage(page, {
                x: details.x,
                y: verticalLength - details.y,
                width: details.width,
                height: details.height,
                borderWidth: 0.00000004,
              });
            }
          });
        }
      }

      const modifiedPdfBytes = await pdfDoc.save();

      // Download the modified PDF
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      setCurrentPdfUrl(url);
      if (refresh && pdfUrlHistory) {
        setPdfUrlHistory([pdfUrlHistory[0], url]);
      }
    }
  };

  useEffect(() => {
    if (
      currentPdfFieldCoordinates &&
      selectedPDFFieldPosition &&
      selectedPDFFieldPosition !== undefined &&
      fieldsList[selectedPDFFieldPosition.parent] &&
      fieldsList[selectedPDFFieldPosition.parent].pdfFields[selectedPDFFieldPosition.child].id &&
      !fieldsList[selectedPDFFieldPosition.parent].pdfFields[selectedPDFFieldPosition.child]
        .isMarkedInPdf
    ) {
      addFieldToPdf(
        [
          {
            fieldId:
              fieldsList[selectedPDFFieldPosition.parent].pdfFields[selectedPDFFieldPosition.child]
                .id,
            fromFieldType: String(fieldsList[selectedPDFFieldPosition.parent].fromFieldType),
            details: {
              x: currentPdfFieldCoordinates.x,
              y: currentPdfFieldCoordinates.y,
              page: currentPage,
              width: fieldWidth,
              height: fieldHeight,
            },
          },
        ],
        false
      );
    }

    return () => {};
  }, [currentPdfFieldCoordinates, currentPdfFieldDimensions]);

  useEffect(() => {
    if (currentPdfFieldCoordinates && fieldWidth && fieldHeight) {
      setCurrentPdfFieldDimensions({
        width: fieldWidth || 0,
        height: fieldHeight || 0,
      });
    }

    return () => {};
  }, [fieldWidth, fieldHeight, currentPdfFieldCoordinates]);

  useEffect(() => {
    if (fieldsList.length > 0) {
      const fieldsToPopulate: {
        fieldId: string;
        fromFieldType: string;
        details: {
          x: number;
          y: number;
          page: number;
          width: number;
          height: number;
        };
      }[] = [];
      fieldsList.forEach((field) => {
        field.pdfFields.forEach((pdfField) => {
          if (pdfField.isMarkedInPdf && pdfField.x && pdfField.y) {
            fieldsToPopulate.push({
              fieldId: pdfField.id,
              fromFieldType: field.fromFieldType,
              details: {
                x: pdfField.x,
                y: pdfField.y,
                page: pdfField?.page || 0,
                width: pdfField.width || 0,
                height: pdfField.height || 0,
              },
            });
          }
        });
      });
      if (fieldsToPopulate.length > 0) {
        addFieldToPdf(fieldsToPopulate, true);
      }
    }

    return () => {};
  }, [fieldsList]);

  useEffect(() => {
    dispatch(getLienTemplatesByAdmin());
    setSelectedTemplate(templates[0]);
    const canvas: any = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, 612, 792);
      context.globalAlpha = 0.2;

      // Draw initial content on the canvas
      context.fillStyle = 'white';
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Event listener for click
      const handleClick = (event: any) => {
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        setCurrentPdfFieldCoordinates({ x, y, page: currentPage });
      };

      canvas.addEventListener('click', handleClick);
      return () => {
        if (canvas) {
          canvas.removeEventListener('click', handleClick);
        }
      };
    }

    // Clean up the event listener
    return () => {};
  }, []);
  const handleFlattenPdf = async (url: string) => {
    const flattened = await removePDFFields(url);
    if (flattened) {
      setPdfUrlHistory([flattened]);
      setCurrentPdfUrl(flattened);
    }
  };
  useEffect(() => {
    if (selectedTemplate) {
      if (selectedTemplate?.originalPdf?.url) {
        handleFlattenPdf(selectedTemplate?.originalPdf?.url);
      }

      setFieldsList([
        {
          id: 'signature',
          canPopulatePDF: true,
          fieldName: 'signature',
          fromFieldType: 'SIGNATURE_FIELD',
          isFieldRequired: true,
          fieldAdjustmentForm: 'ONLY_PDF_FIELDS',
          whoPopulatesField: 'SUB_SUPPLIER',
          fieldType: 'SIGNATURE',
          position: 99,
          pdfFields: [{ id: 'signature1', isMarkedInPdf: false }],
        },
        {
          id: 'signedDate',
          canPopulatePDF: true,
          fieldName: 'Signed Date',
          fromFieldType: 'NEW_FIELD',
          fieldType: 'DATE',
          isFieldRequired: true,
          fieldAdjustmentForm: 'ONLY_PDF_FIELDS',
          whoPopulatesField: 'SUB_SUPPLIER',
          dateSplitFormats: ['MM/DD/YYYY'],
          position: 100,
          pdfFields: [{ id: 'signedDate1Format1', isMarkedInPdf: false }],
        },
      ]);
      // setSelectedFieldPosition(0);
      const canvas: any = canvasRef.current;
      if (canvas) {
        const context = canvas.getContext('2d');
        context.clearRect(0, 0, 612, 792);
        context.globalAlpha = 0.2;

        // Draw initial content on the canvas
        context.fillStyle = 'white';
        context.fillRect(0, 0, canvas.width, canvas.height);
      }
    } else {
      setCurrentPdfUrl('');
      setFieldsList([
        ...fieldsList.map((field, i) => {
          return { ...field, isDone: false };
        }),
      ]);
    }

    // Clean up the event listener
    return () => {};
  }, [selectedTemplate]);

  const handleSubmitLienTemplate = async () => {
    setLoading(true);
    if (selectedTemplate) {
      if (isUsePendingTemplates) {
        const results = await dispatch(
          updateLienTemplateByAdmin({
            templateId: selectedTemplate.id,
            modifiedPdfUrl: currentPdfUrl,
            formFields: [...fieldsList],
          })
        );
        dispatch(getLienTemplatesByAdmin());
        if (results.type === 'lienTemplates/updateLienTemplateByAdmin/fulfilled') {
          setSelectedTemplate(null);
          toast.success('Template submitted', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            style: {
              backgroundColor: '#ECFDF3',
              color: '#054F31',
            },
          });
        }
      } else {
        const results = await dispatch(
          createLienTemplateByAdmin({
            type: selectedTemplate.type,
            originalPdfUrl: selectedTemplate?.originalPdf?.url || '',
            name: selectedTemplate.name,
            modifiedPdfUrl: currentPdfUrl,
            formFields: [...fieldsList],
          })
        );
        dispatch(getLienTemplatesByAdmin());
        if (results.type === 'lienTemplates/createLienTemplateByAdmin/fulfilled') {
          setSelectedTemplate(null);
          resetForm();
          toast.success('Template submitted', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
            style: {
              backgroundColor: '#ECFDF3',
              color: '#054F31',
            },
          });
        }
      }
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-row h-full py-4">
      <AddEditFieldModal
        fieldsList={fieldsList}
        selectedFieldPosition={selectedFieldPosition}
        open={isAddEditFieldModalOpen}
        handleClose={() => {
          setIsAddEditFieldModalOpen(false);
          setSelectedFieldPosition(null);
        }}
        onAddField={(item) => {
          setFieldsList([...fieldsList, item]);
          setIsAddEditFieldModalOpen(false);
          setSelectedFieldPosition(null);
        }}
        onEditField={(item, position) => {
          setFieldsList([
            ...fieldsList.map((fieldItem, i) => {
              if (position === i) {
                return item;
              }
              return fieldItem;
            }),
          ]);
          setIsAddEditFieldModalOpen(false);
          setSelectedFieldPosition(null);
        }}
      />
      <div className={twMerge('flex flex-col w-1/3 mx-4 h-full ')}>
        <div className="h-5/6 relative  overflow-y-scroll flex flex-col items-center pb-3 pt-0">
          <div className={twMerge('flex flex-col  bg-white px-4 pb-3 mb-3   w-full')}>
            <div className="flex flex-col w-full sticky top-0 bg-white z-10 mb-3 pt-2">
              {/* isUsePendingTemplates */}
              <div className="flex items-center my-2">
                <AppCheckBox
                  isChecked={isUsePendingTemplates}
                  checkSize={17}
                  className="h-5 w-5 p-0.5"
                  onClick={() => {
                    setSelectedTemplate(null);
                    setIsUsePendingTemplates(!isUsePendingTemplates);
                  }}
                />
                <p className="text-xs self-start PRIMARY_500-CRL ml-2">
                  Select from pending templates
                </p>
              </div>
              {!isUsePendingTemplates && (
                <div className="flex flex-col ">
                  <p className="text-xs self-start PRIMARY_500-CRL">Select Template</p>
                  <AppDropDown
                    value={selectedTemplate?.id || ''}
                    items={[
                      ...templates.map((template) => ({
                        label: template.name,
                        value: template.id,
                      })),
                    ]}
                    onSelectChange={(item) => {
                      if (item?.value) {
                        const template = templates.find(
                          (templateDetails) => templateDetails.id === item?.value
                        );
                        if (template) {
                          setSelectedTemplate(template);
                        }
                      }
                    }}
                  />
                </div>
              )}
              {isUsePendingTemplates && (
                <div key={'pp'} className="flex flex-col ">
                  <p className="text-xs self-start PRIMARY_500-CRL">Select Template</p>
                  <AppDropDown
                    value={selectedTemplate?.id || ''}
                    items={[
                      ...lienTemplatesByAdmin.map((template) => ({
                        label: template.name,
                        value: template.id,
                      })),
                    ]}
                    onSelectChange={(item) => {
                      if (item?.value) {
                        const template = lienTemplatesByAdmin.find(
                          (templateDetails) => templateDetails.id === item?.value
                        );
                        if (template) {
                          setSelectedTemplate(template);
                        }
                      }
                    }}
                  />
                </div>
              )}
              <div className="flex justify-between mt-1">
                <p className="text-lg font-bold">Fields</p>
                <div className="">
                  <AppButton
                    text="Add New Field"
                    buttonStyles={{}}
                    onClick={() => {
                      setSelectedFieldPosition(null);
                      setIsAddEditFieldModalOpen(true);
                    }}
                  />
                </div>
              </div>
            </div>

            {fieldsList
              .sort((A, B) => {
                if (
                  (A.position === null || A.position === undefined) &&
                  B.position !== null &&
                  B.position !== undefined
                ) {
                  return 1;
                }
                if (
                  (B.position === null || B.position === undefined) &&
                  A.position !== null &&
                  A.position !== undefined
                ) {
                  return -1;
                }
                if (
                  A.position !== null &&
                  A.position !== undefined &&
                  B.position !== null &&
                  B.position !== undefined &&
                  A.position > B?.position
                ) {
                  return 1;
                }
                return -1;
              })
              .map((field, i) => {
                return (
                  <div
                    key={i + 'ww'}
                    className={twMerge(
                      'my-1 rounded-lg px-2 border  py-2 relative',
                      field.fieldAdjustmentForm === 'EDITABLE' ||
                        field.fieldAdjustmentForm === 'ONLY_PDF_FIELDS'
                        ? 'cursor-pointer'
                        : ''
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (
                        field.fieldAdjustmentForm === 'EDITABLE' ||
                        field.fieldAdjustmentForm === 'ONLY_PDF_FIELDS'
                      ) {
                        setIsAddEditFieldModalOpen(true);
                        setSelectedFieldPosition(i);
                      }
                    }}>
                    {field.fieldAdjustmentForm === 'EDITABLE' && (
                      <X
                        size={15}
                        className="absolute top-1 right-1 cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();

                          setFieldsList([...fieldsList.filter((fieldItem, index) => index !== i)]);
                        }}
                      />
                    )}
                    {field.fromFieldType === 'NEW_FIELD' && field.id !== 'signedDate' && (
                      <p className="text-2xs text-center">Order Position: {field.position}</p>
                    )}
                    <p className="text-sm font-bold">{field.fieldName}</p>
                    {field?.pdfFields.length > 0 && (
                      <>
                        <p className="text-xs mt-3">Ids for field in Pdf</p>
                        <div className="flex flex-wrap">
                          {field?.pdfFields &&
                            field?.pdfFields.map((pdfField, j) => {
                              return (
                                <div
                                  key={j + 'pp'}
                                  className={twMerge(
                                    'my-1 mx-1 py-1 px-2 cursor-pointer  border rounded-lg border-blue-500 bg-white flex',
                                    selectedPDFFieldPosition &&
                                      i === selectedPDFFieldPosition.parent &&
                                      j === selectedPDFFieldPosition.child
                                      ? ' bg-green-50 '
                                      : '',
                                    pdfField.isMarkedInPdf ? 'border-blue-500' : 'border-red-500'
                                  )}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (selectedPDFFieldPosition !== null) {
                                      setErrorMessage('First save the current pdf field');
                                      return;
                                    }
                                    setErrorMessage('');
                                    setSelectedPDFFieldPosition({ parent: i, child: j });
                                    setCurrentPdfFieldCoordinates(null);
                                  }}>
                                  <p className="text-xs">{pdfField.id}</p>
                                  {selectedPDFFieldPosition &&
                                    i === selectedPDFFieldPosition.parent &&
                                    j === selectedPDFFieldPosition.child && (
                                      <div className="flex ml-4 ">
                                        {fieldsList[selectedPDFFieldPosition.parent] &&
                                          fieldsList[selectedPDFFieldPosition.parent].pdfFields[
                                            selectedPDFFieldPosition.child
                                          ] &&
                                          fieldsList[selectedPDFFieldPosition.parent].pdfFields[
                                            selectedPDFFieldPosition.child
                                          ].isMarkedInPdf && (
                                            <ArrowCounterClockwise
                                              className="hover:bg-blue-800 bg-blue-500 rounded-full p-0.5 text-white"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setCurrentPdfFieldCoordinates(null);
                                                setFieldsList([
                                                  ...fieldsList.map((field2, i2) => {
                                                    return {
                                                      ...field2,
                                                      pdfFields: field2.pdfFields.map(
                                                        (pdfField2, j2) => {
                                                          if (
                                                            selectedPDFFieldPosition.parent ===
                                                              i2 &&
                                                            selectedPDFFieldPosition.child === j2
                                                          ) {
                                                            delete pdfField2.x;
                                                            delete pdfField2.y;
                                                            delete pdfField2.width;
                                                            delete pdfField2.height;
                                                            delete pdfField2.page;
                                                            return {
                                                              ...pdfField2,
                                                              isMarkedInPdf: false,
                                                            };
                                                          }
                                                          return { ...pdfField2 };
                                                        }
                                                      ),
                                                    };
                                                  }),
                                                ]);
                                              }}
                                            />
                                          )}
                                        {fieldsList[selectedPDFFieldPosition.parent] &&
                                          fieldsList[selectedPDFFieldPosition.parent].pdfFields[
                                            selectedPDFFieldPosition.child
                                          ] &&
                                          !fieldsList[selectedPDFFieldPosition.parent].pdfFields[
                                            selectedPDFFieldPosition.child
                                          ].isMarkedInPdf && (
                                            <Database
                                              className="ml-1 hover:bg-blue-800 bg-blue-500 rounded-full p-0.5 text-white animate-bounce"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                  currentPdfFieldCoordinates === null ||
                                                  currentPdfFieldDimensions === null
                                                ) {
                                                  setErrorMessage(
                                                    'Field has not been positioned in pdf'
                                                  );
                                                  return;
                                                }
                                                setSelectedPDFFieldPosition(null);
                                                setFieldsList([
                                                  ...fieldsList.map((field2, i2) => {
                                                    return {
                                                      ...field2,
                                                      pdfFields: field2.pdfFields.map(
                                                        (pdfField2, j2) => {
                                                          if (
                                                            selectedPDFFieldPosition.parent ===
                                                              i2 &&
                                                            selectedPDFFieldPosition.child === j2
                                                          ) {
                                                            return {
                                                              ...pdfField2,
                                                              isMarkedInPdf: true,
                                                              x: currentPdfFieldCoordinates.x,
                                                              y: currentPdfFieldCoordinates.y,
                                                              width:
                                                                currentPdfFieldDimensions.width,
                                                              height:
                                                                currentPdfFieldDimensions.height,
                                                              page: currentPage,
                                                            };
                                                          }
                                                          return { ...pdfField2 };
                                                        }
                                                      ),
                                                    };
                                                  }),
                                                ]);
                                              }}
                                            />
                                          )}
                                      </div>
                                    )}
                                </div>
                              );
                            })}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
          </div>
          <div className="flex flex-row">
            <AppButton
              buttonStyles={{ marginLeft: '5px', height: '40px' }}
              text="Submit"
              isDisabled={
                loading ||
                fieldsList.length === 0 ||
                (fieldsList.length > 0 &&
                  fieldsList.some((item) => item.pdfFields.some((field) => !field.isMarkedInPdf)))
              }
              isLoading={loading}
              onClick={handleSubmitLienTemplate}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-2/3 h-full">
        <div className="h-5/6 relative  overflow-y-scroll flex flex-col items-center pb-3">
          <div
            style={{
              width: '612px',
              background: '#F4F7FA',
            }}
            className="sticky top-0  z-10 flex justify-between  mb-0.5">
            {selectedPDFFieldPosition !== null && (
              <div className="flex flex-col">
                {fieldsList[selectedPDFFieldPosition.parent] &&
                  fieldsList[selectedPDFFieldPosition.parent]?.id === 'signature' && (
                    <p className="text-xs text-red-400">
                      Signature may not show but position is tracked
                    </p>
                  )}
                {fieldsList[selectedPDFFieldPosition.parent] &&
                  fieldsList[selectedPDFFieldPosition.parent]?.id !== 'signature' && (
                    <p className="text-xs PRIMARY_500-CLR">Click in pdf to position fields</p>
                  )}
                {errorMessage && <p className="text-xs ERROR_500-CLR">{errorMessage}</p>}
                <div className="flex">
                  <AppInputField
                    value={fieldWidth}
                    placeholder="Field Width"
                    inputType="number"
                    onTextChange={(text) => {
                      setFieldWidth(parseInt(text));
                    }}
                  />
                  <AppInputField
                    value={fieldHeight}
                    placeholder="Field Height"
                    inputType="number"
                    onTextChange={(text) => {
                      setFieldHeight(parseInt(text));
                    }}
                  />
                  {fieldsList.length > 0 &&
                    fieldsList.every((item) =>
                      item.pdfFields.every((field) => field.isMarkedInPdf)
                    ) && <AppButton text="Submit" onClick={handleSubmitLienTemplate} />}
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              width: '612px',
              height: '792px',
            }}
            className="relative ">
            {currentPdfUrl && (
              <Viewer
                defaultScale={1}
                fileUrl={currentPdfUrl}
                plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
                initialPage={currentPage}
              />
            )}
            {/* 792px */}
            <canvas
              ref={canvasRef}
              style={{
                width: '612px',
                height: '792px',
                left: '50%',
                transform: 'translateX( -50%)',
              }}
              className={twMerge('h-full w-full absolute top-0 left-0 opacity-100')}
            />
          </div>
        </div>

        <div className="flex flex-row justify-center">
          <div className="mx-4 flex items-center justify-center">
            <CurrentPageLabel>
              {(props: RenderCurrentPageLabelProps) => {
                return (
                  <div className="mx-4 flex items-center justify-center">
                    {props.numberOfPages > 1 && (
                      <button
                        className={`mr-2 flex items-center justify-center py-[2px] px-2 ${
                          props.numberOfPages > props.currentPage + 1
                            ? 'GREY_500-CLR cursor-default'
                            : 'hover:bg-blue-100  hover:text-[#3762FB]'
                        }`}
                        onClick={() => {
                          setCurrentPage(props.currentPage - 1);
                          jumpToPreviousPage();
                        }}>
                        <CaretCircleLeft className="mr-1" />
                        Previous Page
                      </button>
                    )}
                    <span>{`${props.numberOfPages > 1 ? '' : 'page'} ${props.currentPage + 1} / ${
                      props.numberOfPages
                    }`}</span>
                    {props.numberOfPages > 1 && (
                      <button
                        className={`ml-2 flex items-center justify-center py-[2px] px-2 ${
                          props.numberOfPages - 1 < props.currentPage + 1
                            ? 'GREY_500-CLR cursor-default'
                            : 'hover:bg-blue-100 hover:text-[#3762FB]'
                        }`}
                        onClick={() => {
                          jumpToNextPage();
                          setCurrentPage(props.currentPage + 1);
                        }}>
                        Next Page
                        <CaretCircleRight className="ml-1" />
                      </button>
                    )}
                  </div>
                );
              }}
            </CurrentPageLabel>
          </div>

          <ZoomOutButton />
          <ZoomPopover />
          <ZoomInButton />
        </div>
      </div>
    </div>
  );
};

export default AddTemplateFields;
