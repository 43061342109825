export const COLORS = {
  // primary
  PRIMARY_50: '#F9FAFF',
  PRIMARY_100: '#E8EDFF',
  PRIMARY_200: '#CDD9FF',
  PRIMARY_300: '#96B0FF',
  PRIMARY_400: '#5982FF',
  PRIMARY_500: '#3762FB',
  PRIMARY_600: '#3158E2',
  PRIMARY_700: '#2847B7',
  PRIMARY_800: '#2440A5',
  PRIMARY_900: '#1D3485',
  // secondary
  SECONDARY_50: '#F6F7FC',
  SECONDARY_100: '#EFF2FA',
  SECONDARY_200: '#A8ADD3',
  SECONDARY_300: '#7177AE',
  SECONDARY_400: '#454B89',
  SECONDARY_500: '#303778',
  SECONDARY_600: '#1D2675',
  SECONDARY_700: '#081371',
  SECONDARY_800: '#000954',
  SECONDARY_900: '#000534',
  // error
  ERROR_100: '#FEF0EF',
  ERROR_200: '#FEE4E2',
  ERROR_300: '#FDA29B',
  ERROR_400: '#F97066',
  ERROR_500: '#F04438',
  ERROR_600: '#D92D20',
  ERROR_700: '#B42318',
  ERROR_800: '#912018',
  ERROR_900: '#7A271A',
  // warning
  WARNING_100: '#FFFAEB',
  WARNING_200: '#FEF0C7',
  WARNING_300: '#FEDF89',
  WARNING_400: '#FEC84B',
  WARNING_500: '#FDB022',
  WARNING_600: '#F79009',
  WARNING_700: '#DC6803',
  WARNING_800: '#DC6803',
  WARNING_900: '#93370D',
  // success
  SUCCESS_100: '#ECFDF3',
  SUCCESS_200: '#D1FADF',
  SUCCESS_300: '#A6F4C5',
  SUCCESS_400: '#6CE9A6',
  SUCCESS_500: '#32D583',
  SUCCESS_600: '#12B76A',
  SUCCESS_700: '#027A48',
  SUCCESS_800: '#05603A',
  SUCCESS_900: '#054F31',
  // info
  INFO_100: '#EDF7FE',
  INFO_200: '#E0F2FE',
  INFO_300: '#B9E6FE',
  INFO_400: '#7CD4FD',
  INFO_500: '#36BFFA',
  INFO_600: '#0BA5EC',
  INFO_700: '#0086C9',
  INFO_800: '#1769AA',
  INFO_900: '#065986',
  // grey
  WHITE: '#FFFFFF',
  GREY_50: '#FFFFFF',
  GREY_100: '#F1F5F9',
  GREY_200: '#E4EAF1',
  GREY_300: '#CBD5E1',
  GREY_400: '#94A3B8',
  GREY_500: '#64748B',
  GREY_600: '#475569',
  GREY_700: '#334155',
  GREY_800: '#1E293B',
  GREY_900: '#101218',
  // grey
  TEXT_PRIMARY: '#101218',
  TEXT_SECONDARY: '#64748B',
  TEXT_TERTIARY: '#7A7A7A',
  TEXT_ACCENT: '#AAAAAA',
};

export type ColorType =
  | 'PRIMARY_50'
  | 'PRIMARY_100'
  | 'PRIMARY_200'
  | 'PRIMARY_300'
  | 'PRIMARY_400'
  | 'PRIMARY_500'
  | 'PRIMARY_600'
  | 'PRIMARY_700'
  | 'PRIMARY_800'
  | 'PRIMARY_900'
  | 'SECONDARY_50'
  | 'SECONDARY_100'
  | 'SECONDARY_200'
  | 'SECONDARY_300'
  | 'SECONDARY_400'
  | 'SECONDARY_500'
  | 'SECONDARY_600'
  | 'SECONDARY_700'
  | 'SECONDARY_800'
  | 'SECONDARY_900'
  | 'ERROR_100'
  | 'ERROR_200'
  | 'ERROR_300'
  | 'ERROR_400'
  | 'ERROR_500'
  | 'ERROR_600'
  | 'ERROR_700'
  | 'ERROR_800'
  | 'ERROR_900'
  | 'WARNING_100'
  | 'WARNING_200'
  | 'WARNING_300'
  | 'WARNING_400'
  | 'WARNING_500'
  | 'WARNING_600'
  | 'WARNING_700'
  | 'WARNING_800'
  | 'WARNING_900'
  | 'SUCCESS_100'
  | 'SUCCESS_200'
  | 'SUCCESS_300'
  | 'SUCCESS_400'
  | 'SUCCESS_500'
  | 'SUCCESS_600'
  | 'SUCCESS_700'
  | 'SUCCESS_800'
  | 'SUCCESS_900'
  | 'INFO_100'
  | 'INFO_200'
  | 'INFO_300'
  | 'INFO_400'
  | 'INFO_500'
  | 'INFO_600'
  | 'INFO_700'
  | 'INFO_800'
  | 'INFO_900'
  | 'WHITE'
  | 'GREY_50'
  | 'GREY_100'
  | 'GREY_200'
  | 'GREY_300'
  | 'GREY_400'
  | 'GREY_500'
  | 'GREY_600'
  | 'GREY_700'
  | 'GREY_800'
  | 'GREY_900'
  | 'TEXT_PRIMARY'
  | 'TEXT_SECONDARY'
  | 'TEXT_TERTIARY'
  | 'TEXT_ACCENT';
