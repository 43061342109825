import React, { useMemo, useEffect, useState } from 'react';
import { downloadFile, disablePDFFields } from '../../../../../utils/helpers';
import emptyListImg from '../../../../../assets/images/emptyList.png';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { COLORS } from '../../../../../utils/colors';
import { Eye } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import {
  getSupplierLienReleases,
  getSupplierReleaseHistory,
} from '../../../../../redux/services/liensService';
import { AppCheckBox } from '../../../../general/AppCheckBox/AppCheckBox';
import { AppPdfPreviewModal } from '../../../../general/AppPdfPreviewModal/AppPdfPreviewModal';
import moment from 'moment';
import {
  selectSupplierLiens,
  unSelectSupplierLiens,
  selectLiensRelease,
} from '../../../../../redux/globalSlices/liensSlice';
import {
  DillLinkedTransaction,
  DillTransaction,
  LienApiResponse,
  formatMoney,
} from '@dill/dill-shared';
import {
  DownloadSimple,
  CaretLeft,
  CaretRight,
  ArrowsDownUp,
  SortDescending,
  SortAscending,
} from 'phosphor-react';
import { AppErrorPage } from '../../../../general/AppErrorPage/AppErrorPage';
import ReactPaginate from 'react-paginate';
import AppReleaseStatusPill from '../../../../general/AppReleaseStatusPill/AppReleaseStatusPill';
import { AppReleaseHistoryModal } from '../../../../general/AppReleaseHistoryModal/AppReleaseHistoryModal';
import { InvoiceReference } from '../../../../../constants/invoice';

const SupplierLienWaiversTable = ({ releases }: { releases: LienApiResponse[] }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showPreviewPdf, setShowPreviewPdf] = useState(false);
  const [previewURL, setPreviewURL] = useState('');
  const [lienType, setLienType] = useState('');
  const [selectedLienID, setSelectedLienID] = useState('');
  const [selectedRelease, setSelectedRelease] = useState<LienApiResponse | null>(null);
  const [sort, setSort] = useState('');
  const [sorted, setSorted] = useState(false);
  const [sortedRealeases, setSortedReleases] = useState<LienApiResponse[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [openShowHistory, setOpenShowHistory] = useState(false);
  const { selectedSupplierLiens, supplierLienReleases, loadingErrorList, selectedReleaseHistory } =
    useAppSelector((state) => state.liens);
  const { user } = useAppSelector((state) => state.auth);
  // Pagination
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 20; // Number of items per page
  const pageCount = Math.ceil(releases.length / itemsPerPage);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = sortedRealeases.slice(itemOffset, endOffset);

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % releases.length;
    setItemOffset(newOffset);
  };

  const cummulativeOffset = useMemo(() => {
    let result = 0;
    const currentTotalItems = endOffset / itemsPerPage;
    if (pageCount === currentTotalItems) {
      result = releases.length;
    } else if (releases.length === 0) {
      result = 0;
    } else {
      const itemsTotal = currentTotalItems * itemsPerPage;
      result = itemsTotal;
    }
    return result;
  }, [endOffset, releases]);
  const error = useMemo(() => {
    if (loadingErrorList.length > 0) {
      const errorMessage: any = loadingErrorList.filter((obj) =>
        obj.hasOwnProperty('getSupplierLienReleases')
      );
      if (errorMessage.length > 0) {
        return errorMessage[0]?.getSupplierLienReleases;
      }
    }
    return null;
  }, [loadingErrorList]);
  const [releasePreviewDetails, setReleasePreviewDetails] = useState(
    {} as {
      recipient: string;
      type: string;
      paymentAmount: number | null;
      throughDate: string | Date;
      job: string;
      status:
        | 'Declined'
        | 'Rejected'
        | 'Requested'
        | 'Signed'
        | 'Scheduled'
        | 'Pending'
        | 'Sent to GC';
      declineReason?: string | null;
      releaseAttachments?: { name: string; url: string }[] | null;
      notes?: string | null;
      dueDate: string | Date;
      signedTime?: Date | null;
      signedBy?: string | null;
      transactions?: DillTransaction[] | DillLinkedTransaction[] | null;
      sentToGCTime?: Date | null;
      sentToGCEmails?: string[] | null;
      invoiceList?: InvoiceReference[] | null;
    }
  );

  useEffect(() => {
    setSortedReleases(releases);
    setItemOffset(0);
    setCurrentPage(1);
  }, [releases]);

  useEffect(() => {
    if (user && user.roles.includes('SUB_SUPPLIER')) {
      dispatch(getSupplierLienReleases());
    }

    return () => {};
  }, [user]);
  const columns = useMemo(() => {
    return [
      { id: 'select', name: '', width: 50, cellType: 'SELECT' },
      { id: 'sender', name: 'Sender', width: 130, cellType: 'VIEW_TEXT' },
      { id: 'requestDate', name: 'Request Date', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'throughDate', name: 'Through Date', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'dueDate', name: 'Due Date', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'type', name: 'Type', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'amount', name: 'Amount', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'job', name: 'Job', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'status', name: 'Status', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'actions', name: '', width: 200, cellType: 'VIEW_TEXT' },
    ];
  }, []);
  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );
  const handlePreviewPdf = async (release: LienApiResponse) => {
    const throughdate = release?.fieldsData?.find((elem) => elem.id === 'throughDate');
    const pAmount = release?.fieldsData?.find((elem) => elem.id === 'paymentAmount');
    setPreviewURL(
      release?.signedPdf?.url ? release?.signedPdf?.url : release.previewPdf?.url ?? ''
    );
    setLienType(release?.type ?? '');
    setSelectedLienID(release.id);
    setReleasePreviewDetails({
      recipient: `${release.sender?.subUser?.firstName ?? release.sender?.user?.firstName ?? ''} ${
        release.sender?.subUser?.lastName ?? release.sender?.user?.lastName ?? ''
      }`,
      type: release?.type?.toLowerCase() ?? '',
      paymentAmount: pAmount ? Number(pAmount.value) : null,
      throughDate: throughdate && throughdate.value ? new Date(throughdate.value) : '',
      job: release.job?.name ?? '',
      status: release.status,
      declineReason: release?.declineReason,
      releaseAttachments: release?.releaseAttachments,
      notes: release?.notes,
      dueDate: release?.dueDate ?? '',
      signedTime: release.signedTime,
      signedBy:
        release.signedBy?.subUser?.firstName || release.signedBy?.user?.firstName
          ? `${release.signedBy?.subUser?.firstName ?? release.signedBy?.user?.firstName ?? ''} ${
              release.signedBy?.subUser?.lastName ?? release.signedBy?.user?.lastName ?? ''
            }`
          : null,
      transactions: release?.transactions ?? null,
      sentToGCTime: release?.sentToGCTime ?? null,
      sentToGCEmails: release?.sentToGCEmails ?? null,
      invoiceList: release?.invoiceList,
    });
    setShowPreviewPdf(true);
  };
  const handleDownloadSelectedPdf = async (release: LienApiResponse) => {
    if (release) {
      let flattenedPDF;
      const createdAtTimeStamp = release?.createdAt
        ? new Date(release.createdAt).getTime()
        : new Date().getTime();
      if (release?.status === 'Signed' || release?.status === 'Sent to GC') {
        flattenedPDF = release?.signedPdf?.url;
      } else {
        flattenedPDF = await disablePDFFields(release?.previewPdf?.url ?? '');
      }
      downloadFile(
        flattenedPDF ?? '',
        `${release.job?.name ?? ''}-${release.buyer?.name ?? ''}-${createdAtTimeStamp}.pdf`
      );
    }
  };
  useEffect(() => {
    if (location.pathname.startsWith('/lienReleases')) {
      const params = new URLSearchParams(location.search);
      const releaseId = params.get('releaseId');
      if (releaseId) {
        const lienRelease = releases.find((rel) => rel.id === releaseId);
        if (lienRelease) {
          setSelectedRelease(lienRelease);
          handlePreviewPdf(lienRelease);
        }
      }
    }
  }, [location.pathname, releases]);
  const sortByProperty = (
    sortType: 'asc' | 'desc',
    sortKey: string,
    getter: (item: any) => any,
    compare?: (a: any, b: any) => number
  ) => {
    const filteredReleases = releases.filter((release) => {
      const value = getter(release);
      return value !== undefined && value !== null;
    });

    const sortedReleasesCopy = [...filteredReleases];
    sortedReleasesCopy.sort((a, b) => {
      const valueA = getter(a);
      const valueB = getter(b);

      if (compare) {
        return compare(valueA, valueB) * (sortType === 'asc' ? 1 : -1);
      } else {
        const stringA = String(valueA).toUpperCase();
        const stringB = String(valueB).toUpperCase();

        if (stringA < stringB) {
          return sortType === 'asc' ? -1 : 1;
        } else if (stringA > stringB) {
          return sortType === 'asc' ? 1 : -1;
        }

        return 0;
      }
    });

    const undefinedOrNullReleases = releases.filter((release) => {
      const value = getter(release);
      return value === undefined || value === null;
    });

    const sortedReleases = sortedReleasesCopy.concat(undefinedOrNullReleases);

    setSortedReleases(sortedReleases);
  };
  const handleSort = (value: string) => {
    if (releases) {
      if (sort === value) {
        if (sorted) {
          setSortedReleases(releases);
          setSort('');
          setSorted(false);
        } else {
          if (value === 'sender') {
            sortByProperty('desc', value, (release) => release.buyer?.name);
          } else if (value === 'requestDate') {
            sortByProperty('desc', value, (release) => release.requestedAt);
          } else if (value === 'throughDate') {
            sortByProperty('desc', value, (release) => {
              const pAmount = release?.fieldsData?.find((elem: any) => elem.id === 'throughDate');
              return pAmount?.value;
            });
          } else if (value === 'dueDate') {
            sortByProperty('desc', value, (release) => release.dueDate);
          } else if (value === 'status') {
            sortByProperty('desc', value, (release) => release.status);
          } else if (value === 'amount') {
            sortByProperty(
              'desc',
              value,
              (release) => {
                const pAmount = release?.fieldsData?.find(
                  (elem: any) => elem.id === 'paymentAmount'
                );
                return pAmount?.value;
              },
              (a, b) => a - b
            );
          } else if (value === 'job') {
            sortByProperty('desc', value, (release) => release.job?.name);
          } else if (value === 'type') {
            sortByProperty('desc', value, (release) =>
              release?.type.replaceAll('_', ' ').toLowerCase()
            );
          }

          setSorted(true);
        }
      } else {
        if (value === 'sender') {
          sortByProperty('asc', value, (release) => release.buyer?.name);
        } else if (value === 'requestDate') {
          sortByProperty('asc', value, (release) => release.requestedAt);
        } else if (value === 'throughDate') {
          sortByProperty('asc', value, (release) => {
            const pAmount = release?.fieldsData?.find((elem: any) => elem.id === 'throughDate');
            return pAmount?.value;
          });
        } else if (value === 'dueDate') {
          sortByProperty('asc', value, (release) => release.dueDate);
        } else if (value === 'status') {
          sortByProperty('asc', value, (release) => release.status);
        } else if (value === 'amount') {
          sortByProperty(
            'asc',
            value,
            (release) => {
              const pAmount = release?.fieldsData?.find((elem: any) => elem.id === 'paymentAmount');
              return pAmount?.value;
            },
            (a, b) => a - b
          );
        } else if (value === 'job') {
          sortByProperty('asc', value, (release) => release.job?.name);
        } else if (value === 'type') {
          sortByProperty('asc', value, (release) =>
            release?.type?.replaceAll('_', ' ').toLowerCase()
          );
        }

        setSort(value);
        setSorted(false);
      }
    }
  };
  const handleShowHistory = async () => {
    const releaseHisResult = await dispatch(
      getSupplierReleaseHistory({ releaseId: selectedRelease?.id ?? '' })
    );
    if (releaseHisResult.type === 'releases/getSupplierReleaseHistory/fulfilled') {
      setOpenShowHistory(true);
    }
  };
  return (
    <div className="w-full h-3/4">
      <AppReleaseHistoryModal
        open={openShowHistory}
        handleClose={() => setOpenShowHistory(false)}
        history={selectedReleaseHistory}
      />
      <div className="w-full h-full flex flex-col border rounded-lg  overflow-y-scroll mt-4">
        <AppPdfPreviewModal
          open={showPreviewPdf}
          handleClose={() => {
            setShowPreviewPdf(false);
            if (location.pathname.startsWith('/lienReleases')) {
              const params = new URLSearchParams(location.search);
              const releaseId = params.get('releaseId');
              if (releaseId) {
                navigate('/lienReleases');
              }
            }
          }}
          invoice={lienType}
          pdfType="RELEASE"
          pdfList={[{ name: 'lien-release', url: previewURL, date: new Date() }]}
          handleDownloadPdf={() => {
            if (selectedRelease) {
              handleDownloadSelectedPdf(selectedRelease);
            }
          }}
          isSigned={false}
          releaseDetails={releasePreviewDetails}
          mode="Supplier"
          onShowHistory={handleShowHistory}
        />
        {error ? (
          <AppErrorPage
            title="Error loading  suppliers."
            contactMessage="Please contact customer support."
            errorMessage={error}
          />
        ) : (
          <div>
            <div className="w-full flex border-b justify-between px-4 sticky top-0 PRIMARY_50-BG">
              {columns.map((column) => {
                return (
                  <div
                    key={column.id}
                    style={{ flex: column.width / totalWidth }}
                    className="py-3 px-1">
                    {column.cellType === 'SELECT' && column.id === 'select' && (
                      <AppCheckBox
                        isChecked={
                          selectedSupplierLiens.length !== 0 &&
                          selectedSupplierLiens.length === supplierLienReleases.length
                        }
                        onClick={(isChecked: boolean) => {
                          if (isChecked) {
                            dispatch(selectSupplierLiens(supplierLienReleases));
                          } else {
                            dispatch(unSelectSupplierLiens(supplierLienReleases));
                          }
                        }}
                      />
                    )}
                    {column.id !== 'select' && column.id !== 'actions' ? (
                      <div
                        onClick={() => handleSort(column.id)}
                        className="cursor-pointer flex align-center">
                        <p className="text-xs TEXT_SECONDARY-CLR mr-2">{column.name}</p>
                        <div>
                          {sort === column.id && sorted ? (
                            <SortAscending color={COLORS.GREY_400} />
                          ) : sort === column.id && !sorted ? (
                            <SortDescending color={COLORS.GREY_400} />
                          ) : (
                            <ArrowsDownUp color={COLORS.GREY_400} />
                          )}
                        </div>
                      </div>
                    ) : (
                      <p className="text-xs TEXT_SECONDARY-CLR">{column.name}</p>
                    )}
                  </div>
                );
              })}
            </div>

            {releases.length === 0 && (
              <div className="w-full h-full flex items-center justify-center">
                <div className="flex flex-col items-center">
                  <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
                  <p>
                    You're successfully setup! If you just got invited, your contractors will be
                    sending you releases later this month.
                  </p>
                </div>
              </div>
            )}

            {currentItems &&
              currentItems.map((lienWaiver, index) => {
                const throughdate = lienWaiver?.fieldsData?.find(
                  (elem) => elem.id === 'throughDate'
                );
                const pAmount = lienWaiver?.fieldsData?.find((elem) => elem.id === 'paymentAmount');
                return (
                  <div key={index + 'oo'} className="w-full flex  px-4 justify-between">
                    {columns.map((column) => {
                      return (
                        <div
                          key={column.id}
                          style={{ flex: column.width / totalWidth }}
                          className="flex py-3 px-1 items-center w-full h-full overflow-hidden
                           ">
                          {column.id === 'sender' && (
                            <div className="flex  w-full">
                              <div className="">
                                <p className="text-xs capitalize font-bold">
                                  {lienWaiver.buyer?.name || ''}
                                </p>
                                <p className="text-2xs mt-1 capitalize text-slate-500">
                                  {`${
                                    lienWaiver.sender?.subUser?.firstName ||
                                    lienWaiver.sender?.user?.firstName ||
                                    ''
                                  } ${
                                    lienWaiver.sender?.subUser?.lastName ||
                                    lienWaiver.sender?.user?.lastName ||
                                    ''
                                  }`}
                                </p>
                              </div>
                            </div>
                          )}
                          {column.id === 'requestDate' && (
                            <p className="text-sm capitalize  ">
                              {moment(lienWaiver.requestedAt).format('MM/DD/YYYY')}
                            </p>
                          )}
                          {column.id === 'throughDate' && (
                            <p className="text-sm capitalize">
                              {throughdate && throughdate.value
                                ? moment(new Date(throughdate.value)).format('MM/DD/YYYY')
                                : ''}
                            </p>
                          )}
                          {column.id === 'dueDate' && (
                            <p className="text-sm capitalize">
                              {lienWaiver?.dueDate
                                ? moment(lienWaiver.dueDate).format('MM/DD/YYYY')
                                : ''}
                            </p>
                          )}
                          {column.id === 'type' && (
                            <p className="text-sm capitalize">
                              {lienWaiver?.type?.replaceAll('_', ' ').toLowerCase()}
                            </p>
                          )}
                          {column.id === 'amount' && (
                            // <p className="text-sm capitalize">
                            //   {formatMoney(lienWaiver?.paymentAmount ?? '', 2)}
                            // </p>
                            <p className="text-sm capitalize">{`${
                              pAmount ? formatMoney(pAmount.value ?? '', 2) : ''
                            }`}</p>
                          )}
                          {column.id === 'job' && (
                            <div className="">
                              <p className="text-sm capitalize">{lienWaiver.job?.name}</p>
                            </div>
                          )}
                          {column.id === 'status' && (
                            <div className="w-full">
                              <AppReleaseStatusPill status={lienWaiver?.status} />
                            </div>
                          )}
                          {column.cellType === 'SELECT' && column.id === 'select' && (
                            <AppCheckBox
                              isChecked={selectedSupplierLiens.some(
                                (obj) => obj.id === lienWaiver.id
                              )}
                              onClick={(isChecked: boolean) => {
                                if (isChecked) {
                                  dispatch(selectSupplierLiens([lienWaiver]));
                                } else {
                                  dispatch(unSelectSupplierLiens([lienWaiver]));
                                }
                              }}
                            />
                          )}
                          {column.id === 'actions' && (
                            <div className="flex items-center">
                              <AppButton
                                type="TERTIARY"
                                buttonStyles={{
                                  padding: '8px',
                                  height: '38px',
                                  width: '38px',
                                  margin: '0px 4px',
                                }}
                                icon={<Eye color={COLORS.GREY_500} size={20} />}
                                onClick={() => {
                                  setSelectedRelease(lienWaiver);
                                  handlePreviewPdf(lienWaiver);
                                  navigate(`/lienReleases?releaseId=${lienWaiver?.id}`);
                                }}
                              />
                              <AppButton
                                type="TERTIARY"
                                buttonStyles={{
                                  padding: '8px',
                                  height: '38px',
                                  width: '38px',
                                  margin: '0px 4px',
                                }}
                                icon={<DownloadSimple color={COLORS.GREY_500} size={20} />}
                                onClick={() => {
                                  handleDownloadSelectedPdf(lienWaiver);
                                }}
                              />
                              <AppButton
                                text={'Sign'}
                                buttonStyles={{
                                  height: '38px',
                                  width: '60px',
                                  margin: '0px 4px',
                                }}
                                onClick={() => {
                                  dispatch(selectLiensRelease(lienWaiver));
                                  const state = { lienWaiver };
                                  navigate(`/lienReleases/${lienWaiver.id}`, { state });
                                }}
                                isDisabled={
                                  lienWaiver.status !== 'Requested' &&
                                  lienWaiver.status !== 'Rejected'
                                }
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {/* Pagination component */}
      <div className="flex flex-row justify-between items-center w-3/5 mt-3">
        <div className="text-xs GREY_500-CLR">{`Showing ${
          releases.length !== 0 ? `${itemOffset + 1} to` : ''
        } ${cummulativeOffset} from ${releases.length} release${
          releases.length > 1 ? 's' : ''
        }`}</div>
        <ReactPaginate
          breakLabel="..."
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          forcePage={Math.min(Math.max(0, currentPage - 1), pageCount - 1)}
          previousLabel={<CaretLeft size={20} />}
          nextLabel={<CaretRight size={20} />}
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
    </div>
  );
};

export default SupplierLienWaiversTable;
