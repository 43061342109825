import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';
import { firebaseFileUploadHelper } from '../../utils/helpers';
import { NoticeTemplateField } from '@dill/dill-shared';

export const createNoticeTemplateByAdmin = createAsyncThunk(
  'noticeTemplates/createNoticeTemplateByAdmin',
  async (
    {
      originalPdfUrl,
      modifiedPdfUrl,
      formFields,
      name,
    }: {
      originalPdfUrl: string;
      name: string;
      modifiedPdfUrl: string;
      formFields: NoticeTemplateField[];
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const downloadUrl = await firebaseFileUploadHelper({
        blobUrl: modifiedPdfUrl,
        fileNameWithExtension: `${new Date().getTime()}-${name}.pdf`,
        storagePath: 'noticeTemplates',
      });
      if (!downloadUrl) {
        return null;
      }
      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/suppliers/noticeTemplates/createNoticeTemplatesByAdmin`,
        { originalPdfUrl, modifiedPdfUrl: downloadUrl, formFields, name },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const createSupplierNoticeTemplate = createAsyncThunk(
  'noticeTemplates/createSupplierNoticeTemplate',
  async (
    {
      pdfUrl,
      name,
    }: {
      pdfUrl: string;
      name: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const downloadUrl = await firebaseFileUploadHelper({
        blobUrl: pdfUrl,
        fileNameWithExtension: `${new Date().getTime()}-${name}.pdf`,
        storagePath: 'noticeTemplates',
      });
      if (!downloadUrl) {
        return null;
      }
      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/suppliers/noticeTemplates/createSupplierNoticeTemplate`,
        { pdfUrl: downloadUrl, name },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getNoticeTemplates = createAsyncThunk(
  'noticeTemplates/getNoticeTemplates',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/noticeTemplates/getNoticeTemplates`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getNoticeTemplatesByAdmin = createAsyncThunk(
  'noticeTemplates/getNoticeTemplatesByAdmin',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/noticeTemplates/getNoticeTemplatesByAdmin?status=pending`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateNoticeTemplateByAdmin = createAsyncThunk(
  'noticeTemplates/updateNoticeTemplateByAdmin',
  async (
    {
      templateId,
      modifiedPdfUrl,
      formFields,
    }: {
      templateId: string;
      modifiedPdfUrl: string;
      formFields: NoticeTemplateField[];
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const downloadUrl = await firebaseFileUploadHelper({
        blobUrl: modifiedPdfUrl,
        fileNameWithExtension: `${new Date().getTime()}-${name}.pdf`,
        storagePath: 'noticeTemplates',
      });
      if (!downloadUrl) {
        return null;
      }
      const response = await axios.put<AppServerResponse>(
        `${BASE_API_URL}/suppliers/noticeTemplates/updateNoticeTemplatesByAdmin/${templateId}`,
        { modifiedPdfUrl: downloadUrl, formFields },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
