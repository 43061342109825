import { Provider } from 'react-redux';
import AppRouter from './AppRouter';
import './App.scss';
import LogRocket from 'logrocket';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/september-spirit/september-spirit.otf';
import { store } from './redux/store';
import { useEffect } from 'react';
import AppSetup from './AppSetup';
function App() {
  useEffect(() => {
    if (process.env.REACT_APP_FRONTEND_URL === 'https://app.usedill.com') {
      LogRocket.init(process.env.REACT_APP_LOG_ROCKET_PROJECT_ID || '', {
        network: {
          isEnabled: true,
        },
      });
    }
    return () => {};
  }, []);

  return (
    <Provider store={store}>
      <ToastContainer />
      <AppSetup />
      <div style={{ position: 'fixed', bottom: 15, right: 15, zIndex: 1000 }}>Version: 1.1.7</div>
    </Provider>
  );
}

export default App;
