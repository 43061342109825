import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export const useParamMainBuyerId = () => {
  const params = useParams();
  const location = useLocation();
  const [selectedMainBuyerId, setSelectedMainBuyerId] = useState<string | null>(null);

  useEffect(() => {
    if (params && params.mainBuyerId) {
      setSelectedMainBuyerId(params.mainBuyerId);
    } else {
      setSelectedMainBuyerId(null);
    }
    return () => {};
  }, [location.pathname]);

  return [selectedMainBuyerId];
};
