import { Modal } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { AppButton } from '../AppButton/AppButton';
import { X } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import { AppDropDown2 } from '../AppDropDown2/AppDropDown2';
import { AppCheckBox } from '../AppCheckBox/AppCheckBox';

const AppAddSupplierModal = ({
  open,
  handleClose,
  suppliersGroup,
  onAddSupplier,
}: {
  open: boolean;
  handleClose: () => void;
  suppliersGroup: { label: string; value: string }[];
  onAddSupplier: (list: string[]) => void;
}) => {
  const [suppliers, setSuppliers] = useState<string[]>([]);
  useEffect(() => {
    if (open) {
      setSuppliers([]);
    }
  }, [open]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-80 w-[540px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
        <h2 className="text-lg font-semibold mx-4 my-3">Add New Supplier</h2>
        <X
          className="absolute right-4 top-4 cursor-pointer"
          color={COLORS.GREY_500}
          onClick={handleClose}
        />
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
        {suppliersGroup.length === 0 ? (
          <div className="h-16 w-full flex items-center p-6 justify-center">No supplier to add</div>
        ) : (
          <div className="mx-4 mt-3 mb-2">
            <AppDropDown2
              isSearchable={false}
              isCreatable={false}
              closeMenu={false}
              isMulti
              showSelectedItems
              value={null}
              multiValue={suppliers}
              items={[
                ...suppliersGroup.map((item) => ({
                  label: item.label,
                  value: item.value,
                  labelInElement: (
                    <div className="flex m-2 items-center">
                      <AppCheckBox className="mr-2" isChecked={suppliers?.includes(item.value)} />
                      <div className="text-black text-sm">{item.label}</div>
                    </div>
                  ),
                })),
              ]}
              showMinimalDesign
              onMultiSelectChange={(data) => {
                if (data) {
                  const valueList: string[] = data.map((item) => item.value);
                  setSuppliers(valueList);
                }
              }}
            />
          </div>
        )}
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
        <div className="flex justify-end mx-3 mb-2">
          <AppButton
            text="Cancel"
            type="TERTIARY"
            onClick={handleClose}
            buttonStyles={{ marginRight: '8px' }}
          />
          <AppButton
            text="Add"
            isDisabled={suppliers.length === 0}
            onClick={() => {
              onAddSupplier(suppliers);
              handleClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AppAddSupplierModal;
