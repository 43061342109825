import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';
import { PreliminaryNoticeCreate, PreliminaryNoticeUpdate } from '@dill/dill-shared';
import { firebaseFileUploadHelper } from '../../utils/helpers';

export const createPreliminaryNotice = createAsyncThunk(
  'preliminaryNotices/createPreliminaryNotice',
  async (
    {
      preliminaryNoticeDetails,
      storagePathName,
    }: {
      preliminaryNoticeDetails: PreliminaryNoticeCreate;
      storagePathName: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      let preliminaryNoticeData = {
        ...preliminaryNoticeDetails,
      };
      if (preliminaryNoticeDetails.pdf?.url) {
        const downloadUrl = await firebaseFileUploadHelper({
          blobUrl: preliminaryNoticeDetails.pdf?.url,
          fileNameWithExtension: `${new Date().getTime()}.pdf`,
          storagePath: storagePathName,
        });

        preliminaryNoticeData = {
          ...preliminaryNoticeData,
          pdf: { url: downloadUrl ?? '', name: preliminaryNoticeDetails.pdf?.name ?? '' },
        };
      }

      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/suppliers/preliminaryNotices/createPreliminaryNotice`,
        { ...preliminaryNoticeData },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getSupplierPreliminaryNotices = createAsyncThunk(
  'preliminaryNotices/getSupplierPreliminaryNotices',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/preliminaryNotices/getSupplierPreliminaryNotices`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getPreliminaryNotice = createAsyncThunk(
  'releases/getPreliminaryNotice',
  async ({ preliminaryNoticeId }: { preliminaryNoticeId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        BASE_API_URL + `/suppliers/preliminaryNotices/getPreliminaryNotice/${preliminaryNoticeId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updatePreliminaryNotice = createAsyncThunk(
  'preliminaryNotices/updatePreliminaryNotice',
  async (
    {
      preliminaryNoticeId,
      preliminaryNoticeDetails,
      storagePathName,
    }: {
      preliminaryNoticeId: string;
      preliminaryNoticeDetails: PreliminaryNoticeUpdate;
      storagePathName?: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      let preliminaryNoticeData = preliminaryNoticeDetails;
      if (storagePathName && preliminaryNoticeDetails.pdf?.url) {
        const uploadUrl = await firebaseFileUploadHelper({
          blobUrl: preliminaryNoticeDetails.pdf?.url,
          fileNameWithExtension: `${new Date().getTime()}.pdf`,
          storagePath: storagePathName,
        });
        preliminaryNoticeData = {
          ...preliminaryNoticeDetails,
          pdf: {
            name: preliminaryNoticeDetails.pdf?.name ?? '',
            url: uploadUrl ?? '',
          },
        };
      }

      const response = await axios.put<AppServerResponse>(
        BASE_API_URL +
          `/suppliers/preliminaryNotices/updatePreliminaryNotice/${preliminaryNoticeId}`,
        {
          ...preliminaryNoticeData,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const deletePreliminaryNotice = createAsyncThunk(
  'preliminaryNotices/deletePreliminaryNotice',
  async (
    {
      preliminaryNoticeId,
    }: {
      preliminaryNoticeId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.delete<AppServerResponse>(
        BASE_API_URL +
          `/suppliers/preliminaryNotices/deletePreliminaryNotice/${preliminaryNoticeId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
