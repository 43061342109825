import React, { useEffect, useRef } from 'react';
import './AppMultiSelectDropDown.scss';
import Select from 'react-select';
import { FormOptionItem } from '../../../utils/interfaces/generalInterfaces';
import type { MultiValue } from 'react-select';
import { twMerge } from 'tailwind-merge';

export const AppMultiSelectDropDown = ({
  items = [],
  onSelect,
  value,
  isRequired = false,
  label = '',
  labelTextClassName = '',
  isSearchable = true,
  errorText = '',
}: {
  items: FormOptionItem[];
  onSelect?: (selectedOptions: FormOptionItem[]) => void;
  value: FormOptionItem[];
  label?: string;
  isRequired?: boolean;
  labelTextClassName?: string;
  isSearchable?: boolean;
  errorText?: string;
}) => {
  const menuPortalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (menuPortalRef.current && document.body) {
      document.body.appendChild(menuPortalRef.current);
    }

    return () => {
      if (menuPortalRef.current && document.body) {
        document.body.removeChild(menuPortalRef.current);
      }
    };
  }, []);

  const filteredOptions = items.filter(
    (item) => !value.some((selectedItem) => Number(selectedItem.value) === Number(item.value))
  );

  const handleSelectChange = (selectedValues: MultiValue<FormOptionItem>) => {
    if (onSelect) {
      onSelect(selectedValues as FormOptionItem[]);
    }
  };

  const customStyles = {
    menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: '200px',
      overflowY: 'auto',
    }),
    control: (provided: any) => ({
      ...provided,
      borderRadius: '8px',
      minHeight: '42px',
      borderColor: errorText ? '#F04438' : '#E4EAF1',
      marginBottom: '4px',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: '#94A3B8',
      fontSize: 12,
    }),
  };

  return (
    <div className="w-full flex flex-col relative">
      {(label || isRequired) && (
        <p className={twMerge('text-xs mb-0.5  whitespace-nowrap font-medium', labelTextClassName)}>
          {isRequired && <span className="text-red-500">*</span>}
          {label}
        </p>
      )}
      <div ref={menuPortalRef} />
      <Select
        closeMenuOnSelect={false}
        isMulti
        options={filteredOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        value={value}
        onChange={handleSelectChange}
        menuPortalTarget={menuPortalRef.current}
        styles={customStyles}
        isSearchable={isSearchable}
      />
      {errorText && <p className="app-multi-select-drop-error-text">{errorText}</p>}
    </div>
  );
};
