import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import emptyListImg from '../../../../assets/images/emptyList.png';
import alertCircleImg from '../../../../assets/images/alertCircle.png';
import moment from 'moment';
import './UserSalesReceiptsTable.scss';
import { AppButton } from '../../../general/AppButton/AppButton';
import { QuickbooksSalesReceipt, SalesReceiptStatus, AppFile, roundTo } from '@dill/dill-shared';
import { useLocation, useParams } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { myAnalytics } from '../../../../firebase/config';
import { downloadFile } from '../../../../utils/helpers';
import { AppCheckBox } from '../../../general/AppCheckBox/AppCheckBox';
import { Checks, X } from 'phosphor-react';
import { COLORS } from '../../../../utils/colors';
import { AppDropDown2 } from '../../../general/AppDropDown2/AppDropDown2';
import { AppPdfPreviewModal } from '../../../general/AppPdfPreviewModal/AppPdfPreviewModal';
import { Eye } from 'react-feather';
import { AppPdfListModal } from '../../../general/AppPdfListModal/AppPdfListModal';
import {
  assignDriverToSalesReceipt,
  getSalesReceiptPdf,
} from '../../../../redux/services/salesReceiptsService';
import {
  selectSalesReceiptIdsForDownload,
  unSelectSalesReceiptIdsForDownload,
} from '../../../../redux/globalSlices/salesReceiptsSlice';

export const UserSalesReceiptsTable = ({
  salesReceipts,
  selectedTabId,
}: {
  salesReceipts: QuickbooksSalesReceipt[];
  selectedTabId: SalesReceiptStatus;
}) => {
  // const { salesReceipts } = useAppSelector((state) => state.salesReceipt);
  const { salesReceiptBase64, salesReceiptIdsForDownload, previewPdf } = useAppSelector(
    (state) => state.salesReceipts
  );
  const { supplierDrivers, userSupplierSalesReceiptsDataWeeksLimit } = useAppSelector(
    (state) => state.supplier
  );
  const { user } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const dispatch = useAppDispatch();
  const params = useParams();

  const [currentSupplierDomainId, setCurrentSupplierDomainId] = useState('');
  const [openPdfPreviewModal, setOpenPdfPreviewModal] = useState(false);
  const [openPdfListModal, setOpenPdfListModal] = useState(false);
  const [selectedSalesReceiptNumber, setSelectedSalesReceiptNumber] = useState('');
  const [previewMode, setPreviewMode] = useState(false);
  const [downloadPdfList, setDownloadPdfList] = useState([] as any);
  const [previewPdfList, setPreviewPdfList] = useState([] as any);
  const [salesReceiptSigned, setSalesReceiptSigned] = useState(false);

  const isAnySignedSalesReceipt = useMemo(() => {
    let check = false;
    if (salesReceipts) {
      check = salesReceipts.some((salesReceipt) => salesReceipt.dillSalesReceipt?.receivedBy);
    }
    return check;
  }, [salesReceipts]);

  const columns = useMemo(() => {
    if (selectedTabId === 'THIS_WEEK') {
      const currentColumns = [
        { id: 'select', name: '', width: 50, cellType: 'SELECT' },
        { id: 'docNumber', name: 'Sales Receipt #', width: 150, cellType: 'VIEW_TEXT' },
        { id: 'createdAt', name: 'Date', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'customerName', name: 'Customer', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'amount', name: 'Amount', width: 150, cellType: 'VIEW_TEXT' },
        { id: 'driver', name: 'Driver', width: 300, cellType: 'VIEW_TEXT' },
        { id: 'signature', name: 'Signature', width: 150, cellType: 'VIEW_TEXT' },
        { id: 'signedBy', name: 'Signed By', width: 250, cellType: 'VIEW_TEXT' },
        { id: 'download', name: 'Sales Receipt', width: 200, cellType: 'VIEW_TEXT' },
      ];
      return isAnySignedSalesReceipt
        ? currentColumns
        : currentColumns.filter((col) => col.id !== 'signedBy');
    } else {
      const currentColumns = [
        { id: 'select', name: '', width: 50, cellType: 'SELECT' },
        { id: 'docNumber', name: 'Sales Receipt #', width: 150, cellType: 'VIEW_TEXT' },
        { id: 'createdAt', name: 'Date', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'customerName', name: 'Customer', width: 180, cellType: 'VIEW_TEXT' },
        { id: 'amount', name: 'Amount', width: 150, cellType: 'VIEW_TEXT' },
        { id: 'signedBy', name: 'Signed By', width: 250, cellType: 'VIEW_TEXT' },
        { id: 'download', name: 'Sales Receipt', width: 200, cellType: 'VIEW_TEXT' },
      ];
      return isAnySignedSalesReceipt
        ? currentColumns
        : currentColumns.filter((col) => col.id !== 'signedBy');
    }
  }, [selectedTabId, isAnySignedSalesReceipt]);

  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );

  useEffect(() => {
    if (salesReceiptBase64) {
      // window.location.href = salesReceiptBase64;
      // window.location.href = 'data:application/pdf;base64,' + salesReceiptBase64;
      // const image = new Image();
      // image.src = `data:application/pdf;base64,${salesReceiptBase64}`;
      // const newTab = window.open();
      // if (newTab) {
      //   newTab.document.body.innerHTML = image.outerHTML;
      // }

      window.open('data:application/pdf;base64, ' + salesReceiptBase64, '_blank');

      // window.open('');
      // window.document.write(
      //   "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
      //     encodeURI(salesReceiptBase64) +
      //     "'></iframe>"
      // );
      // dispatch(resetSalesReceiptBase64());
    }
    return () => {};
  }, [salesReceiptBase64]);

  useEffect(() => {
    if (previewPdf && previewMode) {
      setSalesReceiptSigned(false);
      setPreviewPdfList([{ url: previewPdf, name: 'pdf', date: new Date() }]);
      setOpenPdfPreviewModal(true);
      logEvent(myAnalytics, 'buyer_preview_salesReceipt', {
        userId: user ? user.id : '',
        time: new Date(),
        route: location.pathname,
      });
    }
  }, [previewPdf]);

  useEffect(() => {
    if (location.pathname) {
      if (params.supplierDomainId) {
        setCurrentSupplierDomainId(params.supplierDomainId);
      }
    }

    return () => {};
  }, [location.pathname]);

  const handleDownloadPdf = (
    salesReceiptId: string,
    salesReceiptNumber: string,
    signedPdfs?: AppFile[] | undefined | null
  ) => {
    setPreviewMode(false);
    setSelectedSalesReceiptNumber(salesReceiptNumber);
    if (signedPdfs && signedPdfs.length > 0) {
      const sortedPdfs = [...signedPdfs].sort((a, b) => {
        console.log(a, b);
        if (!b.date || !a.date) {
          return 0;
        }
        return new Date(b.date.toString()).getTime() > new Date(a.date.toString()).getTime()
          ? 1
          : -1;
      });
      if (sortedPdfs.length > 1) {
        setDownloadPdfList(sortedPdfs);
        setOpenPdfListModal(true);
      } else {
        downloadFile(
          sortedPdfs[0].url || '',
          `salesReceipt-${salesReceiptNumber}-${+new Date()}.pdf`
        );
        logEvent(myAnalytics, 'buyer_download_salesReceipt', {
          userId: user ? user.id : '',
          time: new Date(),
          route: location.pathname,
        });
      }
    } else {
      if (user?.userSupplier?.domainId) {
        dispatch(
          getSalesReceiptPdf({ supplierDomainId: user?.userSupplier?.domainId, salesReceiptId })
        );
        logEvent(myAnalytics, 'buyer_download_salesReceipt', {
          userId: user ? user.id : '',
          time: new Date(),
          route: location.pathname,
        });
      }
    }
  };

  const handlePreviewPdf = async (
    salesReceiptId: string,
    salesReceiptNumber: string,
    signedPdfs?: AppFile[] | undefined | null
  ) => {
    setSelectedSalesReceiptNumber(salesReceiptNumber);
    setPreviewMode(true);
    if (signedPdfs && signedPdfs.length > 0) {
      const sortedPdfs = [...signedPdfs].sort((a, b) => {
        console.log(a, b);
        if (!b.date || !a.date) {
          return 0;
        }
        return new Date(b.date.toString()).getTime() > new Date(a.date.toString()).getTime()
          ? 1
          : -1;
      });
      setSalesReceiptSigned(true);
      setPreviewPdfList(sortedPdfs);
      setOpenPdfPreviewModal(true);
      logEvent(myAnalytics, 'buyer_preview_salesReceipt', {
        userId: user ? user.id : '',
        time: new Date(),
        route: location.pathname,
      });
    } else {
      if (user?.userSupplier?.domainId) {
        const salesReceipt = await dispatch(
          getSalesReceiptPdf({
            supplierDomainId: user?.userSupplier?.domainId,
            salesReceiptId,
            preview: true,
          })
        );
      }
    }
  };
  const handleDownloadSelectedPdf = (url: string) => {
    downloadFile(url, `salesReceipt-${selectedSalesReceiptNumber}-${+new Date()}.pdf`);
  };
  return (
    <div className=" h-full overflow-y-scroll">
      <div className="flex flex-col overflow-x-scroll h-full  w-full border rounded-lg">
        <div className="flex w-full h-full flex-col overflow-y-scroll ">
          <div className="flex border-b px-5 items-center w-full sticky top-0 WHITE-BG py-3 z-10">
            {columns.map((column) => {
              return (
                <div
                  key={column.id}
                  style={{ flex: column.width / totalWidth }}
                  className="flex items-center  h-full   ">
                  {column.cellType === 'SELECT' && column.id === 'select' && (
                    <AppCheckBox
                      isChecked={
                        salesReceiptIdsForDownload.length !== 0 &&
                        salesReceiptIdsForDownload.length === salesReceipts.length
                      }
                      onClick={(isChecked: boolean) => {
                        if (isChecked) {
                          dispatch(
                            selectSalesReceiptIdsForDownload(
                              salesReceipts.map((salesReceipt) => salesReceipt.id)
                            )
                          );
                        } else {
                          dispatch(
                            unSelectSalesReceiptIdsForDownload(
                              salesReceipts.map((salesReceipt) => salesReceipt.id)
                            )
                          );
                        }
                      }}
                    />
                  )}
                  {column.cellType !== 'SELECT' && (
                    <div>
                      {column.id === 'customerName' ? (
                        <p className="TEXT_SECONDARY-CLR text-xs" style={{ width: column.width }}>
                          {column.name}
                        </p>
                      ) : (
                        <p className="TEXT_SECONDARY-CLR text-xs ">{column.name}</p>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {salesReceipts.length < 1 && (
            <div className="w-full h-full flex items-center justify-center">
              <div className="flex flex-col items-center">
                <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
                <p>No sales receipts</p>
              </div>
            </div>
          )}
          {salesReceipts.length > 0 &&
            [...salesReceipts].map((salesReceipt, salesReceiptIndex) => {
              return (
                <div
                  key={salesReceiptIndex + 'oo'}
                  className={
                    'flex  px-5 my-2 ' +
                    (salesReceipt.dillSalesReceipt?.errorMessage ? 'ERROR_100-BG' : '')
                  }>
                  {columns.map((column) => {
                    type ObjectKey = keyof typeof salesReceipt;
                    const columnKey = column.id as ObjectKey;
                    let value = salesReceipt[columnKey]?.toString();
                    if (column.id === 'createdAt' || column.id === 'dueDate') {
                      // value = moment(value).format('MM/DD/YYYY');
                      value = moment(value).utc().format('MM/DD/YYYY');
                    }
                    if (column.id === 'balance' || column.id === 'amount') {
                      value = `$${roundTo(parseFloat(value || '0'), 2)}`;
                    }

                    return (
                      <div
                        key={column.id}
                        style={{ flex: column.width / totalWidth }}
                        className="flex items-center w-full h-full  justify-center  ">
                        {/* {column.cellType === 'VIEW_TEXT' && column.id === 'id' && (
                          <p style={{}}>{salesReceiptIndex + 1}</p>
                        )} */}
                        {column.cellType === 'SELECT' && column.id === 'select' && (
                          <AppCheckBox
                            isChecked={salesReceiptIdsForDownload.includes(salesReceipt.id)}
                            onClick={(isChecked: boolean) => {
                              if (isChecked) {
                                dispatch(selectSalesReceiptIdsForDownload([salesReceipt.id]));
                              } else {
                                dispatch(unSelectSalesReceiptIdsForDownload([salesReceipt.id]));
                              }
                            }}
                          />
                        )}
                        {column.cellType === 'VIEW_TEXT' && column.id == 'customerName' && (
                          <p
                            className={'text-sm text-left flex-row name-container'}
                            style={{ width: column.width }}>
                            {value}
                            {salesReceipt.dillSalesReceipt &&
                              salesReceipt.dillSalesReceipt.isReceived &&
                              salesReceipt.dillSalesReceipt?.errorMessage != '' && (
                                <div className="tooltip-trigger">
                                  <img className="alert-icon" src={alertCircleImg} alt="" />
                                  <div className="tooltip tooltip-bottom text-sm">
                                    {salesReceipt.dillSalesReceipt?.errorMessage}
                                  </div>
                                </div>
                              )}
                          </p>
                        )}
                        {column.cellType === 'VIEW_TEXT' &&
                          (column.id === 'docNumber' ||
                            column.id === 'createdAt' ||
                            column.id === 'dueDate' ||
                            column.id === 'amount' ||
                            column.id === 'balance' ||
                            column.id == 'signedBy' ||
                            column.id == 'signature' ||
                            column.id == 'isPastDueDate') && (
                            <p
                              className={
                                'text-sm ' +
                                (column.id === 'dueDate' && selectedTabId === 'PAST_DUE'
                                  ? 'ERROR_500-CLR'
                                  : 'TEXT_PRIMARY-CLR')
                              }>
                              {value}
                            </p>
                          )}
                        {column.cellType === 'VIEW_TEXT' && column.id === 'driver' && (
                          <div className="w-full px-1">
                            <AppDropDown2
                              inputFieldStyles={{
                                width: '90%',
                                border: '1px solid #E4EAF1',
                                background: 'white',
                              }}
                              disabled={salesReceipt.dillSalesReceipt?.isReceived}
                              value={salesReceipt.dillSalesReceipt?.driverId?.userId || ''}
                              items={[
                                ...supplierDrivers.map((item) => {
                                  return {
                                    label: `${item?.firstName ? item?.firstName : ''} ${
                                      item?.lastName ? item?.lastName : ''
                                    } ${item?.phoneNumber ? `(${item?.phoneNumber})` : ''}`,
                                    value: item.id,
                                  };
                                }),
                              ]}
                              onSelectChange={(newValue) => {
                                if (newValue.value !== salesReceipt.dillSalesReceipt?.driverId) {
                                  dispatch(
                                    assignDriverToSalesReceipt({
                                      salesReceiptId: salesReceipt.id,
                                      driverId: newValue.value,
                                      weeksLimit: userSupplierSalesReceiptsDataWeeksLimit,
                                    })
                                  );
                                }
                              }}
                            />
                          </div>
                        )}

                        {column.cellType === 'VIEW_TEXT' && column.id === 'signedBy' && (
                          <div className="items-center justify-center w-full">
                            {salesReceipt.dillSalesReceipt &&
                              salesReceipt.dillSalesReceipt.isReceived && (
                                <div className="items-center justify-center">
                                  {salesReceipt.dillSalesReceipt.receivedBy && (
                                    <p
                                      className={
                                        'text-xs font-bold text-center  ' + 'TEXT_PRIMARY-CLR'
                                      }>
                                      {salesReceipt.dillSalesReceipt.receivedBy}
                                    </p>
                                  )}
                                  {salesReceipt.dillSalesReceipt.receivedByTime && (
                                    <p className={'text-xs text-center ' + 'TEXT_PRIMARY-CLR'}>
                                      {moment(salesReceipt.dillSalesReceipt.receivedByTime).format(
                                        'MM/DD  LT'
                                      )}
                                    </p>
                                  )}
                                </div>
                              )}
                            {(!salesReceipt.dillSalesReceipt ||
                              (salesReceipt.dillSalesReceipt &&
                                !salesReceipt.dillSalesReceipt.isReceived)) && (
                              <p className={'text-sm  text-center ' + 'TEXT_PRIMARY-CLR'}>-</p>
                            )}
                          </div>
                        )}
                        {column.cellType === 'VIEW_TEXT' && column.id === 'signature' && (
                          <div style={{}} className="w-full flex justify-center">
                            {salesReceipt.dillSalesReceipt &&
                              salesReceipt.dillSalesReceipt.isReceived && (
                                <Checks size={30} color={COLORS.PRIMARY_500} />
                              )}
                            {(!salesReceipt.dillSalesReceipt ||
                              (salesReceipt.dillSalesReceipt &&
                                !salesReceipt.dillSalesReceipt.isReceived)) && (
                              <X size={20} color={COLORS.ERROR_500} />
                            )}
                          </div>
                        )}
                        {column.cellType === 'VIEW_TEXT' && column.id === 'download' && (
                          <div className="flex">
                            <AppButton
                              type="TERTIARY"
                              buttonStyles={{
                                padding: '8px',
                                margin: '5px 8px',
                              }}
                              icon={<Eye color={COLORS.GREY_500} size={20} />}
                              onClick={() => {
                                handlePreviewPdf(
                                  salesReceipt.id,
                                  salesReceipt.docNumber,
                                  salesReceipt.dillSalesReceipt?.signedPdfs
                                );
                              }}
                            />
                            {/* <a
                              href={`${BASE_API_URL}/salesReceiptsAPI/salesReceiptPdf/?salesReceiptId=${salesReceipt.id}&supplierId=${currentSupplierId}`}>
                              Download
                            </a> */}
                            <AppButton
                              text="Download"
                              onClick={() => {
                                handleDownloadPdf(
                                  salesReceipt.id,
                                  salesReceipt.docNumber,
                                  salesReceipt.dillSalesReceipt?.signedPdfs
                                );
                              }}
                            />
                            {/* <AppButton
                              text="Signed Copy"
                              buttonType="FILLED"
                              buttonColor="PRIMARY_500"
                              buttonStyles={{ margin: '0 10px' }}
                            /> */}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </div>
      <AppPdfListModal
        open={openPdfListModal}
        handleClose={() => {
          setOpenPdfListModal(false);
        }}
        pdfList={downloadPdfList}
        invoice={selectedSalesReceiptNumber}
        handleDownloadPdf={handleDownloadSelectedPdf}
        pdfType="SALES_RECEIPT"
      />
      <AppPdfPreviewModal
        open={openPdfPreviewModal}
        handleClose={() => {
          setOpenPdfPreviewModal(false);
        }}
        invoice={selectedSalesReceiptNumber}
        pdfList={previewPdfList}
        handleDownloadPdf={handleDownloadSelectedPdf}
        isSigned={salesReceiptSigned}
        pdfType="SALES_RECEIPT"
      />
    </div>
  );
};
