import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { myFirebaseAuth } from '../../firebase/config';
import { logoutUser } from './authService';

export const getUserSuppliers = createAsyncThunk(
  'supplier/getUserSuppliers',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<AppServerResponse>(
        BASE_API_URL + '/invoicesAPI/distributors',
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getUserCustomers = createAsyncThunk(
  'supplier/getUserCustomers',
  async (
    {
      previousLastDocumentId,
      pageSize,
      sortBy,
      sortOrder,
    }: {
      previousLastDocumentId?: string;
      pageSize: number;
      sortBy: string;
      sortOrder: 'asc' | 'desc';
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      let params: any = { pageSize };
      if (previousLastDocumentId) {
        params = { ...params, previousLastDocumentId };
      }
      if (sortBy) {
        params = { ...params, sortBy };
      }
      if (sortOrder) {
        params = { ...params, sortOrder };
      }
      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/getCustomers`,
        {
          params,
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getSupplierSummary = createAsyncThunk(
  'supplier/getSupplierSummary',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/getSupplierSummary`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getUserInvoices = createAsyncThunk(
  'supplier/getUserInvoices',
  async (data: { weeksLimit?: number } | null, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      let url = `${BASE_API_URL}/invoicesAPI/supplierInvoices`;
      if (data && data.weeksLimit) {
        url = `${BASE_API_URL}/invoicesAPI/supplierInvoices?weeksLimit=${data.weeksLimit}`;
      }
      const response = await axios.get<AppServerResponse>(url, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getUserSalesReceipts = createAsyncThunk(
  'supplier/getUserSalesReceipts',
  async (data: { weeksLimit?: number } | null, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      let url = `${BASE_API_URL}/salesReceiptsAPI/supplierSalesReceipts`;
      if (data && data.weeksLimit) {
        url = `${BASE_API_URL}/salesReceiptsAPI/supplierSalesReceipts?weeksLimit=${data.weeksLimit}`;
      }
      const response = await axios.get<AppServerResponse>(url, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getUserDrivers = createAsyncThunk(
  'supplier/getUserDrivers',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<AppServerResponse>(BASE_API_URL + '/driversAPI/drivers', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const assignDriverToInvoice = createAsyncThunk(
  'supplier/assignDriverToInvoice',
  async (
    {
      invoiceId,
      driverId,
      weeksLimit,
    }: { invoiceId: string; driverId: string; weeksLimit: number },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/invoicesAPI/supplierInvoices/${invoiceId}/driver`,
        { driverId },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      thunkApi.dispatch(getUserInvoices({ weeksLimit }));
      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const updateSupplier = createAsyncThunk(
  'supplier/update',
  async (
    {
      supplierId,
      supplierDetails,
    }: {
      supplierId: string;
      // TODO: Add the type of data
      supplierDetails: any;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<AppServerResponse>(
        BASE_API_URL + `/suppliers/updateSupplier/${supplierId}`,
        { ...supplierDetails },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const sendEmailStatementsForBuyers = createAsyncThunk(
  'supplier/sendEmailStatementsForBuyers',
  async (
    {
      buyerIds,
    }: {
      buyerIds: string[];
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<AppServerResponse>(
        BASE_API_URL + `/quickBooksAuthAPI/buyersEmailStatement`,
        { buyerIds },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      let errorMessage = '';
      if (error?.response?.data?.errors) {
        errorMessage = [...error?.response?.data?.errors].reduce((prev, curr) => {
          if (curr.msg) {
            return `${prev} ${curr.msg}`;
          }
          return prev;
        }, '');
      } else {
        errorMessage = error?.message;
      }
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const addDistributionOneSupplierConnection = createAsyncThunk(
  'supplier/addDistributionOneSupplierConnection',
  async (
    {
      companyName,
      userName,
      password,
    }: {
      companyName: string;
      userName: string;
      password: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/suppliers/accountingIntegrations/addDistributionOneSupplierConnection`,
        { companyName, userName, password },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      let errorMessage = '';
      if (error?.response?.data?.errors) {
        errorMessage = [...error?.response?.data?.errors].reduce((prev, curr) => {
          if (curr.msg) {
            return `${prev} ${curr.msg}`;
          }
          return prev;
        }, '');
      } else {
        errorMessage = error?.message;
      }
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

export const addSage100SupplierConnection = createAsyncThunk(
  'supplier/addSage100SupplierConnection',
  async (
    {
      clientId,
      clientSecrete,
    }: {
      clientId: string;
      clientSecrete: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/suppliers/accountingIntegrations/addSage100SupplierConnection`,
        { clientId, clientSecrete },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      let errorMessage = '';
      if (error?.response?.data?.errors) {
        errorMessage = [...error?.response?.data?.errors].reduce((prev, curr) => {
          if (curr.msg) {
            return `${prev} ${curr.msg}`;
          }
          return prev;
        }, '');
      } else {
        errorMessage = error?.message;
      }
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);
