import React from 'react';
import AddTemplateFields from '../AddTemplateFields/AddTemplateFields';

const BuyerTemplatesFields = () => {
  return (
    <div className="w-full h-full">
      <AddTemplateFields />
    </div>
  );
};

export default BuyerTemplatesFields;
