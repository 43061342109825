import { Modal } from '@mui/material';
import { AppButton } from '../AppButton/AppButton';
import { COLORS } from '../../../utils/colors';
import { X, Eye } from 'react-feather';
import { PreliminaryNoticeApiResponse } from '@dill/dill-shared/dist/src';

export const AppTrackPreliminaryNoticeModal = ({
  open,
  handleClose,
  preliminaryNotice,
  onPreviewAttachment,
}: {
  open: boolean;
  handleClose?: () => void;
  preliminaryNotice: PreliminaryNoticeApiResponse | null;
  onPreviewAttachment: (url: string) => void;
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col min-h-8 min-h-80 w-[412px]">
        <h2 className="text-lg font-semibold mx-4 my-3">Track Notice</h2>
        <X
          className="absolute right-4 top-4 cursor-pointer"
          color={COLORS.GREY_500}
          onClick={handleClose}
        />
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400"></div>
        <div className="mx-4 mt-3 mb-2">
          <div className="flex justify-between items-center mb-2">
            <div className="text-sm w-[120px]">Contractor Status</div>
            <div className="capitalize text-sm w-[120px]">
              {preliminaryNotice?.contractorStatus?.status ?? ''}
            </div>
            <div className="w-[120px]">
              {preliminaryNotice?.contractorStatus?.trackLink && (
                <a
                  className="underline text-sm PRIMARY_500-CLR"
                  href={preliminaryNotice?.contractorStatus?.trackLink ?? ''}
                  target="_blank">
                  Tracking Link
                </a>
              )}
            </div>
            <div className="w-[50px]">
              {preliminaryNotice?.contractorStatus?.returnAttachments &&
                preliminaryNotice?.contractorStatus?.returnAttachments.length > 0 && (
                  <AppButton
                    type="TERTIARY"
                    buttonStyles={{
                      padding: '4px',
                      height: '30px',
                      width: '30px',
                    }}
                    icon={<Eye color={COLORS.GREY_500} size={18} />}
                    onClick={() => {
                      onPreviewAttachment(
                        preliminaryNotice?.contractorStatus?.returnAttachments?.[0]?.url ?? ''
                      );
                    }}
                  />
                )}
            </div>
          </div>
          <div className="flex justify-between items-center mb-2">
            <div className="text-sm w-[120px]">Onwer Status</div>
            <div className="capitalize text-sm w-[120px]">
              {preliminaryNotice?.ownerStatus?.status ?? ''}
            </div>
            <div className="w-[120px]">
              {preliminaryNotice?.ownerStatus?.trackLink && (
                <a
                  className="underline text-sm PRIMARY_500-CLR"
                  href={preliminaryNotice?.ownerStatus?.trackLink ?? ''}
                  target="_blank">
                  Tracking Link
                </a>
              )}
            </div>
            <div className="w-[50px]">
              {preliminaryNotice?.ownerStatus?.returnAttachments &&
                preliminaryNotice?.ownerStatus?.returnAttachments.length > 0 && (
                  <AppButton
                    type="TERTIARY"
                    buttonStyles={{
                      padding: '4px',
                      height: '30px',
                      width: '30px',
                    }}
                    icon={<Eye color={COLORS.GREY_500} size={18} />}
                    onClick={() => {
                      onPreviewAttachment(
                        preliminaryNotice?.ownerStatus?.returnAttachments?.[0]?.url ?? ''
                      );
                    }}
                  />
                )}
            </div>
          </div>
          <div className="flex justify-between items-center ">
            <div className="text-sm w-[120px]">Lender Status</div>
            <div className="capitalize text-sm w-[120px]">
              {preliminaryNotice?.lenderStatus?.status ?? ''}
            </div>
            <div className="w-[120px]">
              {preliminaryNotice?.lenderStatus?.trackLink && (
                <a
                  className="underline text-sm PRIMARY_500-CLR"
                  href={preliminaryNotice?.lenderStatus?.trackLink ?? ''}
                  target="_blank">
                  Tracking Link
                </a>
              )}
            </div>
            <div className="w-[50px]">
              {preliminaryNotice?.lenderStatus?.returnAttachments &&
                preliminaryNotice?.lenderStatus?.returnAttachments.length > 0 && (
                  <AppButton
                    type="TERTIARY"
                    buttonStyles={{
                      padding: '4px',
                      height: '30px',
                      width: '30px',
                    }}
                    icon={<Eye color={COLORS.GREY_500} size={18} />}
                    onClick={() => {
                      onPreviewAttachment(
                        preliminaryNotice?.lenderStatus?.returnAttachments?.[0]?.url ?? ''
                      );
                    }}
                  />
                )}
            </div>
          </div>
        </div>
        <div style={{ height: '0.01em' }} className="h-0.5 w-full bg-slate-400 my-4"></div>
        <div className="flex justify-end mx-3 mb-2">
          <AppButton
            text="Cancel"
            type="TERTIARY"
            onClick={handleClose}
            buttonStyles={{ marginRight: '8px' }}
          />
        </div>
      </div>
    </Modal>
  );
};
