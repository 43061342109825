import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../redux/store';
import { RefreshCcw } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import { timeSince } from '@dill/dill-shared';
import momenttz from 'moment-timezone';

const AppLastSyncDataView = ({ dataKey, from }: { dataKey: string; from: string }) => {
  const { lastSyncTimeBuyer, lastSyncTimeSupplier } = useAppSelector((state) => state.generic);
  const { user } = useAppSelector((state) => state.auth);
  const [finalLastSyncTime, setFinalLastSyncTime] = useState<Date | null>();
  const [intervalChange, setIntervalChange] = useState({});

  useEffect(() => {
    if (lastSyncTimeBuyer && from === 'buyer') {
      if (dataKey === 'invoices') {
        const dates = [];
        if (lastSyncTimeBuyer.invoices) {
          dates.push(momenttz(lastSyncTimeBuyer.invoices).toDate());
        }
        if (lastSyncTimeBuyer.creditNotes) {
          dates.push(momenttz(lastSyncTimeBuyer.creditNotes).toDate());
        }

        const earliestDate = momenttz(Math.max(...dates.map((date) => date.getTime()))).toDate();
        console.log('buyer-side', {
          displayedTime: timeSince(earliestDate),
          lastSyncTimeBuyer,
          earliestDate,
          currentDate: new Date(),
        });
        if (earliestDate && dates.length > 0) {
          setFinalLastSyncTime(earliestDate);
        }
      }
    }

    return () => {};
  }, [lastSyncTimeBuyer]);
  useEffect(() => {
    if (lastSyncTimeSupplier && from === 'supplier') {
      if (dataKey === 'invoices') {
        const dates = [];
        if (lastSyncTimeSupplier.invoices) {
          dates.push(momenttz(lastSyncTimeSupplier.invoices).toDate());
        }

        const earliestDate = momenttz(Math.max(...dates.map((date) => date.getTime()))).toDate();
        if (earliestDate && dates.length > 0) {
          console.log(earliestDate);
          setFinalLastSyncTime(earliestDate);
        }
      }
      if (dataKey === 'customers') {
        const dates = [];

        if (lastSyncTimeSupplier.customers) {
          dates.push(momenttz(lastSyncTimeSupplier.customers).toDate());
        }
        if (lastSyncTimeSupplier.invoices) {
          dates.push(momenttz(lastSyncTimeSupplier.invoices).toDate());
        }
        if (lastSyncTimeSupplier.creditNotes) {
          dates.push(momenttz(lastSyncTimeSupplier.creditNotes).toDate());
        }
        let earliestDate: Date | null = null;
        // if (user?.userSupplier?.integrationType?.startsWith('RUTTER_')) {
        //   earliestDate = momenttz(Math.min(...dates.map((date) => date.getTime()))).toDate();
        // } else if (user?.userSupplier?.integrationType === 'QBO') {
        //   earliestDate = momenttz(Math.max(...dates.map((date) => date.getTime()))).toDate();
        // }
        earliestDate = momenttz(Math.max(...dates.map((date) => date.getTime()))).toDate();
        console.log('supplier-side', {
          displayedTime: timeSince(earliestDate),
          lastSyncTimeBuyer,
          earliestDate,
          currentDate: new Date(),
        });
        if (earliestDate && dates.length > 0) {
          setFinalLastSyncTime(earliestDate);
        }
      }
    }

    return () => {};
  }, [lastSyncTimeSupplier]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIntervalChange({});
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="relative flex">
      {finalLastSyncTime && (
        <div className="flex items-center">
          <RefreshCcw className="mr-1" color={COLORS.PRIMARY_500} size={16} />
          <p className="text-xs">
            <span className=" text-2xs">Synced </span>
            <span className="font-semibold">{`${timeSince(finalLastSyncTime)} ago`}</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default AppLastSyncDataView;
