import { Modal } from '@mui/material';
import './AuthorizeQuickbooksConnect.scss';
import { AppButton } from '../../../general/AppButton/AppButton';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { Check, X } from 'react-feather';
import quickBooksConnectIcon from '../../../../assets/svg/quickbooks_connect.svg';
import { COLORS } from '../../../../utils/colors';

export const AuthorizeQuickbooksConnect = ({
  open,
  modalImage,
  platformName,
  handleClose = () => {},
  handleAuthorizeButtonClick = () => {},
}: {
  open: boolean;
  handleClose: () => void;
  platformName: string;
  modalImage?: any;
  handleAuthorizeButtonClick: () => void;
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="quick-books-auth-content text-center">
        <img className="h-13 my-4" src={modalImage || quickBooksConnectIcon} />
        <p className=" font-bold mt-5 mb-3 text-lg ">
          Securely connect your {platformName} to Dill
        </p>

        <p className={'TEXT_SECONDARY-CLR mb-4 text-sm'}>
          Authorize the connection by clicking on the button below
        </p>

        <div className="text-left text-sm">
          <p className="my-1">
            This will allow <span className="PRIMARY_500-CLR">Dill</span> to:
          </p>
          <div className="flex items-center mb-6 mt-2">
            <Check color={COLORS.PRIMARY_500} size={20} />
            <p className="ml-2">See and edit invoice data in {platformName}</p>
          </div>
          <p>
            This does not mean that <span className="PRIMARY_500-CLR">Dill</span> can:
          </p>
          <div className="flex items-center mb-4 mt-2">
            <X color={COLORS.ERROR_500} size={20} />
            <p className="ml-2">Create or delete invoice data in {platformName}</p>
          </div>
        </div>

        <div className="flex justify-between w-full mt-4">
          <AppButton
            buttonStyles={{ width: '100px' }}
            text="Cancel"
            type="TERTIARY"
            onClick={handleClose}
          />
          <AppButton
            buttonStyles={{ width: '100px' }}
            text="Authorize"
            onClick={handleAuthorizeButtonClick}
          />
        </div>
      </div>
    </Modal>
  );
};
