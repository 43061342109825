import React, { useEffect, useState } from 'react';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import {
  pageNavigationPlugin,
  RenderCurrentPageLabelProps,
} from '@react-pdf-viewer/page-navigation';
import { twMerge } from 'tailwind-merge';
import { Viewer } from '@react-pdf-viewer/core';
import { AppInputField } from '../../../general/AppInputField/AppInputField';
import { useLocation, useNavigate } from 'react-router-dom';
import { Files, CaretCircleLeft, CaretCircleRight } from 'phosphor-react';
import { COLORS } from '../../../../utils/colors';
import { AppButton } from '../../../general/AppButton/AppButton';
import { AppSuccessModal } from '../../../general/AppSuccessModal/AppSuccessModal';
import successChecksCircle from '../../../../assets/svg/success_checks_circle.svg';
import { useAppDispatch } from '../../../../redux/store';
import { capitalizeWordsInSentence } from '@dill/dill-shared';
import { removePDFFields, openDillToast } from '../../../../utils/helpers';
import { createSupplierNoticeTemplate } from '../../../../redux/services/noticeTemplatesService';

const AddNoticeTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageLabel, jumpToNextPage, jumpToPreviousPage } = pageNavigationPluginInstance;

  const [selectedPdf, setSelectedPdf] = useState<string | null>('');
  const [selectedFlattenedPdf, setSelectedFlattenedPdf] = useState<string | null>('');
  const [isAddTemplateSuccessModalOpen, setIsAddTemplateSuccessModalOpen] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state?.pdf) {
      setTemplateName(state.name);
      setSelectedPdf(state.pdf);
    }
    return () => {};
  }, [state]);

  const handleFlattenPdf = async (url: string) => {
    const flattened = await removePDFFields(url);
    if (flattened) {
      setSelectedFlattenedPdf(flattened);
    } else {
      openDillToast({ type: 'ERROR', message: 'Error loading pdf' });
      setSelectedFlattenedPdf(null);
    }
  };
  useEffect(() => {
    if (selectedPdf) {
      handleFlattenPdf(selectedPdf);
    }
    return () => {};
  }, [selectedPdf]);

  const handleCreateTemplate = async () => {
    setLoading(true);
    if (selectedPdf && templateName && selectedFlattenedPdf) {
      const results = await dispatch(
        createSupplierNoticeTemplate({
          pdfUrl: selectedFlattenedPdf,
          name: templateName,
        })
      );
      if (results.type === 'noticeTemplates/createSupplierNoticeTemplate/fulfilled') {
        setIsAddTemplateSuccessModalOpen(true);
      }
    }
    if (!templateName) {
      setErrorFields([...errorFields, 'templateName']);
    } else {
      setErrorFields([...errorFields.filter((field) => field !== 'templateName')]);
    }
    setLoading(false);
  };

  return (
    <div className="flex h-full p-5 relative">
      <AppSuccessModal
        open={isAddTemplateSuccessModalOpen}
        modalImage={successChecksCircle}
        title={'New template added!'}
        subTitle="Your template has been sent to the Dill team for processing which will take 1-2 hours. After that, your template will be ready to use"
        buttonText="Back to All Templates"
        handleClose={() => {
          setIsAddTemplateSuccessModalOpen(false);
          navigate('/noticeTemplates/templates');
        }}
      />
      <div className="w-full h-full flex bg-white p-5 justify-between pb-40">
        <div className="w-4/12 px-2 h-full bg-white">
          <div className="my-3 mb-4">
            <AppInputField
              label="Name"
              isRequired={true}
              errorText={errorFields.includes('templateName') ? 'Required' : ''}
              value={templateName}
              placeholder="New York"
              onTextChange={(text) => {
                setTemplateName(text);
              }}
            />
          </div>

          <div className="">
            <label className="text-xs mb-0.5  whitespace-nowrap font-medium">File</label>
            <div className="flex items-center">
              <Files
                color={COLORS.GREY_900}
                weight="fill"
                size={50}
                className="p-2 GREY_100-BG rounded-full mr-4"
              />
              <div className="relative">
                <input
                  id="upload"
                  className="absolute w-full h-full top-0 bottom-0 opacity-0"
                  type="file"
                  accept="application/pdf"
                  onChange={(event) => {
                    if (!event?.target.files) {
                      return;
                    }
                    const file = event?.target?.files[0];

                    if (file) {
                      const reader = new FileReader();
                      reader.onloadend = () => {
                        const base64String: any = reader.result;
                        setSelectedPdf(base64String);
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  // onClick={(event) => {}}
                />
                <AppButton
                  type="SECONDARY"
                  text="Change"
                  buttonStyles={{
                    padding: '3px 12px',
                    borderRadius: '5px',
                    height: '26px',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="h-full GREY_200-BG overflow-y-scroll p-5 w-8/12">
          <div
            style={{
              width: '612px',
              height: '792px',
            }}
            className="relative ">
            {selectedFlattenedPdf && (
              <Viewer
                defaultScale={1}
                fileUrl={selectedFlattenedPdf}
                plugins={[zoomPluginInstance, pageNavigationPluginInstance]}
              />
            )}
            <canvas
              style={{
                width: '612px',
                height: '792px',
                left: '50%',
                transform: 'translateX( -50%)',
              }}
              className={twMerge('h-full w-full absolute top-0 left-0 opacity-100')}
            />
          </div>
        </div>
      </div>
      <div className="absolute w-full h-40 bg-white left-0  bottom-0 z-20 flex justify-end shadow-lg p-2">
        <div className="m-2">
          <CurrentPageLabel>
            {(props: RenderCurrentPageLabelProps) => {
              return (
                <div className="mx-4 flex items-center justify-center">
                  {props.numberOfPages > 1 && (
                    <button
                      className={`mr-2 flex items-center justify-center py-[2px] px-2 ${
                        props.numberOfPages > props.currentPage + 1
                          ? 'GREY_500-CLR cursor-default'
                          : 'hover:bg-blue-100  hover:text-[#3762FB]'
                      }`}
                      onClick={() => {
                        jumpToPreviousPage();
                      }}>
                      <CaretCircleLeft className="mr-1" />
                      Previous Page
                    </button>
                  )}
                  <span>{`${props.numberOfPages > 1 ? '' : 'page'} ${props.currentPage + 1} / ${
                    props.numberOfPages
                  }`}</span>
                  {props.numberOfPages > 1 && (
                    <button
                      className={`ml-2 flex items-center justify-center py-[2px] px-2 ${
                        props.numberOfPages - 1 < props.currentPage + 1
                          ? 'GREY_500-CLR cursor-default'
                          : 'hover:bg-blue-100 hover:text-[#3762FB]'
                      }`}
                      onClick={() => {
                        jumpToNextPage();
                      }}>
                      Next Page
                      <CaretCircleRight className="ml-1" />
                    </button>
                  )}
                </div>
              );
            }}
          </CurrentPageLabel>
        </div>
        <div className="flex relative h-fit">
          <AppButton
            text="Close"
            type="TERTIARY"
            buttonStyles={{
              marginTop: '4px',
              marginBottom: '4px',
              marginRight: '12px',
              height: '40px',
            }}
            onClick={() => {
              navigate('/buyerLienReleases/templates');
            }}
          />
          <AppButton
            text="Add Template"
            buttonStyles={{
              marginTop: '4px',
              marginBottom: '4px',
              width: '100px',
              height: '40px',
            }}
            isDisabled={!selectedFlattenedPdf || loading}
            onClick={() => {
              handleCreateTemplate();
            }}
            isLoading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default AddNoticeTemplate;
