import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  isSignInWithEmailLink,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signOut,
  signInWithEmailAndPassword,
  confirmPasswordReset,
} from 'firebase/auth';
import { myFirebaseAuth } from '../../firebase/config';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import {
  resetAuthRootState,
  updateLoginState,
  updateSendLoginEmailState,
} from '../globalSlices/authSlice';
import { DLog, openDillToast } from '../../utils/helpers';
import { resetBuyersRootState } from '../globalSlices/buyersSlice';
import { resetInvoicesRootState } from '../globalSlices/invoiceSlice';
import { resetJobsRootState } from '../globalSlices/jobsSlice';
import { resetLiensRootState } from '../globalSlices/liensSlice';
import { resetLienTemplatesRootState } from '../globalSlices/lienTemplatesSlice';
import { resetPaymentsRootState } from '../globalSlices/paymentsSlice';
import { resetSalesReceiptsRootState } from '../globalSlices/salesReceiptsSlice';
import { resetSuppliersRootState } from '../globalSlices/suppliersSlice';
import { resetBSubSuppliersRootState } from '../globalSlices/subSuppliersSlice';
import { AppUserUpdate } from '@dill/dill-shared/dist/src';

export const logoutUser = createAsyncThunk('auth/logoutUser', async (data, thunkApi) => {
  try {
    thunkApi.dispatch(resetAuthRootState());
    thunkApi.dispatch(resetBuyersRootState());
    thunkApi.dispatch(resetInvoicesRootState());
    thunkApi.dispatch(resetJobsRootState());
    thunkApi.dispatch(resetLiensRootState());
    thunkApi.dispatch(resetLienTemplatesRootState());
    thunkApi.dispatch(resetPaymentsRootState());
    thunkApi.dispatch(resetSalesReceiptsRootState());
    thunkApi.dispatch(resetSuppliersRootState());
    thunkApi.dispatch(resetBSubSuppliersRootState());

    console.log('user ---logged out');
    localStorage.removeItem('firebaseToken');
    // localStorage.removeItem('referPath');
    // sessionStorage.removeItem('emailFromLink');

    await signOut(myFirebaseAuth);
    // thunkApi.dispatch(updateLoginState(''));
    // thunkApi.dispatch(getStripePublishableKey());

    return true;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

export const getStripePublishableKey = createAsyncThunk(
  'auth/getStripePublishableKey',
  async (data, thunkApi): Promise<any> => {
    try {
      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/generics/stripeGenerics/getConfig`,
        {}
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getRutterPublicKey = createAsyncThunk(
  'auth/getRutterPublicKey',
  async (data, thunkApi): Promise<any> => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        if (!token) {
          return null;
        }
      }
      const response = await axios.get<AppServerResponse>(`${BASE_API_URL}/rutterAPI/config`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const processRutterPublicToken = createAsyncThunk(
  'auth/processRutterPublicToken',
  async ({ publicToken }: { publicToken: string }, thunkApi): Promise<any> => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        if (!token) {
          return null;
        }
      }
      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/rutterAPI/processPublicToken`,
        { publicToken },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const getUserDetails = createAsyncThunk('auth/getUserDetails', async (data, thunkApi) => {
  try {
    const token = await myFirebaseAuth.currentUser?.getIdToken();
    DLog(token);
    if (!token) {
      if (!token) {
        return null;
      }
    }
    const response = await axios.get<AppServerResponse>(BASE_API_URL + '/authAPI/user', {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log(error);
    if (error?.response?.status === 401) {
      thunkApi.dispatch(logoutUser());
    }
    return thunkApi.rejectWithValue(error.message);
  }
});

export const firebaseSendLoginEmailv1 = createAsyncThunk(
  'auth/firebaseLoginUser',
  async (data: { email: string }, thunkApi) => {
    try {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: `${process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3000'}/login`,
        // url: 'http://localhost:3000/login',

        // This must be true.
        handleCodeInApp: true,
      };
      await sendSignInLinkToEmail(myFirebaseAuth, data.email, actionCodeSettings);
      sessionStorage.removeItem('emailFromLink');
      localStorage.setItem('emailForSignIn', data.email);
      console.log('success');
      thunkApi.dispatch(updateSendLoginEmailState(true));
      thunkApi.dispatch(getUserDetails());
      return { success: true };
    } catch (error: any) {
      console.log(error);
      console.log(error.message);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const firebaseSendLoginEmail = createAsyncThunk(
  'auth/firebaseLoginUser',
  async (data: { email: string }, thunkApi) => {
    try {
      await axios.post<AppServerResponse>(BASE_API_URL + '/authAPI/sendEmailSignInLink', {
        email: data.email,
      });

      // sessionStorage.removeItem('emailFromLink');
      localStorage.setItem('emailForSignIn', data.email);
      console.log('success');
      thunkApi.dispatch(updateSendLoginEmailState(true));
      thunkApi.dispatch(getUserDetails());
      return { success: true };
    } catch (error: any) {
      console.log(error);
      console.log(error.message);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const firebaseSendPasswordResetEmail = createAsyncThunk(
  'auth/firebaseSendPasswordResetEmail',
  async (data: { email: string }, thunkApi) => {
    try {
      await axios.post<AppServerResponse>(BASE_API_URL + '/authAPI/sendPasswordResetLink', {
        email: data.email,
      });

      sessionStorage.removeItem('emailFromLink');
      localStorage.setItem('emailForPasswordReset', data.email);
      console.log('success');
      // thunkApi.dispatch(updateSendLoginEmailState(true));
      // thunkApi.dispatch(getUserDetails());
      return { success: true };
    } catch (error: any) {
      console.log(error);
      console.log(error.message);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const firebaseLoginEmailAndPassword = createAsyncThunk(
  'auth/firebaseLoginEmailAndPassword',
  async (data: { email: string; password: string }, thunkApi) => {
    try {
      await signInWithEmailAndPassword(myFirebaseAuth, data.email, data.password);

      thunkApi.dispatch(getUserDetails());
      return { success: true };
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const firebaseConfirmLoginUser = createAsyncThunk(
  'auth/firebaseConfirmLoginUser',
  async ({ url }: { url: string }, thunkApi) => {
    try {
      const emailForSignIn = localStorage.getItem('emailForSignIn');
      if (!emailForSignIn) {
        return null;
      }
      console.log(url);
      console.log(emailForSignIn);

      if (isSignInWithEmailLink(myFirebaseAuth, url)) {
        const response = await signInWithEmailLink(myFirebaseAuth, emailForSignIn, url);
        thunkApi.dispatch(updateLoginState(response.user.uid));
        console.log('"===="success');
        return { success: true, data: response.user.uid };
      }
      return null;
    } catch (error: any) {
      if (error?.message && error?.message === 'Firebase: Error (auth/invalid-action-code).') {
        openDillToast({
          type: 'ERROR',
          message:
            'Failed to verify your email. Make sure you click the latest verification email. This happens when you open multiple tabs and many verification emails are sent. Check your inbox and sign in from the latest email',
        });
      } else if (
        error?.message &&
        error?.message ===
          'Firebase: The email provided does not match the sign-in email address. (auth/invalid-email).'
      ) {
        openDillToast({
          type: 'ERROR',
          message:
            'Failed to verify your email. This link is not for this email address. You can resend the verification link for this email by entering your email below or open the verification link with the previous browser where the email for this link was set',
        });
      }
      console.log(error);
      console.log(error?.message);
      return thunkApi.rejectWithValue('error');
    }
  }
);
export const firebaseConfirmPasswordReset = createAsyncThunk(
  'auth/firebaseConfirmPasswordResetUser',
  async ({ oobCode, newPassword }: { oobCode: string; newPassword: string }, thunkApi) => {
    try {
      await confirmPasswordReset(myFirebaseAuth, oobCode, newPassword);
      localStorage.removeItem('emailForPasswordReset');

      console.log('"===="success');
      return { success: true, data: {} };
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue('error');
    }
  }
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (data: { email: string }, thunkApi) => {
    const { email } = data;
    try {
      const actionCodeSettings = {
        // url: 'https://' + myFirebase.options.authDomain,
        // url: 'http://localhost:3000/login',
        url: `${process.env.REACT_APP_FRONTEND_URL || 'http://localhost:3000'}/login`,
        // handleCodeInApp: true,
      };
      const res = await sendPasswordResetEmail(myFirebaseAuth, email, actionCodeSettings);
      console.log({ res, email });
      return true;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const checkUserExists = createAsyncThunk(
  'auth/checkUserExists',
  async (
    { email, thenLogIn = false }: { email: string; thenLogIn?: boolean },
    thunkApi
  ): Promise<any> => {
    try {
      const response = await axios.post<AppServerResponse>(BASE_API_URL + '/authAPI/userCheck', {
        email,
      });
      // response.data.;

      if (
        response.data &&
        response.data?.success &&
        response?.data.data?.roles &&
        response?.data.data?.roles.length === 1 &&
        response?.data.data?.roles[0] === 'DRIVER'
      ) {
      } else if (response.data && response.data?.success && thenLogIn) {
        thunkApi.dispatch(firebaseSendLoginEmail({ email }));
      }
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateLoggedInUserPassword = createAsyncThunk(
  'auth/updateLoggedInUserPassword',
  async ({ email, password }: { email: string; password: string }, thunkApi): Promise<any> => {
    try {
      if (!myFirebaseAuth.currentUser) {
        return null;
      }
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<AppServerResponse>(
        BASE_API_URL + '/authAPI/userPassword',
        {
          password,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      localStorage.removeItem('emailForSignIn');
      // const response = await updatePassword(myFirebaseAuth.currentUser, password);
      await thunkApi.dispatch(firebaseLoginEmailAndPassword({ email, password }));
      await thunkApi.dispatch(checkUserExists({ email }));
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const getPlaidLinkToken = createAsyncThunk(
  'auth/getPlaidLinkToken',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/paymentsAPI/plaid/linkToken`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      // response.data.;

      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getUserByBuyers = createAsyncThunk(
  'auth/getUserBuyers',
  async (data: { buyerId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/authAPI/userByProperty`,
        {
          params: { ...data },
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const referBuyerUsers = createAsyncThunk(
  'auth/referBuyerUsers',
  async (
    data: {
      supplierDomainId: string;
      referrerEmail: string;
      idReferrerAcceptSetup: boolean;
      referredUsers: { firstName: string; lastName: string; email: string; title?: string }[];
    },
    thunkApi
  ): Promise<any> => {
    try {
      const response = await axios.post<AppServerResponse>(
        BASE_API_URL + '/authAPI/buyerUserReferral',
        {
          ...data,
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const referSubSupplierUsers = createAsyncThunk(
  'auth/referSubSupplierUsers',
  async (
    data: {
      buyerId: string;
      referrerEmail: string;
      idReferrerAcceptSetup: boolean;
      referredUsers: { firstName: string; lastName: string; email: string; title?: string }[];
    },
    thunkApi
  ): Promise<any> => {
    try {
      const response = await axios.post<AppServerResponse>(
        BASE_API_URL + '/authAPI/subSupplierUserReferral',
        {
          ...data,
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateSubUser = createAsyncThunk(
  'auth/updateSubUser',
  async (
    {
      buyerId,
      email,
      supplierDomainId,
      roles,
    }: {
      buyerId?: string;
      supplierDomainId?: string;
      email: string;
      roles?: string[];
    },
    thunkApi
  ): Promise<any> => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<AppServerResponse>(
        BASE_API_URL + '/authAPI/updateSubUser',
        { buyerId, email, supplierDomainId, roles },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const checkUserIsSetupWithCompany = createAsyncThunk(
  'auth/checkUserIsSetupWithCompany',
  async (
    {
      companyId,
      email,
      supplierDomainId,
    }: {
      companyId?: string;
      email: string;
      supplierDomainId?: string;
    },
    thunkApi
  ): Promise<any> => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.post<AppServerResponse>(
        BASE_API_URL + '/authAPI/checkUserIsSetupWithCompany',
        { email, companyId, supplierDomainId },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  'auth/updateUserDetails',
  async (
    {
      userDetails,
      userId,
    }: {
      userDetails: AppUserUpdate;
      userId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<any>(
        `${BASE_API_URL}/authAPI/user/${userId}`,
        { ...userDetails },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const createUserSignature = createAsyncThunk(
  'auth/createUserSignatures',
  async (
    {
      signature,
      date,
    }: {
      signature: string;
      date: Date;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/authAPI/userSignatures`,
        { signature, date },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getUserSignatures = createAsyncThunk(
  'auth/getUserSignatures',
  async (data, thunkApi): Promise<any> => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/authAPI/userSignatures`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data) {
        return thunkApi.rejectWithValue(error.response.data);
      }
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const deleteUserSignature = createAsyncThunk(
  'auth/deleteUserSignatures',
  async (
    {
      userSignatureId,
    }: {
      userSignatureId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.delete<AppServerResponse>(
        BASE_API_URL + `/authAPI/userSignatures/${userSignatureId}`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const newUserNotifyDill = createAsyncThunk(
  'auth/newUserNotifyDill',
  async ({ email }: { email: string }, thunkApi) => {
    try {
      const response = await axios.post<any>(
        `${BASE_API_URL}/generics/notificationsGenerics/newUserNotifyDill`,
        { email },
        {}
      );
      return { success: true, data: response.data };
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
