import { onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { myFirebaseAuth } from '../../firebase/config';
import { updateLoginState } from '../../redux/globalSlices/authSlice';
import { getUserDetails } from '../../redux/services/authService';
import { useAppDispatch } from '../../redux/store';

export const useAuth = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(myFirebaseAuth, (user) => {
      if (user) {
        dispatch(updateLoginState(user.uid));
        dispatch(getUserDetails());
      } else {
        dispatch(updateLoginState(''));
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);
};
