import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useNavigation } from 'react-router-dom';
import { myFirebaseAuth } from './firebase/config';
import { getUserDetails, logoutUser } from './redux/services/authService';
import { useAppDispatch, useAppSelector } from './redux/store';
import LogRocket from 'logrocket';
import { handleSavePastPath } from './utils/helpers';
import { useParamMainBuyerId } from './utils/hooks/useParamMainBuyerId';
import { getReleaseBySupplier } from './redux/services/liensService';

const LEGACY_BASE_PATHS = ['/buyerLienReleases', '/companies'];

const LEGACY_SUB_PATHS = [
  'overview',
  'lienReleasesSupplier',
  'lienReleasesJobs',
  'templates',
  'templateFields',
  'invoices',
  'customers',
  'payments',
  'invoices',
];

export const ProtectedRoute = ({ children }: { children: any }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user, firebaseUserId, isPasswordSet } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const navigation = useNavigation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedMainBuyerId] = useParamMainBuyerId();

  useEffect(() => {
    const currentPath = location.pathname + location.search;

    if (
      location.pathname &&
      !location.pathname.startsWith('/login') &&
      !location.pathname.startsWith('/refer?')
    ) {
      handleSavePastPath(currentPath);
    }

    return () => {};
  }, [location.pathname]);

  useEffect(() => {
    if (myFirebaseAuth.currentUser && firebaseUserId && isPasswordSet) {
      setIsLoggedIn(true);
      console.log('user logged in');
    } else {
      setIsLoggedIn(false);
      console.log('user not logged in');
      const pastPath = location.pathname;
      let loginPagePath = '/login';
      if (pastPath && pastPath.startsWith('/companies/')) {
        const pathSplit = pastPath.split('/');
        if (pathSplit.length > 3) {
          loginPagePath = `/login/${pathSplit[4]}`;
        } else {
          loginPagePath = '/login';
        }
      } else {
        loginPagePath = '/login';
      }
      navigate(loginPagePath);
    }
    if (!user && myFirebaseAuth.currentUser && firebaseUserId && isPasswordSet) {
      dispatch(getUserDetails());
    }
  }, [myFirebaseAuth.currentUser]);

  useEffect(() => {
    (async () => {
      if (myFirebaseAuth.currentUser && isLoggedIn && isPasswordSet) {
        // Navigate to a specific path depending on the user roles
        if (user && firebaseUserId) {
          LogRocket.identify(user.id, {
            email: user.email,
            name: `${user?.firstName ?? ''} ${user?.lastName ?? ''}`,
          });
          const mainRoles = user.roles.filter((role) => role !== 'DILL_ADMIN');
          const mainPath = location.pathname;
          // Handle email links with old routes
          if (mainPath.startsWith('/companies/invoices') && user.userMainBuyers) {
            const mainPathSplit = mainPath.split('/');
            if (mainPathSplit.length === 5) {
              const supplierDomainId = mainPathSplit[4];
              const buyerName = decodeURIComponent(mainPathSplit[3].split('__')[0])
                .replaceAll(' ', '_')
                .replaceAll('+', '_')
                .replaceAll('_', ' ');

              const mainBuyer = user.userMainBuyers?.find((mb) => mb.name === buyerName);
              let newNavPath = '';
              if (mainBuyer) {
                newNavPath = `/companies/${mainBuyer.id}/invoices/${supplierDomainId}`;
              } else {
                newNavPath = `/companies/${user?.userMainBuyers[0]?.id}/invoices`;
              }
              navigate(newNavPath);
            }
            if (mainPathSplit.length === 4) {
              const buyerName = decodeURIComponent(mainPathSplit[3].split('__')[0])
                .replaceAll(' ', '_')
                .replaceAll('+', '_')
                .replaceAll('_', ' ');

              const mainBuyer = user.userMainBuyers?.find((mb) => mb.name === buyerName);
              let newNavPath = '';
              if (mainBuyer) {
                newNavPath = `/companies/${mainBuyer.id}/invoices`;
              } else {
                newNavPath = `/companies/${user.userMainBuyers[0].id}/invoices`;
              }
              navigate(newNavPath);
            }
          }
          if (mainPath.startsWith('/buyerLienReleases/overview') && user.userMainBuyers) {
            const searchParams = new URLSearchParams(location.search);
            const email = searchParams.get('email');
            let releaseId = searchParams.get('releaseId');
            let extraPath = '';
            if (!releaseId && mainPath.startsWith('/buyerLienReleases/overview/editRelease')) {
              const mainPathSplit = mainPath.split('/');
              if (mainPathSplit.length === 5) {
                releaseId = mainPathSplit[4];
                // releaseId = '';
                extraPath = `/editRelease/${releaseId}`;
              }
            }
            if (email && releaseId) {
              const result = await dispatch(getReleaseBySupplier({ releaseId }));
              console.log({ result });
              if (result.type === 'releases/getReleaseBySupplier/fulfilled') {
                const payload = result.payload as any;
                const mainBuyerId = payload.data.buyer?.id;
                const mainBuyer = user.userMainBuyers?.find((mb) => mb.id === mainBuyerId);
                let newNavPath = '';
                if (mainBuyer) {
                  // navigate(`/companies/${mainBuyer.id}/invoices/${supplierDomainId}`);
                  newNavPath = `/buyerLienReleases/${mainBuyer.id}/overview${extraPath}${location.search}`;
                } else {
                  // navigate(`/companies/${user.userMainBuyers[0].id}`);
                  newNavPath = `/buyerLienReleases/${user.userMainBuyers[0].id}/overview`;
                }
                navigate(newNavPath, { replace: false });
                navigate(0);
                return;
              }
            }
          }
          // Handle legacy routes such as /buyerLienReleases/overview, /companies/invoices, etc
          if (
            LEGACY_BASE_PATHS.some((route) => mainPath.startsWith(route)) &&
            selectedMainBuyerId &&
            user.userMainBuyers &&
            LEGACY_SUB_PATHS.includes(selectedMainBuyerId)
          ) {
            const urlParts = location.pathname.split('/');
            const firstMainBuyer = user.userMainBuyers[0];

            if (urlParts.length > 1 && firstMainBuyer) {
              navigate(`/${urlParts[1]}/${firstMainBuyer.id}/${selectedMainBuyerId}`);
            }
          }
          if (mainRoles.length > 0) {
            if (
              !mainRoles.includes('SUPPLIER') &&
              ['/customers', '/invoices', '/salesReceipts', '/payments'].some((path) =>
                mainPath.startsWith(path)
              )
            ) {
              if (mainRoles.includes('BUYER') && selectedMainBuyerId) {
                navigate(`/companies/${selectedMainBuyerId}/invoices`);
                localStorage.setItem('selectAccountType', 'Buyer');
              } else if (
                mainRoles.includes('BUYER') &&
                user?.userMainBuyers &&
                user.userMainBuyers?.length > 0
              ) {
                navigate(`/companies/${user.userMainBuyers[0].id}/invoices`);
                localStorage.setItem('selectAccountType', 'Buyer');
              } else if (mainRoles.includes('MAIN_BUYER') && selectedMainBuyerId) {
                navigate(`/buyerLienReleases/${selectedMainBuyerId}/overview`);
                localStorage.setItem('selectAccountType', 'Buyer');
              } else if (
                mainRoles.includes('MAIN_BUYER') &&
                user?.userMainBuyers &&
                user.userMainBuyers?.length > 0
              ) {
                navigate(`/buyerLienReleases/${user.userMainBuyers[0].id}/overview`);
                localStorage.setItem('selectAccountType', 'Buyer');
              } else if (mainRoles.includes('SUB_SUPPLIER')) {
                navigate('/lienReleases');
                localStorage.setItem('selectAccountType', 'Supplier');
              }
            }

            if (
              !mainRoles.includes('SUB_SUPPLIER') &&
              ['/lienReleases'].some((path) => mainPath.startsWith(path))
            ) {
              if (mainRoles.includes('SUPPLIER')) {
                navigate('/customers');
                localStorage.setItem('selectAccountType', 'Supplier');
              } else if (mainRoles.includes('BUYER') && selectedMainBuyerId) {
                navigate(`/companies/${selectedMainBuyerId}/invoices`);
                localStorage.setItem('selectAccountType', 'Buyer');
              } else if (
                mainRoles.includes('BUYER') &&
                user?.userMainBuyers &&
                user.userMainBuyers?.length > 0
              ) {
                navigate(`/companies/${user.userMainBuyers[0].id}/invoices`);
                localStorage.setItem('selectAccountType', 'Buyer');
              } else if (mainRoles.includes('MAIN_BUYER') && selectedMainBuyerId) {
                navigate(`/buyerLienReleases/${selectedMainBuyerId}/overview`);
                localStorage.setItem('selectAccountType', 'Buyer');
              } else if (
                mainRoles.includes('MAIN_BUYER') &&
                user?.userMainBuyers &&
                user.userMainBuyers?.length > 0
              ) {
                navigate(`/buyerLienReleases/${user.userMainBuyers[0].id}/overview`);
                localStorage.setItem('selectAccountType', 'Buyer');
              }
            }

            if (
              !mainRoles.includes('BUYER') &&
              ['/companies'].some((path) => mainPath.startsWith(path))
            ) {
              if (mainRoles.includes('MAIN_BUYER') && selectedMainBuyerId) {
                navigate(`/buyerLienReleases/${selectedMainBuyerId}/overview`);
                localStorage.setItem('selectAccountType', 'Buyer');
              } else if (
                mainRoles.includes('MAIN_BUYER') &&
                user?.userMainBuyers &&
                user.userMainBuyers?.length > 0
              ) {
                navigate(`/buyerLienReleases/${user.userMainBuyers[0].id}/overview`);
                localStorage.setItem('selectAccountType', 'Buyer');
              } else if (mainRoles.includes('SUB_SUPPLIER')) {
                navigate('/lienReleases');
                localStorage.setItem('selectAccountType', 'Supplier');
              } else if (mainRoles.includes('SUPPLIER')) {
                navigate('/customers');
                localStorage.setItem('selectAccountType', 'Supplier');
              }
            }
            if (
              !mainRoles.includes('MAIN_BUYER') &&
              ['/buyerLienReleases', '/adminTemplates'].some((path) => mainPath.startsWith(path))
            ) {
              if (mainRoles.includes('BUYER') && selectedMainBuyerId) {
                navigate(`/companies/${selectedMainBuyerId}/invoices`);
                localStorage.setItem('selectAccountType', 'Buyer');
              } else if (
                mainRoles.includes('BUYER') &&
                user?.userMainBuyers &&
                user.userMainBuyers?.length > 0
              ) {
                navigate(`/companies/${user.userMainBuyers[0].id}/invoices`);
                localStorage.setItem('selectAccountType', 'Buyer');
              } else if (mainRoles.includes('SUB_SUPPLIER')) {
                navigate('/lienReleases');
                localStorage.setItem('selectAccountType', 'Supplier');
              } else if (mainRoles.includes('SUPPLIER')) {
                navigate('/customers');
                localStorage.setItem('selectAccountType', 'Supplier');
              }
            }
          }
        }
      }
    })();
  }, [user?.id, isLoggedIn, selectedMainBuyerId]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailFromLink = searchParams.get('email');
    const mainPath = location.pathname;
    if (location.pathname) {
      if (emailFromLink) {
        sessionStorage.setItem('emailFromLink', emailFromLink);
      }
      if (
        [
          '/customers',
          '/invoices',
          '/supplierJobs',
          '/salesReceipts',
          '/payments',
          '/lienReleases',
        ].some((path) => mainPath.startsWith(path))
      ) {
        localStorage.setItem('selectAccountType', 'Supplier');
      }
      if (['/buyerLienReleases', '/companies'].some((path) => mainPath.startsWith(path))) {
        localStorage.setItem('selectAccountType', 'Buyer');
      }
    }

    if (
      emailFromLink &&
      myFirebaseAuth.currentUser &&
      myFirebaseAuth.currentUser.email !== emailFromLink
    ) {
      sessionStorage.setItem('emailFromLink', emailFromLink);
      // localStorage.removeItem('pastPathList');
      // localStorage.removeItem('referPath');
      dispatch(logoutUser());
    }
    return () => {};
  }, [location.pathname, firebaseUserId]);

  return <React.Fragment>{isLoggedIn ? children : null}</React.Fragment>;
};
