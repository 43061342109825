import { Modal } from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';
import { AppButton } from '../AppButton/AppButton';
import './AppScheduleModal.scss';
import { X } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import AppDatePicker from '../AppDatePicker/AppDatePicker';
import moment from 'moment';

const AppScheduleModal = ({
  open,
  handleClose,
  onSelectDate,
}: {
  open: boolean;
  handleClose: () => void;
  onSelectDate: (date: Date) => void;
}) => {
  const currentDate = moment();
  const updatedDate = currentDate.add(1, 'day').format('MM DD YYYY');
  const [selectedDate, setSelectedDate] = useState(new Date(updatedDate));
  const handleSchedule = () => {
    onSelectDate(selectedDate);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="schedule-modal-content pt-3">
        <div className="schedule-modal-title-container">
          <div className="flex">
            <h3 className="schedule-modal-title-header">Schedule Request</h3>
          </div>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={handleClose}
          />
        </div>
        <div className="content">
          <div className="timezone-text">Timezone pst</div>
          <div className="details">
            <div className="date-text">Date</div>
            <div className="select-day-container">
              <div className="date-picker-container">
                <AppDatePicker
                  selectedDate={selectedDate}
                  minumumDate={new Date(updatedDate)}
                  onDateSelected={(date) => {
                    if (date) {
                      setSelectedDate(date);
                    }
                  }}
                />
              </div>
              <div className="instruction-text">
                *All release requests will be delivered at <span>9am</span> on selected day
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row self-end px-6 py-2">
          <AppButton
            text="Close"
            buttonStyles={{
              marginTop: '4px',
              marginBottom: '4px',
            }}
            onClick={handleClose}
            type="TERTIARY"
          />
          <AppButton
            buttonStyles={{ marginLeft: '5px', marginTop: '4px', marginBottom: '4px' }}
            text="Schedule"
            onClick={handleSchedule}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AppScheduleModal;
