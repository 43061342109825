import React, { useEffect, useMemo, useState } from 'react';
import { AppInputField } from '../../../../general/AppInputField/AppInputField';
import { Plus, Search } from 'react-feather';
import BuyerLienSuppliersTable from '../BuyerLienSuppliersTable/BuyerLienSuppliersTable';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { COLORS } from '../../../../../utils/colors';
import BuyerAddSupplierModal from '../BuyerAddSupplierModal/BuyerAddSupplierModal';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import {
  getSubSuppliers,
  unarchiveSubSupplier,
} from '../../../../../redux/services/subSuppliersService';
import { UserLinkApiResponse } from '@dill/dill-shared';
import { AppErrorPage } from '../../../../general/AppErrorPage/AppErrorPage';
import { Lock } from 'phosphor-react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { openMessageModal } from '../../../../../redux/globalSlices/genericSlice';
import { useParamMainBuyerId } from '../../../../../utils/hooks/useParamMainBuyerId';

const BuyerLienSuppliers = () => {
  const dispatch = useAppDispatch();
  const [isAddSupplierModalOpen, setIsAddSupplierModalOpen] = useState(false);
  const [isUnarchiveSupplierModalOpen, setIsUnarchiveSupplierModalOpen] = useState(false);
  const { subSuppliers, loadingErrorList } = useAppSelector((state) => state.subSuppliers);
  const { user } = useAppSelector((state) => state.auth);
  const [searchText, setSearchText] = useState('');
  const [selectedMainBuyerId] = useParamMainBuyerId();
  const error = useMemo(() => {
    if (loadingErrorList.length > 0) {
      const errorMessage: any = loadingErrorList.filter((obj) =>
        obj.hasOwnProperty('getSubSuppliers')
      );
      if (errorMessage.length > 0) {
        return errorMessage[0]?.getSubSuppliers;
      }
    }
    return null;
  }, [loadingErrorList]);
  const subSupplierUsers = useMemo(() => {
    if (subSuppliers) {
      return subSuppliers
        .filter((subSupplier) => !subSupplier.archivedState?.includes('BUYER_ARCHIVED'))
        .reduce(
          (
            prev: {
              subSupplierName: string;
              subSupplierId: string;
              notes: string;
              user: UserLinkApiResponse;
            }[],
            curr
          ) => {
            return [
              ...prev,
              ...curr.users.map((userLink) => {
                return {
                  subSupplierId: curr.id,
                  subSupplierName: curr.name,
                  notes: curr.notes ?? '',
                  user: userLink,
                };
              }),
            ];
          },
          []
        );
    }
    return [];
  }, [subSuppliers]);

  const searchedSubSupplierUsers: {
    subSupplierName: string;
    subSupplierId: string;
    notes: string;
    user: UserLinkApiResponse;
  }[] = useMemo(() => {
    let filteredSubSupplierUsers: {
      subSupplierName: string;
      subSupplierId: string;
      notes: string;
      user: UserLinkApiResponse;
    }[] = [];
    if (subSupplierUsers && searchText) {
      const searched = subSupplierUsers.filter((subSupplierUser) => {
        const userDetails = subSupplierUser.user.subUser || subSupplierUser.user.user;
        if (
          (subSupplierUser.subSupplierName &&
            subSupplierUser.subSupplierName
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())) ||
          (userDetails?.title &&
            userDetails?.title.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (userDetails &&
            userDetails.email.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (userDetails?.firstName &&
            userDetails?.firstName.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (userDetails?.lastName &&
            userDetails?.lastName.toString().toLowerCase().includes(searchText.toLowerCase()))
        ) {
          return true;
        }
        return false;
      });
      filteredSubSupplierUsers = searched;
    } else {
      filteredSubSupplierUsers = subSupplierUsers;
    }

    return filteredSubSupplierUsers;
  }, [subSupplierUsers, searchText]);

  const handleUnarchiveSubSupplier = async (supplierId: string) => {
    const result = await dispatch(
      unarchiveSubSupplier({
        subSupplierId: supplierId,
        subSupplierDetails: {},
        mainBuyerId: selectedMainBuyerId || '',
      })
    );
    if (result.type === 'subSuppliers/unarchiveSubSupplier/fulfilled') {
      dispatch(
        openMessageModal({
          title: 'Successfully restored supplier!',
          subTitle: `Your supplier has been un-archived, and any linked releases have been restored`,
          buttonText: 'Add New Supplier',
          modalType: 'SUCCESS',
          onClose: () => {
            dispatch(getSubSuppliers({ mainBuyerId: selectedMainBuyerId || '' }));
          },
        })
      );
    } else {
      dispatch(
        openMessageModal({
          title: 'Restoring supplier failed!',
          subTitle: `Contact customer support.`,
          buttonText: 'Close',
          modalType: 'ERROR',
          isSecondButton: false,
          onClose: () => {},
        })
      );
    }
  };

  const handleUnArchiveSubsupplierModal = (supplierId: string, supplierName: string) => {
    dispatch(
      openMessageModal({
        title: `Would you like to restore ${supplierName}?`,
        subTitle: `This action will restore the supplier and also any linked releases for this supplier`,
        buttonText: 'Restore',
        modalType: 'WARN',
        isSecondButton: true,
        closeOnClickOutsideModal: true,
        secondButtonText: 'No, cancel',
        onSecondButtonClick: () => {
          setIsUnarchiveSupplierModalOpen(false);
        },
        onClose: () => {
          setIsUnarchiveSupplierModalOpen(false);
          handleUnarchiveSubSupplier(supplierId);
        },
      })
    );
  };
  return (
    <div className="h-full p-8 flex ">
      <BuyerAddSupplierModal
        open={isAddSupplierModalOpen}
        handleClose={() => {
          if (selectedMainBuyerId) {
            dispatch(getSubSuppliers({ mainBuyerId: selectedMainBuyerId }));
          }
          setIsAddSupplierModalOpen(false);
        }}
        onUnArchiveSubsupplier={handleUnArchiveSubsupplierModal}
      />
      <div className="flex flex-col px-5 py-4 w-full bg-white">
        <div className="flex justify-between">
          <AppInputField
            inputFieldStyles={{ width: '300px' }}
            id="search"
            value={searchText}
            placeholder={'Search '}
            icon={<Search size={15} />}
            onTextChange={(text) => {
              setSearchText(text);
            }}
          />
          <div className="flex items-center">
            <div className="mr-3">
              {!user?.userMainBuyers?.find((mb) => mb.id === selectedMainBuyerId)?.isPaid && (
                <Lock
                  data-tooltip-id={'locked_supplier_feature'}
                  color={COLORS.ERROR_500}
                  className="ERROR_200-BG p-2 rounded-full w-10 h-10 z-40"
                />
              )}
              {!user?.userMainBuyers?.find((mb) => mb.id === selectedMainBuyerId)?.isPaid && (
                <ReactTooltip
                  id={'locked_supplier_feature'}
                  place="top"
                  variant="dark"
                  opacity={'100%'}
                  style={{
                    display: 'flex',
                    background: '#334155',
                    width: '300px',
                    zIndex: '70',
                    borderRadius: '8px',
                    alignItems: 'center',
                    justifyItems: 'center',
                    fontWeight: '600',
                  }}>
                  <span>
                    These features are locked. If you are interested in requesting releases from
                    other suppliers, please contact customer support
                  </span>
                </ReactTooltip>
              )}
            </div>
            <div
              data-tooltip-id={
                !user?.userMainBuyers?.find((mb) => mb.id === selectedMainBuyerId)?.isPaid
                  ? 'locked_supplier_feature'
                  : undefined
              }>
              <AppButton
                text="Add New Supplier"
                icon={
                  <Plus
                    color={
                      !user?.userMainBuyers?.find((mb) => mb.id === selectedMainBuyerId)?.isPaid
                        ? COLORS.PRIMARY_300
                        : COLORS.WHITE
                    }
                  />
                }
                onClick={() => {
                  setIsAddSupplierModalOpen(true);
                }}
                isDisabled={
                  !user?.userMainBuyers?.find((mb) => mb.id === selectedMainBuyerId)?.isPaid
                }
              />
            </div>
          </div>
        </div>
        {error ? (
          <AppErrorPage
            title="Error loading  suppliers."
            contactMessage="Please contact customer support."
            errorMessage={error}
          />
        ) : (
          <BuyerLienSuppliersTable subSupplierUsers={searchedSubSupplierUsers} />
        )}
      </div>
    </div>
  );
};

export default BuyerLienSuppliers;
