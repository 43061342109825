export const BASE_API_URL = process.env.REACT_APP_API_URL;
export const REACT_APP_FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const REACT_APP_RUTTER_PUBLIC_KEY = process.env.REACT_APP_RUTTER_PUBLIC_KEY;
export const weeksOptions = [
  { label: 'From 2 weeks ago', value: '2' },
  { label: 'From 4 weeks ago', value: '4' },
  { label: 'From 8 weeks ago', value: '8' },
  { label: 'From 24 weeks ago', value: '24' },
];

export const releaseStatusOptions = [
  'Requested',
  'Declined',
  'Rejected',
  'Pending',
  'Signed',
  'Scheduled',
  'Sent to GC',
  'All',
];
