import React, { useState, useEffect, useMemo } from 'react';
import { Plus, Search } from 'react-feather';
import { COLORS } from '../../../../../utils/colors';
import { AppButton } from '../../../../general/AppButton/AppButton';
import { AppInputField } from '../../../../general/AppInputField/AppInputField';
import BuyerLienSuppliersTable from '../BuyerLienSuppliersTable/BuyerLienSuppliersTable';
import BuyerLienJobsTable from '../BuyerLienJobsTable/BuyerLienJobsTable';
import { AppNewJobModal } from '../../../../general/AppNewJobModal/AppNewJobModal';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { getJobs } from '../../../../../redux/services/jobsService';
import { getSubSuppliers } from '../../../../../redux/services/subSuppliersService';
import { Job } from '@dill/dill-shared';
import { AppErrorPage } from '../../../../general/AppErrorPage/AppErrorPage';
import { getLienTemplates } from '../../../../../redux/services/leinTemplatesService';
import { getUserByBuyers } from '../../../../../redux/services/authService';
import { useParams } from 'react-router-dom';
import { useParamMainBuyerId } from '../../../../../utils/hooks/useParamMainBuyerId';

const BuyerLienJobs = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const [addNewJob, setAddNewJob] = useState(false);
  const { jobs, loadingErrorList } = useAppSelector((state) => state.jobs);
  const { user } = useAppSelector((state) => state.auth);
  const [searchText, setSearchText] = useState('');
  const [toogle, setToogle] = useState(true);
  const { subSuppliers } = useAppSelector((state) => state.subSuppliers);
  const [selectedMainBuyerId] = useParamMainBuyerId();

  const error = useMemo(() => {
    if (loadingErrorList.length > 0) {
      const errorMessage: any = loadingErrorList.filter((obj) => obj.hasOwnProperty('getJobs'));
      if (errorMessage.length > 0) {
        return errorMessage[0]?.getJobs;
      }
    }
    return null;
  }, [loadingErrorList]);
  const searchedJobs: Job[] = useMemo(() => {
    let filteredJobs: Job[] = [];
    const activeJobs = jobs.filter((job) => {
      if (toogle && job?.archivedState && job.archivedState.includes('BUYER_ARCHIVED')) {
        return false;
      }
      return true;
    });
    if (jobs && searchText) {
      const searched = activeJobs.filter((job) => {
        if (
          (job.name && job.name.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (job.number && job.number.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (job.customerNumber &&
            job.customerNumber.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (job.address &&
            job.address.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (job.owner && job.owner.toString().toLowerCase().includes(searchText.toLowerCase())) ||
          (job.accountantEmails &&
            job.accountantEmails
              .join(', ')
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase()))
        ) {
          return true;
        }
        return false;
      });
      filteredJobs = searched;
    } else {
      filteredJobs = activeJobs;
    }

    return filteredJobs;
  }, [jobs, searchText, toogle]);

  useEffect(() => {
    if (selectedMainBuyerId) {
      dispatch(getSubSuppliers({ mainBuyerId: selectedMainBuyerId }));
      dispatch(getJobs({ mainBuyerId: selectedMainBuyerId }));
      dispatch(getLienTemplates({ mainBuyerId: selectedMainBuyerId }));
      dispatch(getUserByBuyers({ buyerId: selectedMainBuyerId }));
    }
  }, [selectedMainBuyerId]);

  return (
    <div className="h-full p-8 flex ">
      <AppNewJobModal
        open={addNewJob}
        handleClose={() => setAddNewJob(false)}
        subSuppliers={subSuppliers}
      />
      <div className="flex flex-col px-5 py-4 w-full bg-white">
        <div className="flex justify-between">
          <div className="flex items-center">
            <AppInputField
              inputFieldStyles={{ width: '300px' }}
              id="search"
              value={searchText}
              placeholder={'Search '}
              icon={<Search size={15} />}
              onTextChange={(text) => {
                setSearchText(text);
              }}
            />
            <div className="flex ml-2">
              <div
                onClick={() => setToogle(!toogle)}
                className={`w-[40px] h-[22px] rounded-full p-[1px] grid flex-col cursor-pointer ${
                  toogle ? 'justify-items-end bg-[#3862fb]' : 'GREY_500-BG'
                }`}>
                <div className="bg-white w-[20px] h-[20px] rounded-full" />
              </div>
              <div className="w-[180px] ml-2 font-semibold">Show Only Active Jobs</div>
            </div>
          </div>
          <AppButton
            text="Add New Job"
            icon={<Plus color={COLORS.WHITE} />}
            onClick={() => setAddNewJob(true)}
          />
        </div>
        {error ? (
          <AppErrorPage
            title="Error loading  jobs."
            contactMessage="Please contact customer support."
            errorMessage={error}
          />
        ) : (
          <BuyerLienJobsTable jobs={searchedJobs} subSuppliers={subSuppliers} />
        )}
      </div>
    </div>
  );
};

export default BuyerLienJobs;
