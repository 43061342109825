import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';
import { BuyerUpdate } from '@dill/dill-shared';

export const updateSupplierBuyer = createAsyncThunk(
  'supplierBuyers/updateSupplierBuyer',
  async (
    {
      supplierBuyerDetails,
      supplierBuyerId,
    }: {
      supplierBuyerDetails: BuyerUpdate;
      supplierBuyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<any>(
        `${BASE_API_URL}/suppliers/supplierBuyers/updateBuyer/${supplierBuyerId}`,
        { ...supplierBuyerDetails },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getSupplierBuyers = createAsyncThunk(
  'supplierBuyers/getSupplierBuyers',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<AppServerResponse>(
        BASE_API_URL + '/suppliers/supplierBuyers/getBuyerByCompany',
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      } else if (error?.response?.data?.errors && error?.response?.data?.errors.length > 0) {
        return thunkApi.rejectWithValue(error?.response?.data?.errors[0]?.msg);
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
