import { Modal } from '@mui/material';
import { CaretLeft } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { AppButton } from '../../AppButton/AppButton';
import { AppInputField } from '../../AppInputField/AppInputField';
import { AppDropDown } from '../../AppDropDown/AppDropDown';
import { useAppDispatch } from '../../../../redux/store';
import { createExternalPaymentMethod } from '../../../../redux/services/paymentsService';
import { openDillToast } from '../../../../utils/helpers';
import { AppSuccessModal } from '../../AppSuccessModal/AppSuccessModal';
import errorCircle from '../../../../assets/svg/error_circle.svg';

const AppAddDwollaBankFundingSourceModal = ({
  open,
  userType = 'buyer',
  handleClose = () => {},
}: {
  open: boolean;
  userType?: 'supplier' | 'buyer';
  handleClose: (isSuccessfullyAdded?: boolean) => void;
}) => {
  const dispatch = useAppDispatch();
  const [isBankSuccessfullyAddedModalOpen, setIsBankSuccessfullyAddedModalOpen] = useState(false);
  const [isBankAddFailedModalOpen, setIsBankAddFailedModalOpen] = useState(false);
  const [bankAddFailedErrorMessage, setBankAddFailedErrorMessage] = useState('');
  const [accountType, setAccountType] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [errors, setErrors] = useState({
    accountType: '',
    routingNumber: '',
    accountNumber: '',
  });
  useEffect(() => {
    if (open) {
      setErrors({
        accountType: '',
        routingNumber: '',
        accountNumber: '',
      });
      setBankAddFailedErrorMessage('');
      setRoutingNumber('');
      setAccountType('');
      setAccountNumber('');
    }

    return () => {};
  }, [open]);

  const validateInputs = () => {
    const newErrors = {
      accountType: '',
      routingNumber: '',
      accountNumber: '',
    };

    let isValid = true;

    if (accountType === '') {
      newErrors.accountType = 'This field is required.';
      isValid = false;
    }
    if (routingNumber === '') {
      newErrors.routingNumber = 'This field is required.';
      isValid = false;
    }
    if (routingNumber && (routingNumber.length > 9 || routingNumber.length < 9)) {
      newErrors.routingNumber = 'Routing number must be exactly 9 characters.';
      isValid = false;
    }
    if (accountNumber === '') {
      newErrors.accountNumber = 'This field is required.';
      isValid = false;
    }
    if (accountNumber && accountNumber.length < 4) {
      newErrors.accountNumber = 'Account number must be 4 or more characters.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleAddDwollaBank = async () => {
    const isValid = validateInputs();
    if (!isValid) {
      return;
    }
    if (accountType && accountType && accountNumber && routingNumber) {
      const results = await dispatch(
        createExternalPaymentMethod({
          accountType,
          bankAccountNumber: accountNumber,
          routingNumber,
          userType,
        })
      );
      if (results && results.type === 'payments/createExternalPaymentMethod/fulfilled') {
        openDillToast({ message: 'Bank created', type: 'SUCCESS' });
        setIsBankSuccessfullyAddedModalOpen(true);
      } else {
        const payload: any = results.payload as any;
        if (results.type === 'payments/createExternalPaymentMethod/rejected' && payload) {
          setBankAddFailedErrorMessage(payload);
          setIsBankAddFailedModalOpen(true);
        }
      }
    } else {
      openDillToast({ message: 'Some fields are missing', type: 'ERROR' });
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="flex min-h-90 w-[40vw] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg  flex-col">
        <AppSuccessModal
          open={isBankSuccessfullyAddedModalOpen}
          title={`Successfully added bank method!`}
          subTitle="Two micro-deposits have been sent. They should arrive within the next 1-2 business days. Once you see these deposits in your account, please return and verify them in order to start using this bank method"
          buttonText="Close"
          handleClose={() => {
            setIsBankSuccessfullyAddedModalOpen(false);
            handleClose(true);
          }}
        />
        <AppSuccessModal
          open={isBankAddFailedModalOpen}
          modalImage={errorCircle}
          title={'Failed to add bank'}
          subTitle={bankAddFailedErrorMessage}
          buttonText="Close"
          handleClose={() => {
            setIsBankAddFailedModalOpen(false);
          }}
        />
        <div className="flex items-center mt-2">
          <AppButton
            type="FLAT"
            buttonStyles={{
              width: '40px',
              height: '40px',
              marginLeft: '12px',
              marginRight: '12px',
            }}
            icon={<CaretLeft size={24} color={'#000000'} />}
            onClick={() => {
              handleClose(false);
            }}
          />

          <h3 className="font-bold text-xl">Bank Details</h3>
        </div>
        <hr className="h-px my-1 bg-gray-200 border-0 dark:bg-gray-200"></hr>
        <div className="flex flex-col px-4 mt-2 grow pb-2">
          <div className="mb-2 bg-gray-100 p-1 rounded-md">
            <p className="text-xs  text-center">
              To link your bank account, please enter your account and routing information below. We
              will send two micro-deposits to verify your account. These should arrive in your
              account within the next 1-2 business days. If you'd like a faster process and have
              access to the online bank portal, you can go back and link your bank account the other
              way.
            </p>
          </div>

          <div className="w-full my-1">
            <AppDropDown
              isRequired
              label="Account Type"
              errorText={errors.accountType}
              value={accountType ? accountType : ''}
              items={[
                {
                  label: 'Savings',
                  value: 'savings',
                },
                {
                  label: 'Checking',
                  value: 'checking',
                },
              ]}
              onSelectChange={(item) => {
                if (item?.value) {
                  setAccountType(item?.value || '');
                }
              }}
            />
          </div>
          <div className="w-full my-1.5">
            <AppInputField
              isRequired
              id="routingNumber"
              label="Routing Number"
              inputType="number"
              placeholder=""
              value={routingNumber}
              errorText={errors.routingNumber ? errors.routingNumber : ''}
              onTextChange={(text) => {
                setRoutingNumber(text || '');
              }}
            />
          </div>
          <div className="w-full my-1.5">
            <AppInputField
              isRequired
              id="accountNumber"
              label="Account Number"
              inputType="number"
              placeholder=""
              value={accountNumber}
              errorText={errors.accountNumber ? errors.accountNumber : ''}
              onTextChange={(text) => {
                setAccountNumber(text || '');
              }}
            />
          </div>
        </div>
        <div className="flex flex-col w-full  mb-3">
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
          <div className="flex flex-row self-end px-6">
            <AppButton
              text="Close"
              type="TERTIARY"
              buttonStyles={{
                marginTop: '4px',
                marginBottom: '4px',
                marginRight: '12px',
              }}
              onClick={() => {
                handleClose(false);
              }}
            />
            <AppButton
              text={'Add Bank'}
              onClick={handleAddDwollaBank}
              buttonStyles={{
                marginTop: '4px',
                marginBottom: '4px',
                width: '100px',
              }}
              //   isLoading={loading}
              // isDisabled={validateInputs}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AppAddDwollaBankFundingSourceModal;
