import React from 'react';
import emptyListImg from '../../assets/images/emptyList.png';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const backHome = () => {
    localStorage.removeItem('pastPathList');
    navigate('/');
  };
  return (
    <div className="flex w-full h-full items-center">
      <div className="w-full flex flex-col items-center ">
        <p className="font-medium text-lg">Page not found</p>
        <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
        <div onClick={backHome}>
          <p className="text-blue-500 cursor-pointer">Back home</p>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
