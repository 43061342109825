import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import { PaymentsInitialState } from '../globalSlices/paymentsSlice';
import {
  createPlaidPaymentMethod,
  getBuyerTransactions,
  createExternalPaymentMethod,
  createSupplierPaymentMethod,
  payInvoices,
  getSupplierTransactions,
  createSupplierPlaidPaymentMethod,
  getSupplierPaymentMethods,
  supplierWithdraw,
  getWeekPaymentTransactionsLimitationsForMainBuyer,
  getStripeSupplierAccount,
  getStripeConfig,
  getSupplierRegistrationStatus,
  dwollaTransactionLimitNotifyDill,
  getTransactionById,
  verifyDwollaMicroTransactions,
  getMainBuyerPaymentMethods,
  deletePaymentMethodByMainBuyer,
  getSupplierPaymentsFiles,
  getBuyerTransactionsFiles,
  sendInvoicePaymentReminderEmail,
} from '../services/paymentsService';

export const createExternalPaymentMethodExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'createExternalPaymentMethod';
  builder.addCase(createExternalPaymentMethod.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createExternalPaymentMethod.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
      } else {
      }
    }
  );
  builder.addCase(createExternalPaymentMethod.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const verifyDwollaMicroTransactionsExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'verifyDwollaMicroTransactions';
  builder.addCase(verifyDwollaMicroTransactions.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    verifyDwollaMicroTransactions.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
      } else {
      }
    }
  );
  builder.addCase(verifyDwollaMicroTransactions.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const createPlaidPaymentMethodExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'createPlaidPaymentMethod';
  builder.addCase(createPlaidPaymentMethod.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createPlaidPaymentMethod.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
      } else {
      }
    }
  );
  builder.addCase(createPlaidPaymentMethod.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const createSupplierPlaidPaymentMethodExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'createSupplierPlaidPaymentMethod';
  builder.addCase(createSupplierPlaidPaymentMethod.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createSupplierPlaidPaymentMethod.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
      } else {
      }
    }
  );
  builder.addCase(
    createSupplierPlaidPaymentMethod.rejected,
    (state, action: PayloadAction<any>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
};

export const createSupplierPaymentMethodExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'createSupplierPaymentMethod';
  builder.addCase(createSupplierPaymentMethod.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    createSupplierPaymentMethod.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
      } else {
      }
    }
  );
  builder.addCase(createSupplierPaymentMethod.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getMainBuyerPaymentMethodsExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'getMainBuyerPaymentMethods';
  builder.addCase(getMainBuyerPaymentMethods.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getMainBuyerPaymentMethods.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.paymentMethods = action.payload?.data;
      }
    }
  );
  builder.addCase(getMainBuyerPaymentMethods.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getSupplierPaymentMethodsExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'getSupplierPaymentMethods';
  builder.addCase(getSupplierPaymentMethods.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getSupplierPaymentMethods.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.supplierPaymentMethods = action.payload?.data;
      }
    }
  );
  builder.addCase(getSupplierPaymentMethods.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const supplierWithdrawExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'supplierWithdraw';
  builder.addCase(supplierWithdraw.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    supplierWithdraw.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
      }
    }
  );
  builder.addCase(supplierWithdraw.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const getBuyerTransactionsExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'getBuyerTransactions';
  builder.addCase(getBuyerTransactions.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.buyerTransactions = [];
  });
  builder.addCase(
    getBuyerTransactions.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      if (action.payload) {
        state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      }

      if (action.payload && action.payload.success && action.payload?.data) {
        state.buyerTransactions = action.payload?.data;
      }
    }
  );
  builder.addCase(getBuyerTransactions.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const getBuyerTransactionsFilesExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'getBuyerTransactionsFiles';
  builder.addCase(getBuyerTransactionsFiles.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getBuyerTransactionsFiles.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      if (action.payload) {
        state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      }
    }
  );
  builder.addCase(getBuyerTransactionsFiles.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const getTransactionByIdExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'getTransactionById';
  builder.addCase(getTransactionById.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getTransactionById.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(getTransactionById.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const payInvoicesExtraReducer = (builder: ActionReducerMapBuilder<PaymentsInitialState>) => {
  const reducerId = 'payInvoices';
  builder.addCase(payInvoices.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.paymentError = null;
  });
  builder.addCase(
    payInvoices.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        // state.transactions = action.payload?.data;
      }
    }
  );
  builder.addCase(payInvoices.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.paymentError = action.payload;
  });
};
export const dwollaTransactionLimitNotifyDillExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'dwollaTransactionLimitNotifyDill';
  builder.addCase(dwollaTransactionLimitNotifyDill.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    dwollaTransactionLimitNotifyDill.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(
    dwollaTransactionLimitNotifyDill.rejected,
    (state, action: PayloadAction<any>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
};
export const getSupplierTransactionsExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'getSupplierTransactions';
  builder.addCase(getSupplierTransactions.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getSupplierTransactions.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.supplierTransactions = action.payload?.data?.transactions;
        const total =
          Number(action.payload?.data?.summary?.dwollaBalance?.value) +
          Number(action.payload?.data?.summary?.stripeBalance.availableAccountBalance) +
          Number(action.payload?.data?.summary?.stripeBalance.pendingAccountBalance);
        const amountAvailable =
          Number(action.payload?.data?.summary?.dwollaBalance?.value) +
          Number(action.payload?.data?.summary?.stripeBalance.availableAccountBalance);
        state.totalSupplierBalance = String(total);
        state.amountToWithraw = String(amountAvailable);
      }
    }
  );
  builder.addCase(getSupplierTransactions.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const getSupplierPaymentsFilesExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'getSupplierPaymentsFiles';
  builder.addCase(getSupplierPaymentsFiles.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getSupplierPaymentsFiles.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(getSupplierPaymentsFiles.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const getWeekPaymentTransactionsLimitationsForMainBuyerExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'getWeekPaymentTransactionsLimitationsForMainBuyer';
  builder.addCase(getWeekPaymentTransactionsLimitationsForMainBuyer.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getWeekPaymentTransactionsLimitationsForMainBuyer.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        const { totalAmountTransacted, weekLimit } = action.payload?.data;
        state.weekLimitations = { totalAmountTransacted, weekLimit };
      }
    }
  );
  builder.addCase(
    getWeekPaymentTransactionsLimitationsForMainBuyer.rejected,
    (state, action: PayloadAction<any>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
};
export const getStripeSupplierAccountExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'getStripeSupplierAccount';
  builder.addCase(getStripeSupplierAccount.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getStripeSupplierAccount.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.stripeCustomer = action.payload?.data;
      }
    }
  );
  builder.addCase(getStripeSupplierAccount.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const getStripeConfigExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'getStripeConfig';
  builder.addCase(getStripeConfig.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getStripeConfig.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.publishableKey = action.payload?.data.publishableKey;
      }
    }
  );
  builder.addCase(getStripeConfig.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const getSupplierRegistrationStatusExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'getSupplierRegistrationStatus';
  builder.addCase(getSupplierRegistrationStatus.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getSupplierRegistrationStatus.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.supplierRegistrationStatus = action.payload?.data;
      }
    }
  );
  builder.addCase(getSupplierRegistrationStatus.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const deletePaymentMethodByMainBuyerExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'deletePaymentMethodByMainBuyer';
  builder.addCase(deletePaymentMethodByMainBuyer.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    deletePaymentMethodByMainBuyer.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(deletePaymentMethodByMainBuyer.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const sendInvoicePaymentReminderEmailExtraReducer = (
  builder: ActionReducerMapBuilder<PaymentsInitialState>
) => {
  const reducerId = 'sendInvoicePaymentReminderEmail';
  builder.addCase(sendInvoicePaymentReminderEmail.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    sendInvoicePaymentReminderEmail.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(sendInvoicePaymentReminderEmail.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
