import { Modal } from '@mui/material';
import React, { useState, useEffect, useMemo } from 'react';
import { AppButton } from '../AppButton/AppButton';
import './AppDwollaCustomerDropIn.scss';
import {
  generateDwollaToken,
  getDwollaCustomer,
  getBeneficialOwnershipStatus,
} from '../../../redux/services/paymentsService';
import { updateSupplier } from '../../../redux/services/suppliersService';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { X, Loader, AlertOctagon } from 'react-feather';
import { Checks } from 'phosphor-react';
import { COLORS } from '../../../utils/colors';
import { getUserDetails } from '../../../redux/services/authService';

declare global {
  interface Window {
    dwolla?: any;
  }
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'dwolla-business-vcr': any;
      'dwolla-document-upload': any;
      'dwolla-beneficial-owners': any;
      'dwolla-personal-vcr': any;
      'dwolla-customer-update': any;
    }
  }
}

export const AppDwollaCustomerDropIn = ({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [condition, setCondition] = useState('create');
  const [customerId, setCustomerId] = useState('');
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.auth);
  const userEmail = useMemo(() => {
    if (user?.userSupplier?.email) {
      return user?.userSupplier?.email;
    }
    return '';
  }, [user]);
  const updateDwollaCustomerStatus = async () => {
    const dwollaCustomerResult: any = await dispatch(getDwollaCustomer({ email: userEmail }));
    if (!dwollaCustomerResult.payload.success) {
      setTitle('Create Verified Business');
      setCondition('create');
      return;
    }
    let status;
    setCustomerId(dwollaCustomerResult.payload.data.data.id);
    switch (dwollaCustomerResult.payload.data.data.status) {
      case 'verified':
        if (
          dwollaCustomerResult.payload.data.data.businessType === 'corporation' ||
          dwollaCustomerResult.payload.data.data.businessType === 'llc' ||
          dwollaCustomerResult.payload.data.data.businessType === 'partnership'
        ) {
          const beneficalStatus: any = await dispatch(
            getBeneficialOwnershipStatus({ id: dwollaCustomerResult.payload.data.data.id })
          );
          if (beneficalStatus.payload.data.data.status === 'uncertified') {
            setTitle('Add Business Owners');
            setCondition('beneficial');
            status = 'uncertified';
          } else {
            setTitle('Business Verified');
            setCondition('exist');
            status = 'verified';
          }
        } else {
          setTitle('Business Verified');
          setCondition('exist');
          status = 'verified';
        }
        break;
      case 'retry':
        setTitle('Retry Verifying Business');
        setCondition('retry');
        status = 'retry';
        break;
      case 'document':
        setTitle('Upload Document For Verification');
        setCondition('document');
        status = 'document';
        break;
      case 'unverified':
        setTitle('Retry Verifying Business');
        setCondition('unverified');
        status = 'unverified';
        break;
      case 'suspended':
        setTitle('Business Suspended');
        setCondition('suspended');
        status = 'suspended';
        break;
      default:
        setTitle('Create Verified Business');
        setCondition('create');
        status = 'create';
    }
    await dispatch(
      updateSupplier({
        supplierId: user?.userSupplier?.id ?? '',
        supplierDetails: {
          dwollaCustomerId: dwollaCustomerResult.payload.data.data.id,
          dwollaCustomerStatus: status,
        },
      })
    );
  };
  useEffect(() => {
    if (condition === 'exist') {
      handleClose();
    }
  }, [condition]);
  useEffect(() => {
    if (open) {
      updateDwollaCustomerStatus();
      setCondition('');
    } else {
      updateDwollaCustomerStatus();
      dispatch(getUserDetails());
    }
  }, [open]);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//cdn.dwolla.com/v2.1.8/dwolla-web.js';
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      window.dwolla.configure({
        environment: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'production' : 'sandbox',
        styles: '/styles/business-vcr.css',
        error: (err: any) => Promise.resolve(),
        token: async (req: any) => {
          const resp: any = await dispatch(generateDwollaToken({ req, body: 'abcd' }));
          return resp.payload.data.data;
        },
        success: (res: any) => {
          updateDwollaCustomerStatus();
          return Promise.resolve(res);
        },
      });
    };
    script.onerror = () => {
      console.log('Error loading Dwolla script.');
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const handleCloseModal = () => {
    handleClose();
    setCondition('');
    setTitle('');
  };
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <div className="dwolla-customer-dropin-modal-content">
        <div
          className={condition === '' ? 'modal-title-container' : 'drop-in-modal-title-container'}>
          <h3 className="font-medium text-lg">{title}</h3>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={handleCloseModal}
          />
        </div>
        {condition === '' && (
          <div className="process-content">
            <div className="px-6 py-12">
              <div className="flex flex-col justify-center content-center">
                <div className="loadingImage">
                  <Loader size={64} color={COLORS.PRIMARY_500} />
                </div>
              </div>
              <div className="details-text">Checking business status</div>
            </div>
          </div>
        )}
        {condition === 'exist' && (
          <div className="process-content">
            <div className="px-6 py-6">
              <div className="flex flex-col justify-center content-center">
                <div className="success-image">
                  <Checks size={64} color={COLORS.SUCCESS_600} />
                </div>
                <div className="details-text">Business Verified</div>
              </div>
            </div>
          </div>
        )}
        {condition === 'create' && (
          <div className="dropInContainer">
            <dwolla-business-vcr
              email={userEmail}
              terms="https://www.usedill.com/terms"
              privacy="https://www.usedill.com/privacy"></dwolla-business-vcr>
          </div>
        )}
        {condition === 'document' && (
          <div className="dropInContainer">
            <dwolla-document-upload
              email={userEmail}
              customerId={customerId}></dwolla-document-upload>
          </div>
        )}
        {condition === 'unverified' && (
          <div className="dropInContainer">
            <dwolla-customer-update
              customerId={customerId}
              terms="https://www.usedill.com/terms"
              isBusiness
              privacy="https://www.usedill.com/privacy"></dwolla-customer-update>
          </div>
        )}
        {condition === 'retry' && (
          <div className="dropInContainer">
            <dwolla-business-vcr
              email={userEmail}
              customerId={customerId}
              terms="https://www.usedill.com/terms"
              privacy="https://www.usedill.com/privacy"></dwolla-business-vcr>
          </div>
        )}
        {condition === 'beneficial' && (
          <div className="dropInContainer">
            <dwolla-beneficial-owners customerId={customerId}></dwolla-beneficial-owners>
          </div>
        )}
        {condition === 'suspended' && (
          <div className="process-content">
            <div className="px-6 py-6">
              <div className="flex flex-col justify-center content-center">
                <div className="errorImage">
                  <AlertOctagon size={64} color={COLORS.ERROR_500} />
                </div>
                <div className="details-text">Business Suspended</div>
                <div className="failed-desc-text mt-2">
                  Please reach out to <span>972-339-0799</span> for customer support
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
