import React, { useRef, useState, useEffect } from 'react';
import { Edit3, Eye } from 'react-feather';
import { COLORS } from '../../../utils/colors';
import './AppReleaseOptionsDropdown.scss';
import { DotsThreeVertical, ArrowElbowUpRight } from 'phosphor-react';
import { AppButton } from '../AppButton/AppButton';
import { useAppSelector } from '../../../redux/store';

const AppReleaseOptionsDropdown = ({
  inputFieldStyles,
  onSelect,
}: {
  inputFieldStyles?: any;
  onSelect: (value: string) => void;
}) => {
  const { user } = useAppSelector((state) => state.auth);
  const [showMenu, setShowMenu] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handler = (e: any) => {
      if (containerRef.current && !containerRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener('click', handler);
    return () => {
      window.removeEventListener('click', handler);
    };
  }, []);
  const handleShowDropdown = (e: any) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleSelectedOption = (val: string) => {
    onSelect(val);
    setShowMenu(!showMenu);
  };

  const renderDropdown = () => {
    return (
      <div className="flex flex-col py-3">
        <button
          onClick={() => handleSelectedOption('forward')}
          className="w-full flex flex-row py-1.5  px-3 font-medium items-center hover:bg-blue-100">
          <ArrowElbowUpRight color={COLORS.GREY_500} size={16} />
          <div className="text-sm TEXT_SECONDARY-CLR  ml-2">Forward</div>
        </button>
        <button
          onClick={() => handleSelectedOption('release-manual-add')}
          className="w-full flex flex-row py-1.5  px-3 font-medium items-center hover:bg-blue-100">
          <Eye color={COLORS.GREY_500} size={16} />
          <div className="text-sm TEXT_SECONDARY-CLR  ml-2">Manual Add</div>
        </button>
        <button
          onClick={() => handleSelectedOption('import-csv')}
          disabled={!user?.roles.includes('DILL_ADMIN')}
          className={`w-full flex flex-row py-1.5  px-3 font-medium items-center ${
            !user?.roles.includes('DILL_ADMIN') ? 'opacity-40 cursor-auto' : 'hover:bg-blue-100 '
          }`}>
          <Edit3 color={COLORS.GREY_500} size={16} />
          <div className="text-sm TEXT_SECONDARY-CLR  ml-2">Import CSV</div>
        </button>
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col" ref={containerRef}>
      <div
        className="release-option-dropdown-container h-fit "
        style={{
          ...inputFieldStyles,
        }}>
        <AppButton
          type="TERTIARY"
          buttonStyles={{
            padding: '8px',
            height: '40px',
            width: '40px',
            margin: '0px 0px',
          }}
          icon={<DotsThreeVertical color={COLORS.GREY_500} size={20} />}
          onClick={handleShowDropdown}
        />
        {showMenu && <div className="release-option-dropdown-menu h-fit">{renderDropdown()}</div>}
      </div>
    </div>
  );
};

export default AppReleaseOptionsDropdown;
