import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { REACT_APP_FRONTEND_URL } from '../../utils/constants';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import {
  getQuickBooksAuthUri,
  disconnectQuickbooks,
  mainBuyerDownloadSupplierInvoiceStatements,
  getGroupedSuppliersInvoices,
  deleteSupplierInvoice,
  sendMessagesInvoice,
} from '../services/invoicesService';
import { InvoiceInitialState } from '../globalSlices/invoiceSlice';

export const getQuickBooksAuthUriExtraReducer = (
  builder: ActionReducerMapBuilder<InvoiceInitialState>
) => {
  const reducerId = 'getQuickBooksAuthUri';
  builder.addCase(getQuickBooksAuthUri.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    getQuickBooksAuthUri.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data?.authUri) {
        state.quickBooksAuthUri = action.payload.data.authUri;
      } else {
        state.isGetQuickBooksAuthUrlFailed = true;
      }
    }
  );
  builder.addCase(getQuickBooksAuthUri.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.isGetQuickBooksAuthUrlFailed = true;
  });
};

export const disconnectQuickbooksExtraReducer = (
  builder: ActionReducerMapBuilder<InvoiceInitialState>
) => {
  const reducerId = 'disconnectQuickbooks';
  builder.addCase(disconnectQuickbooks.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    disconnectQuickbooks.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success) {
        window.location.href = `${REACT_APP_FRONTEND_URL}/customers?disconnected=true`;
      } else {
        state.isDisconnectQuickBooksFailed = true;
        window.location.href = `${REACT_APP_FRONTEND_URL}/customers?disconnected=false`;
      }
    }
  );
  builder.addCase(disconnectQuickbooks.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    state.isDisconnectQuickBooksFailed = true;
    window.location.href = `${REACT_APP_FRONTEND_URL}/customers?disconnected=false`;
  });
};
export const mainBuyerDownloadSupplierInvoiceStatementsExtraReducer = (
  builder: ActionReducerMapBuilder<InvoiceInitialState>
) => {
  const reducerId = 'mainBuyerDownloadSupplierInvoiceStatements';
  builder.addCase(mainBuyerDownloadSupplierInvoiceStatements.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    mainBuyerDownloadSupplierInvoiceStatements.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(
    mainBuyerDownloadSupplierInvoiceStatements.rejected,
    (state, action: PayloadAction<any>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
};

export const getGroupedSuppliersInvoicesExtraReducer = (
  builder: ActionReducerMapBuilder<InvoiceInitialState>
) => {
  const reducerId = 'getGroupedSuppliersInvoices';
  builder.addCase(getGroupedSuppliersInvoices.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.groupedSuppliersInvoices = [];
  });
  builder.addCase(
    getGroupedSuppliersInvoices.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      if (action.payload) {
        state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      }
      if (action.payload && action.payload.success && action.payload?.data.buyerSuppliers) {
        state.groupedSuppliersInvoices = action.payload?.data.buyerSuppliers;
      }
    }
  );
  builder.addCase(getGroupedSuppliersInvoices.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
export const deleteSupplierInvoiceExtraReducer = (
  builder: ActionReducerMapBuilder<InvoiceInitialState>
) => {
  const reducerId = 'deleteSupplierInvoice';
  builder.addCase(deleteSupplierInvoice.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.groupedSuppliersInvoices = [];
  });
  builder.addCase(
    deleteSupplierInvoice.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      if (action.payload) {
        state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      }
    }
  );
  builder.addCase(deleteSupplierInvoice.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};

export const sendMessagesInvoiceExtraReducer = (
  builder: ActionReducerMapBuilder<InvoiceInitialState>
) => {
  const reducerId = 'sendMessagesInvoice';
  builder.addCase(sendMessagesInvoice.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.groupedSuppliersInvoices = [];
  });
  builder.addCase(
    sendMessagesInvoice.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      if (action.payload) {
        state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      }
    }
  );
  builder.addCase(sendMessagesInvoice.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
