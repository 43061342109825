import React, { useMemo } from 'react';

import emptyListImg from '../../../../assets/images/emptyList.png';
import { NoticeTemplate } from '@dill/dill-shared';
import moment from 'moment';
import { DotsThreeVertical, Pencil, Trash } from 'phosphor-react';
import { AppButton } from '../../../general/AppButton/AppButton';
import { COLORS } from '../../../../utils/colors';

const NoticeTemplatesTable = ({ noticeTemplates }: { noticeTemplates: NoticeTemplate[] }) => {
  const columns = useMemo(() => {
    return [
      { id: 'name', name: 'Name', width: 200, cellType: 'VIEW_TEXT' },
      { id: 'created', name: 'Created', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'status', name: 'Status', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'actions', name: '', width: 150, cellType: 'VIEW_TEXT' },
    ];
  }, []);
  const totalWidth = useMemo(
    () =>
      columns.reduce((curr, prev) => {
        return curr + prev.width;
      }, 0),
    [columns]
  );
  return (
    <div className="w-full h-5/6 flex flex-col border rounded-lg  overflow-y-scroll mt-4">
      <div className="w-full flex border-b justify-between px-4 sticky top-0 PRIMARY_50-BG">
        {columns.map((column) => {
          return (
            <div key={column.id} style={{ flex: column.width / totalWidth }} className="py-3 px-1">
              <p className="text-xs TEXT_SECONDARY-CLR">{column.name}</p>
            </div>
          );
        })}
      </div>

      {noticeTemplates.length === 0 && (
        <div className="w-full h-full flex items-center justify-center">
          <div className="flex flex-col items-center">
            <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
            <p>No Templates</p>
          </div>
        </div>
      )}

      {noticeTemplates &&
        noticeTemplates.map((noticeTemplate, index) => {
          return (
            <div key={index + 'oo'} className="w-full flex  px-4 justify-between items-center">
              {columns.map((column) => {
                return (
                  <div
                    key={column.id}
                    style={{ flex: column.width / totalWidth }}
                    className="py-3 px-1">
                    {column.id === 'name' && (
                      <p className="text-sm capitalize">{noticeTemplate.name}</p>
                    )}
                    {column.id === 'created' && (
                      <p className="text-sm capitalize">
                        {' '}
                        {noticeTemplate?.createdAt
                          ? moment(noticeTemplate?.createdAt).format('MM/DD/YYYY')
                          : ''}
                      </p>
                    )}
                    {column.id === 'status' && (
                      <p className="text-sm capitalize">{noticeTemplate.status}</p>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

export default NoticeTemplatesTable;
