import type { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import { getSupplierBuyers, updateSupplierBuyer } from '../services/supplierBuyersService';
import { SupplierBuyersInitialState } from '../globalSlices/supplierBuyersSlice';

export const getSupplierBuyersExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierBuyersInitialState>
) => {
  const reducerId = 'getSupplierBuyers';
  builder.addCase(getSupplierBuyers.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
    state.loadingErrorList = [];
  });
  builder.addCase(
    getSupplierBuyers.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
      if (action.payload && action.payload.success && action.payload?.data) {
        state.supplierBuyers = action.payload?.data;
      }
    }
  );
  builder.addCase(getSupplierBuyers.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    if (action.payload) {
      state.loadingErrorList = [
        ...new Set([...state.loadingErrorList, { getSupplierBuyers: action.payload }]),
      ];
    }
  });
};

export const updateSupplierBuyerExtraReducer = (
  builder: ActionReducerMapBuilder<SupplierBuyersInitialState>
) => {
  const reducerId = 'updateSupplierBuyer';
  builder.addCase(updateSupplierBuyer.pending, (state, action) => {
    state.loadingList = [...new Set([...state.loadingList, reducerId])];
  });
  builder.addCase(
    updateSupplierBuyer.fulfilled,
    (state, action: PayloadAction<AppServerResponse | null>) => {
      state.loadingList = state.loadingList.filter((item) => item !== reducerId);
    }
  );
  builder.addCase(updateSupplierBuyer.rejected, (state, action: PayloadAction<any>) => {
    state.loadingList = state.loadingList.filter((item) => item !== reducerId);
  });
};
