import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppButton } from '../../../../general/AppButton/AppButton';
import emptyListImg from '../../../../../assets/images/emptyList.png';
import { CheckCircle, X } from 'phosphor-react';
import { useAppDispatch } from '../../../../../redux/store';
import { UserLinkApiResponse, SubUserApiPostBody } from '@dill/dill-shared';
import {
  getSubSuppliers,
  resendSubSuppliersInvite,
} from '../../../../../redux/services/subSuppliersService';
import BuyerAddSupplierModal from '../BuyerAddSupplierModal/BuyerAddSupplierModal';
import AppCustomDropdown from '../../../../general/AppCustomDropdown/AppCustomDropdown';
import { toast } from 'react-toastify';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { openMessageModal } from '../../../../../redux/globalSlices/genericSlice';
import { Edit3, Trash } from 'react-feather';
import { useParamMainBuyerId } from '../../../../../utils/hooks/useParamMainBuyerId';

const BuyerLienSuppliersTable = ({
  subSupplierUsers,
}: {
  subSupplierUsers: {
    subSupplierName: string;
    subSupplierId: string;
    notes: string;
    user: UserLinkApiResponse;
  }[];
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isAddSupplierModalOpen, setIsAddSupplierModalOpen] = useState(false);
  const [selectedSubSupplierUser, setSelectedSubSupplierUser] = useState<{
    subSupplierName: string;
    subSupplierId: string;
    notes: string;
    user: UserLinkApiResponse;
  } | null>(null);
  const [openDropdown, setOpenDropDown] = useState('');
  const [selectedMainBuyerId] = useParamMainBuyerId();

  const columns = useMemo(() => {
    return [
      { id: 'name', name: 'Company', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'title', name: 'Job Title', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'firstName', name: 'First Name', width: 200, cellType: 'VIEW_TEXT' },
      { id: 'lastName', name: 'Last Name', width: 200, cellType: 'VIEW_TEXT' },
      { id: 'email', name: 'Email', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'notes', name: 'Notes', width: 150, cellType: 'VIEW_TEXT' },
      { id: 'setup', name: 'Setup', width: 100, cellType: 'VIEW_TEXT' },
      { id: 'actions', name: '', width: 100, cellType: 'VIEW_TEXT' },
    ];
  }, []);

  useEffect(() => {
    if (selectedMainBuyerId) {
      dispatch(getSubSuppliers({ mainBuyerId: selectedMainBuyerId }));
    }

    return () => {};
  }, [selectedMainBuyerId]);

  const handleResendInvite = async (contact: SubUserApiPostBody) => {
    const resendInviteResult = await dispatch(
      resendSubSuppliersInvite({ contact, mainBuyerId: selectedMainBuyerId || '' })
    );
    if (resendInviteResult.type === 'subSuppliers/resendSubSuppliersInvite/fulfilled') {
      toast.success('Supplier invite resent!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        style: {
          backgroundColor: '#ECFDF3',
          color: '#054F31',
        },
      });
    } else {
      toast.error('Supplier resent invite failed!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        style: {
          backgroundColor: '#FEF0EF',
          color: '#F04438',
        },
      });
    }
  };

  return (
    <div className="w-full h-5/6 flex flex-col border rounded-lg overflow-y-scroll mt-4">
      <BuyerAddSupplierModal
        open={isAddSupplierModalOpen}
        selectedSubSupplierUser={selectedSubSupplierUser}
        handleClose={() => {
          setSelectedSubSupplierUser(null);
          if (selectedMainBuyerId) {
            dispatch(getSubSuppliers({ mainBuyerId: selectedMainBuyerId }));
          }
          setIsAddSupplierModalOpen(false);
        }}
        onUnArchiveSubsupplier={() => {}}
      />
      <div className="w-full flex border-b justify-between px-4 sticky top-0 PRIMARY_50-BG z-10">
        {columns.map((column) => {
          return (
            <div key={column.id} style={{ width: '14%' }} className="py-3 px-1">
              <p className="text-xs TEXT_SECONDARY-CLR">{column.name}</p>
            </div>
          );
        })}
      </div>

      {subSupplierUsers.length === 0 && (
        <div className="w-full h-full flex items-center justify-center">
          <div className="flex flex-col items-center">
            <img className="w-3/6 object-contain" src={emptyListImg} alt="" />
            <p>No suppliers</p>
          </div>
        </div>
      )}

      {subSupplierUsers &&
        subSupplierUsers.map((subSupplierUser, index) => {
          const user = subSupplierUser?.user?.subUser
            ? subSupplierUser?.user?.subUser
            : subSupplierUser?.user?.user;
          return (
            <div key={index + 'oo'} className="w-full flex  px-4 justify-between items-center">
              {columns.map((column, i) => {
                return (
                  <div key={column.id} style={{ width: '12%' }} className="my-1 px-1 items-center">
                    {column.id === 'title' && (
                      <p className="text-sm  overflow-hidden text-ellipsis">{user?.title}</p>
                    )}
                    {column.id === 'name' && (
                      <p className="text-sm capitalize  overflow-hidden text-ellipsis">
                        {subSupplierUser.subSupplierName}
                      </p>
                    )}
                    {column.id === 'firstName' && (
                      <p className="text-sm whitespace-nowrap  overflow-hidden text-ellipsis">
                        {user?.firstName}
                      </p>
                    )}
                    {column.id === 'lastName' && (
                      <p className="text-sm whitespace-nowrap  overflow-hidden text-ellipsis">
                        {user?.lastName}
                      </p>
                    )}
                    {column.id === 'email' && (
                      <p className="text-sm whitespace-nowrap  overflow-hidden text-ellipsis">
                        {user?.email}
                      </p>
                    )}
                    {column.id === 'notes' && (
                      <p
                        className="text-sm whitespace-nowrap  overflow-hidden text-ellipsis"
                        data-tooltip-id={`supplier-notes-tooltip_${index}`}>
                        {subSupplierUser?.notes}
                      </p>
                    )}
                    <ReactTooltip
                      id={`supplier-notes-tooltip_${index}`}
                      place="top"
                      variant="dark"
                      opacity={'100%'}
                      style={{
                        display: 'flex',
                        background: '#334155',
                        width: '300px',
                        zIndex: '70',
                        borderRadius: '8px',
                        alignItems: 'center',
                        justifyItems: 'center',
                        fontWeight: '600',
                      }}>
                      <span>{subSupplierUser?.notes}</span>
                    </ReactTooltip>
                    {column.id === 'setup' && (
                      <div className="flex items-center">
                        {subSupplierUser?.user?.subUser?.isSetup ? (
                          <div className="flex SUCCESS_100-BG SUCCESS_500-CLR h-7 w-7 items-center justify-center  SUCCESS_500-BORDER rounded-full border">
                            <CheckCircle size={15} />
                          </div>
                        ) : (
                          <div>
                            <div className="flex ERROR_100-BG ERROR_500-CLR h-7 w-7 items-center justify-center  ERROR_500-BORDER rounded-full border">
                              <X size={15} />
                            </div>
                          </div>
                        )}
                        <div>
                          {!subSupplierUser?.user?.subUser?.isSetup && (
                            <AppButton
                              type="TERTIARY"
                              text="Resend Invite"
                              buttonStyles={{
                                padding: '4px',
                                margin: '2px 16px',
                                borderRadius: '4px',
                              }}
                              onClick={() => {
                                if (subSupplierUser.user.subUser) {
                                  handleResendInvite(subSupplierUser.user.subUser);
                                }
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {column.id === 'actions' && (
                      <div className="flex justify-center flex-row-reverse w-full">
                        <div>
                          <AppCustomDropdown
                            inputFieldStyles={{ width: '35px', marginLeft: '4px' }}
                            onSelect={(val) => {
                              if (val === 'edit') {
                                setSelectedSubSupplierUser(subSupplierUser);
                                setIsAddSupplierModalOpen(true);
                              }
                              if (val === 'delete') {
                                setSelectedSubSupplierUser(subSupplierUser);
                                setIsAddSupplierModalOpen(true);
                              }
                            }}
                            open={openDropdown === `${user?.id}_${index}_${i}`}
                            onOpen={() => setOpenDropDown(`${user?.id}_${index}_${i}`)}
                            onClose={() => setOpenDropDown('')}
                            options={[
                              {
                                value: 'edit',
                                label: 'Edit',
                                icon: <Edit3 size={16} />,
                              },
                              {
                                value: 'delete',
                                label: 'Delete',
                                icon: <Trash size={16} />,
                                type: 'error',
                              },
                            ]}
                            width="100px"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
    </div>
  );
};

export default BuyerLienSuppliersTable;
