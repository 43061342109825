import React, { useState, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import './AppFilePicker.scss';

const baseStyle = {
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 4,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const AppFilePicker = ({
  onSelectFile,
  fileUrls,
  onDropProps,
}: {
  onSelectFile: (files: File[], fileUrl?: { name: string; url: string }[]) => void;
  fileUrls?: { name: string; url: string }[] | null;
  onDropProps?: any;
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [dragging, setDragging] = useState(false);
  const [urlFiles, setUrlFiles] = useState<{ name: string; url: string }[]>([]);
  const [change, setChange] = useState(false);
  const [fileErrors, setFileErrors] = useState<{ errorMessage: string; fileName: string }[]>([]);

  useEffect(() => {
    onSelectFile(files, urlFiles);
  }, [files, change]);

  useEffect(() => {
    if (fileUrls && fileUrls.length > 0) {
      setUrlFiles(fileUrls);
    } else {
      setUrlFiles([]);
    }
  }, [fileUrls]);

  const handleFiles = (uploadedFiles: any[]) => {
    const fileList = Array.from(uploadedFiles) as File[];
    setFiles((prevFiles) => [...prevFiles, ...fileList]);
  };

  const handleRemoveFile = (index: number) => {
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const handleRemoveUrlFile = (index: number) => {
    setUrlFiles((prevUrlFiles) => {
      const updatedUrlFiles = [...prevUrlFiles];
      updatedUrlFiles.splice(index, 1);
      return updatedUrlFiles;
    });
    setChange(!change);
  };

  const onDrop = (acceptedFiles: any[], fileRejections: any[]) => {
    if (acceptedFiles) {
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    }
    if (fileRejections && fileRejections.length > 0) {
      console.log(fileRejections);
      const fileReject = fileRejections.map((file) => ({
        errorMessage:
          file.errors[0]?.code === 'file-invalid-type'
            ? 'File should be .pdf'
            : file.errors[0]?.message || 'File is rejected',
        fileName: file?.file ? file?.file?.name : '',
      }));
      setFileErrors(fileReject);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    open: openFilePicker,
    isDragReject,
  } = useDropzone({
    onDrop,
    ...onDropProps,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div>
      <div onClick={openFilePicker} {...getRootProps({ style })} className="cursor-pointer">
        <input {...getInputProps()} />
        <p>Drag and drop files here, or click to select files</p>
      </div>
      {files.length > 0 && (
        <div>
          <h2>Uploaded Files:</h2>
          <ul>
            {files.map((file, index) => (
              <li key={index} className="drop-zone-list-file">
                {file.name}
                <button
                  className="drop-zone-remove-item-text"
                  onClick={() => handleRemoveFile(index)}>
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {urlFiles.length > 0 && (
        <div>
          <h2>Attached Files:</h2>
          <ul>
            {urlFiles.map((file, index) => (
              <li key={index} className="drop-zone-list-file">
                {file.name}
                <button
                  className="drop-zone-remove-item-text"
                  onClick={() => handleRemoveUrlFile(index)}>
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      {fileErrors.map((fileError, i) => (
        <div key={`file_error_${i}`} className="flex mt-2">
          <div className="text-sm">{`${fileError.fileName ? `${fileError.fileName}:` : ''} `}</div>
          <div className="text-sm ERROR_500-CLR">{fileError.errorMessage}</div>
        </div>
      ))}
    </div>
  );
};

export default AppFilePicker;
