import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';
import { firebaseFileUploadHelper } from '../../utils/helpers';
import { LienTemplateTypes, ReleaseTemplateField } from '@dill/dill-shared';

export const createLienTemplateByAdmin = createAsyncThunk(
  'lienTemplates/createLienTemplateByAdmin',
  async (
    {
      originalPdfUrl,
      modifiedPdfUrl,
      formFields,
      type,
      name,
    }: {
      originalPdfUrl: string;
      name: string;
      modifiedPdfUrl: string;
      type: LienTemplateTypes;
      formFields: ReleaseTemplateField[];
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const downloadUrl = await firebaseFileUploadHelper({
        blobUrl: modifiedPdfUrl,
        fileNameWithExtension: `${new Date().getTime()}-${name}.pdf`,
        storagePath: 'releaseTemplates',
      });
      if (!downloadUrl) {
        return null;
      }
      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/admin/releaseTemplates/createReleaseTemplate`,
        { originalPdfUrl, modifiedPdfUrl: downloadUrl, formFields, type, name },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const createBuyerLienTemplate = createAsyncThunk(
  'lienTemplates/createBuyerLienTemplate',
  async (
    {
      mainBuyerId,
      details: { pdfUrl, type, name, isWetSignature },
    }: {
      details: {
        pdfUrl: string;
        name: string;
        type: LienTemplateTypes;
        isWetSignature: boolean;
      };
      mainBuyerId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const downloadUrl = await firebaseFileUploadHelper({
        blobUrl: pdfUrl,
        fileNameWithExtension: `${new Date().getTime()}-${name}.pdf`,
        storagePath: 'releaseTemplates',
      });
      if (!downloadUrl) {
        return null;
      }
      const response = await axios.post<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/releaseTemplates/createReleaseTemplate`,
        { pdfUrl: downloadUrl, type, name, isWetSignature },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getLienTemplates = createAsyncThunk(
  'lienTemplates/getLienTemplates',
  async ({ mainBuyerId }: { mainBuyerId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/releaseTemplates/getReleaseTemplate`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getLienTemplatesByAdmin = createAsyncThunk(
  'lienTemplates/getLienTemplatesByAdmin',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/admin/releaseTemplates/getReleaseTemplates?status=pending`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateLienTemplateByAdmin = createAsyncThunk(
  'lienTemplates/updateLienTemplateByAdmin',
  async (
    {
      templateId,
      modifiedPdfUrl,
      formFields,
    }: {
      templateId: string;
      modifiedPdfUrl: string;
      formFields: ReleaseTemplateField[];
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const downloadUrl = await firebaseFileUploadHelper({
        blobUrl: modifiedPdfUrl,
        fileNameWithExtension: `${new Date().getTime()}-${name}.pdf`,
        storagePath: 'releaseTemplates',
      });
      if (!downloadUrl) {
        return null;
      }
      const response = await axios.put<AppServerResponse>(
        `${BASE_API_URL}/admin/releaseTemplates/updateReleaseTemplate/${templateId}`,
        { modifiedPdfUrl: downloadUrl, formFields },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getSupplierLienTemplates = createAsyncThunk(
  'lienTemplates/getSupplierLienTemplates',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.get<AppServerResponse>(
        `${BASE_API_URL}/suppliers/releaseTemplates/getReleaseTemplate`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
