import { createSlice } from '@reduxjs/toolkit';
import {
  getUserDetailsExtraReducer,
  logoutUserExtraReducer,
  resetPasswordExtraReducer,
  firebaseSendLoginEmailExtraReducer,
  firebaseConfirmLoginUserExtraReducer,
  checkUserExistsExtraReducer,
  getPlaidLinkTokenExtraReducer,
  updateLoggedInUserPasswordExtraReducer,
  firebaseLoginEmailAndPasswordExtraReducer,
  firebaseConfirmPasswordResetExtraReducer,
  getUserBuyersExtraReducer,
  referBuyerUsersExtraReducer,
  referSubSupplierUsersExtraReducer,
  updateSubUserExtraReducer,
  checkUserIsSetupWithCompanyExtraReducer,
  getStripePublishableKeyExtraReducer,
  getRutterPublicKeyExtraReducer,
  processRutterPublicTokenExtraReducer,
  firebaseSendPasswordResetEmailExtraReducer,
  updateUserDetailsExtraReducer,
  createUserSignatureExtraReducer,
  getUserSignaturesExtraReducer,
  deleteUserSignatureExtraReducer,
  newUserNotifyDillExtraReducer,
} from '../reducers/authReducers';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AppUserApiResponse, UserSignature } from '@dill/dill-shared';

export interface AuthInitialState {
  loadingList: string[];
  isLoginEmailSent: boolean;
  isValidatingUser: boolean;
  userCheckState: string;
  userCheckRoles: string[];
  error: string | null;
  authToken: string | null;
  companyId: string | null;
  user: AppUserApiResponse | null;
  firebaseUserId: string | null;
  globalPastPath: string;
  stripePublishableKey: string;
  rutterPublicKey: string;
  plaidTokenDetails: any;
  isPasswordSet: boolean;
  isEmailVerified: boolean;
  userBuyers: AppUserApiResponse[];
  userSignatures: UserSignature[];
}

const initialState: AuthInitialState = {
  loadingList: [],
  isLoginEmailSent: false,
  isValidatingUser: false,
  userCheckState: '',
  userCheckRoles: [],
  globalPastPath: '',
  stripePublishableKey: '',
  rutterPublicKey: '',
  error: null,
  authToken: null,
  companyId: null,
  user: null,
  firebaseUserId: null,
  plaidTokenDetails: null,
  isPasswordSet: false,
  isEmailVerified: false,
  userBuyers: [],
  userSignatures: [],
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthRootState: (state) => ({
      ...initialState,
      stripePublishableKey: state.stripePublishableKey ? state.stripePublishableKey : '',
      firebaseUserId: '',
    }),
    updateLoginState: (state, action: PayloadAction<string>) => {
      state.firebaseUserId = action.payload;
      if (!action.payload) {
        state.userCheckState = '';
        state.userCheckRoles = [];
      }
    },
    updateGlobalPastPath: (state, action: PayloadAction<string>) => {
      state.globalPastPath = action.payload;
    },
    resetUserCheck: (state) => {
      state.userCheckState = '';
      state.userCheckRoles = [];
    },
    updateSendLoginEmailState: (state, action: PayloadAction<boolean>) => {
      state.isLoginEmailSent = action.payload;
    },
    updateIsPasswordSet: (state, action: PayloadAction<boolean>) => {
      state.isPasswordSet = action.payload;
    },
    updateIsEmailVerified: (state, action: PayloadAction<boolean>) => {
      state.isEmailVerified = action.payload;
    },
    startProcessProgress: (state, action: PayloadAction<string>) => {
      state.loadingList = [...new Set([...state.loadingList, action.payload])];
    },
    endProcessProgress: (state, action: PayloadAction<string>) => {
      state.loadingList = state.loadingList.filter((item) => item !== action.payload);
    },
  },
  extraReducers(builder) {
    getUserDetailsExtraReducer(builder);
    logoutUserExtraReducer(builder);
    resetPasswordExtraReducer(builder);
    firebaseSendLoginEmailExtraReducer(builder);
    firebaseSendPasswordResetEmailExtraReducer(builder);
    firebaseConfirmLoginUserExtraReducer(builder);
    checkUserExistsExtraReducer(builder);
    getPlaidLinkTokenExtraReducer(builder);
    firebaseLoginEmailAndPasswordExtraReducer(builder);
    updateLoggedInUserPasswordExtraReducer(builder);
    firebaseConfirmPasswordResetExtraReducer(builder);
    getUserBuyersExtraReducer(builder);
    referBuyerUsersExtraReducer(builder);
    referSubSupplierUsersExtraReducer(builder);
    updateSubUserExtraReducer(builder);
    checkUserIsSetupWithCompanyExtraReducer(builder);
    getStripePublishableKeyExtraReducer(builder);
    getRutterPublicKeyExtraReducer(builder);
    processRutterPublicTokenExtraReducer(builder);
    updateUserDetailsExtraReducer(builder);
    createUserSignatureExtraReducer(builder);
    getUserSignaturesExtraReducer(builder);
    deleteUserSignatureExtraReducer(builder);
    newUserNotifyDillExtraReducer(builder);
  },
});

export const {
  resetAuthRootState,
  updateLoginState,
  updateIsPasswordSet,
  updateIsEmailVerified,
  updateSendLoginEmailState,
  resetUserCheck,
  startProcessProgress,
  endProcessProgress,
  updateGlobalPastPath,
} = authSlice.actions;
