import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  DillPaymentMethod,
  DillTransaction,
  StripeCustomerResponse,
  SupplierRegistrationStatusResponse,
} from '@dill/dill-shared';
import {
  createSupplierPaymentMethodExtraReducer,
  createExternalPaymentMethodExtraReducer,
  getMainBuyerPaymentMethodsExtraReducer,
  getBuyerTransactionsExtraReducer,
  payInvoicesExtraReducer,
  getSupplierTransactionsExtraReducer,
  createSupplierPlaidPaymentMethodExtraReducer,
  getSupplierPaymentMethodsExtraReducer,
  supplierWithdrawExtraReducer,
  getWeekPaymentTransactionsLimitationsForMainBuyerExtraReducer,
  getStripeSupplierAccountExtraReducer,
  getStripeConfigExtraReducer,
  getSupplierRegistrationStatusExtraReducer,
  dwollaTransactionLimitNotifyDillExtraReducer,
  getTransactionByIdExtraReducer,
  verifyDwollaMicroTransactionsExtraReducer,
  deletePaymentMethodByMainBuyerExtraReducer,
  getSupplierPaymentsFilesExtraReducer,
  getBuyerTransactionsFilesExtraReducer,
  sendInvoicePaymentReminderEmailExtraReducer,
} from '../reducers/paymentsReducers';

export interface PaymentsInitialState {
  error: string | null;
  paymentMethods: DillPaymentMethod[];
  supplierPaymentMethods: DillPaymentMethod[];
  buyerTransactions: DillTransaction[];
  loadingList: string[];
  supplierTransactions: DillTransaction[];
  totalSupplierBalance: string;
  amountToWithraw: string;
  paymentError: string | null;
  weekLimitations: { totalAmountTransacted: number; weekLimit: number } | null;
  stripeCustomer: StripeCustomerResponse | null;
  supplierRegistrationStatus: SupplierRegistrationStatusResponse | null;
  publishableKey: string | null;
}

const initialState: PaymentsInitialState = {
  error: null,
  buyerTransactions: [],
  paymentMethods: [],
  supplierPaymentMethods: [],
  loadingList: [],
  supplierTransactions: [],
  totalSupplierBalance: '',
  amountToWithraw: '',
  paymentError: null,
  weekLimitations: null,
  stripeCustomer: null,
  publishableKey: null,
  supplierRegistrationStatus: null,
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    resetPaymentsRootState: () => initialState,
  },
  extraReducers(builder) {
    createSupplierPaymentMethodExtraReducer(builder);
    createExternalPaymentMethodExtraReducer(builder);
    getMainBuyerPaymentMethodsExtraReducer(builder);
    getBuyerTransactionsExtraReducer(builder);
    payInvoicesExtraReducer(builder);
    getSupplierTransactionsExtraReducer(builder);
    createSupplierPlaidPaymentMethodExtraReducer(builder);
    getSupplierPaymentMethodsExtraReducer(builder);
    supplierWithdrawExtraReducer(builder);
    getWeekPaymentTransactionsLimitationsForMainBuyerExtraReducer(builder);
    getStripeSupplierAccountExtraReducer(builder);
    getStripeConfigExtraReducer(builder);
    getSupplierRegistrationStatusExtraReducer(builder);
    dwollaTransactionLimitNotifyDillExtraReducer(builder);
    getTransactionByIdExtraReducer(builder);
    verifyDwollaMicroTransactionsExtraReducer(builder);
    deletePaymentMethodByMainBuyerExtraReducer(builder);
    getSupplierPaymentsFilesExtraReducer(builder);
    getBuyerTransactionsFilesExtraReducer(builder);
    sendInvoicePaymentReminderEmailExtraReducer(builder);
  },
});

export const { resetPaymentsRootState } = paymentsSlice.actions;
