import { QuickbooksCustomer } from '@dill/dill-shared/dist/src';

export enum InvoiceListButtonStyle {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface InvoiceReference {
  invoiceNumber: string;
  jobNumber?: string;
  amount: number | null;
  customer?: QuickbooksCustomer;
}
