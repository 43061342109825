import { useEffect, useRef, useState } from 'react';

import { X } from 'phosphor-react';

import { AppButton } from '../AppButton/AppButton';

import { COLORS } from '../../../utils/colors';

import './AppNewSupplierJobModal.scss';
import { SupplierJobUpdate } from '@dill/dill-shared';
import { PartialSupplierOwnerVerify, SupplierVerifiedJobFormData } from './constants';
import VerifySupplierJob from './VerifyJob';
import { AppInputField } from '../AppInputField/AppInputField';
import { on } from 'events';
import { VerifyJobRequestData } from '../../../redux/services/verifyAddressService';

type VerifiedJobDataPick = Pick<
  SupplierJobUpdate,
  | 'owner'
  | 'ownerAddress'
  | 'ownerCity'
  | 'ownerState'
  | 'ownerZipCode'
  | 'GC'
  | 'GCAddress'
  | 'GCCity'
  | 'GCState'
  | 'GCZipCode'
  | 'GCEmail'
  | 'lender'
  | 'lenderAddress'
  | 'lenderCity'
  | 'lenderState'
  | 'lenderZipCode'
  | 'apn'
  | 'constructionMonitorPermitId'
>;

interface SupplierVerifyJobFormFunctions {
  supplierJobData: PartialSupplierOwnerVerify;
  onClose: () => void;
  onUseVerifiedData: (data: Record<string, any>) => void;
  onManualDataUpdate: (data: Record<string, any>) => void;
}

enum ModalState {
  VERIFY_JOB,
  VERIFY_OWNER,
  VERIFY_OWNER_MANUAL,
}

type SupplierVerifyJobFormProps = SupplierVerifyJobFormFunctions & SupplierVerifiedJobFormData;

const SupplierVerifyJobForm = (props: SupplierVerifyJobFormProps) => {
  const {
    onClose,
    onUseVerifiedData,
    onManualDataUpdate,
    jobAddress,
    jobCity,
    jobState,
    jobZipCode,
    owner,
    ownerAddress,
    ownerCity,
    ownerState,
    ownerZipCode,
    verifiedProperty,
    jobNumber,
    name,
    buyerId,
    supplierJobData,
    constructionMonitorPermitId,
  } = props;

  const [state, setState] = useState<ModalState>(ModalState.VERIFY_JOB);
  const [formState, setFormState] = useState<VerifiedJobDataPick>({});
  const [showErrorAbove, setShowErrorAbove] = useState(false);

  const [errors, setErrors] = useState<VerifiedJobDataPick>({});

  const onChange2 = (data: { name: string; value: string }) => {
    setFormState((prevState) => ({
      ...prevState,
      [data.name]: data.value,
    }));
  };

  const changeAutoState = () => {
    setState(ModalState.VERIFY_OWNER);
  };

  const changeManualState = () => {
    setState(ModalState.VERIFY_OWNER_MANUAL);
  };

  const closeModal = () => {
    setFormState({});
    onClose();
  };
  console.log('buyerId', buyerId);
  const validateInputs = () => {
    const newErrors = {} as VerifiedJobDataPick;

    let isValid = true;

    if (formState.owner?.trim() === '') {
      newErrors.owner = 'Owner is required.';
      isValid = false;
    }
    if (formState.ownerAddress?.trim() === '') {
      newErrors.ownerAddress = 'Owner Address is required.';
      isValid = false;
    }
    if (formState.ownerCity?.trim() === '') {
      newErrors.ownerCity = 'Owner City is required.';
      isValid = false;
    }
    if (formState.ownerState?.trim() === '') {
      newErrors.ownerState = 'Owner State is required.';
      isValid = false;
    }
    if (formState.ownerZipCode?.trim() === '') {
      newErrors.ownerZipCode = 'Owner Zip is required.';
      isValid = false;
    }
    if (formState.GC?.trim() === '') {
      newErrors.GC = 'GC is required.';
      isValid = false;
    }
    if (formState.GCAddress?.trim() === '') {
      newErrors.GCAddress = 'GC Address is required.';
      isValid = false;
    }
    if (formState.GCCity?.trim() === '') {
      newErrors.GCCity = 'GC City is required.';
      isValid = false;
    }
    if (formState.GCState?.trim() === '') {
      newErrors.GCState = 'GC State is required.';
      isValid = false;
    }
    if (formState.GCZipCode?.trim() === '') {
      newErrors.GCZipCode = 'GC Zip is required.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const onSubmitData = () => {
    if (!validateInputs()) {
      setShowErrorAbove(true);
      return;
    }
    setShowErrorAbove(false);

    if (state === ModalState.VERIFY_OWNER) {
      const updatdData = {
        ...formState,
        jobAddress: verifiedProperty?.address ? verifiedProperty.address : jobAddress,
        jobCity: verifiedProperty?.city ? verifiedProperty.city : jobCity,
        jobState: verifiedProperty?.state ? verifiedProperty.state : jobState,
        jobZipCode: verifiedProperty?.zipCode ? verifiedProperty.zipCode : jobZipCode,
        apn: verifiedProperty?.apn ? verifiedProperty.apn : '',
        constructionMonitorPermitId: constructionMonitorPermitId,
      };
      onUseVerifiedData(updatdData);
    } else if (state === ModalState.VERIFY_OWNER_MANUAL) {
      onManualDataUpdate(formState);
    }
    onClose();
  };

  useEffect(() => {
    if (state === ModalState.VERIFY_OWNER || state === ModalState.VERIFY_OWNER_MANUAL) {
      if (state === ModalState.VERIFY_OWNER) {
        onChange2({ name: 'owner', value: owner || '' });
        onChange2({ name: 'ownerAddress', value: ownerAddress || '' });
        onChange2({ name: 'ownerCity', value: ownerCity || '' });
        onChange2({ name: 'ownerState', value: ownerState || '' });
        onChange2({ name: 'ownerZipCode', value: ownerZipCode || '' });
        onChange2({ name: 'apn', value: verifiedProperty?.apn || '' });
      }
      onChange2({ name: 'GC', value: formState.GC || '' });
      onChange2({ name: 'GCAddress', value: formState.GCAddress || '' });
      onChange2({ name: 'GCCity', value: formState.GCCity || '' });
      onChange2({ name: 'GCState', value: formState.GCState || '' });
      onChange2({ name: 'GCZipCode', value: formState.GCZipCode || '' });
      onChange2({ name: 'lender', value: formState.lender || '' });
      onChange2({ name: 'lenderAddress', value: formState.lenderAddress || '' });
      onChange2({ name: 'lenderCity', value: formState.lenderCity || '' });
      onChange2({ name: 'lenderState', value: formState.lenderState || '' });
      onChange2({ name: 'lenderZipCode', value: formState.lenderZipCode || '' });
      onChange2({ name: 'jobNumber', value: jobNumber || '' });
      onChange2({ name: 'jobAddress', value: jobAddress || '' });
      onChange2({ name: 'jobCity', value: jobCity || '' });
      onChange2({ name: 'jobState', value: jobState || '' });
      onChange2({ name: 'jobZipCode', value: jobZipCode || '' });
      onChange2({ name: 'name', value: name || '' });
      onChange2({ name: 'buyerId', value: buyerId || '' });
    }
  }, [state]);

  useEffect(() => {
    if (supplierJobData) {
      setFormState({
        apn: supplierJobData.apn,
        owner: supplierJobData.owner,
        ownerAddress: supplierJobData.ownerAddress,
        ownerCity: supplierJobData.ownerCity,
        ownerState: supplierJobData.ownerState,
        ownerZipCode: supplierJobData.ownerZipCode,
        GC: supplierJobData.GC,
        GCAddress: supplierJobData.GCAddress,
        GCCity: supplierJobData.GCCity,
        GCState: supplierJobData.GCState,
        GCZipCode: supplierJobData.GCZipCode,
        lender: supplierJobData.lender,
        lenderAddress: supplierJobData.lenderAddress,
        lenderCity: supplierJobData.lenderCity,
        lenderState: supplierJobData.lenderState,
        lenderZipCode: supplierJobData.lenderZipCode,
      });
    }
  }, [supplierJobData]);

  return (
    <>
      <div className={`new-job-modal-content pt-3`}>
        <div className="new-job-modal-title-container">
          <h3 className="text-lg font-semibold">Verify Job</h3>
          <AppButton
            type="FLAT"
            buttonStyles={{ padding: '0px', margin: '5px 2px' }}
            icon={<X color={COLORS.GREY_300} size={30} />}
            onClick={onClose}
          />
        </div>
        <div className="content-job !overflow-y-auto mx-6 my-3 px-4">
          <VerifySupplierJob
            jobAddress={jobAddress}
            jobCity={jobCity}
            jobState={jobState}
            jobZipCode={jobZipCode}
            owner={owner}
            ownerAddress={ownerAddress}
            ownerCity={ownerCity}
            ownerState={ownerState}
            ownerZipCode={ownerZipCode}
            verifiedProperty={verifiedProperty}
          />
          {(state === ModalState.VERIFY_OWNER || state === ModalState.VERIFY_OWNER_MANUAL) && (
            <>
              <div className="flex flex-row  pb-3">
                <div className="w-full">
                  <AppInputField
                    id="apn"
                    label="Property APN"
                    placeholder="12345"
                    isRequired={true}
                    value={formState.apn}
                    errorText={errors.apn ? errors.apn : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'apn', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full">
                  <AppInputField
                    id="owner"
                    label="Owner"
                    inputType="text"
                    placeholder="Owner"
                    isRequired={true}
                    value={formState.owner}
                    errorText={errors.owner ? errors.owner : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'owner', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full">
                  <AppInputField
                    id="ownerAddress"
                    label="Owner Address"
                    inputType="text"
                    placeholder="Owner Address"
                    isRequired={true}
                    value={formState.ownerAddress}
                    errorText={errors.ownerAddress ? errors.ownerAddress : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'ownerAddress', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row pb-3">
                <div className="w-full">
                  <div className="w-full">
                    <AppInputField
                      id="ownerCity"
                      label="Owner City"
                      inputType="text"
                      placeholder="San Francisco"
                      isRequired={true}
                      value={formState.ownerCity}
                      errorText={errors.ownerCity ? errors.ownerCity : ''}
                      onTextChange={(text) => {
                        onChange2({ name: 'ownerCity', value: text });
                      }}
                    />
                  </div>
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="ownerState"
                    label="Owner State"
                    inputType="text"
                    placeholder="CA"
                    isRequired={true}
                    value={formState.ownerState}
                    errorText={errors.ownerState ? errors.ownerState : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'ownerState', value: text });
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <div className="w-full">
                    <AppInputField
                      id="ownerZip"
                      label="Owner Zip Code"
                      inputType="text"
                      placeholder="12345"
                      isRequired={true}
                      value={formState.ownerZipCode}
                      errorText={errors.ownerZipCode ? errors.ownerZipCode : ''}
                      onTextChange={(text) => {
                        onChange2({ name: 'ownerZipCode', value: text });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full relative">
                  <div className="w-full ">
                    <AppInputField
                      id="gc"
                      label="GC"
                      inputType="text"
                      placeholder="GC"
                      isRequired={true}
                      value={formState.GC}
                      errorText={errors.GC ? errors.GC : ''}
                      onTextChange={(text) => {
                        onChange2({ name: 'GC', value: text });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full ">
                  <AppInputField
                    id="GCAddress"
                    label="GC Address"
                    inputType="text"
                    placeholder="GC Address"
                    isRequired={true}
                    value={formState.GCAddress}
                    errorText={errors.GCAddress ? errors.GCAddress : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'GCAddress', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full">
                  <AppInputField
                    id="GCCity"
                    label="GC City"
                    inputType="text"
                    placeholder="San Francisco"
                    isRequired={true}
                    value={formState.GCCity}
                    errorText={errors.GCCity ? errors.GCCity : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'GCCity', value: text });
                    }}
                  />
                </div>

                <div className="spacer" />

                <div className="w-full">
                  <AppInputField
                    id="GCState"
                    label="GC State"
                    inputType="text"
                    placeholder="CA"
                    isRequired={true}
                    value={formState.GCState}
                    errorText={errors.GCState ? errors.GCState : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'GCState', value: text });
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="GCZip"
                    label="GC Zip Code"
                    inputType="text"
                    placeholder="12345"
                    isRequired={true}
                    value={formState.GCZipCode}
                    errorText={errors.GCZipCode ? errors.GCZipCode : ''}
                    onTextChange={(text) => {
                      onChange2({ name: 'GCZipCode', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full">
                  <AppInputField
                    id="lender"
                    label="Lender"
                    inputType="text"
                    placeholder="Lender"
                    value={formState.lender}
                    onTextChange={(text) => {
                      onChange2({ name: 'lender', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full ">
                  <AppInputField
                    id="lenderAddress"
                    label="Lender Address"
                    inputType="text"
                    placeholder="Lender Address"
                    value={formState.lenderAddress}
                    onTextChange={(text) => {
                      onChange2({ name: 'lenderAddress', value: text });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row  pb-3">
                <div className="w-full ">
                  <AppInputField
                    id="lenderCity"
                    label="Lender City"
                    inputType="text"
                    placeholder="San Francisco"
                    value={formState.lenderCity}
                    onTextChange={(text) => {
                      onChange2({ name: 'lenderCity', value: text });
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="lenderState"
                    label="Lender State"
                    inputType="text"
                    placeholder="CA"
                    value={formState.lenderState}
                    onTextChange={(text) => {
                      onChange2({ name: 'lenderState', value: text });
                    }}
                  />
                </div>
                <div className="spacer" />
                <div className="w-full">
                  <AppInputField
                    id="lenderZip"
                    label="Lender Zip Code"
                    inputType="text"
                    placeholder="12345"
                    value={formState.lenderZipCode}
                    onTextChange={(text) => {
                      onChange2({ name: 'lenderZipCode', value: text });
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </div>

        <div className="flex flex-col w-full mb-3 px-3">
          <hr className="h-px my-2 bg-gray-200 border-0 dark:bg-gray-200"></hr>
          {showErrorAbove && (
            <div className="my-1 text-xs ERROR_500-CLR text-center">Please see errors above</div>
          )}
          <div className="flex flex-row self-end px-6 gap-3">
            {state === ModalState.VERIFY_JOB && (
              <>
                <AppButton text="Close" type="TERTIARY" onClick={closeModal} />
                <AppButton
                  text="Manually update information"
                  type="SECONDARY"
                  onClick={changeManualState}
                />
                {verifiedProperty && (
                  <AppButton text="Use this data" type="PRIMARY" onClick={changeAutoState} />
                )}
              </>
            )}
            {(state === ModalState.VERIFY_OWNER || state === ModalState.VERIFY_OWNER_MANUAL) && (
              <>
                <AppButton text="Close" type="TERTIARY" onClick={closeModal} />
                <AppButton text="Save" type="PRIMARY" onClick={onSubmitData} />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierVerifyJobForm;
