import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppServerResponse } from '../../utils/interfaces/generalInterfaces';
import axios from 'axios';
import { BASE_API_URL } from '../../utils/constants';
import { logoutUser } from './authService';
import { myFirebaseAuth } from '../../firebase/config';

export const getQuickBooksAuthUri = createAsyncThunk(
  'invoice/getQuickBooksAuthUri',
  async (data, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.get<AppServerResponse>(
        BASE_API_URL + '/quickBooksAuthAPI/authUri',
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error?.message);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const disconnectQuickbooks = createAsyncThunk(
  'invoice/disconnectQuickbooks',
  async ({ supplierDomainId }: { supplierDomainId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        if (!token) {
          return null;
        }
      }
      const response = await axios.post<AppServerResponse>(
        BASE_API_URL + '/quickBooksAuthAPI/revokeQuickbookTokens/' + supplierDomainId,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getGroupedSuppliersInvoices = createAsyncThunk(
  'suppliers/getGroupedSuppliersInvoices',
  async (
    {
      mainBuyerId,
      weeksLimit,
      buyerId,
    }: { mainBuyerId?: string; buyerId?: string; weeksLimit: number },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      let url = `${BASE_API_URL}/suppliers/invoices/getGroupedSuppliersInvoices`;
      if (weeksLimit) {
        url = `${BASE_API_URL}/suppliers/invoices/getGroupedSuppliersInvoices?weeksLimit=${weeksLimit}`;
      }
      let details = {};
      if (mainBuyerId) {
        details = { ...details, mainBuyerId };
      }
      if (buyerId) {
        details = { ...details, buyerId };
      }
      const response = await axios.get<AppServerResponse>(url, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
        params: {
          ...details,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const updateBuyerGroupedInvoice = createAsyncThunk(
  'suppliers/updateBuyerGroupedInvoice',
  async (
    {
      data,
      invoiceId,
    }: {
      data: any;
      invoiceId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }

      const response = await axios.put<any>(
        `${BASE_API_URL}/suppliers/invoices/updateBuyerGroupedInvoice/${invoiceId}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const mainBuyerDownloadSupplierInvoiceStatements = createAsyncThunk(
  'invoice/mainBuyerDownloadSupplierInvoiceStatements',
  async (
    { mainBuyerId, supplierDomainId }: { mainBuyerId: string; supplierDomainId: string },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        if (!token) {
          return null;
        }
      }
      const response = await axios.get<any>(
        `${BASE_API_URL}/mainBuyers/${mainBuyerId}/invoices/downloadInvoiceStatements?supplierDomainId=${supplierDomainId}`,
        {
          responseType: 'blob',
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      const file = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(file);
      link.download = `invoice-statement-${mainBuyerId}-${supplierDomainId}-${+new Date()}.pdf`;
      link.click();

      return { success: true, data: { pdfUrl: link.href } };
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const deleteSupplierInvoice = createAsyncThunk(
  'invoice/deleteSupplierInvoice',
  async ({ invoiceId }: { invoiceId: string }, thunkApi) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        if (!token) {
          return null;
        }
      }
      const response = await axios.delete<any>(
        `${BASE_API_URL}/suppliers/invoices/${invoiceId}/deleteSupplierInvoice`,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const sendMessagesInvoice = createAsyncThunk(
  'suppliers/sendMessagesInvoice',
  async (
    {
      data,
      invoiceId,
    }: {
      data: any;
      invoiceId: string;
    },
    thunkApi
  ) => {
    try {
      const token = await myFirebaseAuth.currentUser?.getIdToken();
      if (!token) {
        return null;
      }
      const response = await axios.put<any>(
        `${BASE_API_URL}/suppliers/invoices/sendMessagesInvoice/${invoiceId}`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        }
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.status === 401) {
        thunkApi.dispatch(logoutUser());
      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
